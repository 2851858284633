import * as React from "react";
import { memo, FC } from "react";
import get from "lodash/get";
import Typography from "@mui/material/Typography";
import { useRecordContext } from "ra-core";
import { FieldProps, Labeled, sanitizeFieldRestProps } from "ra-ui-materialui";
import dayjs from "dayjs";
import { DATE_AND_TIME_FORMAT, DATE_FORMAT } from "../../constants/constants";

interface Props extends FieldProps {
  label: string;
  showTime?: boolean;
  hideLabel?: boolean;
  format?: string;
}

export const DateField: FC<Props> = memo(props => {
  const {
    className,
    emptyText,
    showTime = false,
    source = "",
    hideLabel = false,
    format = null,
    ...rest
  } = props;
  const record = useRecordContext(props);

  const label = hideLabel ? false : props.label;

  if (!record) {
    return null;
  }

  const value = get(record, source);
  if (value == null || value === "") {
    return emptyText ? (
      <Labeled label={label}>
        <Typography
          component="span"
          variant="body1"
          className={className}
          {...sanitizeFieldRestProps(rest)}
        >
          {emptyText}
        </Typography>
      </Labeled>
    ) : null;
  }

  const defaultFormat = showTime ? DATE_AND_TIME_FORMAT : DATE_FORMAT;
  const date = dayjs(value).format(format ?? defaultFormat);

  return (
    <Labeled label={label}>
      <Typography
        component="span"
        variant="body1"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {date}
      </Typography>
    </Labeled>
  );
});

DateField.displayName = "DateField";

export default DateField;
