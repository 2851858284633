import dayjs from "dayjs";
import { GetListParams } from "ra-core";
import {
  HealthmailListRaRecord,
  HealthmailRecord,
  HealthmailRecordResponse,
} from "../../types/healthmail";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";
import { list } from "../gaiaClient";
import { v4 as uuid } from "uuid";
import { 
  ADASTRA_CASE_NUMBER_SYSTEM_IDENTIFIER, 
  ADASTRA_CASE_REFERENCE_SYSTEM_IDENTIFIER, 
  ADASTRA_PATIENT_REFERENCE_SYSTEM_IDENTIFIER 
} from "../../constants/gaia";

const A_LONG_TIME_AGO = dayjs().subtract(2, "years").format();

const listHealthmailRecords = async (params: GetListParams) => {
  const { filter } = params;
  const { caseNumber } = filter;

  const queryParams = {
    startDate: A_LONG_TIME_AGO,
  };

  const correlationId = uuid();
  const headers = {
    "x-correlation-id": correlationId,
  };

  const filteredParams = shallowlyRemoveEmptyValues(queryParams);

  if (!caseNumber) {
    return {
      total: 0,
      data: [],
    };
  }

  const endpoint = `healthmail/cases/${caseNumber}`;
  const { data }: HealthmailRecordResponse = await list(endpoint, {
    params: filteredParams,
    headers,
  });

  const formattedData = formatData(data.healthmailDataOutputs, caseNumber);

  return {
    total: data.healthmailDataOutputs.length,
    data: formattedData,
  };
};

export default listHealthmailRecords;

function formatData(
  res: HealthmailRecord[],
  caseNumber: string
): HealthmailListRaRecord[] {
  const mappedData = res.map(output => {
    // separating by __ as : is part of the date string
    const id = `${caseNumber}__${output.encounter.startTime}__${output.encounter.endTime}`;

    return {
      ...output,
      id,
      caseNumber: output.encounter.identifiers.find(i => i.system == ADASTRA_CASE_NUMBER_SYSTEM_IDENTIFIER)!.value,
      caseReference: output.encounter.identifiers.find(i => i.system == ADASTRA_CASE_REFERENCE_SYSTEM_IDENTIFIER)!.value,
      patientReference: output.patient.identifiers.find(i => i.system == ADASTRA_PATIENT_REFERENCE_SYSTEM_IDENTIFIER)!.value,
    };
  });

  return mappedData;
}
