import { useDataProvider } from "ra-core";
import { useEffect } from "react";
import { SubscriptionCallback, SubscriptionEventMap } from "../types/realTime";

export function useSubscribe<Type extends keyof SubscriptionEventMap>(
  topic: Type,
  callback: SubscriptionCallback<Type>
) {
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.subscribe(topic, callback);

    return () => {
      dataProvider.unsubscribe(topic, callback);
    };
  }, [dataProvider, callback, topic]);
}
