import get from "lodash/get";
import React from "react";
import { TextField, useRecordContext, Labeled } from "react-admin";
import { useEditStateContext } from "../../../../context/EditStateContext";
import { BooleanField } from "../../../fields/BooleanField";
import { CapsDelimitedField } from "../../../fields/CapsDelimitedField";
import { Group } from "../../../layout/Group";
import { Heading } from "../../../ui/Heading";
import { Stack } from "../../../layout/Stack";
import { Grid } from "@mui/material";

export function ConsultationSummary() {
  const { currentState } = useEditStateContext();

  if (currentState === "show") return <ShowContent />;

  return null;
}

function ShowContent() {
  const record = useRecordContext();

  const status = get(record, "attributes.status");
  const isConsultFinished =
    status === "Completed" || status === "PendingFollowUpActions";

  if (!isConsultFinished) return null;
  return (
    <Group heading={<Heading level={2}>CP Summary</Heading>}>
      <Stack>
        <Labeled label="Ultimate Method Of Delivery">
          <TextField source="completionDetails[0].attributes.completedDeliveryMethod" />
        </Labeled>
        <CapsDelimitedField
          label="Consultation Outcome"
          source="completionDetails[0].consultationOutcomeDetails[0].attributes.name"
        />
        <Grid container>
          <Grid item xs={12}>
            <Heading level={4}>Recorded Technical Issues</Heading>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label="CP Sound Issue">
              <BooleanField source="completionDetails[0].consultationQualities[0].attributes.clinicalPractitionerSoundIssue" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label="CP Video Issue">
              <BooleanField source="completionDetails[0].consultationQualities[0].attributes.clinicalPractitionerVideoIssue" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label="Patient Sound Issue">
              <BooleanField source="completionDetails[0].consultationQualities[0].attributes.patientSoundIssue" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label="Patient Sound Issue">
              <BooleanField source="completionDetails[0].consultationQualities[0].attributes.patientVideoIssue" />
            </Labeled>
          </Grid>
        </Grid>
      </Stack>
    </Group>
  );
}
