import { GetListParams } from "react-admin";
import { list } from "../gaiaClient";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

const endpoint = "gp-surgeries";

export default async function listGpSurgeries(params: GetListParams) {

  const query = {
    "pageSize": params.pagination.perPage,
    "pageNumber": params.pagination.page,
    "nameContains": params.filter.name,
    "addressContains": params.filter.address,
    "addressCity": params.filter.city,
    "pageToken": params.meta.pageToken
  }

  const filteredQuery = shallowlyRemoveEmptyValues(query);


  const { data } = await list(endpoint, { params: filteredQuery });

  return {
      data: data.surgeries,
      total: data.total,
      pageInfo: {
        nextPageToken: data.nextPageToken
      }
    };
}
