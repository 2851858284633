import { baseUrl, create } from "..";

interface Params {
  id: string;
}

async function completeWorkItem(params: Params) {
  const { id } = params;

  const completeWorkItemEndpoint = `${baseUrl}/ms/workQueueItems/${id}/complete`;

  await create(completeWorkItemEndpoint, {});

  return {
    data: {
      id: "id-to-satisfy-ra",
    },
  };
}

export default completeWorkItem;
