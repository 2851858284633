import get from "lodash/get";
import { RaRecord } from "ra-core";

function getShouldOptimiseApts(record: RaRecord) {
  const backToBackApts = get(record, "backToBackApts", []);
  const shouldOptimiseApts = backToBackApts.find(
    (x: string) => x === "backToBack"
  );

  return shouldOptimiseApts;
}
export default getShouldOptimiseApts;
