import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function useTrackPageView() {
  const location = useLocation();

  const { pathname } = location;

  useEffect(() => {
    const { _paq } = window;
    if (!_paq) return;

    _paq.push(["setCustomUrl", pathname]);
    _paq.push(["setDocumentTitle", window.document.title]);
    _paq.push(["trackPageView"]);
  }, [pathname]);

  return null;
}
