import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import {
  useRecordContext,
  Button,
  ReferenceInput,
  Labeled,
  TextField,
  RaRecord,
  useChoicesContext,
  useCreate,
  useNotify,
  useRefresh,
} from "react-admin";
import { FullNameField } from "../fields/FullNameField";
import AutocompleteInput from "../inputs/AutocompleteInput";
import { Stack } from "../layout/Stack";
import { Heading } from "../ui/Heading";

const buttonStyles = {
  margin: "16px 0",
};

export const LinkWithGuardianButton = React.memo(function AddDependantButton() {
  const record = useRecordContext();

  const [isModalOpen, setModalOpenState] = useState(false);

  const handleClose = () => setModalOpenState(false);

  if (!record) return null;

  const { dateOfBirth } = record.attributes;
  const sixteenYearsAgo = dayjs().subtract(16, "years");
  const underAgeOfMajority = dayjs(sixteenYearsAgo).isBefore(dateOfBirth);

  if (!underAgeOfMajority) return null;

  return (
    <div>
      <Button
        label="Link with Guardian"
        sx={buttonStyles}
        color="primary"
        aria-label="Link with Guardian"
        variant="contained"
        size="medium"
        onClick={() => {
          setModalOpenState(true);
        }}
      />

      <Dialog open={isModalOpen} onClose={handleClose}>
        <ReferenceInput source="dependantOf" reference="patientRecords">
          <LinkWithGuardianModal handleClose={handleClose} />
        </ReferenceInput>
      </Dialog>
    </div>
  );
});

function LinkWithGuardianModal(props: any) {
  const { handleClose } = props;

  return (
    <div>
      <DialogTitle>Link with Guardian</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
          <AutocompleteInput
            label="Dependant of"
            filterToQuery={query => ({
              email: query,
            })}
            placeholder="Search by email..."
            optionText={(guardianRecord: RaRecord) => {
              if (!guardianRecord) return "";

              return guardianRecord.attributes.email;
            }}
          />
          <GuardianDetails />
        </div>

        <DialogActions>
          <Button
            size="medium"
            onClick={handleClose}
            variant="outlined"
            label="Cancel"
          />
          <ConfirmLinkButton />
        </DialogActions>
      </DialogContent>
    </div>
  );
}

function GuardianDetails() {
  const { selectedChoices } = useChoicesContext();
  const choice = selectedChoices[0] ? selectedChoices[0] : {};

  return (
    <Stack sx={{ width: "256px" }}>
      <Heading level={3}>Guardian details</Heading>
      <Labeled label="Guardian name">
        <FullNameField
          record={choice}
          firstNameSource="attributes.firstName"
          lastNameSource="attributes.lastName"
          emptyText="N/A"
        />
      </Labeled>
      <Labeled label="Email">
        <TextField record={choice} source="attributes.email" emptyText="N/A" />
      </Labeled>
      <Labeled label="Date of birth">
        <TextField
          record={choice}
          source="attributes.dateOfBirth"
          emptyText="N/A"
        />
      </Labeled>
    </Stack>
  );
}

function ConfirmLinkButton() {
  const record = useRecordContext();
  const clientId = record.clients[0].id;
  const { selectedChoices } = useChoicesContext();
  const [linkWithGuardian, { isLoading }] = useCreate();
  const choice = selectedChoices[0];
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = () => {
    linkWithGuardian(
      "linkWithGuardian",
      {
        data: {
          clientId,
          guardianId: choice.id,
          dependantId: record.patientId,
        },
      },
      {
        onSuccess: () => {
          notify("The patient was successfully linked with the guardian");
          refresh();
        },
      }
    );
  };

  return (
    <Button
      size="medium"
      disabled={!choice || isLoading}
      variant="contained"
      label="Link with Guardian"
      aria-label="Confirm Link with Guardian"
      onClick={() => handleClick()}
    />
  );
}
