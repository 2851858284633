import React from "react";
import { FormTab } from "../../../form/CustomTabForm/FormTab";
import { Stack } from "../../../layout/Stack";
import { PersonalDetails } from "./PersonalDetails";
import { ContactDetails } from "./ContactDetails";
import { Notes } from "./Notes";
import { GPDetails } from "./GPDetails";
import { PharmacyDetails } from "./PharmacyDetails";
import { EditTabProps } from "../../../../types/utilities";
import { ClinicalDetails } from "./ClinicalDetails";
import { Box } from "@mui/material";
import { RequestOverrideModal } from "../../../modals/RequestOverrideModal";
import { RaRecord } from "react-admin";
import { IdentityVerificationDetails } from "./IdentityVerificationDetails";

interface GeneralTabProps {
  open: boolean;
  onClose: () => void;
  transform?: (record: RaRecord) => RaRecord;
}

type Props = GeneralTabProps & EditTabProps;

export function GeneralTab(props: Props) {
  const {open, onClose, transform, ...rest} = props;
  return (
    <FormTab
      {...rest}
      editable="editable"
      label="General">
      <RequestOverrideModal
        open={open}
        onClose={onClose}
        transform={transform}
      />
      <Box sx={{ width: "100%" }}>
        <Stack>
          <PersonalDetails />
          <IdentityVerificationDetails />
          <ContactDetails />
          <ClinicalDetails />
          <Notes />
          <GPDetails />
          <PharmacyDetails />
        </Stack>
      </Box>
    </FormTab>
  );
}
