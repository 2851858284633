// Based on postcoder's Node script:  https://github.com/allies-computing/address-lookup-node/blob/master/index.js
import axios from "axios";
import { GetListParams } from "react-admin";
import { ExtendedAddress } from "../context/AddressContext";

interface PostcoderAddress {
  addressline1: string;
  addressline2: string;
  posttown: string;
  county: string;
  postcode: string;
  summaryline: string;
}

function formatAddresses(addresses: PostcoderAddress[]): ExtendedAddress[] {
  return addresses.map(address => {
    return {
      id: address.summaryline,
      addressLine1: address.addressline1,
      addressLine2: address.addressline2,
      city: address.posttown,
      county: address.county,
      countryCode: "GB",
      postCode: address.postcode,
    };
  });
}

/**
 *
 * This function should be treated as an adapter between EDIII and whichever 3rd part solution we choose to use.
 * i.e. we should be able to change any of the internals to match the requirements of the library while adhering to the same inputs/outputs
 */
async function addressRequestAdapter(postcode: string) {
  const API_KEY = process.env.REACT_APP_POSTCODER_API_KEY;
  const endpoint = `https://ws.postcoder.com/pcw/${API_KEY}/address/UK/${postcode}?format=json&lines=3&include=country,posttown,postcode,addressline1,addressline2`;

  const { data } = await axios.get(endpoint);
  return formatAddresses(data);
}

export async function listAddressesByPostcode(params: GetListParams) {
  const { postcode } = params.filter;

  const address = await addressRequestAdapter(postcode);

  return {
    data: address,
    total: address.length,
  };
}
