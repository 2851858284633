type BaseData = {
  id: string;
  type: string;
  attributes: unknown;
  relationships: Relationships;
  included?: Record<string, BaseData[]>;
};

type BaseIncluded = BaseData[];

type Relationships = Record<string, Relationship>;

type Relationship = {
  links: Record<string, string>;
  data?: { type: string; id: string }[];
  ids?: string[];
};

function appendIncludedDataToObject<Included extends BaseIncluded>(
  item: Relationship,
  output: Record<string, BaseData[]>,
  included: Included
) {
  if (!item || !item.data) return;

  const { data } = item;

  const relationship = Array.isArray(data) ? data : [data];

  relationship.forEach(({ id, type }) => {
    const relatedItem = included.find(includedItem => {
      return includedItem.id === id && includedItem.type === type;
    });

    if (relatedItem) {
      if (!output[type]) {
        output[type] = [];
      }

      output[type]!.push(relatedItem);
    }
  });
}

function combineIncludedRecords<Included extends BaseIncluded>(
  relationships: Relationship[],
  included: Included
) {
  const output: Record<string, BaseData[]> = {};

  relationships.forEach(relationship => {
    appendIncludedDataToObject(relationship, output, included);
  });

  return output;
}

function combineIdsToRelationships(relationships: Relationships) {
  const relCopy = { ...relationships };
  const entries = Object.entries(relationships);

  entries.forEach(([key, value]) => {
    if (!value || !value.data) return;

    const { data } = value;

    const formatedData = Array.isArray(data) ? data : [data];

    const ids = formatedData.map(({ id }) => id);

    relCopy[key].ids = ids;
  });

  return relCopy;
}

function consolidateRecords<
  Data extends BaseData,
  Included extends BaseIncluded
>(data: Data[], included: Included): any {
  const combinedIncluded = included.map(record => {
    if (!record.relationships) return record;

    const relationshipItems = Object.values(record.relationships);

    const relationshipsWithIds = combineIdsToRelationships(
      record.relationships
    );

    record.relationships = relationshipsWithIds;

    const newRecords = combineIncludedRecords(relationshipItems, included);

    return { ...record, ...newRecords };
  });

  const consolidatedData = data.map(record => {
    const { relationships } = record;
    const relationshipItems = Object.values(relationships);

    const includes = combineIncludedRecords(
      relationshipItems,
      combinedIncluded
    );

    return { ...record, ...includes };
  });

  return consolidatedData;
}

export default consolidateRecords;
