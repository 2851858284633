import { useGetList } from "ra-core";
import React, { createContext, useContext, useState } from "react";
import { Address } from "../types/common";

export interface ExtendedAddress extends Address {
  id: string;
}

interface Context {
  currentAddress: ExtendedAddress | null;
  addresses: ExtendedAddress[];
  selectAddress: (id: string) => void;
  requestAddresses: (postcode: string | null) => void;
}

const AddressContext = createContext<Context>({
  currentAddress: null,
  addresses: [],
  selectAddress: () => null,
  requestAddresses: () => null,
});

interface ProviderProps {
  children: React.ReactNode;
}

const resource = "addresses";

export function AddressContextProvider(props: ProviderProps) {
  const { children } = props;
  const [currentAddressId, setCurrentAddress] = useState<string | null>(null);
  const [postcode, setPostcode] = useState<string | null>(null);

  const { data = [] } = useGetList<ExtendedAddress>(
    resource,
    {
      pagination: {
        page: 0,
        perPage: 0,
      },
      sort: {
        field: "id",
        order: "asc",
      },
      filter: {
        postcode,
      },
    },
    {
      enabled: Boolean(postcode),
      refetchOnWindowFocus: false,
    }
  );

  const currentAddress = currentAddressId
    ? data[Number(currentAddressId)]
    : null;

  return (
    <AddressContext.Provider
      value={{
        currentAddress,
        addresses: data,
        selectAddress: setCurrentAddress,
        requestAddresses: setPostcode,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
}

export const useAddressContext = () => useContext(AddressContext);
