import { update } from "..";
import { ClientEmployerDTO } from "../../types/clientEmployer";

async function updateEmployer(args: ClientEmployerDTO["data"]) {
  const { id, attributes } = args;
  const updateEmployersEndpoint = `ms/employers/${id}`;
  const { name, code, clientId, clinicalProductId } = attributes;

  const updateEmployerBody = {
    data: {
      type: "employers",
      id,
      attributes: {
        name,
        clientId,
        code,
        clinicalProductId
      },
    },
  };

  const employerRes = await update(updateEmployersEndpoint, updateEmployerBody);

  return employerRes;
}

export default updateEmployer;
