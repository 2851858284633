import React from "react";
import { styled } from "@mui/material/styles";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useState } from "react";
import { TextInput } from "react-admin";
import { useWatch, useFormContext } from "react-hook-form";
import { commentTemplates } from "../../constants/commentTemplates";
import { Button } from "../buttons/Button";
import { Heading } from "../ui/Heading";
import { Colors } from "../../theme";

const PREFIX = "CommentsInput";

const classes = {
  inputsGroup: `${PREFIX}-inputsGroup`,
  content: `${PREFIX}-content`,
};

const Root = styled("div")(({ theme: { spacing } }) => ({
  [`&.${classes.inputsGroup}`]: {
    display: "flex",
    flexDirection: "column",
    margin: `${spacing(1)} 0 ${spacing(4)}`,
    width: 360,
  },

  [`& .${classes.content}`]: {
    color: Colors.Blue,
  },
}));

const buttonStyles = {
  textTransform: "none",
  textAlign: "left",
  borderColor: "transparent",
};

export function CommentsInput() {
  const form = useFormContext();
  const comments = useWatch({ name: "comments" });
  const [isDialogOpen, setDialogOpenState] = useState(false);

  const handleCloseDialog = () => setDialogOpenState(false);
  const handleOpenDialog = () => setDialogOpenState(true);

  const appendComment = (comment: string) => {
    const existingComment = comments;

    if (!existingComment) {
      form.setValue("comments", comment);
      return handleCloseDialog();
    }

    // Make sure template literal remains formatted this way
    const updatedComment = `${existingComment}

${comment}`;

    form.setValue("comments", updatedComment);
    return handleCloseDialog();
  };

  return (
    <Root className={classes.inputsGroup}>
      <Heading level={3}>Comments</Heading>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Example comments</DialogTitle>
        <DialogContent id="responses-content" className={classes.content}>
          {commentTemplates.map(msg => (
            <Button
              sx={buttonStyles}
              onClick={() => appendComment(msg)}
              variant="text"
              key={msg}
              color="inherit"
            >
              <Typography variant="body1">{msg}</Typography>
            </Button>
          ))}
        </DialogContent>
      </Dialog>
      <TextInput multiline source="comments" label="Comments" />
      <Button
        aria-label="Add note template"
        variant="contained"
        onClick={handleOpenDialog}
      >
        Add note template
      </Button>
    </Root>
  );
}
