import React from "react";

function formatAddress(address?: Record<string, React.ReactNode>) {
  if (!address) return null;

  return Object.entries(address)
    .map(([key, value]) => <div key={key}>{value}</div>)
    .filter(x => x);
}

export default formatAddress;
