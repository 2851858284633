import { Datagrid } from "ra-ui-materialui";
import React from "react";
import { TimeRange } from "../fields/TimeRange";
import { StatusField } from "./StatusField";
import DateField from "../fields/DateField";
import { ListBase, ResourceContextProvider, useRecordContext } from "ra-core";
import get from "lodash/get";
import { EmptyGrid } from "./EmptyGrid";
import { CancelAppointmentsButton } from "./CancelAppointmentsButton";

/**
 * You can leverage the Datagrid's "selected" state if it lives within a `List` component.
 *
 * We can wrap a Datagrid inside of a ListBase where it fetches the proposed appointments.
 * The ListBase is only concerned with data fetching, and doesn't set any visual defaults like pagination, filtering.
 * This treats the proposed appointments like a resource.
 *
 * The upsides is that we users can interface with it as if it's any other listed resource, including performing bulk actions.
 *
 * The downsides is that it requires an additional network request and more code complexity.
 *
 * You can learn more about the implementation here: https://marmelab.com/blog/2021/02/02/react-admin-february-2021-update.html#use-code-classlanguage-textltlistgtcode-everywhere-you-want
 */
export function ConfirmedAppointments() {
  const record = useRecordContext();
  const shiftId = record?.id ?? "";

  return (
    <ResourceContextProvider value="proposedAppointments">
      <ListBase filter={{ id: shiftId }}>
        <Datagrid
          empty={<EmptyGrid />}
          bulkActionButtons={<span />}
          rowClick={(id, path, aptRecord) => {
            const aptId = get(aptRecord, "appointments[0].id");
            return `/adminAppointments/${aptId}/show`;
          }}
        >
          <TimeRange
            label="Apt. Slot"
            startSource="attributes.start"
            endSource="attributes.end"
          />
          <DateField label="Date" hideLabel source="attributes.start" />
          <StatusField label="Status" />
        </Datagrid>
        <CancelAppointmentsButton />
      </ListBase>
    </ResourceContextProvider>
  );
}
