import React from "react";
import { Button, ButtonProps } from "react-admin";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline";
import { SxProps, Theme } from "@mui/material";

interface Props extends ButtonProps {
  buttonClassName?: string;
  sx?: SxProps<Theme>;
}

export function FormIteratorRemoveButton(props: Props) {
  const { label, onClick, sx } = props;
  return (
    <Button
      size="small"
      onClick={onClick}
      aria-label={label}
      label={label}
      sx={sx}
    >
      <RemoveIcon />
    </Button>
  );
}
