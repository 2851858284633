import React from "react";
import Button from "@mui/material/Button";
import { useCreate, useGetManyReference, useRecordContext } from "ra-core";

export function DownloadExistingIdentifiersButton() {
  const record = useRecordContext();
  const { id } = record;

  const { data, isLoading } = useGetManyReference("clientIdentifiers", {
    target: "client.id",
    id,
    pagination: { page: 1, perPage: 10 },
    sort: { field: "published_at", order: "DESC" },
  });

  const [download, { isLoading: isDownloading }] = useCreate(
    "downloadClientIdentifiers",
    {
      data: { id: id },
    }
  );

  const identifiers = Object.values(data ?? {});

  const hasExistingIdentifiers =
    !isLoading && identifiers.length ? true : false;

  return (
    <Button
      disabled={!hasExistingIdentifiers || isDownloading}
      variant="contained"
      color="primary"
      aria-label="download-existing-identifiers"
      onClick={() => download()}
    >
      Download Existing Identifiers
    </Button>
  );
}
