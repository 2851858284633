import {
  // eslint-disable-next-line no-restricted-imports
  AutocompleteInput as RaAutocompleteInput,
  AutocompleteInputProps,
} from "ra-ui-materialui";
import React from "react";

export interface Props extends AutocompleteInputProps {
  minDate?: string | false;
  maxDate?: string;
  required?: boolean;
  placeholder?: string;
  showClearButton?: boolean
}

export const AutocompleteInput = (props: Props) => {
  const isRequired = props.isRequired || props.required;

  if (props.showClearButton)
    return (
      <RaAutocompleteInput
        {...props}
        isRequired={isRequired}
        TextFieldProps={{
          placeholder: props.placeholder,
        }}
      />
    )

  return (
    <RaAutocompleteInput
      // We need these three props set to allow clearing on blur while hiding the close button
      disableClearable={false}
      clearIcon={<span />}
      clearOnBlur={true}
      {...props}
      isRequired={isRequired}
      TextFieldProps={{
        placeholder: props.placeholder,
      }}
    />
  );
};

export default AutocompleteInput;
