import { Grid } from "@mui/material";
import React from "react";
import { TextField, Labeled } from "react-admin";
import { EMPTY_TEXT } from "../../../../constants/constants";
import { useEditStateContext } from "../../../../context/EditStateContext";
import { GPAddressField } from "../../../fields/GPAddressField";
import { GooglePlaceAddressForm } from "../../../inputs/GooglePlace/GooglePlaceAddressForm";
import { Group } from "../../../layout/Group";
import { Stack } from "../../../layout/Stack";
import { Heading } from "../../../ui/Heading";

export function GPDetails() {
  const { currentState } = useEditStateContext();

  if (currentState === "show") return <ShowContent />;

  return <EditContent />;
}

function ShowContent() {
  return (
    <Group heading={<Heading level={2}>GP Details</Heading>}>
      <Grid item xs={12} sm={6}>
        <Stack>
          <Labeled label="GP Surgery Name">
            <TextField
              source="attributes.gp.surgery.name"
              emptyText={EMPTY_TEXT}
            />
          </Labeled>
          <Labeled label="GP Surgery Phone Number">
            <TextField
              source="attributes.gp.surgery.phoneNumber"
              emptyText={EMPTY_TEXT}
            />
          </Labeled>
          <Labeled label="GP Surgery Email">
            <TextField
              source="attributes.gp.surgery.email"
              emptyText={EMPTY_TEXT}
            />
          </Labeled>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <GPAddressField source="attributes.gp" />
      </Grid>
    </Group>
  );
}

function EditContent() {
  return (
    <Group heading={<Heading level={2}>GP Details</Heading>}>
      <GooglePlaceAddressForm
        sourcePrefix="attributes.gp.surgery"
        type="GP Surgery"
      />
    </Group>
  );
}
