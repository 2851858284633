import { GetListParams } from "react-admin";
import { list } from "../";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

const adminEndpoint = `/ms/appointments`;

type Resource = "appointments" | "adminAppointments";

const includes = [
  "clinicalPractitioner",
  "booking",
  "booking.patientRecord",
  "booking.user",
];

async function listAppointments(params: GetListParams, resource: Resource) {
  const { filter, pagination, meta } = params;

  // Ignore request from the appointment input if service(specialty/clinicalService) isn't selected.
  // Return an empty list instead.
  if (meta?.requestSource === "AppointmentInput" && !filter.specialties && !filter.clinicalServiceId) {
    return { data: [], total: 0 };
  }

  const gender = filter.gender === "noPreference" ? "" : filter.gender;

  const query = {
    "filter[start]": filter.start || "",
    "filter[end]": filter.end || "",
    "filter[status]": filter.status || "",
    "filter[consultationType]": filter.consultationType || "",
    "filter[clinicalPractitioner.gender]": gender,
    "filter[clinicalPractitioner.specialties]":  filter.specialties || "",
    "filter[clinicalService.id]": filter.clinicalServiceId || "",
    "filter[appointmentHold.userId]": filter.userId,
    "page[number]": pagination.page,
    "page[size]": pagination.perPage,
    ignoreClientContractRequirements:
      filter.ignoreClientContractRequirements || 0,
    ignoreOpeningTimes: filter.ignoreOpeningTimes,
    ignoreStartAndEndTimeLimitations: 1,
    include: includes.join(","),
  };

  const standardEndpoint = `/clients/${filter.clientId}/appointments`;
  const endpoint =
    resource === "appointments" ? standardEndpoint : adminEndpoint;

  const queryParams = shallowlyRemoveEmptyValues(query);

  const { data, total } = await list(endpoint, { params: queryParams });

  return { data, total };
}

export default listAppointments;
