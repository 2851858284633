import { GetManyParams } from "react-admin";
import { get } from "..";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

async function getManyClients(params: GetManyParams) {
  const { ids } = params;

  const url = "/ms/clients";
  const filters = {
    "filter[id]": `in:${ids}`,
  };

  const filterParams = shallowlyRemoveEmptyValues(filters);

  const data = await get(url, filterParams);

  return data;
}

export default getManyClients;
