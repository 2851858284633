import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import {
  FieldProps,
  RaRecord,
  SaveButton,
} from "react-admin";

interface DialogProps {
  open: boolean;
  onClose: () => void;
  transform?: (record: RaRecord) => RaRecord;
}

type Props = DialogProps & FieldProps;

export function RequestOverrideModal(props: Props) {
  const { onClose, open, transform } = props;

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="request-modal-override"
      open={open}
    >
      <DialogTitle>
        This patient's details do not meet the client's validation criteria.
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          This client has validation enabled, and each patient must satisfy the
          client's requirements.
        </Typography>
        <Typography variant="body1">
          These requirements may be based on:
        </Typography>
        <List>
          <ListItem>A pre-approved list of client identifer</ListItem>
          <ListItem>A patient's data points.</ListItem>
        </List>
        <Typography variant="body1">
          Failure to meet these criteria may suggest a patient is not eligible
          to access the medical service.
        </Typography>
        <Typography variant="body1">
          You can override these rules and create the patient anyway.
        </Typography>
      </DialogContent>
      <DialogActions>
        <SaveButton
          variant="contained"
          aria-label="Override"
          color="secondary"
          label={"Override"}
          type="button"
          transform={data => {
            const dataWithValidationOverride: RaRecord = {
              ...data,
              validationOverride: true,
            };

            return transform ? transform(dataWithValidationOverride) : dataWithValidationOverride;
          }}
          data-testid="validation-override-button"
        />
        <Button variant="outlined" aria-label="Cancel" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
