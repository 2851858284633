import { create, remove, update } from "..";
import { MaintenanceWindowsDTO } from "../../types/clients";
import deepEquals from "../../utilities/deepEquals";

interface Args {
  clientId: string;
  oldMaintenanceWindows: MaintenanceWindowsDTO["data"];
  maintenanceWindows: MaintenanceWindowsDTO["data"];
}

async function updateMaintenanceWindows(args: Args) {
  const { clientId, oldMaintenanceWindows, maintenanceWindows } = args;
  const oldIds = oldMaintenanceWindows.map(({ id }) => id);
  const newIds = maintenanceWindows.map(({ id }) => id);

  const baseEndpoint = `/ms/clients/${clientId}/clientMaintenanceWindows`;
  const promises: Promise<unknown>[] = [];

  /* DELETE */
  const toDelete = oldIds.filter(id => !newIds.includes(id));

  toDelete.forEach(id => {
    const promise = remove(`${baseEndpoint}/${id}`);
    promises.push(promise);
  });

  /* CREATE */
  const toCreate = maintenanceWindows.filter(({ id }) => !id);

  toCreate.forEach(window => {
    const body = {
      data: {
        type: "clientMaintenanceWindows",
        attributes: window.attributes,
      },
    };

    const promise = create(baseEndpoint, body);
    promises.push(promise);
  });

  /* UPDATE */
  const toUpdate = oldIds.filter(id => newIds.includes(id));

  toUpdate.forEach(windowId => {
    const contract = maintenanceWindows.find(({ id }) => id === windowId)!;
    const oldContract = oldMaintenanceWindows.find(
      ({ id }) => id === windowId
    )!;

    const noChange = deepEquals(contract, oldContract);
    if (noChange) return;

    const updateContractsEndpoint = `${baseEndpoint}/${windowId}`;
    const contractBody = {
      data: {
        type: "clientMaintenanceWindows",
        id: windowId,
        attributes: contract.attributes,
      },
    };

    const promise = update(updateContractsEndpoint, contractBody);
    promises.push(promise);
  });

  return promises;
}

export default updateMaintenanceWindows;
