import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import {
  useNotify,
  useEditContext,
  useUpdate,
  useRecordContext,
} from "react-admin";
import get from "lodash/get";

export function AcceptTermsButton() {
  const record = useRecordContext();
  const notify = useNotify();
  const accepted = record?.attributes?.accepted ?? false;
  const [isAccepted, setAcceptedState] = useState(accepted);
  const context = useEditContext();
  const clientId = get(context.record, "clients[0].id");
  const userId = get(context.record, "patientId");

  const [acceptTerm, { isLoading, error, isSuccess }] = useUpdate();

  const handleClick = () => {
    acceptTerm("term", {
      data: {
        userId,
        termId: record?.id,
        clientId,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      notify("The term has been accepted");
      setAcceptedState(true);
      return;
    }

    if (error) {
      notify("There was a problem accepting the term");
      return;
    }
  }, [isSuccess, error, notify]);

  return (
    <Button
      disabled={isAccepted || isLoading}
      variant="contained"
      aria-label="Accept"
      onClick={handleClick}
    >
      Accept
    </Button>
  );
}
