import React from "react";
import { NumberField } from "react-admin";
import { ShowTabProps } from "../../../types/utilities";
import { BooleanField } from "../../fields/BooleanField";
import { Tab } from "../../Tabs";
import { Heading } from "../../ui/Heading";

export function AppointmentDetailsShowTab(props: ShowTabProps) {
  return (
    <Tab {...props} label="Appointment Creation Rules">
      <Heading level={2}>Appointment Details</Heading>
      <NumberField
        source="resourceManagementRules[0].attributes.appointmentDuration"
        label="Appointment duration"
        emptyText="n/a"
      />
      <NumberField
        source="resourceManagementRules[0].attributes.appointmentsPerHour"
        label="Appointments per hours"
        emptyText="n/a"
      />
      <NumberField
        source="attributes.contractedHoursPerWeek"
        label="Contract hours per week"
        emptyText="n/a"
      />
      <NumberField
        source="attributes.contractedOOHPerWeek"
        label="Contract OOH hours per week"
        emptyText="n/a"
      />
      <NumberField
        source="attributes.contractedHoursShoulders"
        label="Contract shoulder hours per week"
        emptyText="n/a"
      />
      <BooleanField
        source="attributes.isContractedOvernight"
        label="Can be contracted overnight"
        emptyText="n/a"
      />
    </Tab>
  );
}
