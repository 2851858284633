import { GetListParams } from "ra-core";
import { PharmacyResponse } from "../../types/healthmail";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";
import { list } from "../gaiaClient";
import { v4 as uuid } from "uuid";

async function getGaiaPharmacies(params: GetListParams) {
  const { addressName, addressCity, address } = params.filter;

  if (!addressName && !addressCity && !address) {
    return Promise.resolve({
      total: 0,
      data: []
    });
  }

  const endpoint = "healthmail/pharmacies";

  const correlationId = uuid();
  const headers = {
    "x-correlation-id": correlationId,
  };

  const queryParams = {
    addressCity,
    pageSize: 50,
    nameContains: addressName,
    addressContains: address,
  };

  const filteredParams = shallowlyRemoveEmptyValues(queryParams);

  const { data }: PharmacyResponse = await list(endpoint, {
    params: filteredParams,
    headers,
  });

  const formattedPharmacies = data.pharmacies.map(pharmacy => {
    // Full explanation about this weird-ass hack in the `getManyGaiaPharmacies` file.
    const { city, state, line } = pharmacy.address;
    const formattedName = `${pharmacy.name} (${line[0]}, ${city}, ${state})`;
    const pharmacyId = pharmacy.id;
    const stringifiedRecord = JSON.stringify(pharmacy);

    return {
      ...pharmacy,
      id: stringifiedRecord,
      pharmacyId,
      formattedName,
    };
  });

  return {
    total: data.pharmacies.length,
    data: formattedPharmacies,
  };
}

export default getGaiaPharmacies;
