import { create, update } from "..";
import { PatientManagementSystemsDTO, PmsPatientCase } from "../../types/patientManagementSystems";
import deepEquals from "../../utilities/deepEquals";

interface Args {
  pmsPatientCase: PmsPatientCase;
  oldPmsPatientCase: PmsPatientCase;
  bookingPatientManagementSystems: PatientManagementSystemsDTO["data"][] | null;
  bookingId: string;
}

async function updateBookingAssignedPmsCase(args: Args) {
  const { pmsPatientCase, oldPmsPatientCase, bookingPatientManagementSystems, bookingId } = args;

  const previouslyExists = Boolean(oldPmsPatientCase.caseId);
  const isAutomaticallyCreated = Boolean(oldPmsPatientCase.isAutomaticallyCreated);
  const noChange = deepEquals(pmsPatientCase, oldPmsPatientCase);

  if ((previouslyExists && isAutomaticallyCreated) || noChange) return null;

  const bookingPmsType = getPmsType(bookingPatientManagementSystems);

  if (previouslyExists) {
    return await updateCaseLink(pmsPatientCase, bookingPmsType, bookingId);
  } else {
    return await createCaseLink(pmsPatientCase, bookingPmsType, bookingId);
  }

}

function getPmsType(patientManagementSystems: PatientManagementSystemsDTO["data"][] | null) {
  if (Array.isArray(patientManagementSystems) && patientManagementSystems.length > 0) {
    return patientManagementSystems[0].attributes.systemType
  } else {
    return "";
  }
}

async function updateCaseLink(pmsPatientCase: PmsPatientCase, bookingPmsType: string, bookingId: string) {
  const body = {
    data: {
      id: bookingId,
      type: "manualSetPatientManagementSystemCases",
      attributes: {
        systemType: bookingPmsType,
        caseNumber: pmsPatientCase.caseNumber,
        caseReference: pmsPatientCase.caseReference,
        patientReference: pmsPatientCase.patientReference,
      },
    },
  };

  const updateEndpoint = `/ms/bookings/${bookingId}/patientManagementSystemCase`;

  return await update(updateEndpoint, body);
}

async function createCaseLink(pmsPatientCase: PmsPatientCase, bookingPmsType: string, bookingId: string) {
  const body = {
    data: {
      type: "manualSetPatientManagementSystemCases",
      attributes: {
        systemType: bookingPmsType,
        caseNumber: pmsPatientCase.caseNumber,
        caseReference: pmsPatientCase.caseReference,
        patientReference: pmsPatientCase.patientReference,
      },
    },
  };

  const createEndpoint = `/ms/bookings/${bookingId}/setPatientManagementSystemCase`;

  return await create(createEndpoint, body);
}

export default updateBookingAssignedPmsCase;
