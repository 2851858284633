import get from "lodash/get";
import { PaginationPayload } from "react-admin";
import { baseUrl, list } from "..";
import { WorkQueueItemDTO } from "../../types/workQueues";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

const workQueueItemsEndpoint = `${baseUrl}/ms/workQueueItems`;

const includes = [
  "booking",
  "client",
  "messageThread",
  "assignee",
  "booking.patientManagementSystemCase",
  "message.patientManagementSystemCase",
  "patientRecord",
  "completedBy"
];

function getMADDetails(workQueueItem: WorkQueueItemDTO["data"]) {
  const { messageThreads = [], patients = [] } = workQueueItem;
  const firstName = get(patients[0], "attributes.firstName");
  const lastName = get(patients[0], "attributes.lastName");
  const bookingTime = get(workQueueItem, "attributes.bookingTime", "");
  const appointmentTime = get(workQueueItem, "attributes.appointmentTime", "");

  return {
    patientName: `${firstName} ${lastName}`,
    bookingId: messageThreads[0]?.id,
    bookingTime: bookingTime,
    appointmentTime: appointmentTime,
  };
}

function getBookingDetails(workQueueItem: WorkQueueItemDTO["data"]) {
  const { bookings = [], patients = [] } = workQueueItem;
  const firstName = get(patients[0], "attributes.firstName");
  const lastName = get(patients[0], "attributes.lastName");
  const bookingTime = get(workQueueItem, "attributes.bookingTime", "");
  const appointmentTime = get(workQueueItem, "attributes.appointmentTime", "");

  return {
    patientName: `${firstName} ${lastName}`,
    bookingId: bookings[0]?.id,
    bookingTime: bookingTime,
    appointmentTime: appointmentTime,
  };
}

function getPatientRecordDetails(workQueueItem: WorkQueueItemDTO["data"]) {
  const { patients = [] } = workQueueItem;

  const firstName = get(patients[0], "attributes.firstName");
  const lastName = get(patients[0], "attributes.lastName");

  return {
    patientName: `${firstName} ${lastName}`
  }
}

function getDetails(workQueueItem: WorkQueueItemDTO["data"]) {
  if (workQueueItem.messageThreads) {
    return getMADDetails(workQueueItem);
  }

  if (workQueueItem.bookings) {
    return getBookingDetails(workQueueItem);
  }

  if (workQueueItem.patients) {
    return getPatientRecordDetails(workQueueItem);
  }

  return {};
}

async function listWorkQueue(params: {
  filter: any;
  pagination: PaginationPayload;
  sort: {
    field: string;
    order: string;
  };
}) {
  const { pagination, filter, sort } = params;
  const { page, perPage } = pagination;
  const sortOrder = sort.order === "DESC" ? "-" : "";
  const formatSorted = `${sortOrder}${sort.field}`;

  let itemType = filter.itemType || "";
  if (Array.isArray(filter.itemType)) {
    itemType = `in:${filter.itemType.join()}`;
  }

  const query = {
    "page[number]": page,
    "page[size]": perPage,
    "filter[itemType]": itemType,
    "filter[status]": filter.status || "in:Assigned,Unassigned",
    "filter[assignedToUserId]": filter.assignedToUserId || "",
    "filter[booking.id]": filter.bookingId || "",
    "filter[booking.caseNumber]": filter.caseNumber || "",
    "filter[messageThread.id]": filter.threadId || "",
    "filter[completedAt]": filter.completedAt || "",
    sort: formatSorted,
  };

  const queryParams = shallowlyRemoveEmptyValues(query);
  queryParams.include = includes.join(",");
  const { data, total } = await list(workQueueItemsEndpoint, {
    params: queryParams,
  });

  const appendedData = data.map((item: WorkQueueItemDTO["data"]) => {
    const bookingDetails = getDetails(item);

    return {
      ...item,
      bookingDetails,
    };
  });

  return { data: appendedData, total };
}

export default listWorkQueue;
