import { Box, Button, Chip } from "@mui/material";
import { FunctionField, ListContextProvider, RaRecord, useList } from "react-admin";
import { ClinicalProductsChange } from "../../../types/clinicalProducts";
import Typography from "@mui/material/Typography";
import React from "react";
import { ArrayField, Datagrid, SingleFieldList } from "ra-ui-materialui";
import { ChangeAction } from "../../../types/common";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import { BooleanField } from "../../fields/BooleanField";
import { getStringDate } from "../helpers/getStringDate";

type ClinicalProductChangesTableInputProps = {
  onResetChangesClicked: () => void;
  onUndoClicked: (change: ClinicalProductsChange) => void;
  data: ClinicalProductsChange[];
};

const actionIconsSx = { marginRight: "0.2em" };

const ActionIcons = [
  <AddIcon sx={actionIconsSx} key={"addIcon"} />,
  <EditIcon sx={actionIconsSx} key={"editIcon"} />,
  <RemoveIcon sx={actionIconsSx} key={"removeIcon"} />
];
const actionColors = ["#ccf8d6", "#f8eed0", "#ffd6d6"];


export const ClinicalProductChangesTableInput = (props: ClinicalProductChangesTableInputProps) => {
  const changesListContext = useList({ data: props.data });

  if(!props.data.length) return null;

  return (
    <Box>
      {props.data.length > 0 ?
        <Box display="flex" justifyContent={"space-between"} marginTop={"20px"}>
          <Typography variant={"h4"}>
            Pending Changes
          </Typography>
          <Button
            data-testid={"resetChangesButton"}
            sx={{ marginTop: "10px" }}
            variant={"outlined"}
            onClick={props.onResetChangesClicked}
            size={`small`}
          >
            Reset changes
          </Button>
        </Box> : null
      }
      <ListContextProvider value={changesListContext}>
        <Datagrid
          bulkActionButtons={false}
          title={"Changes"}
          sx={{
            "& .RaDatagrid-row td:last-child": { textAlign: "right" },
          }}
        >
          <FunctionField label="Name" render={(rec: ClinicalProductsChange) =>
            <Box>
              {rec.action === ChangeAction.Edited && rec.payload.attributes.name !== rec.oldPayload?.attributes.name &&
                <Typography
                  component="span"
                  variant="body1"
                  sx={{ textDecoration: "line-through", marginRight: "5px" }}
                >
                  {rec.oldPayload?.attributes.name}
                </Typography>}
              <Typography
                component="span"
                variant="body1"
              >
                {rec.payload.attributes.name}
              </Typography>
            </Box>
          } />
          <FunctionField label="Code" render={(rec: ClinicalProductsChange) =>
            <Box>
              <Typography
                component="span"
                variant="body1"
              >
                {rec.payload.attributes.code}
              </Typography>
            </Box>
          } />
          <BooleanField
            label="Enabled"
            source="payload.attributes.isEnabled"
            sortable={false}
          />
          <FunctionField
            render={(rec: RaRecord) => {
              const {day, month} = rec.payload.attributes.maxUsageTimeFrame;
              return getStringDate(day, month)
            }}
            label="Allowance Renewal Date"
            emptyText="Not specified"
            hideLabel
            sortable={false}
          />
          <ArrayField label={"Services"} source="payload.attributes.services" sortable={false}>
            <SingleFieldList sx={{ padding: "10px 0", maxWidth: "300px" }} linkType={false}>
              <FunctionField label="Services" render={(rec: any) =>
                <Chip
                  sx={{margin: "4px" }}
                  label={`${rec.name} - ${rec.maxUsageCount ?? "Unlimited"}`}
                />
              }/>
            </SingleFieldList>
          </ArrayField>
          <FunctionField label="Action" render={(rec: ClinicalProductsChange) =>
            <Box
              display={"flex"}
              sx={{
                backgroundColor: actionColors[rec.action],
                borderRadius: "7px",
                width: "100px",
                padding: "5px",
              }}
            >
              {ActionIcons[rec.action]}
              {ChangeAction[rec.action]}
            </Box>} />
          <FunctionField
            render={(rec: ClinicalProductsChange) =>
              <Button
                data-testid={"undoButton"}
                variant={"contained"}
                onClick={() => props.onUndoClicked(rec)}
                size={"small"}
              >
                Undo
              </Button>}
          />
        </Datagrid>
      </ListContextProvider>
    </Box>
  );
}
