import React from "react";
import { Title, useRecordContext } from "react-admin";

export function PageTitle() {
  const record = useRecordContext();

  if (!record) return <Title title=" " />;

  const name = `${record?.attributes?.firstName} ${record?.attributes?.lastName}`;

  return <Title title={name} />;
}
