import React, { ChangeEvent, useState } from "react";

import {
  useCreate,
  useCreateSuggestionContext,
  useNotify,
  useRefresh,
  useRecordContext,
} from "react-admin";

import { Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import { Button } from "../buttons/Button";

export const CreateMasterClient = () => {
  const record = useRecordContext();
  const { id, masterClientGroup } = record;

  const { onCancel, filter } = useCreateSuggestionContext();
  const [newClientMasterName, setClientMasterName] = useState(filter);

  const notify = useNotify();
  const refresh = useRefresh();

  const [create, { error }] = useCreate();

  const handleClick = () => {
    create(
      "clientGroups",
      {
        data: {
          name: newClientMasterName,
          clientId: id,
          masterClientGroup,
          notify,
        },
      },
      {
        onSuccess: () => {
          notify("Master Client added successfully");
          refresh();
          onCancel();
        },
        onError: () => {
          notify((error as Error).message);
        },
      }
    );
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setClientMasterName(e.target.value);

  return (
    <Dialog open onClose={onCancel}>
      <DialogContent>
        <TextField
          label="New Master Client"
          value={newClientMasterName}
          onChange={handleChange}
          autoFocus
          name="newMasterClientInput"
          sx={{
            "& > div": {
              width: "260px",
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>
        <Button
          aria-label="Create master client"
          onClick={handleClick}
          disabled={!newClientMasterName}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
