import {
  RaRecord,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  Title,
  useRecordContext,
  ChipFieldProps,
  Link,
  sanitizeFieldRestProps
} from "react-admin";
import get from "lodash/get";
import React from "react";
import { Actions } from "../Actions";
import { ArrayField, NumberField } from "ra-ui-materialui";
import { FunctionField } from "ra-ui-materialui";
import { Heading } from "../ui/Heading";
import { Chip } from "@mui/material";

function PageTitle() {
  const record = useRecordContext();
  const name = get(record, "attributes.name");

  return <Title title={name} />;
}

export function ClinicalServiceShow() {
  return (
    <Show
      actions={<Actions />}
      title={<PageTitle />}
    >
      <SimpleShowLayout>
        <Heading level={2}>General Details</Heading>
        <TextField
          label="Name"
          source="attributes.name"
        />
        <TextField
          label="Delivery Type"
          source="attributes.deliveryType"
          sortable={false}
        />
        <TextField
          label="PMS Code"
          source="attributes.pmsCode"
        />
        <FunctionField
          label="Appointment Duration"
          render={(record: RaRecord) => {
            if (record.attributes.deliveryType === "Asynchronous") return null;

            return record.attributes.appointmentDuration;
          }} />
        <ReferenceField
          source="attributes.shiftTypeId"
          reference="quinyxShiftTypes"
          label="Shift Type"
          link={false}
          emptyText="Not provided"
        >
          <TextField
            source="attributes.name"
          />
        </ReferenceField>
        <ArrayField
          label="Resource Pools"
          source="resourcePools"
        >
          <SingleFieldList
            sx={{ margin: "5px 0px" }}>
            <ResourcePoolLink sx={{ marginRight: "5px" }} />
          </SingleFieldList>
        </ArrayField>
        <FunctionField
          label="Notifications Enabled"
          render={(record: RaRecord) => {
            return record.attributes.notificationsEnabled ? "Yes" : "No";
          }} />

        <Heading level={2}>Release Settings</Heading>
        <FunctionField
          label="Release Settings Enabled"
          render={(record: RaRecord) => {
            return record.attributes.canBeReleased ? "Yes" : "No";
          }} />

        <NumberField
          label="Release Threshold"
          source="attributes.releaseThreshold"
          emptyText="Not provided"
        />
        <ReferenceField
          source="attributes.fallbackServiceId"
          reference="clinicalServices"
          link={(record: RaRecord) => `/clinicalServices/${record.attributes.fallbackServiceId}/show`}
          emptyText="Not provided"
          label="Fallback Clinical Service"
        >
          <TextField
            source="attributes.name"
          />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  )
}


function ResourcePoolLink(props: ChipFieldProps) {
  const record = useRecordContext();
  const id = get(record, "id");
  const name = get(record, "attributes.name");

  // The MUI Chip requires an onClick handler to behave like a clickable element.
  // eslint-disable-next-line lodash/prefer-noop, @typescript-eslint/no-empty-function
  const handleClick = () => {};

  return <Link to={`/resourcePools/${id}/show`}>
    <Chip
      label={name}
      onClick={handleClick}
      component="span"
      variant="body1"
      {...sanitizeFieldRestProps(props)} />
  </Link>
}