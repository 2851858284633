import { create as sapiCreate } from "..";
import { create as gaiaCreate } from "../gaiaClient";
import { ClinicalPractitionerDocuments } from "../../types/clinicalPractitioners";
import { getConfig } from "../../config";
import { HttpError } from "react-admin";

interface Args {
  id: string;
  userId: string;
  documents: ClinicalPractitionerDocuments[];
}

const config = getConfig();

async function uploadClinicalPractitionerDocumentsInSapi(args: Args) {
  const { documents, userId, id } = args;

  const requests: Promise<unknown>[] = [];
  const fileEndpoint = `/ms/users/${userId}/fileUpload`;
  const url = `/ms/clinicalPractitioners/${id}/attachments`;

  for await (const document of documents) {
    if (!document || !document.file?.rawFile) continue;

    const file = document.file.rawFile;
    const formData = new FormData();

    formData.append("file", file);
    formData.append("filename", file.name);

    const fileRes = await sapiCreate(fileEndpoint, formData);

    const documentBody = {
      data: {
        type: "clinicalPractitionerAttachments",
        attributes: {
          description: file.name,
          clinicalPractitionerAttachmentType:
            document.attributes.cpDocumentType,
        },
        relationships: {
          file: {
            data: {
              id: fileRes.data.id,
              type: "files",
            },
          },
        },
      },
    };

    const createRequest = sapiCreate(url, documentBody);
    requests.push(createRequest);
  }

  await Promise.all(requests);

  return {
    data: {
      id: "id-to-satisfy-ra",
    },
  };
}

async function uploadClinicalPractitionerDocumentsInGaia(args: Args) {
  const { documents, userId, id } = args;

  for await (const document of documents) {
    if (!document || !document.file?.rawFile) continue;

    const file = document.file.rawFile;
    const formData = new FormData();

    formData.append("file", file);
    formData.append("filename", file.name);

    const fileEndpoint = `/clinical-practitioners/${id}/documents/${document.attributes.cpDocumentType}/upload`;

    try {
      await gaiaCreate(fileEndpoint, formData);
    }
    catch (error: any) {
      if (error instanceof HttpError && error.status === 404) {
        throw new HttpError("FHIR record is not found for practitioner", error.status, error.body);
      }

      throw error;
    }
  }

  return {
    data: {
      id: "id-to-satisfy-ra",
    },
  };
}

export default config.featureFlags.useGaiaForCpDocuments ? uploadClinicalPractitionerDocumentsInGaia : uploadClinicalPractitionerDocumentsInSapi;
