import React from "react";
import { styled } from "@mui/material/styles";
import { useGetList } from "ra-core";
import { Alert } from "@mui/material";
import { MarkdownField } from "./fields/MarkdownField";
import { NeutralColors, WarningColors } from "../theme";

const PREFIX = "InstructionAside";

const classes = {
  alert: `${PREFIX}-alert`,
};

const Root = styled("aside")({
  ['']: {
    position: "relative"
  },
  ['& .sticky']: {
    position: "sticky",
    top: "64px"
  },
  [`& .${classes.alert}`]: {
    maxWidth: "32rem",
    marginLeft: "1rem",
    padding: "0.5rem 1rem 1rem",
    height: "fit-content",
    marginBottom: "1rem",
    color: NeutralColors.Black,
    backgroundColor: WarningColors.LightYellow
  },
  ['& .mde-preview-content']: {
    padding: 0,
  },
  ['& .mde-preview .mde-preview-content p']: {
    marginBottom: 0
  },
});

interface Props {
  clientId?: string;
}

export function InstructionAside(props: Props) {
  const { clientId } = props;
  const { data } = useGetList(
    "instructions",
    {
      pagination: { page: 1, perPage: 25 },
      sort: { field: "ID", order: "DESC" },
      filter: { instructionType: "CSO", clientId, isEnabled: true },
    },
    { enabled: Boolean(clientId) }
  );

  if (!data?.length) return null;

  const instructions = data.reverse().map(instruction => (
    <Alert className={classes.alert} key={instruction.id} severity={"warning"}>
      <MarkdownField record={instruction} source="attributes.instructions" />
    </Alert>
  ));

  return <Root>
    <div className="sticky">
      {instructions}
    </div>
  </Root>;
}
