import React from "react";
import { Button } from "@mui/material";
import { Pharmacy } from "../../../types/patients";
import { VerifiedPharmacyAddressField } from "../../fields/VerifiedPharmacyAddressField";

interface Props {
  selectedAddress: Pharmacy | null | undefined;
  onRemoveAddressClick: () => void;
}

export function SelectedAddressFields(props: Props) {
  const { selectedAddress, onRemoveAddressClick } = props;
  const isPharmacyHealthmailVerified = Boolean(selectedAddress?.organisationId);

  return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <VerifiedPharmacyAddressField
          label="Selected pharmacy"
          isVerifiedPharmacy={isPharmacyHealthmailVerified}
          pharmacy={selectedAddress}
        />
        <Button
          sx={{ marginTop: "8px", marginBottom: "4px", alignSelf: "flex-start" }}
          onClick={onRemoveAddressClick}
          aria-label="Remove pharmacy"
          variant="outlined"
          disabled={!selectedAddress}
        >
          Remove pharmacy
        </Button>
      </div>
  );
}