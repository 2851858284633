import React from "react";
import {
  EditButton,
  useListContext,
  ShowButton,
  useRecordContext,
  useResourceContext,
  TopToolbar,
} from "react-admin";
import { useACL } from "ra-access-control-lists";
import { CreateButton } from "./CreateButton";

export function Actions() {
  const record = useRecordContext();
  const resource = useResourceContext();
  const { edit: canEdit } = useACL(resource);

  return <TopToolbar>{canEdit && <EditButton record={record} />}</TopToolbar>;
}

export function EditActions() {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <ShowButton record={record} label="View Details" />
    </TopToolbar>
  );
}

export type ExtendedListActionsProps = {
  createLabel?: string;
  initialValues?: any;
  additionalActions?: React.ReactNode;
};

export function ListActions(props: ExtendedListActionsProps) {
  const { filterValues, resource } = useListContext();
  const { createLabel = "", initialValues = {}, additionalActions } = props;

  return (
    <TopToolbar>
      {additionalActions ?? null}
      <CreateButton
        initialValues={{
          ...filterValues,
          ...initialValues,
        }}
        size="medium"
        resource={resource}
        label={createLabel}
      />
    </TopToolbar>
  );
}
