import React from "react";
import { TimeInput } from "../inputs/TimeInput";

export function BookingTime() {
  return (
    <TimeInput
      sx={{
        display: "block",
        width: "260px",
        "& > div": {
          width: "260px",
        },
      }}
      label="Show appointments from"
      source="selectedTime"
    />
  );
}
