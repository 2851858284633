import { GetOneParams } from "react-admin";
import { get } from "../";
import lodashGet from "lodash/get";
import getEvents from "./getBookingEvents";
import { PmsPatientCase } from "../../types/patientManagementSystems";
import getAssignedPMS from "../clients/getAssignedPMS";

const includes = [
  "user",
  "client",
  "appointment",
  "appointment.clinicalPractitioner",
  "attachments",
  "attachments.file",
  "consultation",
  "consultation.outputs",
  "consultation.outputs.file",
  "consultation.recordings",
  "consultation.recordings.file",
  "patientRecord",
  "patientManagementSystemCase",
  "patientRecord.patientManagementSystemPatientRecords",
  "patientManagementSystem",
];

async function getBooking(params: GetOneParams) {
  const [bookingId, userId, clientId] = String(params.id).split(":");

  const getBookingEndpoint = `/clients/${clientId}/users/${userId}/bookings/${bookingId}`;

  const query = {
    include: includes.join(","),
  };

  /* BOOKING DETAILS */
  const { data } = await get(getBookingEndpoint, query);

  const booking = {
    data: data[0],
  };

  /* BOOKING EVENTS */
  const consultationId = booking.data.consultations[0].id;

  const eventsReq = getEvents({
    clientId,
    userId,
    bookingId,
    consultationId,
  });

  /* COMPLETION DETAILS */

  const getCompletionDetailsEndpoint = `/ms/consultations/${consultationId}/completiondetails?include=quality&include=outcome`;

  const completionDetailsReq = get(getCompletionDetailsEndpoint);

  const clientPmsReq = await getAssignedPMS({clientId});


  const [events, completionDetails, clientPms] = await Promise.all([
    eventsReq,
    completionDetailsReq,
    clientPmsReq
  ]);

  booking.data.clients[0].assignedPMS = clientPms;


  const existingOutputs = lodashGet(
    booking,
    "data.consultations[0].consultationOutputs",
    []
  );

  booking.data.events = events;
  booking.data.existingOutputs = existingOutputs;
  booking.data.followUpOutputs = [];
  booking.data.completionDetails = completionDetails.data;

  // Booking PMS data for UI layer
  booking.data.pmsPatientCase = getPmsPatientCaseFromBooking(booking);
  booking.data.oldPmsPatientCase = booking.data.pmsPatientCase;

  // explanation in README#getOne-data-fetching
  booking.data.bookingId = booking.data.id;
  booking.data.id = params.id;

  return booking;
}

function getPmsPatientCaseFromBooking(booking: any) : PmsPatientCase {
  const patientAttributes = lodashGet(booking, "data.patients[0].patientManagementSystemPatients[0].attributes");
  const pmsCase = lodashGet(booking, "data.patientManagementSystemCases[0]");

  return {
    caseId: pmsCase?.id,
    caseNumber: pmsCase?.attributes?.caseNumber,
    caseReference: pmsCase?.attributes?.caseReference,
    patientReference: patientAttributes?.patientReference,
    isAutomaticallyCreated: pmsCase?.attributes?.isAutomaticallyCreated,
  };
}

export default getBooking;
