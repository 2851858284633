import { GetListParams } from "ra-core";
import axios, { AxiosRequestConfig } from "axios";
import { list, interceptNetworkRequests } from "..";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";
import { INPUT_TIME_INVALID_VALUE } from "../../constants/appointments";

const endpoint = "/ms/resourceManagement/shifts";

async function listShifts(params: GetListParams) {
  const { sort, pagination, filter } = params;
  const { page, perPage } = pagination;
  const { start, clinicalPractitioner, end, status, ignoreWithoutFilters, startDay, source } = filter;

  if (!clinicalPractitioner && !status && !start && ignoreWithoutFilters) {
    return {
      data: [],
      total: 0,
    };
  }

  const sortOrder = sort.order === "DESC" ? "-" : "";
  const formatSorted = `${sortOrder}${sort.field}`;

  const startTime = start ? `ge:${start}` : "";
  const endTime = end ? `le:${end}` : "";

  const ctSource = axios.CancelToken.source();

  const shouldCancel = (clientConfig: AxiosRequestConfig): boolean => {
    const getOneShiftRegex = new RegExp("ms/resourceManagement/shifts/[0-9]+?");
    const url = clientConfig.url ?? "";
    return getOneShiftRegex.test(url);
  };

  const disconnectInterceptor = interceptNetworkRequests(async clientConfig => {
    if (shouldCancel(clientConfig)) {
      disconnectInterceptor();
      ctSource.cancel();
    }

    return clientConfig;
  });

  const query = {
    "page[number]": page,
    "page[size]": perPage,
    sort: formatSorted,
    include: "clinicalPractitioner",
    "filter[clinicalPractitioner.id]": clinicalPractitioner,
    "filter[start]": start !== INPUT_TIME_INVALID_VALUE ? startTime : "",
    "filter[end]": end !== INPUT_TIME_INVALID_VALUE ? endTime : "",
    "filter[status]":  status ? `in:${status}` : '',
    "startDay": startDay ?? '',
    "filter[shiftSource]": source ?? ''
  };

  const queryParams = shallowlyRemoveEmptyValues(query);

  const { data, total } = await list(endpoint, {
    params: queryParams,
    cancelToken: ctSource.token,
  });

  disconnectInterceptor();

  return { data, total };
}

export default listShifts;
