import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useListContext, useNotify } from "ra-core";
import React, { useState } from "react";
import { Colors } from "../../theme";
import { AppointmentStatus } from "../../types/appointments";
import { Button } from "../buttons/Button";
import { useDeleteMany } from "react-admin";
import { Box } from "@mui/material";

const buttonWrapperStyles = {
  backgroundColor: `${Colors.LightGrey}`,
  position: "sticky",
  bottom: 0,
  width: "100%",
  padding: "16px",
};
const CANCELLABLE_STATES: AppointmentStatus[] = ["Nonworking", "Available"];

export function CancelAppointmentsButton() {
  const { isLoading, selectedIds } = useListContext();
  const [isModalOpen, setModalOpenState] = useState(false);

  const isDisabled = !selectedIds.length;

  if (isLoading) return null;

  const closeModal = () => setModalOpenState(false);
  const openModal = () => setModalOpenState(true);

  return (
    <Box sx={buttonWrapperStyles}>
      <CancelAppointmentsModal isOpen={isModalOpen} handleClose={closeModal} />
      <Button
        onClick={openModal}
        disabled={isDisabled}
        aria-label="Cancel Appointments"
      >
        Cancel Appointments
      </Button>
    </Box>
  );
}

interface ModalProps {
  isOpen: boolean;
  handleClose: VoidFunction;
}

function CancelAppointmentsModal(props: ModalProps) {
  const { handleClose, isOpen } = props;
  const { selectedIds, data, onUnselectItems } = useListContext();
  const notify = useNotify();

  const selectedShifts = data.filter(shift => selectedIds.includes(shift.id));
  const appointments = selectedShifts.map(shift => shift.appointments[0]);

  const cancellableAppointments = appointments.filter(apt => {
    const { status } = apt.attributes;
    return CANCELLABLE_STATES.includes(status);
  });
  const cancellableAptsIds = cancellableAppointments.map(({ id }) => id);

  const bookedAppointments = appointments.filter(apt => {
    const { status } = apt.attributes;
    return status === "Booked";
  });

  const [deleteMany] = useDeleteMany(
    "proposedAppointments",
    { ids: cancellableAptsIds, meta: { previousData: data } },
    {
      mutationMode: "undoable",
      onSuccess: () => {
        handleClose();
        onUnselectItems();
        notify(`You've cancelled ${cancellableAptsIds.length} appointments`, {
          type: "info",
          undoable: true,
        });
      },
    }
  );

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <DialogTitle>Cancel Appointments</DialogTitle>
      <DialogContent>
        {bookedAppointments.length ? (
          <Typography gutterBottom>
            Note: There are {bookedAppointments.length} booked appointments that
            you will need reschedule or cancel.
          </Typography>
        ) : null}
        {cancellableAptsIds.length ? (
          <Typography gutterBottom>
            Are you sure you want to cancel {cancellableAptsIds.length}{" "}
            appointments?
          </Typography>
        ) : (
          <Typography gutterBottom>
            None of the chosen appointments can be cancelled.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Back
        </Button>
        <Button
          variant="contained"
          disabled={!cancellableAptsIds.length}
          aria-label="Confirm Cancel Appointments"
          onClick={() => deleteMany()}
          color="secondary"
        >
          Cancel Appointments
        </Button>
      </DialogActions>
    </Dialog>
  );
}
