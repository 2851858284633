import React, { useState } from "react";
import { Create, SimpleForm, ReferenceInput } from "ra-ui-materialui";
import { Heading } from "../ui/Heading";
import { DateTimeInput } from "../inputs/DateTimeInput";
import validateCreateShift from "./validateCreateShift";
import { FormIteratorAddButton } from "../form/FormIteratorAddButton";
import { FormIteratorRemoveButton } from "../form/FormIteratorRemoveButton";
import { RaRecord, required, useNotify, useRedirect } from "ra-core";
import { ShiftPreferencesInput } from "../inputs/ShiftPreferencesInputs/ShiftPreferencesInputs";
import dayjs from "dayjs";
import { ArrayInput } from "../inputs/ArrayInput";
import { SimpleFormIterator } from "../form/SimpleFormIterator";
import AutocompleteInput from "../inputs/AutocompleteInput";
import getShouldOptimiseApts from "./helpers/getShouldOptimiseApts";
import { useChoicesContext } from "react-admin";

const arrayInputStyles = {
  "& .RaSimpleFormIterator-line": {
    "& .RaSimpleFormIterator-form > div:first-of-type": {
      mr: 2,
    },
  },
};
export function ShiftCreate() {
  const notify = useNotify();
  const redirect = useRedirect();

  function onSuccess(record: RaRecord) {
    notify("Shift was created successfully");
    redirect(`/shifts/${record.id}/show`);
  }

  const hourFromNow = dayjs().add(1, "hour").format();

  const [selectedClinicalService, setSelectedClinicalService] = useState<any>(null);

  const shouldCreateAppointments = selectedClinicalService?.attributes?.deliveryType != "Asynchronous";

  return (
    <Create
      mutationOptions={{
        onSuccess,
      }}
      title="Create Shift"
      transform={(record: RaRecord) => {
        const newRecord = { ...record };

        const shouldOptimiseApts = getShouldOptimiseApts(newRecord);

        if (shouldOptimiseApts) {
          newRecord.appointmentsPerHour = null;
        }

        // replace empty string with null
        const clinicalServiceId = newRecord.clinicalServiceId;
        newRecord.clinicalServiceId = clinicalServiceId ? clinicalServiceId : null;

        return newRecord;
      }}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        validate={values => validateCreateShift(values, shouldCreateAppointments)}
        mode="onChange"
      >
        <Heading level={2}>Shift Details</Heading>
        <ReferenceInput
          source="clinicalPractitioner"
          reference="clinicalPractitioners"
          required
        >
          <AutocompleteInput
            label="Clinical Practitioner"
            filterToQuery={query => ({
              fullName: query,
            })}
            placeholder="Search by CP name..."
            source="id"
            optionText="attributes.fullName"
          />
        </ReferenceInput>
        <ReferenceInput
          source="clinicalServiceId"
          reference="clinicalServices"
        >
          <ClinicalServiceInput setClinicalService={setSelectedClinicalService}/>
        </ReferenceInput>
        <DateTimeInput
          label="Start"
          source="start"
          validate={[required()]}
          hasHelperText
        />
        <DateTimeInput
          label="End"
          source="end"
          minDate={hourFromNow}
          validate={[required()]}
          hasHelperText
        />
        <ArrayInput
          defaultValue={[]}
          source="breaks"
          label="Breaks"
          level={3}
          sx={arrayInputStyles}
        >
          <SimpleFormIterator
            addButton={<FormIteratorAddButton label="Add break" />}
            removeButton={<FormIteratorRemoveButton label="Remove break" />}
          >
            <DateTimeInput
              label="Start"
              source="start"
              hasHelperText
              validate={[required()]}
            />
            <DateTimeInput
              label="End"
              source="end"
              hasHelperText
              validate={[required()]}
            />
          </SimpleFormIterator>
        </ArrayInput>
        { shouldCreateAppointments && <ShiftPreferencesInput baseSourcePath="" />}
      </SimpleForm>
    </Create>
  );
}


interface ClinicalServiceInputProps {
  setClinicalService: any;
}

function ClinicalServiceInput(props: ClinicalServiceInputProps) {
  const { availableChoices } = useChoicesContext();

  return (
    <AutocompleteInput
      onChange={id => props.setClinicalService(availableChoices.find(x => x.id === id))}
      label="Clinical service"
      filterToQuery={query => ({
        name: query,
      })}
      placeholder="Search by name..."
      source="id"
      optionText="attributes.name"
    />
  )
}
