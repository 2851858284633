import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, ChangeEvent } from "react";
import { SelectInput, RaRecord } from "react-admin";
import { useFormContext } from "react-hook-form";
import { messageTypeResponse } from "../../constants/constants";
import {
  closingPhrase,
  rejectionFieldsName,
  responseMessages,
  safeguardingRejectionMessages,
} from "../../constants/responseTemplates";
import { MultilineInput } from "../inputs/MultilineInput";
import { Colors } from "../../theme";
import { ClientDTO } from "../../types/clients";

const PREFIX = "MessageOptionsField";

const classes = {
  inputsGroup: `${PREFIX}-inputsGroup`,
  button: `${PREFIX}-button`,
  dialog: `${PREFIX}-dialog`,
  inputIcon: `${PREFIX}-inputIcon`,
  textField: `${PREFIX}-textField`,
};

const Root = styled("div")(() => ({
  [`&.${classes.inputsGroup}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.button}`]: {
    marginBottom: "20px",
  },

  [`& .${classes.dialog}`]: {
    margin: "20px 0",
    cursor: "pointer",
    borderBottom: "1px dashed grey",
  },

  [`& .${classes.inputIcon}`]: {
    color: Colors.Grey,
  },

  [`& .${classes.textField}`]: {
    "& > div": {
      paddingRight: "30px",
    },
  },
}));

const closeIconStyles = {
  position: "absolute",
  top: "4px",
  right: "4px",
  cursor: "pointer",
  height: "100%",
};

const getConfirmText = (clientName: string) => {
  return `Thank you for using the ${clientName} Message Doctor Service, provided by HealthHero.

Please find the GP’s response to your health query below:
  
[Paste response from CP here]
  
We hope this information is helpful. If you have further questions regarding this response, please make an appointment for a consultation. Alternatively you may wish to contact your own GP.`;
};

const RejectionTypes = {
  SAFEGUARDING: "Select safeguarding or rejection statement",
  RESPONSEMESSAGES: "Select response",
  CLOSINGPHRASE: "Select a closing phrase",
};

const rejectTemplates = {
  [RejectionTypes.SAFEGUARDING]: safeguardingRejectionMessages,
  [RejectionTypes.RESPONSEMESSAGES]: responseMessages,
  [RejectionTypes.CLOSINGPHRASE]: closingPhrase,
};

interface Props {
  clients: ClientDTO["data"][];
}

interface FieldProps {
  id: string;
  name: string;
}

type MessageType = "reject" | "confirm";

const defaultRejectionTemplate = {
  firstStatement: "",
  response: "",
  closingPhrase: "",
};

type RejectionTemplate = typeof defaultRejectionTemplate;

export const MessageOptionsField = ({ clients }: Props) => {
  const form = useFormContext();

  const [messageType, setMessageType] = useState<MessageType>("confirm");
  const [rejectionMessageType, setRejectionMessageType] = useState<
    string | null
  >("");
  const [rejectionMessageTemplate, setRejectionMessageTemplate] =
    useState<RejectionTemplate>(defaultRejectionTemplate);

  const clientName = clients[0].attributes.name;

  const updateMessage = (rejectionMessage: RejectionTemplate) => {
    form.setValue(
      "message",
      `Thank you for using the ${clientName} Message Doctor Service, provided by HealthHero.
      
      ${rejectionMessage.firstStatement || ""} 

      ${rejectionMessage.response || ""} 
      
      ${rejectionMessage.closingPhrase || ""}`
    );
  };

  const handleSelectMessageType = (
    e: ChangeEvent<HTMLInputElement> | RaRecord
  ) => {
    const value = e.target.value as MessageType;

    setMessageType(value);
    if (value === "confirm") {
      form.setValue("message", getConfirmText(clientName));
    } else {
      handleClearMessage();
    }
  };

  const handleOpenDialog = (type: string) => () => {
    setRejectionMessageType(type);
  };
  const handleCloseDialog = () => setRejectionMessageType(null);
  const handleClearMessage = () => form.setValue("message", "");

  const selectRejectionMessage = (msg: string) => () => {
    const updatedMessage = {
      ...rejectionMessageTemplate,
    };

    if (rejectionMessageType === RejectionTypes.SAFEGUARDING) {
      updatedMessage.firstStatement = msg;
    }
    if (rejectionMessageType === RejectionTypes.RESPONSEMESSAGES) {
      updatedMessage.response = msg;
    }
    if (rejectionMessageType === RejectionTypes.CLOSINGPHRASE) {
      updatedMessage.closingPhrase = msg;
    }

    updateMessage(updatedMessage);
    setRejectionMessageTemplate(updatedMessage);
    handleCloseDialog();
  };

  return (
    <Root className={classes.inputsGroup}>
      <SelectInput
        label="Message Type"
        source="messageType"
        id="messageType"
        onChange={handleSelectMessageType}
        choices={messageTypeResponse}
      />
      {messageType === "reject" && (
        <>
          {rejectionFieldsName.map((fieldProps: FieldProps) => (
            <Button
              variant="contained"
              className={classes.button}
              aria-label={fieldProps.id}
              onClick={handleOpenDialog(fieldProps.name)}
              key={fieldProps.id}
            >
              {fieldProps.name}
            </Button>
          ))}
          <Dialog open={!!rejectionMessageType} onClose={handleCloseDialog}>
            <DialogTitle>Responses</DialogTitle>
            <DialogContent id="responses-content">
              {rejectionMessageType
                ? rejectTemplates[rejectionMessageType].map((msg: string) => (
                    <div
                      className={`${classes.dialog} dialog-message`}
                      key={msg}
                    >
                      <p onClick={selectRejectionMessage(msg)}>{msg}</p>
                    </div>
                  ))
                : null}
            </DialogContent>
          </Dialog>
        </>
      )}
      <MultilineInput
        className={classes.textField}
        id="message"
        name="message"
        label="Message"
        variant="outlined"
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              id="clearMessage"
              sx={closeIconStyles}
            >
              <CloseIcon
                className={classes.inputIcon}
                onClick={handleClearMessage}
              />
            </InputAdornment>
          ),
        }}
      />
    </Root>
  );
};
