import { GetOneParams } from "react-admin";
import { get as sapiGet } from "../";
import { get as gaiaGet } from "../gaiaClient";
import { formatClinicalPractitioner } from "./helpers/formatClinicalPractitioner";
import { getConfig } from "../../config";

const config = getConfig();

const includes = [
  "expertiseAreas",
  "resourceManagementRules",
  "clinicalPractitionerAttachments",
  "clinicalPractitionerAttachments.file",
  "resourcePools",
  "registrations"
];

async function getClinicalPractitionerFromSapi(params: GetOneParams) {
  const { id } = params;
  const getCPEndpoint = `/ms/clinicalPractitioners/${id}`;

  const queryParams = {
    include: includes.join(","),
  };

  const { data } = await sapiGet(getCPEndpoint, queryParams);

  return Promise.resolve(formatClinicalPractitioner(data[0] || data));
}

async function getClinicalPractitionerFromGaia(params: GetOneParams) {

  const { id } = params;
  const getCPEndpoint = `/clinical-practitioners/${id}`;

  const queryParams = {
    include: includes
  };
  
  const { data } = await gaiaGet(getCPEndpoint, { params: queryParams }, true);

  return Promise.resolve(formatClinicalPractitioner(data[0] || data));
}

export default config.featureFlags.useGaiaForCpDocuments ? getClinicalPractitionerFromGaia : getClinicalPractitionerFromSapi;
