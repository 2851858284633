import { baseUrl, create } from "../";
import {
  NotificationChannel,
  NotificationType,
} from "../../types/notifications";
import { defaultSendGridIds } from "./constants";
import { createNotificationSettings } from "./createNotificationSettings";
import { createSendGridTemplates } from "./createSendGridTemplates";
import updateNotificationChannelSettings from "./updateNotificationChannelSettings";
import updateNotificationTypeSettings from "./updateNotificationTypeSettings";

interface Body {
  name: string;
}

async function createClient(body: Body) {
  const createClientEndpoint = `${baseUrl}/ms/clients`;

  const createClientRecordBody = {
    data: {
      type: "adminClients",
      attributes: {
        name: body.name,
        imcgpRequired: true,
        gmcgpRequired: true,
        clientIdentifierRequired: true
      },
    },
  };

  const clientRes = await create(createClientEndpoint, createClientRecordBody);

  const { id, attributes } = clientRes.data;

  await createInitialNotifications(id, attributes.name);

  return clientRes;
}

async function createInitialNotifications(clientId: string, clientName: string) {
  const defaultNotificationSetting = {
    emailFromAddress: "gpservices@healthhero.com",
    emailFromName: "GP Services",
    twilioSmsSenderName: null
  };

  const settingPromise = createNotificationSettings({
    clientId,
    settings: defaultNotificationSetting,
  });

  const defaultNotificationChannels: NotificationChannel[] = ["SMS", "Email"];

  const channelPromise = updateNotificationChannelSettings({
    clientId,
    notificationChannelSettings: defaultNotificationChannels,
    existingNotificationChannelSettings: [],
  });

  const defaultNotificationTypes: NotificationType[] = [
    "BookingCreated",
    "BookingCancelled",
    "MessageDoctorCPAnswered",
    "ConsultationReminder",
    "ConsultationAboutToStart",
    "ConsultationOutputsAttached",
  ];

  const typePromise = updateNotificationTypeSettings({
    clientId,
    notificationTypeSettings: defaultNotificationTypes,
    existingNotificationTypeSettings: [],
  });

  const sendGridPromise = createSendGridTemplates({
    clientId,
    templates: defaultSendGridIds,
  });

  return Promise.all([
    settingPromise,
    channelPromise,
    typePromise,
    sendGridPromise,
  ]);
}

export default createClient;
