import React from "react";
import { Link, LinkProps } from "react-router-dom";
import Button from "@mui/material/Button";
import { FieldProps } from "react-admin";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";

interface Props extends FieldProps {
  text?: string;
  to: LinkProps["to"];
  type?: "link" | "button";
  onClick?: VoidFunction;
}

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export function LinkButtonBase(props: Props) {
  const { text = "", to, type = "link", onClick } = props;

  if (type === "button") {
    return (
      <Button
        sx={{ marginBottom: "24px", marginTop: "8px" }}
        variant="outlined"
        component={Link}
        aria-label={text}
        to={to}
        onClick={onClick}
      >
        {text}
      </Button>
    );
  }

  return (
    <Typography component="span" variant="body1">
      <StyledLink aria-label={text} to={to}>
        {text}
      </StyledLink>
    </Typography>
  );
}
