import { styled } from "@mui/material";
import React, { FC } from "react";

type Props<T> = AdornedFieldProps & T;

type AdornedFieldProps = {
  adornment: JSX.Element;
  Field: FC<any>;
  addLabel?: boolean;
};

const StyledDiv = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

export function AdornedField<T>(props: Props<T>) {
  const { Field, adornment, addLabel, ...rest } = props;

  return (
    <StyledDiv>
      <Field {...rest} />
      {adornment}
    </StyledDiv>
  );
}
