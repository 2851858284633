import React from "react";
import {
  Edit,
  Labeled,
  SimpleForm,
  TextField,
  useRecordContext,
} from "react-admin";
import { CapsDelimitedField } from "../fields/CapsDelimitedField";
import { Heading } from "../ui/Heading";
import { AssignmentActions } from "./AssignmentActions";
import { CompleteButton } from "./CompleteButton";
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { Group } from "../layout/Group";
import { Stack } from "../layout/Stack";
import { WorkQueueItemType } from "../../types/workQueues";
import { AllocationRequiredFields } from "./components/AllocationRequiredFields";
import { ManualInterventionRequiredFields } from "./components/ManualInterventionRequiredFields";
import { FollowUpActionsFields } from "./components/FollowUpActionFields";
import { SendPrescriptionFields } from "./components/SendPrescriptionFields";
import { BookingGroup } from "./components/BookingGroup";
import { CancelBookingFields } from "./components/CancelBookingFields";
import { PatientGroup } from "./components/PatientGroup";
import { NotificationFailedFields } from "./components/NotificationFailedFields";
import { AddPatientSurgeryFields } from "./components/AddPatientSurgeryFields";
import { FormFillConsultationActionRequiredFields } from "./components/FormFillConsultationActionRequiredFields";
import { CompletedDetails } from "./CompletedDetails";
import { SendFormFillDocumentsToGpFields } from "./components/SendFormFillDocumentsToGpFields";

const buttonsWrapStyles = {
  display: "flex",
  gap: 2,
};

function WorkQueueButtons() {
  return (
    <Box sx={buttonsWrapStyles}>
      <CompleteButton />
    </Box>
  );
}
export function WorkQueueEdit() {
  const { id } = useParams();

  return (
    <Edit title={`Task #${id}`}>
      <SimpleForm toolbar={<div />}>
        <Stack>
          <Group heading={<Heading level={2}>Details</Heading>}>
            <Grid item xs={12} sm={6}>
              <Labeled label="ID">
                <TextField source="id" />
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CapsDelimitedField
                source="attributes.itemType"
                label="Item Type"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Labeled label="Status">
                <TextField source="attributes.status" />
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Labeled label="Client Name">
                <TextField source="clients[0].attributes.name" emptyText="-" />
              </Labeled>
            </Grid>

            <Grid item xs={12} sm={6}>
              <AssignmentActions />
            </Grid>

            <Grid item xs={12}>
              <WorkQueueButtons />
            </Grid>
            <CompletedDetails />
          </Group>
          <PatientGroup />
          <ItemTypeFields />
        </Stack>
      </SimpleForm>
    </Edit>
  );
}

const components: Partial<Record<WorkQueueItemType, () => JSX.Element>> = {
  AllocationRequired: AllocationRequiredFields,
  ManualInterventionRequired: ManualInterventionRequiredFields,
  FollowUpAction: FollowUpActionsFields,
  CancelBooking: CancelBookingFields,
  MessageDoctorAllocationRequired: AllocationRequiredFields,
  MessageDoctorReplyReady: AllocationRequiredFields,
  NotificationFailed: NotificationFailedFields,
  SendPrescription: SendPrescriptionFields,
  AddPatientSurgery: AddPatientSurgeryFields,
  FormFillConsultationActionRequired: FormFillConsultationActionRequiredFields,
  SendFormFillDocumentsToGp: SendFormFillDocumentsToGpFields
};

const NoComp = () => {
  return <BookingGroup />;
};

function ItemTypeFields() {
  const record = useRecordContext();

  const itemType = record.attributes.itemType as WorkQueueItemType;

  const Component = components[itemType] ?? NoComp;

  return <Component />;
}
