import dayjs from "dayjs";
import getCurrentDate from "./getCurrentDate";

const TIME_LIMIT = 1000 * 60 * 60 * 2; // 2 hours

function setLastActiveTime() {
  const time = getCurrentDate();

  localStorage.setItem("lastActiveTime", time);
  return time;
}

function getLastActiveTime() {
  const lastActiveTime = localStorage.getItem("lastActiveTime") ?? "";

  return lastActiveTime;
}

function resetActiveTime() {
  localStorage.removeItem("lastActiveTime");
}

function isUserInactive() {
  const lastActiveTime = getLastActiveTime();

  if (!lastActiveTime) return true;

  const timeTwoHoursAgo = dayjs().subtract(TIME_LIMIT, "milliseconds");
  const userIsInactive = dayjs(lastActiveTime).isBefore(timeTwoHoursAgo);

  return userIsInactive;
}

export {
  setLastActiveTime,
  getLastActiveTime,
  isUserInactive,
  resetActiveTime,
};
