import { GetManyParams } from "ra-core";
import { get } from "../index";

async function getManyClinicalProducts(args: GetManyParams) {
  const { ids } = args;

  const params = {
    "filter[id]": `in:${ids}`,
    "page[size]": 100,
  };

  const { data } = await get("ms/clinicalProducts", params);

  return { data };
}

export default getManyClinicalProducts;
