import React from "react";
import { Slide, Snackbar, SnackbarContent } from "@mui/material";

const snackBarContentStyles = {
  animation: "pulse 2s infinite",
  "@keyframes pulse": {
    "0%": {
      boxShadow: "0 0 0 0 rgba(0, 0, 0, 0.7)",
    },

    "70%": {
      boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)",
    },
    "100%": {
      boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
    },
  },
};

const snackbarWrapperStyles = {
  "@media (min-width: 600px)": {
    top: "64px",
  },
};

interface Props {
  shouldDisplay: boolean;
  message: React.ReactNode;
  action: React.ReactNode;
}

export function StateDisplay(props: Props) {
  const { shouldDisplay, message, action } = props;

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={shouldDisplay}
      sx={snackbarWrapperStyles}
      TransitionComponent={Slide}
    >
      <SnackbarContent
        sx={snackBarContentStyles}
        message={message}
        action={action}
      />
    </Snackbar>
  );
}
