import * as React from "react";
import { FC, memo } from "react";
import get from "lodash/get";
import { Typography } from "@mui/material";
import { useRecordContext } from "ra-core";
import { BooleanFieldProps, sanitizeFieldRestProps } from "react-admin";

export const BooleanField: FC<BooleanFieldProps> = memo<BooleanFieldProps>(
  function BooleanField(props) {
    const {
      className,
      classes: classesOverride,
      emptyText,
      source = "",
      valueLabelTrue,
      valueLabelFalse,
      label,
      ...rest
    } = props;

    const record = useRecordContext(props);
    const value = get(record, source);
    const isTruthyValue = value === true;
    const truthyText = valueLabelTrue ?? "Yes";
    const falsyText = valueLabelFalse ?? "No";
    const displayText = isTruthyValue ? truthyText : falsyText;

    if (value === false || value === true) {
      return (
        <Typography
          variant="body1"
          sx={{ display: "flex" }}
          className={className}
          data-cy={source}
          {...sanitizeFieldRestProps(rest)}
        >
          {displayText}
        </Typography>
      );
    }

    return (
      <Typography
        variant="body1"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText}
      </Typography>
    );
  }
);
