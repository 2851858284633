import {
  Filter,
  ReferenceInput,
  TextField,
} from "ra-ui-materialui";
import React from "react";
import { BooleanField } from "../fields/BooleanField";
import {
  List,
  Datagrid,
  TextInput
} from "react-admin";
import AutocompleteInput from "../inputs/AutocompleteInput";
import { ListActions } from "../Actions";
import { ListWithoutFilterEmptyText, removeDefaultEmptyMessageStyle } from "../shared/ListHelpers";

function ResourcePoolListFilter(props: any) {
  return (
    <Filter {...props}>
      <TextInput
        label="Name"
        source="name"
        alwaysOn
        autoComplete="off"
      />
      <ReferenceInput
        alwaysOn
        source="clinicalPractitionerId"
        reference="clinicalPractitioners"
      >
        <AutocompleteInput
          label="Clinical Practitioner"
          filterToQuery={query => ({
            fullName: query,
          })}
          placeholder="Search by CP name..."
          optionText="attributes.fullName"
          helperText={false}
        />
      </ReferenceInput>

      <ReferenceInput
        sort={{ field: "id", order: "ASC" }}
        alwaysOn
        source="clientId"
        reference="clients"
      >
        <AutocompleteInput
          label="Client Contract"
          filterToQuery={query => ({ name: query })}
          placeholder="Search by client..."
          shouldRenderSuggestions={(query: string) => query.length > 1}
          source="id"
          optionText="attributes.name"
          helperText={false}
        />
      </ReferenceInput>
    </Filter>
  )
}

export function ResourcePoolList() {
  return (
    <List
      title="Resource Pools"
      filterDefaultValues={{ignoreWithoutFilters: true}}
      filters={<ResourcePoolListFilter/>}
      actions={<ListActions createLabel="Create" />}
      sx={removeDefaultEmptyMessageStyle}
    >
      <Datagrid rowClick="show" bulkActionButtons={false} empty={<ListWithoutFilterEmptyText/>}>
        <TextField
          label="Name"
          source="attributes.name"
          sortBy="name"
        />
        <TextField
          label="Service"
          source="attributes.serviceName"
          sortable={false}
        />
        <BooleanField
          label="Is Enabled"
          source="attributes.isEnabled"
          sortable={false}
        />
        <BooleanField
          label="Can be released"
          source="attributes.isGloballyAvailable"
          sortable={false}
        />
        <TextField
          label="Release duration"
          source="attributes.globallyAvailableDuration"
          sortable={false}
        />
      </Datagrid>
    </List>
  )

}
