import React from "react";
import { SaveButton, Toolbar, ToolbarProps } from "react-admin";
import { ResetPasswordButton } from "./patient/ResetPasswordButton";

export function BasicToolbar(
  props: Omit<ToolbarProps, "width"> & { resetPasswordLabel: string }
) {
  return (
    <Toolbar {...props}>
      <SaveButton sx={{ mr: 4 }} />
      <ResetPasswordButton label={props.resetPasswordLabel} />
    </Toolbar>
  );
}
