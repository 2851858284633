import React from "react";
import { useACL } from "ra-access-control-lists";
import { PermissionKey } from "ra-access-control-lists/dist/types";
import { FormTab, TabProps } from "./FormTab";

interface TabWithPermissionProps {
  options: {
    resource: string;
    permission: PermissionKey;
  };
}

type Props = TabWithPermissionProps & TabProps;

export function FormTabWithPermission(props: Props) {
  const { options } = props;
  const { resource, permission } = options;
  const access = useACL(resource);
  const canAccess = access[permission];
  if (!canAccess) return null;
  return <FormTab {...props} />;
}
