import { Typography } from "@mui/material";
import React from "react";
import { Labeled, sanitizeFieldRestProps } from "react-admin";

interface Props {
  label: string;
  text: string;
}

export function EmptyLabeledText(props: Props) {
  const { label, text, ...rest } = props;

  return (
    <Labeled label={label}>
      <Typography
        component="div"
        variant="body1"
        {...sanitizeFieldRestProps(rest)}
      >
        {text}
      </Typography>
    </Labeled>
  );
}
