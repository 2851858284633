import { TextInput } from "ra-ui-materialui";
import React from "react";
import { Stack } from "../layout/Stack";
import { SimpleFormIterator } from "../form/SimpleFormIterator";
import { ArrayInput } from "../inputs/ArrayInput";

interface Props {
  prefix?: string;
}

export function ClinicalDetailsInputs(props: Props) {
  const { prefix } = props;

  const source = prefix ? `${prefix}.` : "";

  return (
    <Stack>
      <ArrayInput source={`${source}allergies`} label="Allergies" level={3}>
        <SimpleFormIterator>
          <TextInput source="" label="Allergy" helperText="" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source={`${source}medications`} label="Medications" level={3}>
        <SimpleFormIterator>
          <TextInput source="" label="Medication" helperText="" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput
        source={`${source}medicalHistory`}
        label="Medical History"
        level={3}
      >
        <SimpleFormIterator>
          <TextInput source="" label="Medical History" helperText="" />
        </SimpleFormIterator>
      </ArrayInput>
    </Stack>
  );
}
