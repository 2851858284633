import React from "react";
import { Heading } from "../../ui/Heading";
import { Group } from "../../layout/Group";
import { Grid, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import get from "lodash/get";
import { ClinicalReport } from "../../../types/prescriptions";
import { styles } from "../PrescriptionsShow";


export function DemographicsGroup() {
  const record = useRecordContext();
  const clinicalReport = get(record, "traversal.clinicalReport") as ClinicalReport;


  const generalInfos = clinicalReport?.summary.categories.find(
    (x) => x.displayText.toLowerCase() === "general information"
  );
  const demographicsSubCategory = generalInfos?.subCategories.find(
    (x) => x.displayText.toLowerCase() === "demographics"
  );
  const demographics =
    demographicsSubCategory?.summaryGroups.map((group, index) => {
      const [label = "", value = ""] = group.displayText.split(":");
      return {
        id: index.toString(),
        label: label.trim(),
        value: value.trim()
      };
    }) ?? [];


  return (<Group heading={<Heading level={2}>Demographics</Heading>}>
    {demographics.map(item => (
      <Grid key={item.id} sx={styles.subCategoriesContainer} container>
        <Grid item>
          <Typography sx={styles.subCategoryHeader}>
            {item.label}
          </Typography>
          <Typography>
            {item.value}
          </Typography>
        </Grid>
      </Grid>
    ))}

  </Group>);
}