import React from "react";
import { Filter, SelectInput, TextInput } from "react-admin";
import { specialtyChoices } from "../../constants/constants";

export function ClinicalPractitionerFilter(props: any) {
  return (
    <Filter {...props}>
      <TextInput label="First Name" source="firstName" alwaysOn />
      <TextInput label="Last Name" source="lastName" alwaysOn />
      <TextInput label="Email" source="email" alwaysOn />
      <SelectInput
        label="Clinical Specialty"
        source="specialty"
        choices={specialtyChoices}
      />
    </Filter>
  );
}
