import { Identifier } from "react-admin";
import { get } from "..";
interface Args {
  clientId: Identifier;
}

const includes = ["client", "clientGroup"];

async function getClientGroups(args: Args) {
  const { clientId } = args;
  const query = {
    include: includes.join(","),
    "filter[client.id]": clientId || "",
  };
  const endpoint = `/ms/clientgroupsClient`;
  const { data } = await get(endpoint, query);

  const clientGroupData = data[0] || {};

  return clientGroupData;
}

export default getClientGroups;
