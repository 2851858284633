function removeFalsyValues<T extends Partial<Record<keyof T, unknown>>>(
  obj: T
) {
  const withFalsyValuesRemoved: Partial<T> = {};

  Object.keys(obj).forEach(key => {
    const val = obj[key as keyof T];

    if (val) {
      withFalsyValuesRemoved[key as keyof T] = val;
    }
  });

  return withFalsyValuesRemoved;
}

export default removeFalsyValues;
