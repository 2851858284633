import { create } from "..";
import { ResourceDTO } from "../../types/resources";

async function createResource(params: ResourceDTO["data"]) {
  const createResourceEndpoint = `/ms/administratorUsers`;

  const userName = params.attributes.email;

  const createResourceBody = {
    data: {
      type: "administratorUsers",
      attributes: {
        ...params.attributes,
        userName,
      },
    },
  };

  return create(createResourceEndpoint, createResourceBody);
}

export default createResource;
