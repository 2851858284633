import { useCreate, useNotify, useRecordContext } from "react-admin";
import dayjs from "dayjs";
import { Button } from "../../../stories/Button.stories";
import DownloadIcon from "@mui/icons-material/Download";
import React from "react";

interface DownloadConsultationOutputButtonProps {
  patientDob?: string;
  label: string;
  applyPassword: boolean;
}


export function DownloadConsultationOutputButton(props: DownloadConsultationOutputButtonProps) {
  const record = useRecordContext();
  const notify = useNotify();

  const {label, applyPassword, patientDob} = props;
  const formattedPatientDob = dayjs(patientDob, "YYYY-MM-DD").format(
    "DDMMYYYY"
  );


  const [download, { isLoading }] = useCreate(
    "gaia:downloadFile",
    {
      data: {
        url: `consultation-output/documents/${record.id}`,
        fileName: "consultation-output.pdf",
        applyPassword,
        password: formattedPatientDob
      },
    },
    {
      onError: err => {
        notify((err as any).message, { type: "error" });
      },
    }
  );

  return (
    <Button
      size={"small"}
      variant={"outlined"}
      endIcon={<DownloadIcon />}
      onClick={() => download()}
      disabled={isLoading}
    >
      {label}
    </Button>
  );

}