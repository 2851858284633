import get from "lodash/get";
import { RaRecord } from "ra-core";

export const getIdsFromWorkQueueItem = (record: RaRecord) => {
  return {
    bookingId: get(record, "bookings[0].id"),
    patientId: get(record, "patients[0].id"),
    clientId: get(record, "clients[0].id"),
  };
};
