import { update } from "..";

interface Params {
  clientId: string;
  guardianId: string;
  dependantId: string;
}

async function linkPatientWithGuardian(params: Params) {
  const { clientId, dependantId, guardianId } = params;

  const url = `/clients/${clientId}/users/${dependantId}/patientRecord/${dependantId}`;

  const body = {
    id: dependantId,
    type: "patients",
    relationships: {
      dependantOf: {
        data: {
          id: guardianId,
          type: 'users'
        },
      },
    },
  };

  await update(url, { data: body });

  return {
    data: {
      id: "id-to-satisfy-ra",
    },
  };
}

export default linkPatientWithGuardian;
