import { GetManyReferenceParams } from "ra-core";
import { FormFillPrescriptionTaskResponse } from "../../types/prescriptions";
import { list } from "../gaiaClient";
import { v4 as uuid } from "uuid";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

interface QueryParams extends Record<string, unknown>{
  encounterId?: string,
  patientRecordId?: string
  traversalId?: string
}

const getPrescriptionsByReference = async (params: GetManyReferenceParams) => {
  const { id, target, meta } = params;
  const stringId = id.toString();
  const queryParams: QueryParams = {};

  if (target === "patientRecordId") {
    queryParams.patientRecordId = stringId.substring(0, stringId.indexOf(":"))
  }
  else if (target === "encounterId") {
    queryParams.encounterId = stringId;
  }
  else if (target === "traversalId") {
    queryParams.traversalId = stringId;
  }
  queryParams.continuationToken = meta?.continuationToken;

  const filteredParams = shallowlyRemoveEmptyValues(queryParams);

  const queueName = "form-fill-prescription-queue";
  const correlationId = uuid();
  const headers = {
    "x-correlation-id": correlationId,
  };

  const endpoint = `queues/${queueName}/tasks`;
  const { data }: FormFillPrescriptionTaskResponse = await list(endpoint, {
    params: filteredParams,
    headers
  })

  return {
    total: data.tasks.length,
    data: data.tasks,
    pageInfo: { continuationToken: data.continuationToken}
  }
}

export default getPrescriptionsByReference;
