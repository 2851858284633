import { Grid } from "@mui/material";
import React from "react";
import { Group } from "../layout/Group";
import { Heading } from "../ui/Heading";
import AppointmentInput from "./AppointmentInput";
import { BookingDate } from "./BookingDate";
import { BookingRestrictionToggles } from "./BookingRestrictionToggles";
import { BookingTime } from "./BookingTime";
import { useWatch } from "react-hook-form";

export function AppointmentTimeInputs() {
  const service = useWatch({name: 'service'});

  return (
    <>
      <Group heading={<Heading level={2}>Appointment time</Heading>}>
        <Grid item xs={12}>
          <BookingRestrictionToggles />
        </Grid>
        <Grid item xs={12}>
          <BookingDate />
        </Grid>
        <Grid item xs={12}>
          <BookingTime />
        </Grid>
        <Grid item xs={12}>
          <AppointmentInput disabled={!service} />
        </Grid>
      </Group>
    </>
  );
}
