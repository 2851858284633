function isValidImageURL(str: string): boolean {
  try {
    const url = new URL(str);
    return /\.(jpeg|jpg|gif|png)$/i.test(url.pathname);
  } catch {
    return false;
  }
}

function isValidDomain(str: string): boolean {
  return /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i.test(str);
}

type ErrorMessage = string;

interface Error {
  attributes: {
    nonGeographicPhoneNumber?: ErrorMessage;
    phoneNumber?: ErrorMessage;
    clientBrandLogoUrl?: ErrorMessage;
    domain?: ErrorMessage;
  };
}

interface ContractValidation {
  contract: {
    attributes: {
      nonGeographicPhoneNumber: string;
      phoneNumber: string;
      clientBrandLogoUrl: string;
      domain: string;
    };
  };
}

function validateContracts(values: ContractValidation) {
  const { contract } = values;

  if (!contract) {
    return {};
  }

  const { attributes } = contract;

  const error: Error = {
    attributes: {},
  };

  if (!attributes.nonGeographicPhoneNumber && !attributes.phoneNumber) {
    error.attributes.nonGeographicPhoneNumber =
      "Enter the contract's NGN Phone Number or their DDI Phone Number";
    error.attributes.phoneNumber =
      "Enter the contract's NGN Phone Number or their DDI Phone Number";
  }

  const { clientBrandLogoUrl, domain } = attributes;

  if (clientBrandLogoUrl && !isValidImageURL(clientBrandLogoUrl)) {
    error.attributes.clientBrandLogoUrl = "Image URL is invalid";
  }

  if (domain && !isValidDomain(domain)) {
    error.attributes.domain =
      "The website domain is invalid. Please only include the domain name e.g. google.com or healthhero.com";
  }

  return {
    contract: error,
  };
}

export default validateContracts;
