import { GetOneParams } from "ra-core";
import { get } from "../index";
import { getResourcePoolServiceName } from "../../types/resourcePools";

const includes = [
  "clinicalPractitioners",
  "clientClinicianPools",
  "clientClinicianPools.clients",
  "clinicalService"
];

async function getResourcePool(args: GetOneParams) {
  const { id } = args;
  const endpoint = `/ms/resourcePools/${id}`;

  const params = {
    include: includes.join(","),
  };

  const res = await get(endpoint, params);

  return getFormattedPool(res);
}

function getFormattedPool(res: any) {
  const data = Array.isArray(res.data)
    ? res.data[0]
    : res.data;

  return {
    data: {
      ...data,
      attributes: {
        ...data.attributes,
        serviceName: getResourcePoolServiceName(data)
      },
      relationships: {
        ...data?.relationships,
        clinicalPractitioners: {
          data: data?.relationships?.clinicalPractitioners.data.map((r: any) => r.id)
        }
      }
    }
  };

}

export default getResourcePool;
