import React, { useState } from "react";
import { Button } from "@mui/material";
import { useListContext } from "react-admin";
import { useFormContext } from "react-hook-form";

export const EmployersEditBulkActionButtons = (props: {deleteEmployers: () => void}) => {
  const {deleteEmployers} = props;
  const { onUnselectItems } = useListContext();
  const { trigger } = useFormContext();


  const onDeleteEmployersClicked = () => {
    deleteEmployers();
    trigger("employerSettings.attributes.employerRequiredOnBookings");
    onUnselectItems();
  }

  return (
    <>
      <Button
        data-testId={"deleteEmployersButton"}
        variant="outlined"
        color="secondary"
        size="small"
        aria-label="Delete"
        onClick={onDeleteEmployersClicked}
      >
        Delete
      </Button>
    </>
  );
};
