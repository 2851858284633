import { create } from "..";

type VNetReferenceType = "Intake" | "Case";

interface VNetValidationArgs {
  reference: string;
  referenceType: VNetReferenceType;
}

const endpoint = "admin/vnet/reference/validate";

async function validateVNetReference(args: VNetValidationArgs) {
  const body = {
    data: {
      type: "vnetValidations",
      attributes: {
        reference: args.reference,
        referenceType: args.referenceType
      }
    }
  };

  return await create(endpoint, body);
}

export default validateVNetReference;