import { TextField } from "@mui/material";
import React, { useRef } from "react";
import { useAddressContext } from "../../../context/AddressContext";
import { Button } from "../../buttons/Button";

interface Props {
  disabled?: boolean;
}

export function FindAddress(props: Props) {
  const { disabled } = props;
  const { requestAddresses } = useAddressContext();
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    const input = ref.current?.querySelector("input");
    const value = input?.value;

    if (!value) {
      return requestAddresses(null);
    }

    return requestAddresses(value);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <TextField
        sx={{ marginRight: "1em" }}
        InputProps={{
          ref,
          placeholder: "E.g. NR14 7PZ",
        }}
        disabled={disabled}
        name="findAddress"
        variant="filled"
        margin="dense"
        label="Enter a UK postcode"
        onKeyDown={e => {
          if (e.key === "Enter") {
            handleClick();
          }
        }}
      />
      <Button
        sx={{ marginTop: "8px", marginBottom: "4px", alignSelf: "flex-end" }}
        onClick={handleClick}
        aria-label="Find address"
        variant="outlined"
      >
        Find address
      </Button>
    </div>
  );
}
