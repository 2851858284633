import { ClientBookingChannelsDTO } from "../../types/clients";
import { create } from "..";

interface Args {
  clientId: string;
  bookingChannels: ClientBookingChannelsDTO["data"]["attributes"];
}

export async function createClientBookingChannels(args: Args) {
  const { clientId, bookingChannels } = args;
  const endpoint = `/ms/clients/${clientId}/bookingChannels`;

  const createBookingChannelsBody = {
    data: {
      type: "clientBookingChannels",
      attributes: {
        clientId: parseInt(clientId),
        phone: bookingChannels.phone,
        patientPortal: bookingChannels.patientPortal,
        clientApp: bookingChannels.clientApp,
        symbio: bookingChannels.symbio
      }
    }
  }

  await create(endpoint, createBookingChannelsBody);
}
