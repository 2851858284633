import { GetManyParams } from "ra-core";
import { get } from "..";

async function getPatientTerms(params: GetManyParams) {
  const [id] = params.ids;
  const [userId, clientId] = String(id).split(":");

  const patientTermsEndpoint = `/clients/${clientId}/users/${userId}/terms`;

  const { data } = await get(patientTermsEndpoint, {
    include: "term",
    "filter[status]": "in:Pending,Accepted",
  });

  const res = {
    id,
    terms: data,
  };

  return {
    data: [res],
  };
}

export default getPatientTerms;
