import React from "react";
import { useEditStateContext } from "../../../../context/EditStateContext";
import { TextArrayField } from "../../../fields/TextArrayField";
import { Group } from "../../../layout/Group";
import { Stack } from "../../../layout/Stack";
import { Heading } from "../../../ui/Heading";
import { ClinicalDetailsInputs } from "../../ClinicalDetailsInputs";
import { Grid } from "@mui/material";

export function ClinicalDetails() {
  const { currentState } = useEditStateContext();

  if (currentState === "show") return <ShowContent />;

  return <EditContent />;
}

function ShowContent() {
  return (
    <Group heading={<Heading level={2}>Clinical Details</Heading>}>
      <Grid xs={12} sm={6} item>
        <Stack>
          <TextArrayField label="Allergies" source="attributes.allergies" />
          <TextArrayField label="Medications" source="attributes.medications" />
        </Stack>
      </Grid>
      <Grid xs={12} sm={6} item>
        <TextArrayField
          label="Medical History"
          source="attributes.medicalHistory"
        />
      </Grid>
    </Group>
  );
}

function EditContent() {
  return (
    <Group heading={<Heading level={2}>Clinical Details</Heading>}>
      <ClinicalDetailsInputs prefix="attributes" />
    </Group>
  );
}
