import React from "react";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  Toolbar,
  DayView,
  DateNavigator,
} from "@devexpress/dx-react-scheduler-material-ui";

interface Props {
  date: string;
  setDate: (date: any) => void;
  emptyComponent: React.ComponentType<DayView.LayoutProps>;
}

export const EmptyScheduler = (props: Props) => {
  const { date, setDate, emptyComponent } = props;
  return (
    <div data-cy="emptyschedule-selector">
      <Scheduler data={[]}>
        <ViewState
          currentDate={date}
          onCurrentDateChange={val => {
            setDate(val);
          }}
        />
        <Toolbar />
        <DateNavigator />
        <DayView
          layoutComponent={emptyComponent}
          startDayHour={0}
          endDayHour={1}
        />
      </Scheduler>
    </div>
  );
};
