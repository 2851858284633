import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title } from "react-admin";
import { Typography } from "@mui/material";
import { KeyboardKey } from "./KeyboardKey";

const dashboardTextStyles = {
  marginBottom: 2,
  "& span": {
    margin: 0,
  },
};

const containerStyles = {
  marginTop: 2,
};

function DocumentationLink() {
  return (
    <Typography sx={dashboardTextStyles} variant="body1">
      If you're new to HealthHero, or want to learn more about using this portal,
      you can view the{" "}
      <a
        href="https://healthhero.atlassian.net/wiki/spaces/ED3/overview"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "inherit" }}
      >
        documentation
      </a>
    </Typography>
  );
}

function PowerUsersDescription() {
  return (
    <Typography sx={dashboardTextStyles} variant="body1">
      If you're a power user, you can be more productive with the
      keyboard shortcuts. You can view all available shortcuts using{" "}
      <KeyboardKey char="⇧" /> + <KeyboardKey char="k" />
    </Typography>
  );
}

export function Home() {
  return (
    <Card sx={containerStyles}>
      <Title defaultTitle="Home" title="Home" />
      <CardContent>
        <DocumentationLink />
        <PowerUsersDescription />
      </CardContent>
    </Card>
  );
}
