import { GetManyParams } from "react-admin";
import { list } from "..";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

async function getManyResources(params: GetManyParams) {
  const query = {
    "filter[id]": `in:${params.ids}`,
  };

  const queryParams = shallowlyRemoveEmptyValues(query);

  const { data, total } = await list(`/ms/administratorUsers`, {
    params: queryParams,
  });

  return { data, total };
}

export default getManyResources;
