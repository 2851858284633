import React, { Suspense, useEffect, useState } from "react";
import { useNotify, useListContext } from "react-admin";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { SwapStateDisplay } from "./SwapStateDisplay";
import useListAppointments, {
  APT_DISPLAY_LIMIT,
  areFiltersInInitialState,
} from "../../context/useListAppointments";
import { EmptyScheduler } from "./EmptyScheduler";

import getDefaultDate from "../../utilities/getDefaultDate";

const DXScheduler = React.lazy(() =>
  import("./DXScheduler").then(module => ({ default: module.DXScheduler }))
);

const IdleComponent = () => {
  return (
    <div style={{ display: "flex", margin: "8px", flexDirection: "column" }}>
      <Typography style={{ marginBottom: 16 }} variant="h2">
        Appointments aren't displayed automatically.
      </Typography>
      <Typography variant="body1">
        To begin viewing the appointments, please use the filters.
      </Typography>
    </div>
  );
};

const NoDataComponent = () => {
  return (
    <div style={{ display: "flex", margin: "8px", flexDirection: "column" }}>
      <Typography style={{ marginBottom: 16 }} variant="h2">
        No results
      </Typography>
      <Typography variant="body1">
        We couldn't find any appointments that matched your search criteria
      </Typography>
    </div>
  );
};

const defaultDate = getDefaultDate().toISOString();

export function SchedulerView() {
  const { filterValues } = useListContext();
  const [date, setDate] = useState(defaultDate);

  const {
    data,
    loading: isLoading,
    hasError,
  } = useListAppointments({ ...filterValues, date });

  const notify = useNotify();

  const aptCount = data?.length ?? 0;

  const filtersAreInInitialState = areFiltersInInitialState(filterValues);

  useEffect(() => {
    if (aptCount === APT_DISPLAY_LIMIT) {
      notify(
        "There are too many appointments to display. Use the filters to narrow down appointments",
        { autoHideDuration: 0 }
      );
    }
  }, [aptCount, notify]);

  if (isLoading) {
    return (
      <div style={{ display: "flex", margin: "8px", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  if (filtersAreInInitialState) {
    return (
      <EmptyScheduler
        setDate={setDate}
        date={date}
        emptyComponent={() => <IdleComponent />}
      />
    );
  }

  if (!data?.length) {
    return (
      <EmptyScheduler
        setDate={setDate}
        date={date}
        emptyComponent={() => <NoDataComponent />}
      />
    );
  }

  if (hasError) {
    return (
      <div style={{ display: "flex", margin: "8px", flexDirection: "column" }}>
        <Typography style={{ marginBottom: 16 }} variant="h2">
          We had trouble fetching the appointments
        </Typography>
        <Typography variant="body1">
          If this issue keeps happening, please get in touch with support
        </Typography>
      </div>
    );
  }
  return (
    <div>
      <Suspense
        fallback={
          <div
            style={{ display: "flex", margin: "8px", justifyContent: "center" }}
          >
            <CircularProgress />
          </div>
        }
      >
        <SwapStateDisplay />
        <DXScheduler data={data} date={date} setDate={setDate} />
      </Suspense>
    </div>
  );
}
