import React from "react";
import {
  Datagrid,
  TextField,
  ReferenceManyField,
  useRecordContext,
  useListContext,
  Pagination,
} from "react-admin";
import { ShowTabProps } from "../../../types/utilities";
import { CountField } from "../../fields/CountField";
import { FormTab } from "../../form/CustomTabForm/FormTab";
import get from "lodash/get";
import useLazyLoadTab from "../../../hooks/useLazyLoadTab";

import { EmptyTab } from "../../layout/EmptyTab";
import { Box } from "@mui/material";

export function MessageThreadShowTab(props: ShowTabProps) {
  const record = useRecordContext(props);
  const { hidden = true } = props;

  const isDependant = get(record, "attributes.isDependant");

  const shouldDisplay = useLazyLoadTab(hidden);

  return (
    <FormTab
      {...props}
      disabled={isDependant}
      label="Message Threads"
      editable="hidden"
    >
      {shouldDisplay ? (
        <ReferenceManyField
          reference="messageThreads"
          target="id"
          label={false}
          pagination={<Pagination />}
        >
          <MessageThreads />
        </ReferenceManyField>
      ) : null}
    </FormTab>
  );
}

function MessageThreads() {
  const { data, isLoading } = useListContext();

  if (isLoading) return null;

  const hasRecords = data?.length > 0;
  if (!hasRecords) {
    return (
      <EmptyTab emptyText="There are no message threads associated with this patient" />
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Datagrid
        id="message-threads"
        bulkActionButtons={false}
        rowClick={threadId => {
          return `/messageThreads/${threadId}/show`;
        }}
      >
        <TextField label="Thread ID" source="id" />
        <TextField label="Status" source="attributes.status" />
        <CountField label="Message Count" source="messages" />
      </Datagrid>
    </Box>
  );
}
