import React from "react";
import { Group } from "../../layout/Group";
import { Heading } from "../../ui/Heading";
import { Grid } from "@mui/material";
import { Stack } from "../../layout/Stack";
import { TextField, Labeled } from "react-admin";
import { EMPTY_TEXT } from "../../../constants/constants";
import { GPAddressField } from "../../fields/GPAddressField";

export function GeneralPractitionerGroup() {
  return (
    <Group
      heading={<Heading level={2}>GP Details</Heading>}
      data-testid="gp-group"
    >
      <Grid item xs={12} sm={6}>
        <Stack>
          <Labeled label="GP Surgery Name">
            <TextField
              source="patients[0].attributes.gp.surgery.name"
              emptyText={EMPTY_TEXT}
            />
          </Labeled>
          <Labeled label="GP Surgery Phone Number">
            <TextField
              source="patients[0].attributes.gp.surgery.phoneNumber"
              emptyText={EMPTY_TEXT}
            />
          </Labeled>
          <Labeled label="GP Surgery Email">
            <TextField
              source="patients[0].attributes.gp.surgery.email"
              emptyText={EMPTY_TEXT}
            />
          </Labeled>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <GPAddressField source="patients[0].attributes.gp" />
      </Grid>
    </Group>
  )
}