import React from "react";
import { MenuItem } from "@mui/material";
import {
  ExtendedAddress,
  useAddressContext,
} from "../../../context/AddressContext";
import { AddressSelectInput } from "../AddressSelectInput";

interface DisplayItemProps {
  id: string;
  addressCount: number;
  currentAddress: ExtendedAddress | null;
}

const DisplayItem = (props: DisplayItemProps) => {
  const { addressCount, currentAddress } = props;

  if (!currentAddress || currentAddress.id === "-" || addressCount === 0) {
    return <>{addressCount} results</>;
  }

  const { id } = currentAddress;

  return <>{id}</>;
};

export function SelectAddress() {
  const { selectAddress, currentAddress, addresses } = useAddressContext();

  const choices = Object.values(addresses).map((val, index) => {
    return (
      <MenuItem key={val.id} value={index}>
        {val.id}
      </MenuItem>
    );
  });

  const addressCount = Object.keys(addresses).length;

  return (
    <AddressSelectInput
      id="selectPatientAddress"
      choices={choices}
      value={currentAddress?.id ?? "-"}
      onChange={e => selectAddress(`${e.target.value}`)}
      disabled={!addressCount}
      fullWidth
      renderValue={val => {
        return (
          <DisplayItem
            id={`${val}`}
            addressCount={addressCount}
            currentAddress={currentAddress}
          />
        );
      }}
    />
  );
}
