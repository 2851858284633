import React from "react";
import { Typography } from "@mui/material";
import { FormTabWithPermission } from "../../../form/CustomTabForm/FormTabsWithPermission";
import { Stack } from "../../../layout/Stack";
import { Group } from "../../../layout/Group";
import { EditTabProps } from "../../../../types/utilities";


// eslint-disable-next-line
export enum HealthmailUnavailableReason {
  ClientNotIrish,
  LinkRequired
}

interface Props extends EditTabProps {
  reason: HealthmailUnavailableReason
}

export function HealthmailUnavailableTab(props: Props) {
  const { reason } = props;

  return (
    <FormTabWithPermission
      {...props}
      label="Healthmail"
      editable="hidden"
      options={{
        resource: "healthmail",
        permission: "enabled",
      }}
    >
      <Group>
        <Stack>
          { reason === HealthmailUnavailableReason.ClientNotIrish && <IrishClientOnlyMessage /> }
          { reason === HealthmailUnavailableReason.LinkRequired && <LinkRequiredMessage /> }
        </Stack>
      </Group>
    </FormTabWithPermission>
  );
}




const IrishClientOnlyMessage = () => (<Stack>
  <Typography>
    Healthmail is only available for Irish clients.
  </Typography>
</Stack>);

const LinkRequiredMessage = () => (
  <Stack>
    <Typography>
      No PMS data has been linked to this booking yet.
    </Typography>
    <Typography>
      Use the PMS tab to link data before proceeding with Healthmail.
    </Typography>
  </Stack>);
