import { SelectInput, TextInput } from "ra-ui-materialui";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ExtendedAddress } from "../../../context/AddressContext";
import { useGooglePlaceAddressContext } from "../../../context/GooglePlaceAddressContext";
import { countrySelectChoices } from "../../../data/countryCodes";
import { EmailInput } from "../EmailInput";
import { PlaceType } from "./GooglePlaceAddressForm";

interface Props {
  sourcePrefix: string;
  type: PlaceType;
}

export interface GooglePlacesAddress extends ExtendedAddress {
  name: string;
  phoneNumber: string;
  address: string;
}

const inputStyles = {
  marginBottom: 0,
};

export function GooglePlaceAddressInputs(props: Props) {
  const { sourcePrefix, type } = props;

  const { currentAddress } = useGooglePlaceAddressContext();

  const { setValue } = useFormContext();

  const { name, address, id: addressId, city, countryCode, postCode, phoneNumber } =
    currentAddress;

  useEffect(() => {
    if (!addressId) return;
    
    setValue(`${sourcePrefix}.name`, name);
    setValue(`${sourcePrefix}.address.addressLine1`, address);
    setValue(`${sourcePrefix}.address.city`, city);
    setValue(`${sourcePrefix}.address.postCode`, postCode);
    setValue(`${sourcePrefix}.address.countryCode`, countryCode);
    setValue(`${sourcePrefix}.phoneNumber`, phoneNumber);
  }, [
    address,
    addressId,
    city,
    countryCode,
    name,
    phoneNumber,
    postCode,
    setValue,
    sourcePrefix,
  ]); 

  return (
    <div style={{ flexDirection: "column", display: "flex" }}>
      <TextInput
        sx={inputStyles}
        source={`${sourcePrefix}.name`}
        label={`${type} name`}
        fullWidth
      />
      <TextInput
        sx={inputStyles}
        source={`${sourcePrefix}.address.addressLine1`}
        label="Address Line One"
        fullWidth
      />
      <TextInput
        sx={inputStyles}
        source={`${sourcePrefix}.address.city`}
        label="City"
        fullWidth
      />
      <TextInput
        source={`${sourcePrefix}.address.postCode`}
        sx={inputStyles}
        label="Post Code"
        fullWidth
      />
      <SelectInput
        inputProps={{ 'data-testid': `${sourcePrefix}.address.countryCode` }}
        source={`${sourcePrefix}.address.countryCode`}
        sx={inputStyles}
        label="Country"
        choices={countrySelectChoices}
        fullWidth
      />
      <EmailInput source={`${sourcePrefix}.email`} sx={inputStyles} fullWidth />
      <TextInput
        source={`${sourcePrefix}.phoneNumber`}
        sx={inputStyles}
        label="Phone Number"
        fullWidth
      />
    </div>
  );
}
