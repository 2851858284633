import { NeutralColors, WarningColors } from "../../theme";
import { Alert } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import { PatientNotesDTO } from "../../types/patients";

export function PatientNotesAlert({ notes }: { notes: PatientNotesDTO["data"][] }) {
  return <Alert
    data-testid="patient-has-notes-alert"
    severity={"warning"}
    sx={{
      color: NeutralColors.Black,
      backgroundColor: WarningColors.LightYellow,
      marginLeft: "15px",
      marginTop: "15px",
      marginBottom: "10px",
      borderRadius: 5,
    }}
  >
    {notes.map((note: PatientNotesDTO["data"]) =>
      <Typography key={note.id} sx={{marginBottom: "10px"}}>
        {note.attributes.note}
      </Typography>
    )}
  </Alert>
}
