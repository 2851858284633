import React from "react";
import { Heading } from "../ui/Heading";
import { BooleanInput, useGetOne } from "react-admin";
import TooltipAdornment from "./TooltipAdornment";
import { GooglePlaceAddressForm } from "../inputs/GooglePlace/GooglePlaceAddressForm";
import { Group } from "../layout/Group";
import { Grid } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { HealthmailAddressFormInputs } from "../inputs/HealthmailAddress/HealthmailAddressFormInputs";
import { TOGGLE_USE_HEALTHMAIL_PHARMACY_LOOKUP } from "../../featureFlags/flags";
import { useFlag } from "../../featureFlags";

const headingStyles = {
  display: "flex",
  alignItems: "center",
  width: "100%",
};

export function ClinicalAddressesInputs() {
  const form = useFormContext();

  const formValues = form.getValues();

  const showGpDetails = formValues?.showGpDetails;
  const showPharmacyDetails = formValues?.showPharmacyDetails;

  return (
    <>
      <Group
        heading={
          <Heading level={2} sx={headingStyles}>
            GP Details
            <TooltipAdornment
              text={
                "The patient’s GP needs to know about consultation notes for any ongoing care they may support."
              }
            />
          </Heading>
        }
      >
        <Grid item xs={12}>
          <BooleanInput source="showGpDetails" label="Show GP Details" />
        </Grid>

        <Grid item xs={12} sm={6}>
          {showGpDetails ? (
            <GooglePlaceAddressForm
              sourcePrefix="gp.surgery"
              type="GP Surgery"
            />
          ) : null}
        </Grid>
      </Group>

      <Group
        heading={
          <Heading level={2} sx={headingStyles}>
            Pharmacy Details
            <TooltipAdornment
              text={
                "By nominating a pharmacy, the patient can have any prescription issued much faster. There is no need to contact them again."
              }
            />
          </Heading>
        }
      >
        <Grid item xs={12}>
          <BooleanInput
            source="showPharmacyDetails"
            label="Show Pharmacy Details"
          />
        </Grid>
        {showPharmacyDetails ? (
          <PharmacyAddressInput />
        ) : null}
      </Group>
    </>
  );
}

function PharmacyAddressInput() {
  const isHealthmailLookupEnabled = useFlag(TOGGLE_USE_HEALTHMAIL_PHARMACY_LOOKUP);
  const selectedClientId = useWatch({name: "clientId"});

  const { data: client } = useGetOne(
    "clients", 
    { id: selectedClientId },
    {
      enabled: Boolean(selectedClientId),
      refetchOnWindowFocus: false,
    });
  
  const irishCountryCode = "IE";
  const isHealthmailAddressRequired = isHealthmailLookupEnabled && client && client.attributes.countryCode == irishCountryCode;

  return (
    <>
      { isHealthmailAddressRequired
        ? <HealthmailAddressFormInputs source="nominatedPharmacy" />
        : (
          <Grid item xs={12} sm={6}>
            <GooglePlaceAddressForm
              sourcePrefix="nominatedPharmacy"
              type="Pharmacy"
            />
          </Grid>
        )
      }
    </>
  )
}
