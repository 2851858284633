function replaceItem<T extends { id: string | number }>(items: T[], item: T) {
  const itemsCopy = [...items];

  const ids = itemsCopy.map(({ id }) => id);
  const index = ids.indexOf(item.id);

  if (index !== -1) {
    itemsCopy[index] = item;
  }

  return itemsCopy;
}

export default replaceItem;
