import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Group } from "../../../layout/Group";
import { Heading } from "../../../ui/Heading";
import { AddressField } from "../../../fields/AddressField";
import { PatientNameSearchLink } from "./PatientNameSearchLink";
import { DateField } from "../../../fields/DateField";
import { RecordContextProvider } from "react-admin";
import { GaiaPatient } from "../../../../types/gaia";

type GaiaPatientGroupProps = {
  patient: GaiaPatient,
}

export function GaiaPatientGroup(props: GaiaPatientGroupProps) {
  return (
    <RecordContextProvider value={props.patient}>
      <Group 
        heading={<Heading level={2}>Patient</Heading>}
        data-testid="gaia-patient-group"
      >
        <Grid item xs={12} sm={6}>
          <Box>
            <PatientNameSearchLink 
              label="Patient Name" 
              firstName={props.patient.givenName}
              lastName={props.patient.familyName}
              birthDate={props.patient.birthDate}
            /> 
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateField label="Date of Birth" source="birthDate" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AddressField
            label="Patient address"
            source="address"
            emptyText="-"
            paddingRight={"20px"}
          />
        </Grid>
      </Group>
    </RecordContextProvider>
  );
}
