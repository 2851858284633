import { TextField } from "ra-ui-materialui";
import React from "react";
import { ShowTabProps } from "../../../types/utilities";
import { Tab } from "../../Tabs";

export function ContractShowTab(props: ShowTabProps) {
  return (
    <Tab {...props} label="Brand Details">
      <TextField
        source="contract.attributes.nonGeographicPhoneNumber"
        label="Non Geographic Phone Number"
        emptyText="n/a"
      />
      <TextField
        source="contract.attributes.phoneNumber"
        label="Direct Dial-in Number"
        emptyText="n/a"
      />
      <TextField
        source="contract.attributes.portalCode"
        label="Generic Portal Code"
        emptyText="n/a"
      />
      <TextField
        source="contract.attributes.domain"
        label="Website URL"
        emptyText="n/a"
      />
    </Tab>
  );
}
