import { ShiftList } from "./ShiftList";
import { ShiftCreate } from "./ShiftCreate";
import { ShiftShow } from "./ShiftShow";
import ShiftIcon from "@mui/icons-material/AccessTime";

export const shiftResources = {
  name: "shifts",
  options: { label: "Shifts" },
  list: ShiftList,
  create: ShiftCreate,
  show: ShiftShow,
  icon: ShiftIcon,
};
