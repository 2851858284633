import { AvailableService } from "../getAvailableServices";

// Returns the reference to the same array now sorted
export function sortAvailableServices(availableServices: AvailableService[]) : AvailableService[] {
  // Sort by group DESC first then by name ASC, General Practitioner is always first
  availableServices.sort((a:AvailableService, b:AvailableService) => {
    if (a.name === "General Practitioner") return -1;
    if (b.name === "General Practitioner") return 1;

    if (a.group > b.group) return -1;
    if (a.group < b.group) return 1;

    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;

    return 0;
  })

  return availableServices
}
