import React from "react";
import { Button, ButtonProps } from "react-admin";
import AddIcon from "@mui/icons-material/AddCircleOutline";

export function FormIteratorAddButton(props: ButtonProps) {
  return (
    <Button
      size="small"
      onClick={props.onClick}
      aria-label={props.label}
      label={props.label}
      disabled={props.disabled}
    >
      <AddIcon />
    </Button>
  );
}
