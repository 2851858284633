import React from "react";
import { Labeled, RaRecord, useRecordContext } from "react-admin";
import get from "lodash/get";
import { FunctionField } from "ra-ui-materialui";

interface RegistrationNumberFieldProps {
  label: string,
  source: string
}

export function CPSpecificFields() {
  const record = useRecordContext();
  const specialty = get(record, "attributes.specialty");

  if (!record || !specialty) return null;

  if (specialty === "GeneralPractitioner" || specialty == "Psychiatrist") {
    return <GPFields />;
  }

  if (specialty === "Physiotherapist"
    || specialty === "SpeechAndLanguage"
    || specialty === "Dietitian")
  {
    return <AhsFields />;
  }

  if (specialty === "ClinicalPharmacist") {
    return <ClinicalPharmacistFields />;
  }

  if (specialty === "Counsellor") {
    return <CounsellorFields />;
  }

  if (specialty === "AdvancedNursePractitioner") {
    return <AdvancedNursePractitionerFields />;
  }

  if (specialty === "Psychologist") {
    return <PsychologistFields />;
  }

  return null;
}

function RegistrationNumberField(props: RegistrationNumberFieldProps) {
  const { label, source } = props;

  return (
    <Labeled label={label}>
      <FunctionField
        render={(record: RaRecord) => {
          if (!record.cpRegistrations[source]) return "Not Registered"

          const { registrationNumber, isRegistered } = record.cpRegistrations[source];
          return isRegistered ? registrationNumber : "Not Registered";
        }}
      />
    </Labeled>
  );
}

function GPFields() {
  return (
    <>
      <RegistrationNumberField label="GMC Number" source="GeneralMedicalCouncil"/>
      <RegistrationNumberField label="IMC Number" source="IrishMedicalCouncil"/>
    </>
  );
}

function AhsFields() {
  return (
    <>
      <RegistrationNumberField label="HCPC Number" source="HealthAndCareProfessionsCouncil"/>
      <RegistrationNumberField label="CORU Number" source="CORU"/>
    </>
  );
}

function ClinicalPharmacistFields() {
  return (
    <>
      <RegistrationNumberField label="GPhC Number" source="GeneralPharmaceuticalCouncil"/>
      <RegistrationNumberField label="PSI Number" source="PharmaceuticalSocietyOfIreland"/>
    </>
  );
}

function CounsellorFields() {
  return (
    <>
      <RegistrationNumberField label="BACP Number" source="BritishAssociationForCounsellingAndPsychotherapy"/>
      <RegistrationNumberField label="IACP Number" source="IrishAssociationForCounsellingAndPsychotherapy"/>
    </>
  );
}

function AdvancedNursePractitionerFields() {
  return (
    <>
      <RegistrationNumberField label="NMC Number" source="NursingAndMidwiferyCouncil"/>
      <RegistrationNumberField label="NMBI Number" source="NursingAndMidwiferyBoardOfIreland"/>
    </>
  )
}

function PsychologistFields() {
  return (
    <>
      <RegistrationNumberField label="BPS Number" source="BritishPsychologicalSociety"/>
      <RegistrationNumberField label="PSI Number" source="PsychologicalSocietyOfIreland"/>
    </>
  )
}
