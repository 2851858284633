import { Identifier } from "ra-core";
import { list } from "..";

interface Args {
  clientId: Identifier;
}

export async function getClientTerms(args: Args) {
  const { clientId } = args;

  const endpoint = `/clients/${clientId}/terms`;
  const { data } = await list(endpoint);

  return data;
}
