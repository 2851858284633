import React, { useEffect, useState } from "react";
import { ArrayField, Datagrid, TextField } from "ra-ui-materialui";
import { ChipField, ListContextProvider, SingleFieldList, TextInput, useGetList, useList } from "react-admin";
import debounce from "lodash/debounce";
import { CreateEmployerButtonField } from "../fields/CreateEmployerButtonField";
import { EmployersEditBulkActionButtons } from "../buttons/EmployersEditBulkActionButtons";
import { EditEmployerButtonField } from "../fields/EditEmployerButtonField";
import { NoRecordsMessage } from "../messages/NoRecordsMessage";
import { Box, Button } from "@mui/material";
import { FieldValues, useFormContext, useWatch } from "react-hook-form";
import { v4 as uuid } from "uuid";
import { ClientEmployerChange, ClientEmployerDTO } from "../../../types/clientEmployer";
import { EditEmployersChangesList } from "../EditEmployersChangesList";
import { useFlag } from "../../../featureFlags";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS, TOGGLE_ENABLE_CLINICAL_PRODUCTS } from "../../../featureFlags/flags";
import { ChangeAction } from "../../../types/common";

const clientEmployersChangesSource = "clientEmployersChanges";

export function EmployerTableInput() {
  const clinicalProductsEnabled = useFlag(TOGGLE_ENABLE_CLINICAL_PRODUCTS);
  const clientClinicalProductsEnabled = useFlag(TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS);
  const perPage = 25;
  const { setValue, formState } = useFormContext();
  const clientId = useWatch({name: "id"});
  const [shouldReplaceResponse, setShouldReplaceResponse] = useState(false);
  const [changesList, setChangesList] = useState<ClientEmployerChange[]>([]);
  const [listOfEmployers, setListOfEmployers] = useState<ClientEmployerDTO["data"][]>([]);
  const [filterState, setFilterState] = useState({ page: 1, name: "" });

  const {isLoading, pageInfo } = useGetList(
    "employers",
    {
      pagination: { page: filterState.page, perPage: perPage },
      filter: { "clientId": clientId, name: filterState.name },
      sort: { field: "name", order: "ASC" }
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => {
        if (shouldReplaceResponse) {
          setListOfEmployers(data!);
        } else {
          setListOfEmployers([...listOfEmployers, ...data!]);
        }
      }
    }
  );

  useEffect(() => {
    setChanges([]);
    setFilterState({page: 1, name: ''})
    setShouldReplaceResponse(true);

  }, [formState.isSubmitSuccessful]);

  const listContext = useList<ClientEmployerDTO["data"]>({ data: listOfEmployers });

  const setChanges = (updatedList: ClientEmployerChange[]) => {
    setChangesList(updatedList);
    setValue(clientEmployersChangesSource, updatedList, { shouldDirty: true });
  };

  const loadMoreItems = () => {
    if (!isLoading) {
      setFilterState({ ...filterState, page: filterState.page + 1 });
      setShouldReplaceResponse(false);
    }
  };

  const addEmployer = (values: FieldValues) => {
    const newEmployer = {
      id: uuid(),
      attributes: {
        name: values.name,
        code: values.code,
        clientId,
        clinicalProductId: values.clinicalProductId
      }
    } as ClientEmployerDTO["data"];

    setChanges([...changesList, { id: newEmployer.id, action: ChangeAction.Added, payload: newEmployer }]);
  };

  const updateEmployer = (id: string, values: FieldValues) => {
    const employer = listOfEmployers.find(r => r.id === id)!;

    const newEmployer = {
      ...employer,
      attributes: {
        ...employer.attributes,
        name: values.name,
        clinicalProductId: values.clinicalProductId
      }
    };

    const alreadyChanged = changesList.find(r => r.payload.id === id);

    // Create a new edit change
    if (!alreadyChanged) {
      setChanges([...changesList, { id, action: ChangeAction.Edited, payload: newEmployer, oldPayload: employer }]);

    // Remove from the changes list if edited and initial values are the same
    } else if (alreadyChanged.oldPayload &&
               alreadyChanged.oldPayload.attributes.name === values.name &&
               alreadyChanged.oldPayload.attributes.clinicalProductId === values.clinicalProductId) {

      setChanges(changesList.filter(r => r.id !== id));

      // Set action as edited
    } else {
      alreadyChanged.action = ChangeAction.Edited;
      alreadyChanged.payload = employer;
      alreadyChanged.oldPayload = newEmployer;
      setChanges([...changesList]);
    }
  };

  const deleteEmployers = () => {
    const deleted = listOfEmployers.filter(e => listContext.selectedIds.includes(e.id));

    let updatedList = changesList;

    deleted.forEach(e => {
      const changed = updatedList.find(r => r.id === e.id);

      if (changed) {
        updatedList = updatedList.filter(r => r !== changed);
      }

      updatedList.push({ id: e.id, action: ChangeAction.Deleted, payload: e });

    });

    setChanges([...updatedList]);
  };

  const onFilterChange = debounce((e: any) => {
    setShouldReplaceResponse(true);
    setFilterState({ page: 1, name: e.target.value });
  }, 600);

  const onUndoClicked = (change: ClientEmployerChange) => setChanges(changesList.filter(r => r.id !== change.id));

  const onResetChangesClicked = () => setChanges([]);

  const employerNameFilterStyles = {
    "& .MuiFormHelperText-root": {
      display: "none"
    }
  };

  const styles = {
    "& .RaBulkActionsToolbar-toolbar": {
      marginLeft: "1rem",
      marginRight: "1rem",
    },
    "& .RaDatagrid-row td:last-child": {
      textAlign: "right"
    }
  };

  return (
    <>
      <TextInput
        data-testid={"employerNameFilter"}
        onChange={onFilterChange}
        label={"Employer name filter"}
        source={"nameFilter"}
        name={"employerNameFilter"}
        value={filterState.name}
        sx={employerNameFilterStyles}
      />
      <Box sx={{ marginTop: "3rem", maxHeight: "30rem", overflow: "auto" }}>
        <ListContextProvider value={listContext}>
          <Datagrid
            bulkActionButtons={<EmployersEditBulkActionButtons deleteEmployers={deleteEmployers} />}
            sx={styles}
            data-cy="employer-edit-table"
            empty={<NoRecordsMessage message={"No employers are associated with this client. Click 'Add' to create one."} />}
          >
            <TextField label="Name" source="attributes.name" data-testid={"employerRecordName"} />
            {clinicalProductsEnabled || clientClinicalProductsEnabled ?
              <ArrayField label={"Clinical Products"} source="clinicalProducts">
                <SingleFieldList sx={{ padding: "10px 0", maxWidth: "300px" }} linkType={false}>
                  <ChipField source="attributes.name" />
                </SingleFieldList>
              </ArrayField>
              : null}
            <EditEmployerButtonField updateEmployer={updateEmployer} />
          </Datagrid>
          <Box display="flex" justifyContent="center">
            <Button
              data-testid={"LoadMoreButton"}
              sx={{ marginTop: "10px" }}
              onClick={loadMoreItems}
              variant="outlined"
              size="large"
              disabled={isLoading || !pageInfo?.hasNextPage}
            >
              Load more
            </Button>
          </Box>
        </ListContextProvider>
      </Box>
      <EditEmployersChangesList onResetChangesClicked={onResetChangesClicked} onUndoClicked={onUndoClicked} data={changesList} />
      <CreateEmployerButtonField addEmployer={addEmployer} />
    </>
  );
}

