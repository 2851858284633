import React from "react";
import {
  TextFieldProps,
  sanitizeFieldRestProps,
  useRecordContext,
  Labeled,
} from "react-admin";
import Typography from "@mui/material/Typography";
import get from "lodash/get";
import formatAddress from "./formatAddress";

export function AddressField(props: TextFieldProps & { label: string }) {
  const { source, emptyText, ...rest } = props;
  const record = useRecordContext();

  if (!record || !source) return null;

  const address = get(record, source);
  const formattedAddress = formatAddress(address);

  return (
    <Labeled label={props.label}>
      <Typography
        component="span"
        variant="body1"
        {...sanitizeFieldRestProps(rest)}
      >
        {formattedAddress !== null ? formattedAddress : emptyText}
      </Typography>
    </Labeled>
  );
}
