import React from "react";
import { Tooltip } from "@mui/material";
import GppGoodIcon from '@mui/icons-material/GppGood';
import GppBadIcon from '@mui/icons-material/GppBad';

interface Props {
  isPharmacyVerified: boolean;
}

export function PharmacyVerificationIconField(props: Props) {  
  const verifiedTooltip = props.isPharmacyVerified ? "Pharmacy verified" : "Pharmacy not verified. Please update.";
  const testId = props.isPharmacyVerified ? "pharmacy-verified-icon" : "pharmacy-not-verified-icon";
  return (
    <Tooltip title={verifiedTooltip} data-testid={testId}>
      { props.isPharmacyVerified ? <GppGoodIcon color="success"/> : <GppBadIcon color="error"/> }
    </Tooltip>
  )
}