import { UpdateParams } from "ra-core";
import { baseUrl, update } from ".";

interface Args {
  clientId: string;
  userId: string;
  termId: string;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
function acceptTerm({ data }: UpdateParams<Args>) {
  const { clientId, userId, termId } = data;

  const acceptTermsEndpoint = `${baseUrl}/clients/${clientId}/users/${userId}/terms/${termId}`;
  const acceptTermsBody = {
    data: {
      id: termId,
      type: "userTerms",
      attributes: {
        accepted: true,
      },
    },
  };

  return update(acceptTermsEndpoint, acceptTermsBody);
}

export default acceptTerm;
