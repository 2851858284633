import React from "react";
import TextField from "@mui/material/TextField";
import { useController } from "react-hook-form";
import { InputAdornment } from "@mui/material";

interface Props {
  name: string;
  label: string;
  type?: "text" | "password";
  showPassword?: boolean;
  disabled: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  autoComplete?: string;
}

const inputStyles = {
  border: "none",
  bgcolor: "background.default",
  height: "43px",
  paddingRight: "0",
};

export function AuthInput(props: Props) {
  const {
    name,
    label,
    type = "text",
    disabled,
    startAdornment,
    endAdornment,
  } = props;

  const { field, fieldState } = useController({ name });
  const { error } = fieldState;

  // Note - Structure of error object differs from FieldError schema.
  // Actual error message appears to be under error.message.message.

  return (
    <TextField
      {...field}
      id={name}
      name={name}
      label={label}
      placeholder={label}
      variant="outlined"
      size="small"
      type={type}
      // @ts-ignore
      error={!!error?.message?.message}
      // @ts-ignore
      helperText={error?.message?.message}
      fullWidth
      InputProps={{
        startAdornment: startAdornment ? (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ) : null,
        disabled: disabled,
        endAdornment: endAdornment ? (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ) : null,
        sx: inputStyles,
      }}
    />
  );
}
