import React from "react";
import { MenuItem, SelectChangeEvent, Typography, styled } from "@mui/material";
import { useHealthmailAddressContext } from "../../../context/HealthmailAddressContext";
import { AddressSelectInput } from "../AddressSelectInput";
import { HealthmailPharmacy } from "../../../types/healthmail";

const StyledSpan = styled("span")({
  fontWeight: "bold",
});

const menuItemStyles = {
  whiteSpace: "normal",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingTop: "2px",
  paddingBottom: "2px",
};

interface DisplayItemProps {
  id: string;
  addressCount: number;
  currentAddress: HealthmailPharmacy;
}


const DisplayItem = (props: DisplayItemProps) => {
  const { currentAddress } = props;

  if (!currentAddress.pharmacyId) {
    return null;
  }

  const { name } = currentAddress;

  return <>{name}</>;
};

export function SelectAddressInputs() {
  const { selectAddress, currentAddress, addresses, loading } =
    useHealthmailAddressContext();
  
  const choices = addresses.map((val: HealthmailPharmacy) => {    
    const { pharmacyId, name, address: { line: addressLines, city, state } } = val;
    
    return (
      <MenuItem key={pharmacyId} value={pharmacyId} sx={menuItemStyles} divider>
        <StyledSpan>{name}</StyledSpan>
        {addressLines.map((line, key) => (
          <Typography key={key}>{line}</Typography>
        ))}
        <Typography>{city}</Typography>
        <Typography>{state}</Typography>
      </MenuItem>
    );
  });

  const handleInputSelection = (e: SelectChangeEvent<unknown>) => {
    selectAddress(e.target.value as string);
  };
  
  const addressCount = addresses.length;
  const inputId = "select-healthmail";

  const inputLabel = `${addressCount} results`

  return (
    <AddressSelectInput
      choices={choices}
      inputLabel={inputLabel}
      id={inputId}
      inputProps={{ 'data-testid': inputId }}
      value={currentAddress.pharmacyId}
      onChange={handleInputSelection}
      disabled={!addressCount || loading}
      fullWidth
      choicesMaxHeight={530}
      renderValue={val => {
        return (
          <DisplayItem
            id={`${val}`}
            addressCount={addressCount}
            currentAddress={currentAddress}
          />
        );
      }}
    />
  );
}

