import React, {
  isValidElement,
  cloneElement,
  ReactElement,
  Children,
} from "react";
import { matchPath, useLocation, useResolvedPath } from "react-router-dom";
import { getTabbedFormTabFullPath } from "ra-ui-materialui";
import { FormTabs } from "./FormTabs";
import { useEditContext } from "ra-core";
import { ToolbarProps } from "react-admin";

/**
 *
 * The TabLayoutView is only concerned with layout and setting up the routing for the tab.
 * It shouldn't deal with any form logic.
 *
 * We've created a bespoke TabLayoutView to accommodate for bespoke logic, such as:
 *  - control over the save button's placement and behaviour
 *  - preventing navigating between tabs when editing the form
 */

type ValidationRes = Record<string, any> | undefined;

interface Props {
  children: React.ReactElement | React.ReactElement[];
  save?: (values: any) => void;
  validate?: (values: any) => ValidationRes;
  Toolbar?: (props: ToolbarProps) => JSX.Element;
}

export function TabLayoutView(props: Props) {
  const { pathname } = useLocation();
  const resolvedPath = useResolvedPath("");
  const context = useEditContext();
  const { children, save, validate, Toolbar } = props;

  const saveFn = save ?? context.save;

  return (
    <div>
      <FormTabs>{children}</FormTabs>
      <div>
        {Children.map(children, (tab: ReactElement, index) => {
          if (!tab) {
            return;
          }
          const tabPath = getTabbedFormTabFullPath(tab, index);
          const hidden = !matchPath(
            `${resolvedPath.pathname}/${tabPath}`,
            pathname
          );

          return isValidElement<any>(tab)
            ? cloneElement(tab, {
                intent: "content",
                save: saveFn,
                hidden,
                value: tabPath,
                validate,
                Toolbar,
              })
            : null;
        })}
      </div>
    </div>
  );
}
