import * as React from "react";
import { TopToolbar, ShowActionsProps } from "react-admin";
import { CancelBookingButton } from "../buttons/CancelBookingButton";
import { RescheduleBookingButton } from "../buttons/RescheduleBookingButton";

export function BookingEditActions(props: ShowActionsProps) {
  const { className } = props;

  return (
    <TopToolbar className={className}>
      <RescheduleBookingButton />
      <CancelBookingButton bookingIdSource="bookingId" />
    </TopToolbar>
  );
}
