import { GetOneParams } from "react-admin";
import { get } from "..";

async function getAdminAppointment(args: GetOneParams) {
  const { id } = args;

  const params = {
    include: "booking,clinicalPractitioner,clinicalService",
  };

  const res = await get(`/ms/appointments/${id}`, params);

  const data = Array.isArray(res.data) ? res.data[0] : res.data;

  return { data };
}

export default getAdminAppointment;
