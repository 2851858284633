import { FieldValues } from "react-hook-form";
import { useRecordContext } from "react-admin";
import React, { useState } from "react";
import { BaseClinicalProductDialog } from "./BaseClinicalProductDialog";
import uniqBy  from "lodash/uniqBy";
import { Box, Button, Tooltip } from "@mui/material";

export interface ClinicalProductCreateDialogProps {
  addClinicalProduct: (values: FieldValues) => void;
}

export const ClinicalProductCreateDialog = (props: ClinicalProductCreateDialogProps) => {
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const record = useRecordContext();

  const { addClinicalProduct } = props;

  if (!record) {
    return null;
  }

  function submit(values: FieldValues) {
    addClinicalProduct(values);
    setIsDialogOpened(false);
  }

  return (
    <Box style={{ padding: "6px 6px 6px 0px" }}>
      <Tooltip title={"Create Clinical Product"}>
        <Button
          variant="contained"
          size="small"
          onClick={() => setIsDialogOpened(true)}
        >
          Add
        </Button>
      </Tooltip>
      <BaseClinicalProductDialog
        mode="create"
        isOpened={isDialogOpened}
        onClose={() => setIsDialogOpened(false)}
        title={"Create Clinical Product"}
        submitButtonText={"Add"}
        record={{
          name: "",
          code: "",
          clinicalProductServices: [],
          appointmentFilterCategories: uniqBy(record.appointmentFilterCategories, "id").sort((a:any, b:any) => b.group.localeCompare(a.group)),
        }}
        submit={submit}
      />
    </Box>
  );
}
