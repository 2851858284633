import { create } from "../gaiaClient";
import { v4 as uuid } from "uuid";

interface Params {
  pharmacy: string;
  encounter: {
    id: string;
  };
  bookingId: string;
  consultationId: string;
  documentId?: string;
  file: {
    rawFile: File;
  };
}

async function sendHealthmail(params: Params) {
  const { pharmacy, encounter, bookingId, consultationId, documentId, file } = params;

  const correlationId = uuid();
  const headers = {
    "x-correlation-id": correlationId,
  };

  const parsedPharmacy = JSON.parse(pharmacy);
  const pharmacyId = parsedPharmacy.id;

  const endpoint = `healthmail/encounters/${encounter.id}/process`;

  const queryParams = {
    organisationId: pharmacyId,
    consultationId,
    bookingId
  };

  const formData = new FormData();

  if (file) {
    formData.append("file", file.rawFile);
  } else if (documentId) {
    formData.append("documentId", documentId);
  }

  await create(endpoint, formData, { params: queryParams, headers });

  return {
    data: {
      id: "id-to-satisfy-ra",
    },
  };
}

export default sendHealthmail;
