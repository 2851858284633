import { ShowTabProps } from "../../../types/utilities";
import { FormTab } from "../../form/CustomTabForm/FormTab";
import { Heading } from "../../ui/Heading";
import React, { useState } from "react";
import { Group } from "../../layout/Group";
import { Grid, Tooltip } from "@mui/material";
import {
  Button, Confirm, FunctionField, Labeled, useCreate, useNotify, useRecordContext
} from "react-admin";
import RefundIcon from "@mui/icons-material/Payment";
import get from "lodash/get";
import { BooleanField } from "../../fields/BooleanField";
import { convertToTitleCase } from "../../../utilities/convertToTitleCase";

export function VhiPaymentsTab(props: ShowTabProps){
  return (
    <FormTab {...props} label="Payments" editable="noneditable">
      <Group heading={<Heading level={2}>Payments details</Heading>}>
        <Grid item xs={12} sm={6}>
          <Labeled label="Transaction Type">
            <FunctionField render={(record: any) => {
              if (record?.attributes?.payload?.TransactionType) {
                return convertToTitleCase(record?.attributes?.payload?.TransactionType, "_");
              }
              return "N/A"
            }
            } />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Labeled label="Refund requested">
            <BooleanField source="attributes.payload.IsRefundRequested" emptyText="No" />
          </Labeled>
        </Grid>
        <RefundPaymentButton/>
      </Group>
    </FormTab>
  )
}

function RefundPaymentButton() {
  const record = useRecordContext();
  const payload = get(record, "attributes.payload");
  const bookingStatus = get(record, "attributes.status");

  const [isDialogOpen, setDialogOpenState] = useState(false);
  const [isRefundRequestSent, setRefundRequestSent] = useState(false);
  const notify = useNotify();

  const [refundConsultation, { isLoading }] = useCreate("refundConsultation",
    {
      data: {
        consultationId: record.attributes.consultationId
      },
    },
    {
      onSuccess: () => {
        setDialogOpenState(false);
        setRefundRequestSent(true);
        notify("Consultation refund has been requested");
      },
      onError: () => {
        setDialogOpenState(false);
        notify("Something went wrong while processing refund");
      },
    }
  );

  const buttonDisabled = !(!payload.IsRefundRequested &&
    payload.TransactionType === "COVERED_CONSULTATION" &&
    bookingStatus === "Completed") || isRefundRequestSent || isLoading;

  const tooltipMessage = buttonDisabled ?
    "Only completed consultations with transaction type 'Covered Consultation' can be refunded" :
    "Note: It will take some time before the transaction type updates to 'Complimentary'";



  return (
    <>
      <Confirm
        isOpen={isDialogOpen}
        title="Refund consultation"
        content="Are you sure you want to refund this user's consultation?"
        confirm="Yes"
        confirmColor="primary"
        cancel="Cancel"
        onConfirm={() => {
          refundConsultation();
        }}
        onClose={() => setDialogOpenState(false)}
      />
      <Tooltip title={tooltipMessage}>
        <span>
          <Button
            sx={{margin: 1}}
            disabled={buttonDisabled}
            label="Refund Consultation"
            variant="outlined"
            onClick={() => setDialogOpenState(true)}
          >
            <RefundIcon/>
          </Button>
        </span>
      </Tooltip>
    </>
  );
}
