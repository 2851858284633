import validatePatientAddress, {
  PatientValidation,
} from "../../validators/validatePatientAddress";
import isEmpty from "lodash/isEmpty";
import recursiveIsObjectEmpty from "../../utilities/recursiveIsObjectEmpty";

type ErrorMessage = string;

interface FollowUpOutputError {
  attributes: {
    followUpActionType?: ErrorMessage;
  };
}

interface Errors {
  followUpOutputs: FollowUpOutputError[];
}

export function validateConsultationOutputs(values: any) {
  const errors: Errors = {
    followUpOutputs: [],
  };

  const { followUpOutputs = [] } = values;

  followUpOutputs.forEach((output: any, index: number) => {
    const error: FollowUpOutputError = {
      attributes: {},
    };
    if (output?.attributes?.followUpActionType === "") {
      error.attributes.followUpActionType = "Output type can not be empty";
      errors.followUpOutputs[index] = error;
    }
  });
  return errors;
}

interface BookingErrors {
  attributes: {
    contactDetails?: PatientValidation;
    consultationType?: ErrorMessage;
  };
}

function validateEditBookingForm(values: any) {
  const patientAddressErrors = validatePatientAddress(
    values.attributes.contactDetails
  );
  const consultationOutputErrors = validateConsultationOutputs(values);

  let bookingErrors: BookingErrors = {
    attributes: {},
  };

  if (!isEmpty(patientAddressErrors)) {
    bookingErrors = {
      attributes: {
        contactDetails: {
          ...patientAddressErrors,
        },
      },
    };
  }

  if (!values.attributes.consultationType) {
    bookingErrors.attributes.consultationType =
      "A consultation type is required";
  }

  const errors = {
    ...bookingErrors,
    ...consultationOutputErrors,
  };
  
  return recursiveIsObjectEmpty(errors) ? {} : errors;
}

export default validateEditBookingForm;
