import InputAdornment, {
  InputAdornmentProps,
} from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import HelpIcon from "@mui/icons-material/Help";
import { Colors } from "../../theme";

/**
 * Because the placement of the Tooltip is dependant on the component it decorates,
 * it makes sense to give more control to the consumer when it comes to styling.
 */
interface Props {
  text: string;
  sx?: InputAdornmentProps["sx"];
}

const iconStyles = {
  width: "20px",
  height: "20px",
  cursor: "pointer",
  color: Colors.Grey,
};

const TooltipAdornment = ({ text, sx }: Props) => {
  return (
    <InputAdornment sx={sx} position="end">
      <Tooltip title={text} arrow>
        <HelpIcon sx={iconStyles} />
      </Tooltip>
    </InputAdornment>
  );
};

export default TooltipAdornment;
