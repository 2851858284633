import React from "react";
import { ShowTabProps } from "../../types/utilities";
import { RecordContextProvider, SimpleList, useRecordContext } from "react-admin";
import get from "lodash/get";
import { Tab } from "../Tabs";
import { ArrayField } from "ra-ui-materialui";
import { Grid, Typography } from "@mui/material";
import { Colors } from "../../theme";


export function MessagingTab(props: ShowTabProps) {
  const record = useRecordContext();
  const conversations = get(record, "conversations", []);

  const [selectedConversationIndex, setSelectedConversationIndex] = React.useState(0);

  if (!conversations || !conversations.length) {
    return (<Tab data-testid="messaging-tab" {...props} label="Messaging">
      <Typography>
        No conversations found for this prescription.
      </Typography>
    </Tab>);
  }

  return (
    <Tab data-testid="messaging-tab" {...props} label="Messaging">
      <Grid container>
        <Grid item xs={2}>
          <ArrayField source={"conversations"}>
            <SimpleList
              linkType={false}
              primaryText={(rec: any) => {
                const index = conversations.findIndex((r: any) => r.conversation.conversationId === rec.conversation.conversationId);
                const isSelected = index === selectedConversationIndex;
                return (
                  <Typography
                    sx={{
                      cursor: isSelected ? "default" : "pointer",
                      marginRight: "12px",
                      padding: "15px",
                      backgroundColor: isSelected ? Colors.LightBlue : Colors.LighterBlue,
                      borderRadius: "15px",
                      "&:hover": {
                        backgroundColor: Colors.LightBlue
                      }
                    }}
                    onClick={() => {
                      if (isSelected) return;
                      setSelectedConversationIndex(index);
                    }}
                  >
                    {`Conversation from ${new Date(rec.conversation.createdAt).toLocaleDateString()}`}
                  </Typography>
                );
              }} />
          </ArrayField>
        </Grid>
        <Grid item xs={10}>
          <RecordContextProvider value={conversations[selectedConversationIndex]}>
            <Conversation />
          </RecordContextProvider>
        </Grid>
      </Grid>
    </Tab>
  );
}


function Conversation() {
  const record = useRecordContext();

  const getAuthor = (rec: any) => {
    const [authorType, authorId] = rec.sender.split("/");
    if (authorType === "Device") {
      return "System";
    } else {
      const participant = record.conversation.detailedParticipants.find((x: any) => x.id === authorId);
      const isPractitioner = authorType === "Practitioner";
      return `${participant.firstName} ${participant.lastName} - ${isPractitioner ? "Practitioner" : "Patient"}`;
    }
  };

  const rowSx = (rec: any) => {
    const authorType = rec.sender.split("/")[0];
    const isPractitioner = authorType === "Practitioner";

    return {
      padding: '12px',
      margin: '8px 0',
      borderRadius: '8px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
      backgroundColor: isPractitioner ? '#e3f2fd' : '#e8f5e9',
      borderLeft: isPractitioner ? `5px solid #1e88e5` : `5px solid #43a047`,
    };
  }


  return (
    <ArrayField label={false} source="messages">
      <SimpleList
        sx={{ minHeight: "50vh" }}
        secondaryText={rec => (<div style={{ whiteSpace: "pre-wrap" }}>{rec.content}</div>)}
        primaryText={getAuthor}
        tertiaryText={rec => new Date(rec.createdAt).toLocaleString()}
        linkType={false}
        rowStyle={rowSx}
      />
    </ArrayField>
  );
}