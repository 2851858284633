import React from "react";
import {  Typography } from "@mui/material";
import { Group } from "../../../layout/Group";
import { Stack } from "../../../layout/Stack";

export function LinkDataIssueMessage() {
  return (
    <Group>
      <Stack>
        <Typography>
          There was an issue retrieving the linked PMS case data for this booking.
        </Typography>
        <Typography>
          Please open a Halo ticket if the issue persists.
        </Typography>
      </Stack>
    </Group>
  );
}
