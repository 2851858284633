import { Datagrid, ReferenceManyField, TextField } from "ra-ui-materialui";
import React from "react";
import { Pagination } from "react-admin";
import { ShowTabProps } from "../../../types/utilities";
import { CancelBookingButton } from "../../buttons/CancelBookingButton";
import { CapsDelimitedField } from "../../fields/CapsDelimitedField";
import { FullNameField } from "../../fields/FullNameField";
import { customStyles } from "../../../theme";
import { QuickEditBookingButton } from "../QuickEditBookingButton";
import DateField from "../../fields/DateField";
import { FormTabWithPermission } from "../../form/CustomTabForm/FormTabsWithPermission";

export function BookingsShowTab(props: ShowTabProps) {
  return (
    <FormTabWithPermission
      {...props}
      options={{
        resource: "bookings",
        permission: "enabled",
      }}
      label="Bookings"
      editable="hidden"
    >
      <ReferenceManyField
        sort={{ field: "id", order: "DESC" }}
        reference="bookings"
        target="patientRecord.id"
        label={false}
        pagination={<Pagination />}
      >
        <Datagrid
          bulkActionButtons={false}
          rowClick={(id, basePath, record) => {
            const bookingUserId = record.users[0].id;
            const clientId = record.clients[0].id;
            return `/patientRecords/${bookingUserId}/clients/${clientId}/bookings/${record.id}/summary`;
          }}
          sx={{ ...customStyles.horiztonalScrollableShadows }}
        >
          <TextField label="Booking ID" source="id" />
          <TextField
            sortable={false}
            label="PMS Case Number"
            source="patientManagementSystemCases[0].attributes.caseNumber"
            emptyText="n/a"
          />
          <FullNameField
            label="Clinical Practitioner"
            firstNameSource="appointments[0].clinicalPractitioners[0].attributes.firstName"
            lastNameSource="appointments[0].clinicalPractitioners[0].attributes.lastName"
          />
          <DateField
            sortBy="appointment.start"
            showTime
            hideLabel
            label="Date of consultation"
            source="appointments[0].attributes.start"
          />
          <CapsDelimitedField
            hideLabel
            sortBy="status"
            label="Status"
            source="attributes.status"
          />
          <CancelBookingButton bookingIdSource="id" />
          <QuickEditBookingButton />
        </Datagrid>
      </ReferenceManyField>
    </FormTabWithPermission>
  );
}
