import { create } from "..";

interface Params {
  relationships: {
    clinicalPractitioner: {
      data: {
        id: string;
      };
    },
    clinicalService?: {
      data: {
        id: string
      }
    }
  };
  attributes: {
    start: string;
    end: string;
    consultationType: string;
    clinicalServiceId?: string;
  };
}

async function createAppointment(params: Params) {
  const { relationships, attributes } = params;

  const endpoint = `/ms/appointments`;
  const body = {
    data: {
      type: "adminAppointments",
      attributes: {
        ...attributes,
        clinicalServiceId: attributes.clinicalServiceId ? attributes.clinicalServiceId : null
      },
      relationships: {
        clinicalPractitioner: {
          data: {
            type: "clinicalPractitioners",
            id: relationships.clinicalPractitioner.data.id,
          },
        },
      },
    },
  };

  const res = await create(endpoint, body);

  return res;
}

export default createAppointment;
