import React from "react";
import { maxLength, TextInput } from "react-admin";
import { nonEmpty } from "../../validators/nonEmpty";
import { Grid } from "@mui/material";

export function ReasonForMADInput() {
  return (
    <Grid item xs={12} sm={6}>
      <TextInput
        multiline
        source="messageForTheDoctor"
        label="'Message a Doctor' text"
        style={{ width: "500px" }}
        required
        validate={[
          nonEmpty("A message is required"),
          maxLength(1000, "Message should be no longer than 1000 characters"),
        ]}
      />
    </Grid>
  );
}
