import { create, update } from "..";
import { PatientValidationDTO } from "../../types/validation";
import deepEquals from "../../utilities/deepEquals";

interface Args {
  clientId: string;
  patientIdentifiers: PatientValidationDTO["data"];
  newPatientIdentifiers: PatientValidationDTO["data"];
  isEnabled: boolean;
}

async function updatePatientIdentifiers(args: Args) {
  const { clientId, patientIdentifiers, newPatientIdentifiers, isEnabled } =
    args;

  if (!isEnabled) return;

  // temp fields used on the client side and are removed to ensure the deepEquals is reliable
  delete patientIdentifiers.clientProperties;
  delete newPatientIdentifiers.clientProperties;

  const areEquals = deepEquals(patientIdentifiers, newPatientIdentifiers);

  if (areEquals) return;

  const baseEndpoint = `/ms/clients/${clientId}/multipoints`;

  if (!newPatientIdentifiers.id) {
    const body = {
      data: newPatientIdentifiers,
    };

    return create(baseEndpoint, body);
  }

  const updateEndpoint = `${baseEndpoint}/${patientIdentifiers.id}`;

  const updateBody = {
    data: {
      id: patientIdentifiers.id,
      type: "clientMultipoints",
      attributes: newPatientIdentifiers.attributes,
    },
  };

  return update(updateEndpoint, updateBody);
}

export default updatePatientIdentifiers;
