import React from "react";
import { Group } from "../../layout/Group";
import { Heading } from "../../ui/Heading";
import { Grid, Typography } from "@mui/material";
import get from "lodash/get";
import { ConclusionReport } from "../../../types/prescriptions";
import { useRecordContext } from "react-admin";
import { groupBy } from "../../../utilities/groupBy";
import { styles } from "../PrescriptionsShow";



export function ExtraInformationGroup() {
  const record = useRecordContext();

  const conclusionReport = get(record, "traversal.conclusionReport") as ConclusionReport;
  if (!conclusionReport?.informationConclusions?.length) return null;

  const validConclusions = conclusionReport.informationConclusions
    .filter((conclusion) => conclusion.category1.toLowerCase() === "extra information");

  const groupedConclusions = groupBy(validConclusions, "subCategory");
  if (!Object.keys(groupedConclusions).length) return null;



  return (<Group heading={<Heading level={2}>Extra information</Heading>}>
    {Object.keys(groupedConclusions).map((conclusionName: string, conclusionNameIndex) => (
      <Grid key={conclusionNameIndex} sx={styles.subCategoriesContainer} container>
        <Grid item>
          <Typography sx={styles.subCategoryHeader}>
            {conclusionName}
          </Typography>
          <ul style={{ padding: "0 10px", margin: 0 }}>
            {groupedConclusions[conclusionName]?.map((conclusion, conclusionIndex) => (
              <li
                key={conclusionIndex}
                style={styles.summaryGroup}
                dangerouslySetInnerHTML={{
                  __html: conclusion.displayText
                }}
              />
            ))}
          </ul>
        </Grid>
      </Grid>
    ))}
  </Group>);
}