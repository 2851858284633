import React from "react";
import { Button, ButtonProps, Tooltip } from "@mui/material";
import {
  useCreate,
  useNotify,
  useRefresh,
  useRecordContext,
} from "react-admin";
import DoneIcon from '@mui/icons-material/Done';

type Props = Pick<ButtonProps, "size"> & {
  variant?: 'text' | 'icon';
};

export function CompleteButton(props: Props) {
  const record = useRecordContext();
  const { size = "medium", variant = "text" } = props;
  const { isCompleted } = record!.attributes;

  const notify = useNotify();
  const refresh = useRefresh();

  const [completeItem] = useCreate(
    "completeWorkItem",
    {
      data: {
        id: record?.id,
      },
    },
    {
      onSuccess: () => {
        notify("This work item has been completed");
        refresh();
      },
      onError: () => {
        notify("There was a problem completing this work item");
      },
    }
  );

  if (isCompleted) {
    return null;
  }

  if (variant === 'icon') {
    return (
      <Tooltip title="Mark task as completed">
        <Button
          aria-label="Complete"
          variant="contained"
          size={size}
          color="primary"
          onClick={e => {
            e.stopPropagation();
            return completeItem();
          }}
        >
          <DoneIcon />
        </Button>
      </Tooltip>
  )}

  return (
    <Button
      aria-label="Complete"
      size={size}
      startIcon={<DoneIcon />}
      color="primary"
      variant="contained"
      onClick={e => {
        e.stopPropagation();
        return completeItem();
      }}
    >
      Complete
    </Button>
)}
