import React from "react";
import { Button, Tooltip } from "@mui/material";
import {
  useCreate,
  useNotify,
  useRefresh,
  useRecordContext,
} from "react-admin";
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';

export function QuickAssignButton() {
  const record = useRecordContext();
  const { isAssigned, isCompleted } = record!.attributes;
  const notify = useNotify();
  const refresh = useRefresh();

  const [assignItem] = useCreate(
    "assignWorkItem",
    {
      data: {
        id: record?.id,
      },
    },
    {
      onSuccess: () => {
        notify("You've been assigned the work item");
        refresh();
      },
      onError: () => {
        notify("There was a problem assigning the work item");
      },
    }
  );

  const [unassignItem] = useCreate(
    "unassignWorkItem",
    {
      data: {
        id: record?.id,
      },
    },
    {
      onSuccess: () => {
        notify("This work item's user has been unassigned");
        refresh();
      },
      onError: () => {
        notify("There was a problem unassigning the work item");
      },
    }
  );

  if (isCompleted) {
    return null;
  }
  return isAssigned ? (
    <Tooltip title="Unassign the task">
      <Button
        color="primary"
        size="small"
        aria-label="Unassign"
        variant="outlined"
        onClick={e => {
          e.stopPropagation();
          return unassignItem();
        }}
      >
        <PersonOffIcon />
      </Button>
    </Tooltip>
  ) : (
    <Tooltip title="Assign the task to yourself">
      <Button
        color="primary"
        aria-label="Assign to me"
        variant="outlined"
        size="small"
        onClick={e => {
          e.stopPropagation();
          return assignItem();
        }}
      >
        <PersonIcon />
      </Button>
    </Tooltip>
  );
}
