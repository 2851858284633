import { MutableRefObject, useEffect } from "react";

interface ExtendedOptions extends IntersectionObserverInit {
  callback: IntersectionObserverCallback;
}

function useIntersectionObserver<T extends HTMLElement>(
  ref: MutableRefObject<T | null>,
  options: ExtendedOptions
) {
  useEffect(() => {
    if (!ref) return;

    const { current } = ref;
    const observer = new IntersectionObserver(options.callback, options);

    if (current) {
      observer.observe(current);
    }
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [ref, options]);
}

export default useIntersectionObserver;
