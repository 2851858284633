import { Title, useRecordContext } from "react-admin";
import { PatientNotesAlert } from "./PatientNotesAlert";
import React from "react";

export function PatientTitle() {
  const record = useRecordContext();

  if (!record) return <Title title=" " />;

  const name = `${record?.attributes?.firstName} ${record?.attributes?.lastName}`;
  const hasNotes = record.notes && record.notes.length !== 0;

  return (<>
    <Title title={name} />
    {hasNotes ? <PatientNotesAlert notes={record.notes} /> : null}
  </>);
}
