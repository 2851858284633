import {
  Tab as RATab,
  TabProps,
  FormTab as RAFormTab,
  FormTabProps,
} from "ra-ui-materialui";
import React from "react";
import { useACL } from "ra-access-control-lists";
import { PermissionKey } from "ra-access-control-lists/dist/types";
import { Tooltip } from "@mui/material";

/**
 *
 * PLEASE READ BEFORE MAKING CHANGES TO THIS FILE
 *
 * We're currently in the process of moving over to the custom form tabs to the `CustomTabForm` directory.
 *
 * While we move over there will be some duplication between these form components and the others. We should aim to minimise any changes made
 * to these components and instead try and migrate all tabbed forms to the new component and make changes there.
 */

interface CustomTabProps extends TabProps {
  tooltipMessage?: string;
  disabled?: boolean;
}

/**
 * Use these instead of React Admin's default Tab and FormTab components
 *
 * These components were created as a means of abstracting away the adding of `replace` to ~50 tab components
 */
export function Tab(props: CustomTabProps) {
  return (
    <Tooltip title={props.tooltipMessage ?? ""}>
      <span>
        <RATab {...props} {...{ replace: true }} />
      </span>
    </Tooltip>
  );
}

export function FormTab(props: FormTabProps) {
  return <RAFormTab {...props} {...{ replace: true }} />;
}

/**
 * Temporary until we add custom `Tab` component prop to `ra-access-control-lists`
 */
interface TabWithPermissionProps {
  options: {
    resource: string;
    permission: PermissionKey;
  };
}

type Props = TabWithPermissionProps & TabProps;

export function TabWithPermission(props: Props) {
  const { options } = props;
  const { resource, permission } = options;

  const access = useACL(resource);
  const canAccess = access[permission];

  if (!canAccess) return null;

  return <Tab {...props} />;
}

export function FormTabWithPermission(props: Props) {
  const { options } = props;
  const { resource, permission } = options;

  const access = useACL(resource);
  const canAccess = access[permission];

  if (!canAccess) return null;

  return <FormTab {...props} />;
}
