import pureSplice from "../../utilities/pureSplice";

type ErrorMessage = string;

interface FollowUpActionError {
  attributes: {
    specialty?: ErrorMessage;
    followUpActionType?: ErrorMessage;
  };
}

interface InstructionError {
  attributes: {
    instructions?: ErrorMessage;
    instructionType?: ErrorMessage;
  };
}

interface TermError {
  attributes: {
    version?: ErrorMessage;
    title?: ErrorMessage;
    type?: ErrorMessage;
    activeFrom?: ErrorMessage;
    body?: ErrorMessage;
  };
}

interface Values {
  attributes: {
    name?: string;
  };
  followUpActions: {
    attributes: {
      specialty: string;
      followUpActionType: string;
    };
  }[];
  instructions: {
    attributes: {
      instructions: string;
      instructionType: string;
    };
  }[];
  terms: {
    id?: string;
    attributes: {
      activeFrom: string;
      body: string;
      title: string;
      version: string;
      type: string;
    };
  }[];
}

interface Errors {
  attributes: {
    name?: ErrorMessage;
  };
  followUpActions: FollowUpActionError[];
  instructions: InstructionError[];
  terms: TermError[];
}

function checkifNewVersionIsLower(
  existingTypeVersion: string,
  newTypeVersion: string
) {
  if (!existingTypeVersion || !newTypeVersion) return false;

  const existingVersion = existingTypeVersion.split(".");
  const newVerion = newTypeVersion.split(".");

  if (
    newVerion[0] < existingVersion[0] ||
    (newVerion[0] == existingVersion[0] && newVerion[1] <= existingVersion[1])
  ) {
    return true;
  }
}

function validateGeneralDetails(values: Values) {
  const { followUpActions, instructions, attributes, terms } = values;
  const { name = "" } = attributes;
  const errors: Errors = {
    attributes: {},
    followUpActions: [],
    instructions: [],
    terms: [],
  };

  if (!name || !name.trim()) {
    errors.attributes.name = "All client contracts require a name";
  }

  if (followUpActions?.length) {
    followUpActions.forEach((action, i: number) => {
      if (!action) return;

      const { followUpActionType, specialty } = action.attributes;
      const error: FollowUpActionError = {
        attributes: {},
      };

      const previousFollowUpActions = pureSplice(followUpActions, i);

      const sameFollowUpConfigExists = previousFollowUpActions.some(
        prevAction => {
          const actionTypeMatches =
            prevAction.attributes.followUpActionType === followUpActionType;
          const specialtyMatches =
            prevAction.attributes.specialty === specialty;

          return actionTypeMatches && specialtyMatches;
        }
      );

      if (sameFollowUpConfigExists) {
        error.attributes.followUpActionType =
          "This follow up action and specialty combination already exists.";
        errors.followUpActions[i] = error;
      }
    });
  }

  if (instructions?.length) {
    instructions.forEach((instruction, i: number) => {
      if (!instruction) return;

      const error: InstructionError = {
        attributes: {},
      };

      if (instruction && !instruction?.attributes?.instructions) {
        error.attributes.instructions = "Instructions can not be empty";
      }

      if (instruction && !instruction?.attributes?.instructionType) {
        error.attributes.instructionType = "Please choose an instruction type";
      }

      errors.instructions[i] = error;
    });
  }

  if (terms?.length) {
    terms.forEach((term, i: number) => {
      if (!term) return;

      const regex = /^[0-9].[0-9]$/;

      const error: TermError = {
        attributes: {},
      };

      if (!term?.attributes?.type) {
        error.attributes.type = "The type is required";
      }

      if (!term?.attributes?.body) {
        error.attributes.body = "The body is required";
      }

      if (!term?.attributes?.title) {
        error.attributes.title = "The title is required";
      }

      if (!term?.attributes?.version) {
        error.attributes.version = "The version is required";
      }

      if (
        term &&
        term.attributes.version &&
        !term.attributes.version.match(regex)
      ) {
        error.attributes.version = 'Version must be in the form of "x.x"';
      }

      if (
        !term?.id &&
        term?.attributes?.version &&
        term.attributes.version.match(regex)
      ) {
        const currentType = term?.attributes?.type;
        const currentVersion = term?.attributes?.version;

        const [existingTerm] =
          terms.filter(
            singleTerm =>
              singleTerm.id && singleTerm.attributes.type === currentType
          ) ?? [];

        const existingVersion = existingTerm?.attributes?.version;

        const isVersionLower = checkifNewVersionIsLower(
          existingVersion,
          currentVersion
        );

        if (isVersionLower) {
          error.attributes.version =
            "The new version must be higher than the old version";
        }
      }
      errors.terms[i] = error;
    });
  }
  return errors;
}

export default validateGeneralDetails;
