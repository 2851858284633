import { Tooltip } from "@mui/material";
import get from "lodash/get";
import { useCreate, useGetOne, useNotify, useRecordContext } from "ra-core";

import React, { useState } from "react";
import { ClientPatientManagementSystemMappingDTO } from "../../types/patientManagementSystems";
import displayTooltipMessages from "../../utilities/displayTooltipMessages";
import { Button } from "../buttons/Button";

interface Props {
  label: string;
}

const CLIENT_HAS_NO_ASSIGNED_PMS =
  "The client hasn't assigned a PMS for this specialty";

const CASE_REF_EXISTS =
  "This booking already has an associated Adastra CaseRef";

const PMS_IS_NOT_ENABLED =
  "The client has assigned a PMS for this specialty, but it is not enabled";

const HAS_REQUESTED =
  "It will take a few minutes for Adastra details to be reflected here";

export function AddCaseButtonInternal(props: Props) {
  const { label = "Update Case" } = props;

  const {
    bookingId,
    attributes,
    users,
    clients,
    appointments,
    patientManagementSystemCases,
  } = useRecordContext();

  const [hasRequested, setRequestedState] = useState(false);

  const specialty = get(
    appointments,
    "[0].clinicalPractitioners[0].attributes.specialties[0]"
  );

  const caseRef = get(
    patientManagementSystemCases,
    "[0].attributes.caseReference"
  );

  const userId = users[0].id;
  const clientId = clients[0].id;

  const notify = useNotify();

  const { data } = useGetOne("clients", { id: clientId });

  const [updateCase, { isLoading }] = useCreate(
    "addCase",
    {
      data: {
        clientId,
        bookingId,
        userId,
      },
    },
    {
      onSuccess: () => {
        notify(
          "Adastra has been updated, this will take a few minutes to be reflected here"
        );
        setRequestedState(true);
      },
      onError: () => {
        notify(
          "We had a problem updating adastra, please try again in a few moments"
        );
      },
    }
  );

  const { status } = attributes;
  if (status != "PendingConsultation") {
    return null;
  }

  const assignedPms = get(
    data,
    "assignedPMS",
    []
  ) as ClientPatientManagementSystemMappingDTO[];

  const pmsAssignedToSpecialty =
    assignedPms.find(pms => {
      return pms?.specialties.find(s => s === specialty);
    }) ?? null;

  const isEnabled = get(pmsAssignedToSpecialty, "isEnabled", false);
  const isCaseCreationEnabled = get(
    pmsAssignedToSpecialty,
    "isAutomaticCaseCreationEnabled",
    false
  );

  const isPmsEnabled = isEnabled && isCaseCreationEnabled;

  return (
    <Tooltip
      title={displayTooltipMessages([
        [!pmsAssignedToSpecialty, CLIENT_HAS_NO_ASSIGNED_PMS],
        [!isPmsEnabled, PMS_IS_NOT_ENABLED],
        [Boolean(caseRef), CASE_REF_EXISTS],
        [hasRequested, HAS_REQUESTED],
      ])}
    >
      <span style={{ display: "inline-block" }}>
        <Button
          variant="contained"
          aria-label={label}
          disabled={
            isLoading ||
            !pmsAssignedToSpecialty ||
            caseRef ||
            !isPmsEnabled ||
            hasRequested
          }
          onClick={() => updateCase()}
        >
          {label}
        </Button>
      </span>
    </Tooltip>
  );
}

export function AddCaseButton(props: Props) {
  return <AddCaseButtonInternal {...props} />;
}
