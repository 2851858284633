import { EditTabProps } from "../../../types/utilities";
import { useFormContext } from "react-hook-form";
import { specialtyChoices } from "../../../constants/constants";
import React from "react";
import { FormTab } from "../../Tabs";
import { Typography } from "@mui/material";
import { ArrayInput } from "../../inputs/ArrayInput";
import { SimpleFormIterator } from "../../form/SimpleFormIterator";
import { FormIteratorAddButton } from "../../form/FormIteratorAddButton";
import { FormIteratorRemoveButton } from "../../form/FormIteratorRemoveButton";
import { BooleanInput, FormDataConsumer, ReferenceInput, TextInput } from "react-admin";
import AutocompleteInput from "../../inputs/AutocompleteInput";
import { nonEmpty } from "../../../validators/nonEmpty";
import { ClientClinicianPoolsDTO } from "../../../types/resourcePools";

export function ClientClinicianPoolsEditTab(props: EditTabProps) {
  // need this because AutocompleteInput not always rerender component on change
  const [rerender, setRerender] = React.useState(false);
  const forceRerender = () => {
    setRerender(!rerender); // flip the current state to trigger rerender
  };

  const formValues = useFormContext().getValues();

  if (!formValues.clientClinicianPools) {
    return null;
  }

  const linkedServices = formValues.clientClinicianPools
    .filter((x: ClientClinicianPoolsDTO["data"]) => x.attributes?.resourcePoolId && x.resourcePools?.[0].attributes?.clinicalServiceId)
    .map((x: ClientClinicianPoolsDTO["data"]) => x.resourcePools?.[0].attributes?.clinicalServiceId?.toString());

  const linkedSpecialties = formValues.clientClinicianPools
    .filter((x: ClientClinicianPoolsDTO["data"]) => x.attributes?.resourcePoolId && x.resourcePools?.[0].attributes?.specialty)
    .map((x: ClientClinicianPoolsDTO["data"]) => x.resourcePools?.[0].attributes?.specialty);

  return (
    <FormTab {...props} label="CP Management">
      <Typography variant="h2">
        CP Resource Pools
      </Typography>
      <Typography variant="body1">
        Note: You can link only one resource pool for each specialty
      </Typography>
      <ArrayInput source="clientClinicianPools" level={2}>
        <SimpleFormIterator
          addButton={<FormIteratorAddButton label="Add resource pool" />}
          disableAdd={formValues.clientClinicianPools.length >= specialtyChoices.length ||
            formValues.clientClinicianPools.some((x: ClientClinicianPoolsDTO["data"]) => !x.attributes?.resourcePoolId)}
          removeButton={
            <FormIteratorRemoveButton label="Remove resource pool" sx={{ marginBottom: "5px" }} />
          }
        >
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...rest }) => {
              if (!getSource) return null;

              const service = scopedFormData?.resourcePools?.[0].service;
              const isSpecialityFilter = isNaN(service);

              const resourcePoolFilter = {
                specialty: service && isSpecialityFilter ? service : null,
                clinicalServiceId: service && !isSpecialityFilter ? service : null
              }

              return (<>
                {
                  scopedFormData.resourcePools?.[0]?.attributes?.serviceName ?
                    <TextInput
                      sx={{ width: "248px", marginRight: "20px" }}
                      label={"Service"}
                      source={getSource("resourcePools.[0].attributes.serviceName")}
                      disabled />
                    :
                    <ReferenceInput
                      reference="clinicalServicesWithSpecialities"
                      source={getSource("resourcePools.[0].service")}
                      filter={{excludedServices: linkedServices, excludedSpecialties: linkedSpecialties, deliveryType: "Synchronous"}}
                      isRequired
                    >
                      <AutocompleteInput
                        {...rest}
                        sx={{ display: "inline-flex",  marginRight: "20px", width: "248px" }}
                        required
                        label="Service"
                        allowDuplicates={false}
                        onChange={forceRerender}
                        optionText="name"
                        filterToQuery={query => ({
                          name: query
                        })}
                        disabled={!!scopedFormData.attributes?.resourcePoolId}
                        validate={nonEmpty("Select a service")}
                        groupBy={(choice) => choice.group}
                      />
                    </ReferenceInput>
                    }
                {
                  scopedFormData.id ?
                    <TextInput
                      sx={{ width: "240px" }}
                      label={"Name"}
                      source={getSource("resourcePools.[0].attributes.name")}
                      disabled /> :
                    <ReferenceInput
                      source={getSource(`attributes.resourcePoolId`)}
                      reference="resourcePools"
                      required
                      filter={resourcePoolFilter}
                    >
                      <AutocompleteInput
                        required
                        {...rest}
                        source={getSource(`attributes.resourcePoolId`)}
                        debounce={200}
                        sx={{ display: "inline-flex", width: "240px" }}
                        disabled={!scopedFormData.resourcePools?.[0].service}
                        onChange={forceRerender}
                        label="Resource Pool"
                        optionText="attributes.name"
                        filterToQuery={query => ({
                          name: query
                        })}
                        validate={nonEmpty("Select the resource pool")}
                      />

                    </ReferenceInput>
                }
                <BooleanInput
                  sx={{ display: "inline-flex", width: "fit-content", marginLeft: "20px", marginTop: "20px" }}
                  source={getSource("attributes.isExclusive")}
                  label="Is Exclusive"
                />
              </>);
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>
  );
}
