import React from "react";
import {
  FieldProps,
  useCreate,
  useRecordContext,
  useShowContext,
  RaRecord,
  useNotify,
  LinearProgress,
  Button,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import get from "lodash/get";
import GetAppIcon from "@mui/icons-material/GetApp";
import { Box } from "@mui/system";
import { styled, Typography } from "@mui/material";
import { splitByCapitalLetters } from "../../utilities/splitByCapitalLetters";
import DownloadIcon from "@mui/icons-material/Download";
import { Colors } from "../../theme";
import { getConfig } from "../../config";

const config = getConfig();

interface Props extends FieldProps {
  view: "show" | "edit";
  sources: {
    clientId?: string;
    fileId: string;
    userId?: string;
    fileName: string;
    clinicalPractitionerId?: string;
  };
}

export function DownloadButton(props: Props) {
  if (props.view == "show") {
    return <DownloadButtonInShowView {...props} />;
  }

  return <DownloadButtonInEditView {...props} />;
}

function DownloadButtonInShowView(props: Props) {
  const { record } = useShowContext();
  const arrayRecord = useRecordContext();

  return (
    <InnerDownloadButton
      formValues={record!}
      file={arrayRecord}
      sources={props.sources}
    />
  );
}

function DownloadButtonInEditView(props: Props) {
  const values = useFormContext().getValues();
  const arrayRecord = useRecordContext();

  return (
    <InnerDownloadButton
      formValues={values! as RaRecord}
      file={arrayRecord}
      sources={props.sources}
    />
  );
}

interface InnerDownloadButtonProps {
  formValues: RaRecord;
  file: RaRecord;
  sources: {
    clientId?: string;
    fileId: string;
    userId?: string;
    fileName: string;
    identityUserId?: string;
    id?: string;
    clinicalPractitionerAttachmentType? :string;
  };
}

function InnerDownloadButton(props: InnerDownloadButtonProps) {
  const notify = useNotify();

  const { data } = formatData(props);

  const [downloadFile, { isLoading }] = useCreate();

  const handleClick = () => {
    downloadFile(
      data.source == "gaia" ? "gaia:downloadFile" : "downloadFile",
      {
        data: {
          id: "",
          ...data,
        },
      },
      {
        onError: () => {
          notify("There was a problem downloading your chosen file");
        },
      }
    );
  };

  return (
    <Box style={{ display: "flex", alignItems: "center", height: "100%" }}>
      <Button
        disabled={isLoading}
        color="primary"
        aria-label="Download"
        onClick={() => handleClick()}
        label="Download"
      >
        <GetAppIcon />
      </Button>
      {isLoading && <LinearProgress sx={{ width: "120px" }} />}
    </Box>
  );
}

type DownloadButtonV2Props = {
  label: string;
  nameSource: string;
  titleValue?: string;
  titleSource?: string;
  downloadSources: {
    clientId?: string;
    fileId: string;
    userId?: string;
    fileName: string;
    clinicalPractitionerId?: string;
  };
};

export function DownloadButtonV2(props: DownloadButtonV2Props) {

  const record = useRecordContext(props);
  const name = get(record, props.nameSource)
  const title = props.titleValue != null ? props.titleValue : get(record, props.titleSource!);
  const values = useFormContext().getValues();
  const arrayRecord = useRecordContext();

  const notify = useNotify();

  const { data } = formatData({
      formValues: values! as RaRecord,
      file: arrayRecord,
      sources: props.downloadSources
    });

  const [downloadFile] = useCreate();

  const handleClick = () => {
    downloadFile(
      "downloadFile",
      {
        data: {
          id: "",
          ...data,
        },
      },
      {
        onError: () => {
          notify("There was a problem downloading your chosen file");
        },
      }
    );
  };

  return (
    <StyledDiv onClick={handleClick} >
      <WrapperStyledDiv>
        <Typography sx={typeTextStyles} variant="body1">
          {splitByCapitalLetters(title)}
        </Typography>
        <Typography sx={titleTextStyles} variant="body1">
          {name}
        </Typography>

      </WrapperStyledDiv>
      {<DownloadIcon />}
    </StyledDiv>
  )
}


const formatData = (props: InnerDownloadButtonProps) => {
  const { formValues, file, sources } = props;

  const userId = get(formValues, sources.userId!, "");
  const clientId = get(formValues, sources.clientId!, "");
  const identityUserId = get(formValues, sources.identityUserId!, "");
  const fileId = get(file, sources.fileId);
  const fileName = get(file, sources.fileName);
  const clinicalPractitionerId = get(formValues, sources.id!, "");
  const clinicalPractitionerAttachmentType = get(file, sources.clinicalPractitionerAttachmentType!, "");
  const clinicalPractitionerAttachmentId = get(file, sources.id!, "");

  // if we need to extend it to accommodate more types of URLs, that we should instead refactor the component

  if (formValues.type === "adminClinicalPractitioners") {
    return {
      data: {
        fileId,
        identityUserId,
        fileName,
        url: config.featureFlags.useGaiaForCpDocuments 
          ? `/clinical-practitioners/${clinicalPractitionerId}/documents/${clinicalPractitionerAttachmentType}/${clinicalPractitionerAttachmentId}/download`
          : `/ms/users/${identityUserId}/download/${fileId}`,
        source: config.featureFlags.useGaiaForCpDocuments ? 'gaia' : 'sapi'
      },
    };
  }

  return {
    data: {
      fileId,
      userId,
      clientId,
      fileName,
      url: `/clients/${clientId}/users/${userId}/files/${fileId}/contents`,
    },
  };
};


const StyledDiv = styled("div")(({ theme }) => ({
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  background: Colors.LighterGrey,
  width: "280px",
  padding: "6px",
  borderRadius: "10px",
  boxSizing: "border-box",
  paddingRight: theme.spacing(1),
  '&:hover': {
    background: Colors.LighterBlue,
    color: Colors.BrightBlue,
    cursor: "pointer"
  },
  "svg": {
    display: "none",
  },
  '&:hover svg': {
    display: "block"
  }
}));

const WrapperStyledDiv = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "260px"
}));


const typeTextStyles = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  marginLeft: "8px",
  fontSize: "10px",
  lineHeight: "1.5"
};

const titleTextStyles = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  marginLeft: "8px",
  fontSize: "14px",
  width: "220px",
  lineHeight: "1.5"
}
