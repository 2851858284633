import { GetOneParams } from "react-admin";
import { get } from "../index";

async function getClinicalService(args: GetOneParams) {
  const { id } = args;
  const endpoint = `/ms/clinicalServices/${id}`;

  const params = {
    include: "resourcePools"
  }

  const res = await get(endpoint, params)

  const data = Array.isArray(res.data)
    ? res.data[0]
    : res.data;

  return {
    data: {
      ...data,
      attributes: {
        ...data?.attributes,
        shiftTypeId: data?.attributes?.shiftTypeId?.toString() ?? '',             // AutocompleteInput works correctly only with strings
        fallbackServiceId: data?.attributes?.fallbackServiceId?.toString() ?? ''  // AutocompleteInput works correctly only with strings
      }
    }
  };
}

export default getClinicalService;
