import { GetManyParams } from "react-admin";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";
import { get } from "../index";

const endpoint = "/ms/clinicalServices";
async function getManyClinicalServices(params: GetManyParams) {
  const { ids } = params;
  const filters = {
    "filter[id]": `in:${ids}`
  }

  const filterParams = shallowlyRemoveEmptyValues(filters);

  return await get(endpoint, filterParams);

}

export default getManyClinicalServices;
