import { ClinicalProductsChange } from "../../types/clinicalProducts";
import { ChangeAction } from "../../types/common";
import { createClinicalProduct, updateClinicalProduct } from "./index";

type Args = {
  clinicalProductsChanges: ClinicalProductsChange[];
}

export async function updateManyClinicalProducts(args: Args) {
  const { clinicalProductsChanges } = args;

  const clinicalProductsToAdd =
    clinicalProductsChanges
      .filter(x => x.action === ChangeAction.Added)
      .map(x => x.payload);


  const clinicalProductsToUpdate =
    clinicalProductsChanges
      .filter(x => x.action === ChangeAction.Edited)
      .map(x => ({data: {...x.payload}}));

  const createPromises = clinicalProductsToAdd.map(createClinicalProduct);
  const updatePromises = clinicalProductsToUpdate.map(updateClinicalProduct);


  await Promise.all([
    ...createPromises,
    ...updatePromises
  ]);
}
