import React from "react";
import { Filter, SelectInput, TextInput } from "react-admin";
import { resourceRoles } from "../../constants/constants";

export function ResourceFilter(props: any) {
  return (
    <Filter {...props}>
      <TextInput label="First name" source="firstName" alwaysOn />
      <TextInput label="Last name" source="lastName" alwaysOn />
      <SelectInput
        label="Roles"
        source="role"
        choices={resourceRoles}
        alwaysOn
      />
      <TextInput label="Username" source="username" alwaysOn />
    </Filter>
  );
}
