import { create, update } from "..";
import {
  ChannelSettingsDTO,
  NotificationChannel,
} from "../../types/notifications";
import deepEquals from "../../utilities/deepEquals";
import formatNotificationChannels from "./helpers/formatNotificationChannels";

interface Args {
  clientId: string;
  existingNotificationChannelSettings: ChannelSettingsDTO["data"][];
  notificationChannelSettings: NotificationChannel[];
}

// how can I tell if a value already exists?
const notificationChannels: NotificationChannel[] = ["Email", "SMS"];

async function updateNotificationChannelSettings(args: Args) {
  const {
    clientId,
    notificationChannelSettings,
    existingNotificationChannelSettings,
  } = args;

  const { notificationChannelSettings: prevNotificationSettings } =
    formatNotificationChannels(existingNotificationChannelSettings);

  const noChange = deepEquals(
    prevNotificationSettings,
    notificationChannelSettings
  );

  if (noChange) return null;

  // the only change I need to do is not create

  const baseClientEndpoint = `/ms/clients/${clientId}`;

  try {
    const notificationSettingsEndpoint = `${baseClientEndpoint}/notificationChannelSettings`;

    // a lot of this logic would be simplified once a batch endpoint is added
    const requestBodies = notificationChannels.map(channel => {
      const isEnabled = notificationChannelSettings.includes(channel);

      const notificationChannelId =
        existingNotificationChannelSettings.find(existingChannel => {
          return existingChannel.attributes.notificationChannel === channel;
        })?.id ?? null;

      return {
        type: "notificationChannelSettings",
        attributes: {
          isEnabled,
          notificationChannel: channel,
        },
        id: notificationChannelId,
      };
    });

    const promises = requestBodies.map(requestBody => {
      if (requestBody.id) {
        return update(`${notificationSettingsEndpoint}/${requestBody.id}`, {
          data: requestBody,
        });
      }
      const { id: voidId, ...rest } = requestBody;

      return create(notificationSettingsEndpoint, { data: rest });
    });

    await Promise.all(promises);
  } catch (err) {
    console.error(err);
  }

  return {};
}

export default updateNotificationChannelSettings;
