import {
  Datagrid,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput
} from "react-admin";
import React from "react";
import { ListActions } from "../Actions";

function ClinicalServiceFilter(props: any) {
  return (
    <Filter {...props}>
      <TextInput
        label="Name"
        source="name"
        alwaysOn
        autoComplete="off"
      />
    </Filter>
  )
}

export function ClinicalServiceList() {
  return (
    <List
      actions={<ListActions createLabel="Create" />}
      filters={<ClinicalServiceFilter/>}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField
          label="Name"
          source="attributes.name"
          sortBy="name"
        />
        <TextField
          label="Delivery Type"
          source="attributes.deliveryType"
          sortable={false}
        />
        <TextField
          label="Appointment Duration"
          source="attributes.appointmentDuration"
          sortable={false}
        />
        <ReferenceField
          source="attributes.shiftTypeId"
          reference="quinyxShiftTypes"
          label="Shift Type"
          link={false}
        >
          <TextField source="attributes.name"/>
        </ReferenceField>
      </Datagrid>
    </List>
  )
}
