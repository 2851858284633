import React from "react";
import { Button, Typography } from "@mui/material";
import { useAppointmentSwapStore } from "../../context/appointmentSwapStore";
import dayjs from "dayjs";
import { StateDisplay } from "../ui/StateDisplay";

export function SwapStateDisplay() {
  const store = useAppointmentSwapStore();
  const swappingState = store.getSwappingState();
  const isSwapping = swappingState === "SWAPPING";
  const booking = store.swapFromBooking;
  const startTime = dayjs(booking?.start).format("D MMMM, HH:mma");

  return (
    <StateDisplay
      shouldDisplay={isSwapping}
      message={
        <>
          <Typography sx={{ marginBottom: 1 }} variant="body1">
            You've selected to swap {booking?.cpName}'s {startTime} booking.
          </Typography>
          <Typography variant="body1">
            Please choose an available appointment to swap it with.
          </Typography>
        </>
      }
      action={
        <Button
          color="secondary"
          onClick={() => {
            store.setBooking(null);
          }}
          variant="text"
        >
          Cancel Swap
        </Button>
      }
    />
  );
}
