import { Typography } from "@mui/material";
import React from "react";
import { Stack } from "../layout/Stack";

export function EmptyPMSTab() {
  return (
    <Stack>
      <Typography>
        It looks like no PMS case can be found for this booking.
      </Typography>
      <Typography>
        You'll need to ensure that a PMS has been configured for this client.
      </Typography>
      <Typography>
        If a PMS has already been set up, then there may have been a problem
        syncing the booking to the PMS.
      </Typography>
    </Stack>
  );
}
