import { DeleteParams } from "react-admin";
import { remove } from "..";

async function deleteEmployer(params: DeleteParams) {
  const { id } = params;

  const employersEndpoint = `ms/employers/${id}`;

  const employer = await remove(employersEndpoint);

  return employer;
}

export default deleteEmployer;
