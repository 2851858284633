import React from "react";
import { Tab } from "../../Tabs";
import { ShowTabProps } from "../../../types/utilities";
import { RequestDetailsGroup } from "./RequestDetailsGroup";
import { OutcomesGroup } from "./OutcomesGroup";
import { DemographicsGroup } from "./DemographicsGroup";
import { ExtraInformationGroup } from "./ExtraInformationGroup";


export function GeneralTab(props: ShowTabProps) {
  return (
    <Tab label={"General"} {...props}>
      <RequestDetailsGroup />
      <OutcomesGroup />
      <DemographicsGroup />
      <ExtraInformationGroup />
    </Tab>
  );
}


