import { useWatch } from "react-hook-form";
import { BooleanInput, TextInput } from "ra-ui-materialui";
import React from "react";

export function VNetIntake() {
  const intakeCreationEnabled = useWatch({ name: "enableIntakeCreation", defaultValue: true });

  return (
    <>
      <BooleanInput defaultValue={true} label={"Enable automatic intake creation"} source={"enableIntakeCreation"} />
      {!intakeCreationEnabled && <TextInput required source={"vnetIntakeReference"} label={"Intake Reference"}  /> }
    </>
  );
}