import { DecodedToken, Role } from "../../types/permissions";

// In order of permissiveness
export const roles: Role[] = [
  "Administrator",
  "CSO",
  "ClientAdmin",
  "ClinicalPractitioner",
  "Patient",
];

const getMostPermissiveRole = (userRoles: Role[]) => {
  return roles.find(role => userRoles.includes(role));
};

const getRoleFromToken = (token: DecodedToken): Role | null => {
  const { role: userRoles } = token;
  const isCsoUser = token["medicalsolutions.co.uk/csoUser"];

  const mostPermissiveRole = getMostPermissiveRole(userRoles);

  if (!mostPermissiveRole) return null;

  if (isCsoUser) return "CSO";

  return mostPermissiveRole;
};

export default getRoleFromToken;
