import { Grid } from "@mui/material";
import { useRecordContext } from "ra-core";
import React from "react";
import { useEditStateContext } from "../../../../context/EditStateContext";
import { AddressField } from "../../../fields/AddressField";
import { PatientAddressForm } from "../../../inputs/PatientAddress/PatientAddressInput";
import { Group } from "../../../layout/Group";
import { Heading } from "../../../ui/Heading";

const headingStyle = {
  width: "initial",
};

export function LocationForBooking() {
  const { currentState } = useEditStateContext();

  if (currentState === "show") return <ShowContent />;

  return <EditContent />;
}

function ShowContent() {
  return (
    <Group
      heading={
        <Heading level={2} sx={headingStyle}>
          Location at the time of appointment
        </Heading>
      }
    >
      <AddressField
        label="Booking location"
        source="attributes.contactDetails.address"
      />
    </Group>
  );
}

function EditContent() {
  const record = useRecordContext();
  const { status } = record.attributes;
  const disabled = status !== "PendingConsultation";

  return (
    <Group
      heading={
        <Heading level={2} sx={headingStyle}>
          Location at the time of appointment
        </Heading>
      }
    >
      <Grid item xs={12} sm={6}>
        <PatientAddressForm
          sourcePrefix="attributes.contactDetails.address"
          disabled={disabled}
        />
      </Grid>
    </Group>
  );
}
