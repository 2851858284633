import { isNonEmptyFormLevel } from "../../validators/nonEmpty";
import recursiveIsObjectEmpty from "../../utilities/recursiveIsObjectEmpty";
import validateCPDocuments from "./validateCPDocuments";
import validateResourceRules from "./validateResourceRules";
import { validateRegistrations } from "./validateRegistrations";

type ErrorMessage = string;

interface CPValidation {
  "attributes.email"?: ErrorMessage;
  "attributes.firstName"?: ErrorMessage;
  "attributes.country"?: ErrorMessage;
  "attributes.lastName"?: ErrorMessage;
  "attributes.gender"?: ErrorMessage;
  "attributes.specialty"?: ErrorMessage;
  "attributes.consultationTypes"?: ErrorMessage;
  "attributes.extraCompliance"?: ErrorMessage;
}

function validateCreateCPForm(values: any) {
  const errors: CPValidation = {};

  if (!values.attributes?.email) {
    errors["attributes.email"] = "Please enter a valid email";
  }

  if (!isNonEmptyFormLevel(values.attributes?.firstName)) {
    errors["attributes.firstName"] = "The CP's first name is required";
  }

  if (!isNonEmptyFormLevel(values.attributes?.lastName)) {
    errors["attributes.lastName"] = "The CP's last name is required";
  }

  if (!values.attributes?.gender) {
    errors["attributes.gender"] = "The CP's gender is required";
  }

  if (!values.attributes?.country) {
    errors["attributes.country"] = "The CP's current country is required";
  }

  if (!values.attributes?.specialty) {
    errors["attributes.specialty"] = "The CP's specialty is required";
  }

  if (!values.attributes?.consultationTypes) {
    errors["attributes.consultationTypes"] =
      "The CP's preferred consultation types are required";
  }

  if (!values.attributes?.extraCompliance) {
    errors["attributes.extraCompliance"] = "A CP compliance level is required";
  }

  const resourceRulesErrors = validateResourceRules(values);
  const cpDocumentsRulesErrors = validateCPDocuments(values);
  const registrationNumberErrors = validateRegistrations(values);

  const combinedErrors = {
    ...errors,
    ...resourceRulesErrors,
    ...cpDocumentsRulesErrors,
    ...registrationNumberErrors,
  };

  const errorObjectIsEmpty = recursiveIsObjectEmpty(combinedErrors);

  return errorObjectIsEmpty ? {} : combinedErrors;
}

export default validateCreateCPForm;
