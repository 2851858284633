import get from "lodash/get";
import { useRecordContext } from "ra-core";
import { ArrayField, FieldProps, Labeled } from "ra-ui-materialui";
import React from "react";
import { SimpleIteratorLayout } from "../fields/SimpleIteratorLayout";
import { styled } from "@mui/material";
import { ConsultationOutputField } from "./tabs/DocumentsTab/ConsultationOutputField";

interface ConsultationOutputsListProps extends FieldProps {
  fields?: {
    label: string;
    titleSource: string;
    nameSource: string;
    resourceTypeSource: string;
  }[];
}

const StyledDiv = styled("div")(({ theme }) => ({
  display: "flex",
  minWidth: "360px",
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

type Props = ConsultationOutputsListProps & FieldProps;

export function ConsultationOutputsList(props: Props) {
  const { fields = [], ...rest } = props;
  const record = useRecordContext();

  const consultationId = get(record, "consultations[0].id");
  return (
    <Labeled {...rest} label={`${props.label}`}>
      <ArrayField {...rest}>
        <SimpleIteratorLayout>
          <StyledDiv>
            {fields.map(field => {
              return (
                <ConsultationOutputField
                  key={field.titleSource}
                  nameSource={field.nameSource}
                  titleSource={field.titleSource}
                  resourceTypeSource={field.resourceTypeSource}
                  label={field.label}
                  consultationId={consultationId}
                />
              );
            })}
          </StyledDiv>
        </SimpleIteratorLayout>
      </ArrayField>
    </Labeled>
  );
}
