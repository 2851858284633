import {
  AdminClinicalPractitionerDTO,
  ClinicalPractitionerRegistrationsDTO,
} from "../../../types/clinicalPractitioners";
import { Identifier } from "react-admin";

interface ExpertiseArea {
  type: string;
  id: string;
  attributes: {
    name: string;
    specialty: string;
  };
}

export interface CPRegistrations {
  [index: string]: Registrations | undefined;
  GeneralMedicalCouncil?: Registrations,
  IrishMedicalCouncil?: Registrations,
  GeneralPharmaceuticalCouncil?: Registrations,
  PharmaceuticalSocietyOfIreland?: Registrations,
  BritishAssociationForCounsellingAndPsychotherapy?: Registrations,
  IrishAssociationForCounsellingAndPsychotherapy?: Registrations,
  HealthAndCareProfessionsCouncil?: Registrations,
  CORU?: Registrations,
  NursingAndMidwiferyCouncil?: Registrations,
  NursingAndMidwiferyBoardOfIreland?: Registrations,
  BritishPsychologicalSociety?: Registrations,
  PsychologicalSocietyOfIreland?: Registrations
}


export interface Registrations {
  id: Identifier,
  registrationNumber: string,
  isRegistered: boolean,
}



function getConsultationTypes(
  attributes: AdminClinicalPractitionerDTO["data"]["attributes"]
) {
  if (!attributes) return [];

  const consultationTypes = [];

  if (attributes.isVideoAllowed) {
    consultationTypes.push("Video");
  }

  if (attributes.isPhoneAllowed) {
    consultationTypes.push("Phone");
  }

  if (attributes.isChatAllowed) {
    consultationTypes.push("Chat");
  }

  if (attributes.isOnsiteAllowed) {
    consultationTypes.push("Onsite");
  }

  return consultationTypes;
}

function getFormattedRegistrations(regNumbers: ClinicalPractitionerRegistrationsDTO[]) {
  const formattedRegistrations = {} as CPRegistrations;

  for (const regNumber of regNumbers) {
    formattedRegistrations[regNumber.attributes.professionalBody] = {
      id: regNumber.id!,
      registrationNumber: regNumber.attributes.registrationNumber,
      isRegistered: regNumber.attributes.isRegistered
    }
  }

  return formattedRegistrations;
}



export function formatClinicalPractitioner(clinicalPractitioner: any) {
  if (!clinicalPractitioner?.attributes) return clinicalPractitioner;

  // Set available consultation types
  clinicalPractitioner.attributes.consultationTypes = getConsultationTypes(clinicalPractitioner?.attributes);

  // Set specialty
  clinicalPractitioner.attributes.specialty = clinicalPractitioner?.attributes?.specialties[0];

  // Set available areas
  clinicalPractitioner.attributes.expertiseAreas = clinicalPractitioner?.expertiseAreas
    ?.filter((area: ExpertiseArea) =>
      area.attributes.specialty === clinicalPractitioner.attributes.specialty)
    ?.map(({ attributes }: ExpertiseArea) => attributes.name);

  // Set registration numbers
  clinicalPractitioner.cpRegistrations =
    getFormattedRegistrations(clinicalPractitioner.clinicalPractitionerRegistrations ?? []);

  const expertiseAreas = clinicalPractitioner.expertiseAreas
      ?.map((area: ExpertiseArea) => area.id) ?? [];

  return {
    data: {
      ...clinicalPractitioner,
      expertiseAreas,
    }
  }
}
