import { Box, Typography } from "@mui/material";
import { useChoicesContext } from "ra-core";
import { ReferenceInput, TextInput } from "ra-ui-materialui";
import React, { useState } from "react";
import AutocompleteInput from "../inputs/AutocompleteInput";
import debounce from "lodash/debounce";

const addressWrapperStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginBottom: 2
};

export function PharmacyDetailsInputs() {
  const [addressCity, setAddressCity] = useState("");
  const [address, setAddress] = useState("");

  return (
    <>
      <TextInput
        source="address"
        onChange={debounce(e => {
          setAddress(e.target.value);
        }, 500)}
        label="Pharmacy's Address"
      />
      <TextInput
        source="addressCity"
        onChange={debounce(e => {
          setAddressCity(e.target.value);
        }, 500)}
        label="Pharmacy's City"
      />
      <ReferenceInput
        source="pharmacy"
        reference="gaia:pharmacies"
        sx={{ width: "300px" }}
        filter={{
          addressCity,
          address,
        }}
      >
        <ChosenPharmacyInput />
      </ReferenceInput>
    </>
  );
}

function ChosenPharmacyInput() {
  return (
    <>
      <AutocompleteInput
        label="Patient's Preferred Pharmacy"
        placeholder="Type the pharmacy name..."
        optionText="formattedName"
        required
        filterToQuery={query => ({
          addressName: query,
        })}
      />

      <PharmacyAddress />
    </>
  );
}

function PharmacyAddress() {
  const { selectedChoices } = useChoicesContext();
  const chosenRecord = selectedChoices[0];

  if (!chosenRecord) return null;

  const { address, emailAddress, name } = chosenRecord;
  const { line = [], city, state } = address;

  return (
    <Box sx={addressWrapperStyles}>
      <Typography>{name}</Typography>
      {line.map((addressLine: string) => {
        return <Typography key={addressLine}>{addressLine}</Typography>;
      })}
      <Typography>
        {city}, {state}
      </Typography>
      <Typography>{emailAddress}</Typography>
    </Box>
  );
}
