import { baseUrl, get, remove } from "../";
import { BookingAttachmentsDTO } from "../../types/bookings";

interface Params {
  bookingAttachments: {
    rawFile: File;
  }[];
  clientId: string;
  userId: string;
  bookingId: string;
  attachments: BookingAttachmentsDTO[];
}

async function deleteBookingAttachments(params: Params) {
  const { clientId, userId, bookingId, attachments } = params;
  if (!attachments) return;
  const attachmentsEndpoint = `${baseUrl}/clients/${clientId}/users/${userId}/bookings/${bookingId}/attachments`;

  const bookingRes = await get(attachmentsEndpoint);

  if (bookingRes.data.length === attachments.length) return;

  const attachmentsToRemove = bookingRes.data.filter(
    (bookingAttachment: BookingAttachmentsDTO) =>
      attachments.every(
        updatedAttachement => updatedAttachement.id !== bookingAttachment.id
      )
  );

  for await (const attachment of attachmentsToRemove) {
    const { id } = attachment;
    const attachmentDeletionEndpoint = `${baseUrl}/clients/${clientId}/users/${userId}/bookings/${bookingId}/attachments/${id}`;

    await remove(attachmentDeletionEndpoint);
  }

  return;
}

export default deleteBookingAttachments;
