import React, { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNotify, useCreate, Form } from "react-admin";
import { AuthInput } from "../inputs/AuthInput";
import { Link, Navigate } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import PasswordStrengthMeter from "./PasswordStrengthMeter";
import { Colors } from "../../theme";
import validatePasswordChange from "../../validators/validatePasswordChange";
import { FieldValues } from "react-hook-form";
import { Box } from "@mui/material";
import { Stack } from "../layout/Stack";
import { Group } from "../layout/Group";
import logo from "../../static/logo-red.svg";

const toggleIconStyles = {
  width: "20px",
  height: "20px",
  color: Colors.Grey,
};

const containerStyles = {
  "&": {
    marginTop: "125px",
    width: "450px",
    backgroundColor: `${Colors.White}dd`,
    padding: "24px",
  },
};

const stackStyles = {
  alignItems: "center",
  textAlign: "center",
};

const imageContainerStyles = {
  display: "flex",
  justifyContent: "start",
  width: "100%"
};

export function ChangePasswordForm() {
  const notify = useNotify();

  const [isChanged, setChangedState] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const params = useQueryParams();

  const [resetPassword, { isLoading }] = useCreate();

  const tokenId = params.get("tokenId");
  const token = params.get("token");

  const submit = (data: FieldValues) => {
    resetPassword(
      "changePassword",
      {
        data: {
          tokenId,
          token,
          password: data.password,
        },
      },
      {
        onSuccess: () => {
          setChangedState(true);
        },
        onError: () => {
          notify(
            "We had trouble changing your password. Please let the tech team know if this issue persists"
          );
        },
      }
    );
  };

  if (!token || !tokenId) {
    return <Navigate to="/login" replace />;
  }

  if (isChanged) {
    return (
      <Group sx={containerStyles}>
        <Stack sx={stackStyles}>
          <Box sx={imageContainerStyles}>
            <img src={logo} alt="Logo" height="100" />
          </Box>
          <Box sx={{ width: '100%', marginTop: 0 }}>
            <Typography sx={{ marginTop: 0, fontSize: '20px', fontWeight: 600, textAlign: 'left' }} variant="body1">
              Your password has been changed, you can now sign in to EDIII
            </Typography>
          </Box>
          <Box>
            <Button
              component={Link}
              to="/login"
              variant="contained"
              type="submit"
              color="secondary"
              disabled={isLoading}
            >
              Sign In
            </Button>
          </Box>
        </Stack>
      </Group>
    );
  }

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <Form onSubmit={submit} validate={validatePasswordChange} mode="onBlur">
      <Group sx={containerStyles}>
        <Stack sx={stackStyles}>
          <Box sx={imageContainerStyles}>
            <img src={logo} alt="Logo" height="100" />
          </Box>
          <Box sx={{ width: '100%', marginTop: 0 }}>
            <Typography sx={{ marginTop: 0, fontSize: '20px', fontWeight: 600, textAlign: 'left' }} variant="h1">
              Enter your new password
            </Typography>
          </Box>

          <Box>
            <Typography>
              Your password must be at least 12 characters long, contain upper
              and lower caps, have 1 letter, 1 number, and 1 symbol.
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            <AuthInput
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              startAdornment={<VpnKeyOutlinedIcon style={{ marginRight: 8 }} />}
              endAdornment={
                <IconButton onClick={handleClickShowPassword} size="large">
                  {showPassword ? (
                    <VisibilityOffIcon sx={toggleIconStyles} />
                  ) : (
                    <VisibilityIcon sx={toggleIconStyles} />
                  )}
                </IconButton>
              }
              disabled={isLoading}
            />
            <PasswordStrengthMeter />
          </Box>
          <Box sx={{ width: '100%' }}>
            <AuthInput
              name="confirmPassword"
              label="Confirm Password"
              type={showPassword ? "text" : "password"}
              startAdornment={<VpnKeyOutlinedIcon style={{ marginRight: 8 }} />}
              endAdornment={
                <IconButton onClick={handleClickShowPassword} size="large">
                  {showPassword ? (
                    <VisibilityOffIcon sx={toggleIconStyles} />
                  ) : (
                    <VisibilityIcon sx={toggleIconStyles} />
                  )}
                </IconButton>
              }
              disabled={isLoading}
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={isLoading}
              fullWidth
            >
              {isLoading && <CircularProgress />}
              Reset Password
            </Button>
          </Box>
        </Stack>
      </Group>
    </Form>
  );
}
