import React from "react";
import { Title } from "ra-ui-materialui";
import { Heading } from "../ui/Heading";
import { styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { CreateButton } from "../CreateButton";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { useFlag } from "../../featureFlags";
import { TOGGLE_APPOINTMENTS_PAGE_ENBALED } from "../../featureFlags/flags";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const StyledWrapper = styled("div")(() => ({
  maxWidth: 600,
  textAlign: "center",
  margin: "0 auto",
}));

const StyledImgWrapper = styled("div")(({ theme }) => ({
  background: theme.palette.grey[300],
  opacity: 0.87,
  width: 200,
  height: 200,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",
}));

const imageStyles = { height: 150, width: 150, color: "grey.700" };

export function AppointmentList() {
  return (
    <>
      <Title title="Appointments" />
      <StyledWrapper>
        <StyledImgWrapper>
          <EventAvailableIcon sx={imageStyles} />
        </StyledImgWrapper>
        <Heading level={2}>Appointments</Heading>
        <Typography sx={{ marginBottom: 1.5 }} variant="body1">
          You won't find a list of appointments here. The{" "}
          <StyledLink to="/schedule">scheduler</StyledLink> is great for viewing
          appointments.
        </Typography>
        <Typography sx={{ marginBottom: 1.5 }} variant="body1">
          If you want to create multiple appointments for a clinical
          practitioner, you will need to create a{" "}
          <Link to="/shifts">shift</Link>.
        </Typography>
        <CreateButton
          initialValues={{}}
          size="medium"
          resource="adminAppointments"
          label="Create a single appointment"
        />
      </StyledWrapper>
    </>
  );
}
