import { RaRecord, useNotify, useRedirect } from "ra-core";
import { BooleanInput, Create, SimpleForm, TextInput } from "react-admin";
import React from "react";
import { nonEmpty } from "../../validators/nonEmpty";
import { AutocompleteArrayInput, ReferenceArrayInput } from "ra-ui-materialui";


export function ClinicalProductsCreate() {
  const notify = useNotify();
  const redirect = useRedirect();

  function onSuccess(record: RaRecord) {
    notify("Clinical product was created successfully");
    redirect(`/clinicalProducts/${record.id}/show`);
  }


  return (
    <Create
      mutationOptions={{
        onSuccess,
      }}
      title="Create Product"
    >
      <SimpleForm
        warnWhenUnsavedChanges
        mode="onChange"
      >
        <TextInput
          required
          validate={nonEmpty("Name is required")}
          source="attributes.name"
          label="Name"
        />
        <TextInput
          required
          validate={nonEmpty("Code is required")}
          source="attributes.code"
          label="Code"
          inputProps={{ maxLength: 10 }}
        />
        <BooleanInput
          label="Is Enabled"
          source="attributes.isEnabled"
          sx={{marginBottom: "5px"}}
        />
        <ReferenceArrayInput
          source={"servicesIds"}
          reference="clinicalServicesWithSpecialities"
          filter={{ deliveryType: "Synchronous" }}
          required
        >
          <AutocompleteArrayInput
            data-testId={'servicesInput'}
            id={"services"}
            sx={{ display: "inline-flex" }}
            label="Services"
            optionText="name"
            filterToQuery={query => ({
              name: query
            })}
            groupBy={(choice) => choice.group}
          />
        </ReferenceArrayInput>

      </SimpleForm>
    </Create>)

}