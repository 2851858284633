import get from "lodash/get";
import { TextFieldProps } from "ra-ui-materialui";
import React from "react";
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";
import makeStyles from "@mui/styles/makeStyles";
import { useRecordContext } from "ra-core";

// We aren't replace this style because react-mde is transitioning to a new way of handling styles
// Once this is complete we should look to remove the below.
const useStyles = makeStyles({
  preview: {
    minHeight: "0 !important",
    "& .mde-preview-content": {
      paddingLeft: "0",
    },
  },
  reactMde: {
    border: "none",
    ["& .mde-header"]: {
      display: "none",
    },
    whiteSpace: "break-spaces",
  },
});

export function MarkdownField(props: TextFieldProps) {
  const { source = "" } = props;

  const record = useRecordContext(props);

  const classes = useStyles();

  if (!record) return null;

  const deets = get(record, source);

  return (
    <ReactMde
      generateMarkdownPreview={markdown =>
        Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
      }
      selectedTab="preview"
      classes={classes}
      disablePreview
      value={deets}
      readOnly
    />
  );
}
