import { create, get } from "../";
import acceptTerm from "../acceptTerm";
import getValidationHeaders from "../helpers/getValidationHeaders";
import { PatientRecordDTO } from "../../types/patients";
import { NewDTO } from "../../types/utilities";
import { v4 as uuid } from "uuid";

interface Body {
  clientId: string;
  email: string;
  documents: {
    rawFile: File;
  }[];
  dependantOf?: string;
  validationOverride?: boolean;
}

function generatePatientRequestBody(
  patientDetails: any
): NewDTO<PatientRecordDTO["data"]["attributes"]> {
  return {
    ...patientDetails,
    gp: patientDetails.gp ?? null,
    nominatedPharmacy: patientDetails.nominatedPharmacy ?? null,
    hasVerifiedEmail: false,
  };
}

async function createPatientRecord(body: Body) {
  const { clientId, email, validationOverride = false } = body;

  const validationHeaders = getValidationHeaders(validationOverride);

  const reqConfig = {
    headers: {
      ...validationHeaders,
    },
  };

  const patientAttributes = generatePatientRequestBody(body);
  const createUserEndpoint = `/admin/clients/${clientId}/createUserWithPatientRecord`;

  const userAttributes = {
    userName: email || `${clientId}-${uuid()}`,
    password: null,
    clientIdentifier: patientAttributes.clientIdentifier,
  };

  const createBody = {
    data: {
      type: "userWithPatientRecord",
      attributes: {
        user: userAttributes,
        patientRecord: patientAttributes,
      },
    },
  };

  const res = await create(createUserEndpoint, createBody, reqConfig);

  const { id: userId } = res.data;

  const pendingTermsEndpoint = `/clients/${clientId}/users/${userId}/terms?filter[status]=Pending`;
  const pendingTerms = await get(pendingTermsEndpoint);

  const acceptTermRequests = pendingTerms.data.map(
    ({ id: termId }: { id: string }) => {
      const data = { termId, userId, clientId };
      acceptTerm({ data, previousData: data, id: "" });
    }
  );

  await Promise.all(acceptTermRequests);

  const patientRecordEndpoint = `/clients/${clientId}/users/${userId}/patientRecord`;
  const query = {
    include: "client",
  };

  const patientRes = await get(patientRecordEndpoint, query);

  return {
    data: patientRes.data[0],
  };
}

export default createPatientRecord;
