import React from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Datagrid,
  TextField,
} from "ra-ui-materialui";
import { ListContextProvider, useList } from "react-admin";
import { PmsPatientCaseManualLink } from "../../../../types/patientManagementSystems";

const StyledLinkDiv = styled("div")(() => ({
  width: "100%",
  padding: "20px 0 0 0",
}));

interface PmsEditCaseLinkedPanelProps {
  linkedPmsCase: PmsPatientCaseManualLink,
  onUnlinkClick: () => void,
}

export function PmsEditCaseLinkedPanel(props: PmsEditCaseLinkedPanelProps) {
  const listContext = useList({ data: [ { id: 0, ...props.linkedPmsCase, } ] });

  return (
    <StyledLinkDiv>
      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={false}>
          <TextField 
            source="caseNumber"
            label="Case Number"
            sortable={false}
          />
          <TextField 
            source="caseReference"
            label="Case Reference"
            sortable={false}
          />
          <UnlinkButton {...props} />
        </Datagrid>
      </ListContextProvider>
    </StyledLinkDiv>
  )
}

function UnlinkButton(props: PmsEditCaseLinkedPanelProps) {
  return (
    <Button
      sx={{ margin: 1 }}
      size="small"
      label="Unlink"
      variant="contained"
      onClick={() => props.onUnlinkClick()}
    />
  );
}