import { SchedulerWrapper as Scheduler } from "./SchedulerWrapper";
import SchedulerIcon from "@mui/icons-material/Event";

export { SchedulerIcon };

export const schedulerResources = {
  name: "schedule",
  list: Scheduler,
  icon: SchedulerIcon,
  options: { label: "Schedule" },
};
