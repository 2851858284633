import { Identifier } from "ra-core";
import { get } from "..";

interface Args {
  clientId: Identifier;
}

async function getMaintenanceWindows(args: Args) {
  const { clientId } = args;
  const endpoint = `/ms/clients/${clientId}/clientMaintenanceWindows`;

  const { data } = await get(endpoint, { include: "clientAppointmentFilter" });

  return data;
}

export default getMaintenanceWindows;
