import { UpdateParams } from "ra-core";
import { create } from "..";

type Body = {
  clinicalPractitioners: {
    id: string;
  }[];
  id: string;
};

async function assignQuinyxResource({ data }: UpdateParams<Body>) {
  const { id, clinicalPractitioners = [] } = data;
  const baseEndpoint = `/ms/resourceManagement/quinyxClinicalPractitioners/${id}/assign/${clinicalPractitioners[0].id}`;

  const res = await create(baseEndpoint, {});

  return res;
}

export default assignQuinyxResource;
