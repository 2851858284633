import React, { useState } from "react";
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  TextInput,
  useGetList,
  useList,
} from "react-admin";

import { GaiaGpSurgery } from "../../types/gaia";
import { SimpleForm, TextField } from "ra-ui-materialui";

import { ClientEmployerDTO } from "../../types/clientEmployer";
import { Box, Button, Card, Typography } from "@mui/material";
import debounce from "lodash/debounce";
import { CreateButton } from "../CreateButton";

function Toolbar(props: any) {
  return (
    <SimpleForm
      toolbar={false}
      noValidate
      sx={{
        padding: 0,
        paddingBottom: "0px !important",
        "& .MuiStack-root": {
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        },
      }}
    >
      <Box>
        <TextInput
          label="Name Filter"
          source={"nameFilter"}
          name={"nameFilter"}
          sx={{ marginRight: "1rem" }}
          onChange={e => props.onFilterChange("name", e.target.value)}
        />
        <TextInput
          label="Address Filter"
          source={"addressFilter"}
          name={"addressFilter"}
          sx={{ marginRight: "1rem" }}
          onChange={e => props.onFilterChange("address", e.target.value)}
        />
        <TextInput
          label="City Filter"
          source={"cityFilter"}
          onChange={e => props.onFilterChange("city", e.target.value)}
          name={"cityFilter"}
        />
      </Box>
      <Box sx={{ marginTop: "7px" }}>
        <CreateButton
          initialValues={{}}
          label={"Create"}
          size="medium"
          resource={"gpSurgeries"}
        />
      </Box>
    </SimpleForm>
  );
}

export const NoSurgeriesMessage = () => (
  <Card style={{ padding: 16 }}>
    <Typography variant="body1">No GP Surgeries found</Typography>
  </Card>
);

export function GpSurgeriesList() {
  const perPage = 5;
  const [pageToken, setPageToken] = useState(null);
  const [surgeries, setSurgeries] = useState<GaiaGpSurgery[]>([]);
  const [shouldReplaceResponse, setShouldReplaceResponse] =
    useState<boolean>(false);
  const [filter, setFilter] = useState({
    name: null,
    address: null,
    city: null,
  });

  const { pageInfo, isLoading } = useGetList(
    "gpSurgeries",
    {
      // @ts-ignore missing page property
      pagination: { perPage },
      filter: filter,
      meta: { pageToken },
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => {
        if (shouldReplaceResponse) {
          setSurgeries(data);
        } else {
          setSurgeries([...surgeries, ...data]);
        }
      },
    }
  );

  // @ts-ignore
  const listContext = useList<ClientEmployerDTO["data"]>({ data: surgeries });

  const loadMoreItems = () => {
    // @ts-ignore
    setPageToken(pageInfo.nextPageToken);
    setShouldReplaceResponse(false);
  };

  const onFilterChange = debounce((filterName, value) => {
    setShouldReplaceResponse(true);
    setFilter({ ...filter, [filterName]: value });
    setPageToken(null);
  }, 500);

  return (
    <ListContextProvider value={listContext}>
      <Typography variant="h1" style={{ marginTop: 0 }}>
        Surgeries
      </Typography>
      <Toolbar onFilterChange={onFilterChange} />
      <Datagrid
        rowClick="show"
        bulkActionButtons={false}
        empty={<NoSurgeriesMessage />}
      >
        <TextField source="name" />
        <TextField source="email" />
        <TextField label="City" source="address.city" />
        <FunctionField
          label="Address"
          render={(record: GaiaGpSurgery) => record.address.line.join(", ")}
        />
        <TextField label="Postal Code" source="address.postalCode" />
      </Datagrid>
      <Box display="flex" justifyContent="center">
        <Button
          data-testid={"LoadMoreButton"}
          sx={{ marginTop: "10px" }}
          onClick={loadMoreItems}
          variant="outlined"
          size="large"
          // @ts-ignore
          disabled={isLoading || !pageInfo?.nextPageToken}
        >
          Load more
        </Button>
      </Box>
    </ListContextProvider>
  );
}
