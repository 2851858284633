import { list } from "..";
import { QuinyxUnitDTO } from "../../types/quinyxUnits";

async function listQuinyxUnits() {
  const endpoint = "ms/resourceManagement/quinyxUnits";
  const { data, total } = await list(endpoint);

  data.sort((a: QuinyxUnitDTO["data"], b: QuinyxUnitDTO["data"]) =>
    a.attributes.name
      .toLowerCase()
      .localeCompare(b.attributes.name.toLowerCase())
  );
  return { data, total };
}

export default listQuinyxUnits;
