import React from "react";
import { ArrayField, Datagrid, TextField } from "ra-ui-materialui";
import { HolidayCalendarField } from "../../fields/HolidayCalendarField";
import { Heading } from "../../ui/Heading";
import { ShowTabProps } from "../../../types/utilities";
import { Tab } from "../../Tabs";
import { CapsDelimitedField } from "../../fields/CapsDelimitedField";
import DateField from "../../fields/DateField";
import { TrimmedTimeField } from "../fields/TrimmedTimeField";

export function ServiceConfigShowTab(props: ShowTabProps) {
  return (
    <Tab {...props} label="Service Config">
      <Heading level={2}>Appointment Filters</Heading>
      <TrimmedTimeField
        label="Daily start time"
        source="appointmentFilters.attributes.dailyStartTime"
      />
      <TrimmedTimeField
        label="Daily end time"
        source="appointmentFilters.attributes.dailyEndTime"
      />
      <TextField
        label="Hold duration in minutes"
        source="appointmentFilters.attributes.appointmentHoldDurationMinutes"
      />
      <HolidayCalendarField
        source="appointmentFilters.clientHolidayCalendars[0].id"
        label="Holiday Calendar"
      />
      <ArrayField
        source="appointmentFilterCategories"
        label="Categories"
      >
        <Datagrid bulkActionButtons={false}>
          <CapsDelimitedField
            sortable={false}
            hideLabel
            source="name"
            label="Specialty / Clincal Service"
          />
          <CapsDelimitedField
            sortable={false}
            hideLabel
            source="consultationType"
            label="Consultation Type"
          />
        </Datagrid>
      </ArrayField>
      <Heading level={2}>Scheduled Maintenance</Heading>
      <ArrayField
        label="Upcoming maintenance windows"
        source="maintenanceWindows"
      >
        <Datagrid bulkActionButtons={false}>
          <TextField sortable={false} label="ID" source="id" />
          <TextField
            sortable={false}
            label="Description"
            source="attributes.description"
          />
          <DateField
            sortable={false}
            showTime
            label="Start"
            hideLabel
            source="attributes.start"
          />
          <DateField
            sortable={false}
            showTime
            label="End"
            hideLabel
            source="attributes.end"
          />
        </Datagrid>
      </ArrayField>
    </Tab>
  );
}
