import React from "react";
import { Filter, TextInput, BooleanInput } from "react-admin";

export function ClientFilter(props: any) {
  return (
    <Filter {...props}>
      <TextInput label="Contract Name" source="name" alwaysOn />
      <TextInput label="Client ID" source="id" alwaysOn />
      <BooleanInput
        label="Show Discarded Clients"
        source="shouldShowDiscardedClients"
        alwaysOn
      />
    </Filter>
  );
}
