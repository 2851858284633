import React from "react";
import { ComponentStory, ComponentMeta } from "@storybook/react";
import { Button as ButtonComponent } from "../components/buttons/Button";
import Add from "@mui/icons-material/Add";

const Template: ComponentStory<typeof ButtonComponent> = args => {
  return <ButtonComponent {...args} />;
};

export const Button = Template.bind({});
Button.args = {
  children: "Button",
  disabled: false,
  fullWidth: false,
  variant: "contained",
  color: "primary",
  startIcon: "Add",
  size: "medium",
  disableElevation: true,
};

export default {
  title: "EDS/Components/Button",
  component: Button,
  argTypes: {
    onClick: { action: "clicked" },
    variant: {
      control: {
        options: ["contained", "outlined", "text"],
        type: "select",
        labels: {
          contained: "primary",
          outlined: "secondary",
          text: "tertiary",
        },
      },
    },
    fullWidth: {
      control: {
        type: "boolean",
      },
    },
    color: {
      control: {
        type: "select",
        options: ["primary", "secondary"],
        labels: {
          primary: "Blue",
          secondary: "Coral",
        },
      },
    },
    disabled: {
      type: "boolean",
    },
    disableElevation: {
      type: "boolean",
    },
    size: {
      control: {
        type: "select",
        options: ["small", "medium"],
      },
    },
    startIcon: {
      options: ["None", "Add"],
      mapping: {
        None: null,
        Add: <Add />,
      },
      control: {
        type: "select",
      },
    },
  },
} as ComponentMeta<typeof ButtonComponent>;
