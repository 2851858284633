import createPersistedState from "use-persisted-state";

const useShortcutsStateFactory = createPersistedState("areShortcutsEnabled");

export const useShortcutsState = () => {
  const defaultValue = localStorage.getItem("areShortcutsEnabled") ?? "false";

  const [areShortcutsEnabled, setShortcutsEnabledState] =
    useShortcutsStateFactory(JSON.parse(defaultValue));

  return [areShortcutsEnabled, setShortcutsEnabledState];
};
