import dayjs from "dayjs";
import React from "react";
import { useRecordContext, Button } from "react-admin";
import { Link } from "react-router-dom";

const buttonStyles = {
  margin: "16px 0",
};

export const AddDependantButton = React.memo(function AddDependantButton() {
  const record = useRecordContext();

  if (!record) return null;

  const { dateOfBirth, address } = record.attributes;
  const sixteenYearsAgo = dayjs().subtract(16, "years");
  const underAgeOfMajority = dayjs(sixteenYearsAgo).isBefore(dateOfBirth);

  if (underAgeOfMajority) return null;

  const partialRecord = {
    isDependant: true,
    dependantOf: record.patientId,
    guardianAddress: address,
  };

  return (
    <Button
      component={Link}
      label="Add Dependant"
      sx={buttonStyles}
      color="primary"
      aria-label="Add Dependant"
      variant="contained"
      size="medium"
      to={{
        pathname: `/patientRecords/create`,
        state: {
          record: partialRecord,
        },
      }}
    />
  );
});
