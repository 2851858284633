import { Tab } from "../../Tabs";
import { Group } from "../../layout/Group";
import { Stack } from "../../layout/Stack";
import { PatientLinkField } from "../../booking/PatientLinkField";
import { Labeled, TextField } from "react-admin";
import { CountField } from "../../fields/CountField";
import React from "react";
import { ShowTabProps } from "../../../types/utilities";

export function GeneralDetailsShowTab(props: ShowTabProps) {
  return (
    <Tab {...props} label="General">
      <Group>
        <Stack>
          <PatientLinkField label="Name" />
          <Labeled label="ID">
            <TextField source="id" />
          </Labeled>
          <Labeled label="Status">
            <TextField source="attributes.status" />
          </Labeled>
          <Labeled label="Product ID">
            <TextField
              source="messages[0].attributes.payload.productId"
              emptyText="n/a"/>
          </Labeled>
          <Labeled label="Messages Count">
            <CountField source="messages" />
          </Labeled>
          <Labeled label="Employer">
            <TextField
              source="employers[0].attributes.name"
              emptyText="n/a"
            />
          </Labeled>
        </Stack>
      </Group>
    </Tab>
  )
}
