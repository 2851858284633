import recursiveIsObjectEmpty from "../../utilities/recursiveIsObjectEmpty";

type ErrorMessage = string;

interface GpSurgeryValidation {
  name?: ErrorMessage;
  email?: ErrorMessage;
  "address.line[0]"?: ErrorMessage;
  "address.city"?: ErrorMessage;
  "address.postalCode"?: ErrorMessage;
}

const emailPattern =
  /^[^\s@]+@[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?(\.[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?)*\.[a-zA-Z]{2,}$/;

export const nhsEmailDomains = [
  "nhs.net",
  "nhs.uk",
  "nhs.scot",
  "wales.nhs.uk",
  "hscni.net",
];

export const allowedNonProdDomains = [
  "mailosaur.com",
  "healthhero.com"
];

export const allowedProdDomains = [
  ...nhsEmailDomains,
  "healthhero.com"
];

const isAllowedNonProdEmail = (email: string) => {
  const domain = email.split("@")[1];
  return allowedNonProdDomains.some(
    nhsDomain => domain === nhsDomain || domain.endsWith(`.${nhsDomain}`)
  );
};

const isAllowedProdEmail = (email: string) => {
  const domain = email.split("@")[1];
  return allowedProdDomains.some(
    prodDomain => domain === prodDomain || domain.endsWith(`.${prodDomain}`)
  );
};

export function validateCreateGpSurgery(values: any) {
  const isProdEnv = process.env.REACT_APP_ENV === "prod";
  const errors: GpSurgeryValidation = {};

  // return errors;

  if (!values.name || values.name.trim() === "") {
    errors["name"] = "Please enter a surgery name";
  }

  if (!values.email || values.email.trim() === "") {
    errors["email"] = "Please enter an email address";
  } else if (!emailPattern.test(values.email.trim())) {
    errors["email"] = "Please enter a valid email address";
  } else if (isProdEnv && !isAllowedProdEmail(values.email)) {
    errors[
      "email"
    ] = `Email should have an allowed domain: ${allowedProdDomains.join(", ")}`;
  } else if (!isProdEnv && !isAllowedNonProdEmail(values.email)) {
    errors["email"] = `Email should have an allowed domain: ${allowedNonProdDomains.join(
      ", "
    )}`;
  }

  if (!values.address.line[0] || values.address.line[0].trim() === "") {
    errors["address.line[0]"] = "Please enter a first line of address";
  }

  if (!values.address.city || values.address.city.trim() === "") {
    errors["address.city"] = "Please enter a city";
  }

  if (!values.address.postalCode || values.address.postalCode.trim() === "") {
    errors["address.postalCode"] = "Please enter a postal code";
  }

  return recursiveIsObjectEmpty(errors) ? {} : errors;
}
