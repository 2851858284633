import { PaginationPayload } from "react-admin";
import { list } from "../";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";
import isObjectEmpty from "../../utilities/isObjectEmpty";

async function listClinicalPractitioners(params: {
  filter: any;
  pagination: PaginationPayload;
}) {
  const { filter, pagination} = params;
  const { page, perPage } = pagination;
  const { firstName, lastName, fullName, specialty, q, email, showDisabled, ignoreWithoutFilters } = filter;
  const firstNameFilter = q ?? firstName;

  const filterParams = shallowlyRemoveEmptyValues({
    "filter[firstName]": firstNameFilter ? `like:${firstNameFilter}` : "",
    "filter[lastName]": lastName ? `like:${lastName}` : "",
    "filter[fullName]": fullName ? `like:${fullName}` : "",
    "filter[specialties]": specialty ? `in:${specialty}` : "",
    "filter[email]": email ? `like:${email}` : "",
    "filter[isEnabled]": showDisabled ? "" : "true",
  });

  const hasNoFilter = isObjectEmpty(filterParams);

  if (ignoreWithoutFilters && hasNoFilter) {
    return {
      data: [],
      total: 0,
    };
  }

  const includes = ["resourceManagementRules"];
  const pageParams = {
    "page[number]": page,
    "page[size]": perPage,
  };

  const query = {
    ...pageParams,
    ...filterParams,
    include: includes.join(","),
  };


  try {
    const { data, total } = await list(`ms/clinicalPractitioners`, {
      params: query,
    });
    return {
      data,
      total,
    };
  } catch (err) {
    console.log(err);
  }
  return {
    data: [],
    total: 0,
  };
}

export default listClinicalPractitioners;
