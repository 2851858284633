import { Box, Tabs } from "@mui/material";
import { getTabbedFormTabFullPath } from "ra-ui-materialui";
import React, {
  isValidElement,
  Children,
  ReactElement,
  cloneElement,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import useForceRerender from "../../../hooks/useForceRerender";
import { useStickyTabs } from "../../ScrollableTabLayouts";

interface Props {
  url: string;
  value: string;
  children: React.ReactElement;
}

function FormTabsInner(props: Props) {
  const { children, value, ...rest } = props;
  const params = useParams();
  const tabValue = params["*"];

  return (
    <Tabs
      value={tabValue}
      TabIndicatorProps={{ style: { display: "none" } }}
      variant="scrollable"
      {...rest}
    >
      {Children.map(children, (tab: ReactElement, index) => {
        if (!isValidElement<any>(tab)) return null;
        const tabPath = getTabbedFormTabFullPath(tab, index);

        return cloneElement(tab, {
          intent: "header",
          value: tabPath,
        });
      })}
    </Tabs>
  );
}

export function FormTabs(props: any) {
  const ref = useRef<HTMLDivElement>(null!);

  const stickyStyles = useStickyTabs(ref);
  useForceRerender();

  return (
    <Box ref={ref} sx={{ minHeight: "48px", position: "relative" }}>
      <FormTabsInner {...props} style={stickyStyles} />
    </Box>
  );
}
