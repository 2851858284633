import { PatientList } from "./PatientList";
import { PatientCreate } from "./PatientCreate";
import { PatientEdit } from "./PatientEdit";
import PatientIcon from "@mui/icons-material/LocalHospital";

export { PatientIcon };

export const patientResources = {
  options: { label: "Patients and Bookings" },
  name: "patientRecords",
  list: PatientList,
  icon: PatientIcon,
  edit: PatientEdit,
  create: PatientCreate,
};
