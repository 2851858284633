import { create, update } from "..";
import { PaymentSettingsDTO } from "../../types/payments";
import deepEquals from "../../utilities/deepEquals";

type Args = {
  clientId: string;
  payments: PaymentSettingsDTO["data"];
  oldPayments: PaymentSettingsDTO["data"];
};

async function updatePayments(args: Args) {
  const { clientId, payments, oldPayments } = args;

  const noChange = deepEquals(payments, oldPayments);

  if (noChange) {
    return null;
  }

  const paymentSettings = `/ms/clients/${clientId}/paymentSettings`;

  if (!payments) return;

  const isPaymentEnabled = payments.attributes.isPaymentQueryEnabled;

  // if no paymentId and is not enable -> do nothing
  if (!payments.id && isPaymentEnabled) {
    const paymentQueryConfigBody = {
      type: "clientPaymentSettings",
      attributes: payments.attributes,
    };

    await create(paymentSettings, { data: paymentQueryConfigBody });
  } else if (payments.id && !isPaymentEnabled) {
    const updateEndpoint = `${paymentSettings}/${payments.id}`;
    // if has paymentId and is disabled -> change query config to enabled false
    const paymentQueryConfigBody = {
      type: "clientPaymentSettings",
      id: payments.id,
      attributes: payments.attributes,
    };

    await update(updateEndpoint, { data: paymentQueryConfigBody });
  } else if (payments.id && isPaymentEnabled) {
    // if paymentid and is enabled -> update query config and rest endpoint
    const updateEndpoint = `${paymentSettings}/${payments.id}`;

    const paymentQueryConfigBody = {
      type: "clientPaymentSettings",
      id: payments.id,
      attributes: payments.attributes,
    };

    await update(updateEndpoint, { data: paymentQueryConfigBody });
  }
}

export default updatePayments;
