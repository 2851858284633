import {
  ClinicalPractitionerAttachmentsDTO,
  ClinicalPractitionerDocumentsType,
} from "../../types/clinicalPractitioners";
import pureSplice from "../../utilities/pureSplice";

type ErrorMessage = string;

export interface Values {
  cpDocuments?: {
    attributes: {
      cpDocumentType: ClinicalPractitionerDocumentsType;
    };
  }[];
  clinicalPractitionerAttachments?: ClinicalPractitionerAttachmentsDTO[];
}

const singleDocumentsAllowed = [
  "ClinicalPractitionerESignature",
  "ClinicalPractitionerPhoto",
];

const documentTypes = {
  ClinicalPractitionerESignature: "Clinical Practitioner E-Signature",
  ClinicalPractitionerPhoto: "Clinical Practitioner Photo",
  ClinicalPractitionerDocument: "Clinical Practitioner Document",
};

function validateCPDocuments(values: Values) {
  const errors: Record<string, ErrorMessage> = {};

  const { cpDocuments = [], clinicalPractitionerAttachments = [] } = values;

  const selectedTypes = cpDocuments
    .filter(item => item)
    .map(item => item.attributes.cpDocumentType);

  const uploadedTypes = clinicalPractitionerAttachments.map(
    (item: ClinicalPractitionerAttachmentsDTO) =>
      item.attributes.clinicalPractitionerAttachmentType
  );

  if (cpDocuments?.length) {
    cpDocuments.forEach((attachment, i) => {
      if (!attachment) return;

      const { cpDocumentType } = attachment.attributes;

      const previousSelectedTypes = pureSplice(selectedTypes, i);
      const existingTypes = [...uploadedTypes, ...previousSelectedTypes].reduce(
        (unique: Array<string>, item: string) =>
          unique.includes(item) ? unique : [...unique, item],
        []
      );

      const invalidAttachment = existingTypes.some(prevType => {
        const isOnlySingleDocAllowed =
          singleDocumentsAllowed.includes(prevType);

        const cpDocumentAlreadyExists = cpDocumentType === prevType;
        return isOnlySingleDocAllowed && cpDocumentAlreadyExists;
      });

      if (invalidAttachment) {
        errors[
          `cpDocuments.${i}.attributes.cpDocumentType`
        ] = `There is already a ${documentTypes[cpDocumentType]}. You can edit or delete the existing one.`;
      }
    });
  }

  return errors;
}

export default validateCPDocuments;
