import deepEquals from "../../utilities/deepEquals";
import { update } from "..";

interface Args {
  clientId: string;
  attributes: {
    name: string;
  };
  existingAttributes: {
    name: string;
  };
}

async function updateClientAttributes(args: Args) {
  const { clientId, attributes, existingAttributes } = args;
  const url = `/ms/clients/${clientId}`;

  if (deepEquals(attributes, existingAttributes)) return;

  const body = {
    data: {
      id: clientId,
      type: "adminClients",
      attributes,
    },
  };

  try {
    return update(url, body);
  } catch (err) {
    console.error(err);
  }
}

export default updateClientAttributes;
