import React from "react";
import { useRecordContext } from "ra-core";
import {
  ArrayField,
  Datagrid,
  Labeled,
  TextField
} from "ra-ui-materialui";
import get from "lodash/get";
import { styled } from "@mui/material/styles";
import { Group } from "../../../layout/Group";
import { AddCaseButton } from "../../AddCaseButton";
import { EmptyPMSTab } from "../../EmptyPMSTab";
import { Stack } from "../../../layout/Stack";
import { isManualIntakeEligibleBooking, isVnetAssociatedClient } from "../../helpers/vNetUtils";

const PREFIX = "PmsTab";

const classes = {
  table: `${PREFIX}-table`,
  container: `${PREFIX}-container`
};

const Root = styled("div")({
  [`& .${classes.table}`]: {
    marginBottom: "16px"
  },
  [`& .${classes.container}`]: {
    ["& div"]: {
      width: "100%"
    }
  }
});

export function PmsShowContent() {
  const record = useRecordContext();
  const pmsId = get(record, "patientManagementSystemCases[0].id");
  const pmsIsAlreadyAssigned = Boolean(pmsId);

  const isVNetBooking = isVnetAssociatedClient(record.clients[0]) && isManualIntakeEligibleBooking(record);

  if (!pmsIsAlreadyAssigned) {
    return (
      <Stack>
        {isVNetBooking ?
          <Group>
            <Labeled label={"Manual Intake Reference"} >
              <TextField emptyText={"Empty"} source={"attributes.clientAttributes.VNet.intakeReference"}
                         label={"Intake Reference"} />
            </Labeled>
          </Group>
          :
          <Group>
            <Stack>
              <EmptyPMSTab />
              <AddCaseButton label="Sync with PMS" />
            </Stack>
          </Group>}
      </Stack>
    );
  }

  return (
    <Root>
      <ArrayField label={false} source="patientManagementSystemCases">
        <Datagrid bulkActionButtons={false} className={classes.table} sx={{ padding: "57px 0 0 0" }}>
          <TextField source="attributes.name" label="PMS Name" />
          <TextField source="attributes.caseNumber" label="Case Number" />
          <TextField source="attributes.caseReference" label="Case Reference" />
        </Datagrid>
      </ArrayField>
      {!isVNetBooking && <AddCaseButton label="Sync with PMS" />}
    </Root>
  );
}

