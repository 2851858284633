import { Box, Grid, Typography } from "@mui/material";
import { Colors } from "../../../../theme";
import React from "react";
import { SignpostingSymptom } from "../../../../types/symptomSignposting";

interface SignpostingSymptomProps {
  item: SignpostingSymptom;
  onClick: () => void;
  selected: boolean;
}


export function SignpostingSymptomItem(props: SignpostingSymptomProps) {
  const { item, onClick, selected } = props;

  return (
    <Grid item xs={12} sm={4}>
      <Box
        sx={{
          height: "80px",
          backgroundColor: selected ? Colors.MediumGrey : Colors.White,
          border: selected ? `2px solid ${Colors.BrightBlue}` : "",
          padding: "15px",
          borderRadius: "20px",
          "&:hover": {
            backgroundColor: selected ? Colors.MediumGrey : Colors.LightGrey,
            cursor: "pointer"
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
        onClick={onClick}
      >

        <Typography sx={{ fontWeight: "bold" }} component={"b"} variant="body1">
          {item.attributes.name}
        </Typography>
        <Typography variant="subtitle2">
          {item.attributes.supplementaryText}
        </Typography>
      </Box>
    </Grid>
  );

}