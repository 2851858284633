import { ClinicalPractitionerRegistrationsDTO, ProfessionalBody } from "../../types/clinicalPractitioners";
import { CPRegistrations } from "./helpers/formatClinicalPractitioner";
import { create, update } from "../index";

async function updateRegistrations(
  clinicalPractitionerId: string,
  oldRegistrations: ClinicalPractitionerRegistrationsDTO[] = [],
  newRegistrations: CPRegistrations) {

  const url = `/ms/clinicalPractitioners/${clinicalPractitionerId}/registrations`;

  const promises = [];

  if (newRegistrations) {
    for (const key of Object.keys(newRegistrations)) {
      const oldNumber = oldRegistrations.find(x => x.id === newRegistrations[key]?.id);

      const {registrationNumber, isRegistered} = newRegistrations[key]!;

      if (!oldNumber) {
        const createBody: { data: ClinicalPractitionerRegistrationsDTO } = {
          data: {
            type: "clinicalPractitionerRegistrations",
            attributes: {
              registrationNumber: registrationNumber as string,
              professionalBody: key as ProfessionalBody,
              clinicalPractitionerId: clinicalPractitionerId,
              isRegistered: isRegistered
            },
          },
        };

        const createPromise = create(url, createBody);
        promises.push(createPromise);
      }
      else if (oldNumber?.attributes.registrationNumber !== registrationNumber ||
              oldNumber?.attributes.isRegistered !== isRegistered) {
        const updateUrl = `${url}/${oldNumber?.id}`;

        const updateBody = {
          data: {
            type: "clinicalPractitionerRegistrations",
            id: oldNumber.id,
            attributes: {
              ...oldNumber.attributes,
              registrationNumber,
              isRegistered
            }
          }
        };

        const updatePromise = update(updateUrl, updateBody);
        promises.push(updatePromise);
      }
    }
  }

  await Promise.all(promises);
}

export default updateRegistrations;
