
export function groupBy<T>(arr: T[], prop: keyof T): { [key: string]: T[] } {
  return arr.reduce(
    (acc, obj) => {
      const key = obj[prop] as unknown as string;
      if (!acc[key]) {
        acc[key] = [];
      }
      (acc[key] as T[]).push(obj); // Type assertion
      return acc;
    },
    {} as { [key: string]: T[] }
  );
}