import { GetManyReferenceParams } from "ra-core";
import { list } from "..";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";



async function getManyClientEmployers(params: GetManyReferenceParams) {
  const { id: clientId, pagination, sort } = params;

  const { page, perPage } = pagination;
  const { field, order } = sort;
  const sortQuery = `${order === "DESC" ? "-" : ""}${field}`;


  const endpoint = "/ms/employers";

  const includes = ["clinicalProducts"];

  const query = {
    sort: sortQuery,
    "page[size]": perPage,
    "page[number]": page,
    "filter[clientId]": clientId,
    include: includes.join(","),
  };

  const queryParams = shallowlyRemoveEmptyValues(query);

  const { data, total } = await list(endpoint, { params: queryParams });

  return {data, total};

}

export default getManyClientEmployers;
