import { create, get, remove, update } from "..";
import deepEquals from "../../utilities/deepEquals";
import uploadFiles from "../uploadFiles";
import { Body } from "./updatePatientRecord";

interface Attachment {
  files: { id: string }[];
}

async function updatePatientNotes(body: Body) {
  const { prevNotes = [], notes = [], patientId, clients, users } = body;
  const clientId = clients[0].id;
  const userId = users[0].id;
  const baseUrl = `clients/${clientId}/users/${userId}/patientRecords/${patientId}/notes`;

  const prevIds = prevNotes.map(({ id }) => id);
  const ids = notes.map(({ id }) => id);
  const promises: Promise<unknown>[] = [];

  const notesToRemove = prevIds.filter(x => !ids.includes(x));

  if (notesToRemove) {
    for (const id of notesToRemove) {
      const deleteRequest = remove(`${baseUrl}/${id}`);

      promises.push(deleteRequest);
    }
  }

  const notesToCreate = notes.filter(({ id }) => !prevIds.includes(id));

  if (notesToCreate) {
    for await (const note of notesToCreate) {
      const files = await uploadFiles({
        clientId,
        userId,
        attachments: note.newAttachments ?? [],
      });

      const attributes = {
        note: note.attributes.note,
        isAvailableToClinicalPractitioner: Boolean(
          note.attributes.isAvailableToClinicalPractitioner
        ),
      };

      const createNotesBody = {
        data: {
          type: "patientInternalNotes",
          attributes,
          relationships: {
            attachments: {
              data: files,
            },
          },
        },
      };

      const createRequest = create(baseUrl, createNotesBody);

      promises.push(createRequest);
    }
  }

  const notesToUpdate = notes.filter(note => {
    const match = prevNotes.find(({ id }) => note.id === id);

    if (!match) return false;

    const areEqual = deepEquals(match, note);

    return !areEqual;
  });

  if (notesToUpdate) {
    for await (const note of notesToUpdate) {
      const files = await uploadFiles({
        clientId,
        userId,
        attachments: note.newAttachments ?? [],
      });

      const updateNotesBody = {
        data: {
          id: note.id,
          type: note.type,
          attributes: note.attributes,
          relationships: {
            attachments: {
              data: files,
            },
          },
        },
      };
      const attachmentsEndpoint = `${baseUrl}/${note.id}`;

      await update(attachmentsEndpoint, updateNotesBody);

      if (note.files) {
        const params = {
          include: "attachments",
        };

        const attachmentsRes = await get(attachmentsEndpoint, params);

        const attachment: Attachment = Array.isArray(attachmentsRes.data)
          ? attachmentsRes.data[0]
          : attachmentsRes.data;

        const serverIds = attachment.files.map(({ id }) => id);
        const newIds = files.map(({ id }) => id);
        const idsFromUi = note.files.map(({ id }) => id);
        const combinedIds = [...newIds, ...idsFromUi];
        const toDelete = serverIds.filter(id => !combinedIds.includes(id));

        for await (const attachmentId of toDelete) {
          const fileBaseUrl = `clients/${clientId}/users/${userId}/files/${attachmentId}`;

          const deleteNoteAttachment = remove(fileBaseUrl);
          promises.push(deleteNoteAttachment);
        }
      }
    }
  }

  try {
    await Promise.all(promises);
  } catch (err) {
    console.error(err);
  }
}

export default updatePatientNotes;
