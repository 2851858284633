import { isUserInactive } from "./lastActive";

const INTERVAL = 15000; // 15 seconds

function checkInactivityInterval() {
  const intervalId = setInterval(() => {
    const isInactive = isUserInactive();

    if (isInactive) {
      clearInterval(intervalId);

      // This is an easy way to force authProvider.checkAuth(), which manages all the cleanup and notifications
      window.location.href = "/";
    }
  }, INTERVAL);

  return intervalId;
}

export default checkInactivityInterval;
