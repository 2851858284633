import { Identifier } from "ra-core";
import { get } from "..";

interface Args {
  clientId: Identifier;
}

const defaultValue = {
  type: "clientFunctionValidations",
  id: null,
  restEndpoints: [
    {
      id: null,
      type: "restEndpoints",
      attributes: {
        remoteUrl: "",
      },
    },
  ],
};

async function getClientFunctionValidation(args: Args) {
  const { clientId } = args;

  const endpoint = `/ms/clients/${clientId}/functionValidations`;
  const include = "endpoint";

  try {
    // if it doesn't exist, which it won't for most clients, it will return a 404
    const res = await get(endpoint, { include });
    const data = res.data[0];
    return data;
  } catch (err) {
    return defaultValue;
  }
}

export default getClientFunctionValidation;
