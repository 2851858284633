import get from "lodash/get";
import React from "react";
import { FieldProps, sanitizeFieldRestProps } from "react-admin";
import Typography from "@mui/material/Typography";
import { EMPTY_TEXT, holidayCalendarMap } from "../../constants/constants";

export function HolidayCalendarField(props: FieldProps) {
  const { record, source = "", ...rest } = props;

  if (!record)
    return (
      <Typography
        component="span"
        variant="body1"
        {...sanitizeFieldRestProps(rest)}
      >
        {EMPTY_TEXT}
      </Typography>
    );

  const id = get(record, source);
  const label = holidayCalendarMap[id];

  return (
    <Typography
      component="span"
      variant="body1"
      {...sanitizeFieldRestProps(rest)}
    >
      {label}
    </Typography>
  );
}
