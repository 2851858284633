import { ClientCodeMappingsDTO } from "../../../types/clients";
import { ClientPatientManagementSystemsDTO } from "../../../types/patientManagementSystems";

function consolidatePMSData(
  assignedPMS: ClientPatientManagementSystemsDTO["data"],
  clientMappings: ClientCodeMappingsDTO["data"]
) {
  const consolidatedData = assignedPMS.map(pms => {
    const clientMapping = clientMappings.find(mapping => {
      return mapping.attributes.clientPatientManagementSystemId === pms.id;
    });

    if (clientMapping) {
      return {
        ...pms,
        clientMapping,
      };
    }

    return pms;
  });

  return consolidatedData;
}

export default consolidatePMSData;
