import { create } from "..";
import { ShiftOperationDTO } from "../../types/shifts";

interface Params {
  id: string;
  ignoreAppointments: Record<string, boolean>;
  workingAppointments: Record<string, boolean>;
}

async function applyShiftOperation(args: Params) {
  const { id, ignoreAppointments, workingAppointments } = args;

  const payload: ShiftOperationDTO = {
    data: {
      type: "shiftOperations",
      attributes: {
        ignoreAppointments,
        workingAppointments,
      },
    },
  };

  const res = await create(
    `/ms/resourceManagement/shifts/${id}/applyOperation`,
    payload
  );

  return {
    data: res.data,
  };
}

export default applyShiftOperation;
