import { Identifier } from "ra-core";
import { get } from "..";
import { ClientCodeMappingsDTO } from "../../types/clients";

type Args = {
  clientId: Identifier;
};

async function getClientCodeMappings(params: Args) {
  const { data } = await get(
    `client-codes/mappings?filter[clientId]=${params.clientId}`
  );

  if (!data.length) {
    return [];
  }

  const mappedData = data.map((record: ClientCodeMappingsDTO["data"][0]) => {
    return {
      id: record.id,
      type: record.type,
      attributes: {
        clientCodeId: String(record.attributes.clientCodeId),
        clientId: String(record.attributes.clientId),
        clientPatientManagementSystemId: String(
          record.attributes.clientPatientManagementSystemId
        ),
      },
    };
  });

  return mappedData;
}

export default getClientCodeMappings;
