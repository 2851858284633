import { create } from "..";

interface Args {
  threadId: string;
  message: string;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
async function sendMessage(data: Args) {
  const { threadId, message } = data;

  const endpoint = `/ms/messageThreads/${threadId}/sendMessage`;
  const body = {
    data: {
      type: "messages",
      attributes: {
        message,
      },
    },
  };

  const res = await create(endpoint, body);

  return {
    data: res.data,
  };
}

export default sendMessage;
