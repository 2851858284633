function pick<T extends Record<string, unknown>>(obj: T, keys: Array<keyof T>) {
  const newObj: Partial<T> = {};

  keys.map(key => {
    newObj[key] = obj[key];
  });

  return newObj;
}

export default pick;
