import { create } from "../";
import uploadFiles from "../uploadFiles";
import convertToMAD from "../appointments/convertToMAD";
import getValidationHeaders from "../helpers/getValidationHeaders";
import { AxiosRequestConfig } from "axios";

interface Args {
  userId: string;
  employerId: string;
  clients: [{ id: string }];
  appointmentId: string;
  messageForTheDoctor: string;
  productId: string;
  clientIdentifier: string;
  dependantId: string;
  newAttachments: {
    rawFile: File;
  }[];
  validationOverride?: boolean;
}

async function startMessageThread(args: Args) {
  const {
    userId,
    clients,
    appointmentId,
    messageForTheDoctor,
    productId,
    newAttachments,
    employerId,
    clientIdentifier,
    dependantId,
    validationOverride = false
  } = args;
  const clientId = clients[0].id;
  const appointmentHoldEndpoint = `/clients/${clientId}/appointments/${appointmentId}/hold`;
  const appointmentReleaseEndpoint = `/clients/${clientId}/appointments/${appointmentId}/release`;
  const endpoint = `/clients/${clientId}/users/${userId}/messageDoctors/start`;

  await create(appointmentReleaseEndpoint);
  await convertToMAD({ id: appointmentId });

  await create(appointmentHoldEndpoint, {
    data: {
      type: "appointmentHolds",
      attributes: {
        userId,
      },
    },
  });

  const uploadFileParams = {
    clientId,
    userId,
    attachments: newAttachments,
  };

  const files = await uploadFiles(uploadFileParams);

  const body = {
    data: {
      type: "messageDoctor",
      attributes: {
        message: {
          message: messageForTheDoctor,
          payload: {
            productId: productId
          },
          appointmentId: appointmentId,
          attachments: files
        },
        employerId: employerId,
        clientIdentifier: clientIdentifier,
        patientRecordId: dependantId ?? userId
      },
    },
  };

  const validationHeaders = getValidationHeaders(validationOverride);

  const reqConfig = {
    headers: {
      ...validationHeaders,
    },
  } as AxiosRequestConfig;

  const result = await create(endpoint, body, reqConfig);

  return result;
}

export default startMessageThread;
