import { GetOneParams } from "react-admin";
import { get } from "..";
import { defaultEmployerSettings } from "../../constants/employer";

async function getClientEmployerSettings(params: GetOneParams) {
  const { id } = params;

  const settingsEndpoint = `ms/clients/${id}/employerSettings`;
  const { data } = await get(settingsEndpoint);

  return {
    data: data ?? defaultEmployerSettings
  };
}

export default getClientEmployerSettings;
