import { NotificationType } from "react-admin";
import { genderEnum } from "../constants/constants";

export type Gender = typeof genderEnum[number];

export interface Address {
  addressLine1: string;
  addressLine2?: string | null;
  postCode: string;
  city: string;
  county?: string | null;
  countryCode: string;
  lon?: number | null;
  lat?: number | null;
}

export type Notify = (message: string, type?: NotificationType) => void;

// eslint-disable-next-line no-shadow
export enum ChangeAction {"Added" = 0, "Edited" = 1, "Deleted" = 2}
