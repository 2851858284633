import { get } from "../gaiaClient";
import { v4 as uuid } from "uuid";
import { ClinicalReport } from "../../types/prescriptions";

interface Args {
  tenantId: string;
  traversalId: string;
}

const getClinicalReport = async (args: Args) : Promise<{data: ClinicalReport}> => {
  const { tenantId, traversalId } = args;
  const clinicalReportEndpoint = `${tenantId}/traversals/${traversalId}/clinical-report`;
  const clinicalReport: {data: ClinicalReport} = await get(clinicalReportEndpoint, {
    headers: {
      "x-correlation-id": uuid(),
    }
  })

  return clinicalReport;
}

export default getClinicalReport;
