import { Typography } from "@mui/material";
import React from "react";

export function EmptyGrid() {
  return (
    <Typography variant="body1" style={{ fontWeight: "bold" }}>
      There are no appointments to display
    </Typography>
  );
}
