import React from "react";
import { Filter, TextInput, BooleanInput } from "react-admin";

export function ConsultationOutputsFilter(props: any) {
  return (
    <Filter {...props}>
      <TextInput
        label="Case Ref"
        source="caseRef"
        alwaysOn
        autoComplete="off"
      />
      <BooleanInput
        label="Only show recent bookings"
        source="recentBookingsOnly"
        alwaysOn
      />
    </Filter>
  );
}
