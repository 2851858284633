import { RaRecord } from "react-admin";

export const renderPmsCaseNumber = (record: RaRecord) => {
  return renderPmsCaseAttribute("caseNumber", record);
};

export const renderPmsCaseReference = (record: RaRecord) => {
  return renderPmsCaseAttribute("caseReference", record);
};

const renderPmsCaseAttribute = (attributeName = "", record: RaRecord) => {
  let result;
  if (record.attributes.itemType.includes("MessageDoctor")) {
    result =
      record.messages && record.messages[0].patientManagementSystemCases
        ? record.messages[0].patientManagementSystemCases[0].attributes[
            attributeName
          ]
        : null;
  } else {
    result =
      record.bookings && record.bookings[0].patientManagementSystemCases
        ? record.bookings[0].patientManagementSystemCases[0].attributes[
            attributeName
          ]
        : null;
  }

  return result ? result : "n/a";
};
