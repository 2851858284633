import { Grid } from "@mui/material";
import { FormDataConsumer, useGetOne } from "ra-core";
import { BooleanInput, TextInput } from "ra-ui-materialui";
import React from "react";
import { EmailInput } from "../../inputs/EmailInput";
import { PatientAddressForm } from "../../inputs/PatientAddress/PatientAddressInput";
import { Group } from "../../layout/Group";
import { Stack } from "../../layout/Stack";
import { Heading } from "../../ui/Heading";
import { useWatch } from "react-hook-form";

const inputStyles = {
  display: "block",
  "& > div": {
    width: "260px",
  },
};

export function PatientCreateContactGroup() {
  const clientId = useWatch({ name: "clientId" } )

  const { data: client } = useGetOne("clients", { id: clientId });

  const isEmailRequired = client?.attributes.isOtpEnabled || client?.attributes.isKycEnabled;

  return (
    <Group heading={<Heading level={2}>Contact Details</Heading>}>
      <Grid item xs={12}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (!formData.isDependant) {
              return;
            }

            return (
              <BooleanInput
                {...rest}
                source="shouldCopyGuardianAddress"
                label="Use Guardian's Address"
              />
            );
          }}
        </FormDataConsumer>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Stack>
          <TextInput
            source="phoneNumber"
            label="Telephone"
            required
            sx={inputStyles}
          />
          <TextInput
            source="secondaryPhoneNumber"
            label="Secondary Telephone"
            sx={inputStyles}/>
          <EmailInput
            data-testid="email-input"
            required={isEmailRequired}
            source="email"
            sx={inputStyles}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormDataConsumer>
          {({ formData }) => {
            const { isDependant, shouldCopyGuardianAddress } = formData;
            const shouldUseGuardianAddress =
              isDependant && shouldCopyGuardianAddress;

            if (shouldUseGuardianAddress) {
              return null;
            }

            return <PatientAddressForm sourcePrefix="address" />;
          }}
        </FormDataConsumer>
      </Grid>
    </Group>
  );
}
