import { GetManyReferenceParams } from "ra-core";
import { list } from "../gaiaClient";
import { v4 as uuid } from "uuid";


const getManyReferenceEncounterConsultationOutputs = async (params: GetManyReferenceParams) => {
  const {id} = params;

  const endpoint = `encounters/${id}/consultation-outputs`;

  const correlationId = uuid();
  const headers = {
    "x-correlation-id": correlationId,
  };


  const { data } = await list(endpoint, { headers});

  return {
    data,
    total: data.length
  }
}

export default getManyReferenceEncounterConsultationOutputs;




