import { Heading } from "../ui/Heading";
import { BooleanInput } from "ra-ui-materialui";
import React from "react";

export function BookingChannels() {
  return (
    <>
      <Heading level={4}>Booking Channels</Heading>
      <BooleanInput
        source="clientBookingChannels.attributes.phone"
        label="Phone"
        helperText=""
      />
      <BooleanInput
        source="clientBookingChannels.attributes.patientPortal"
        label="Patient Portal"
        helperText=""
      />
      <BooleanInput
        source="clientBookingChannels.attributes.clientApp"
        label="Client App"
        helperText=""
      />
      <BooleanInput
        source="clientBookingChannels.attributes.symbio"
        label="Symbio"
        helperText=""
      />
    </>
  );
}
