import { GetListParams } from "react-admin";
import { list } from "..";
import { MasterClientDTO } from "../../types/clients";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

async function getMasterClients(params: GetListParams) {
  const clientGroupsEndpoint = "/ms/clientgroups";

  const { filter } = params;
  const { name } = filter;

  const queryParams = {
    "filter[name]": name ? `like:${name}` : "",
  };
  const filteredParams = shallowlyRemoveEmptyValues(queryParams);

  const res = await list(clientGroupsEndpoint, { params: filteredParams });

  const data = res.data.map((item: MasterClientDTO["data"]) => {
    return {
      ...item,
      name: item.attributes.name,
    };
  });

  const { total } = res;

  return { data, total };
}

export default getMasterClients;
