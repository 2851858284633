import { GetOneParams, HttpError } from "react-admin";
import getAssignedPMS from "./getAssignedPMS";
import getClientFunctionValidation from "./getClientFunctionValidation";
import { getClientInstructions } from "./getClientInstructions";
import { getClientTerms } from "./getClientTerms";
import getFollowUpActions from "./getFollowUpActions";
import getPatientIdentifiers from "./getPatientIdentifiers";
import getSendGridTemplates from "./getSendGridTemplates";
import getSingleClient from "./getSingleClient";
import getValidationRules from "./getValidationRules";
import getMaintenanceWindows from "./getMaintenanceWindows";
import getClientGroups from "./getClientGroups";
import formatPaymentConfiguration from "./helpers/formatPaymentConfiguration";
import formatContract from "./helpers/formatContract";
import formatAppointmentFilters from "./helpers/formatAppointmentFilters";
import formatNotificationSettings from "./helpers/formatNotificationSettings";
import formatNotificationTypes from "./helpers/formatNotificationTypes";
import formatClientFunctionValidation from "./helpers/formatClientFunctionValidation";
import formatNotificationChannels from "./helpers/formatNotificationChannels";
import formatNotificationTypeSettings from "./helpers/formatNotificationTypeSettings";
import { ClientDTO } from "../../types/clients";
import getClientEmployers from "./getClientEmployers";
import formatEmployerSettings from "./helpers/formatEmployerSettings";
import formatClientBookingChannels from "./helpers/formatClientBookingChannels";
import { mapAppointmentFilter } from "./helpers/mapAppointmentFilter";
import { formatClientClinicianPools } from "./helpers/formatClientClinicianPools";
import { listResourcePools } from "../resourcePools";

async function getClient(params: GetOneParams) {
  const { id } = params;
  const requestArgs = { clientId: id };

  if (!id) {
    return Promise.reject(new HttpError(
        'Missing client ID',
        400
    ))
  }

  /* CLIENT */
  const clientPromise = getSingleClient(requestArgs);

  /* SENDGRID TEMPLATES */
  const sendGridTemplatePromise = getSendGridTemplates(requestArgs);

  /* SERVICE CONFIG */
  const maintenanceWindowsPromise = getMaintenanceWindows(requestArgs);

  /* TERMS */
  const termsPromise = getClientTerms(requestArgs);

  /* NOTES */
  const instructionsPromise = getClientInstructions(requestArgs);

  /* IDENTIFIERS */
  const validationRulesPromise = getValidationRules(requestArgs);
  const patientIdentifiersPromise = getPatientIdentifiers(requestArgs);
  const clientFunctionValidationPromise =
    getClientFunctionValidation(requestArgs);

  /* FOLLOW UP ACTIONS */
  const followUpActionsPromise = getFollowUpActions(requestArgs);

  /* CLIENT GROUPS */
  const clientGroupsPromise = getClientGroups(requestArgs);

  /* PMS */
  const assignedPMSPromise = getAssignedPMS(requestArgs);


  const listResourcePoolsPromise = listResourcePools({ pagination: {page: 1, perPage: 100}, sort: {field:"name", order:"ASC" }, filter: {clientId: id}});

  /* EMPLOYERS */
  const clientEmployersPromise = getClientEmployers(requestArgs);

  const [
    clients,
    patientIdentifiers,
    clientFunctionValidation,
    terms,
    validationRules,
    templateIds,
    followUpActions,
    instructions,
    assignedPMS,
    maintenanceWindows,
    masterClientGroup,
    clientEmployers,
    resourcePools
  ] = await Promise.all([
    clientPromise,
    patientIdentifiersPromise,
    clientFunctionValidationPromise,
    termsPromise,
    validationRulesPromise,
    sendGridTemplatePromise,
    followUpActionsPromise,
    instructionsPromise,
    assignedPMSPromise,
    maintenanceWindowsPromise,
    clientGroupsPromise,
    clientEmployersPromise,
    listResourcePoolsPromise
  ]);

  const [client] = clients as ClientDTO["data"][];

  const payments = formatPaymentConfiguration(client.clientPaymentSettings);

  const contracts = client.contracts ?? [];
  const clientClinicianPools = formatClientClinicianPools(id, resourcePools?.data ?? [])
  const formattedContract = formatContract(contracts);

  const appointmentFilters = formatAppointmentFilters(
    client.appointmentFilters
  );

  const notificationSettings = formatNotificationSettings(
    client.notificationSettings
  );

  const employerSettings = formatEmployerSettings(client.employerSettings);

  const clientBookingChannels = formatClientBookingChannels(client.id, client.clientBookingChannels);

  const { existingNotificationTypeSettings, notificationTypeSettings } =
    formatNotificationTypes(client.notificationTypeSettings);

  const { existingNotificationChannelSettings, notificationChannelSettings } =
    formatNotificationChannels(client.notificationChannelSettings);

  const expandedNotificationTypeSettings = formatNotificationTypeSettings(
    client.notificationTypeSettings,
    templateIds
  );

  const notifications = {
    notificationSettings,
    existingNotificationSettings: notificationSettings,
    existingNotificationChannelSettings,
    notificationChannelSettings,
    existingNotificationTypeSettings,
    notificationTypeSettings,
    expandedNotificationTypeSettings,
  };

  const formattedClientFunctionValidation = formatClientFunctionValidation(
    clientFunctionValidation
  );
  const appointmentFilterCategories = mapAppointmentFilter(appointmentFilters);

  const data = {
    id: client.id,
    type: client.type,
    attributes: client.attributes,
    relationships: client.relationships,
    existingAttributes: client.attributes,
    appointmentFilterCategories,
    existingAppointmentFilterCategories: appointmentFilterCategories,
    contract: formattedContract,
    existingContract: formattedContract,
    payments,
    oldPayments: payments,
    oldTerms: terms,
    terms,
    oldValidationRules: validationRules,
    validationRules,
    clientFunctionValidation: formattedClientFunctionValidation,
    clientClinicianPools,
    oldClientClinicianPools: clientClinicianPools,
    appointmentFilters,
    oldAppointmentFilters: appointmentFilters,
    templateIds,
    existingTemplateIds: templateIds,
    patientIdentifiers,
    newPatientIdentifiers: patientIdentifiers,
    oldFollowUpActions: followUpActions,
    followUpActions,
    oldInstructions: instructions,
    instructions,
    oldAssignedPMS: assignedPMS,
    assignedPMS: assignedPMS,
    oldMaintenanceWindows: maintenanceWindows,
    maintenanceWindows,
    masterClientGroup,
    oldMasterClientGroup: masterClientGroup,
    clientEmployers: clientEmployers.data,
    clientEmployersTotal: clientEmployers.total,
    clientEmployersChanges: [],
    employerSettings,
    oldEmployerSettings: employerSettings,
    ...notifications,
    clientSettings: client.config,
    clientBookingChannels,
    oldClientBookingChannels: clientBookingChannels,
  };

  return Promise.resolve({ data });
}

export default getClient;
