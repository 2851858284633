import React from "react";
import { Identifier, Labeled, useGetOne, useRecordContext } from "react-admin";
import { getIdsFromWorkQueueItem } from "./helpers";
import { LinkButtonBase } from "../buttons/LinkButtonBase";
import { PatientRecordDTO } from "../../types/patients";
import { BookingDTO } from "../../types/bookings";
import { WorkQueueItemType } from "../../types/workQueues";
import { ConsultationDTO } from "../../types/consultations";
import get from "lodash/get";
import { Typography } from "@mui/material";

export type RecordType = {
  id: Identifier;
  bookings: BookingDTO["data"][];
  patients: PatientRecordDTO["data"][];
  consultations: ConsultationDTO["data"][];
  attributes: {
    itemType: WorkQueueItemType;
    status: string;
  };
};

export function BookingLink() {
  const record = useRecordContext();

  const { bookingId, patientId, clientId } = getIdsFromWorkQueueItem(record);

  const { data, isLoading } = useGetOne("patientRecords", {
    id: `${patientId}:${clientId}`,
  });

  const guardianId = get(data, "relationships.dependantOf.data.id");

  const userId = guardianId ? guardianId : patientId;

  const url = `/patientRecords/${userId}/clients/${clientId}/bookings/${bookingId}/summary`;

  if (isLoading) {
    return (
      <Labeled label="Booking ID">
        <Typography variant="body1">{bookingId}</Typography>
      </Labeled>
    );
  }
  return (
    <Labeled label="Booking ID">
      <LinkButtonBase to={url} text={bookingId} />
    </Labeled>
  );
}
