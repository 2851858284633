import { GetManyReferenceParams } from "ra-core";
import { get } from "../index";




export async function getSignposting(args: GetManyReferenceParams) {
  const { id } = args;

  const endpoint = `/clients/${id}/signposting?include=signpostingSymptomServices,signpostingSymptomServices.clinicalServices`;

  const { data } = await get(endpoint);

  return {
    data: data,
    total: data.length
  }
}