import { GetListParams } from "react-admin";
import { list } from "..";
import { WorkQueueItemDTO } from "../../types/workQueues";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";
import getCurrentUser from "../getCurrentUser";
import isObjectEmpty from "../../utilities/isObjectEmpty";

function getIsCSOFilter(role?: "cso" | "admin"): boolean | null {
  if (!role) return null;

  return role === "cso";
}

async function listResources(params: GetListParams) {
  const { pagination, filter } = params;
  const { page, perPage } = pagination;

  const isCSO = getIsCSOFilter(filter.role);

  const filters = shallowlyRemoveEmptyValues({
    "filter[userName]": filter.username,
    "filter[isCsoUser]": isCSO,
    "filter[firstName]": filter.firstName,
    "filter[lastName]": filter.lastName,
  });

  const hasNoFilter = isObjectEmpty(filters);

  if (filter.ignoreWithoutFilters && hasNoFilter) {
    return {
      data: [],
      total: 0,
    };
  }

  const query = {
    "page[number]": page,
    "page[size]": perPage,
    ...filters
  };


  const { data, total } = await list(`/ms/administratorUsers`, {
    params: query,
  });

  if (filter.includeCurrentUser) {
    const { data: currentUserData } = await getCurrentUser();
    const [currentUser] = currentUserData;

    const isUserAlreadyIncluded = data.some(
      ({ id }: WorkQueueItemDTO["data"]) => currentUser.id === id
    );

    if (!isUserAlreadyIncluded) {
      return {
        data: [currentUser, ...data],
        total: total + 1,
      };
    }
  }

  return { data, total };
}

export default listResources;
