import { ClientBookingChannelsDTO } from "../../types/clients";
import deepEquals from "../../utilities/deepEquals";
import { createClientBookingChannels } from "./createClientBookingChannels";
import { update } from "..";

type Args = {
  clientId: string;
  clientBookingChannels: ClientBookingChannelsDTO["data"];
  oldClientBookingChannels: ClientBookingChannelsDTO["data"];
}

async function updateClientBookingChannels(args: Args) {
  const { clientId, clientBookingChannels, oldClientBookingChannels } = args;
  const baseClientEndpoint = `/ms/clients/${clientId}`;

  const noChange = deepEquals(
    clientBookingChannels,
    oldClientBookingChannels
  );

  if (noChange) {
    return null;
  }

  try {
    const { id: bookingChannelsId } = clientBookingChannels;

    if (!bookingChannelsId) {
      const response = await createClientBookingChannels({
        clientId,
        bookingChannels: clientBookingChannels.attributes,
      })

      return response;
    }

    const updateBookingChannelsEndpoint = `${baseClientEndpoint}/bookingChannels/${bookingChannelsId}`;
    const response = update(updateBookingChannelsEndpoint, {
        data: clientBookingChannels
      }
    )

    return response;
  } catch (error) {
    console.error(error);
  }
}

export default updateClientBookingChannels;
