import dayjs from "dayjs";
import {
  Create,
  ReferenceArrayInput,
  SimpleForm,
  AutocompleteArrayInput,
  BooleanInput
} from "ra-ui-materialui";
import React from "react";
import {
  RaRecord,
  required,
  SaveButton,
  Toolbar,
  useNotify,
  useCreateContext,
  useRedirect,
  FormDataConsumer
} from "react-admin";
import { ImportedShifts } from "../../types/shifts";
import getDefaultDate from "../../utilities/getDefaultDate";
import { DateInput } from "../inputs/DateInput";
import { Heading } from "../ui/Heading";
import { minDate as validateMinDate } from "../../validators/minDate";
import { maxDate as validateMaxDate } from "../../validators/maxDate";
import { ShiftImportV2 } from "./ShiftImportV2";
import { TOGGLE_ENABLE_NEW_SHIFT_IMPORT } from "../../featureFlags/flags";
import { useFlag } from "../../featureFlags";



export function ShiftImportWrapper() {
  const newImportEnabled = useFlag(TOGGLE_ENABLE_NEW_SHIFT_IMPORT);

  if (newImportEnabled) {
    return <ShiftImportV2 />;
  }

  return <ShiftImport/>;

}



export function ShiftImport() {
  const notify = useNotify();
  const redirect = useRedirect();
  const maxDate = dayjs().add(1, "year").format();
  const startOfToday = dayjs(getDefaultDate()).format();

  return (
    <Create
      title="Import Shift"
      resource="importedShifts"
      transform={(record: RaRecord) => {
        const { attributes, shouldSyncAllCps, shouldSyncAllUnits } = record;
        const { start } = attributes;

        const endOfDay = dayjs(start).endOf("day").format();
        record.attributes.end = endOfDay;

        if (shouldSyncAllCps) {
          record.attributes.clinicalPractitionerIds = [];
        }

        if (shouldSyncAllUnits) {
          record.attributes.unitGroupIds = [];
        }

        return record;
      }}
      mutationOptions={{
        onSuccess: (record: ImportedShifts["data"]) => {
          const { shifts } = record;

          const createdShifts = shifts?.filter(
            shift => shift?.attributes?.shiftImportStatus === "Created"
          );
          const updatedShifts = shifts?.filter(
            shift => shift?.attributes?.shiftImportStatus === "Updated"
          );
          const erroredShifts = shifts?.filter(
            shift => shift?.attributes?.shiftImportStatus === "Errored"
          );

          if (erroredShifts?.length) {
            return notify(
              `${erroredShifts.length} shifts were not imported successfully\n
               ${erroredShifts
                 .map(shift => shift.attributes.shiftImportLog)
                 .join("\n")}
              `
            );
          }

          if (!createdShifts?.length && !updatedShifts?.length) {
            if (record.attributes.clinicalPractitionerIds?.length) {
              notify(
                "No changes were made. The shifts of your chosen CPs are already up to date"
              );
            } else {
              notify("No changes were made. All shifts are already up to date");
            }
          } else {
            notify(
              `${createdShifts?.length} shifts were successfully imported. They'll be available in the shift list`
            );
          }
          redirect("/shifts");

          return;
        },
      }}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        defaultValues={{
          shouldSyncAllCps: true,
          shouldSyncAllUnits: true,
        }}
        toolbar={<ImportToolbar />}
      >
        <Heading level={2}>Import Shifts from Quinyx</Heading>
        <DateInput
          source="attributes.start"
          label="Date"
          validate={[
            required(),
            validateMaxDate(maxDate),
            validateMinDate(startOfToday),
          ]}
          maxDate={maxDate}
          minDate={startOfToday}
          defaultValue={startOfToday}
        />
        <BooleanInput
          source="shouldSyncAllUnits"
          label="Sync all units"
          style={{ width: "max-content" }}
        />

        <FormDataConsumer>
          {({ formData }) => {
            if (formData.shouldSyncAllUnits) return null;

            return (
              <ReferenceArrayInput
                source="attributes.unitGroupIds"
                validate={required(
                  "Please choose the units you'd like to import shifts from"
                )}
                reference="quinyxUnits"
              >
                <AutocompleteArrayInput
                  source="id"
                  label="Units"
                  optionText="attributes.name"
                  optionValue="attributes.groupId"
                />
              </ReferenceArrayInput>
            );
          }}
        </FormDataConsumer>

        <BooleanInput
          source="shouldSyncAllCps"
          label="Sync all Clinical Practitioners appointments"
          style={{ width: "max-content" }}
        />
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.shouldSyncAllCps) return null;

            return (
              <ReferenceArrayInput
                source="attributes.clinicalPractitionerIds"
                validate={required(
                  "Please choose the CPs whose shifts you'd like to import"
                )}
                reference="clinicalPractitioners"
                filterToQuery={(query: string) => ({
                  fullName: query,
                })}
              >
                <AutocompleteArrayInput
                  source="id"
                  label="Clinical Practitioner"
                  optionText="attributes.fullName"
                />
              </ReferenceArrayInput>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
}

function ImportToolbar() {
  const { saving } = useCreateContext();

  return (
    <Toolbar>
      <SaveButton
        disabled={saving}
        alwaysEnable={!saving}
        label="Import shifts"
      />
    </Toolbar>
  );
}
