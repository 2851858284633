import { Labeled, LinearProgress } from "ra-ui-materialui";
import { styled } from "@mui/material/styles";
import React, { Children, isValidElement } from "react";
import { RecordContextProvider, useListContext } from "ra-core";
const PREFIX = "SimpleIteratorLayout";

const classes = {
  listItem: `${PREFIX}-listItem`,
  list: `${PREFIX}-list`,
};

const Root = styled("ul")({
  [`& .${classes.listItem}`]: {
    display: "flex",
    "&:last-child": {
      paddingBottom: "0px",
    },
  },
  [`&.${classes.list}`]: {
    margin: "0 0 8px 0",
    listStyle: "none",
    padding: 0,
  },
});

export function SimpleIteratorLayout(props: any) {
  const { children } = props;

  const { data, isLoading } = useListContext(props);

  if (isLoading === true) {
    return <LinearProgress />;
  }

  return (
    <Root className={classes.list}>
      {data.map(item => {
        return (
          <li key={item.id} className={classes.listItem}>
            <div>
              <RecordContextProvider value={item}>
                {Children.map(children, field =>
                  field && isValidElement<any>(field) ? (
                    <div key={field.props.source}>
                      {field.props.addLabel ? (
                        <Labeled
                          label={field.props.label}
                          source={field.props.source}
                        >
                          {field}
                        </Labeled>
                      ) : (
                        field
                      )}
                    </div>
                  ) : null
                )}
              </RecordContextProvider>
            </div>
          </li>
        );
      })}
    </Root>
  );
}
