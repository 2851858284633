import { update } from "..";
import { getConfig } from '../../config';

interface Data {
  orderId: string;
  clientId: string;
  userId: string;
}

async function cancelOrder(data: Data) {
  const { orderId, clientId, userId } = data;
  const config = getConfig();
  
  await update(
    `${config.pew.apiHost}/v${config.pew.apiVersion}/clients/${clientId}/users/${userId}/orders/${orderId}/cancel`,
     /*requestBody*/ null,
     /*reqConfig*/ undefined,
     /*updateMethod*/ "put",
     /*useSapiFormat*/ false);

  return {
    data: {
      id: "id-to-satisfy-ra",
    },
  };
}

export default cancelOrder;
