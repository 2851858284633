import ResourcePoolIcon from "@mui/icons-material/RecentActors";
import { ClinicalServiceList } from "./ClinicalServicesList";
import { ClinicalServiceCreate } from "./ClinicalServiceCreate";
import { ClinicalServiceShow } from "./ClinicalServiceShow";
import { ClinicalServiceEdit } from "./ClinicalServiceEdit";
import { TOGGLE_ENABLE_CLINICAL_SERVICES } from "../../featureFlags/flags";

export const clinicalServiceResources = {
  name: "clinicalServices",
  options: {
    label: "Clinical Services",
    flagKey: {
      name: TOGGLE_ENABLE_CLINICAL_SERVICES
    },
  },
  list: ClinicalServiceList,
  icon: ResourcePoolIcon,
  create: ClinicalServiceCreate,
  show: ClinicalServiceShow,
  edit: ClinicalServiceEdit
}
