import React from "react";
import { useParams } from "react-router-dom";
import { EditButton, Show, TopToolbar, useGetOne, useRecordContext, } from "react-admin";
import { CloseThreadButton } from "./CloseThreadButton";
import { ScrollableTabbedShowLayout } from "../ScrollableTabLayouts";
import { GeneralDetailsShowTab } from "./tabs/GeneralDetailsShowTab";
import { MessagesShowTab } from "./tabs/MessagesShowTab";
import get from "lodash/get";
import { ClientSettingsDTO } from "../../types/clientSettings";
import { useFlag } from "../../featureFlags";
import { TOGGLE_ENABLE_MESSAGE_DOCTOR, TOGGLE_ENABLE_MESSAGE_DOCTOR_CREATE_REPLY } from "../../featureFlags/flags";


function MessageThreadActions() {
  const record = useRecordContext();
  const clientId = get(record, "clients[0].id");
  const { data: client } = useGetOne("clients", { id: clientId });

  const messageDoctorEnabled = useFlag(TOGGLE_ENABLE_MESSAGE_DOCTOR);
  const messageDoctorCreateReplyEnabled = useFlag(TOGGLE_ENABLE_MESSAGE_DOCTOR_CREATE_REPLY);

  const clientSettings = client?.clientSettings as ClientSettingsDTO["data"][];
  const collectProductId =
    clientSettings?.some(
      s =>
        s.attributes.key == "CollectProductId" && s.attributes.value == "true"
    ) ?? false;

  return (
    <TopToolbar>
      {collectProductId && messageDoctorEnabled && messageDoctorCreateReplyEnabled && <EditButton />}
      {messageDoctorEnabled && messageDoctorCreateReplyEnabled && <CloseThreadButton />}
    </TopToolbar>
  );
}

export function MessageThreadShow() {
  const params = useParams<{
    id: string;
  }>();

  return (
    <Show
      title={`Message Thread #${params.id}`}
      actions={<MessageThreadActions />}
      emptyWhileLoading
    >
      <ScrollableTabbedShowLayout>
        <GeneralDetailsShowTab />
        <MessagesShowTab />
      </ScrollableTabbedShowLayout>
    </Show>
  );
}
