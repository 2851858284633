import { create } from "..";

interface Params {
  clientId: string;
  userId: string;
  appointmentId: string;
}

const createAppointmentHold = async (params: Params) => {
  const { clientId, appointmentId, userId } = params;

  let appointmentHoldEndpoint, body;

  if (clientId) {
    appointmentHoldEndpoint = `/clients/${clientId}/appointments/${appointmentId}/hold`;
    body = {
      type: "appointmentHolds",
      attributes: {
        userId: userId,
      },
    };
  } else {
    appointmentHoldEndpoint = `/ms/appointments/${appointmentId}/hold`;
  }
  
  const res = await create(appointmentHoldEndpoint, {
    data: body,
  });

  return res;
};

export default createAppointmentHold;
