import React from "react";
import dayjs from "dayjs";
import { useInput } from "ra-core";
import { InputProps } from "react-admin";
import { TextInput } from "ra-ui-materialui";
import { dateHasLeadingZeroDigit } from "./dateInputHelpers";
import { SxProps, Theme } from "@mui/material";

export interface Props extends InputProps {
  minDate?: string;
  maxDate?: string;
  className?: string;
  disabled?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  hasHelperText?: boolean;
  required?: boolean;
  sx?: SxProps<Theme>;
}

const INPUT_FORMAT = "YYYY-MM-DD";

export function DateInput(props: Props) {
  const {
    minDate,
    maxDate,
    label,
    className,
    disabled = false,
    source,
    disablePast = false,
    disableFuture = false,
    validate,
    hasHelperText = true,
    required = false,
    sx = {},
  } = props;

  const { fieldState } = useInput(props);

  const { isTouched, error } = fieldState;

  const format = (val: string | number | null) => {
    if (!val) return "";
    if (dateHasLeadingZeroDigit(val)) return val;
    return dayjs(val).format(INPUT_FORMAT);
  };

  const maximumDate = disableFuture ? dayjs().format(INPUT_FORMAT) : maxDate;
  const minimumDate = disablePast ? dayjs().format(INPUT_FORMAT) : minDate;

  return (
    <TextInput
      id={source}
      label={label}
      source={source}
      inputProps={{
        // We limit the year to 9999 so that no more than 4 digits can be used for the year
        max: maximumDate || "9999-12-31",
        min: minimumDate,
      }}
      disabled={disabled}
      type="date"
      sx={[{ margin: "8px 0 4px" }, ...(Array.isArray(sx) ? sx : [sx])]}
      className={className}
      margin="dense"
      variant="filled"
      placeholder="dd/mm/yyyy"
      defaultValue=""
      name={source}
      isRequired={required}
      InputLabelProps={{
        shrink: true,
      }}
      error={isTouched && Boolean(error)}
      format={format}
      aria-label={source}
      helperText={!hasHelperText || isTouched ? false : "dd/mm/yyyy"}
      validate={validate}
    />
  );
}
