import { create } from "../";
import { MasterClientGroupDTO } from "../../types/clients";
import { Notify } from "../../types/common";
import updateMasterClient from "./updateMasterClient";

interface Body {
  name: string;
  clientId: string;
  masterClientGroup: MasterClientGroupDTO["data"];
  notify: Notify;
}

async function createMasterClient(body: Body) {
  const createMasterClientEndpoint = `/ms/clientgroups`;
  const { name, clientId, masterClientGroup, notify } = body;

  const createClientBody = {
    data: {
      type: "clientGroups",
      attributes: {
        name,
      },
    },
  };

  const clientRes = await create(createMasterClientEndpoint, createClientBody);
  const masterClientId = clientRes.data.id;

  const newMasterClientGroup = {
    ...masterClientGroup,
    clientGroups: [
      {
        id: masterClientId,
      },
    ],
  };

  await updateMasterClient({
    clientId,
    oldMasterClientGroup: masterClientGroup,
    masterClientGroup: newMasterClientGroup,
    handleError: notify,
  });

  return clientRes;
}

export default createMasterClient;
