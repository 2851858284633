import { GetManyReferenceParams } from "ra-core";
import { list } from "..";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

async function getManyMessageThreads(params: GetManyReferenceParams) {
  const { sort, id, pagination } = params;
  const { page, perPage } = pagination;
  const { field, order } = sort;

  const [patientRecordId] = String(id).split(":");

  const sortQuery = `${order === "DESC" ? "-" : ""}${field}`;

  const messageThreadsEndpoint = `ms/messageThreads`;

  const query = {
    "filter[patient.id]": patientRecordId,
    "page[size]": perPage,
    "page[number]": page,
    include: "messages,patient",
    sort: sortQuery,
  }

  const queryParams = shallowlyRemoveEmptyValues(query);

  const { data, total } = await list(messageThreadsEndpoint, {
    params: queryParams
  });

  return { data, total };
}

export default getManyMessageThreads;
