import { Identifier } from "ra-core";
import { update } from "..";

interface Args {
  appointmentsPerHour?: number;
  appointmentDuration?: number;
  clinicalPractitionerId: string;
  id: Identifier;
}

function updateResourceRules(args: Args) {
  const {
    appointmentDuration,
    clinicalPractitionerId,
    appointmentsPerHour = "",
    id,
  } = args;

  if (!appointmentDuration) {
    return null;
  }

  const endpoint = `/ms/resourceManagement/resourceManagementRules/${id}`;
  const body = {
    data: {
      id,
      type: "resourceManagementRules",
      attributes: {
        appointmentsPerHour,
        appointmentDuration,
      },
      relationships: {
        clinicalPractitioner: {
          data: {
            type: "clinicalPractitioners",
            id: clinicalPractitionerId,
          },
        },
      },
    },
  };

  return update(endpoint, body);
}

export default updateResourceRules;
