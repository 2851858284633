import { Tooltip } from "@mui/material";
import { FormDataConsumer } from "ra-core";
import { NumberInput, SelectInput } from "ra-ui-materialui";
import React from "react";
import fillArray from "../../../utilities/fillArray";
import get from "lodash/get";

interface Props {
  basePrefix: string;
}

const MINUTES_IN_HOUR = 60;

const getAptsPerHourChoices = (max: number) => {
  return fillArray(max).map((_, i) => ({
    id: i + 1,
    name: i + 1,
  }));
};

export function AppointmentsPerHourInput(props: Props) {
  const { basePrefix } = props;
  return (
    <FormDataConsumer>
      {({ formData }) => {
        const aptDuration = get(
          formData,
          `${basePrefix}appointmentDuration`,
          null
        );

        if (!aptDuration || aptDuration <= 0) {
          return (
            <div style={{ width: "256px" }}>
              <Tooltip title="You need to select an appointment duration first">
                <div>
                  <NumberInput
                    helperText={false}
                    disabled
                    style={{ width: "100%" }}
                    source={`${basePrefix}appointmentsPerHour`}
                    label="# of appointments per hour"
                  />
                </div>
              </Tooltip>
            </div>
          );
        }

        const maxAptsPerHour = Math.floor(MINUTES_IN_HOUR / aptDuration);
        const aptsPerHourChoices = getAptsPerHourChoices(maxAptsPerHour);

        return (
          <SelectInput
            source={`${basePrefix}appointmentsPerHour`}
            label="# of appointments per hour"
            choices={aptsPerHourChoices}
          />
        );
      }}
    </FormDataConsumer>
  );
}
