import validateAssignedPMS from "./validateAssignedPMS";
import validateResourcePools from "./validateResourcePools";
import validateGeneralDetails from "./validateGeneralDetails";
import validateServiceConfig from "./validateServiceConfig";
import validateContracts from "./validateContracts";
import validateNotifications from "./validateNotifications";
import recursiveIsObjectEmpty from "../../utilities/recursiveIsObjectEmpty";
import validatePayments from "./validatePayments";
import validateEmployers from "./validateEmployers";

function validateClientForm(values: any) {
  const generalDetailsErrors = validateGeneralDetails(values);
  const resourcePoolsErrors = validateResourcePools(values);
  const assignedPMSErrors = validateAssignedPMS(values);
  const serviceConfigErrors = validateServiceConfig(values);
  const contractsErrors = validateContracts(values);
  const notificationsErrors = validateNotifications(values);
  const paymentsErrors = validatePayments(values);
  const employersErrors = validateEmployers(values);

  const combinedErrors = {
    ...generalDetailsErrors,
    ...resourcePoolsErrors,
    ...assignedPMSErrors,
    ...serviceConfigErrors,
    ...contractsErrors,
    ...notificationsErrors,
    ...paymentsErrors,
    ...employersErrors,
  };

  const errorObjectIsEmpty = recursiveIsObjectEmpty(combinedErrors);

  return errorObjectIsEmpty ? {} : combinedErrors;
}

export default validateClientForm;
