import React, { useEffect, useState } from "react";
import { BookingGroup } from "./BookingGroup";
import { RecordContextProvider, TextField, useRecordContext } from "react-admin";
import { WorkQueueItemDTO } from "../../../types/workQueues";
import getBookingNotifications from "../../../api/bookings/getBookingNotifications";
import { Group } from "../../layout/Group";
import { Heading } from "../../ui/Heading";
import { styled } from "@mui/material/styles";
import { ArrayField, Datagrid } from "ra-ui-materialui";
import { CapsDelimitedField } from "../../fields/CapsDelimitedField";
import DateField from "../../fields/DateField";
import { customStyles } from "../../../theme";

interface NotificationsRecord
{
  failedNotifications: FailedNotification[]
}

interface FailedNotification
{
  createdAt: Date
  notificationType: string
  address: string
  status: string
}

export function NotificationFailedFields() {
  const record = useRecordContext<WorkQueueItemDTO["data"]>();
  const clientId = record.clients![0].id;
  const booking = record.bookings![0];
  const bookingId = booking.id;
  const [notificationsRecord, setNotificationsRecord] = useState<NotificationsRecord | undefined>();

  useEffect(() => {
    async function getNotifications() {
      if (!booking.users) {
        return;
      }

      const userId = booking.users[0].id;

      const bookingNotifications = await getBookingNotifications({
        clientId,
        bookingId,
        userId
      });
      const failedBookingNotifications = bookingNotifications.filter(n => !n.attributes.metadata.channelData.isDelivered || n.attributes.metadata.deliveryEvent);
      const failedNotifications = failedBookingNotifications.map<FailedNotification>(n => {
        let address = '';
        if (n.attributes.notificationChannel === 'sms') {
          address = n.attributes.metadata.contactDetails.phoneNumber;
        } else {
          address = n.attributes.metadata.contactDetails.email;
        }

        let status = 'Failed';
        if (n.attributes.metadata.deliveryEvent) {
          status = n.attributes.metadata.deliveryEvent.type || n.attributes.metadata.deliveryEvent.event;
        }

        return {
          createdAt: n.attributes.createdAt,
          notificationType: n.attributes.notificationType,
          address: address,
          status
        }
      });
      setNotificationsRecord({
        failedNotifications
      })
    }

    getNotifications();
  }, [booking.users, bookingId, clientId]);

  const PREFIX = "EventsTab";
  
  const classes = {
    root: `${PREFIX}-root`,
  };

  const StyledArrayField = styled(ArrayField)({
    [`&.${classes.root}`]: {
      ...customStyles.horiztonalScrollableShadows,
      'width': '100%'
    },
  });
  
  return (
    <>
      <BookingGroup />
      <Group 
        heading={<Heading level={2}>Failed Notifications</Heading>} 
      >
        <RecordContextProvider value={notificationsRecord}>
          <StyledArrayField label={false} source="failedNotifications" className={classes.root}>
            <Datagrid bulkActionButtons={false} sx={{
                width: '100%',
            }}>
              <DateField
                sortable={false}
                label="Time"
                source="createdAt"
                showTime
                hideLabel
              />
              <CapsDelimitedField
                sortable={false}
                hideLabel
                label="Notification Type"
                source="notificationType"
              />
              <TextField
                sortable={false}
                label="Address"
                source="address"
                emptyText="-"
              />
              <CapsDelimitedField
                sortable={false}
                label="Status"
                hideLabel
                source="status"
              />
            </Datagrid>
          </StyledArrayField>
        </RecordContextProvider>
      </Group>
    </>
  );
}
