import { Datagrid, ReferenceManyField, useGetOne, useRecordContext } from "react-admin";
import { Group } from "../../layout/Group";
import { Heading } from "../../ui/Heading";
import { Grid } from "@mui/material";
import { TextField } from "../../../stories/TextField.stories";
import React from "react";
import { DownloadConsultationOutputButton } from "./DownloadConsultationOutputButton";


export function ConsultationOutputsGroup() {
  const record = useRecordContext();
  const clientId = record.tenantId.split('.')[0];

  const { data, isLoading } = useGetOne("patientRecords", {
    id: `${record.patientRecordId}:${clientId}`,
  });

  return (<Group  heading={<Heading level={2}>Consultation outputs</Heading>}>
      <Grid item xs={12}>
        <ReferenceManyField
          reference="encounterConsultationOutputs"
          target="encounterId"
          source={"encounterId"}
          label={false}
        >
          {!isLoading && <Datagrid bulkActionButtons={false}>
            <TextField label={"id"} source={"id"} sortable={false} />
            <DownloadConsultationOutputButton
              label={"With password"}
              applyPassword={true}
              patientDob={data?.attributes?.dateOfBirth}
            />
            <DownloadConsultationOutputButton
              label={"Without password"}
              applyPassword={false}
            />
          </Datagrid>}
        </ReferenceManyField>
      </Grid>
    </Group>
  )

}
