import { create, update } from "..";
import { notificationTypeEnum } from "../../constants/constants";
import { ChannelTypeDTO, NotificationType } from "../../types/notifications";
import deepEquals from "../../utilities/deepEquals";
import formatNotificationTypes from "./helpers/formatNotificationTypes";

interface Args {
  clientId: string;
  notificationTypeSettings: NotificationType[];
  existingNotificationTypeSettings: ChannelTypeDTO["data"][];
}

function filterRedundantRequests(
  requestBodies: ChannelTypeDTO["data"][],
  updatedNotificationTypes: NotificationType[],
  existingNotificationTypes: NotificationType[]
) {
  const filteredRequests = requestBodies.filter(body => {
    const { id, attributes } = body;
    const { notificationType } = attributes;

    if (!id && !notificationType) {
      return false;
    }

    if (id) {
      const didContain = existingNotificationTypes.includes(notificationType);
      const doesContain = updatedNotificationTypes.includes(notificationType);

      if (didContain === doesContain) {
        return false;
      }
    }

    return true;
  });

  return filteredRequests;
}

async function updateNotificationTypeSettings(args: Args) {
  const {
    clientId,
    notificationTypeSettings,
    existingNotificationTypeSettings,
  } = args;

  const { notificationTypeSettings: prevNotificationSettings } =
    formatNotificationTypes(existingNotificationTypeSettings);

  const noChange = deepEquals(
    prevNotificationSettings,
    notificationTypeSettings
  );

  if (noChange) return null;

  const notificationSettingsEndpoint = `/ms/clients/${clientId}/notificationTypeSettings`;

  const requestBodies = notificationTypeEnum.map(type => {
    const isEnabled = notificationTypeSettings.includes(type);

    const notificationTypeId =
      existingNotificationTypeSettings.find(existingType => {
        const { notificationType } = existingType.attributes;
        return notificationType === type;
      })?.id ?? null;

    const val: ChannelTypeDTO["data"] = {
      type: "notificationTypeSettings",
      attributes: {
        isEnabled,
        notificationType: type,
      },
      id: notificationTypeId,
    };

    return val;
  });

  const requiredRequests = filterRedundantRequests(
    requestBodies,
    notificationTypeSettings,
    prevNotificationSettings
  );

  try {
    const promises = requiredRequests.map(requestBody => {
      if (requestBody.id) {
        return update(`${notificationSettingsEndpoint}/${requestBody.id}`, {
          data: requestBody,
        });
      }
      const { id: voidId, ...rest } = requestBody;
      return create(notificationSettingsEndpoint, { data: rest });
    });

    await Promise.all(promises);
  } catch (err) {
    console.error(err);
  }
}

export default updateNotificationTypeSettings;
