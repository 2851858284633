import axios from "axios";
import jwtDecode from "jwt-decode";
import qs from "query-string";
import { getConfig } from "../config";

const PUBLIC_ENDPOINTS = ["/resetPassword/start", "/resetPassword/complete"];

// eslint-disable-next-line
export const testLoginCreds: LoginCredentials = {
  username: "admin",
  password: "Password_123",
};

const config = getConfig();

/**
 * Creates a base64-encoded string from the given input.
 * WARN: This function does not support unicode characters.
 */
function toBase64(value: string) {
  return btoa(value);
}

const url = `${config.auth.authHost}/v${config.api.apiVersion}/ms/oauth2/token`;
const contentType = "application/x-www-form-urlencoded";

interface LoginCredentials {
  username: string;
  password: string;
}

// eslint-disable-next-line
export async function login(loginCreds: LoginCredentials) {
  const { username, password } = loginCreds;
  // uncomment below for easy devving!
  // const { username, password } = testLoginCreds;

  const params = {
    username,
    password,
    grant_type: "password",
    scope: "MSAPI_api offline_access",
  };

  const basicAuthCredentials = toBase64(
    `${config.auth.clientId}:${config.auth.clientSecret}`
  );

  const body = qs.stringify(params);
  const loginParams = {
    headers: {
      "Content-Type": contentType,
      Authorization: `Basic ${basicAuthCredentials}`,
    },
  };

  try {
    const { data } = await axios.post(url, body, loginParams);

    const authData = {
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
    };

    return authData;
  } catch (err) {
    throw new Error(
      "We had trouble logging you in. Please check you've used the correct username and password"
    );
  }
}

export async function getValidAccessToken() {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  if (!accessToken) {
    throw Error("No access token present");
  }

  const decodedToken = jwtDecode(accessToken) as Record<string, any>;

  if (!decodedToken) {
    localStorage.removeItem("accessToken");
    throw Error("Access token is invalid");
  }

  if (!refreshToken) {
    localStorage.removeItem("accessToken");
    throw Error("Access and refresh tokens have expired");
  }

  const expiryTime = decodedToken.exp * 1000;
  const tokenExpired = Date.now() >= expiryTime;

  if (tokenExpired) {
    const newAccessToken = await getAccessTokenWithRefreshToken(refreshToken);
    return newAccessToken;
  }

  return accessToken;
}

export async function getAccessTokenWithRefreshToken(
  existingRefreshToken: string
) {
  const body = {
    grant_type: "refresh_token",
    client_id: config.auth.clientId,
    client_secret: config.auth.clientSecret,
    refresh_token: existingRefreshToken,
  };

  const requestParams = {
    headers: { "Content-Type": contentType },
  };

  try {
    const { data } = await axios.post(url, qs.stringify(body), requestParams);

    localStorage.setItem("accessToken", data.access_token);
    localStorage.setItem("refreshToken", data.refresh_token);

    return data.access_token;
  } catch (err) {
    console.error(err);

    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");

    throw new Error(
      "Your session is stale, please log in to gain access back to EDIII"
    );
  }
}

export function getIsPublicEndpoint(baseUrl: string, fullUrl: string) {
  const endpoint = fullUrl.replace(baseUrl, "");
  const isPublic = PUBLIC_ENDPOINTS.includes(endpoint);

  return isPublic;
}
