import React from "react";
import "./index.css";
import "react-mde/lib/styles/css/react-mde-all.css";
import App from "./App";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isToday from "dayjs/plugin/isToday";
import advancedFormat from "dayjs/plugin/advancedFormat";
import enGB from "dayjs/locale/en-gb";
import { initMonitoring } from "./monitoring";
import { getFeatureFlagsProvider, InitExternalLDClient } from "./featureFlags";
import { createRoot } from "react-dom/client";

dayjs.locale(enGB);
dayjs.extend(isToday);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

(async () => {
  const FeatureFlagsProvider = await getFeatureFlagsProvider();


  const rootElement = document.getElementById("root");
  const root = createRoot(rootElement!);

  root.render(
    <React.StrictMode>
      <FeatureFlagsProvider>
        <App />
        <InitExternalLDClient />
      </FeatureFlagsProvider>
    </React.StrictMode>
  );
})();

initMonitoring();
