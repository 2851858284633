import { Grid } from "@mui/material";
import React from "react";
import { ReferenceField, Labeled, TextField, useRecordContext } from "react-admin";
import { FullNameField } from "../../fields/FullNameField";
import { Group } from "../../layout/Group";
import { Heading } from "../../ui/Heading";

interface Props {
  source?: string
}

export function ClinicalPractitionerGroup({ source }: Props) {
  const record = useRecordContext();
  let clinicalPractitionerIdSource = source;

  if (!clinicalPractitionerIdSource) {
    const suffix = "appointments[0].relationships.clinicalPractitioner.data.id";
    if (record.messages && record.messages.length) {
      clinicalPractitionerIdSource = `messages[0].${suffix}`;
    } else {
      clinicalPractitionerIdSource = `bookings[0].${suffix}`;
    }
  }


  return (
    <Group
      heading={<Heading level={2}>Clinical Practitioner</Heading>}
      data-testid="clinical-practitioner-group"
    >
      <ReferenceField
        source={clinicalPractitionerIdSource}
        reference="clinicalPractitioners"
        link=""
      >
        <Grid item xs={12} sm={6}>
          <Labeled label="Full Name">
            <FullNameField
              firstNameSource="attributes.firstName"
              lastNameSource="attributes.lastName"
              emptyText="-"
            />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Labeled label="Gender">
            <TextField source="attributes.gender" emptyText="-" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Labeled label="Specialty">
            <TextField source="attributes.specialties[0]" emptyText="-" />
          </Labeled>
        </Grid>
      </ReferenceField>
    </Group>
  );
}
