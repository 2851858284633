import React from "react";
import { BooleanInput, CheckboxGroupInput } from "ra-ui-materialui";
import { specialtyChoices } from "../../../constants/constants";
import { EditTabProps } from "../../../types/utilities";
import { FormTab } from "../../Tabs";
import { ClientCodeMappingCodeInput } from "../inputs/ClientCodeMappingCodeInput";
import { Heading } from "../../ui/Heading";
import { SimpleFormIterator } from "../../form/SimpleFormIterator";
import { ArrayInput } from "../../inputs/ArrayInput";
import { FormDataConsumer } from "react-admin";
import { Stack } from "../../layout/Stack";

export function PMSEditTab(props: EditTabProps) {
  return (
    <FormTab {...props} label="PMS Details">
      <ArrayInput source="assignedPMS" level={2}>
        <SimpleFormIterator inline disableRemove disableAdd>
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => {
              if (!getSource) return null;

              const {patientManagementSystemType} = scopedFormData;

              return (
                <Stack>
                  <Heading level={2}>{patientManagementSystemType}</Heading>
                  <ClientCodeMappingCodeInput
                    clientCodeIdSource={getSource("clientCodeId")}
                    patientManagementSystem={patientManagementSystemType}
                  />
                </Stack>
              );
            }}
          </FormDataConsumer>
          <BooleanInput label="Is Enabled" source="isEnabled"/>
          <BooleanInput
            label="Is Automatic Case Creation Enabled"
            source="isAutomaticCaseCreationEnabled"
          />
          <CheckboxGroupInput
            source="specialties"
            label="Specialties"
            choices={specialtyChoices}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>
  );
}
