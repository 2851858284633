import React, { cloneElement } from "react";
import { TopToolbar } from "react-admin";
import { ListToolbar } from "react-admin";
import { ScheduleFilter } from "./SchedulerFilter";
import { ResetListFilterButton } from "../buttons/ResetListFilterButton";
import { defaultSchedulerFilters } from "../../context/schedulerFilter";

export function SchedulerToolbar() {
  return (
    <ListToolbar
      filters={<ScheduleFilter />}
      actions={
        <TopToolbar>
          {cloneElement(<ScheduleFilter />, {
            resource: "schedule",
            context: "button",
          })}
          <ResetListFilterButton
            defaultFilterValues={defaultSchedulerFilters}
          />
        </TopToolbar>
      }
    />
  );
}
