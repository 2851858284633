import React from "react";
import { Filter, TextInput } from "react-admin";

export function QuinyxListFilter(props: any) {
  return (
    <Filter {...props}>
      <TextInput label="Quinyx Id" source="id" autoComplete="off" alwaysOn />
      <TextInput
        label="First Name"
        source="firstName"
        alwaysOn
        autoComplete="off"
      />
      <TextInput
        label="Last Name"
        source="lastName"
        alwaysOn
        autoComplete="off"
      />
      <TextInput
        label="Badge Number"
        source="badgeNumber"
        alwaysOn
        autoComplete="off"
      />
    </Filter>
  );
}
