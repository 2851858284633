import { baseUrl, create } from "../";

interface Params {
  bookingAttachments: {
    rawFile: File;
  }[];
  clientId: string;
  userId: string;
  bookingId: string;
}

async function uploadBookingAttachment(params: Params) {
  const { bookingAttachments, clientId, userId, bookingId } = params;

  const fileEndpoint = `${baseUrl}/clients/${clientId}/users/${userId}/fileUpload`;
  const documentEndpoint = `${baseUrl}/clients/${clientId}/users/${userId}/bookings/${bookingId}/attachments`;

  const requests: Promise<any>[] = [];

  for await (const attachment of bookingAttachments) {
    const formData = new FormData();
    const file = attachment.rawFile;

    formData.append("file", file);
    formData.append("filename", file.name);

    const fileRes = await create(fileEndpoint, formData);

    const documentBody = {
      data: {
        attributes: {
          description: file.name,
        },
        type: "attachments",
        relationships: {
          file: {
            data: {
              type: fileRes.data.type,
              id: fileRes.data.id,
            },
          },
        },
      },
    };

    const createRequest = create(documentEndpoint, documentBody);
    requests.push(createRequest);
  }

  await Promise.all(requests);

  return { data: { id: "id-to-satisfy-ra" } };
}

export default uploadBookingAttachment;
