import React from "react";
import get from "lodash/get";
import {
  BooleanInput,
  Edit,
  Title,
  SaveButton,
  Toolbar,
  useNotify,
  useRefresh,
  useRecordContext,
  NumberInput,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FormDataConsumer,
  ArrayField,
  SingleFieldList,
  ChipField
} from "react-admin";
import { FormTab } from "../Tabs";

import { ScrollableTabbedForm } from "../ScrollableTabLayouts";
import { EditActions } from "../Actions";
import { Typography } from "@mui/material";
import { PrimaryColors } from "../../theme";

function PageTitle() {
  const record = useRecordContext();
  const name = get(record, "attributes.name");

  return <Title title={name} />;

}

export function ResourcePoolEdit() {
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <Edit
      mutationMode="pessimistic"
      title={<PageTitle />}
      actions={<EditActions />}
      mutationOptions={{
        onSuccess: () => {
          notify("Changes to resource pool were successful");
          refresh();
        },
      }}
    >
      <ScrollableTabbedForm
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }>
        <FormTab label="General">
          <TextInput
            label="Name"
            source="attributes.name"
          />
          <TextInput
            label="Service"
            source="attributes.serviceName"
            disabled={true}
          />
          <BooleanInput
            label="Is Enabled"
            source="attributes.isEnabled"
            sx={{marginBottom: "5px"}}
          />
          <BooleanInput
            label="Can be released"
            source="attributes.isGloballyAvailable"
            sx={{marginBottom: "5px"}}
          />
          <FormDataConsumer>
            {({ formData, ...rest}) =>
              <NumberInput
                {...rest}
                label="Release duration"
                source="attributes.globallyAvailableDuration"
                sx={{marginBottom: "5px"}}
                min={45}
                defaultValue={0}
                disabled={!formData.attributes.isGloballyAvailable}
              />}
          </FormDataConsumer>
          <ClinicalPractitionerInput />
          <>
            <Typography sx={{fontSize: 12, color: PrimaryColors.Blue,  }}>Clients</Typography>
            <ArrayField source="clientClinicianPools" label={"Clients"} sortable={false} >
              <SingleFieldList sx={{ padding: "5px 0", marginBottom: "5px"}} linkType={false}>
                <ChipField label={false} source="clients.[0].attributes.name"   />
              </SingleFieldList>
            </ArrayField>
          </>
        </FormTab>
      </ScrollableTabbedForm>
    </Edit>
  )
}

function ClinicalPractitionerInput() {

  const record = useRecordContext();
  const specialty = get(record, "attributes.specialty");
  return (
    <ReferenceArrayInput
      source={"relationships.clinicalPractitioners.data"}
      reference="clinicalPractitioners"
      filter={{ specialty }}
      required
    >
      <AutocompleteArrayInput
        source="id"
        label="Clinical Practitioner"
        optionText="attributes.fullName"
        filterToQuery={(query: string) => ({
          fullName: query,
        })}
      />
    </ReferenceArrayInput>
  )
}
