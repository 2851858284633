import React, { useState } from "react";
import {
  useDelete,
  useNotify,
  useRefresh,
  useRecordContext,
  Form,
  required,
} from "react-admin";
import get from "lodash/get";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import getIsBookingActionDisabled from "../booking/helpers/getIsBookingActionDisabled";
import { BookingStatus } from "../../types/bookings";
import { DialogCloseButton } from "./DialogCloseButton";
import { trackEvent } from "../../utilities/trackEvent";
import { FieldValues } from "react-hook-form";
import { SelectInput, TextInput } from "ra-ui-materialui";
import { bookingCancellationReasonEnum } from "../../constants/constants";
import { splitByCapitalLetters } from "../../utilities/splitByCapitalLetters";

interface Props {
  bookingIdSource: string;
}

const getCancelTooltipText = (status: BookingStatus) => {
  if (getIsBookingActionDisabled(status)) {
    return "You cannot cancel a booking that has been completed";
  }

  return "";
};

const headingStyles = {
  margin: 0,
};

const actionStyles = {
  paddingLeft: 3,
  paddingRight: 3,
  paddingTop: 1,
  paddingBottom: 2,
};

const userRoleTypes = {
  Admin: 0,
  Patient: 1,
}

export function CancelBookingButton(props: Props) {
  const record = useRecordContext();
  const { bookingIdSource } = props;

  const userId = get(record, "users[0].id");
  const bookingId = get(record, bookingIdSource);
  const [isOpen, setDialogOpenState] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const [deleteBooking, { isLoading }] = useDelete("bookings");

  if (!record) return null;

  const handleClose = () => setDialogOpenState(false);
  const onSubmit = (values: FieldValues) => {
    deleteBooking(
      "bookings",
      {
        id: bookingId,
        previousData: {
          ...record,
          bookingId,
          users: [{ id: userId }],
        },
        meta: {
          reason: values.reason,
          reasonNotes: values.reasonNotes,
          isCancelledByPatient: values.cancelationAuthorRole === userRoleTypes.Patient,
        },
      },
      {
        onSuccess: () => {
          trackEvent("Bookings", "Cancel Booking");
          notify("This booking has been cancelled");
          refresh();
        },
        onError: () => {
          notify("There was a problem cancelling the booking");
        },
      }
    );
    handleClose();
  };
  const { status } = record.attributes;

  const isDisabled = getIsBookingActionDisabled(status);

  const reasonChoices = bookingCancellationReasonEnum.map(reason => {
    return {
      name: splitByCapitalLetters(reason),
      id: reason,
    };
  });

  const cancelationAuthorRoleChoices = Object.entries(userRoleTypes).map(([role, id]) => {
    return {
      name: splitByCapitalLetters(role),
      id: id,
    };
  });

  return (
    <div onClick={e => e.stopPropagation()}>
      <Tooltip title={getCancelTooltipText(status)}>
        <span>
          <Button
            disabled={isDisabled || isLoading}
            aria-label="Cancel Booking"
            onClick={e => {
              e.stopPropagation();
              return setDialogOpenState(true);
            }}
            variant="outlined"
            color="secondary"
          >
            Cancel Booking
          </Button>
        </span>
      </Tooltip>
      <Dialog onClose={handleClose} open={isOpen}>
        <DialogCloseButton onClick={handleClose} />
        <DialogTitle>
          <Typography variant="h3" sx={headingStyles}>
            Cancel Booking
          </Typography>
        </DialogTitle>
        <Form
          record={{
            reason: null,
            reasonNotes: null,
            cancelationAuthorRole: null,
          }}
          onSubmit={onSubmit}
        >
          <DialogContent>
            <Typography gutterBottom>
              Are you sure you want to cancel this booking?
            </Typography>
            <SelectInput
              choices={reasonChoices}
              label="Cancellation reason"
              source="reason"
              fullWidth
              validate={[required()]}
            ></SelectInput>
            <TextInput
              label="Cancellation reason notes"
              source="reasonNotes"
              fullWidth
            />
            <SelectInput
              choices={cancelationAuthorRoleChoices}
              label="Cancelled by"
              source="cancelationAuthorRole"
              fullWidth
              validate={[required()]}
            ></SelectInput>
          </DialogContent>
          <DialogActions sx={actionStyles}>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button
              variant="contained"
              aria-label="Cancel Booking"
              type="submit"
              color="secondary"
            >
              Cancel Booking
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}
