import { UpdateParams } from "ra-core";
import { update } from "..";
import { ResourceDTO } from "../../types/resources";

interface Params {
  id: string;
  attributes: ResourceDTO["data"]["attributes"];
}

async function updateResource({ data }: UpdateParams<Params>) {
  const { attributes, id } = data as Params;
  const url = `/ms/administratorUsers/${id}`;

  delete attributes.createdAt;
  delete attributes.updatedAt;

  const updateResourceBody = {
    data: {
      type: "administratorUsers",
      id,
      attributes,
    },
  };

  return await update(url, updateResourceBody);
}

export default updateResource;
