import { create } from "..";

interface Params {
  clientId: string;
  userId: string;
  appointmentId: string;
}

const releaseAppointmentHold = async (params: Params) => {
  const { clientId, appointmentId } = params;

  let appointmentHoldEndpoint;

  if (clientId) {
    appointmentHoldEndpoint = `/clients/${clientId}/appointments/${appointmentId}/release`;
  } else {
    appointmentHoldEndpoint = `/ms/appointments/${appointmentId}/release`;
  }
  
  const res = await create(appointmentHoldEndpoint);
  return res;
};

export default releaseAppointmentHold;
