import React from "react";
import qs from "query-string";
import { Labeled } from "react-admin";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { styled } from "@mui/material";
import { PatientListQueryFilter, PatientListQueryParameters } from "../../../patient/PatientList";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

type PatientSearchLinkProps = {
  label: string;
  firstName: string;
  lastName: string;
  birthDate: string;
}

export function PatientNameSearchLink(props: PatientSearchLinkProps) {
  const filter = JSON.stringify({ 
    dateOfBirth: props.birthDate,
    firstName: props.firstName,
    lastName: props.lastName,
  } as PatientListQueryFilter)
  const patientListQueryParameters = {
      filter: filter,
      persistFilters: true
  } as PatientListQueryParameters;
    
  return (
    <Labeled label={props.label}>
      <StyledLink 
        aria-label="patient-search-link" 
        to={{
          pathname: "/patientRecords",
          search: qs.stringify(patientListQueryParameters),
        }}
      >
        <Typography variant="body1">
          {props.firstName} {props.lastName}
        </Typography>
      </StyledLink>
    </Labeled>
  );
}
