type ErrorMessage = string;

interface ClinicalServiceValidation {
  "name"?: ErrorMessage;
  "appointmentDuration"?: ErrorMessage
  "deliveryType"?: ErrorMessage
  "releaseThreshold"?: ErrorMessage
  "fallbackServiceId"?: ErrorMessage
}

function validateClinicalService(values: any) {
  const errors: ClinicalServiceValidation = {};
  const { attributes } = values;

  if (!attributes?.name) {
    errors["name"] = "Please enter a clinical service name";
  }

  if (!attributes?.deliveryType) {
    errors["deliveryType"] = "Please enter a delivery type";
  }

  if (attributes?.deliveryType === "Synchronous" &&
    (attributes?.appointmentDuration < 1 || attributes?.appointmentDuration > 1000)) {
    errors["appointmentDuration"] = "Please enter a valid appointment duration";
  }

  if (attributes?.canBeReleased) {
    if (attributes.releaseThreshold == undefined || attributes.releaseThreshold < 0) {
      errors["releaseThreshold"] = "Please enter a positive release threshold";
    }

    if(values.id == attributes.fallbackServiceId) {
      errors["fallbackServiceId"] = "The fallback service cannot be the same as the original service";
    }
  }

  const prefix = "attributes.";

  const arrayOfErrors = Object.entries(errors).map(([key, value]) => [
    `${prefix}${key}`,
    value,
  ]);

  const formattedErrors = Object.fromEntries(arrayOfErrors);

  return formattedErrors;
}

export default validateClinicalService;
