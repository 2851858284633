import { GetManyParams } from "react-admin";
import { list } from "..";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

async function getManyEmployers(params: GetManyParams) {
  const employersEndpoint = "ms/employers";

  const { ids } = params;

  const query = {
    "filter[id]": ids ? `like:${ids}` : "",
  };

  const queryParams = shallowlyRemoveEmptyValues(query);

  const { data, total } = await list(employersEndpoint, {
    params: queryParams,
  });

  return { data, total };
}

export default getManyEmployers;
