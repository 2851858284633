import React from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { NextAvailableAppointmentType } from ".";
import { ModalList } from "./ModalList";
import CloseIcon from "@mui/icons-material/Close";

interface ModalProps {
  onClose: () => void;
  open: boolean;
  appointments: NextAvailableAppointmentType;
}

const closeIconStyles = {
  position: "absolute",
  top: 15,
  right: 24,
  "&:hover": {
    cursor: "pointer",
  },
};

export function NextAvailableAppointmentModal(props: ModalProps) {
  const { onClose, open, appointments } = props;

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} open={open}>
      <CloseIcon onClick={onClose} sx={closeIconStyles} />
      <DialogContent>
        <Typography variant="h2">Upcoming appointments</Typography>
        <ModalList appointments={appointments ?? []} />
      </DialogContent>
    </Dialog>
  );
}
