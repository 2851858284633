function pureSplice<T>(arr: T[], i: number): T[] {
  if (!Number.isInteger(i) || i < 0) {
    throw Error("Invalid array index");
  }

  const arrCopy = [...arr];
  arrCopy.splice(i);

  return arrCopy;
}

export default pureSplice;
