import { create } from "..";

interface Args {
  id: string;
}

async function closeMessageThread(args: Args) {
  const { id } = args;

  const endpoint = `/ms/messageThreads/${id}/close`;

  const requestBody = {
    data: {
      type: "messages",
      attributes: {
        message: "This thread has been closed",
      },
    },
  };

  await create(endpoint, requestBody);

  return {
    data: { id: "id-to-satisfy-ra" },
  };
}

export default closeMessageThread;
