import React from "react";
import {
  BooleanInput,
  CheckboxGroupInput,
  Edit,
  SelectInput,
  TextInput,
  useNotify,
  useRefresh,
} from "react-admin";
import {
  cpConsultationPreferences,
  genderChoices,
} from "../../constants/constants";
import {
  isCpEnabledMessage,
  isCpExternalMessage,
} from "../../constants/tooltipMessages";
import { countrySelectChoices } from "../../data/countryCodes";
import { EditActions } from "../Actions";
import TooltipAdornment from "../booking/TooltipAdornment";
import { AdornedField } from "../fields/AdornedField";
import { PageTitle } from "../PageTitle";
import { BasicToolbar } from "../Toolbar";
import { Heading } from "../ui/Heading";
import { CPDocumentsInput, NewDocuments } from "./CPDocumentInputs";
import {
  CPExpertiseInput,
  CPSpecificInputs,
  CPSpecialityInput,
} from "./CPSpecificInputs";
import { AppointmentDetailsEditTab } from "./tabs/AppointmentDetailsEditTab";
import validateEditCP from "./validateEditCP";
import { FormTab } from "../Tabs";
import { complianceLevels } from "../../constants/constants";
import { ScrollableTabbedForm } from "../ScrollableTabLayouts";
import { EmailInput } from "../inputs/EmailInput";
import { booleanInputAdornmentStyles } from "../../theme";

const inputStyles = {
  display: "block",
  "& > div": {
    width: "260px",
  },
};

export function ClinicalPractitionerEdit() {
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <Edit
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Changes to Clinical Practitioner were successful");
          refresh();
        },
      }}
      actions={<EditActions />}
      title={<PageTitle />}
      transform={record => {
        const { specialty, consultationTypes } = record.attributes;

        record.attributes.specialties = [specialty];
        record.attributes.isVideoAllowed = consultationTypes?.includes("Video") ?? false;
        record.attributes.isPhoneAllowed = consultationTypes?.includes("Phone") ?? false;
        record.attributes.isChatAllowed = consultationTypes?.includes("Chat") ?? false;
        record.attributes.isOnsiteAllowed = consultationTypes?.includes("Onsite") ?? false;

        return record;
      }}
    >
      <ScrollableTabbedForm
        warnWhenUnsavedChanges
        validate={validateEditCP}
        toolbar={<BasicToolbar resetPasswordLabel="Reset CP's Password" />}
        noValidate
        mode="onChange"
      >
        <FormTab label="General">
          <Heading level={2}>CP Attributes</Heading>
          <AdornedField
            style={{ width: "fit-content" }}
            source="attributes.isEnabled"
            label="Is Enabled"
            Field={BooleanInput}
            adornment={
              <TooltipAdornment
                sx={booleanInputAdornmentStyles}
                text={isCpEnabledMessage}
              />
            }
          />

          <AdornedField
            style={{ width: "fit-content" }}
            source="attributes.isExternal"
            label="Is External"
            Field={BooleanInput}
            adornment={
              <TooltipAdornment
                sx={booleanInputAdornmentStyles}
                text={isCpExternalMessage}
              />
            }
          />

          <SelectInput
            required
            source="attributes.extraCompliance"
            label="Compliance Level"
            choices={complianceLevels}
          />

          <Heading level={2}>Personal Details</Heading>
          <EmailInput source="attributes.email" required sx={inputStyles} />
          <TextInput
            source="attributes.userName"
            label="Username"
            required
            sx={inputStyles}
          />
          <TextInput
            source="attributes.firstName"
            label="First Name"
            required
            sx={inputStyles}
          />
          <TextInput
            source="attributes.lastName"
            label="Last Name"
            required
            sx={inputStyles}
          />
          <SelectInput
            required
            source="attributes.gender"
            label="Gender"
            choices={genderChoices}
          />
          <SelectInput
            required
            source="attributes.country"
            label="Location (country)"
            choices={countrySelectChoices}
          />
          <CheckboxGroupInput
            source="attributes.consultationTypes"
            label="Preferred consultation types"
            choices={cpConsultationPreferences}
          />
          <Heading level={2}>Specialty Details</Heading>
          <CPSpecialityInput />
          <CPExpertiseInput />
          <CPSpecificInputs />
          <NewDocuments />
          <CPDocumentsInput />
        </FormTab>
        <AppointmentDetailsEditTab />
      </ScrollableTabbedForm>
    </Edit>
  );
}
