import GpSurgeryIcon from '@mui/icons-material/MedicalInformation';
import { GpSurgeriesList } from "./GpSurgeriesList";
import { GpSurgeriesCreate } from "./GpSurgeriesCreate";
import { GpSurgeryShow } from "./GpSurgeryShow";
import { GpSurgeriesEdit } from "./GpSurgeriesEdit";
import { TOGGLE_ENABLE_GP_SURGERIES } from "../../featureFlags/flags";

export const gpSurgeriesResources = {
  name: "gpSurgeries",
  options: {
    label: "GP Surgeries",
    flagKey: {
      name: TOGGLE_ENABLE_GP_SURGERIES
    },
  },
  icon: GpSurgeryIcon,
  list: GpSurgeriesList,
  show: GpSurgeryShow,
  create: GpSurgeriesCreate,
  edit: GpSurgeriesEdit
}
