import recursiveIsObjectEmpty from "../../utilities/recursiveIsObjectEmpty";

type ErrorMessage = string;

interface PharmacyValidation {
  Name?: ErrorMessage;
  PrimaryEmail?: ErrorMessage;
  "Telephones[0].Number"?: ErrorMessage;
  AddressLine1?: ErrorMessage;
  City?: ErrorMessage;
  State?: ErrorMessage;
  PostalCode?: ErrorMessage;
}

const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const healthmailDomains = ["@healthmail.ie", "@healthmail.com"];
const allowedProdDomains = [
  ...healthmailDomains,
  "@healthhero.com",
  "@mailosaur.com",
];
const isProdEnv = process.env.REACT_APP_ENV === "prod";
const isHealthmailEmail = (email: string) =>
  healthmailDomains.some(domain => email.toLowerCase().endsWith(domain));
const isAllowedProdEmail = (email: string) =>
  allowedProdDomains.some(domain => email.toLowerCase().endsWith(domain));

export function validateCreatePharmacy(values: any) {
  const errors: PharmacyValidation = {};

  if (!values.Name || values.Name.trim() === "") {
    errors["Name"] = "Please enter a pharmacy name";
  }

  if (!values.PrimaryEmail || values.PrimaryEmail.trim() === "") {
    errors["PrimaryEmail"] = "Please enter an email address";
  } else if (!emailPattern.test(values.PrimaryEmail.trim())) {
    errors["PrimaryEmail"] = "Please enter a valid email address";
  } else if (isProdEnv && !isAllowedProdEmail(values.PrimaryEmail.trim())) {
    errors[
      "PrimaryEmail"
    ] = `Email should have an allowed domain: ${allowedProdDomains.join(", ")}`;
  } else if (!isProdEnv && isHealthmailEmail(values.PrimaryEmail.trim())) {
    errors["PrimaryEmail"] =
      "Email should not end with @healthmail.ie or @healthmail.com";
  }

  if (
    !values.Telephones[0].Number ||
    values.Telephones[0].Number.trim() === ""
  ) {
    errors["Telephones[0].Number"] = "Please enter a phone number";
  } else if (!/^\d(?:\s*\d){3,}$/.test(values.Telephones[0].Number.trim())) {
    errors["Telephones[0].Number"] =
      "Phone number must contain only digits and have a minimum length of 4";
  }

  if (!values.AddressLine1 || values.AddressLine1.trim() === "") {
    errors["AddressLine1"] = "Please enter a first line of address";
  }

  if (!values.City || values.City.trim() === "") {
    errors["City"] = "Please enter a town/city";
  }

  if (!values.State || values.State.trim() === "") {
    errors["State"] = "Please enter a state/county";
  }

  return recursiveIsObjectEmpty(errors) ? {} : errors;
}
