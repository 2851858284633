import { Typography } from "@mui/material";
import React from "react";
import {
  ArrayField,
  Datagrid,
  Labeled,
  TextField,
  useRecordContext,
} from "react-admin";
import { ShowTabProps } from "../../../types/utilities";
import { BooleanField } from "../../fields/BooleanField";
import { Tab } from "../../Tabs";
import { Heading } from "../../ui/Heading";

const NotificationChannels = () => {
  const record = useRecordContext();

  const notificationChannelSettings = record?.notificationChannelSettings;

  if (!notificationChannelSettings) return null;

  const isEmail = notificationChannelSettings.includes("Email") || false;
  const isSms = notificationChannelSettings.includes("SMS") || false;

  const updatedRecord = {
    ...record,
    isNotificationEmail: isEmail,
    isNotificationSms: isSms,
  };

  return (
    <>
      <Labeled label="Email">
        <BooleanField record={updatedRecord} source="isNotificationEmail" />
      </Labeled>
      <Labeled label="SMS">
        <BooleanField record={updatedRecord} source="isNotificationSms" />
      </Labeled>
    </>
  );
};

export function NotificationShowTab(props: ShowTabProps) {
  return (
    <Tab {...props} label="Notifications">
      <TextField
        label="Sender Name"
        source="notificationSettings.attributes.emailFromName"
        emptyText="n/a"
      />
      <TextField
        label="Sender Email"
        source="notificationSettings.attributes.emailFromAddress"
        emptyText="n/a"
      />
      <TextField
        label="SMS Sender Name"
        source="notificationSettings.attributes.twilioSmsSenderName"
        emptyText="n/a"
      />
      <Heading level={2}>Notification Channels</Heading>
      <NotificationChannels />
      <Typography variant="body1">
        Note: SMS notifications are only available for: Consultation Reminder,
        Consultation About To Start, Booking Confirmation
      </Typography>
      <Heading level={2}>Notifications</Heading>
      <ArrayField source="expandedNotificationTypeSettings" label={false}>
        <Datagrid bulkActionButtons={false}>
          <TextField
            source="attributes.notificationTypeName"
            label="Notification Type"
          />
          <BooleanField label="Is Enabled" source="attributes.isEnabled" />
          <TextField
            source="attributes.templateName"
            label="Template Name"
            emptyText="n/a"
          />
        </Datagrid>
      </ArrayField>
    </Tab>
  );
}
