import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import Typography from "@mui/material/Typography";
import { useFormContext, useWatch } from "react-hook-form";
import { useNotify } from "react-admin";
import pick from "../../../utilities/pick";
import { PatientValidationField, PatientValidationIdentifiers, } from "../../../types/validation";
import { Box } from "@mui/material";
import { FileInput } from "../../inputs/FileInput";

const rootStyles = {
  marginBottom: 1,
  "& label": {
    padding: "32px",
  },
};

function sanitizePatientValidationFields(
  fields: PatientValidationIdentifiers[]
): PatientValidationIdentifiers[] {
  return fields.map(field => {
    const {
      addressLine1,
      addressLine2,
      city,
      dateOfBirth,
      email,
      firstName,
      lastName,
      membershipNumber,
      postCode,
    } = field;

    return {
      addressLine1: addressLine1 || null,
      addressLine2: addressLine2 || null,
      city: city || null,
      dateOfBirth: dateOfBirth || null,
      email: email || null,
      firstName: firstName || null,
      lastName: lastName || null,
      membershipNumber: membershipNumber || null,
      postCode: postCode || null,
    };
  });
}

function filterByChosenRows(
  sanitizedValues: PatientValidationIdentifiers[],
  specifiedColumns: PatientValidationField[]
) {
  return sanitizedValues.map(value => {
    return pick(value, specifiedColumns);
  });
}

export function PatientIdentifiersFileInput() {
  const notify = useNotify();
  const { setValue } = useFormContext();
  const [patientIdentifiers, setPatientIdentifiers] = useState<PatientValidationIdentifiers[]>([]);

  const specifiedColumns =
    useWatch({
      name: "newPatientIdentifiers.clientProperties.specifiedColumns",
    }) ?? ([] as PatientValidationField[]);


  useEffect(() => {
    const filteredValues = filterByChosenRows(
      patientIdentifiers,
      specifiedColumns
    );
    setValue("newPatientIdentifiers.attributes.rows", filteredValues);
  }, [specifiedColumns.length, patientIdentifiers]);

  const parseCsv = (file: File) => {
    return new Promise((resolve, reject) => {
    Papa.parse<PatientValidationIdentifiers>(file, {
      header: true,
      error: e => {
        console.error(e);
        notify(
          "We had trouble processing the CSV file you uploaded. Ensure that the file is .csv, there are no titles, and the columns are valid fields",
          { type: "error" }
        );

        reject(e);
      },
      complete: e => resolve(e.data)
    });
  })
};

  return (
    <Box sx={rootStyles}>
      <FileInput
        label={false}
        source="newPatientIdentifiers.attributes.rows"
        onChange={file => {
          parseCsv(file).then((data:any) => {
            setPatientIdentifiers(sanitizePatientValidationFields(data));
          });
        }}
        placeholder="Upload a .csv with the patients' identifiers"
        accept=".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"
        sx={{ marginTop: "16px", padding: 0 }}
      />
      <Typography variant="body1">
        Note: Uploading new patient identifiers will overwrite existing ones
      </Typography>
    </Box>
  );
}
