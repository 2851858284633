import get from "lodash/get";
import React from "react";
import {
  sanitizeFieldRestProps,
  TextFieldProps,
  useRecordContext,
} from "react-admin";
import Typography from "@mui/material/Typography";

interface Props extends TextFieldProps {
  firstNameSource: string;
  lastNameSource: string;
}

export function FullNameField(props: Props) {
  const { firstNameSource, lastNameSource, label, emptyText, ...rest } = props;
  const record = useRecordContext(props);

  const firstName = get(record, firstNameSource, "");
  const lastName = get(record, lastNameSource, "");

  const text = `${firstName} ${lastName}`;
  const hasName = text.trim() !== "";

  return (
    <Typography
      component="span"
      variant="body1"
      {...sanitizeFieldRestProps(rest)}
    >
      {hasName ? text : emptyText}
    </Typography>
  );
}
