import get from "lodash/get";
import { RaRecord } from "ra-core";

const getClientBookingChannels = (record: RaRecord, path: string) => {
  const clientBookingChannels = get(record, path)

  if (!clientBookingChannels)
    return "n/a"

  const bookingChannels = Object.keys(clientBookingChannels)
    .filter(k => clientBookingChannels[k] && k !== 'clientId')  // exclude clientId and channels that are turned off
    .map(k => k.charAt(0).toUpperCase().concat(k.slice(1))      // capitalize first letter in word
      .replace(/(\\B[A-Z])/g, ' $1'))             // insert space before each capital case
    .join(',');

  return bookingChannels || "None specified";
}

export default getClientBookingChannels;
