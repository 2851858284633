import { Identifier } from "ra-core";
import { update } from "..";
import { ValidationRules } from "../../types/validation";

interface Args {
  clientId: Identifier;
  oldValidationRules: ValidationRules;
  validationRules: ValidationRules;
}

async function updateValidationRules(args: Args) {
  const { clientId, oldValidationRules, validationRules } = args;
  const baseEndpoint = `/ms/clients/${clientId}/validationRules`;
  const promises: Promise<unknown>[] = [];

  if (
    oldValidationRules.clientIdentifier.isEnabled !==
    validationRules.clientIdentifier.isEnabled
  ) {
    const { id, isEnabled } = validationRules.clientIdentifier;
    const endpoint = `${baseEndpoint}/${id}`;
    const body = {
      data: {
        id,
        type: "clientValidationRules",
        attributes: {
          ruleType: "ClientIdentifier",
          isEnabled,
        },
      },
    };

    const promise = update(endpoint, body);
    promises.push(promise);
  }

  if (
    oldValidationRules.multipoint.isEnabled !==
    validationRules.multipoint.isEnabled
  ) {
    const { id, isEnabled } = validationRules.multipoint;
    const endpoint = `${baseEndpoint}/${id}`;
    const body = {
      data: {
        id,
        type: "clientValidationRules",
        attributes: {
          ruleType: "Multipoint",
          isEnabled,
        },
      },
    };

    const promise = update(endpoint, body);
    promises.push(promise);
  }

  if (
    oldValidationRules.clientFunction.isEnabled !==
    validationRules.clientFunction.isEnabled
  ) {
    const { id, isEnabled } = validationRules.clientFunction;
    const endpoint = `${baseEndpoint}/${id}`;
    const body = {
      data: {
        id,
        type: "clientValidationRules",
        attributes: {
          ruleType: "ClientFunction",
          isEnabled,
        },
      },
    };

    const promise = update(endpoint, body);
    promises.push(promise);
  }

  return await Promise.all(promises);
}

export default updateValidationRules;
