export const commentTemplates = [
  "This is an MSK/Mental Health consult via MyClinic",
  "Pictures will be sent to your Medical Solutions email once received in Patient Notes email account.",
  "Pictures SENT - Please check MS E-Mail",
  "This is a Message Dr case, you DO NOT need to call the patient, just write your response in diagnosis box and confirm to office by Instant Message once you have completed case or written 'Need more info', thanks",
  "VIGO - Prescription is automated and sent directly to Meaghers Pharmacy",
  "VIDEO CALL - Please use GP Portal to contact pt",
  "VIDEO CALL - Please click on the link within the email you have received in your Medical Solutions account",
  "App Booking - VIDEO CALL VIA PORT",
  "App Booking - TELEPHONE CALL",
  "Bluecrest - Awaiting wellness report",
  "Bluecrest  - Wellness report attached.",
  "Benenden Validation Tool not working - Unable to validate",
  "Patient NOT available BEFORE appointed time",
  "Attached to this case are:- Mammography Results, Abnormal Letter, Normal Letter",
  "Photos attached in GP Portal - please check attachments in pt case",
  "Patient had difficulty hearing",
  "Please DO NOT prescribe pain relief medication to patients from Ireland ASSIST HSF and Ireland Service patients",
  "Please complete the Screening Referral attached and send to Bluecrest@medicalsolutions-uk.com",
  "VIDEO CALL - Pt wishes to use Translation Service. Please dial 0845 310 9900 and quote ID 311223 Medical Solutions and then state language required. SEE ATTACHMENT FOR DETAILS",
  "Patient is hard of hearing and will be using the text relay service & eConsult - please dial the prefix no.18002 , before dialing patients telephone number",
  "Patient very distressed",
  "The patient has access to Tomo. Tomo is an app that helps patients build healthy behaviours to self-manage mental and some chronic physical health conditions",
  "Please complete Healix SMO Referral form that has been sent to your e mail with patient and return completed form to the office AL",
];
