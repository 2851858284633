import React from "react";
import { useRecordContext } from "ra-core";
import { ShowTabProps } from "../../../types/utilities";
import get from "lodash/get";
import { Tab } from "../../Tabs";
import {
  Labeled,
  ReferenceField,
  SimpleShowLayout,
  TextField,
} from "ra-ui-materialui";
import { Heading } from "../../ui/Heading";
import { ProposedAppointments } from "../ProposedAppointments";
import { ConfirmedAppointments } from "../ConfirmedAppointments";
import { CreateAppointmentButton } from "../CreateAppointmentButton";
import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import DateField from "../../fields/DateField";
import { CancelShiftButton } from "../CancelShiftButton";

interface ConflictTextProps {
  shouldDisplay: boolean;
}

function ConflictText({ shouldDisplay }: ConflictTextProps) {
  if (!shouldDisplay) return null;

  return (
    <Typography variant="body1">
      Note: One or more appointments conflict with an existing appointment.
      These are not displayed and will not be created.
    </Typography>
  );
}

export function GeneralDetailsShowTab(props: ShowTabProps) {
  const record = useRecordContext();

  const status = get(record, "attributes.status");

  const isProcessed = status === "Processed";
  const isDiscarded = status === "Discarded";
  const proposedApts = get(record, "shiftProposedAppointments", []);
  const validApts = get(record, "validProposedAppointments", []);
  const hasClinicalService = !!record.attributes.clinicalServiceId;
  const isAsyncClinicalService = record.clinicalServices?.[0]?.attributes.deliveryType === "Asynchronous";

  const hasConflicts = proposedApts.length !== validApts.length;

  return (
    <Tab label="General Details" {...props}>
      <SimpleShowLayout>
        <Stack>
          <Labeled label="Shift ID">
            <TextField source="id" />
          </Labeled>
          <Labeled label="Clinical Practitioner">
            <ReferenceField
              source="clinicalPractitioners[0].id"
              reference="clinicalPractitioners"
            >
              <TextField source="attributes.fullName" label="CP name" />
            </ReferenceField>
          </Labeled>
          {hasClinicalService && <Labeled label="Clinical Service">
            <ReferenceField
              source="attributes.clinicalServiceId"
              reference="clinicalServices"
            >
              <TextField source="attributes.name" label="Service" />
            </ReferenceField>
          </Labeled>}
          {
            isAsyncClinicalService && <>
              <DateField source="attributes.start" label="Start time" showTime />
              <DateField source="attributes.end" label="End time" showTime />
            </>
          }
          {!isDiscarded && <Box>
            <Labeled label="Cancel">
              <CancelShiftButton />
            </Labeled>
          </Box>}
        </Stack>
        {!isAsyncClinicalService && <AppointmentsList processed={isProcessed} hasConflicts={hasConflicts} />}
      </SimpleShowLayout>
    </Tab>
  );
}



function AppointmentsList(props: { processed: boolean, hasConflicts: boolean }) {
  return <>
    {props.processed ? (
      <ConfirmedAppointments />
    ) : (
      <Box display="flex" flexDirection="row">
        <Box mr={2} flexGrow={3}>
          <ProposedAppointments />
        </Box>
        <Box ml={2} flexDirection="column" width="25%">
          <Heading level={2}>Shift Details</Heading>

          <TextField source="attributes.shiftSource" label="Source" />
          <CreateAppointmentButton />
          <ConflictText shouldDisplay={!props.processed && props.hasConflicts} />
        </Box>
      </Box>
    )}
  </>;
}
