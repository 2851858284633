import React from "react";
import { styled } from "@mui/material/styles";
import { MenuItem } from "@mui/material";
import { useGooglePlaceAddressContext } from "../../../context/GooglePlaceAddressContext";
import { GooglePlacesAddress } from "./GooglePlaceAddressInputs";
import { AddressSelectInput } from "../AddressSelectInput";

const StyledSpan = styled("span")({
  fontWeight: "bold",
});

const menuItemStyles = {
  whiteSpace: "normal",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingTop: "2px",
  paddingBottom: "2px",
};

interface DisplayItemProps {
  id: string;
  addressCount: number;
  currentAddress: GooglePlacesAddress | null;
}

const DisplayItem = (props: DisplayItemProps) => {
  const { id, addressCount, currentAddress } = props;

  if (!currentAddress || id === "-" || addressCount === 0) {
    return <>{addressCount} results</>;
  }

  const { name } = currentAddress;

  return <>{name}</>;
};

export function SelectAddress() {
  const { selectAddress, currentAddress, addresses, type, loading } =
    useGooglePlaceAddressContext();

  const choices = addresses.map((val: any) => {
    const { id, surgeryName, vicinity } = val;

    return (
      <MenuItem key={id} value={id} sx={menuItemStyles} divider>
        <StyledSpan>{surgeryName}</StyledSpan>
        {vicinity}
      </MenuItem>
    );
  });

  const addressCount = addresses.length;
  const id = `select${type.replace(" ", "")}`;

  return (
    <AddressSelectInput
      choices={choices}
      id={id}
      inputProps={{ 'data-testid': id }}
      value={currentAddress?.id ?? "-"}
      onChange={e => selectAddress(e.target.value as string)}
      disabled={!addressCount || loading}
      fullWidth
      renderValue={val => {
        return (
          <DisplayItem
            id={`${val}`}
            addressCount={addressCount}
            currentAddress={currentAddress}
          />
        );
      }}
    />
  );
}
