import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import EmptyIcon from "../../icons/empty.svg";
import { Stack } from "./Stack";

interface Props {
  emptyText: string;
}

const StyledDiv = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: `${theme.spacing(2)} 0`,
  display: "flex",
  textAlign: "center",
}));

const imgWrapperStyles = {
  display: "flex",
  justifyContent: "center",
};

export function EmptyTab(props: Props) {
  return (
    <StyledDiv>
      <Stack sx={{ gap: 2 }}>
        <Box sx={imgWrapperStyles}>
          <img src={EmptyIcon} alt="" />
        </Box>
        <Typography>{props.emptyText}</Typography>
      </Stack>
    </StyledDiv>
  );
}
