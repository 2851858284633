import { create } from "..";

type Args = {
  clientId: string;
  employersCSV?: File;
};

async function updateEmployerCsv(args: Args) {
  const { clientId, employersCSV } = args;

  if (!employersCSV) return;

  const employersUploadEndpoint = `/ms/clients/${clientId}/employers/upload`;

  const formData = new FormData();
  formData.append("file", employersCSV);
  formData.append("filename", employersCSV.name);

  await create(employersUploadEndpoint, formData);
}

export default updateEmployerCsv;
