import React from "react";
import { ReferenceInput } from "ra-ui-materialui";
import AutocompleteInput from "../../inputs/AutocompleteInput";
import { CreateNewClientCodeModal } from "./CreateNewClientCodeModal";

interface MappingInputProps {
  clientCodeIdSource: string;
  patientManagementSystem: string;
}

export function ClientCodeMappingCodeInput(props: MappingInputProps) {
  const { clientCodeIdSource, patientManagementSystem } = props;

  return (
    <ReferenceInput
      sx={{ width: "256px" }}
      source={clientCodeIdSource}
      reference="clientCodes"
      sort={{ order: "ASC", field: "code" }}
      filter={{ patientManagementSystem: patientManagementSystem }}
    >
      <span style={{ display: "inline-block" }}>
        <AutocompleteInput
          label="Client Code"
          filterToQuery={query => {
            return { code: query };
          }}
          placeholder="Type to search by client code..."
          optionText={record => record.attributes.code}
          create={
            <CreateNewClientCodeModal
              relatedPmsType={patientManagementSystem}
            />
          } // When allow code for new PMS's, we'll need to prevent modal if PMS is not selected (or allow selection of PMS in modal)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          emptyText="Remove code"
        />
      </span>
    </ReferenceInput>
  );
}
