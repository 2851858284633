import React from "react";
import { Filter, TextInput, DateInput, ReferenceInput } from "react-admin";
import AutocompleteInput from "../inputs/AutocompleteInput";

export function PatientFilter(props: any) {
  return (
    <Filter {...props}>
      <ReferenceInput
        sort={{ field: "id", order: "ASC" }}
        alwaysOn
        source="clients"
        reference="clients"
      >
        <AutocompleteInput
          label="Client Contract"
          filterToQuery={query => ({ name: query })}
          placeholder="Search by client..."
          shouldRenderSuggestions={(query: string) => query.length > 1}
          source="id"
          optionText="attributes.name"
          helperText={false}
        />
      </ReferenceInput>
      <DateInput label="Date of Birth" source="dateOfBirth" alwaysOn />
      <TextInput
        label="First Name"
        source="firstName"
        alwaysOn
        autoComplete="off"
      />
      <TextInput
        label="Last Name"
        source="lastName"
        alwaysOn
        autoComplete="off"
      />
      <TextInput label="Email" source="email" autoComplete="off" />
      <TextInput label="Booking ID" source="bookingId" autoComplete="off" />
      <TextInput label="User ID" source="userId" autoComplete="off" />
    </Filter>
  );
}
