function displayTooltipMessages(validators: [boolean, string][]) {
  const correctCondition = validators.find(([predicate]) => predicate) ?? [
    false,
    "",
  ];

  return correctCondition[1];
}

export default displayTooltipMessages;
