import React, { useState } from "react";
import {
  useRecordContext,
  useNotify,
  RaRecord,
  useCreate,
  Button,
} from "react-admin";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { DialogCloseButton } from "../buttons/DialogCloseButton";

interface Props {
  label: string;
}

const buttonStyles = {
  "& svg": {
    marginRight: 1,
  },
};

function getBody(record?: RaRecord) {
  const userName = record?.attributes?.userName ?? record?.attributes?.email;
  const clientId = record?.clients ? record?.clients[0]?.id : null;

  return {
    userName,
    clientId,
  };
}

export function ResetPasswordButton(props: Props) {
  const record = useRecordContext();

  const body = getBody(record);
  const email = record?.attributes?.email;

  const [isDialogOpen, setDialogOpenState] = useState(false);
  const notify = useNotify();
  const [resetPassword, { isLoading }] = useCreate(
    "resetPassword",
    { data: body },
    {
      onSuccess: () => {
        setDialogOpenState(false);
        notify("The user's password has been reset");
      },
      onError: () => {
        notify("There was a problem resetting the user's password");
      },
    }
  );

  const isDisabled = isLoading || !email;
  const handleClose = () => setDialogOpenState(false);

  return (
    <>
      <Tooltip
        title={
          !email
            ? "Cannot reset password as the user has no associated email address"
            : ""
        }
      >
        <span>
          <Button
            sx={buttonStyles}
            disabled={isDisabled}
            variant="outlined"
            color="primary"
            label={props.label}
            onClick={() => setDialogOpenState(true)}
          >
            <LockOpenIcon />
          </Button>
        </span>
      </Tooltip>

      <Dialog onClose={handleClose} open={isDialogOpen}>
        <DialogCloseButton onClick={handleClose} />
        <DialogTitle>
          <Typography variant="h3" sx={{ margin: 0 }}>
            Reset Password
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Are you sure you want to reset this user's password?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" label="Cancel"></Button>
          <Button
            variant="contained"
            color="primary"
            aria-label="Confirm Reset Password"
            onClick={() => {
              resetPassword();
              handleClose();
            }}
            label="Reset Password"
          ></Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
