import { GetListParams } from "ra-core";

async function listGooglePlaces(params: GetListParams) {
  const { filter } = params;
  const { postcode, type } = filter;

  const getPostcodeDetails = await getLocationDetailsByPostcode(postcode);
  const currentLocation = {
    lng: getPostcodeDetails[0]?.geometry?.location?.lng(),
    lat: getPostcodeDetails[0]?.geometry?.location?.lat(),
  };
  const getListOfGooglePlaces = await getNearbyGooglePlaces(
    currentLocation,
    type
  );

  if (!getListOfGooglePlaces) return { data: {}, total: 0 };

  const formattedList = formatGooglePlaceList(getListOfGooglePlaces);

  return {
    data: formattedList,
    total: getListOfGooglePlaces?.length,
  };
}

export default listGooglePlaces;

const getLocationDetailsByPostcode = (
  postCode: string
): Promise<google.maps.places.PlaceResult[]> => {
  const PlacesService = new google.maps.places.PlacesService(
    document.createElement("div")
  );
  const OK = google.maps.places.PlacesServiceStatus.OK;

  const request = {
    query: postCode,
    type: "postal_code",
  };

  return new Promise((resolve, reject) => {
    PlacesService.textSearch(request, (results, status) => {
      if (status !== OK || !results) {
        reject(status);
      }
      resolve(results || []);
    });
  });
};

const getNearbyGooglePlaces = (
  currentPosition: {
    lat?: number;
    lng?: number;
  },
  type: string
): Promise<google.maps.places.PlaceResult[]> | null => {
  const { lat, lng } = currentPosition;

  if (!lat || !lng) return null;
  const currentPos = new google.maps.LatLng(lat, lng);
  const PlacesService = new google.maps.places.PlacesService(
    document.createElement("div")
  );
  const OK = google.maps.places.PlacesServiceStatus.OK;

  const request = {
    fields: "name,geometry,formatted_address,opening_hours,address_components",
    keyword: type,
    location: currentPos,
    language: "en-GB",
    rankBy: 1,
  };

  return new Promise((resolve, reject) => {
    PlacesService.nearbySearch(request, (results, status) => {
      if (status !== OK || !results) {
        reject(status);
      }

      resolve(results || []);
    });
  });
};

const formatGooglePlaceList = (
  surgeryList: google.maps.places.PlaceResult[]
) => {
  return surgeryList.map(surgery => {
    return {
      id: surgery.place_id,
      surgeryName: surgery.name,
      vicinity: surgery.vicinity,
    };
  });
};
