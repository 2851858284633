import React  from "react";
import { useListContext } from "react-admin";
import { Card, Typography } from "@mui/material";

export function HealthMailDataGridWrapper(props: any) {
  return (
    <>
      <SearchStatusIndicator />
      <Card className={props.className}>{props.children}</Card>
    </>
  );
}

function SearchStatusIndicator() {
  const context = useListContext();
  const { filter, filterValues, total, isFetching } = context;

  if (!filterValues.caseNumber && !filter?.caseNumber) {
    return (
      <Card style={{ padding: 16 }}>
        <Typography variant="body1">
          Please enter the 5-digit case ref to retrieve a list of cases
        </Typography>
      </Card>
    );
  }

  if (total === 0 && !isFetching) {
    return (
      <Card style={{ padding: 16 }}>
        <Typography variant="body1">
          We couldn't find any cases that match that case ref. Please make sure
          the case ref is correct.{" "}
        </Typography>
      </Card>
    );
  }

  return null;
}