import { GetListParams, GetListResult } from "ra-core";
import { get } from "..";

const includes = ["proposedAppointments.createdAppointment"];

const UNAVAILABLE_APPOINTMENTS = ["Cancelled", "NotAvailable"];

async function listProposedAppointments(
  args: GetListParams
): Promise<GetListResult> {
  const { id } = args.filter;

  const endpoint = `ms/resourceManagement/shifts/${id}`;

  const params = {
    include: includes.join(","),
  };

  const res = await get(endpoint, params);

  const data = Array.isArray(res.data) ? res.data[0] : res.data;

  const { shiftProposedAppointments } = data;
  if (!shiftProposedAppointments) {
    return {data: [], total: 0}
  }

  const filteredAppointments = shiftProposedAppointments.filter((apt: any) => {
    if (!apt.appointments) return false;

    const { status } = apt.appointments[0].attributes;

    return !UNAVAILABLE_APPOINTMENTS.includes(status);
  });

  return {
    data: filteredAppointments,
    total: filteredAppointments.length,
  };
}

export default listProposedAppointments;
