import { create, update } from "..";
import { ClientContractDTO } from "../../types/clients";
import deepEquals from "../../utilities/deepEquals";

type Args = {
  clientId: string;
  updatedContract: ClientContractDTO["data"];
  oldContract: ClientContractDTO["data"];
};

async function updateContracts(args: Args) {
  const { clientId, oldContract, updatedContract } = args;
  const { id, attributes } = updatedContract;

  if (attributes?.domain === "") {
    delete attributes.domain;
  }

  const baseClientEndpoint = `/ms/clients/${clientId}`;

  /* CREATE */
  if (!id) {
    const addContractsEndpoint = `${baseClientEndpoint}/contracts`;

    const contractBody = {
      data: {
        type: "contracts",
        attributes,
      },
    };

    return create(addContractsEndpoint, contractBody);
  }

  /* UPDATE */
  const noChange = deepEquals(updatedContract, oldContract);
  if (noChange) return;

  const updateContractsEndpoint = `${baseClientEndpoint}/contracts/${id}`;
  const contractBody: ClientContractDTO = {
    data: {
      type: "contracts",
      id,
      attributes,
    },
  };

  return update(updateContractsEndpoint, contractBody);
}

export default updateContracts;
