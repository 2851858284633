import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import { QuinyxListFilter } from "./QuinyxListFilter";

export function QuinyxList() {
  return (
    <List
      title="Quinyx Resources"
      filters={<QuinyxListFilter />}
      exporter={false}
      resource="quinyxResources"
    >
      <Datagrid
        rowClick={id => `/shifts/quinyxResources/${id}`}
        bulkActionButtons={false}
      >
        <TextField source="id" label="Quinyx ID" sortable={false} />
        <TextField
          source="attributes.firstName"
          label="First name"
          sortable={false}
        />
        <TextField
          source="attributes.lastName"
          label="Last name"
          sortable={false}
        />
        <TextField
          source="attributes.badgeNumber"
          label="Badge Number"
          sortable={false}
        />
        <TextField
          source="relationships.clinicalPractitioner.data.id"
          label="Practitioner ID"
          sortable={false}
          />
      </Datagrid>
    </List>
  );
}
