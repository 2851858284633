import { Box } from "@mui/material";
import { Form, useEditContext } from "ra-core";
import React from "react";
import { useLocation } from "react-router-dom";

const wrapperStyles = {
  display: "flex",
  position: "relative",
};

const childrenStyles = {
  py: 1,
  px: 2,
  flex: 1,
  "& > .ra-input": {
    display: "flex",
    flexDirection: "column",
    alignItems: "self-start",
    minWidth: "250px",
  },
};
/**
 *
 * The FormTabPanelFormView is only concerned with the appearance of the tab, and shouldn't deal with any form state
 */
function FormTabPanelView(props: any) {
  const { value, editable, Toolbar } = props;
  const hidden = editable === "hidden";

  return (
    <Box
      id={`tabpanel-${value}`}
      sx={wrapperStyles}
      aria-labelledby={`tabheader-${value}`}
    >
      <Box sx={childrenStyles}>{props.children}</Box>
      {!hidden && Toolbar ? (
        <Toolbar editable={editable} />
      ) : null}
    </Box>
  );
}

/**
 *
 * The FormTabPanel is the component that manages the form state of the tab
 */
export function FormTabPanel(props: any) {
  const editProps = useEditContext();
  const { hidden, value, children, save, editable, validate, Toolbar } = props;
  const location = useLocation();

  if (hidden) return null;

  // The warnWhenUnsavedChanges is causing an issue where
  // we aren't transitioning to show state on the patients page
  return (
    <Form
      {...editProps}
      formRootPathname={location.pathname}
      warnWhenUnsavedChanges={editable === "editable"}
      onSubmit={save}
      mode="onChange"
      validate={validate}
    >
      <FormTabPanelView Toolbar={Toolbar} value={value} editable={editable}>
        {children}
      </FormTabPanelView>
    </Form>
  );
}
