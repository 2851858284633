import isBoolean from "lodash/isBoolean";

function shallowlyRemoveEmptyValues(object: Record<string, unknown>) {
  const newObject: Record<string, unknown> = {};

  Object.entries(object).forEach(([key, value]) => {
    if (value || isBoolean(value)) {
      newObject[key] = value;
    }
  });

  return newObject;
}

export default shallowlyRemoveEmptyValues;
