import { ClientList } from "./ClientList";
import { ClientCreate } from "./ClientCreate";
import { ClientEdit } from "./ClientEdit";
import { ClientShow } from "./ClientShow";
import ClientIcon from "@mui/icons-material/Business";

export { ClientIcon };

export const clientResources = {
  options: { label: "Client Contracts" },
  name: "clients",
  list: ClientList,
  create: ClientCreate,
  edit: ClientEdit,
  show: ClientShow,
  icon: ClientIcon,
};
