import { GetOneParams } from "ra-core";
import { getPlacesResult } from "../../utilities/getPlacesResult";
import { formatAddressValues } from "../../utilities/googlePlaces";

async function getGooglePlaceDetails(params: GetOneParams) {
  const { id } = params;

  const addressDetails = await getPlacesResult(`${id}`);
  if (!addressDetails) return { data: null };

  const formattedAddressDetails = formatAddressValues(
    addressDetails.address_components
  );

  const { name, place_id, international_phone_number, vicinity } =
    addressDetails;

  return {
    data: {
      ...formattedAddressDetails,
      id: place_id,
      name,
      phoneNumber: international_phone_number,
      vicinity,
    },
  };
}

export default getGooglePlaceDetails;
