import { GetManyParams } from "react-admin";
import { get } from "..";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

async function getManyPatientRecords(params: GetManyParams) {
  const { ids } = params;

  const url = "/ms/patientRecords";
  const filters = {
    "filter[id]": `in:${ids}`,
    include: "dependantOf",
  };

  const filterParams = shallowlyRemoveEmptyValues(filters);

  const data = await get(url, filterParams);

  return data;
}

export default getManyPatientRecords;
