import { PaymentSettingsDTO } from "../../../types/payments";

export const defaultPaymentConfig = {
  id: null,
  attributes: {
    isPaymentQueryEnabled: false,
  },
  restEndpoints: [
    {
      id: null,
      attributes: {
        remoteUrl: "",
      },
    },
  ],
};

function formatPaymentConfiguration(
  paymentConfig?: PaymentSettingsDTO["data"][]
) {
  if (!paymentConfig || !paymentConfig[0]) return defaultPaymentConfig;

  return paymentConfig[0];
}

export default formatPaymentConfiguration;
