import isArray from "lodash/isArray";

export function areArraysEqual(arr1: Array<number|string>, arr2: Array<number|string>) {
  if (!(isArray(arr1) && isArray(arr2))) {
    return false;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort both arrays to ensure the order of elements doesn't matter
  const sortedSerializedArr1 = arr1.sort().toString();
  const sortedSerializedArr2 = arr2.sort().toString();

  return sortedSerializedArr1 == sortedSerializedArr2;
}
