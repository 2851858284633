import React from "react";
import background from "../../static/background.jpg";
import placeholder from "../../static/backgroundSmall.jpg";
import { useImageWithPlaceholder } from "../../hooks/useImageWithPlaceholder";
import { Box } from "@mui/system";
interface Props {
  children: React.ReactNode;
}

const wrapperStyles = {
  display: "flex",
  flexDirection: "column",
  minBlockSize: "100vh",
  alignItems: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  transition: "0.2s",
};

export function AuthLayout(props: Props) {
  const { children } = props;

  const source = useImageWithPlaceholder({ background, placeholder });

  return (
    <Box sx={{ ...wrapperStyles, ...{ backgroundImage: `url(${source})` } }}>
      {children}
    </Box>
  );
}
