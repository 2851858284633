export const rejectionFieldsName = [
  {
    id: "safeguarding",
    name: "Select safeguarding or rejection statement",
  },
  {
    id: "response",
    name: "Select response",
  },
  {
    id: "closingPhrase",
    name: "Select a closing phrase",
  },
];

export const safeguardingRejectionMessages = [
  "Unfortunately, on this occasion we are unable to fulfil your request with the Message Doctor Service",
  "We will process your message to the doctor, however as the nature of your query indicates a Safeguarding need",
];

export const responseMessages = [
  "we would strongly recommend that you contact 111 (UK), 112 (ROI) or 999 who will be able to assist you more urgently.",
  "but given the nature of your enquiry the GP has recommended that you have a consultation to discuss this more fully. Please use online booking or the Request Appointment service to book a consultation.",
  "as the doctor has indicated that, given the nature of the health query you presented, the appropriate course of action is for you to be seen in person by your own doctor or another medical professional.",
  "as you are seeking a prescription. You will need to book an appointment to speak to the doctor, so please do so using online booking or the Request Appointment service to book a consultation.",
  "as you are seeking a Specialist Referral letter. You will need to book an appointment to speak to the doctor, so please do so using online booking or the Request Appointment service to book a consultation.",
  "as you are seeking a Statement of Fitness for Work. You will need to book an appointment to speak to the doctor, so please do so using online booking or the Request Appointment service to book a consultation.",
  "as we are unable to confirm you are eligible to use this service. Please call Irish Life Health Customer Services on 1890 717 717 and ask them to contact us to confirm your cover is current, we will then be able to provide service to you.",
  "as unfortunately, we are unable to provide advice to pregnant women in Ireland. For your own well being, and that of your baby, we recommend you visit your own doctor or midwifery service.",
  "as your enquiry is unrelated to the services we provide. For queries, claims or complaints in relation to your policy or health plan provider, please contact them using the Customer Services number in you policy document.",
  "as complaints about our services provided via the App should be made using the dedicated Feedback option within it.",
  "as the content of your message was inappropriate and/or abusive. We do not tolerate any abuse of our staff in any form. The information provided has been captured and will be passed on to your health plan provider.",
];

export const closingPhrase = [
  "We look forward to making an appointment for you soon.",
  "We're sorry we cannot be of further assistance on this occasion.",
  "Please ensure that your use of this service is appropriate and aligned to the description of the Message Dr. service found within the App or in the Terms and Conditions below.",
  "We will send you the doctor's response in due course.",
];
