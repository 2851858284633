import React from "react";
import Typography from "@mui/material/Typography";
import get from "lodash/get";
import { ArrayFieldProps, useRecordContext, Labeled } from "react-admin";
import nonNullable from "../../utilities/nonNullable";
import { NONE_SPECIFIED } from "../../constants/constants";

type Props = Pick<ArrayFieldProps, "record" | "source"> & {
  emptyText?: string;
  label: string;
  hideLabel?: boolean;
};

export const TextArrayField = ({
  source,
  emptyText = NONE_SPECIFIED,
  label = "",
  hideLabel = false,
}: Props) => {
  const record = useRecordContext();
  if (!record || !source) return null;

  const displayedLabel = hideLabel ? false : label;

  const array = get(record, source);
  const exists = nonNullable(array);

  if (!exists || array.filter((item: string) => item.trim()).length === 0) {
    return (
      <Labeled label={displayedLabel}>
        <Typography component="span" variant="body1">
          {emptyText}
        </Typography>
      </Labeled>
    );
  }

  return (
    <Labeled label={displayedLabel}>
      <>
        {array.map((item: string) => (
          <Typography
            key={item}
            variant="body1"
            sx={{
              my: 0.5,
              "&:first-child": {
                marginTop: 0,
              },
            }}
          >
            {item}
          </Typography>
        ))}
      </>
    </Labeled>
  );
};

TextArrayField.defaultProps = { sortable: false };
