import React, { ComponentType, useEffect } from "react";
import {
  useNotificationContext,
  Notification as RANotification,
  NotificationProps,
} from "react-admin";

export const Notification: ComponentType<NotificationProps> = props => {
  const { resetNotifications, notifications } = useNotificationContext();

  const mostRecentNotification = notifications[notifications.length - 1];

  // This is related to aborting network requests
  // If we abort a request, then we don't want to show an error message
  useEffect(() => {
    if (!mostRecentNotification) return;

    if (mostRecentNotification.message === "ABORT") {
      resetNotifications();
    }
  }, [mostRecentNotification, resetNotifications]);

  return <RANotification {...props} autoHideDuration={10000} />;
};
