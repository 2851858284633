import { GetListParams } from "ra-core";
import { get } from "../index";


export interface PatientClinicalServices {
  attributes: {
    consultationTypes: string[];
    specialty?: string;
    clinicalServiceId?: number;
  }
  clinicalServices?: {
    attributes: {
      id: number,
      name: string,
      deliveryType: string
    }
  }[]
}


async function getPatientClinicalServices(params: GetListParams) {
  const { filter } = params;

  const { clientId, patientRecordId } = filter;

  const endpoint = `/clients/${clientId}/patientRecords/${patientRecordId}/clinicalServices?include=clinicalService`;


  return await get(endpoint);
}

export default getPatientClinicalServices;