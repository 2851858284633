import { ChannelTypeDTO } from "../../../types/notifications";
import nonNullable from "../../../utilities/nonNullable";

function formatNotificationTypes(
  existingNotificationTypeSettings: ChannelTypeDTO["data"][] = []
) {
  const notificationTypeSettings = existingNotificationTypeSettings
    .map(({ attributes }) => {
      const { notificationType, isEnabled } = attributes;

      if (!isEnabled) return;

      return notificationType;
    })
    .filter(nonNullable);

  return {
    existingNotificationTypeSettings,
    notificationTypeSettings,
  };
}

export default formatNotificationTypes;
