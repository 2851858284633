import React from "react";
import { EditTabProps } from "../../../types/utilities";
import { AppointmentInputs } from ".././AppointmentInputs";
import { Heading } from "../../ui/Heading";
import { ShiftPreferencesView } from "../../inputs/ShiftPreferencesInputs/ShiftPreferencesView";
import { FormTab } from "../../Tabs";

export function AppointmentDetailsEditTab(props: EditTabProps) {
  return (
    <FormTab {...props} label="Appointment Creation Rules">
      <Heading level={2}>Appointment Preferences</Heading>
      <ShiftPreferencesView baseSourcePath="resourceManagementRules[0].attributes" />
      <AppointmentInputs />
    </FormTab>
  );
}
