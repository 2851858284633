import React from "react";
import {
  Edit,
  useNotify,
  TextInput,
  SimpleForm,
  SaveButton,
  Toolbar,
  useRecordContext,
  useRedirect,
  useRefresh,
} from "react-admin";
import { validateEditPharmacy } from "./validateEditPharmacy";
import { Stack } from "../layout/Stack";
import { Group } from "../layout/Group";
import { Heading } from "../ui/Heading";
import { Grid } from "@mui/material";
import { ToolbarClasses } from "ra-ui-materialui";
import { DeleteButton } from "../DeleteButton";

const FormToolbar = () => {
  const record = useRecordContext();
  return (
    <Toolbar>
      <div className={ToolbarClasses.defaultToolbar}>
        <SaveButton />
        <DeleteButton
          label={"Delete"}
          resource={"pharmacies"}
          confirmTitle={`Delete ${record.name}?`}
          successMessage={`${record.name} was successfully deleted.`}
        />
      </div>
    </Toolbar>
  );
};

export function PharmacyManagementEdit() {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const inputStyles = {
    marginBottom: 0,
  };

  return (
    <Edit
      mutationMode={"pessimistic"}
      title={"Edit Pharmacy"}
      mutationOptions={{
        onSuccess: (data: any) => {
          const updatedPharmacyName = data.name;
          redirect(`/pharmacies`);
          refresh();
          notify(`Changes to ${updatedPharmacyName} were successful`);
        },
        onError: (error: any) => {
          const responseStatus = error?.response?.status || error?.status;

          if (responseStatus === 400) {
            notify("Bad request: Check your input data");
          } else if (responseStatus === 401) {
            notify(
              "Unauthorized: You don't have permission. Try logging out and in again"
            );
          } else if (responseStatus === 403) {
            notify(
              "Forbidden: You don't have access to this resource. Try logging out and in again"
            );
          } else if (responseStatus === 404) {
            notify(
              "Not Found: The requested resource was not found. If this continues, raise a Halo ticket"
            );
          } else if (responseStatus === 409) {
            notify("Conflict: Resource conflict or duplicate entry");
          } else if (responseStatus === 424) {
            notify("Failed Dependancy: Resource (email) already exists");
          } else {
            notify(
              "A generic error occurred while creating pharmacy. Code: " +
                responseStatus
            );
          }
        },
      }}
      transform={data => ({
        Name: data.name,
        PrimaryEmail: data.emailAddress,
        AddressLine1: data.address.line[0],
        AddressLine2: data.address.line[1],
        AddressLine3: data.address.line[2],
        City: data.address.city,
        State: data.address.state,
        PostalCode: data.address.postalCode,
        Telephones: data.phoneNumbers.map((phoneNumber: any) => ({
          number: phoneNumber.number,
          use: phoneNumber.use || "work",
        })),
      })}
    >
      <SimpleForm
        mode="onBlur"
        validate={validateEditPharmacy}
        noValidate
        toolbar={<FormToolbar />}
      >
        <Stack>
          <Group heading={<Heading level={2}>Details</Heading>}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  source="name"
                  label="Pharmacy Name"
                  required
                  fullWidth
                />
                <TextInput
                  source="emailAddress"
                  label="Email Address"
                  required
                  fullWidth
                  style={{ marginTop: "4px" }}
                />
                <TextInput
                  label="Phone Number"
                  required
                  fullWidth
                  source="phoneNumbers[0].number"
                />
                <TextInput
                  label="Use"
                  fullWidth
                  source="use"
                  defaultValue="work"
                  style={{ display: "none" }}
                />
              </Grid>
            </Grid>
          </Group>
          <Group heading={<Heading level={2}>Address</Heading>}>
            <Grid item xs={12} sm={6}>
              <div style={{ flexDirection: "column", display: "flex" }}>
                <TextInput
                  sx={inputStyles}
                  source="address.line[0]"
                  label="Address Line One"
                  required
                  fullWidth
                />
                <TextInput
                  sx={inputStyles}
                  source="address.line[1]"
                  label="Address Line Two"
                  fullWidth
                />
                <TextInput
                  sx={inputStyles}
                  source="address.line[2]"
                  label="Address Line Three"
                  fullWidth
                />
                <TextInput
                  sx={inputStyles}
                  source="address.city"
                  label="Town/City"
                  required
                  fullWidth
                />
                <TextInput
                  sx={inputStyles}
                  source="address.state"
                  label="State/County"
                  required
                  fullWidth
                />
                <TextInput
                  source="address.postalCode"
                  sx={inputStyles}
                  label="Post/Eir Code"
                  fullWidth
                />
              </div>
            </Grid>
          </Group>
        </Stack>
      </SimpleForm>
    </Edit>
  );
}
