import recursiveIsObjectEmpty from "../utilities/recursiveIsObjectEmpty";

type ErrorMessage = string;
interface GpValidation {
  [property: string]: ErrorMessage;
}

function validateGpAddress(values: any) {
  const errors: GpValidation = {};
  const { gp } = values;

  const shouldValidateGpAddress = !recursiveIsObjectEmpty(gp);

  if (!gp?.surgery) return {};

  if (shouldValidateGpAddress) {
    if (!values.gp.surgery?.name) {
      errors["gp.surgery.name"] = "The surgery name is required";
    }

    if (!values.gp.surgery?.address?.addressLine1) {
      errors["gp.surgery.address.addressLine1"] =
        "The surgery's first line of address is required";
    }

    if (!values.gp.surgery?.address?.city) {
      errors["gp.surgery.address.city"] = "The surgery's city is required";
    }

    if (!values.gp.surgery?.address?.postCode) {
      errors["gp.surgery.address.postCode"] =
        "The surgery's post code is required";
    }

    if (!values.gp.surgery?.address?.countryCode) {
      errors["gp.surgery.address.countryCode"] =
        "The surgery's country of residence is required";
    }
  }

  return errors;
}

export default validateGpAddress;
