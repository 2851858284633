import { GetManyReferenceParams } from "ra-core";
import { list } from "..";

function splitCompKey(id: string) {
  const [primaryKey] = id.split(":");

  return primaryKey;
}

const includes = [
  "user",
  "appointment",
  "client",
  "patientRecord",
  "appointment.clinicalPractitioner",
  "patientManagementSystemCase",
  "appointment.clinicalService"
];

async function getBookingsByReference(args: GetManyReferenceParams) {
  const { sort, id, target, pagination } = args;
  const { page = 1, perPage = 100 } = pagination;
  const { field, order } = sort;
  const sortQuery = `${order === "DESC" ? "-" : ""}${field}`;

  const foreignKey = splitCompKey(String(id));

  const params = {
    sort: sortQuery,
    "page[size]": perPage,
    "page[number]": page,
    [`filter[${target}]`]: foreignKey,
    include: includes.join(","),
  };

  const bookingsEndpoint = "/ms/bookings";
  const { data, total } = await list(bookingsEndpoint, { params });

  return { data, total };
}

export default getBookingsByReference;
