import React from "react";
import { useGetIdentity, RaRecord, useRecordContext } from "react-admin";
import AutocompleteInput from "../inputs/AutocompleteInput";
import get from "lodash/get";

export function AssignUserAutocompleteInput() {
  const record = useRecordContext();
  const { isAssigned } = record.attributes;
  const identity = useGetIdentity();
  const currentUserId = get(identity, "identity.id");

  return (
    <AutocompleteInput
      sx={{ width: "100%" }}
      label="Assign to"
      disabled={isAssigned}
      helperText=""
      placeholder="Search by CSO email..."
      filterToQuery={query => ({
        username: query,
      })}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      emptyText="Unassigned"
      optionText={(resourceRecord: RaRecord) => {
        if (!resourceRecord || !resourceRecord.attributes) return "Unassigned";

        if (resourceRecord.id === currentUserId) {
          const addedLabel = isAssigned ? "(Me)" : "(Assign to me)";
          return `${resourceRecord.attributes.userName} ${addedLabel}`;
        }

        return resourceRecord.attributes.userName;
      }}
    />
  );
}
