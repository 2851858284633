import React from "react";
import {
  Edit,
  SimpleForm,
  TextField,
  SaveButton,
  Toolbar,
  ToolbarProps,
  useNotify,
  useRedirect,
  useRefresh,
  ReferenceInput,
  SimpleShowLayout,
} from "react-admin";
import { useFormState } from "react-hook-form";
import { required } from "ra-core";
import { useParams } from "react-router-dom";
import { get } from "../../api/index";
import lodashGet from "lodash/get";
import AutocompleteInput from "../inputs/AutocompleteInput";

export function EditToolbar(props: Omit<ToolbarProps, "width">) {
  const { isValid } = useFormState();

  return (
    <Toolbar {...props}>
      <SaveButton label="Assign CP to Quinyx Resource" disabled={!isValid} />
    </Toolbar>
  );
}

const quinyxCpValidator = async (id: string, values: any, meta: any) => {
  if (!id) return;

  //running blur so it shows the error immediately
  meta.blur();

  const getCPEndpoint = `/ms/clinicalPractitioners/${id}`;
  const queryParams = {
    include: "quinyxDetails",
  };

  const { data } = await get(getCPEndpoint, queryParams);

  const existingAssignment = lodashGet(data[0], "quinyxDetails");
  const cpDetails = lodashGet(data[0], "attributes");
  const firstName = lodashGet(cpDetails, "firstName");
  const lastName = lodashGet(cpDetails, "lastName");

  if (!existingAssignment) return undefined;

  const existingAssignmentFirstName = lodashGet(
    existingAssignment,
    "[0].attributes.firstName"
  );
  const existingAssignmentLastName = lodashGet(
    existingAssignment,
    "[0].attributes.lastName"
  );

  return `${firstName} ${lastName} has already been assigned to the Quinyx resource ${existingAssignmentFirstName} ${existingAssignmentLastName}`;
};

export function QuinyxEdit() {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const params = useParams<{
    id: string;
  }>();

  return (
    <Edit
      mutationOptions={{
        onSuccess: () => {
          notify(
            "The clinical practitioner was successfully assigned to a Quinyx resource"
          );
          redirect("/shifts/quinyxResources/");
        },
        onError: () => {
          notify(
            "An error occurred. The clinical practitioner was not assigned to a Quinyx resource"
          );
          refresh();
        },
      }}
      mutationMode="pessimistic"
      resource="quinyxResources"
      title={`Quinyx Resource #${params.id}`}
      id={params.id}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <SimpleShowLayout>
          <TextField source="id" label="Quinyx ID" />
          <TextField source="attributes.badgeNumber" label="Badge Number" />
          <TextField source="attributes.firstName" label="First Name" />
          <TextField source="attributes.lastName" label="Last Name" />
        </SimpleShowLayout>
        <ReferenceInput
          source="clinicalPractitioners[0].id"
          reference="clinicalPractitioners"
          validate={[required(), quinyxCpValidator]}
        >
          <AutocompleteInput
            label="Enter a Clinical Practitioner"
            filterToQuery={query => ({
              fullName: query,
            })}
            placeholder="Search by CP name..."
            source="id"
            optionText="attributes.fullName"
            shouldRenderSuggestions={(query: string) => query.length > 1}
            suggestionLimit={7}
          />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}
