import React from "react";

import {
  Create,
  SaveButton,
  SelectInput,
  RaRecord,
  SimpleForm,
  Toolbar,
  useNotify,
  useRedirect,
  useGetOne,
} from "react-admin";
import validateForm from "./validateBookingRescheduleForm";
import { Heading } from "../ui/Heading";
import { genderChoices } from "../../constants/constants";
import { BookingDate } from "./BookingDate";
import AppointmentInput from "./AppointmentInput";
import { useParams } from "react-router-dom";
import { BookingRestrictionToggles } from "./BookingRestrictionToggles";
import AppointmentFilterRescheduleInputs from "./AppointmentFilterRescheduleInputs";
import { BookingTime } from "./BookingTime";
import { trackEvent } from "../../utilities/trackEvent";
import get from "lodash/get";

export function BookingReschedule() {
  const notify = useNotify();
  const redirect = useRedirect();
  const params = useParams<{
    id: string;
    userId: string;
    clientId: string;
  }>();

  const { id, userId, clientId } = params;

  function onSuccess(newRecord: RaRecord) {
    const newId = newRecord.id;
    notify(`Booking: ${newId} was rescheduled successfully`);
    trackEvent("Bookings", "Reschedule Booking");
    redirect(
      `/patientRecords/${userId}/clients/${clientId}/bookings/${newId}/summary`
    );
  }

  const { data } = useGetOne("bookings", { id: `${id}:${userId}:${clientId}` })
  const existingSpecialty = get(
    data,
    "appointments[0].attributes.specialties[0]"
  );
  const existingClinicalService = get(
    data,
    "appointments[0].attributes.clinicalServiceId"
  );

  return (
    <Create
      title="Reschedule Booking"
      mutationOptions={{ onSuccess }}
      resource="swapBooking"
      transform={(newRecord: RaRecord) => {
        newRecord.userId = userId;
        newRecord.bookingId = id;
        newRecord.clientId = clientId;
        return newRecord;
      }}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton label="Reschedule Booking" />
          </Toolbar>
        }
        validate={validateForm}
        defaultValues={{
          service: existingClinicalService ?? existingSpecialty
        }}
      >
        <Heading level={3} sx={{ marginBottom: 2, width: "initial" }}>
          Please choose the new appointment details
        </Heading>
        <AppointmentFilterRescheduleInputs clientId={`${clientId}`} patientRecordId={userId!} />
        <SelectInput
          source="clinicalPractitioner.gender"
          label="Preferred CP Gender"
          emptyText="No preference"
          choices={genderChoices}
        />

        <BookingRestrictionToggles displayClientContractRequirementsToggle />
        <BookingDate />
        <BookingTime />
        <AppointmentInput fallbackClientId={clientId} />
      </SimpleForm>
    </Create>
  );
}
