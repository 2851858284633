import { create, remove, update } from "..";
import {
  ClientPatientManagementSystemMappingDTO,
  ClientPatientManagementSystemsDTO,
} from "../../types/patientManagementSystems";
import deepEquals from "../../utilities/deepEquals";
import getClientMappingsFromPMSData, {
  createClientMappingsFromPmsDetails,
} from "./helpers/getClientMappingsFromPMSData";
import { updateClientCodeMapping } from "../client-codes";

type Args = {
  clientId: string;
  oldAssignedPMS: ClientPatientManagementSystemMappingDTO[];
  assignedPMS: ClientPatientManagementSystemMappingDTO[];
};

async function sendPmsCreateAndUpdateId(clientId: string, pmsCreateData: any) {
  const baseEndpoint = `/ms/clients/${clientId}/patientManagementSystems`;

  const contractBody = {
    data: {
      type: "clientPatientManagementSystems",
      attributes: {
        ...pmsCreateData.attributes,
      },
      relationships: {
        patientManagementSystem: {
          data: {
            id: pmsCreateData.pmsId,
            type: "patientManagementSystems",
          },
        },
      },
    },
  };

  const { data: createResponse } = await create(baseEndpoint, contractBody);
  pmsCreateData.id = createResponse.id;
}

async function sendPmsDelete(clientId: string, pmsId: string) {
  const baseEndpoint = `/ms/clients/${clientId}/patientManagementSystems`;
  const deletePMSEndpoint = `${baseEndpoint}/${pmsId}`;

  await remove(deletePMSEndpoint);
}

async function sendPmsUpdate(
  clientId: string,
  pmsUpdateData: ClientPatientManagementSystemsDTO["data"][0],
  pmsSettings: ClientPatientManagementSystemMappingDTO
) {
  const baseEndpoint = `/ms/clients/${clientId}/patientManagementSystems`;
  const updatePMSEndpoint = `${baseEndpoint}/${pmsUpdateData.id}`;

  const contractBody = {
    data: {
      id: pmsUpdateData.id,
      type: "clientPatientManagementSystems",
      attributes: {
        specialty: pmsUpdateData.attributes.specialty,
        isAutomaticCaseCreationEnabled:
          pmsSettings.isAutomaticCaseCreationEnabled,
        isEnabled: pmsSettings.isEnabled,
      },
      relationships: {
        patientManagementSystem: {
          data: {
            id: pmsUpdateData.relationships.patientManagementSystem?.data.id,
            type: "patientManagementSystems",
          },
        },
      },
    },
  };

  await update(updatePMSEndpoint, contractBody);
}

export async function updateAssignedPMS(args: Args) {
  const { oldAssignedPMS, assignedPMS, clientId } = args;

  const pmsRequests: Promise<any>[] = [];
  const newPmsDetails: any[] = [];
  const newPms = [];
  const deleteRequests: Promise<any>[] = [];
  const deletedPms: string[] = [];

  // If there is no change, return
  if (deepEquals(oldAssignedPMS, assignedPMS)) {
    return;
  }

  const specialtiesToDelete = oldAssignedPMS
    .map((pms: any, i: number) =>
      pms.specialties.filter(
        (oldSpecialty: any) =>
          !assignedPMS[i].specialties.includes(oldSpecialty)
      )
    )
    .flat();

  assignedPMS.forEach((pms: any, i: number) => {
    pms.specialties.forEach((specialty: any) => {
      if (!oldAssignedPMS[i].specialties.includes(specialty)) {
        newPmsDetails.push({
          clientCodeId: pms.clientCodeId,
          isEnabled: pms.isEnabled,
          isAutomaticCaseCreationEnabled: pms.isAutomaticCaseCreationEnabled,
          specialty: specialty,
          pmsId: pms.pms.id,
        });
      }
    });
  });

  assignedPMS.forEach(async (pms: any, index: any) => {
    const systemsToDelete = pms.mappings
      .filter((clientMapping: any) =>
        specialtiesToDelete.includes(clientMapping.attributes.specialty)
      )
      .flat();

    systemsToDelete.forEach(async (pmsToDelete: any) => {
      deleteRequests.push(sendPmsDelete(clientId, pmsToDelete.id));
      deletedPms.push(pmsToDelete.id);
    });

    if (!deepEquals(oldAssignedPMS[index], pms)) {
      for (const existingPms of assignedPMS[index].mappings) {
        if (!specialtiesToDelete.includes(existingPms.attributes.specialty)) {
          await sendPmsUpdate(clientId, existingPms, assignedPMS[index]);
        }
      }
    }
  });

  await Promise.all(deleteRequests);

  for (const newPmsData of newPmsDetails) {
    const pmsCreateData = {
      pmsId: newPmsData.pmsId,
      attributes: {
        specialty: newPmsData.specialty,
        isAutomaticCaseCreationEnabled:
          newPmsData.isAutomaticCaseCreationEnabled,
        isEnabled: newPmsData.isEnabled,
      },
    };

    pmsRequests.push(sendPmsCreateAndUpdateId(clientId, pmsCreateData));

    // Hacky - we need the id from the pmsCreateData response, which we won't have until the promise is resolved
    const newClientMappingData = {
      pmsCreateData,
      ...newPmsData,
    };
    newPms.push(newClientMappingData);
  }

  try {
    await Promise.all(pmsRequests);
  } catch (error) {
    console.log("Error updating PMS details", error);
  }

  const oldClientCodeMappings = getClientMappingsFromPMSData(
    oldAssignedPMS,
    clientId
  );
  const clientCodeMappings = createClientMappingsFromPmsDetails(
    assignedPMS,
    newPms,
    deletedPms,
    clientId
  );

  await updateClientCodeMapping({
    oldClientCodeMappings,
    clientCodeMappings,
  });
}

export default updateAssignedPMS;
