type ErrorMessage = string[];

interface HealthMailValidation {
  pharmacy?: ErrorMessage;
  file?: ErrorMessage;
}

function validateHealthMailSend(values: any) {
  const errors: HealthMailValidation = {};

  if (!values.pharmacy) {
    errors.pharmacy = ["Please select a pharmacy"];
  }

  if (!values.file && !values.documentId) {
    errors.file = ["Please upload a file"];
  }

  return errors;
}

export default validateHealthMailSend;
