import { MessageThreadShow } from "./MessageThreadShow";
import { MessageThreadEdit } from "./MessageThreadEdit";


export const messageThreadResources = {
  name: "messageThreads",
  options: { label: "Clinical Practitioners" },
  show: MessageThreadShow,
  edit: MessageThreadEdit
};
