import { GetOneParams, GetOneResult } from "ra-core";
import getAppointmentFilters from "./getAppointmentFilters";
import { splitByCapitalLetters } from "../../utilities/splitByCapitalLetters";
import uniqBy from "lodash/uniqBy";
import uniq from "lodash/uniq";
import { listClinicalServices } from "../clinicalServices";
import { GetListParams } from "react-admin";
import { sortAvailableServices } from "./helpers/sortAvailableServices";
import getPatientClinicalServices, { PatientClinicalServices } from "./getPatientClinicalServices";
import { FeatureFlagService } from "../../featureFlags";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS, TOGGLE_ENABLE_CLINICAL_PRODUCTS } from "../../featureFlags/flags";
import { getClient } from "./index";

interface AppointmentFilterCategories {
  consultationType: string;
  specialty?: string;
  clinicalServiceId?: number;
  clinicalService?: {
    id: number,
    name: string,
    deliveryType: string
  }
}

export interface AvailableService {
  id: string | number,
  name: string,
  group: string,
  consultationTypes: string[]
}

const defaultSpecialties = ["GeneralPractitioner", "AdvancedNursePractitioner", "ClinicalPharmacist", "Counsellor", "Dietitian", "Physiotherapist", "SpeechAndLanguage"]
const defaultConsultationTypes =  ["Phone","Video","MessageDoctor","Onsite"];

const getAllServices = async (args: GetOneParams) => {
  const clinicalServices = await listClinicalServices({
    sort: {field: "name", order: "ASC"}, pagination: {}, filter: {deliveryType: "Synchronous"}
  } as GetListParams)

  const availableServices = clinicalServices.data.map((cs: any) => {
    return {
      id: cs.id,
      name: splitByCapitalLetters(cs.attributes.name),
      group: "Clinical Services",
      consultationTypes: defaultConsultationTypes
    }
  });


  const specialties = defaultSpecialties.map((s: string) => {
    return {
      id: s,
      name: splitByCapitalLetters(s),
      group: "Specialties",
      consultationTypes: defaultConsultationTypes
    }
  })

  specialties.push(...availableServices);

  const consultationTypes = defaultConsultationTypes.map((ct: string) => {
    return {
      id: ct,
      name: splitByCapitalLetters(ct)
    }
  })

  return {
    data: {
      id: args.id,
      availableServices: specialties,
      consultationTypes,
      appointmentFiltersEnabled: false
    }
  };
}

async function getAvailableServices(
  args: GetOneParams
): Promise<GetOneResult> {
  const featureFlags = FeatureFlagService.getInstance();
  const clinicalProductsEnabledFlag = featureFlags.getFlag(
    TOGGLE_ENABLE_CLINICAL_PRODUCTS
  );

  const clientClinicalProductsEnabledFlag = featureFlags.getFlag(
    TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS
  );

  const consultationType = args.meta?.filter?.consultationType;
  const patientRecordId = args.meta?.filter?.patientRecordId;

  if (clinicalProductsEnabledFlag || clientClinicalProductsEnabledFlag) {

    const client = await getClient(args);
    if (client?.data?.attributes?.clinicalProductsEnabled)
    {
      return getPatientServices(args.id, patientRecordId);
    }
  }

  const appointmentFilters = await getAppointmentFilters(args);

  if (!appointmentFilters.data?.attributes?.categories?.length) {
    const allServices = getAllServices(args);

    return Promise.resolve(allServices);
  }

  const filteredCategories =  appointmentFilters.data.attributes.categories
    .filter((category: AppointmentFilterCategories) => !consultationType || consultationType === category.consultationType)

  const services = filteredCategories
    .map((category: AppointmentFilterCategories) => {
    if (category.specialty)
      {
        return {
                id: category.specialty,
                name: splitByCapitalLetters(category.specialty),
                group: "Specialties",
                consultationTypes: filteredCategories
                  .filter((c: AppointmentFilterCategories) => c.specialty === category.specialty)
                  .map((c: AppointmentFilterCategories) => c.consultationType)
              };
      }
    if (category.clinicalService)
      return {
        id: category.clinicalServiceId,
        name: splitByCapitalLetters(category.clinicalService.name),
        group: "Clinical Services",
        consultationTypes: filteredCategories
          .filter((c: AppointmentFilterCategories) => c.clinicalServiceId === category.clinicalServiceId)
          .map((c: AppointmentFilterCategories) => c.consultationType)
      }
  }) as AvailableService[];

  const availableServices = uniqBy(services, "id");

  sortAvailableServices(availableServices);

  const consultationTypes = filteredCategories.map((category: AppointmentFilterCategories) => {
    return {
      id: category.consultationType,
      name: splitByCapitalLetters(category.consultationType)
    }
  })

  const availableConsultationTypes = uniqBy(consultationTypes, "id");

  const result = {
    data: {
      id: args.id,
      availableServices,
      availableConsultationTypes,
      appointmentFiltersEnabled: true
    }
  };

  return Promise.resolve(result);
}

async function getPatientServices(clientId: number, patientRecordId: string) {

  const productServices = await getPatientClinicalServices({
    filter: { clientId, patientRecordId },
    pagination: { page: 1, perPage: 50}, sort: { field: "name", order: "ASC" }
  });

  const productServicesResult = productServices
    .data.map((service: PatientClinicalServices) => {
      return {
        id: service.attributes.specialty ?? service.attributes.clinicalServiceId,
        name: service.attributes.specialty ? splitByCapitalLetters(service.attributes.specialty) : service.clinicalServices?.[0]?.attributes.name,
        group: service.attributes.specialty ? "Specialties" : "Clinical Services",
        consultationTypes: service.attributes.consultationTypes
      }
    });

  sortAvailableServices(productServicesResult)

  const uniqConsultationTypes =
    uniq(productServicesResult.flatMap((item: any) => item.consultationTypes))
      .map((value: any,index: number) => ({id: index, name: splitByCapitalLetters(value)}));

  return Promise.resolve({
    data: {
      id: clientId,
      availableServices: productServicesResult,
      availableConsultationTypes: uniqConsultationTypes,
      appointmentFiltersEnabled: true
    }
  });
}


export default getAvailableServices;
