// Returns the string where the first letter of each word is capitalized
// e.g. ("YOUR_EXAMPLE", _) => "Your Example"
export const convertToTitleCase = (value: string, delimiter?: string) => {
  if (delimiter) {
    const words = value.split(delimiter);

    return words
      .map((x: any) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
      .join(" ");
  }

  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}
