import { useListContext } from "react-admin";
import { Card, Typography } from "@mui/material";
import React from "react";

interface Props {
  text?: string;
}

export function ListWithoutFilterEmptyText({ text }: Props) {
  const context = useListContext();
  const { total, isFetching } = context;

  if (total === 0 && !isFetching) {
    return (
      <Card style={{ padding: 16 }}>
        <Typography variant="body1">
          {text || "No results found. You can create a new record, or use the filters to search."}
        </Typography>
      </Card>
    );
  }

  return null;
}


export const removeDefaultEmptyMessageStyle = {
  "& .MuiCardContent-root p": {
    // To hide the default empty message. This shouldn't hide anything else
    display: "none",
  },
}
