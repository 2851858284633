import React, { useState } from "react";
import { Button, Tooltip } from "@mui/material";
import { EmployerEditDialog } from "../dialogs/EmployerEditDialog";
import { FieldValues } from "react-hook-form";

export const EditEmployerButtonField = (props: {updateEmployer: (id: string, values: FieldValues) => void}) => {
  const [isEditDialogOpened, setIsEditDialogOpened] = useState(false);
  const { updateEmployer } = props;
  return (
    <>
      <Tooltip title="Edit employer">
        <Button
          data-testid={`editEmployerButton`}
          variant="contained"
          size="small"
          onClick={() => setIsEditDialogOpened(true)}
        >
          Edit
        </Button>
      </Tooltip>
      <EmployerEditDialog
        updateEmployer={updateEmployer}
        isOpened={isEditDialogOpened}
        onClose={() => setIsEditDialogOpened(false)}
      />
    </>
  );
};
