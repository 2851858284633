import React from "react";
import {
  Create,
  RaRecord,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from "react-admin";
import { Stack } from "../layout/Stack";
import { Group } from "../layout/Group";
import { Heading } from "../ui/Heading";
import { Grid } from "@mui/material";
import { validateCreatePharmacy } from "./validateCreatePharmacy";
import { extractGuid } from "../../utilities/extractGuidFromFhirId";

export function PharmacyManagementCreate() {
  const notify = useNotify();
  const redirect = useRedirect();

  const inputStyles = {
    marginBottom: 0,
  };

  return (
    <Create
      title="Create Pharmacy"
      mutationOptions={{
        onSuccess: (newRecord: RaRecord) => {
          const id = newRecord.id.toString();
          const guid = extractGuid(id);
          notify("Pharmacy was created successfully");
          redirect(`/pharmacies/${guid}/show`);
        },
        onError: (error: any) => {
          const responseStatus = error?.response?.status || error?.status;

          if (responseStatus === 400) {
            notify("Bad request: Check your input data");
          } else if (responseStatus === 401) {
            notify(
              "Unauthorized: You don't have permission. Try logging out and in again"
            );
          } else if (responseStatus === 403) {
            notify(
              "Forbidden: You don't have access to this resource. Try logging out and in again"
            );
          } else if (responseStatus === 404) {
            notify(
              "Not Found: The requested resource was not found. If this continues, raise a Halo ticket"
            );
          } else if (responseStatus === 409) {
            notify("Conflict: Resource conflict or duplicate entry");
          } else if (responseStatus === 424) {
            notify("Failed Dependancy: Resource (email) already exists");
          } else {
            notify(
              "A generic error occurred while creating pharmacy. Code: " +
                responseStatus
            );
          }
        },
      }}
    >
      <SimpleForm
        mode="onBlur"
        warnWhenUnsavedChanges
        validate={validateCreatePharmacy}
        noValidate
      >
        <Stack>
          <Group heading={<Heading level={2}>Details</Heading>}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  source="Name"
                  label="Pharmacy Name"
                  required
                  fullWidth
                />
                <TextInput
                  source="PrimaryEmail"
                  label="Email Address"
                  required
                  fullWidth
                  style={{ marginTop: "4px" }}
                />
                <TextInput
                  label="Phone Number"
                  fullWidth
                  required
                  source="Telephones[0].Number"
                />
                <TextInput
                  label="Use"
                  fullWidth
                  source="Telephones[0].Use"
                  defaultValue="work"
                  style={{ display: "none" }}
                />
              </Grid>
            </Grid>
          </Group>
          <Group heading={<Heading level={2}>Address</Heading>}>
            <Grid item xs={12} sm={6}>
              <TextInput
                sx={inputStyles}
                source="AddressLine1"
                label="Address Line One"
                required
                fullWidth
              />
              <TextInput
                sx={inputStyles}
                source="AddressLine2"
                label="Address Line Two"
                fullWidth
              />
              <TextInput
                sx={inputStyles}
                source="AddressLine3"
                label="Address Line Three"
                fullWidth
              />
              <TextInput
                sx={inputStyles}
                source="City"
                label="Town/City"
                required
                fullWidth
              />
              <TextInput
                sx={inputStyles}
                source="State"
                label="State/County"
                required
                fullWidth
              />
              <TextInput
                source="PostalCode"
                sx={inputStyles}
                label="Post/Eir Code"
                fullWidth
              />
            </Grid>
          </Group>
        </Stack>
      </SimpleForm>
    </Create>
  );
}
