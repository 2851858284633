import dayjs, { Dayjs } from "dayjs";

export const minDate = (min: string) => (date: string | null) => {
  const formattedDate = dayjs(min).format("DD/MM/YYYY");
  const message = `Choose a date after ${formattedDate}`;

  return validate(min, date, message, "d");
};

export const minTime = (min: Dayjs) => (date: Dayjs | null) => {
  if (!date) {
    return "Please enter a valid date";
  }

  const formattedMinTime = min.format("HH:mm");
  const message = `Choose a time after ${formattedMinTime}`;

  return validate(min, date, message, "s");
};

export const minDateAndTime = (min: string) => (date: string | null) => {
  const formattedMaxDateAndTime = dayjs(min).format("DD/MM/YYYY HH:mm");

  const message = `Choose a period after ${formattedMaxDateAndTime}`;

  return validate(min, date, message, "s");
};

const validate = (
  min: Dayjs | string,
  date: Dayjs | string | null,
  message: string,
  unit: dayjs.OpUnitType
) => {
  const isValid =
    dayjs(date).isAfter(min, unit) || dayjs(date).isSame(min, unit);

  const error = !isValid ? message : undefined;

  return error;
};
