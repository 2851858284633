import { create } from "./index";

interface RefundConsultationBody {
  consultationId: string
}

async function refundConsultation(data: RefundConsultationBody) {
  await create('/ms/notifications/consultationRefund', data)

  return {
    data: {
      id: "id-to-satisfy-ra",
    },
  };
}

export default refundConsultation;
