import { FormDataConsumer } from "ra-core";
import { CheckboxGroupInput, NumberInput } from "ra-ui-materialui";
import React from "react";
import { ShiftPreferencesController } from "./ShiftPreferencesController";
import { AppointmentsPerHourInput } from "./AppointmentsPerHourInput";
import { Stack } from "../../layout/Stack";
import { AdornedField } from "../../fields/AdornedField";
import TooltipAdornment from "../../booking/TooltipAdornment";
import { textInputAdornmentStyles } from "../../../theme";
import { backToBackAppointmentsMessage } from "../../../constants/tooltipMessages";
import getShouldOptimiseApts from "../../shifts/helpers/getShouldOptimiseApts";

interface Props {
  baseSourcePath?: string;
}

export function ShiftPreferencesInput(props: Props) {
  const { baseSourcePath = "" } = props;

  return (
    <ShiftPreferencesController baseSourcePath={baseSourcePath}>
      <Stack sx={{ "*": { marginBlockStart: 0 } }}>
        <NumberInput
          source={`${baseSourcePath}appointmentDuration`}
          label="Appointment duration"
          min="0"
        />
        <BackToBackAppointmentsCheckbox />
        <FormDataConsumer>
          {({ formData }) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const shouldOptimiseApts = getShouldOptimiseApts(formData);

            if (shouldOptimiseApts) return null;

            return <AppointmentsPerHourInput basePrefix={baseSourcePath} />;
          }}
        </FormDataConsumer>
      </Stack>
    </ShiftPreferencesController>
  );
}

function BackToBackAppointmentsCheckbox() {
  return (
    <AdornedField
      Field={CheckboxGroupInput}
      source="backToBackApts"
      label=""
      choices={[{ id: "backToBack", name: "Optimise Appointments" }]}
      adornment={
        <TooltipAdornment
          sx={textInputAdornmentStyles}
          text={backToBackAppointmentsMessage}
        />
      }
    />
  );
}
