import React from "react";
import { SpecialtyInput } from "../SpecialtyInput";
import { ConsultationTypeInput } from "../ConsultationTypeInput";
import { ClinicalAddressesInputs } from "../ClinicalAddressesInputs";
import { AppointmentTimeInputs } from "../AppointmentTimeInputs";
import { PatientDetailsInputs } from "../PatientDetailsInputs";
import { ReasonForBookingInput } from "../ReasonForBookingInput";
import { genderChoices } from "../../../constants/constants";
import { BookingAttachmentInput } from "../BookingAttachmentInput";
import { Labeled, SelectInput, TextField, TextInput } from "ra-ui-materialui";
import { useLocation } from "react-router-dom";
import get from "lodash/get";
import { useSchedulerBookingStore } from "../../../context/schedulerBookingStore";
import { SchedulerBookingCreateForm } from "./SchedulerBookingCreateForm";
import { useWatch } from "react-hook-form";
import { Stack } from "../../layout/Stack";
import { Group } from "../../layout/Group";
import { Heading } from "../../ui/Heading";
import { Grid } from "@mui/material";
import { ReasonForMADInput } from "../ReasonForMADInput";
import { required, useGetOne } from "react-admin";
import { ClientSettingsDTO } from "../../../types/clientSettings";
import TooltipAdornment from "../TooltipAdornment";
import { clientIdentifierMessage } from "../../../constants/tooltipMessages";
import { VNetIntake } from "./VNetIntake";
import { SymptomSignposting } from "./SymptomSignposting/SymptomSignposting";
import { isVNetCaseService, isVNetManualIntakeService } from "../helpers/vNetUtils";
import { VNetCase } from "./VNetCase";
import { ClientEmployerInput } from "../../inputs/ClientEmployerInput";


export function BookingCreateForm() {
  const { state } = useLocation();
  const clientId = get(state, "record.clients[0].id");
  const dependantId = get(state, "record.dependantId");
  const userId = get(state, "record.userId");
  const patientRecordId = dependantId ? dependantId : userId;
  const store = useSchedulerBookingStore(s => ({
    appointmentId: s.appointmentId,
    state: s.state,
  }));

  const consultationType = useWatch({ name: "consultationType" });
  const isMAD = consultationType === "MessageDoctor";

  const service = useWatch({name: "service"});


  const { data: client } = useGetOne("clients", { id: clientId });
  const clientIdentifierRequired = get(state, "record.clients[0].attributes.clientIdentifierRequired");
  const clientSettings = client?.clientSettings as ClientSettingsDTO["data"][];
  const symptomSignpostingEnabled = get(state, "record.clients[0].attributes.symptomSignpostingEnabled");

  const collectProductId =
    clientSettings?.some(
      s =>
        s.attributes.key == "CollectProductId" && s.attributes.value == "true"
    ) ?? false;

  if (store.state === "HELD" || store.state === "COMPLETE") {
    return (
      <SchedulerBookingCreateForm
        appointmentId={store.appointmentId}
        clientId={clientId}
        patientRecordId={patientRecordId}
      />
    );
  }
  
  const showManualIntake = isVNetManualIntakeService(client, service);
  const showIhcCase = isVNetCaseService(client, service);

  return (
    <Stack>
      {symptomSignpostingEnabled &&
        <SymptomSignposting
          clientId={clientId}
          patientRecordId={patientRecordId}
        />
      }
      <Group heading={<Heading level={2}>General Information</Heading>}>
        <Grid item xs={12}>
          <Labeled label="Patient Name">
            <TextField source="patientName" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SpecialtyInput
            clientId={clientId}
            validate={required()}
            patientRecordId={patientRecordId}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ConsultationTypeInput clientId={clientId} patientRecordId={patientRecordId} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectInput
            source="clinicalPractitioner.gender"
            label="Preferred CP Gender"
            emptyValue="noPreference"
            emptyText="No preference"
            choices={genderChoices}
          />
        </Grid>
        {isMAD ? <ReasonForMADInput /> : <ReasonForBookingInput />}
        {collectProductId ? (
          <Grid item xs={12} sm={6}>
            <TextInput label="Product ID" source="productId" />
          </Grid>
        ) : null}
        {clientIdentifierRequired ? (
          <Grid item xs={12} sm={6}>
            <TextInput
              style={{ width: "360px" }}
              source="clientIdentifier"
              label="Policy Number"
              InputProps={{
                endAdornment: (
                  <TooltipAdornment
                    text={clientIdentifierMessage}
                  />
                ),
              }}
              required
            />
          </Grid>
        ): null}
        <Grid item xs={12} sm={6}>
          <ClientEmployerInput
            clientId={clientId}
            source="employerId"
          />
        </Grid>
        {showManualIntake &&
          <Grid item xs={12} sm={6}>
            <VNetIntake />
          </Grid>}
        {showIhcCase &&
          <Grid item xs={12} sm={6}>
            <VNetCase />
          </Grid>}
      </Group>

      <AppointmentTimeInputs />

      {isMAD ? (
        <>
          <Group heading={<Heading level={2}>Attachments</Heading>}>
            <BookingAttachmentInput />
          </Group>
        </>
      ) : (
        <>
          <ClinicalAddressesInputs />
          <PatientDetailsInputs />
        </>
      )}
    </Stack>
  );
}
