import { GetOneParams } from "ra-core";
import { HealthmailRecordResponse } from "../../types/healthmail";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";
import GaiaClientService from "../gaiaClientService";
import { v4 as uuid } from "uuid";

const emptyResult = {
  data: {
    id: null,
  }
};

const client = new GaiaClientService();

async function getHealthmailRecord(params: GetOneParams) {
  const { id, meta } = params;
  const defaultOnError = meta?.defaultOnError;
  const isUniqueCaseReferenceId = !id.includes("__");
  
  try {
    if (isUniqueCaseReferenceId) {
      return await getHealthmailByCaseReference(id);
    }

    return await getHealthmailByCaseNumberQuery(id);
  } catch (err) {
    console.error(err);

    if (defaultOnError) {
      return emptyResult;
    }

    throw err;
  }
}

async function getHealthmailByCaseReference(caseReference: string) {
  const endpoint = `healthmail/cases`;
  
  const { data }: HealthmailRecordResponse = await client.list(endpoint, {
    params: { caseReference },
    headers: { "x-correlation-id": uuid() },
  });

  const healthmailRecord = data.healthmailDataOutputs[0];

  return {
    data: {
      id: caseReference,
      ...healthmailRecord,
    },
  };
}

async function getHealthmailByCaseNumberQuery(compoundQueryReference: string) {
  const [caseNumber, startDate, endDate] = String(compoundQueryReference).split("__");

  const queryParams = {
    startDate,
    endDate,
  };

  const filteredParams = shallowlyRemoveEmptyValues(queryParams);

  const endpoint = `healthmail/cases/${caseNumber}`;

  const { data }: HealthmailRecordResponse = await client.list(endpoint, {
    params: filteredParams,
    headers: { "x-correlation-id": uuid() },
  });

  const healthmailRecord = data.healthmailDataOutputs[0];

  return {
    data: {
      id: compoundQueryReference,
      caseNumber,
      ...healthmailRecord,
    },
  };
}

export default getHealthmailRecord;
