type ErrorMessage = string;

interface NotificationError {
  attributes: {
    emailFromName?: ErrorMessage;
    emailFromAddress?: ErrorMessage;
    twilioSmsSenderName?: ErrorMessage;
  };
}

interface NotificationValidation {
  notificationSettings: {
    attributes: {
      emailFromName: string;
      emailFromAddress: string;
      twilioSmsSenderName: string;
    };
  };
}

interface Errors {
  notificationSettings: NotificationError;
}

function validateNotifications(values: NotificationValidation) {
  const {
    emailFromName,
    emailFromAddress,
    twilioSmsSenderName
  } = values.notificationSettings.attributes;

  const errors: Errors["notificationSettings"]["attributes"] = {};

  if (emailFromName) {
    if (!emailFromAddress) {
      errors.emailFromAddress = "The sender email address is required";
    }
  }
  if (emailFromAddress) {
    if (!emailFromName) {
      errors.emailFromName = "The email sender name is required";
    }
  }

  if (twilioSmsSenderName) {
    if (twilioSmsSenderName.length > 11) {
      errors.twilioSmsSenderName = "Max length of the SMS sender name is 11 characters"
    }
    else if (!twilioSmsSenderName.match("^[\\sA-Za-z0-9._&-]*$")) {
      errors.twilioSmsSenderName =
        "The SMS sender name can only contain a letter, number, period, underscore, dash, ampersand, or space"
    }
  }

  return {
    notificationSettings: {
      attributes: errors,
    },
  } as Errors;
}

export default validateNotifications;
