import React from "react";
import { SaveButton, Toolbar, ToolbarProps, Button } from "ra-ui-materialui";
import { useEditStateContext } from "../../../context/EditStateContext";
import EditIcon from "@mui/icons-material/Edit";
import { EditableStates } from "./FormTab";
import { useFormState } from "react-hook-form";
import { Box } from "@mui/material";

const wrapperStyles = {
  height: "100%",
  position: "absolute",
  right: "0",
  width: "fit-content",
  pointerEvents: "none",
};

const toolbarStyles = {
  marginTop: "0",
  padding: "0 16px",
  background: "none",
  display: "flex",
  justifyContent: "flex-end",
  position: "sticky",
  top: "112px", // this ensures it isn't hidden by the navbar + sticky tabs
};

const buttonStyles = {
  pointerEvents: "all",
} as const;

interface Props extends Omit<ToolbarProps, "width"> {
  label: string;
  editable?: EditableStates;
}

export function ToolbarBase(props: Props) {
  const { label, editable, ...rest } = props;
  const { currentState, updateState } = useEditStateContext();
  const { isSubmitting, isValid } = useFormState();

  const isEditable = editable === "editable";

  return (
    <Box sx={wrapperStyles}>
      <Toolbar sx={toolbarStyles} {...rest}>
        {currentState === "edit" ? (
          <SaveButton
            sx={buttonStyles}
            saving={isSubmitting}
            invalid={!isValid}
            disabled={!isEditable}
          />
        ) : null}
        {currentState === "show" ? (
          <Button
            sx={buttonStyles}
            onClick={() => updateState("edit")}
            label={label}
            variant="outlined"
            size="medium"
            disabled={!isEditable}
          >
            <EditIcon />
          </Button>
        ) : null}
      </Toolbar>
    </Box>
  );
}
