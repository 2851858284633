import { ClinicalProductsDTO, ClinicalProductServiceDTO } from "../../types/clinicalProducts";
import { create } from "../index";
import { FeatureFlagService } from "../../featureFlags";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS } from "../../featureFlags/flags";

async function createClinicalProduct(args: any) {
  const clientClinicalProductsEnabledFlag = FeatureFlagService.getInstance().getFlag(
    TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS
  );

  const { servicesIds } = args;

  const endpoint = clientClinicalProductsEnabledFlag ?
    `/ms/clients/${args.attributes.clientId}/clinicalProducts` :
    "/ms/clinicalProducts";
  
  const clinicalProductServices = clientClinicalProductsEnabledFlag ?
    args.attributes.clinicalProductServices :
    servicesIds.map((serviceId: any): ClinicalProductServiceDTO["data"]["attributes"] => {
      if (isNaN(serviceId)) {
        return {
          specialty: serviceId,
        } as ClinicalProductServiceDTO["data"]["attributes"];
      } else {
        return {
          clinicalServiceId: serviceId,
        } as ClinicalProductServiceDTO["data"]["attributes"];
      }
    });

  const body = {
    data: {
      type: "clinicalProducts",
      attributes: {
        ...args.attributes,
        clinicalProductServices
      }
    }
  } as ClinicalProductsDTO;


  return await create(endpoint, body);
}

export default createClinicalProduct;
