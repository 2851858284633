import { Identifier } from "react-admin";
import { get } from "..";
import { ClientDTO } from "../../types/clients";

type Args = {
  clientId: Identifier;
};

const includedRelationships = [
  "paymentSettings",
  "clientContracts",
  "appointmentFilters",
  "appointmentFilters.holidayCalendar",
  "notificationSettings",
  "notificationChannelSettings",
  "notificationTypeSettings",
  "employerSettings",
  "clientSettings",
  "clientBookingChannels",
];

async function getSingleClient(args: Args): Promise<ClientDTO["data"][]> {
  const { clientId } = args;
  const query = {
    include: includedRelationships.join(","),
  };

  try {
    const { data } = await get(`/ms/clients/${clientId}`, query);

    const clientData = Array.isArray(data) ? data : [data];

    return clientData;
  } catch (err) {
    console.error(err);
    throw err;
  }
}
export default getSingleClient;
