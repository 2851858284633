import validateResourceRules from "./validateResourceRules";
import validateCPDocuments from "./validateCPDocuments";
import { validateRegistrations } from "./validateRegistrations";

function validateEditCP(values: any) {
  const errors: Record<string, string> = {};

  if (!values.attributes?.extraCompliance) {
    errors["attributes.extraCompliance"] = "A CP compliance level is required";
  }

  const cpDocumentsRulesErrors = validateCPDocuments(values);
  const resourceRulesErrors = validateResourceRules(values);
  const registrationNumberErrors = validateRegistrations(values);

  return { ...cpDocumentsRulesErrors, ...resourceRulesErrors, ...registrationNumberErrors, ...errors };
}

export default validateEditCP;
