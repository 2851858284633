import { SelectInput, TextInput } from "ra-ui-materialui";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  ExtendedAddress,
  useAddressContext,
} from "../../../context/AddressContext";
import { countrySelectChoices } from "../../../data/countryCodes";

interface Props {
  sourcePrefix: string;
  disabled?: boolean;
}

const inputStyles = {
  marginBottom: 0,
};

export function AddressInputs(props: Props) {
  const { sourcePrefix, disabled } = props;

  const { currentAddress } = useAddressContext();
  const form = useFormContext();

  const addressId = currentAddress?.id;

  useEffect(() => {
    if (!addressId) return;

    const { addressLine1, addressLine2, city, countryCode, postCode } =
      currentAddress as ExtendedAddress;

    form.setValue(`${sourcePrefix}.addressLine1`, addressLine1);
    form.setValue(`${sourcePrefix}.addressLine2`, addressLine2);
    form.setValue(`${sourcePrefix}.city`, city);
    form.setValue(`${sourcePrefix}.postCode`, postCode);
    form.setValue(`${sourcePrefix}.countryCode`, countryCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressId]);

  return (
    <div style={{ flexDirection: "column", display: "flex" }}>
      <TextInput
        sx={inputStyles}
        source={`${sourcePrefix}.addressLine1`}
        label="Address Line One"
        required
        disabled={disabled}
        fullWidth
      />
      <TextInput
        sx={inputStyles}
        source={`${sourcePrefix}.addressLine2`}
        label="Address Line Two"
        disabled={disabled}
        fullWidth
      />
      <TextInput
        sx={inputStyles}
        source={`${sourcePrefix}.city`}
        label="City"
        required
        disabled={disabled}
        fullWidth
      />
      <TextInput
        source={`${sourcePrefix}.postCode`}
        sx={inputStyles}
        label="Post Code"
        disabled={disabled}
        fullWidth
      />
      <SelectInput
        source={`${sourcePrefix}.countryCode`}
        sx={inputStyles}
        label="Country"
        choices={countrySelectChoices}
        required
        disabled={disabled}
        fullWidth
      />
    </div>
  );
}
