import { GetManyParams } from "ra-core";
import { get } from "..";

async function getManyExpertiseAreas(params: GetManyParams) {
  const { ids } = params;
  const url = "/ms/expertiseAreas";

  const filters = {
    "filter[id]": `in:${ids}`,
  };

  return await get(url, filters);
}

export default getManyExpertiseAreas;
