import dayjs from "dayjs";
import { baseUrl, get } from "../";
import { UserSummariesDTO } from "../../types/users";
import { BookingNotification } from "./getBookingNotifications";
import { Appointment } from "../../types/bookings";
import { convertToTitleCase } from "../../utilities/convertToTitleCase";

interface Args {
  bookingId: string;
  userId: string;
  clientId: string;
  consultationId: string;
}

interface EventBase {
  id: string;
  attributes: {
    status: string;
    eventType: string;
    createdAt: Date;
  };
  userSummaries: UserSummariesDTO["data"];
  appointments?: Appointment[];
  relationships: {
    user: {
      data: {
        id: string;
      };
    };
  };
}
interface BookingEvent extends EventBase {
  type: "bookingEvents";
}

interface ConsultationEvent extends EventBase {
  type: "consultationEvents";
}

interface Event {
  id: string;
  eventType: string;
  status: string;
  createdAt: Date;
  user: string;
  oldAppointment?: Appointment;
  oldAppointmentId?: string;
  newAppointment?: Appointment;
  newAppointmentId?: string;
}

interface ConsolidateEventsArgs {
  bookingEvents: BookingEvent[];
  consultationEvents: ConsultationEvent[];
  bookingNotifications: BookingNotification[];
}

function getEventStatus(metadata: BookingNotification["attributes"]["metadata"]): string {
  if (metadata?.deliveryEvent?.event) {
    return convertToTitleCase( metadata?.deliveryEvent?.type || metadata?.deliveryEvent?.event);
  }

  return metadata?.channelData?.isDelivered ? 'Sent' : 'Failed';
}

function consolidateEvents(args: ConsolidateEventsArgs): Event[] {
  const { bookingEvents, consultationEvents, bookingNotifications } = args;

  const mappedEvents = [...bookingEvents, ...consultationEvents].map(event => {
    return {
      id: event.id,
      icon: '',
      ...event.attributes,
      appointments: Object.fromEntries(event.appointments?.map(x => [x.id, x]) ?? []),
      user: event.userSummaries[0].attributes.userName
    };
  });

  const mappedNotifications = bookingNotifications.map(event => {
    const channel = event.attributes.notificationChannel;
    let user = '';
    if (channel === 'Email') {
      user = event.attributes.metadata.channelData.attributes?.destination ?? event.attributes.metadata.contactDetails.email;
    } else if (channel === 'SMS') {
      user = event.attributes.metadata.channelData.attributes?.destination ?? event.attributes.metadata.contactDetails.phoneNumber;
    }
    return {
      id: event.id,
      status: getEventStatus(event.attributes.metadata),
      icon: event.attributes.notificationChannel,
      eventType: event.attributes.notificationType + 'Notification',
      createdAt: event.attributes.createdAt,
      user: user,
      appointments: {}
    };
  });

  const events = mappedEvents
    .concat(mappedNotifications)
    .sort((a, b) => {
      const aStart = dayjs(a.createdAt);
      const bStart = dayjs(b.createdAt);

      return dayjs(aStart).isBefore(bStart) ? -1 : 1;
    });

  return events;
}

async function getBookingEvents(args: Args) {
  const { clientId, userId, bookingId, consultationId } = args;

  const eventsQuery = {
    include: "user",
  };

  const bookingEventsQuery = {
    include: "user,oldAppointment,oldAppointment.clinicalPractitioner,newAppointment,newAppointment.clinicalPractitioner",
  }

  const bookingEventsReq = get(
    `${baseUrl}/clients/${clientId}/users/${userId}/bookings/${bookingId}/events`,
    bookingEventsQuery
  );

  const consultationEventsReq = get(
    `${baseUrl}/clients/${clientId}/users/${userId}/consultations/${consultationId}/consultationEvents`,
    eventsQuery
  );

  const bookingNotificationsReq = get(
    `${baseUrl}/clients/${clientId}/users/${userId}/bookings/${bookingId}/notifications`
  );

  const [bookingEvents, consultationEvents, bookingNotifications] = await Promise.all([
    bookingEventsReq,
    consultationEventsReq,
    bookingNotificationsReq,
  ]);

  return consolidateEvents({
    bookingEvents: bookingEvents.data,
    consultationEvents: consultationEvents.data,
    bookingNotifications: bookingNotifications.data
  });
}

export default getBookingEvents;
