import { Button, ButtonProps, Confirm, useRecordContext, useRedirect } from "react-admin";
import { useACL } from "ra-access-control-lists";
import { useDelete, useNotify } from "ra-core";
import React, { useState } from "react";

interface DeleteButtonProps extends ButtonProps {
  successMessage: string;
  confirmTitle: string;
  resource: string;
}

export function DeleteButton(props: DeleteButtonProps) {
  const { label, resource, confirmTitle, successMessage } = props;
  const permission = "edit";
  const access = useACL(resource!);
  const canAccess = access[permission];
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();

  const [open, setOpen] = useState(false);

  const [remove, { isLoading }] = useDelete(
    resource,
    { id: record && record.id },
    {
      onSuccess: () => {
        setOpen(false);
        notify(successMessage);
        redirect(`/${resource}`);
      },
      onError: (error: Error) => {
        setOpen(false);
        notify(`An error occurred. ${error.message}`,
          {
            type: "error"
          }
        );
      }

    }
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  if (!canAccess) return null;


  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size={"large"}
        onClick={handleClick}
        label={label}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={confirmTitle}
        content="Are you sure you want to delete this item?"
        onConfirm={() => remove()}
        onClose={handleDialogClose}
      />
    </>
  );
}
