import {
  Datagrid,
  Filter,
  List,
  ReferenceInput,
  TextField,
  SelectInput,
} from "ra-ui-materialui";
import { useListContext } from "react-admin";
import React from "react";
import dayjs from "dayjs";
import getCurrentDate from "../../utilities/getCurrentDate";
import { ExtendedListActionsProps, ListActions } from "../Actions";
import { FullNameField } from "../fields/FullNameField";
import { ImportButton } from "./ImportButton";
import { QuinyxButton } from "../quinyxUnassignedResources/QuinyxButton";
import { shiftStatusChoices } from "../../constants/constants";
import { ResetListFilterButton } from "../buttons/ResetListFilterButton";
import { DateInput } from "../inputs/DateInput";
import DateField from "../fields/DateField";
import AutocompleteInput from "../inputs/AutocompleteInput";
import { CreateButton } from "../CreateButton";
import { Link } from "react-router-dom";
import { ListWithoutFilterEmptyText, removeDefaultEmptyMessageStyle } from "../shared/ListHelpers";
import { TOGGLE_APPOINTMENTS_PAGE_ENBALED } from "../../featureFlags/flags";
import { useFlag } from "../../featureFlags";

function ShiftListFilter(props: any) {
  return (
    <Filter {...props}>
      <ReferenceInput
        alwaysOn
        source="clinicalPractitioner"
        reference="clinicalPractitioners"
      >
        <AutocompleteInput
          label="Clinical Practitioner"
          filterToQuery={query => ({
            fullName: query,
          })}
          placeholder="Search by CP name..."
          optionText="attributes.fullName"
          helperText={false}
        />
      </ReferenceInput>
      <DateInput
        label="Start"
        source="start"
        alwaysOn
        sx={{ marginBottom: "-14px" }}
      />
      <SelectInput source="status" alwaysOn choices={shiftStatusChoices} />
    </Filter>
  );
}

export function ShiftList() {
  const filterDefaultValues = {
    start: dayjs().startOf("day").format()
  };
  const appointmentsPageEnabled = useFlag(TOGGLE_APPOINTMENTS_PAGE_ENBALED);

  return (
    <List
      filters={<ShiftListFilter />}
      filterDefaultValues={{ignoreWithoutFilters: true}}
      actions={
        <ShiftListActions
          additionalActions={
            <>
              <ResetListFilterButton
                defaultFilterValues={filterDefaultValues}
              />
              <QuinyxButton />
              <ImportButton />
              {!appointmentsPageEnabled && <CreateButton
                resource={"adminAppointments"}
                sx={{ margin: "0 8px" }}
                initialValues={{}}
                component={Link}
                to="/adminAppointments/create"
                variant="contained"
                size="medium"
                label="Create appointment"
              />}
            </>
          }
          createLabel="Create Shift"
        />
      }
      sx={{
        "& .RaList-content": {
          mt: 2.5,
        },
        ...removeDefaultEmptyMessageStyle
      }}
      title="Shifts"
      sort={{ field: "start", order: "ASC" }}
    >
      <Datagrid rowClick="show" bulkActionButtons={false} empty={<ListWithoutFilterEmptyText/>}>
        <FullNameField
          sortable={false}
          label="Assigned CP"
          emptyText="n/a"
          firstNameSource="clinicalPractitioners[0].attributes.firstName"
          lastNameSource="clinicalPractitioners[0].attributes.lastName"
        />
        <DateField
          sortBy="start"
          label="Start time"
          hideLabel
          showTime
          source="attributes.start"
        />
        <DateField
          sortable={false}
          hideLabel
          label="End time"
          showTime
          source="attributes.end"
        />
        <TextField
          sortable={false}
          label="Shift source"
          source="attributes.shiftSource"
        />
        <TextField
          sortable={false}
          label="Shift status"
          source="attributes.status"
        />
      </Datagrid>
    </List>
  );
}

function ShiftListActions(props: ExtendedListActionsProps) {
  const { filterValues } = useListContext();
  const start = filterValues?.start;
  const hasValidDate = dayjs(start).isValid();
  const startDate = hasValidDate ? start : getCurrentDate();

  const initialValues = {
    start: startDate,
    end: startDate,
  };

  return <ListActions initialValues={initialValues} {...props} />;
}
