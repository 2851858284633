import { useCreate, useNotify, useRecordContext } from "react-admin";
import { styled, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { Colors } from "../../../theme";
import React from "react";

interface DownloadButtonProps {
  clientId: number;
  patientRecordId: string;
}


export function DownloadDocumentButton(props: DownloadButtonProps) {
  const record = useRecordContext();
  const notify = useNotify();
  const { clientId, patientRecordId } = props;

  const [downloadFile] = useCreate();


  const handleClick = () => {
    downloadFile(
      "downloadFile",
      {
        data: {
          id: "",
          url: `clients/${clientId}/users/${patientRecordId}/files/${record.fileId}/contents`,
          fileName: record.fileName
        }
      },
      {
        onError: () => {
          notify("There was a problem downloading your chosen file");
        }
      }
    );
  };


  return (
    <StyledDiv onClick={handleClick}>
      <WrapperStyledDiv>
        <Typography sx={typeTextStyles} variant="body1">
          Patient attachment
        </Typography>
        <Typography sx={titleTextStyles} variant="body1">
          {record.fileName}
        </Typography>

      </WrapperStyledDiv>
      {<DownloadIcon />}
    </StyledDiv>
  );
}


const StyledDiv = styled("div")(({ theme }) => ({
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  background: Colors.LighterGrey,
  width: "280px",
  padding: "6px",
  borderRadius: "10px",
  boxSizing: "border-box",
  paddingRight: theme.spacing(1),
  "&:hover": {
    background: Colors.LighterBlue,
    color: Colors.BrightBlue,
    cursor: "pointer"
  },
  "svg": {
    display: "none"
  },
  "&:hover svg": {
    display: "block"
  }
}));

const WrapperStyledDiv = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "260px"
}));


const typeTextStyles = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  marginLeft: "8px",
  fontSize: "10px",
  lineHeight: "1.5"
};

const titleTextStyles = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  marginLeft: "8px",
  fontSize: "14px",
  width: "220px",
  lineHeight: "1.5"
};
