import { GetOneParams } from "react-admin";
import { get } from "../index";
import { ClinicalProductsDTO } from "../../types/clinicalProducts";
import { splitByCapitalLetters } from "../../utilities/splitByCapitalLetters";


const includes = [
  "clients"
];

async function getClinicalProduct(args: GetOneParams) {
  const { id } = args;

  const endpoint = `/ms/clinicalProducts/${id}`;

  const params = {
    include: includes.join(",")
  };

  const res = await get(endpoint, params);

  const data = Array.isArray(res.data)
    ? res.data[0]
    : res.data;

  const formatted = getFormattedClinicalProduct(data);

  return { id: data.id, data: formatted };
}

function getFormattedClinicalProduct(data: ClinicalProductsDTO["data"]) {
  const services = data.attributes.clinicalProductServices.map(clinicalProductService => {
    if (clinicalProductService.specialty) {
      return {
        id: clinicalProductService.specialty,
        name: splitByCapitalLetters(clinicalProductService.specialty),
        maxUsageCount: clinicalProductService.maxUsageCount,
        group: "Specialties"
      }
    }
    else (clinicalProductService.clinicalServiceId)
    {
      return {
        id: clinicalProductService.clinicalServiceId!.toString(),
        name: clinicalProductService.clinicalService.name,
        maxUsageCount: clinicalProductService.maxUsageCount,
        group: "Clinical Services"
      }
    }

  })

  return {
    ...data,
    attributes: {
      ...data.attributes,
      services
    },
    servicesIds: services.map(x => x.id)
  }
}

export default getClinicalProduct;
