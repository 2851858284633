import React from "react";
import { useRecordContext } from "react-admin";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import get from "lodash/get";
import { styled } from "@mui/material";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

interface Props {
  patientIdSource: string;
  clientIdSource: string;
  bookingIdSource: string;
}

export function BookingLinkField(props: Props) {
  const record = useRecordContext();
  const { patientIdSource, clientIdSource, bookingIdSource, ...rest } = props;

  const patientId = get(record, patientIdSource);
  const clientId = get(record, clientIdSource);
  const bookingId = get(record, bookingIdSource);

  return (
    <StyledLink
      aria-label={bookingId}
      to={`/patientRecords/${patientId}/clients/${clientId}/bookings/${bookingId}/summary`}
      {...rest}
    >
      <Typography variant="body1">{bookingId}</Typography>
    </StyledLink>
  );
}
