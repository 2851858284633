import { Box, Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { NextAvailableAppointmentType } from ".";
import { Colors } from "../../../theme";
import { NextAvailableAppointmentModal } from "./NextAvailableAppointmentModal";

interface Props {
  nextAvailableAppointments: NextAvailableAppointmentType;
  clientId: string | null;
  isLoading: boolean;
}

const wrapperStyles = {
  border: `1px solid ${Colors.Blue}`,
  color: Colors.Blue,
  padding: 2,
  marginBottom: 1,
  borderRadius: 1,
  maxWidth: "700px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const buttonStyles = {
  background: "none",
  cursor: "pointer",
  textTransform: "none",
};

const seeMoreButtonStyles = {
  textDecoration: "underline",
};

/** 
 Note: We hardcode this to GP appointment. This is fine for now, but we'll need to reconsider once we start using this for counsellors and other CP types
 */
export function NextAvailableAppointmentView(props: Props) {
  const { clientId, nextAvailableAppointments, isLoading } = props;

  const [isModalOpen, setModalOpenState] = useState(false);

  if (!clientId || (!nextAvailableAppointments?.length && isLoading)) {
    return (
      <Box sx={wrapperStyles}>
        <Typography>
          Please select a client contract to view their next available
          appointments.
        </Typography>
      </Box>
    );
  }

  if (!nextAvailableAppointments?.length) {
    return (
      <Box sx={wrapperStyles}>
        <Typography>
          There are no available appointments for this client contract.
        </Typography>
      </Box>
    );
  }

  const { start, consultationType } = nextAvailableAppointments[0];
  const formattedTime = dayjs(start).format("ddd Do MMM - HH:mma");
  const formattedAppointmentMessage = `${formattedTime} (${consultationType})`;
  const text = `Next available appointment: ${formattedAppointmentMessage}`;

  return (
    <>
      <NextAvailableAppointmentModal
        appointments={nextAvailableAppointments}
        open={isModalOpen}
        onClose={() => setModalOpenState(false)}
      />
      <Button
        sx={[wrapperStyles, buttonStyles]}
        aria-label="See more available appointments"
        onClick={() => {
          setModalOpenState(true);
        }}
      >
        <Typography>{text}</Typography>
        <Typography sx={seeMoreButtonStyles}>See more</Typography>
      </Button>
    </>
  );
}
