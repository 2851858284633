import { ResourceList } from "./ResourceList";
import { ResourceCreate } from "./ResourceCreate";
import { ResourceShow } from "./ResourceShow";
import { ResourceEdit } from "./ResourceEdit";
import ResourceIcon from "@mui/icons-material/SupervisorAccount";

export const resourceManagementResources = {
  name: "resourceManagement",
  options: { label: "User Management" },
  list: ResourceList,
  icon: ResourceIcon,
  create: ResourceCreate,
  edit: ResourceEdit,
  show: ResourceShow,
};
