import React from "react";
import { Box, Typography, styled } from "@mui/material";
import { Labeled } from "ra-ui-materialui";
import { PharmacyVerificationIconField } from "./PharmacyVerificationIconField";
import { Pharmacy } from "../../types/patients";
import { EMPTY_TEXT } from "../../constants/constants";
import recursiveIsObjectEmpty from "../../utilities/recursiveIsObjectEmpty";

const addressWrapperStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
};

const VerifiedAddressDiv = styled("div")({
  display: "flex", 
  flexDirection: "row", 
  gap: "10px" 
});

interface VerifiedPharmacyAddressProps {
  label: string;
  pharmacy: Pharmacy | null | undefined;
  isVerifiedPharmacy: boolean;
}

export function VerifiedPharmacyAddressField(props: VerifiedPharmacyAddressProps) {
  const { pharmacy, label } = props;

  if (!pharmacy || recursiveIsObjectEmpty(pharmacy)) {
    return (
      <VerifiedAddressDiv data-testid="selected-pharmacy-not-provided">
        <Labeled label={label}>
          <Typography>{EMPTY_TEXT}</Typography>
        </Labeled>
      </VerifiedAddressDiv>
    );
  }

  return (
    <VerifiedAddressDiv data-testid="selected-pharmacy-provided">
      <Labeled label={label}>
        <Box sx={addressWrapperStyles}>
          <TypographyIfExists displayItem={pharmacy.name} />
          <TypographyIfExists displayItem={pharmacy.address.addressLine1} />
          <TypographyIfExists displayItem={pharmacy.address.addressLine2} />
          <TypographyIfExists displayItem={pharmacy.address.city} />
          <TypographyIfExists displayItem={pharmacy.address.county} />
          <TypographyIfExists displayItem={pharmacy.address.countryCode} />
        </Box>
      </Labeled>
      <PharmacyVerificationIconField isPharmacyVerified={props.isVerifiedPharmacy} />
    </VerifiedAddressDiv>
  );
}

interface TypographyIfExistsProps {
  displayItem: string | null | undefined;
}

function TypographyIfExists(props: TypographyIfExistsProps) {
  if (props.displayItem) {
    return <Typography>{props.displayItem}</Typography>;
  }

  return null;
}