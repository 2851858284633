import React from "react";
import { Button, useRecordContext } from "react-admin";
import { Link } from "react-router-dom";
import TooltipAdornment from "../booking/TooltipAdornment";
import { Tooltip } from "@mui/material";
import {
  clientDiscardedBookingDisabledMessage,
  patientNotVerifiedBookingDisabledMessage
} from "../../constants/tooltipMessages";
import { mediumButtonAdornmentStyles, smallButtonAdornmentStyles } from "../../theme";
import { CLIENT_DISCARDED_ID } from "../../constants/constants";

interface Props {
  size?: "small" | "medium";
  onClick?: () => void;
}

interface BookingCreateProps {
  disabled?: boolean;
}

export function CreateBookingButton(props: Props) {
  const { size = "medium", onClick = () => null } = props;

  const record = useRecordContext();

  if (!record) return null;

  const { attributes, clients } = record;
  const { firstName, lastName, identityVerificationStatus, isDependant } = attributes;

  const client = clients && clients[0];

  const dependantPartial = {
    userId: record.users ? record.users[0].id : "",
    dependantId: record.patientId,
  };

  const dependant = isDependant ? dependantPartial : {};
  const patientName = `${firstName} ${lastName}`;

  const isKycEnabled = client?.attributes?.isKycEnabled ?? false;
  const isPatientVerified = identityVerificationStatus === "Verified";

  const isClientDisabled = client?.attributes?.clientStatusID === CLIENT_DISCARDED_ID;

  const disabledReasonMessage = isClientDisabled ?
    clientDiscardedBookingDisabledMessage :
    patientNotVerifiedBookingDisabledMessage;

  const clientIdentifierRequired = client?.attributes?.clientIdentifierRequired ?? false;

  const partialRecord = {
    userId: record.patientId,
    clients,
    address: record.attributes.address,
    clientIdentifier: clientIdentifierRequired ? record.attributes.clientIdentifier : undefined,
    employerId: record.attributes.employerId?.toString(), // toString() is a workaround. was fixed in 'react-admin' v4.6.2 https://github.com/marmelab/react-admin/issues/8499
    phoneNumber: record.attributes.phoneNumber,
    secondaryPhoneNumber: record.attributes.secondaryPhoneNumber,
    email: record.attributes.email,
    allergies: record.attributes.allergies,
    showGpDetails: Boolean(record.attributes.gp),
    medicalHistory: record.attributes.medicalHistory,
    medications: record.attributes.medications,
    // This prevents a weird issue where the address labels don't update after selecting
    // an address from the dropdown
    gp: record.attributes.gp ?? undefined,
    nominatedPharmacy: record.attributes.nominatedPharmacy ?? undefined,
    showPharmacyDetails: Boolean(record.attributes.nominatedPharmacy),
    selectedDate: new Date().setHours(0, 0, 0, 0),
    patientName,
    notes: record.notes,
    ignoreOpeningTimes: true,
    ...dependant,
  };

  const BookingButton = (bookingProps: BookingCreateProps) => <Button
    style={{ margin: "0 8px" }}
    component={Link}
    size={size}
    onClick={onClick}
    variant="contained"
    to={{
      pathname: `/patientRecords/${record.patientId}/bookings/create`,
      state: {
        record: partialRecord,
      },
    }}
    label="Create Booking"
    disabled={bookingProps.disabled}
  />

  const tooltipAlignment = size == "small"
    ? smallButtonAdornmentStyles
    : mediumButtonAdornmentStyles

  if (!isClientDisabled && (!isKycEnabled || isKycEnabled && isPatientVerified)) {
    return <BookingButton />
  }

  return (
    <div style={{ display: "flex" }}>
      <Tooltip title={disabledReasonMessage}>
        <span>
          <BookingButton disabled={true} />
        </span>
      </Tooltip>
      <TooltipAdornment
        sx={tooltipAlignment}
        text={disabledReasonMessage}
      />
    </div>
  );
}
