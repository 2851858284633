import { useDataProvider } from "ra-core";
import { useCallback, useEffect, useRef, useState } from "react";
import AdminHub from "../sockets/AdminHub";
import { PublishEventMap, PublishEvent } from "../types/realTime";

export function usePublish() {
  const dataProvider = useDataProvider();
  const currentState = AdminHub.getInstance().getConnectionState();
  const [, forceUpdate] = useState(0);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (currentState === "Connecting") {
      timeoutRef.current = setTimeout(() => {
        forceUpdate(x => x + 1);
      }, 2000);
    }

    if (currentState !== "Connected") return;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  });

  const publish = useCallback(
    <Type extends keyof PublishEventMap>(
      topic: Type,
      event: PublishEvent<Type>
    ) => {
      if (currentState !== "Connected") return;

      return dataProvider.publish(topic, event);
    },
    [dataProvider, currentState]
  );

  return publish;
}
