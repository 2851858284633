import React from "react";
import { Button } from "@mui/material";
import {
  useCreate,
  useNotify,
  useRefresh,
  useChoicesContext,
  useRecordContext,
} from "react-admin";
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';

const buttonStyles = {
  alignSelf: "flex-end",
  marginBottom: 1,
};

export function AssignButton() {
  const record = useRecordContext();
  const { isAssigned, isCompleted } = record!.attributes;
  const choices = useChoicesContext();
  const [assignee] = choices.selectedChoices;

  const notify = useNotify();
  const refresh = useRefresh();

  const [assignItem] = useCreate();

  const handleClick = () => {
    assignItem(
      "assignWorkItem",
      {
        data: {
          id: record?.id,
          assignee: assignee?.id,
        },
      },
      {
        onSuccess: () => {
          notify("This work item has been successfully assigned");
          refresh();
        },
        onError: () => {
          notify("There was a problem assigning the work item");
        },
      }
    );
  };

  const [unassignItem] = useCreate(
    "unassignWorkItem",
    {
      data: {
        id: record?.id,
      },
    },
    {
      onSuccess: () => {
        notify("This work item's user has been unassigned");
        refresh();
      },
      onError: () => {
        notify("There was a problem unassigning the work item");
      },
    }
  );

  if (isCompleted) {
    return null;
  }

  if (isAssigned) {
    return (
    <Button
      color="primary"
      aria-label="Unassign"
      variant="contained"
      startIcon={<PersonOffIcon />}
      onClick={e => {
        e.stopPropagation();
        return unassignItem();
      }}
      sx={buttonStyles}
    >
      Unassign
    </Button>
  )}

  return (
    <Button
      disabled={!assignee}
      color="primary"
      aria-label="Assign"
      variant="contained"
      onClick={handleClick}
      startIcon={<PersonIcon />}
      sx={buttonStyles}
    >
      Assign
    </Button>
  );
}
