import { PaginationPayload } from "react-admin";
import { list } from "../";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";
import isObjectEmpty from "../../utilities/isObjectEmpty";
import { PatientRecordDTO } from "../../types/patients";

type ExtendedPatient = PatientRecordDTO["data"] & {
  patientId: string;
};

async function listPatientRecords(params: {
  filter: any;
  pagination: PaginationPayload;
}) {
  const { filter, pagination } = params;
  const { page, perPage } = pagination;

  const {
    firstName,
    lastName,
    email,
    dateOfBirth,
    bookingId,
    userId,
    clients,
  } = filter;

  const filters = {
    "filter[firstName]": firstName ? `like:${firstName}` : "",
    "filter[lastName]": lastName ? `like:${lastName}` : "",
    "filter[email]": email ? `like:${email}` : "",
    "filter[dateOfBirth]": dateOfBirth,
    "filter[bookings.id]": bookingId,
    "filter[id]": userId,
    "filter[client.id]": clients,
  };

  const filterParams = shallowlyRemoveEmptyValues(filters);
  const hasNoFilter = isObjectEmpty(filterParams);

  if (hasNoFilter) {
    return {
      data: [],
      total: 0,
    };
  }

  const pageParams = {
    "page[number]": page,
    "page[size]": perPage,
  };

  const queryParams = {
    include: "client,client.clientBookingChannels,dependantOf",
    ...pageParams,
    ...filterParams,
  };

  const { data, total } = await list("/ms/patientRecords", {
    params: queryParams,
  });

  const updatedData = data.map((record: ExtendedPatient) => {
    return {
      ...record,
      patientId: record.id,
    };
  });

  return {
    data: updatedData,
    total,
  };
}

export default listPatientRecords;
