import recursiveIsObjectEmpty from "../utilities/recursiveIsObjectEmpty";

type ErrorMessage = string;
interface PharmacyValidation {
  [property: string]: ErrorMessage;
}
function validatePharmacyAddress(values: any) {
  const errors: PharmacyValidation = {};

  if (!values) return;

  const { nominatedPharmacy = {} } = values;

  const shouldValidatePharmacyAddress =
    !recursiveIsObjectEmpty(nominatedPharmacy);

  if (shouldValidatePharmacyAddress) {
    if (!values.nominatedPharmacy?.name) {
      errors["nominatedPharmacy.name"] = "The pharmacy name is required";
    }

    if (!values.nominatedPharmacy?.address?.addressLine1) {
      errors["nominatedPharmacy.address.addressLine1"] =
        "The pharmacy's first line of address is required";
    }

    if (!values.nominatedPharmacy?.address?.city) {
      errors["nominatedPharmacy.address.city"] =
        "The pharmacy's city is required";
    }

    if (!values.nominatedPharmacy?.address?.postCode) {
      errors["nominatedPharmacy.address.postCode"] =
        "The pharmacy's post code is required";
    }

    if (!values.nominatedPharmacy?.address?.countryCode) {
      errors["nominatedPharmacy.address.countryCode"] =
        "The pharmacy's country of residence is required";
    }
  }

  return errors;
}

export default validatePharmacyAddress;
