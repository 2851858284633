import { create, remove, update } from "..";
import { ClientInstructionsDTO } from "../../types/clients";
import deepEquals from "../../utilities/deepEquals";

type Args = {
  clientId: string;
  instructions: ClientInstructionsDTO["data"];
  oldInstructions: ClientInstructionsDTO["data"];
};

async function updateInstructions(args: Args) {
  const { clientId, instructions, oldInstructions } = args;
  const instructionRequests: Promise<any>[] = [];
  const baseEndpoint = `/ms/clients/${clientId}/clientinstructions`;

  const updatedIds = instructions.map(({ id }) => id);
  const oldIds = oldInstructions.map(({ id }) => id);

  const toUpdate = oldIds.filter(id => updatedIds.includes(id));

  /* UPDATE */
  toUpdate.forEach(toUpdateId => {
    const instruction = instructions.find(({ id }) => id === toUpdateId)!;
    const oldInstruction = oldInstructions.find(({ id }) => id === toUpdateId)!;

    const noChange = deepEquals(instruction, oldInstruction);
    if (noChange) return;

    const updateContractsEndpoint = `${baseEndpoint}/${toUpdateId}`;
    const instructionBody: ClientInstructionsDTO["data"][0] = {
      type: "clientInstructions",
      id: toUpdateId,
      attributes: instruction.attributes,
    };

    const instructionRequest = update(updateContractsEndpoint, {
      data: instructionBody,
    });
    instructionRequests.push(instructionRequest);
  });

  /* DELETE */
  const toDelete = oldIds.filter(id => !updatedIds.includes(id));
  const deleteRequests = toDelete.map(id => {
    const deleteInstructionsEndpoint = `${baseEndpoint}/${id}`;
    return remove(deleteInstructionsEndpoint);
  });

  /* CREATE */
  const toCreate = instructions.filter(({ id }) => !id);
  toCreate.forEach(({ attributes }) => {
    const instructionsBody = {
      data: {
        type: "clientInstructions",
        attributes,
        relationships: {
          client: {
            data: {
              id: clientId,
              type: "clients",
            },
          },
        },
      },
    };

    const instructionRequest = create(baseEndpoint, instructionsBody);
    instructionRequests.push(instructionRequest);
  });

  try {
    await Promise.all([...instructionRequests, ...deleteRequests]);
  } catch (err) {
    console.error(err);
  }
}

export default updateInstructions;
