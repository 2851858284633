import * as React from "react";
import { ReactNode } from "react";

import {
  ListContextProvider,
  ListControllerProps,
  useListController,
} from "react-admin";
import { defaultSchedulerFilters } from "../../context/schedulerFilter";

export const ListBase = ({
  children,
}: ListControllerProps & { children: ReactNode }) => {
  const value = useListController({
    filterDefaultValues: defaultSchedulerFilters,
    resource: "schedule",
  });

  return <ListContextProvider value={value}>{children}</ListContextProvider>;
};
