import { UpdateParams } from "ra-core";
import dayjs from "dayjs";
import { update } from "..";
import recursiveIsObjectEmpty from "../../utilities/recursiveIsObjectEmpty";
import getPatientRecord from "./getPatientRecord";
import updatePatientNotes from "./updatePatientNotes";
import { Body } from "./updatePatientRecord";

async function updateDependantRecord({ data }: UpdateParams<Body>) {
  const { users, patientId, attributes } = data as Body;
  const clientId = users![0].attributes.clientId!;
  const userId = users![0].id;
  const dependantId = patientId;

  const requestBody = {
    id: dependantId,
    type: "patients",
    attributes,
  };

  const updateRecordEndpoint = `/clients/${clientId}/users/${userId}/dependants/${dependantId}`;

  const gpIsEmpty = recursiveIsObjectEmpty(requestBody.attributes.gp);
  const pharmacyIsEmpty = recursiveIsObjectEmpty(
    requestBody.attributes.nominatedPharmacy
  );

  if (gpIsEmpty) {
    requestBody.attributes.gp = null;
  }

  if (pharmacyIsEmpty) {
    requestBody.attributes.nominatedPharmacy = null;
  }

  delete requestBody.attributes.createdAt;
  delete requestBody.attributes.updatedAt;
  delete requestBody.attributes.patientReference;
  delete requestBody.attributes.clientIdentifier;

  const { dateOfBirth } = requestBody.attributes;
  const formattedDateOfBirth = dayjs(dateOfBirth).format("YYYY-MM-DD");

  if (dateOfBirth) {
    requestBody.attributes.dateOfBirth = formattedDateOfBirth;
  } else {
    delete requestBody.attributes.dateOfBirth;
  }

  await update(updateRecordEndpoint, {
    data: requestBody,
  });

  await updatePatientNotes(data as Body);

  return getPatientRecord({ id: data.id });
}

export default updateDependantRecord;
