import get from "lodash/get";
import React from "react";
import {
  ArrayField,
  Datagrid,
  FieldProps,
  Labeled,
  ReferenceField,
  useRecordContext,
  TextField,
} from "react-admin";
import { NONE_SPECIFIED } from "../../constants/constants";
import { UserDTO } from "../../types/users";
import { AddDependantButton } from "./AddDependantButton";
import { LinkWithGuardianButton } from "./LinkWithGuardianButton";

const dataGridStyles = {
  width: "100%",
  "& thead, & tbody": {
    "& th, & td": {
      minWidth: "375px",
      "&:last-of-type": {
        width: "100%",
      },
    },
  },
};

export function DependantFields(props: FieldProps) {
  const record = useRecordContext();
  const { isDependant } = record.attributes!;

  if (isDependant) {
    return (
      <Labeled label="Dependant of">
        <ReferenceField
          {...props}
          label="Dependant of"
          source="relationships.dependantOf.data.id"
          reference="patientRecords"
          aria-label="Dependant of"
          link={() => {
            const guardianId = get(record, "relationships.dependantOf.data.id");
            const guardianRecord = record.users.find(
              (x: UserDTO["data"]) => x.id === guardianId
            );

            const guardianClientId = guardianRecord.attributes.clientId;
            const id = `${guardianId}:${guardianClientId}`;
            return `/patientRecords/${id}/general`;
          }}
        >
          <TextField {...props} source="attributes.email" emptyText="-" />
        </ReferenceField>
      </Labeled>
    );
  }

  return (
    <>
      <ArrayField {...props} source="patients">
        <Datagrid
          bulkActionButtons={false}
          rowClick={(depId, basePath, depRecord) => {
            const clientId = get(depRecord, "users[0].attributes.clientId");
            const id = `${depId}:${clientId}`;

            return `/patientRecords/${id}/general`;
          }}
          sx={dataGridStyles}
        >
          <TextField
            sortable={false}
            label="First name"
            source="attributes.firstName"
          />
          <TextField
            sortable={false}
            label="Last name"
            source="attributes.lastName"
          />
          <TextField
            sortable={false}
            label="Email"
            source="attributes.email"
            emptyText={NONE_SPECIFIED}
          />
        </Datagrid>
      </ArrayField>
      <AddDependantButton />
      <LinkWithGuardianButton />
    </>
  );
}
