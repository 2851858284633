import { Box, Typography } from "@mui/material";
import get from "lodash/get";
import { useRecordContext } from "ra-core";
import { Labeled, SelectInput, sanitizeFieldRestProps } from "ra-ui-materialui";
import React, { useState } from "react";
import { FormDataConsumer } from "react-admin";
import {
  consultationOutputChoices,
  consultationOutputAppendableChoices, consultationOutputTagChoices,
} from "../../../../constants/constants";
import { Colors } from "../../../../theme";
import { EmptyLabeledText } from "../../../fields/EmptyLabelledTextField";
import { FileIcon } from "../../../fields/FileIcon";
import { FileImageField } from "../../../fields/FileImageField";
import { FormIteratorAddButton } from "../../../form/FormIteratorAddButton";
import { FormIteratorRemoveButton } from "../../../form/FormIteratorRemoveButton";
import { SimpleFormIterator } from "../../../form/SimpleFormIterator";
import { ArrayInput } from "../../../inputs/ArrayInput";
import { FileInput } from "../../../inputs/FileInput";
import { FileFormatTruncatedText } from "../../../ui/FIleFormatTruncatedText";
import { ConsultationOutputsList } from "../../ConsultationOutputsList";

const fileInputWrapperStyles = {
  display: "flex",
  alignItems: "center",
  background: Colors.LightestGrey,
  width: "200px",
  padding: 1,
  borderRadius: "4px",
  boxSizing: "border-box",
  paddingRight: 2,
  border: `1px solid ${Colors.BrightBlue}`,
};

const outputWrapperStyles = {
  display: "flex",
  alignItems: "center",
  gap: 1,
  ["& > div"]: {
    minWidth: "170px",
  },
};

const arrayInputStyles = {
  "& .RaSimpleFormIterator-line": {
    margin: `8px 0`,
    borderBottom: "none",
    marginBottom: "0",
    backgroundColor: Colors.LightestGrey,
    padding: `5px 12px`,
    "& .RaSimpleFormIterator-action": {
      paddingTop: 0,
    },
  },
};

const pdfInputType = "application/pdf";
const wordDocumentInputType =
  "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";

export function ConsultationOutputsEdit() {
  const record = useRecordContext();

  const status = get(record, "attributes.status");
  const isConsultFinished =
    status === "Completed" || status === "PendingFollowUpActions";

  if (!isConsultFinished) {
    return (
      <EmptyLabeledText label="Consultation Outputs" text="No Documents" />
    );
  }

  return (
    <>
      <ExistingConsultationOutputs />
      <AddFollowUpOutputs />
    </>
  );
}

function OutputUpload(props: any) {
  const { title, getSource } = props;
  const [selectedOutputType, setSelectedOutputType] = useState(null);
  if (!title) {
    return (
      <Box sx={outputWrapperStyles}>
        <SelectInput
          choices={consultationOutputAppendableChoices}
          source={getSource("attributes.followUpActionType")}
          label="Output Type"
          helperText=""
          onChange={e => setSelectedOutputType(e.target.value)}
        />
        {selectedOutputType === "TreatmentDocument" ?
          <SelectInput
          choices={consultationOutputTagChoices}
          source={getSource("attributes.tag")}
          label="Tag"
          helperText=""
         /> : null
        }
        <FileInput
          source={getSource("file")}
          accept={
            selectedOutputType === "TreatmentDocument"
              ? `${wordDocumentInputType},${pdfInputType}`
              : pdfInputType
          }
          label={false}
          helperText=""
          sx={{
            "& .RaFileInput-dropZone": {
              padding: 1.5,
              margin: 0,
            },
          }}
        >
          <FileImageField />
        </FileInput>
      </Box>
    );
  }

  return (
    <Box sx={outputWrapperStyles}>
      <SelectInput
        choices={consultationOutputAppendableChoices}
        source={getSource("attributes.followUpActionType")}
        label="Output Type"
        helperText=""
        onChange={e => setSelectedOutputType(e.target.value)}
      />
      {selectedOutputType === "TreatmentDocument" ?
        <SelectInput
          choices={consultationOutputTagChoices}
          source={getSource("attributes.tag")}
          label="Tag"
          helperText=""
        /> : null
      }
      <Box sx={fileInputWrapperStyles}>
        <FileIcon titleSource={title} imgSource={title} />
        <FileFormatTruncatedText title={title} />
      </Box>
    </Box>
  );
}

function AddFollowUpOutputs() {
  return (
    <>
      <ArrayInput source="followUpOutputs" sx={arrayInputStyles}>
        <SimpleFormIterator
          addButton={<FormIteratorAddButton label="Add Consultation Output" />}
          removeButton={<FormIteratorRemoveButton label="Remove" />}
        >
          <FormDataConsumer>
            {({ formData, getSource }) => {
              if (!getSource) return null;
              const getTitleSource = getSource("file.title");
              const title = get(formData, getTitleSource);

              return (<OutputUpload title={title} getSource={getSource}></OutputUpload>);
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="followUpOutputs">
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const existingOutputs = get(
              formData,
              "consultations[0].consultationOutputs",
              []
            );
            if (!existingOutputs.length) return null;

            return (
              <Typography
                component="div"
                variant="body1"
                {...sanitizeFieldRestProps(rest)}
              >
                Note: Uploading an output will overwrite any existing outputs of
                the same type except Treatment Document type
              </Typography>
            );
          }}
        </FormDataConsumer>
      </ArrayInput>
    </>
  );
}

function ExistingConsultationOutputs() {
  const record = useRecordContext();

  const consultationsOutputs = get(
    record,
    "consultations[0].consultationOutputs"
  );

  if (!consultationsOutputs) {
    return (
      <EmptyLabeledText label="Consultation Outputs" text="No Documents" />
    );
  }

  return (
    <Labeled label="Consultation Outputs">
      <ArrayInput
        source="consultations[0].consultationOutputs"
        sx={{ marginBottom: "0", ...arrayInputStyles }}
      >
        <SimpleFormIterator
          disableAdd
          disableRemove={rec => rec.attributes.resourceType === "Link"}
        >
          <FormDataConsumer>
            {({ getSource }) => {
              if (!getSource) return null;
              const path = getSource("attributes.resourceType");
              if (get(record, path) === "Link") {
                return null;
              }

              const outputType = getSource("attributes.type");
              const outputTypeValue = get(record, outputType);

              return (
                <Box sx={outputWrapperStyles}>
                  <SelectInput
                    choices={consultationOutputChoices}
                    source={outputType}
                    label="Output Type"
                    disabled
                    helperText=""
                  />
                  {outputTypeValue === "TreatmentDocument" ?
                    <SelectInput
                      choices={consultationOutputTagChoices}
                      source={getSource("attributes.tag")}
                      label="Tag"
                      helperText=""
                    /> : null
                  }
                  <FileImageField
                    key={getSource("attributes.description")}
                    titleSource={getSource("attributes.description")}
                    label="Attachment"
                  />
                </Box>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Labeled>
  );
}

export function ConsultationOutputsShow() {
  const record = useRecordContext();

  const consultationOutputs = get(
    record,
    "consultations[0].consultationOutputs"
  );

  if (!consultationOutputs) {
    return (
      <EmptyLabeledText label="Consultation Outputs" text="No Documents" />
    );
  }

  return (
    <ConsultationOutputsList
      label="Consultation Outputs"
      source="consultations[0].consultationOutputs"
      fields={[{ label: "Type", titleSource: "attributes.type", nameSource: "attributes.description", resourceTypeSource: "attributes.resourceType" }]}
    />
  );
}
