import React from "react";
import { useRecordContext } from "ra-core";
import { ImageField, TextFieldProps } from "ra-ui-materialui";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Colors } from "../../theme";
import { styled } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";

interface Props extends TextFieldProps {
  titleSource: string;
  imgSource: string;
}

const StyledDiv = styled("div")(({ theme }) => ({
  height: theme.spacing(4),
  minWidth: theme.spacing(4),
  width: theme.spacing(4),
  display: "flex",
  justifyContent: "center",
  color: Colors.BrightBlue,
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
}));

const imageStyles = {
  objectFit: "cover",
  display: "flex",
  margin: 0,
  height: "32px",
};

export const FileIcon = (props: Props) => {
  const record = useRecordContext(props);
  const type = record?.rawFile?.type ?? "none";
  const isImage = type.includes("image");

  if (isImage) {
    return (
      <StyledDiv>
        <ImageField {...props} source={props.imgSource} sx={imageStyles} />
      </StyledDiv>
    );
  }

  return (
    <StyledDiv>
      <AttachFileIcon />
    </StyledDiv>
  );
};

export const LinkIcon = () => {
  return (
    <StyledDiv>
      <LanguageIcon />
    </StyledDiv>
  );
};
