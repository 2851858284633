import { create, getBaseUrl } from "../";
import {
  CreateAppointmentHoldBody,
  AppointmentStatus,
} from "../../types/appointments";
import { getAdminAppointment, makeAppointmentAvailable } from "../appointments";
import { BookingDTO } from "../../types/bookings";
import { Address } from "../../types/common";
import { ConsultationType } from "../../types/consultations";
import { NewDTO } from "../../types/utilities";
import getValidationHeaders from "../helpers/getValidationHeaders";
import { GP, Pharmacy } from "../../types/patients";
import uploadAttachment from "./uploadBookingAttachment";
import updatePatientClinicalDetails from "../patientRecords/updatePatientClinicalDetails";
import validateVNetReference from "../patientManagementSystems/validateVNetReference";


interface Body {
  enableIntakeCreation?: boolean;
  vnetIntakeReference?: string;
  vnetCaseReference?: string;
  clients: { id: string }[];
  email: string;
  userId: string;
  gp: GP;
  nominatedPharmacy: Pharmacy;
  consultationType: ConsultationType;
  appointmentId: string;
  appointmentStartingStatus: AppointmentStatus;
  newAttachments: {
    rawFile: File;
  }[];
  validationOverride?: boolean;
  address: Address;
  reasonForBooking: string;
  phoneNumber: string;
  secondaryPhoneNumber?: string;
}

function generateAppointmentHoldBody(details: any): CreateAppointmentHoldBody {
  return {
    type: "appointmentHolds",
    attributes: {
      userId: details.userId,
    },
  };
}

function generateBookingRequestBody(details: any): NewDTO<BookingDTO["data"]> {
  const { dependantId, employerId } = details;

  const dependantRelationship = {
    dependant: {
      data: {
        type: "patients",
        id: details.dependantId,
      },
    },
  };

  const employerRelationship = {
    employer: {
      data: {
        type: "employers",
        id: employerId,
      },
    },
  };

  const employer = employerId ? employerRelationship : {};

  const dependant = dependantId ? dependantRelationship : {};

  const clientAttributes = calculateClientAttributes(details);

  return {
    type: "bookings",
    attributes: {
      reasonForBooking: details.reasonForBooking,
      consultationType: details.consultationType,
      comments: details.comments,
      clientIdentifier: details.clientIdentifier,
      contactDetails: {
        acceptHiddenCalls: details.acceptHiddenCalls,
        phoneNumber: details.phoneNumber,
        secondaryPhoneNumber: details.secondaryPhoneNumber,
        address: {
          addressLine1: details.address.addressLine1,
          addressLine2: details.address.addressLine2,
          city: details.address.city,
          postCode: details.address.postCode,
          countryCode: details.address.countryCode,
        },
      },
      payload: {
        productId: details.productId
      },
      clientAttributes: clientAttributes
    },
    relationships: {
      ...dependant,
      appointment: {
        data: { 
          type: "appointments",
          id: details.appointmentId,
        },
      },
      ...employer,
    },
  };
}

function calculateClientAttributes(details: any){
  if (details.vnetIntakeReference && !details.enableIntakeCreation) {
    return {
      VNet: {
        intakeReference: details.vnetIntakeReference
      }
    }
  }
  
  if (details.vnetCaseReference) {
    return {
      VNet: {
        caseReference: details.vnetCaseReference
      }
    }
  }

  return undefined;
}

async function createBooking(body: Body) {
  const {
    userId,
    clients,
    appointmentId,
    appointmentStartingStatus,
    gp,
    nominatedPharmacy,
    validationOverride = false,
  } = body;

  if (body.vnetIntakeReference && !body.enableIntakeCreation) {
    await validateVNetReference({ reference: body.vnetIntakeReference, referenceType: "Intake" });
  }

  if (body.vnetCaseReference){
    await validateVNetReference({ reference: body.vnetCaseReference, referenceType: "Case" });
  }

  const clientId = clients[0].id;
    const validationHeaders = getValidationHeaders(validationOverride);

    if (appointmentStartingStatus) {
      const appointment = await getAdminAppointment({ id: appointmentId });

      if (appointment.data.attributes.status === "Nonworking") {
        await makeAppointmentAvailable({ id: appointmentId });
      }
    }

    const baseUrl = getBaseUrl({ apiVersion: "2" });
    const createBookingEndpoint = `${baseUrl}/clients/${clientId}/users/${userId}/bookings`;
    const createBookingBody = generateBookingRequestBody(body);

    const appointmentHoldEndpoint = `/clients/${clientId}/appointments/${appointmentId}/hold`;
    const appointmentHoldBody = generateAppointmentHoldBody(body);

    await create(appointmentHoldEndpoint, {
      data: appointmentHoldBody,
    });


    const bookingRes = await create(
    createBookingEndpoint,
    {
      data: createBookingBody,
    },
    {
      params: {
        include: "user,client",
      },
      headers: {
        ...validationHeaders,
      },
    }
  );

  if (body.newAttachments?.length > 0) {
    const uploadDocumentsParams = {
      clientId,
      userId,
      bookingAttachments: body.newAttachments,
      bookingId: bookingRes.data.id,
    };

    await uploadAttachment(uploadDocumentsParams);
  }

  if (gp || nominatedPharmacy) {
    const updatePatientBody = {
      userId,
      clientId,
      id: userId,
      type: "patients",
      attributes: {
        gp: gp ?? null,
        nominatedPharmacy: nominatedPharmacy ?? null,
      },
    };

    updatePatientClinicalDetails(updatePatientBody);
  }

  return bookingRes;
}

export default createBooking;
