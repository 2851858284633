import React from "react";
import { ShowTabProps } from "../../types/utilities";
import { Datagrid, useRecordContext } from "react-admin";
import get from "lodash/get";
import { Tab } from "../Tabs";
import { ArrayField, FunctionField, Labeled } from "ra-ui-materialui";
import { TextField } from "../../stories/TextField.stories";
import DateField from "../fields/DateField";
import { Typography } from "@mui/material";


export function ClinicalNotesTab(props: ShowTabProps) {
  const record = useRecordContext();
  const clinicalNotes = get(record, "clinicalNotes");

  if (!clinicalNotes || !clinicalNotes.length) {
    return (<Tab data-testid="clinical-notes-tab" {...props} label="Clinical notes">
      <Typography>
        No clinical notes found for this prescription.
      </Typography>
    </Tab>);
  }

  return (
    <Tab data-testid="clinical-notes-tab" {...props} label="Clinical notes">
        <ArrayField source="clinicalNotes">
          <Datagrid bulkActionButtons={false} expand={<ExpandedClinicalNote/>}>
            <TextField label={"Clinician"} source={"clinician"}/>
            <TextField label={"Category"} source={"category"}/>
            <Labeled label={"Condition"}>
              <FunctionField render={() => (record.condition)} label={"Condition"}/>
            </Labeled>
            <Labeled label={"Task identifier"}>
              <FunctionField render={() => (record.identifier)} label={"Task identifier"}/>
            </Labeled>
            <DateField label={"Date"} source={"date"}/>
            <TextField label={"Content preview"} source={"contentPreview"}/>
          </Datagrid>
        </ArrayField>
    </Tab>
  );
}


function ExpandedClinicalNote() {
  const record = useRecordContext();
  return (
    <div style={{whiteSpace:"pre-wrap"}}>{record.content}</div>
  )
}