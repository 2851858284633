import { get } from "../gaiaClient";
import { v4 as uuid } from "uuid";


interface Args {
  encounterId: string;
}


const getConversationsWithMessages = async (args: Args) => {
  const { encounterId } = args;
  const endpoint = `encounters/${encounterId}/conversations/messages`;

  const { data } = await get(endpoint, {
    headers: {
      "x-correlation-id": uuid(),
    }
  })

  return data;
}

export default getConversationsWithMessages;