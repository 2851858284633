import React, { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useNotify, Form, useCreate } from "react-admin";
import { AuthInput } from "../inputs/AuthInput";
import { Link } from "react-router-dom";
import { FieldValues } from "react-hook-form";
import { Colors } from "../../theme";
import { Group } from "../layout/Group";
import { Stack } from "../layout/Stack";
import { Box } from "@mui/system";
import logo from "../../static/logo-red.svg";

interface FormData {
  userName: string;
}

const containerStyles = {
  "&": {
    marginTop: "125px",
    width: "450px",
    backgroundColor: `${Colors.White}dd`,
    padding: "24px",
  },
};

const iconStyles = {
  width: "16px",
  height: "16px",
  fill: Colors.Blue,
};

const imageContainerStyles = {
  display: "flex",
  justifyContent: "start",
  width: "100%"
};

const validate = (values: FieldValues) => {
  const errors: Partial<FormData> = {};

  if (!values.userName) {
    errors.userName = "A username is required";
  }

  return errors;
};

export function ResetPasswordForm() {
  const notify = useNotify();

  const [isReset, setResetState] = useState(false);

  const [resetPassword, { isLoading }] = useCreate();

  const submit = (data: FieldValues) => {
    resetPassword(
      "resetPassword",
      {
        data,
      },
      {
        onSuccess: () => {
          setResetState(true);
        },
        onError: () => {
          notify(
            "We had trouble resetting your password. Please make sure you entered your username correctly"
          );
        },
      }
    );
  };

  if (isReset) {
    return (
      <Group sx={containerStyles}>
        <Typography sx={{ margin: 0 }} variant="body1">
          Your password has been reset, please follow the instructions sent to
          your inbox
        </Typography>
      </Group>
    );
  }

  return (
    <Form onSubmit={submit} validate={validate} mode="onBlur">
      <Group sx={containerStyles}>
        <Stack sx={{ alignItems: "center", textAlign: "center" }}>
          <Box sx={imageContainerStyles}>
            <img src={logo} alt="Logo" height="100" />
          </Box>
          <Box sx={{ width: '100%', marginTop: 0 }}>
            <Typography sx={{ marginTop: 0, fontSize: '20px', fontWeight: 600, textAlign: 'left' }} variant="h1">
              Reset your new password
            </Typography>
          </Box>

          <Box sx={{ width: '100%' }}>
            <AuthInput
              name="userName"
              label="Username"
              disabled={isLoading}
              startAdornment={<PersonOutlineOutlinedIcon sx={iconStyles} />}
            />
          </Box>
          <Box>
            <Typography variant="body1">
              Please enter your username, and we'll send next steps to your
              email inbox
            </Typography>
          </Box>

          <Box sx={{ width: '100%' }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={isLoading}
              fullWidth
            >
              {isLoading && <CircularProgress />}
              Reset Password
            </Button>
          </Box>
          <Box>
            <Button
              component={Link}
              to="/login"
              type="submit"
              color="primary"
              size="small"
              disabled={isLoading}
            >
              Back to Sign In
            </Button>
          </Box>
        </Stack>
      </Group>
    </Form>
  );
}
