import { GetListParams } from "ra-core";
import { list } from "../index";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";
import { FeatureFlagService } from "../../featureFlags";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS } from "../../featureFlags/flags";



async function listClinicalProducts(params: GetListParams) {
  const clientClinicalProductsEnabledFlag = FeatureFlagService.getInstance().getFlag(
    TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS
  );

  const { pagination, filter, meta } = params;
  const { page, perPage } = pagination;
  const { name, code, isEnabled, clientId, availableForClient } = filter;

  const endpoint = clientClinicalProductsEnabledFlag ?
    `/ms/clients/${clientId}/clinicalProducts` :
    "/ms/clinicalProducts";


  const filters = {
    "filter[name]": name ? `like:${name}` : "",
    "filter[code]": code ? `like:${code}` : "",
    "filter[isEnabled]": isEnabled,
    "filter[clients.id]": clientId,
    "availableForClient": availableForClient,
  };

  const filterParams = shallowlyRemoveEmptyValues(filters);


  const query = {
    "page[number]": page,
    "page[size]": perPage,
    "include": meta?.includes?.join(','),
    ...filterParams
  }


  const { data, total } = await list(endpoint, {
    params: query
  });


  return { data, total, pageInfo: { hasNextPage: perPage * page < total, hasPreviousPage: page !== 1 } }
}

export default listClinicalProducts;
