import React from "react";
import { ArrayField, FieldProps, Labeled } from "ra-ui-materialui";
import { SimpleIteratorLayout } from "./SimpleIteratorLayout";
import { DownloadButtonV2 } from "../buttons/DownloadButton";

type DownloadProps = {
  label: string;
  nameSource: string;
  titleValue?: string;
  titleSource?: string;
  source: string;
  hideLabel?: string;
  downloadSources: {
    clientId?: string;
    fileId: string;
    userId?: string;
    fileName: string;
    clinicalPractitionerId?: string;
  };
};

type Props = FieldProps & DownloadProps ;

export function DownloadsFieldV2(props: Props) {
  const {
    label,
    hideLabel,
    source,
  } = props;

  const displayedLabel = hideLabel ? false : label;

  return (
    <Labeled label={displayedLabel} className="two">
      <ArrayField
        label={false}
        source={source}
        record={props.record}
        className="helo"
      >
        <SimpleIteratorLayout>
          <DownloadButtonV2 {...props} />
        </SimpleIteratorLayout>
      </ArrayField>
    </Labeled>
  );
}



