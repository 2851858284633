
import React from "react";
import { Grid } from "@mui/material";
import { FormDataConsumer } from "ra-core";
import { FunctionField, Labeled } from "ra-ui-materialui";
import DateField from "../../fields/DateField";
import { Group } from "../../layout/Group";
import { Heading } from "../../ui/Heading";
import { BookingLink } from "../BookingLink";
import { MessageADoctorLink } from "../MessageADoctorLink";
import get from "lodash/get";
import { renderPmsCaseNumber, renderPmsCaseReference } from "../../../utilities/renderPmsCaseAttribute";
import { TextField, useRecordContext } from "react-admin";
import { TextArrayField } from "../../fields/TextArrayField";
import { WorkQueueItemType } from "../../../types/workQueues";


const displayBookingDetailsTypes: WorkQueueItemType[] = [
  "AllocationRequired",
  "ManualInterventionRequired"
]

export function BookingGroup() {
  const record = useRecordContext();

  let displayBookingDetails = displayBookingDetailsTypes.includes(record.attributes.itemType);
  let prefix = "bookings[0]";
  if (record.messages && record.messages.length) {
    prefix = "messages[0]";
    displayBookingDetails = false;
  }

  
  return (
    <Group 
      heading={<Heading level={2}>Booking</Heading>} 
      data-testid="booking-group"
    >
      <Grid item xs={12} sm={6}>
        <FormDataConsumer>
          {({ formData }) => {
            const itemType = get(formData, "attributes.itemType");

            if (itemType.toLowerCase().includes("message")) {
              return <MessageADoctorLink />;
            }

            return <BookingLink />;
          }}
        </FormDataConsumer>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField
          label="Appointment date and time"
          source="attributes.appointmentTime"
          showTime
        />
      </Grid>
      {displayBookingDetails && (
        <Grid item xs={12} sm={6}>
          <Labeled label="Reason For Booking">
            <TextField source="bookings[0].attributes.reasonForBooking" emptyText="n/a"/>
          </Labeled>
        </Grid>
      )}
      {displayBookingDetails && (
        <Grid item xs={12} sm={6}>
          <TextArrayField label="Symptoms" source="bookings[0].attributes.symptoms" emptyText="n/a" />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <Labeled label="PMS Case Number">
          <FunctionField render={renderPmsCaseNumber} />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled label="PMS Case Reference">
          <FunctionField render={renderPmsCaseReference} />
        </Labeled>
      </Grid>
    </Group>
  );
}
