import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { useWatch } from "react-hook-form";
import { Colors } from "../../theme";
import { passwordStrengthCalculator } from "./passwordStrengthCalculator";

const linearProgressStyles = {
  position: "absolute",
  top: "39px",
  left: "50%",
  transform: "translateX(-50%)",
  width: "99%",
  height: "4px",
  borderRadius: "0 0 3px 3px",
};

const colors = [
  Colors.Red,
  Colors.DarkYellow,
  Colors.Green,
  Colors.BrightGreen,
];

const PasswordStrengthMeter = () => {
  const password = useWatch({ name: "password" });
  const passwordStrengthScore = passwordStrengthCalculator(password ?? "");
  const calculateColorIndex = Math.round(
    passwordStrengthScore / (100 / colors.length)
  );

  return (
    <LinearProgress
      variant="determinate"
      color="primary"
      value={passwordStrengthScore}
      sx={[
        linearProgressStyles,
        {
          "& .MuiLinearProgress-bar1Determinate": {
            backgroundColor: colors[calculateColorIndex - 1],
          },
        },
      ]}
    />
  );
};

function PasswordStrengthMeterWrapper() {
  return <PasswordStrengthMeter />;
}

export default PasswordStrengthMeterWrapper;
