import React from "react";
import { Button, useRecordContext } from "react-admin";
import { Link } from "react-router-dom";

export function PatientViewButton() {
  const record = useRecordContext();

  if (!record) return null;

  const clientId = record.clients[0].id;
  const userId = record.id;
  const url = `/patientRecords/${userId}:${clientId}/general`;

  return (
    <Button
      component={Link}
      to={url}
      label="View Patient"
      variant="outlined"
      size="small"
    />
  );
}
