import React from "react";
import { Link, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

const baseHelpUrl = "https://healthhero.atlassian.net/wiki/spaces/ED3";

const helpUrlPaths: Record<string, string> = {
  adminAppointments: "/pages/2551742475/Shifts+and+Appointments+Management+INC",
  schedule: "/pages/2345041983/Schedule",
  patientRecords: "/pages/2345074742/Patient+and+Booking+Management",
  bookings: "/pages/2345074742/Patient+and+Booking+Management",
  messageThread: "/pages/2345337072/Message+a+Doctor",
  workQueues: "/pages/2344910945/Tasks+and+Work+Queue+Management",
  clinicalPractitioners: "/pages/2345271417/Clinical+Practitioner+Management",
  resourceManagement: "/pages/2341929031/User+Management",
  clients: "/pages/2345369680/Client+Management",
  shifts: "/pages/2551742475/Shifts+and+Appointments+Management+INC",
  healthmail: "/pages/3311173635/Healthmail",
};

const getHelpUrlByCurrentRoute = (path: string) => {
  const helpPath = helpUrlPaths[path] ?? "/overview";
  return `${baseHelpUrl}${helpPath}`;
};

export function HelpLink() {
  const location = useLocation();
  const baseLocation = location.pathname.split("/", 2)[1] ?? "";
  const href = getHelpUrlByCurrentRoute(baseLocation);

  return (
    <Typography style={{ textAlign: "right", marginTop: "8px" }}>
      <Link href={href} target="_blank" rel="noreferrer noopener">
        Need help?
      </Link>
    </Typography>
  );
}
