import { GetListParams } from "react-admin";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";
import axios from "axios";
import { list } from "../index";

const endpoint = "/ms/clinicalServices";

async function listClinicalServices(params: GetListParams) {
  const { sort, pagination, filter } = params;
  const { page, perPage } = pagination;
  const { name, deliveryType, excludedServices } = filter;
  const { field, order } = sort;

  const sortQuery = `${order === "DESC" ? "-" : ""}${field}`;

  const query = {
    "page[number]": page,
    "page[size]": perPage,
    "filter[name]": name ? `like:${name}` : "",
    "filter[deliveryType]": deliveryType,
    "filter[id]": excludedServices ? `nin:${excludedServices}` : "",
    "sort": sortQuery
  };

  const queryParams = shallowlyRemoveEmptyValues(query);

  const source = axios.CancelToken.source();

  const { data, total } = await list(endpoint, {
    params: queryParams,
    cancelToken: source.token,
  });


  return { data, total };
}

export default listClinicalServices;
