import React from "react";
import { NumberInput } from "ra-ui-materialui";
import { AppointmentsPerHourInput } from "./AppointmentsPerHourInput";

interface Props {
  baseSourcePath: string;
}

export function ShiftPreferencesView(props: Props) {
  const { baseSourcePath } = props;

  const basePrefix = baseSourcePath ? `${baseSourcePath}.` : "";

  return (
    <div style={{ width: 256 }}>
      <NumberInput
        source={`${basePrefix}appointmentDuration`}
        label="Appointment duration"
      />
      <AppointmentsPerHourInput basePrefix={basePrefix} />
    </div>
  );
}
