import recursiveIsObjectEmpty from "../../utilities/recursiveIsObjectEmpty";

type ErrorMessage = string;

interface PharmacyValidation {
  name?: ErrorMessage;
  emailAddress?: ErrorMessage;
  "phoneNumbers[0].number"?: ErrorMessage;
  "address.line[0]"?: ErrorMessage;
  "address.city"?: ErrorMessage;
  "address.state"?: ErrorMessage;
  PostalCode?: ErrorMessage;
}

interface PhoneNumber {
  number?: string;
}

const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const healthmailDomains = ["@healthmail.ie", "@healthmail.com"];
const allowedProdDomains = [
  ...healthmailDomains,
  "@healthhero.com",
  "@mailosaur.com",
];
const isProdEnv = process.env.REACT_APP_ENV === "prod";
const isHealthmailEmail = (email: string) =>
  healthmailDomains.some(domain => email.toLowerCase().endsWith(domain));
const isAllowedProdEmail = (email: string) =>
  allowedProdDomains.some(domain => email.toLowerCase().endsWith(domain));

export function validateEditPharmacy(values: any) {
  const errors: PharmacyValidation = {};

  if (!values.name || values.name.trim() === "") {
    errors["name"] = "Please enter a pharmacy name";
  }

  if (!values.emailAddress || values.emailAddress.trim() === "") {
    errors["emailAddress"] = "Please enter an email address";
  } else if (!emailPattern.test(values.emailAddress.trim())) {
    errors["emailAddress"] = "Please enter a valid email address";
  } else if (isProdEnv && !isAllowedProdEmail(values.emailAddress.trim())) {
    errors[
      "emailAddress"
    ] = `Email should have an allowed domain: ${allowedProdDomains.join(", ")}`;
  } else if (!isProdEnv && isHealthmailEmail(values.emailAddress.trim())) {
    errors["emailAddress"] =
      "Email should not end with @healthmail.ie or @healthmail.com";
  }

  if (
    !values.phoneNumbers[0].number ||
    values.phoneNumbers[0].number.trim() === ""
  ) {
    errors["phoneNumbers[0].number"] = "Please enter a phone number";
  } else if (!/^\d(?:\s*\d){3,}$/.test(values.phoneNumbers[0].number.trim())) {
    errors["phoneNumbers[0].number"] =
      "Phone number must contain only digits and have a minimum length of 4";
  }

  if (!values.address.line[0] || values.address.line[0].trim() === "") {
    errors["address.line[0]"] = "Please enter a first line of address";
  }

  if (!values.address.city || values.address.city.trim() === "") {
    errors["address.city"] = "Please enter a city";
  }

  if (!values.address.state || values.address.state.trim() === "") {
    errors["address.state"] = "Please enter a state/county";
  }

  return recursiveIsObjectEmpty(errors) ? {} : errors;
}
