import { ResourcePoolCreate } from "./ResourcePoolCreate";
import ResourcePoolIcon from '@mui/icons-material/Diversity3';
import { ResourcePoolList } from "./ResourcePoolList";
import { ResourcePoolShow } from "./ResourcePoolShow";
import { ResourcePoolEdit } from "./ResourcePoolEdit";

export const resourcePoolResources = {
  name: "resourcePools",
  options: {
    label: "Resource Pools",
    flagKey: {
      name: "ed-3-enable-resource-pools"
    },
  },
  list: ResourcePoolList,
  create: ResourcePoolCreate,
  icon: ResourcePoolIcon,
  show: ResourcePoolShow,
  edit: ResourcePoolEdit
}