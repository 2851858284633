import { GetManyParams, Identifier } from "react-admin";
import { get } from "..";
import { getConfig } from '../../config';

async function getOrder(params: GetManyParams) {
    const [orderId] = params.ids;
    const { clientId, userId } = params.meta;
    const config = getConfig();

    if (!orderId) {
        return getEmptyResult(orderId);
    }

    try {
        const order = await get(
            `${config.pew.apiHost}/v${config.pew.apiVersion}/clients/${clientId}/users/${userId}/orders/${orderId}`,
            /* params */ undefined,
            /* useSapiFormat */ false
        );

        order.clientId = clientId;
        order.userId = userId;

        return {
            data: [order],
        };
    } catch (err) {
        return getEmptyResult(orderId);
    }
}

function getEmptyResult(orderId: Identifier) {
    return {
        data: [
            {
                id: orderId,
            },
        ],
    };
}

export default getOrder;
