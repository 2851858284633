import { BooleanInput } from "ra-ui-materialui";
import React from "react";
import {
  ignoreClientContractRequirementsMessage,
  ignoreOpeningTimesMessage,
} from "../../constants/tooltipMessages";
import { booleanInputAdornmentStyles } from "../../theme";
import { AdornedField } from "../fields/AdornedField";
import TooltipAdornment from "./TooltipAdornment";

interface Props {
  displayOpeningTimesToggle?: boolean;
  displayClientContractRequirementsToggle?: boolean;
}

export function BookingRestrictionToggles(props: Props) {
  const {
    displayClientContractRequirementsToggle = false,
    displayOpeningTimesToggle = true,
  } = props;

  return (
    <>
      {displayOpeningTimesToggle && (
        <AdornedField
          style={{ width: "fit-content" }}
          source="ignoreOpeningTimes"
          defaultValue={true}
          label="Ignore client contract's hours of operations"
          Field={BooleanInput}
          data-testId={"ignoreOpeningTimesToggle"}
          addLabel={false}
          adornment={
            <TooltipAdornment
              sx={booleanInputAdornmentStyles}
              text={ignoreOpeningTimesMessage}
            />
          }
        />
      )}

      {displayClientContractRequirementsToggle && (
        <AdornedField
          {...props}
          style={{ width: "fit-content" }}
          source="ignoreClientContractRequirements"
          label="Ignore CP's qualification requirements"
          Field={BooleanInput}
          addLabel={false}
          adornment={
            <TooltipAdornment
              sx={booleanInputAdornmentStyles}
              text={ignoreClientContractRequirementsMessage}
            />
          }
        />
      )}
    </>
  );
}
