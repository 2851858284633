import { Identifier } from "ra-core";
import { create } from "..";

interface Args {
  id: Identifier;
}

async function makeAppointmentNonWorking(args: Args) {
  const endpoint = `/ms/appointments/${args.id}/makeNonworking`;

  const res = await create(endpoint);

  return res;
}

export default makeAppointmentNonWorking;
