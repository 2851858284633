import { styled } from "@mui/material";
import React from "react";

import { AddressContextProvider } from "../../../context/AddressContext";
import { AddressInputs } from "./AddressInputs";
import { FindAddress } from "./FindAddress";
import { SelectAddress } from "./SelectAddress";

interface Props {
  sourcePrefix: string;
  disabled?: boolean;
}

const StyledParagraph = styled("p")({
  marginBottom: 0,
  marginTop: 32,
});

export function PatientAddressForm(props: Props) {
  const { sourcePrefix, disabled } = props;

  return (
    <AddressContextProvider>
      <FindAddress disabled={disabled} />
      <SelectAddress />
      <StyledParagraph>Or manually input an address:</StyledParagraph>
      <AddressInputs sourcePrefix={sourcePrefix} disabled={disabled} />
    </AddressContextProvider>
  );
}
