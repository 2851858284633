import { create, update } from "..";
import get from "lodash/get";
import deepEquals from "../../utilities/deepEquals";
import { AppointmentFilterCategory, AppointmentFiltersDTO } from "../../types/appointments";

type Args = {
  clientId: string;
  appointmentFilters: AppointmentFiltersDTO["data"];
  oldAppointmentFilters: AppointmentFiltersDTO["data"];
  appointmentFilterCategories: AppointmentFilterCategory[],
  existingAppointmentFilterCategories: AppointmentFilterCategory[],
};

const isCategoriesEquals = (old: AppointmentFilterCategory[], updated: AppointmentFilterCategory[]) => {
  if (old.length !== updated.length) {
    return false;
  }

  return old.every(value =>
    !!updated.find(x => x.id === value.id && x.consultationType === value.consultationType));

}

async function updateAppointmentFilters(args: Args) {
  const {
    clientId,
    appointmentFilters,
    oldAppointmentFilters,
    appointmentFilterCategories,
    existingAppointmentFilterCategories } = args;

  const noChange = deepEquals(appointmentFilters, oldAppointmentFilters);

  const noCategoriesChanges = isCategoriesEquals(appointmentFilterCategories, existingAppointmentFilterCategories);

  if (noChange && noCategoriesChanges) {
    return null;
  }

  const appointmentFiltersEndpoint = `/ms/clients/${clientId}/appointmentFilters`;

  const holidayCalendar = get(
    appointmentFilters,
    "clientHolidayCalendars[0].id"
  );

  const isHolidayEmptyString = holidayCalendar === "";

  const holidayCalendarBodyValue = isHolidayEmptyString
    ? undefined
    : {
      data: {
        type: "clientHolidayCalendars",
        id: holidayCalendar,
      },
    };

  const appointmentFiltersBody = {
    data: {
      id: clientId,
      type: "appointmentFilters",
      attributes: {
        ...appointmentFilters.attributes,
        categories: appointmentFilterCategories
          .map(x => {
            // @ts-ignore
            const isClinicalService = !isNaN(x.id);
            if (isClinicalService) {
              return { clinicalServiceId: x.id, consultationType: x.consultationType }
            }
            return ({ specialty: x.id, consultationType: x.consultationType });
          }),
      },
      relationships: {
        holidayCalendar: holidayCalendarBodyValue,
      },
    },
  };

  if (appointmentFilters.id === null) {
    try {
      const res = await create(
        appointmentFiltersEndpoint,
        appointmentFiltersBody
      );

      return res;
    } catch (err) {
      console.error("we were not able to create an appointment filter");
      return {
        data: appointmentFilters,
      };
    }
  }

  try {
    const res = await update(
      appointmentFiltersEndpoint,
      appointmentFiltersBody
    );

    return res;
  } catch (err) {
    console.error("we had trouble creating an appointment filter");
    return {
      data: appointmentFilters,
    };
  }
}

export default updateAppointmentFilters;
