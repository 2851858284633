import { GetOneParams } from "react-admin";
import { get } from "..";

async function getEmployer(params: GetOneParams) {
  const { id } = params;

  const employersEndpoint = `ms/employers/${id}`;

  const { data } = await get(employersEndpoint);

  return { data };
}

export default getEmployer;
