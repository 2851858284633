import React from "react";
import { ArrayField, Datagrid, TextField, ReferenceField } from "react-admin";
import useLazyLoadTab from "../../../hooks/useLazyLoadTab";
import { ShowTabProps } from "../../../types/utilities";
import { CapsDelimitedField } from "../../fields/CapsDelimitedField";
import DateField from "../../fields/DateField";
import { FormTab } from "../../form/CustomTabForm/FormTab";
import { AcceptTermsButton } from "../AcceptTermsButton";

export function TermsShowTab(props: ShowTabProps) {
  const { hidden = true } = props;
  const shouldDisplay = useLazyLoadTab(hidden);

  return (
    <FormTab {...props} label="Terms" editable="hidden">
      {shouldDisplay ? (
        <ReferenceField
          label={false}
          source="userCompKey"
          reference="patientTerms"
          link={false}
        >
          <ArrayField label={false} source="terms">
            <Datagrid bulkActionButtons={false}>
              <TextField source="attributes.status" label="Status" />
              <TextField source="terms[0].attributes.title" label="Title" />
              <CapsDelimitedField
                source="terms[0].attributes.type"
                label="Type"
                hideLabel
              />
              <DateField
                source="terms[0].attributes.activeFrom"
                label="Date Accepted"
                hideLabel
                emptyText="N/A"
              />
              <AcceptTermsButton />
            </Datagrid>
          </ArrayField>
        </ReferenceField>
      ) : null}
    </FormTab>
  );
}
