import { Identifier } from "ra-core";

function deepEquals<Obj extends Record<Identifier, unknown> | any[]>(
  obj: Obj,
  obj2: Obj
) {
  return JSON.stringify(obj) === JSON.stringify(obj2);
}

export default deepEquals;
