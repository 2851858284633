import { CPRegistrations } from "../../api/clinicalPractitioners/helpers/formatClinicalPractitioner";

interface Values {
  cpRegistrations: CPRegistrations
}

type ErrorMessage = string;

export function validateRegistrations(values: Values) {
  const errors: Record<string , ErrorMessage> = {};

  if (!values.cpRegistrations) return errors;

  for (const key of Object.keys(values.cpRegistrations)) {
    if (values.cpRegistrations[key]?.isRegistered && !values.cpRegistrations[key]?.registrationNumber?.trim()) {
      errors[`cpRegistrations.${key}.registrationNumber`] = "Registration number is required";
    }
  }

  return errors;
}
