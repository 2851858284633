import { Identifier } from "ra-core";
import { get } from "..";

interface Args {
  clientId: Identifier;
}

async function getFollowUpActions(args: Args) {
  const { clientId } = args;
  const endpoint = `/ms/clients/${clientId}/clientConsultationFollowUpActions`;
  const { data } = await get(endpoint);

  return data;
}

export default getFollowUpActions;
