import { Button, FieldProps, RaRecord, useRecordContext } from "react-admin";
import React, { useState } from "react";
import { AddMessageDialog } from "./AddMessageDialog";
import AddIcon from "@mui/icons-material/Add";
import get from "lodash/get";
import { Tooltip } from "@mui/material";

interface AddMessageState {
  status: string;
  mostRecentSenderRole: string;
}

const getMostRecentSenderRole = (record: RaRecord) => {
  const messages = get(record, "messages", []);
  const mostRecentMessage = messages[messages.length - 1];
  const role = get(mostRecentMessage, "messageSenders[0].attributes.role");

  return role;
};

const getTooltipText = ({ status }: AddMessageState) => {
  if (status === "Closed") {
    return "This thread has been closed and no more messages can be added.";
  }

  if (status === "PendingPatientManagementSystemAllocation") {
    return "The thread is pending PMS allocation.";
  }

  if (status === "PendingResponseFromClinicalPractitioner") {
    return "The CP needs to create their reply in Adastra.";
  }

  if (status === "PendingPatientInteraction") {
    return "The thread is pending a response from the patient.";
  }

  return "";
};

const enabledStates = ["ClinicalPractitionerResponseReady"];

export function AddMessageButton(props: FieldProps) {
  const record = useRecordContext();
  const [isDialogOpen, setDialogOpenState] = useState(false);
  const status = get(record, "attributes.status");
  const canSendMessage = enabledStates.includes(status);
  const mostRecentSenderRole = getMostRecentSenderRole(record!);
  const tooltipText = getTooltipText({ status, mostRecentSenderRole });

  return (
    <div>
      <AddMessageDialog
        {...props}
        open={isDialogOpen}
        onClose={() => setDialogOpenState(false)}
      />
      <Tooltip title={tooltipText}>
        <span>
          <Button
            disabled={!canSendMessage}
            color="primary"
            variant="contained"
            label="Add Message"
            size="medium"
            sx={{ marginTop: "8px", marginBottom: "4px" }}
            onClick={() => setDialogOpenState(true)}
          >
            <AddIcon />
          </Button>
        </span>
      </Tooltip>
    </div>
  );
}
