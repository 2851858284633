import { WorkQueueList } from "./WorkQueueList";
import { WorkQueueEdit } from "./WorkQueueEdit";
import WorkQueueIcon from "@mui/icons-material/FormatListNumbered";

export const workQueuesResources = {
  name: "workQueues",
  options: { label: "Task Monitor" },
  list: WorkQueueList,
  edit: WorkQueueEdit,
  icon: WorkQueueIcon,
};
