import React from "react";
import { Link } from "react-router-dom";
import { SchedulerIcon } from "../scheduler/Scheduler";
import { Button, useRecordContext } from "react-admin";
import { Tooltip } from "@mui/material";
import get from "lodash/get";
import { BookingStatus } from "../../types/bookings";
import getIsBookingActionDisabled from "../booking/helpers/getIsBookingActionDisabled";

const getRescheduleTooltipText = (status: BookingStatus) => {
  if (getIsBookingActionDisabled(status)) {
    return "You cannot resechedule a booking that has been completed";
  }

  return "";
};

export function RescheduleBookingButton() {
  const record = useRecordContext();
  if (!record) return null;

  const status = get(record, "attributes.status");
  const isDisabled = getIsBookingActionDisabled(status);
  const [bookingId, userId, clientId] = String(record.id).split(":");

  return (
    <Tooltip title={getRescheduleTooltipText(status)}>
      <span>
        <Button
          component={Link}
          disabled={isDisabled}
          to={{
            pathname: `/patientRecords/${userId}/clients/${clientId}/bookings/${bookingId}/reschedule`,
            state: {
              record: {
                consultationType: record?.attributes.consultationType,
                userId,
              },
            },
          }}
          label="Reschedule"
          sx={{ margin: "0px 8px" }}
          variant="contained"
          size="medium"
        >
          <SchedulerIcon />
        </Button>
      </span>
    </Tooltip>
  );
}
