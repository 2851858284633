import { update } from "..";
import { NotificationType } from "../../types/notifications";

interface Args {
  clientId: string;
  templates: Record<Partial<NotificationType>, string>;
}

export async function createSendGridTemplates(args: Args) {
  const { clientId, templates } = args;

  const createSendGridTemplatesBody = {
    data: {
      id: clientId,
      type: "batchSendGridNotificationTemplates",
      attributes: {
        templates,
      },
    },
  };

  return update(
    `/ms/clients/${clientId}/batchSendGridNotificationTemplates`,
    createSendGridTemplatesBody
  );
}
