import { list } from "../index";

const endpoint = "ms/resourceManagement/quinyxShiftTypes";

async function listQuinyxShiftTypes() {
  const { data, total } = await list(endpoint);

  return { data, total };
}

export default listQuinyxShiftTypes;
