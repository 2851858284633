interface Blob {
  type: string;
}

function downloadBlob(blob: Blob, filename: string) {
  const url = window.URL.createObjectURL(
    new Blob([blob as BlobPart], { type: blob.type })
  );
  const element = document.createElement("a");

  element.setAttribute("href", url);
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
  window.URL.revokeObjectURL(url);
}

export default downloadBlob;
