import { Grid } from "@mui/material";
import React from "react";
import { TextInput } from "react-admin";

import TooltipAdornment from "./TooltipAdornment";

const adornmentStyles = {
  alignItems: "flex-end",
};

const reasonForBookingTooltipText =
  "The condition or concern that has prompted the patient to request a consultation";

export const ReasonForBookingInput = () => {
  return (
    <Grid item xs={12} sm={6}>
      <TextInput
        multiline
        style={{ width: "360px" }}
        source="reasonForBooking"
        label="Reason For Booking"
        InputProps={{
          endAdornment: (
            <TooltipAdornment
              sx={adornmentStyles}
              text={reasonForBookingTooltipText}
            />
          ),
        }}
        required
      />
    </Grid>
  );
};
