import dayjs from "dayjs";
import { GetListParams } from "ra-core";
import {
  ConsultationDocumentOutput,
  ConsultationDocumentOutputResponse,
  GaiaDocument,
  GaiaEncounter,
  GaiaPatient,
} from "../../types/gaia";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";
import { list } from "../gaiaClient";

const A_LONG_TIME_AGO = dayjs().subtract(2, "years").format();

interface FormattedResponse {
  id: string;
  caseRef: string;
  documents: GaiaDocument[];
  encounter: GaiaEncounter;
  patient: GaiaPatient;
}

async function listDocuments(params: GetListParams) {
  const { filter } = params;

  const { recentBookingsOnly, caseRef } = filter;

  if (!caseRef) {
    return {
      data: [],
      total: 0,
    };
  }

  const aMonthAgo = dayjs().subtract(1, "month").format();
  const endpoint = `consultation-output/document-info/${caseRef}`;

  const startDate = recentBookingsOnly ? aMonthAgo : A_LONG_TIME_AGO;

  const queryParams = {
    startDate,
  };

  const filteredParams = shallowlyRemoveEmptyValues(queryParams);

  const { data }: ConsultationDocumentOutputResponse = await list(endpoint, {
    params: filteredParams,
  });

  const formattedData = formatData(data.consultationDocumentOutputs, caseRef);

  return {
    data: formattedData,
    total: data.consultationDocumentOutputs.length,
  };
}

export default listDocuments;

function formatData(
  res: ConsultationDocumentOutput[],
  caseRef: string
): FormattedResponse[] {
  const mappedData = res.map(output => {
    const newDocs = output.documents.map(({ type, ...rest }) => {
      return {
        ...rest,
        type: type ?? "doc",
      };
    });

    // separating by __ as : is part of the date string
    const id = `${caseRef}__${output.encounter.startTime}__${output.encounter.endTime}`;

    return {
      ...output,
      id,
      caseRef,
      documents: newDocs,
    };
  });

  return mappedData;
}
