import { GetOneParams } from "react-admin";
import { baseUrl, get } from "..";

const includes = [
  "assignee",
  "booking",
  "booking.appointment",
  "booking.appointment.clinicalPractitioner",
  "booking.patientManagementSystemCase",
  "booking.user",
  "client",
  "message.patientManagementSystemCase",
  "message.appointment.clinicalPractitioner",
  "messageThread",
  "patientRecord"
];

async function getWorkQueueItem(params: GetOneParams) {
  const getWorkQueueItemEndpoint = `${baseUrl}/ms/workQueueItems/${params.id}`;

  const getParams = {
    include: includes.join(","),
  };

  const { data } = await get(getWorkQueueItemEndpoint, getParams);

  const result = Array.isArray(data) ? data[0] : data;

  return { data: result };
}

export default getWorkQueueItem;
