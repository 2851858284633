import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import { ListActions } from "../Actions";
import { ClientFilter } from "./ClientFilter";
import { ListWithoutFilterEmptyText, removeDefaultEmptyMessageStyle } from "../shared/ListHelpers";


export function ClientList() {
  return (
    <List
      filterDefaultValues={{ignoreWithoutFilters: true}}
      actions={<ListActions createLabel="Create Client Contract" />}
      filters={<ClientFilter />}
      sort={{ field: "name", order: "ASC" }}
      sx={removeDefaultEmptyMessageStyle}
    >
      <Datagrid bulkActionButtons={false} rowClick="show" empty={<ListWithoutFilterEmptyText/>}>
        <TextField label="Client ID" source="id" />
        <TextField
          label="Contract Name"
          source="attributes.name"
          sortBy="name"
        />
      </Datagrid>
    </List>
  );
}
