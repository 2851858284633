import { baseUrl, update } from "../";
import { PatientNotesDTO, PatientRecordDTO } from "../../types/patients";
import updatePatientNotes from "./updatePatientNotes";
import get from "lodash/get";
import getPatientRecord from "./getPatientRecord";
import dayjs from "dayjs";
import { UpdateParams } from "ra-core";
import recursiveIsObjectEmpty from "../../utilities/recursiveIsObjectEmpty";
import getValidationHeaders from "../helpers/getValidationHeaders";
import { AxiosRequestConfig } from "axios";

export interface Body {
  attributes: Partial<PatientRecordDTO["data"]["attributes"]>;
  clients: { id: string }[];
  users: {
    id: string;
    attributes: {
      clientId?: string;
      clientIdentifier?: string;
    };
  }[];
  id: string;
  patientId: string;
  documents?: any[];
  newDocuments?: { rawFile: File }[];
  prevNotes?: PatientNotesDTO["data"][];
  notes?: PatientNotesDTO["data"][];
  validationOverride?: boolean;
}

async function updatePatientRecord({ data }: UpdateParams<Body>) {
  const { clients, users, patientId, documents, validationOverride = false, ...requestBody } = data as Body;

  const clientId = clients[0].id;
  const userId = users[0].id;
  const clientIdentifier = get(users[0], "attributes.clientIdentifier");

  // remove any attributes that can't be modified
  delete requestBody.attributes.createdAt;
  delete requestBody.attributes.updatedAt;
  delete requestBody.attributes.patientReference;

  const { dateOfBirth } = requestBody.attributes;
  const formattedDateOfBirth = dayjs(dateOfBirth).format("YYYY-MM-DD");

  if (dateOfBirth) {
    requestBody.attributes.dateOfBirth = formattedDateOfBirth;
  } else {
    delete requestBody.attributes.dateOfBirth;
  }

  const gpIsEmpty = recursiveIsObjectEmpty(requestBody.attributes.gp);
  const pharmacyIsEmpty = recursiveIsObjectEmpty(
    requestBody.attributes.nominatedPharmacy
  );

  if (gpIsEmpty) {
    requestBody.attributes.gp = null;
  }

  if (pharmacyIsEmpty) {
    requestBody.attributes.nominatedPharmacy = null;
  } else {
    const nominatedOrganisationId = requestBody.attributes.nominatedPharmacy!.organisationId;

    if (!nominatedOrganisationId) {
      requestBody.attributes.nominatedPharmacy!.organisationId = undefined;
    }
  }

  const updateRecordEndpoint = `${baseUrl}/clients/${clientId}/users/${userId}/patientRecord/${patientId}`;
  const updateClientIdentifierEndpoint = `${baseUrl}/clients/${clientId}/users/${userId}/clientIdentifier`;

  requestBody.id = patientId;

  const clientIdentifierBody = {
    data: {
      id: userId,
      type: "users",
      attributes: {
        clientIdentifier: clientIdentifier,
      },
    },
  };

  const validationHeaders = getValidationHeaders(validationOverride);

  const reqConfig = {
    headers: {
      ...validationHeaders,
    },
  } as AxiosRequestConfig;

  await update(updateClientIdentifierEndpoint, clientIdentifierBody, reqConfig);

  await update(updateRecordEndpoint, {
    data: requestBody,
  });

  await updatePatientNotes(data as Body);

  return getPatientRecord({ id: data.id });
}

export default updatePatientRecord;
