import { Button } from "ra-ui-materialui";
import React from "react";
import { Link } from "react-router-dom";
import ImportIcon from "@mui/icons-material/GetApp";

export function ImportButton() {
  return (
    <Button
      sx={{ margin: "0 8px" }}
      component={Link}
      to="/shifts/import"
      color="primary"
      variant="outlined"
      size="medium"
      label="Import shift"
    >
      <ImportIcon />
    </Button>
  );
}
