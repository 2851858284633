import { GetManyParams } from "react-admin";
import { get } from "..";

async function getManyMasterClients(params: GetManyParams) {
  const { ids = [] } = params;
  const endpoint = "/ms/clientgroups";

  const clientGroupId = ids.length ? ids[0] : "";

  const res = await get(`${endpoint}/${clientGroupId}`);

  const data = [
    {
      ...res.data,
      name: res.data.attributes.name,
    },
  ];

  return { data };
}

export default getManyMasterClients;
