import React from "react";
import {
  Create,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  RaRecord,
  useNotify,
  useRedirect,
} from "react-admin";
import { Heading } from "../ui/Heading";
import { nonEmpty } from "../../validators/nonEmpty";

export function ClientCreate() {
  const redirect = useRedirect();
  const notify = useNotify();

  return (
    <Create
      title="Create Client Contract"
      mutationOptions={{
        onSuccess: (newRecord: RaRecord) => {
          const clientId = newRecord.id;
          notify("Client Contract was created successfully");
          redirect(`/clients/${clientId}`);
        },
      }}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={
          <Toolbar>
            <SaveButton label="Create Client Contract" alwaysEnable />
          </Toolbar>
        }
        mode="onBlur"
      >
        <Heading level={2}>Client Contract Details</Heading>
        <TextInput
          required
          validate={nonEmpty("All contracts require a name")}
          source="name"
          label="Name"
        />
      </SimpleForm>
    </Create>
  );
}
