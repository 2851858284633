import PharmacyIcon from "@mui/icons-material/Medication";
import { PharmacyManagementList } from "../pharmacies/PharmacyManagementList";
import { TOGGLE_USE_PHARMACY_MANAGEMENT } from "../../featureFlags/flags";
import { PharmacyManagementShow } from "./PharmacyManagementShow";
import { PharmacyManagementCreate } from "./PharmacyManagementCreate";
import { PharmacyManagementEdit } from "./PharmacyManagementEdit";

/* 
TODO: We will most likely combine the gaia:pharmacies into the pharmacies resource, when we remove stubbing.
*/
export const pharmacyManagementResources = {
  name: "pharmacies",
  options: {
    label: "Pharmacy Management",
    flagKey: {
      name: TOGGLE_USE_PHARMACY_MANAGEMENT,
    },
  },
  list: PharmacyManagementList,
  show: PharmacyManagementShow,
  create: PharmacyManagementCreate,
  edit: PharmacyManagementEdit,
  icon: PharmacyIcon,
};
