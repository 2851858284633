import React from "react";
import { ArrayField, FieldProps, Labeled } from "ra-ui-materialui";
import { DownloadButton } from "../buttons/DownloadButton";
import { SimpleIteratorLayout } from "./SimpleIteratorLayout";
import { FileImageField } from "./FileImageField";
import { Box } from "@mui/material";

const fieldWrapperStyles = {
  display: "flex",
  minWidth: "360px",
  gap: 1,
  marginTop: 1,
};

type DownloadProps = {
  fields?: {
    label: string;
    source: string;
  }[];
  view?: "show" | "edit";
  downloadSources: {
    clientId?: string;
    fileId: string;
    userId?: string;
    fileName: string;
    clinicalPractitionerId?: string;
  };
};

type Props = FieldProps &
  DownloadProps & { label: string; hideLabel?: boolean };

export function DownloadsField(props: Props) {
  const {
    downloadSources,
    view = "show",
    fields = [],
    label,
    hideLabel,
    source,
  } = props;

  const displayedLabel = hideLabel ? false : label;

  return (
    <Labeled label={displayedLabel} className="two">
      <ArrayField
        label={false}
        source={source}
        record={props.record}
        className="helo"
      >
        <SimpleIteratorLayout>
          <Box sx={fieldWrapperStyles}>
            {fields.map(field => {
              return (
                <FileImageField key={field.source} titleSource={field.source} />
              );
            })}
            <Box sx={{ flexGrow: 1 }}>
              <DownloadButton view={view} sources={downloadSources} />
            </Box>
          </Box>
        </SimpleIteratorLayout>
      </ArrayField>
    </Labeled>
  );
}
