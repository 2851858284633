import React from "react";
import { Show } from "react-admin";
import { Actions } from "../Actions";
import { PageTitle } from "../PageTitle";
import { ScrollableTabbedShowLayout } from "../ScrollableTabLayouts";
import { AppointmentDetailsShowTab } from "./tabs/AppointmentDetailsShowTab";
import { GeneralDetailsShowTab } from "./tabs/GeneralDetailsShowTab";

export function ClinicalPractitionerShow() {
  return (
    <Show title={<PageTitle />} actions={<Actions />}>
      <ScrollableTabbedShowLayout>
        <GeneralDetailsShowTab />
        <AppointmentDetailsShowTab />
      </ScrollableTabbedShowLayout>
    </Show>
  );
}
