type Callback<T> = (val: T) => Record<string, unknown>;

/**
 * A wrapper over reduce. Performs a similar function to map, but returns a single object instead of an array
 */
function objectMap<T>(array: readonly T[], cb?: Callback<T>) {
  return array.reduce(function (acc, val) {
    if (!cb) {
      return {
        ...acc,
        ...val,
      };
    }

    const res = cb(val);

    return {
      ...acc,
      ...res,
    };
  }, {});
}

export default objectMap;
