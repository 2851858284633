import React from "react";
import { Datagrid, FunctionField, List, TextField, useListContext } from "react-admin";
import { renderPmsCaseNumber } from "../../utilities/renderPmsCaseAttribute";
import { CapsDelimitedField } from "../fields/CapsDelimitedField";
import DateField from "../fields/DateField";
import { QuickAssignButton } from "./QuickAssignButton";
import { QuickCompleteButton } from "./QuickCompleteButton";
import { WorkQueueFilter } from "./WorkQueueFilter";

export function WorkQueueList() {
  return (
    <List
      filters={<WorkQueueFilter />}
      title="Task Monitor"
      actions={false}
      sort={{ field: "appointment.start", order: "ASC" }}
    >
      <WorkQueueDataGrid />
    </List>
  );
}

function WorkQueueDataGrid(){
  const { filterValues } = useListContext();
  const isCompletedStatus = filterValues?.status === "Completed";
  
  return (
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField label="Task ID" source="id" />
      <CapsDelimitedField
        sortable={false}
        hideLabel
        label="Type"
        source="attributes.itemType"
      />
      <TextField
        sortable={false}
        label={isCompletedStatus ? "Completed by" : "Assigned to" }
        source="users[0].attributes.userName"
        emptyText="-"
      />
      {isCompletedStatus && 
        <DateField
          hideLabel
          label="Completed At"
          source="attributes.completedAt"
          showTime
          emptyText="-"
          sortBy="completedAt"
          sortByOrder="DESC"
        />
      }
      <TextField
        sortable={false}
        label="Client Contract"
        source="clients[0].attributes.name"
      />
      <TextField
        sortable={false}
        label="Patient Name"
        source="bookingDetails.patientName"
      />
      <DateField
        hideLabel
        label="Booking Time"
        source="bookingDetails.bookingTime"
        showTime
        emptyText="-"
        sortBy="booking.createdAt"
      />
      <DateField
        hideLabel
        label="Appointment Time"
        source="bookingDetails.appointmentTime"
        showTime
        emptyText="-"
        sortBy="appointment.start"
      />
      <FunctionField
        label="PMS Case No"
        render={renderPmsCaseNumber} />
      <div style={{ display: "flex", gap: "0.75rem", justifyContent: "right" }}>
        <QuickAssignButton />
        <QuickCompleteButton />
      </div>
    </Datagrid>
  );
}
