import dayjs from "dayjs";
import get from "lodash/get";
import getShouldOptimiseApts from "./helpers/getShouldOptimiseApts";

type ErrorMessage = string;

interface Errors {
  end?: ErrorMessage;
  start?: ErrorMessage;
  clinicalPractitioner?: ErrorMessage;
  appointmentDuration?: ErrorMessage;
  appointmentsPerHour?: ErrorMessage;
  breaks: {
    start?: ErrorMessage;
    end?: ErrorMessage;
  }[];
}

type Break =
  | {
      start: Date;
      end: Date;
    }
  | undefined;



function validateAppointmentsFields(values: any) {
  const errors: Errors = {
    breaks: []
  };
  const shouldOptimiseApts = getShouldOptimiseApts(values);
  const appointmentsPerHour = get(values, "appointmentsPerHour");
  const appointmentDuration = get(values, "appointmentDuration");

  if (!appointmentsPerHour && !shouldOptimiseApts) {
    errors.appointmentsPerHour = "Please select the # of appointments per hour";
  }


  if (!appointmentDuration) {
    errors.appointmentDuration = "Please choose an appointment duration";
  } else {
    if (appointmentDuration < 5) {
      errors.appointmentDuration =
        "Appointment must be at least 5 minutes long";
    }
    if (appointmentDuration > 60) {
      errors.appointmentDuration =
        "Appointment cannot be longer than 60 minutes";
    }
  }

  return errors;
}


function validateCreateShift(values: any, shouldCreateAppointments: boolean) {
  const start = get(values, "start");
  const end = get(values, "end");
  const breaks: Break[] = get(values, "breaks", []);

  let appointmentsErrors = {};

  if (shouldCreateAppointments) {
    appointmentsErrors = validateAppointmentsFields(values);
  }

  const errors: Errors = {
    ...appointmentsErrors,
    breaks: [],
  };

  const clinicalPractitioner = get(values, "clinicalPractitioner");

  if (!clinicalPractitioner) {
    errors.clinicalPractitioner = "Please choose a clinical practitioner";
  }


  let hasBreakError = false;
  breaks.forEach((aptBreak, i) => {
    errors.breaks[i] = {};

    if (!aptBreak) {
      hasBreakError = true;
      errors.breaks[i].start = "Please choose a break start time";
      errors.breaks[i].end = "Please choose a break end time";
      return;
    }

    if (!aptBreak.start) {
      errors.breaks[i].start = "Please choose a break start time";
    }

    if (!aptBreak.end) {
      errors.breaks[i].end = "Please choose a break end time";
    }

    const breakStartIsAfterBreakEnd = dayjs(aptBreak.start).isAfter(
      aptBreak.end
    );

    if (breakStartIsAfterBreakEnd) {
      errors.breaks[i].start = "The break's start is after the break's end";
    }

    const breakStartIsBeforeStart = dayjs(aptBreak.start).isBefore(start);
    if (breakStartIsBeforeStart) {
      errors.breaks[i].start = "This break falls outside of shift hours";
    }

    const breakStartIsAfterEnd = dayjs(aptBreak.start).isAfter(end);
    if (breakStartIsAfterEnd) {
      errors.breaks[i].start = "This break falls outside of shift hours";
    }

    const breakEndIsBeforeStart = dayjs(aptBreak.end).isBefore(start);
    if (breakEndIsBeforeStart) {
      errors.breaks[i].end = "This break falls outside of shift hours";
    }

    const breakEndIsAfterEnd = dayjs(aptBreak.end).isAfter(end);
    if (breakEndIsAfterEnd) {
      errors.breaks[i].end = "This break falls outside of shift hours";
    }

    if (errors.breaks[i].end || errors.breaks[i].start) {
      hasBreakError = true;
    }
  });

  if (!hasBreakError) {
    // related: https://github.com/marmelab/react-admin/issues/7500
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete errors.breaks;
  }

  if (start && end) {


    if (dayjs(start).isBefore(dayjs())) {
      errors.start = "Shift cannot start in the past"
    }

    const endMinusAnHour = dayjs(end).subtract(1, "hour");
    const isEndWithinAnHourOfStart = dayjs(endMinusAnHour).isBefore(
      start,
      "minute"
    );
    const isEndBeforeStart = dayjs(end).isBefore(start);

    if (isEndBeforeStart) {
      errors.end = "Shift cannot end before it has started";
    } else if (isEndWithinAnHourOfStart) {
      errors.end = "Minimum shift length is one hour";
    }
  }

  return errors;
}



export default validateCreateShift;
