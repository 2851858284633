import React from "react";
import { Group } from "../../layout/Group";
import { Stack } from "../../layout/Stack";
import { Heading } from "../../ui/Heading";
import { ConsultationOutputLink } from "../ConsultationOutputLink";
import { BookingGroup } from "./BookingGroup";
import { ClinicalPractitionerGroup } from "./ClinicalPractitionerGroup";

export function FollowUpActionsFields() {
  return (
    <>
      <BookingGroup />
      <Group
        heading={<Heading level={2}>Consultation Outputs</Heading>}
        data-testid="consultation-outputs-group"
      >
        <Stack>
          <ConsultationOutputLink />
        </Stack>
      </Group>
      <ClinicalPractitionerGroup />
    </>
  );
}
