import {
  useCreate,
  useDelete,
  useNotify,
  useRecordContext,
  useRefresh,
} from "ra-core";
import {
  Button,
  Edit,
  SimpleForm,
  TextField,
  Toolbar,
  ToolbarProps,
} from "ra-ui-materialui";
import DeleteIcon from "@mui/icons-material/Delete";
import AvailableIcon from "@mui/icons-material/EventAvailable";
import BusyIcon from "@mui/icons-material/EventBusy";
import MessageIcon from "@mui/icons-material/Message";
import React from "react";
import { EditActions } from "../Actions";
import { Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { StatusField } from "./StatusField";
import displayTooltipMessages from "../../utilities/displayTooltipMessages";
import { useParams } from "react-router-dom";
import { SimpleShowLayout } from "react-admin";
import { Heading } from "../ui/Heading";
import { FullNameField } from "../fields/FullNameField";

const terminalStates = ["Completed", "Cancelled", "NotAvailable"];

const PAST_MESSAGE = "Cannot update a past appointment";
const TERMINAL_STATE_MESSAGE =
  "Cannot update an appointment that's been completed or cancelled";
const ALREADY_MAD_APT_MESSAGE =
  "Cannot convert this appointment as it is already Message A Doctor";

const buttonStyles = {
  margin: "0px 8px",
};

const toolbarStyles = {
  justifyContent: "flex-end",
};

function AppointmentEditToolbar(props: ToolbarProps) {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();

  const { id = "", attributes } = record;
  const { status, consultationType } = attributes;

  const isTerminalState = terminalStates.includes(status);
  const isAvailable = status === "Available";
  const isNonWorking = status === "Nonworking";
  const isMessageDoctor = consultationType === "MessageDoctor";

  const [convert, { isLoading: madLoading }] = useCreate(
    "convertToMAD",
    {
      data: { id },
    },
    {
      onSuccess: () => {
        notify("The appointment has been successfully converted");
        refresh();
      },
      onError: error => notify((error as Error).message),
    }
  );

  const [cancel, { isLoading: cancelLoading }] = useDelete(
    "adminAppointments",
    { id, previousData: record },
    {
      onSuccess: () => {
        notify("The appointment was cancelled");
        refresh();
      },
      onError: error => {
        notify((error as Error).message ?? "");
      },
    }
  );

  const [makeAvailable, { isLoading: availableLoading }] = useCreate(
    "makeAppointmentAvailable",
    { data: { id } },
    {
      onSuccess: () => {
        notify("The appointment is now available");
        refresh();
      },
      onError: error => notify((error as Error).message),
    }
  );

  const [makeNonWorking, { isLoading: nonWorkingLoading }] = useCreate(
    "makeAppointmentNonWorking",
    {
      data: { id },
    },
    {
      onSuccess: () => {
        notify("The appointment is now a break");
        refresh();
      },
      onError: error => notify((error as Error).message),
    }
  );

  const isAptInPast = dayjs().isAfter(attributes.start);

  return (
    <Toolbar {...props} sx={toolbarStyles}>
      <Tooltip
        title={displayTooltipMessages([
          [isMessageDoctor, ALREADY_MAD_APT_MESSAGE],
        ])}
      >
        <span>
          <Button
            label="Convert to message doctor"
            disabled={
              madLoading || isTerminalState || isMessageDoctor || isAptInPast
            }
            onClick={() => convert()}
            sx={buttonStyles}
          >
            <MessageIcon />
          </Button>
        </span>
      </Tooltip>
      <Tooltip
        title={displayTooltipMessages([
          [isTerminalState, TERMINAL_STATE_MESSAGE],
          [isAptInPast, PAST_MESSAGE],
        ])}
      >
        <span>
          <Button
            label="Make available"
            disabled={
              availableLoading || isTerminalState || isAvailable || isAptInPast
            }
            onClick={() => makeAvailable()}
            sx={buttonStyles}
          >
            <AvailableIcon />
          </Button>
        </span>
      </Tooltip>
      <Tooltip
        title={displayTooltipMessages([
          [isTerminalState, TERMINAL_STATE_MESSAGE],
          [isAptInPast, PAST_MESSAGE],
        ])}
      >
        <span>
          <Button
            label="Make break"
            onClick={() => makeNonWorking()}
            disabled={
              nonWorkingLoading ||
              isTerminalState ||
              isNonWorking ||
              isAptInPast
            }
            sx={buttonStyles}
          >
            <BusyIcon />
          </Button>
        </span>
      </Tooltip>
      <Button
        label="Cancel appointment"
        color="secondary"
        disabled={cancelLoading || isTerminalState}
        onClick={() => cancel()}
        sx={buttonStyles}
      >
        <DeleteIcon />
      </Button>
    </Toolbar>
  );
}

export function AppointmentEdit() {
  const { id } = useParams();
  return (
    <Edit actions={<EditActions />} title={`Appointment #${id}`}>
      <SimpleForm toolbar={<AppointmentEditToolbar />}>
        <SimpleShowLayout sx={{ padding: "0px" }}>
          <Heading level={2}>Appointment Details</Heading>
          <TextField label="ID" source="id" />
          <FullNameField
            label="Clinical Practitioner"
            firstNameSource="clinicalPractitioners[0].attributes.firstName"
            lastNameSource="clinicalPractitioners[0].attributes.lastName"
          />
          <TextField
            label="Clinical Service"
            source="clinicalServices[0].attributes.name"
            emptyText="Not provided"
          />
          <TextField label="Consultation Type" source="attributes.consultationType" />
          <StatusField label="Status"/>
        </SimpleShowLayout>
      </SimpleForm>
    </Edit>
  );
}
