// eslint-disable-next-line no-restricted-imports
import { FileInput as RAFileInput, FileInputProps } from "ra-ui-materialui";
import { InputProps } from "ra-core";
import React from "react";
import { customStyles } from "../../theme";
import { useNotify } from "react-admin";
import { useFormContext } from "react-hook-form";
import splitByLastInstance from "../../utilities/splitByLastInstance";
import { SxProps } from "@mui/material";
import isString from "lodash/isString";

interface Props extends FileInputProps, InputProps<any> {
  exclude?: string[] | string;
  source: string;
  className?: string;
  disabled?: boolean;
  sx?: SxProps;
}

interface FileAlt {
  rawFile: File;
}

const DEFAULT_MAX_SIZE = 50000000;

const disabledInputStyles = {
  "& [data-testid='dropzone']": customStyles.dropZoneDisabled,
};

export const fileInputStyles = {
  "& .RaFileInput-dropZone": customStyles.dropZone,
  "& .RaFileInput-removeButton": {
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
    "& > button": {
      height: "min-content",
    },
  },
  "& .RaFileInput-preview": {
    "& img": {
      maxWidth: "100%",
      maxHeight: "200px",
    },
  },
};

export function FileInput(props: Props) {
  const {
    accept,
    exclude = [],
    disabled,
    maxSize = DEFAULT_MAX_SIZE,
    className,
    sx = {},
    ...rest
  } = props;

  const disabledClass = disabled ? disabledInputStyles : {};
  const notify = useNotify();
  const form = useFormContext();

  const onDropRejected = () => {
    notify(
      "One or more files were rejected. Please ensure the file types are valid and the files are no larger than 28mb."
    );
  };

  return (
    <RAFileInput
      {...rest}
      className={`${disabled ? disabledClass : ""}`}
      maxSize={maxSize}
      sx={[fileInputStyles, disabledClass, ...(Array.isArray(sx) ? sx : [sx])]}
      options={{
        accept,
        disabled,
        onDropRejected,
      }}
      onChange={async (files: Array<File | FileAlt>) => {
        if (rest.onChange) {
          rest.onChange(files);
        }

        if (!exclude.length) return;

        const excludedArr = isString(exclude) ? [exclude] : exclude;

        if (!files || !files.length) return;

        // This isn't a very reliable validator
        // It's possible for extensions to not match the fileType
        // Based on this issue it's non-trivial: https://stackoverflow.com/questions/18299806/how-to-check-file-mime-type-with-javascript-before-upload
        const validFiles: Array<File | FileAlt> = [];

        for await (const file of files) {
          if ("rawFile" in file) {
            validFiles.push(file);
          } else {
            const ext = splitByLastInstance(file.name, ".");
            const isInvalidFormat = excludedArr.some(
              excludedFormat => ext === excludedFormat
            );

            if (!isInvalidFormat) {
              validFiles.push(file);
            }
          }
        }

        if (validFiles.length !== files.length) {
          notify(
            `One or more files was an invalid file type. Please ensure it's not ${excludedArr.join(
              ", "
            )}`
          );
          form.setValue(rest.source, validFiles);
        }
      }}
      inputProps={{ "data-testid": "fileInput", name: props.source }}
    />
  );
}
