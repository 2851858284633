import React from "react";
import {
  ChipField,
  Show,
  SimpleShowLayout,
  TextField,
  Title,
  useRecordContext
} from "react-admin";
import { BooleanField } from "../fields/BooleanField";
import { ArrayField, SingleFieldList } from "ra-ui-materialui";
import get from "lodash/get";
import { Heading } from "../ui/Heading";

function PageTitle() {
  const record = useRecordContext();
  const name = get(record, "attributes.name");
  return <Title title={name} />;

}

export function ClinicalProductsShow() {
  return (
    <Show title={<PageTitle />}>
      <SimpleShowLayout>
        <Heading level={2}>General Details</Heading>
        <TextField label="Name" source="attributes.name" />
        <TextField label="Code" source="attributes.code" />
        <BooleanField label="Enabled" source="attributes.isEnabled" />
        <ArrayField label={"Services"} source="attributes.services">
          <SingleFieldList sx={{ padding: "10px 0", maxWidth: "300px" }} linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <ArrayField label={"Clients"} source="clients">
          <SingleFieldList sx={{ padding: "10px 0", maxWidth: "300px" }} linkType={false}>
            <ChipField source="attributes.name" />
          </SingleFieldList>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
}