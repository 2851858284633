import React, { useState } from "react";
import {
  Datagrid,
  ListContextProvider,
  useList,
  Title, useCreate, useRedirect
} from "react-admin";
import { useLocation } from "react-router-dom";
import { FullNameField } from "../fields/FullNameField";
import DateField from "../fields/DateField";
import { TextField } from "ra-ui-materialui";
import { Identifier, useNotify } from "ra-core";
import { Button } from "../buttons/Button";


export function ShiftImportProcess() {
  const { state } = useLocation();
  const shifts = state?.shifts;

  const context = useList({ data: shifts });
  const [selectedIds, setSelectedIds] = useState(shifts?.map((r: any) => r.id));
  const [create, {isLoading}] = useCreate();
  const redirect = useRedirect();
  const notify = useNotify();

  if (!shifts?.length) {
    notify("No shifts to process");
    redirect("/shifts");
  }



  const process = async () => {
    await create("importShiftsProcessed", { data: {shiftIds: selectedIds }});
    notify("Selected shift queued to process");
    redirect("/shifts");

  }
  
  const onToggleItem = (id: Identifier) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId: Identifier) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  }

  const onSelect = (ids: Identifier[]) => {
    setSelectedIds(ids);
  }

  const styles = {
    "& .RaBulkActionsToolbar-toolbar": {
      display: 'none'
    },
    "margin-top": "20px",
    "margin-bottom": "20px",
  };

  return (
      <ListContextProvider value={context}>
      <Title title={"Process shifts import"} />
      <Datagrid sx={styles} title={"Process Shifts Import"} onSelect={onSelect} onToggleItem={onToggleItem}  selectedIds={selectedIds} >
        <FullNameField
          sortable={false}
          label="Assigned CP"
          emptyText="n/a"
          firstNameSource="clinicalPractitioner.firstName"
          lastNameSource="clinicalPractitioner.lastName"
        />
        <DateField
          sortBy="start"
          label="Start time"
          hideLabel
          showTime
          source="start"
        />
        <DateField
          sortable={false}
          hideLabel
          label="End time"
          showTime
          source="end"
        />
        <TextField
          sortable={false}
          label="Shift status"
          source="status"
        />
      </Datagrid>
        <Button
          disabled={!selectedIds?.length || isLoading}
          onClick={process}
          aria-label={"Process import"}
          variant="outlined"
        >
          {"Process import"}
        </Button>
      </ListContextProvider>
  );
}
