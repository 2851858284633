import { email, required as requiredValidator } from "ra-core";
import { TextInput, TextInputProps } from "ra-ui-materialui";
import React from "react";

export function EmailInput(props: TextInputProps) {
  const { source = "", required, ...rest } = props;

  const validate = [
    email(),
    ...(required ? [requiredValidator("An email address is required")] : []),
  ];
  return (
    <TextInput
      source={source}
      label="Email"
      validate={validate}
      parse={(v: string) => v.trim()}
      {...rest}
    />
  );
}
