import { AdminClinicalPractitionerDTO, Specialty } from "./clinicalPractitioners";
import { Relationship } from "./utilities";
import { ClientDTO } from "./clients";

type ResourcePoolsRelationships = {
  clinicalPractitioners: { name: "clinicalPractitioners"; type: "many" };
  clientClinicalPools: { name: "clientClinicianPools"; type: "many" };
};

export const getResourcePoolServiceName =
  (resourcePool: ResourcePoolsDTO["data"]) =>
  resourcePool.attributes.clinicalServiceId ?
    resourcePool?.clinicalServices?.[0].attributes.name : resourcePool.attributes.specialty

export interface ResourcePoolsDTO {
  data: {
    type: "resourcePools";
    id?: string;
    attributes: {
      isEnabled: boolean;
      specialty?: Specialty;
      clinicalServiceId?: number;
      clientId: string;
      clinicalPractitioners: string[];
      createdAt?: string;
      updatedAt?: string;
      globallyAvailableDuration: number;
      isGloballyAvailable: boolean;
    };
    relationships: Relationship<ResourcePoolsRelationships>;
    // related data
    clinicalPractitioners?: AdminClinicalPractitionerDTO["data"][];
    clientClinicianPools?: ClientClinicianPoolsDTO["data"][];
    clinicalServices: any[];
  };
}

type ClientClinicalPoolsRelationships = {
  clinicalPractitioners: { name: "resourcePools"; type: "one" };
  client: { name: "client", type: "one" };
};

export interface ClientClinicianPoolsDTO {
  data: {
    type: "clientClinicianPools";
    id?: string;
    attributes: {
      isExclusive: boolean;
      resourcePoolId: string;
      clientId: number;
    }
    relationships: Relationship<ClientClinicalPoolsRelationships>;
    // related data
    resourcePools?: ResourcePoolsDTO["data"][];
    client?: ClientDTO["data"];
  }
}

export type EditResourcePools = {
  type: "resourcePools";
  id?: string;
  attributes: {
    isExclusive: boolean;
    isEnabled: boolean;
    specialty: Specialty;
    clientId: string;
    clinicalPractitioners: string[];
    globallyAvailableDuration: number;
    isGloballyAvailable: boolean;
  };
  relationships: {
    clinicalPractitioners: {
      data: string[];
    };
  };
};
