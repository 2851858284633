import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import { BooleanField } from "../fields/BooleanField";
import { ResourceActions } from "./ResourceActions";
import { ResourceFilter } from "./ResourceFilter";
import { RoleField } from "./ResourceRoleField";
import { ListWithoutFilterEmptyText, removeDefaultEmptyMessageStyle } from "../shared/ListHelpers";

export function ResourceList() {
  return (
    <List
      filterDefaultValues={{ ignoreWithoutFilters: true }}
      title="User Management"
      actions={<ResourceActions />}
      filters={<ResourceFilter />}
      sx={removeDefaultEmptyMessageStyle}
    >
      <Datagrid rowClick="show" bulkActionButtons={false} empty={<ListWithoutFilterEmptyText />}>
        <TextField sortable={false} source="attributes.email" label="Email" />
        <TextField
          sortable={false}
          source="attributes.firstName"
          label="First name"
        />
        <TextField
          sortable={false}
          source="attributes.lastName"
          label="Last name"
        />
        <TextField
          sortable={false}
          source="attributes.phoneNumber"
          label="Phone number"
        />
        <RoleField sortable={false} source="role" />
        <BooleanField
          sortable={false}
          source="attributes.isEnabled"
          label="Is Enabled"
        />
      </Datagrid>
    </List>
  );
}
