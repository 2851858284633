import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";

import { Button, Datagrid, FunctionField, ListBase, TextField, useGetIdentity, useNotify } from "react-admin";
import { Stack } from "../../../layout/Stack";
import DateField from "../../../fields/DateField";
import { renderGaiaPatientName } from "../../../../utilities/renderGaiaName";
import { PmsPatientCaseManualLink } from "../../../../types/patientManagementSystems";
import { CaseLinkButton } from "./CaseLinkButton";
import { datadogLogs } from "@datadog/browser-logs";
import { HealthMailDataGridWrapper } from "../../../shared/healthmail/HealthMailDataGridWrapper";
import { TextField as MuiTextField } from "@mui/material";

const StyledSearchDiv = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

interface PmsEditSearchCasePanelProps {
  onLinkClick: (caseToLink: PmsPatientCaseManualLink) => void;
}

export function PmsEditSearchCasePanel(props: PmsEditSearchCasePanelProps) {
  const { identity } = useGetIdentity();
  const notify = useNotify();
  // If we want to search the same case number again after unlink, we'd need to lift this state into parent (to preserve) and pass down, tracking if search has been done in here too.
  const [pmsCaseNumberToSearch, setPmsCaseNumberToSearch] = useState("");
  const searchTextRef = useRef<HTMLInputElement>(null!);

  const caseNumberSearchLabelText = "Search by case number";

  const handleSearch = () => {
    setPmsCaseNumberToSearch(searchTextRef.current.value);
  };

  return (
    <Stack>
      <StyledSearchDiv>
        <MuiTextField
          label={caseNumberSearchLabelText}
          aria-label={caseNumberSearchLabelText}
          inputRef={searchTextRef}
          name="search-case-number"
        />
        <Button
          sx={{ margin: 1 }}
          size="medium"
          label="Search"
          variant="contained"
          onClick={() => handleSearch()}
          data-cy="case-number-search-button"
        />
      </StyledSearchDiv>

      <ListBase
        resource="healthmail"
        filter={{ caseNumber: pmsCaseNumberToSearch }}
        queryOptions={{
          onError: err => {
            const { status, message } = err as any;
  
            if (status === 401 || status === 403) {
              datadogLogs.logger.info("Healthmail Error", {
                userId: identity?.id ?? "unknown",
                message: message,
                status,
              });
            }
   
            notify((err as any).message, { type: "error" });
          },
        }}
      >
        <HealthMailDataGridWrapper>
          <Datagrid bulkActionButtons={false} >
            <TextField sortable={false} label="Case Number" source="caseNumber" />
            <DateField
              hideLabel
              label="Consultation Time"
              source="encounter.startTime"
            />
            <FunctionField
              sortable={false}
              label="Patient Name"
              render={renderGaiaPatientName}
            />
            <DateField
              hideLabel
              sortable={false}
              label="Date of Birth"
              source="patient.birthDate"
            />
            <TextField
              sortable={false}
              label="Postcode"
              source="patient.address.postalCode"
            />
            <CaseLinkButton onLinkClick={props.onLinkClick}/>
          </Datagrid>
        </HealthMailDataGridWrapper>
      </ListBase>
    </Stack>
  );
}