import { SxProps, Theme, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import React from "react";

export type Level = 1 | 2 | 3 | 4;

interface Props {
  children: React.ReactNode;
  level: Level;
  className?: string;
  sx?: SxProps<Theme>;
}

export function Heading(props: Props) {
  const { children, level, className, sx } = props;

  const variant = `h${level}` as Variant;

  return (
    <Typography variant={variant} className={className} sx={sx}>
      {children}
    </Typography>
  );
}
