type ErrorMessage = string;

interface VideoBookingValidation {
  appointmentId?: ErrorMessage;
  service?: ErrorMessage;
}

function validateForm(values: any) {
  const errors: VideoBookingValidation = {};

  if (!values.appointmentId) {
    errors.appointmentId = 
      "No appointment has been selected. Please choose an available appointment";
  }

  if (!values.service)
  {
    errors.service = "Please choose a service"
  }

  return errors;
}

export default validateForm;
