export const formatAddressValues = (
  addressComponents: google.maps.places.PlaceResult["address_components"]
) => {
  let premise = null;
  let streetNumber = null;
  let streetAddress = null;
  let locality = "";
  let postalCode = "";
  let countryCode = "";

  if (addressComponents) {
    for (const component of addressComponents) {
      const componentType = component.types[0];

      switch (componentType) {
        case "subpremise":
          premise = [component.long_name, premise].filter(Boolean).join(' ');
          break;
        case "premise":
          premise = [premise, component.long_name].filter(Boolean).join(' ');
          break;
        case "street_number":
          streetNumber = component.long_name;
          break;
        case "route":
          streetAddress = component.long_name;
          break;
        case "neighborhood":
          streetAddress = component.long_name;
          break;
        case "locality":
          locality = component.long_name;
          break;
        case "postal_town":
          locality = component.long_name;
          break;
        case "postal_code": {
          postalCode = component.long_name;
          break;
        }
        case "country":
          countryCode = component.short_name;
          break;
      }
    }

    streetAddress = [streetNumber, streetAddress].filter(Boolean).join(' ');

    streetAddress = [premise, streetAddress].filter(Boolean).join(', ');
  }

  return {
    address: (streetAddress ?? '').trim(),
    city: locality,
    postCode: postalCode,
    countryCode
  };
};
