import { baseUrl, create } from "..";

interface Params {
  id: string;
}

async function unassignWorkItem(params: Params) {
  const { id } = params;

  const unassignWorkItemEndpoint = `${baseUrl}/ms/workQueueItems/${id}/unassign`;

  await create(unassignWorkItemEndpoint, {});

  return {
    data: {
      id: "id-to-satisfy-ra",
    },
  };
}

export default unassignWorkItem;
