import { update } from "../index";


async function updateResourcePool(args: any) {
  const { id, type } = args.data;
  const { name, isEnabled, isGloballyAvailable, globallyAvailableDuration } = args.data.attributes;
  const endpoint = `/ms/resourcePools/${id}`;


  const body = {
    data: {
      type,
      id,
      attributes: {
        name,
        isEnabled,
        isGloballyAvailable,
        globallyAvailableDuration
      },
      relationships: {
        ...args.data.relationships,
        clinicalPractitioners: {
          data: args.data.relationships?.clinicalPractitioners.data.map((r: string) => {
            return {
              id: r
            };
          })
        }
      }
    }
  };

  return await update(endpoint, body);


}

export default updateResourcePool;
