import { GetOneParams, GetOneResult } from "ra-core";
import { get } from "..";

async function getAppointmentFilters(
  args: GetOneParams
): Promise<GetOneResult> {
  const { id } = args;
  const endpoint = `/ms/clients/${id}/appointmentFilters`;

  try {
    const res = await get(endpoint);
    const data = Array.isArray(res.data) ? res.data[0] : res.data;
    return {
      data,
    };
  } catch (err) {
    return {
      data: {
        attributes: {
          categories: [],
        },
        id,
      },
    };
  }
}

export default getAppointmentFilters;
