import { Grid } from "@mui/material";
import React from "react";
import { invalidFileExtensions } from "../../constants/constants";
import { FileImageField } from "../fields/FileImageField";
import { FileInput } from "../inputs/FileInput";

export function BookingAttachmentInput() {
  return (
    <Grid item xs={12}>
      <FileInput
        source="newAttachments"
        exclude={invalidFileExtensions}
        label="Booking Attachments"
        multiple
      >
        <FileImageField />
      </FileInput>
    </Grid>
  );
}
