import { UpdateParams } from "ra-core";
import { MessageDTO, MessageThreadDTO } from "../../types/messages";
import { update } from "../index";
import { getMessageThread } from "./index";

interface Body {
  id: string,
  attributes: MessageThreadDTO["data"]["attributes"],
  messages: MessageDTO["data"][]
}
async function updateMessageThread({ data }: UpdateParams<Body>) {
  const { messages } = data;

  if (messages) {
    const { id, attributes } = messages[0];
    const endpoint = `/ms/messageDoctors/${id}`
    const body = {
      data: {
        type: "messages",
        id: id,
        attributes: {
          payload: attributes.payload
        }
      }
    }

    update(endpoint, body);

    return getMessageThread({ id: data.id });
  }

}

export default updateMessageThread;
