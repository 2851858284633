import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Button, useRecordContext } from "react-admin";
import { useNavigate } from "react-router-dom";

export function QuickEditBookingButton() {
  const record = useRecordContext();
  const navigate = useNavigate();

  if (!record) return null;

  const bookingId = record.id;
  const clientId = record.clients[0].id;
  const userId = record.users[0].id;
  const url = `/patientRecords/${userId}/clients/${clientId}/bookings/${bookingId}/patient`;

  const handleClick = () => {
    navigate({ pathname: url, search: "state=edit" });
  };

  return (
    <Button
      onClick={e => {
        e.stopPropagation();
        handleClick();
      }}
      label="Edit"
      variant="outlined"
      size="medium"
    >
      <EditIcon />
    </Button>
  );
}
