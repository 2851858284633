import React from "react";
import { Group } from "../../layout/Group";
import { Heading } from "../../ui/Heading";
import {
  ChipField,
  Labeled,
  ReferenceOneField,
  TextField,
  useRecordContext,
} from "react-admin";
import { Link } from "react-router-dom";
import { EMPTY_TEXT } from "../../../constants/constants";
import { Grid, Typography, styled } from "@mui/material";
import { ArrayField, SingleFieldList } from "ra-ui-materialui";
import { Stack } from "../../layout/Stack";
import { ClinicalPractitionerGroup } from "./ClinicalPractitionerGroup";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

function FormFillLink() {
  const record = useRecordContext();
  const { id, identifier } = record;
  return (<StyledLink to={`/prescriptions/${id}/show`}>
    <Typography variant="body1">
      {identifier}
    </Typography>
  </StyledLink>)
}

export function FormFillConsultationActionRequiredFields() {
  return (
    <ReferenceOneField
      target="encounterId"
      source="attributes.caseReference"
      reference="prescriptions"
      link=""
    >
      <ClinicalPractitionerGroup source="actioningPractitionerId" />
      <Group
        heading={<Heading level={2}>Form Fill Consultation</Heading>}
        data-testid="ffp-group"
      >
        <Grid item xs={12} sm={6}>
          <Labeled label="Task">
            <FormFillLink />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Labeled label="Condition">
            <TextField
              source="condition"
              emptyText={EMPTY_TEXT}
            />
          </Labeled>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Labeled label="Summary">
            <TextField
              source="outcome"
              emptyText={EMPTY_TEXT}
            />
          </Labeled>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Labeled label="Medications">
            <ArrayField source="medicationRequests">
              <SingleFieldList sx={{ margin: "5px 0px", display: "block" }}>
                <Stack>
                  <ChipField clickable={false} label={false} source="medication" />
                </Stack>
              </SingleFieldList>
            </ArrayField>
          </Labeled>
        </Grid>
      </Group>
    </ReferenceOneField>
  );
}
