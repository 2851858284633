import React from "react";
import { TextField, Show, SimpleShowLayout } from "react-admin";
import { Actions } from "../Actions";
import { BooleanField } from "../fields/BooleanField";
import { PageTitle } from "../PageTitle";

export function ResourceShow() {
  return (
    <Show actions={<Actions />} title={<PageTitle />}>
      <SimpleShowLayout>
        <TextField source="attributes.email" label="Email" />
        <TextField source="attributes.userName" label="Username" />
        <TextField source="attributes.firstName" label="First name" />
        <TextField source="attributes.lastName" label="Last name" />
        <TextField
          source="attributes.phoneNumber"
          label="Phone number"
          emptyText="n/a"
        />
        <TextField source="role" label="Role" />
        <BooleanField
          valueLabelTrue="User Is Enabled"
          valueLabelFalse="User Is Not Enabled"
          source="attributes.isEnabled"
          label="Is Enabled"
        />
      </SimpleShowLayout>
    </Show>
  );
}
