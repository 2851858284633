import { useEffect, useState } from "react";

function useLazyLoadTab(isHidden: boolean) {
  const [shouldDisplay, setDisplayState] = useState(false);

  useEffect(() => {
    if (!isHidden) {
      return setDisplayState(true);
    }
  }, [isHidden]);

  return shouldDisplay;
}

export default useLazyLoadTab;
