import get from "lodash/get";
import React from "react";
import { TextFieldProps, useRecordContext, Labeled } from "react-admin";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";

interface Props extends TextFieldProps {
  label: string;
}

export function DurationField(props: Props) {
  const { label } = props;
  const record = useRecordContext(props);
  const startTime = get(record, "consultations[0].attributes.start");
  const endTime = get(record, "consultations[0].attributes.end");

  const difference = dayjs(endTime).diff(startTime, "m");

  return (
    <Labeled label={label}>
      <Typography component="span" variant="body1">
        {`${difference} minutes`}
      </Typography>
    </Labeled>
  );
}
