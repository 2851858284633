import { create } from ".";

interface Data {
  token: string;
  tokenId: string;
  password: string;
}

const apiUrl = "/resetPassword/complete";

async function changePassword(data: Data) {
  const { token, tokenId, password } = data;

  const requestBody = {
    data: {
      type: "passwordResets",
      attributes: {
        token,
        tokenId,
        password,
      },
    },
  };

  await create(apiUrl, requestBody);

  return {
    data: { id: "id-to-satisfy-ra" },
  };
}

export default changePassword;
