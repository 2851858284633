import { Grid } from "@mui/material";
import { required } from "ra-core";
import { Labeled, TextField, TextInput } from "ra-ui-materialui";
import React from "react";
import { NONE_SPECIFIED } from "../../../../constants/constants";
import { useEditStateContext } from "../../../../context/EditStateContext";
import { AddressField } from "../../../fields/AddressField";
import { EmailInput } from "../../../inputs/EmailInput";
import { PatientAddressForm } from "../../../inputs/PatientAddress/PatientAddressInput";
import { Group } from "../../../layout/Group";
import { Stack } from "../../../layout/Stack";
import { Heading } from "../../../ui/Heading";
import { ResetPasswordButton } from "../../ResetPasswordButton";

export function ContactDetails() {
  const { currentState } = useEditStateContext();

  if (currentState === "show") return <ShowContent />;

  return <EditContent />;
}

function ShowContent() {
  return (
    <Group heading={<Heading level={2}>Contact Details</Heading>}>
      <Grid xs={12} sm={6} item>
        <Stack>
          <Labeled label="Telephone">
            <TextField source="attributes.phoneNumber" />
          </Labeled>
          <Labeled label="Secondary Telephone">
            <TextField source="attributes.secondaryPhoneNumber" emptyText="Not Provided" />
          </Labeled>
          <Labeled label="Email">
            <TextField source="attributes.email" emptyText={NONE_SPECIFIED} />
          </Labeled>
          <ResetPasswordButton label="Reset Patient's Password" />
        </Stack>
      </Grid>

      <AddressField label="Address" source="attributes.address" />
    </Group>
  );
}

function EditContent() {
  return (
    <Group heading={<Heading level={2}>Contact Details</Heading>}>
      <Grid item xs={12} sm={6}>
        <Stack>
          <TextInput
            source="attributes.phoneNumber"
            label="Telephone"
            validate={required()}
          />
          <TextInput
            source="attributes.secondaryPhoneNumber"
            label="Secondary Telephone"
          />
          <EmailInput source="attributes.email" />
          <ResetPasswordButton label="Reset Patient's Password" />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <PatientAddressForm sourcePrefix="attributes.address" />
      </Grid>
    </Group>
  );
}
