import React, { useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useLogin, useNotify, Form } from "react-admin";
import { useSafeSetState } from "../../hooks/useSafeSetState";
import { AuthInput } from "../inputs/AuthInput";
import { FieldValues } from "react-hook-form";
import { Colors } from "../../theme";
import { Group } from "../layout/Group";
import { Stack } from "../layout/Stack";
import { Box } from "@mui/system";
import logo from "../../static/logo-red.svg";

const containerStyles = {
  "&": {
    marginTop: "125px",
    width: "450px",
    backgroundColor: `${Colors.White}dd`,
    padding: "24px",
  },
};

const iconStyles = {
  width: "16px",
  height: "16px",
  fill: Colors.Blue,
};

const imageContainerStyles = {
  display: "flex",
  justifyContent: "start",
  width: "100%"
};

interface FormData {
  username: string;
  password: string;
}

const validate = (values: FieldValues) => {
  const errors: Partial<FormData> = {};

  if (!values.username) {
    errors.username = "A username is required";
  }
  if (!values.password) {
    errors.password = "A password is required";
  }

  return errors;
};

function LoginForm() {
  const [loading, setLoading] = useSafeSetState(false);
  const [showPassword, setShowPassword] = useState(false);
  const login = useLogin();
  const notify = useNotify();

  const submit = (values: FieldValues) => {
    setLoading(true);

    login(values, "/")
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        notify(
          "We had trouble logging you in. Please check you've used the correct username and password"
        );
      });
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <Form onSubmit={submit} validate={validate} mode="onBlur">
      <Group sx={containerStyles}>
        <Stack sx={{ alignItems: "center", textAlign: "center" }}>
          <Box sx={imageContainerStyles}>
            <img src={logo} alt="Logo" height="100" />
          </Box>
          <Box sx={{ width: '100%', marginTop: 0 }}>
            <Typography sx={{ marginTop: 0, fontSize: '20px', fontWeight: 600, textAlign: 'left' }} variant="h1">
              Operations Portal
            </Typography>
          </Box>

          <Box sx={{ width: '100%' }}>
            <AuthInput
              name="username"
              label="Username"
              disabled={loading}
              startAdornment={<PersonOutlineOutlinedIcon sx={iconStyles} />}
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            <AuthInput
              name="password"
              label="Password"
              disabled={loading}
              startAdornment={<VpnKeyOutlinedIcon sx={iconStyles} />}
              endAdornment={
                <IconButton onClick={handleClickShowPassword} size="large">
                  {showPassword ? (
                    <VisibilityOffIcon sx={iconStyles} />
                  ) : (
                    <VisibilityIcon sx={iconStyles} />
                  )}
                </IconButton>
              }
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
            />
          </Box>

          <Box sx={{ width: '100%' }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading && <CircularProgress />}
              Log In
            </Button>
          </Box>

          <Box>
            <Typography variant="body1">
              Having problems with your username/password?
            </Typography>
          </Box>

          <Box>
            <Typography variant="body1">
              Please contact customer support for issues at +44 (0) 3000 240015
              or +44 (0) 78408 67491
            </Typography>
          </Box>

          <Box>
            <Button
              component={Link}
              variant="text"
              size="small"
              to="/reset-password"
            >
              Forgot your password?
            </Button>
          </Box>
        </Stack>
      </Group>
    </Form>
  );
}

export default LoginForm;
