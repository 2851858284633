import { Group } from "../layout/Group";
import { Heading } from "../ui/Heading";
import { ShowTabProps } from "../../types/utilities";
import { useRecordContext } from "react-admin";
import get from "lodash/get";
import { ClinicalSummaryQuestion } from "../../types/prescriptions";
import { Tab } from "../Tabs";
import { Typography } from "@mui/material";
import React from "react";
import { styles } from "./PrescriptionsShow";

export function FullDetailsTab(props: ShowTabProps) {
  const TRAVERSAL_ANSWER_DOB_ASSET_ID = 19063;
  const record = useRecordContext();
  const questions = get(record, "traversal.questions") as ClinicalSummaryQuestion[];

  if (!questions) {
   return (<Tab data-testid="empty-full-details-tab"  {...props} label="Full Details">
       <Typography>
         Details are not available for this prescription.
       </Typography>
     </Tab>
   )
  }

  const getAnswerFromQuestion = (question: ClinicalSummaryQuestion) => {
    const answers = question.answers.filter((answer) => answer.isAnswered);

    //format date
    if (answers[0]?.assetId == TRAVERSAL_ANSWER_DOB_ASSET_ID) {
      return new Date(answers[0]?.value as string).toLocaleDateString();
    }

    const answersTexts = answers.map((answer) => {
      if (answer.value && answer.value != answer.displayText)
        return `${answer.value} ${answer.displayText}`

      return answer.displayText
    });

    return answersTexts.join(",");
  }

  return (
    <Tab data-testid="full-details-tab"  {...props} label="Full Details">
      <Group
        heading={<Heading level={2}>Full Details</Heading>}
      >
        <Typography>Transcript of the online consultation as completed by the patient</Typography>
        {questions.map((question, questionsIndex) => (
            <div style={{width: "100%"}} key={questionsIndex} >
              <Typography dangerouslySetInnerHTML={{__html: question.displayText}} sx={styles.subCategoryHeader}/>
              <Typography dangerouslySetInnerHTML={{__html: getAnswerFromQuestion(question)}} sx={styles.summaryGroup}/>
            </div>
          )
        )}
      </Group>
    </Tab>
  )
}
