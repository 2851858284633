import { usePermissions, useRedirect, useSidebarState } from "react-admin";
import { useHotkeys } from "react-hotkeys-hook";
import { ROUTES } from "../constants/constants";
import { useShortcutsState } from "../context/useShortcutContext";
import get from "lodash/get";
import { Resource } from "../types/permissions";

function useShortcuts() {
  const redirect = useRedirect();
  const [areShortcutsEnabled] = useShortcutsState();
  const { permissions } = usePermissions();

  const createOptions = (resource?: Resource) => {
    const hasPermissions = get(permissions, `${resource}.enabled`, false);
    const enabled = areShortcutsEnabled && resource && hasPermissions;

    return { enabled };
  };
  const [open, setOpen] = useSidebarState();

  useHotkeys("d", () => redirect(ROUTES.dashboard), createOptions());

  useHotkeys(
    "p",
    () => redirect(ROUTES.patients),
    createOptions("patientRecords")
  );

  useHotkeys(
    "s",
    () => redirect(ROUTES.schedule),
    createOptions("adminAppointments")
  );

  useHotkeys("t", () => redirect(ROUTES.tasks), createOptions("workQueues"));

  useHotkeys(
    "g",
    () => redirect(ROUTES.clinicalPractitioners),
    createOptions("clinicalPractitioners")
  );

  useHotkeys(
    "a",
    () => redirect(ROUTES.adminAppointments),
    createOptions("adminAppointments")
  );

  useHotkeys("h", () => redirect(ROUTES.shifts), createOptions("shifts"));

  useHotkeys(
    "u",
    () => redirect(ROUTES.users),
    createOptions("resourceManagement")
  );

  useHotkeys("c", () => redirect(ROUTES.clients), createOptions("clients"));

  useHotkeys(
    "/",
    () => {
      setOpen(!open);
    },
    createOptions(),
    [open, setOpen]
  );

  return null;
}

export default useShortcuts;
