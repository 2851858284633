import { create } from "..";


interface Params {
  shiftIds: [];

}

async function importShiftsProcess(params: Params) {
  const endpoint = "/admin/shifts/process";

  await create(endpoint, params);

  return {
    data: {
      id: "id-to-satisfy-ra"
    }
  }

}


export default importShiftsProcess;
