import {
  FormControl,
  MenuItem,
  Select,
  SelectProps,
  InputLabel,
} from "@mui/material";
import React from "react";

const wrapperStyles = {
  display: "flex",
  width: "100%",
  marginTop: 1,
  marginBottom: 1 / 2,
};

const selectStyles = {
  ".MuiSelect-select": {
    paddingTop: "21px",
    paddingRight: "12px",
    paddingLeft: "12px",
    paddingBottom: "4px",
  },
};

interface Props extends SelectProps {
  choices: JSX.Element[];
  choicesMaxHeight?: number;
  inputLabel?: string;
}

export function AddressSelectInput(props: Props) {
  const { choices, choicesMaxHeight, inputLabel, ...rest } = props;

  return (
    <FormControl sx={wrapperStyles}>
      <InputLabel id="patient-address">{inputLabel ?? "Select an address"}</InputLabel>
      <Select
        {...rest}
        displayEmpty
        variant="filled"
        margin="dense"
        sx={selectStyles}
        MenuProps={{ PaperProps: { sx: { maxHeight: choicesMaxHeight ?? 500 } } }}
      >
        <MenuItem value="-" disabled>
          {choices.length} results
        </MenuItem>
        {choices}
      </Select>
    </FormControl>
  );
}
