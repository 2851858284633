import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Group } from "../../layout/Group";
import { Heading } from "../../ui/Heading";
import { Labeled, TextField } from "ra-ui-materialui";
import { AddressField } from "../../fields/AddressField";
import { PatientLinkField } from "../../booking/PatientLinkField";
import { DateField } from "../../fields/DateField";
import { CopyFieldToClipboard } from "../../buttons/CopyToClipboard";

export function PatientGroup() {
  return (
    <>
      <Group
        heading={<Heading level={2}>Patient</Heading>}
        data-testid="patient-group"
      >
        <Grid item xs={12} sm={6}>
          <Box>
            <PatientLinkField label="Patient Name" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateField label="Date of Birth" source="patients[0].attributes.dateOfBirth" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AddressField
            label="Patient address"
            source={`patients[0].attributes.address`}
            emptyText="-"
            paddingRight={"20px"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Labeled label="Patient phone">
            <TextField
              source={`patients[0].attributes.phoneNumber`}
              emptyText="-"
            />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Labeled label="Patient email">
            <Box>
              <TextField
                source={`patients[0].attributes.email`}
                emptyText="-"
              />
              <CopyFieldToClipboard source={`patients[0].attributes.email`} />
            </Box>
          </Labeled>
        </Grid>
      </Group>
    </>
  );
}
