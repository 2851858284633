import React from "react";
import {
  Create,
  RaRecord,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from "react-admin";
import { Stack } from "../layout/Stack";
import { Group } from "../layout/Group";
import { Heading } from "../ui/Heading";
import { Grid } from "@mui/material";
import { validateCreateGpSurgery } from "./validateCreateGpSurgery";


export function GpSurgeriesCreate() {
  const notify = useNotify();
  const redirect = useRedirect();

  const inputStyles = {
    marginBottom: 0,
  };

  return (
    <Create
      title="Create Gp Surgery"
      mutationOptions={{
        onSuccess: (newRecord: RaRecord) => {
          const id = newRecord.id;
          notify("Gp surgery was created successfully");
          redirect(`/gpSurgeries/${id}/show`);
        },
      }}
    >
      <SimpleForm
      mode="onBlur"
      // warnWhenUnsavedChanges
      validate={validateCreateGpSurgery}
      noValidate
      >
      <Stack>
        <Group heading={<Heading level={2}>Details</Heading>}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextInput
                source="name"
                label="Surgery Name"
                required
                fullWidth
              />
              <TextInput
                source="email"
                required
                fullWidth
                style={{ marginTop: "4px" }}
              />
            </Grid>
          </Grid>
        </Group>
        <Group heading={<Heading level={2}>Address</Heading>} >
          <Grid item xs={12} sm={6}>
              <TextInput
                sx={inputStyles}
                source="address.line[0]"
                label="Address Line One"
                required
                fullWidth
              />
              <TextInput
                sx={inputStyles}
                source="address.line[1]"
                label="Address Line Two"
                fullWidth
              />
              <TextInput
                sx={inputStyles}
                source="address.line[2]"
                label="Address Line Three"
                fullWidth
              />
              <TextInput
                sx={inputStyles}
                source="address.city"
                label="City"
                required
                fullWidth
              />
              <TextInput
                source="address.postalCode"
                sx={inputStyles}
                label="Post Code"
                required
                fullWidth
              />
          </Grid>
        </Group>
      </Stack>
    </SimpleForm>
    </Create>
  );
}
