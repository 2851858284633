import React from "react";
import { Admin, memoryStore } from "react-admin";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import theme from "./theme";
import LoginPage from "./components/auth/LoginPage";
import { Layout } from "./components/Layout";
import { Home } from "./components/Home";
import { Notification } from "./components/Notification";
import createResources from "./resources";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Admin
        layout={Layout}
        notification={Notification}
        disableTelemetry
        theme={theme}
        loginPage={LoginPage}
        dashboard={Home}
        authProvider={authProvider}
        dataProvider={dataProvider}
        store={memoryStore()}
      >
        {createResources}
      </Admin>
    </BrowserRouter>
  );
}

export default App;
