import { GetOneParams } from "react-admin";
import { get } from "../gaiaClient";
import { extractGuid } from "../../utilities/extractGuidFromFhirId";

export default async function getPharmacy(params: GetOneParams) {
  const { id } = params;

  const guid = extractGuid(id);

  const endpoint = `/pharmacies/${guid}`;

  const { data } = await get(endpoint);

  const formattedData = {
    ...data,
    id: extractGuid(data.id),
  };

  return { data: formattedData };
}
