import { RaRecord } from "ra-core";
import { FunctionField } from "ra-ui-materialui";
import React from "react";
import {
  contractQualificationLabelMap,
  NONE_SPECIFIED,
} from "../../constants/constants";
import { Qualification } from "../../types/clinicalPractitioners";
import get from "lodash/get";
import { FieldProps } from "react-admin";
import { QualificationItem } from "./QualificationItem";

export function CPQualificationField(props: FieldProps) {
  return (
    <FunctionField
      {...props}
      render={(record?: RaRecord) => {
        if (!record) return;

        const requiredQualifications =
          get(record, "contract.attributes.requiredQualifications") ?? [];

        const labels = requiredQualifications.map(
          (qualification: Qualification) => {
            return contractQualificationLabelMap[qualification];
          }
        );

        const imc = get(record, "attributes.imcgpRequired");
        const gmc = get(record, "attributes.gmcgpRequired");

        if (!labels.length && !imc && !gmc) {
          return NONE_SPECIFIED;
        }

        return (
          <>
            {labels.map((item: string) => {
              if (
                item === "IMC (General Practitioner)" ||
                item === "GMC (General Practitioner)"
              )
                return;

              return <div key={item}>{item}</div>;
            })}
            <QualificationItem
              source="attributes.imcgpRequired"
              description="IMC (General Practitioner)"
            />
            <QualificationItem
              source="attributes.gmcgpRequired"
              description="GMC (General Practitioner)"
            />
          </>
        );
      }}
    />
  );
}

CPQualificationField.defaultProps = {
  addLabel: true,
};
