import { useRecordContext } from "ra-core";
import {
  ArrayField,
  ButtonProps,
  Datagrid,
  FieldProps,
} from "ra-ui-materialui";
import React from "react";

import IgnoreIcon from "@mui/icons-material/VisibilityOff";
import UnIgnoreIcon from "@mui/icons-material/Visibility";

import get from "lodash/get";
import { Typography } from "@mui/material";
import { ApplyOperationButtonBase } from "./ApplyOperationButtonBase";
import { TimeRange } from "../fields/TimeRange";
import { StatusField } from "./StatusField";
import { Heading } from "../ui/Heading";
import { EmptyGrid } from "./EmptyGrid";
import DateField from "../fields/DateField";

interface ActionButtonProps extends ButtonProps {
  id: string;
  appointmentId: string;
  disabled?: boolean;
}

function IgnoreAppointmentButton(props: ActionButtonProps) {
  return (
    <ApplyOperationButtonBase
      id={props.id}
      appointmentId={props.appointmentId}
      disabled={props.disabled}
      actionKey="ignoreAppointments"
      shouldAdd
      icon={<IgnoreIcon />}
      label="Ignore"
    />
  );
}

function UnIgnoreAppointmentButton(props: ActionButtonProps) {
  return (
    <ApplyOperationButtonBase
      id={props.id}
      appointmentId={props.appointmentId}
      disabled={props.disabled}
      actionKey="ignoreAppointments"
      shouldAdd={false}
      icon={<UnIgnoreIcon />}
      label="Un-ignore"
    />
  );
}

interface ActionProps extends FieldProps {
  shiftId: string;
}

function Actions(props: ActionProps) {
  const { shiftId } = props;
  const record = useRecordContext();

  const appointmentId = String(get(record, "id", ""));
  const isIgnored = get(record, "attributes.isIgnored");

  return (
    <div>
      {isIgnored ? (
        <UnIgnoreAppointmentButton
          id={shiftId}
          appointmentId={appointmentId}
          disabled={false}
        />
      ) : (
        <IgnoreAppointmentButton
          id={shiftId}
          appointmentId={appointmentId}
          disabled={false}
        />
      )}
    </div>
  );
}

export function ProposedAppointments() {
  const record = useRecordContext();
  const shiftId = record?.id ?? "";

  return (
    <>
      <Heading level={2}>Processing Shift</Heading>
      <Typography variant="body1">
        Confirm your shift once you've completed your shift changes.
      </Typography>

      <ArrayField source="validProposedAppointments">
        <Datagrid bulkActionButtons={false} optimized empty={<EmptyGrid />}>
          <TimeRange
            label="Apt. Slot"
            startSource="attributes.start"
            endSource="attributes.end"
          />

          <DateField hideLabel label="Date" source="attributes.start" />
          <StatusField label="Status" />
          <Actions shiftId={`${shiftId}`} label="Action" />
        </Datagrid>
      </ArrayField>
    </>
  );
}
