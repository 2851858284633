import React from "react";
import { TextField, Labeled } from "react-admin";
import { Group } from "../../../layout/Group";
import { Heading } from "../../../ui/Heading";

export function Employer() {
  return <ShowContent />;
}

function ShowContent() {
  return (
    <Group heading={<Heading level={2}>Employer</Heading>}>
      <Labeled label="Employer">
        <TextField source="attributes.employer.name" emptyText="n/a" />
      </Labeled>
    </Group>
  );
}
