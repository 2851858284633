import React from "react";
import {
  sanitizeFieldRestProps,
  TextFieldProps,
  useRecordContext,
} from "react-admin";
import Typography from "@mui/material/Typography";
import get from "lodash/get";

interface Props extends TextFieldProps {
  source: string;
}

export function CountField(props: Props) {
  const { source, ...rest } = props;

  const record = useRecordContext();
  if (!record) return null;

  const value = get(record, source, []);

  if (!Array.isArray(value)) return null;

  const count = value.length;

  return (
    <Typography variant="body1" {...sanitizeFieldRestProps(rest)}>
      {count}
    </Typography>
  );
}
