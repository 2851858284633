import pureSplice from "../../utilities/pureSplice";

type ErrorMessage = string;

interface ResourcePoolsError {
  attributes: {
    specialty?: ErrorMessage;
    globallyAvailableDuration?: ErrorMessage;
    isGloballyAvailable?: ErrorMessage;
  };
}

interface Errors {
  resourcePools: ResourcePoolsError[];
}

interface Values {
  resourcePools: {
    attributes: {
      specialty: string;
      isGloballyAvailable: boolean;
      globallyAvailableDuration: string;
    };
  }[];
}

function validateResourcePools(values: Values) {
  const { resourcePools } = values;
  const errors: Errors = {
    resourcePools: [],
  };

  if (resourcePools) {
    resourcePools.forEach((pool, i: number) => {
      if (!pool) return;

      const error: ResourcePoolsError = {
        attributes: {},
      };

      if (!pool.attributes?.specialty) {
        error.attributes.specialty = "Please select a specialty";
      }

      if (pool.attributes?.specialty) {
        const specialty = pool.attributes?.specialty;
        const previousResourcePools = pureSplice(resourcePools, i);

        const poolExists = previousResourcePools.find(
          ({ attributes }) => attributes.specialty === specialty
        );

        if (poolExists) {
          error.attributes.specialty =
            "A pool already exists with this specialty.";
        }
      }

      if (error.attributes?.specialty) {
        errors.resourcePools = [];
        errors.resourcePools[i] = error;
      }

      if (pool.attributes?.isGloballyAvailable) {
        const duration = parseInt(pool.attributes?.globallyAvailableDuration);
        const isNumber = !isNaN(duration);

        if (!isNumber) {
          error.attributes.globallyAvailableDuration =
            "The release duration must be a number";
        }

        if (duration < 60) {
          error.attributes.globallyAvailableDuration =
            "The minimum release duration is 60 minutes";
        }

        if (!pool.attributes?.globallyAvailableDuration) {
          error.attributes.globallyAvailableDuration =
            "Please enter a release duration";
        }

        errors.resourcePools[i] = error;
      }

      errors.resourcePools[i] = error;
    });
  }
  return errors;
}

export default validateResourcePools;
