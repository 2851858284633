import { create } from "..";

interface Params {
  id: string;
  assignee?: string;
}

async function assignWorkItem(params: Params) {
  const { id, assignee } = params;

  const assignId = assignee ? `assign/${assignee}` : "assign";
  const assignWorkItemEndpoint = `/ms/workQueueItems/${id}/${assignId}`;

  await create(assignWorkItemEndpoint);

  return {
    data: {
      id: "id-to-satisfy-ra",
    },
  };
}

export default assignWorkItem;
