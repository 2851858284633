import { get } from "../gaiaClient";
import { GetOneParams } from "ra-core";

async function getFulfillmentDetails(params: GetOneParams) {
    const { id } = params;

    const endpoint = `encounters/${id}/fulfilments`;

    const response = await get(endpoint);

    return {
        data: {
            id: "id-to-satisfy-ra",
            fulfillments: response.data.fulfilments
        }
    };
}

export default getFulfillmentDetails;
