import { Identifier } from "ra-core";
import { list } from "..";

interface Args {
  clientId: Identifier;
}

async function getClientEmployers(args: Args) {
  const { clientId } = args;

  const endpoint = `/ms/employers?filter[clientId]=${clientId}&sort=name`;
  const { data, total } = await list(endpoint);

  return { data, total };
}

export default getClientEmployers;
