import { Box, Typography } from "@mui/material";
import React from "react";
import { InputProps, TextInput } from "react-admin";
import { useWatch } from "react-hook-form";
import { NONE_SPECIFIED } from "../../constants/constants";

const wrapperStyles = {
  display: "flex",
  gap: "8px",
  alignItems: "baseline",
};

const styles = {
  inputStyles: {
    width: 100,
  },
};

// We've been saving hex colour values without "#" up until now, so we should keep it that way
const parse = (val: string | null) => {
  if (!val) return null;

  return val.replace(/#/g, "");
};

const format = (val: string | null) => {
  if (!val) return "";

  if (!val.match(/^#/)) {
    return `#${val}`;
  }

  return val;
};

export function ColorInput(props: InputProps) {
  const value = useWatch({ name: props.source });

  return (
    <Box sx={wrapperStyles}>
      <TextInput
        parse={parse}
        format={format}
        sx={styles.inputStyles}
        type="color"
        {...props}
      />
      <Typography variant="body1">
        {value ? `#${value}` : NONE_SPECIFIED}
      </Typography>
    </Box>
  );
}
