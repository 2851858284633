import { GetOneParams } from "ra-core";
import { v4 as uuid } from "uuid";
import { FormFillPrescriptionTask } from "../../types/prescriptions";
import { get } from "../gaiaClient";
import getClinicalReport from "./getClinicalReport";
import getClinicalSummaryQuestions from "./getClinicalSummaryQuestions";
import getConclusionReport from "./getConclusionReport";
import getClinicalNotes from "./getClinicalNotes";
import getConversationsWithMessages from "./getConversationsWithMessages";

const getOnePrescription = async (params: GetOneParams) => {
  const { id } = params;

  const queueName = "form-fill-prescription-queue";
  const prescriptionEndpoint = `queues/${queueName}/tasks/${id}`;
  const { data }: {data: FormFillPrescriptionTask} = await get(prescriptionEndpoint, {
    headers: {
      "x-correlation-id": uuid(),
    }
  })

  const requestArgs = {
    tenantId: data.tenantId,
    traversalId: data.traversalId
  }

  const getClinicalReportPromise = getClinicalReport(requestArgs);
  const getClinicalSummaryQuestionsPromise = getClinicalSummaryQuestions(requestArgs);
  const getConclusionReportPromise = getConclusionReport(requestArgs);
  const clinicalNotesPromise = getClinicalNotes({encounterId: data.encounterId});
  const conversationsPromise = getConversationsWithMessages({encounterId: data.encounterId});

  const [
    clinicalReport,
    clinicalSummaryQuestion,
    conclusionReport,
    clinicalNotes,
    conversations
  ] = await Promise.all([
    getClinicalReportPromise,
    getClinicalSummaryQuestionsPromise,
    getConclusionReportPromise,
    clinicalNotesPromise,
    conversationsPromise
  ]);

  const result = {
    ...data,
    traversal: {
      clinicalReport: clinicalReport.data,
      questions: clinicalSummaryQuestion.data,
      conclusionReport: conclusionReport.data
    },
    clinicalNotes,
    conversations
  }

  return { data: result }
}

export default getOnePrescription;
