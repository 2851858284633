import { Typography } from "@mui/material";
import React from "react";

const keyboardStyles = {
  minWidth: 8,
  minHeight: 8,
  padding: "2px 6px",
  backgroundColor: "grey.300",
  textAlign: "center",
  border: 1,
  borderColor: "grey.500",
  borderRadius: 1,
  display: "inline-block",
  margin: 1,
};

export function KeyboardKey(props: { char: string }) {
  return (
    <Typography variant="body1" component="span" sx={keyboardStyles}>
      {props.char}
    </Typography>
  );
}
