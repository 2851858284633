import { v4 as uuid } from "uuid";
import { create } from "..";
import { FeatureFlagService } from "../../featureFlags";
import { ShiftDTO } from "../../types/shifts";
import createAppointments from "./createAppointments";

type Args = ShiftDTO["data"]["attributes"] & {
  clinicalPractitioner: string;
};

const endpoint = "/ms/resourceManagement/shifts?include=clinicalService";

async function createShift(args: Args) {
  const externalId = uuid();

  const { clinicalPractitioner: clinicalPractitionerId, ...attributes } = args;

  const body = {
    data: {
      type: "shifts",
      attributes: {
        ...attributes,
        shiftSource: "Manual",
        shiftCategory: "n/a",
        externalId,
      },
      relationships: {
        clinicalPractitioner: {
          data: {
            type: "clinicalPractitioners",
            id: clinicalPractitionerId,
          },
        },
      },
    },
  };

  const res = await create(endpoint, body);

  const featureFlags = FeatureFlagService.getInstance();
  const shouldSkipProposedShiftsFlow = featureFlags.getFlag(
    "ed-3-skip-proposed-shifts-flow"
  );

  const hasAsyncClinicalService = res.data.clinicalServices?.[0]?.attributes.deliveryType === "Asynchronous";

  if (shouldSkipProposedShiftsFlow && !hasAsyncClinicalService) {
    const confirmShiftRes = await createAppointments({ id: res.data.id });

    return confirmShiftRes;
  }

  return res;
}

export default createShift;
