import React, { ChangeEvent, useState } from "react";
import { useCreate, useCreateSuggestionContext, useNotify } from "react-admin";
import { Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import { Button } from "../../buttons/Button";

const inputStyles = {
  width: "260px",
};

const dialogBoxStyles = {
  display: "flex",
  flexDirection: "column",
};

interface CreateClientCodeModalProps {
  relatedPmsType: string;
}

export const CreateNewClientCodeModal = (props: CreateClientCodeModalProps) => {
  const { relatedPmsType } = props;
  const { onCancel, filter, onCreate } = useCreateSuggestionContext();
  const [newMappingCode, setNewMappingCode] = useState(filter);

  const notify = useNotify();

  const [create] = useCreate("clientCodes");

  const handleCreate = () => {
    create(
      "clientCodes",
      {
        data: {
          attributes: {
            code: newMappingCode,
            patientManagementSystem: relatedPmsType,
          },
        },
      },
      {
        onSuccess: data => {
          onCreate(data);
          notify("Code mapped successfully");
        },
        onError: (error: unknown) => {
          notify((error as Error).message);
        },
      }
    );
  };

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    return setNewMappingCode(e.target.value);
  };

  return (
    <Dialog open onClose={onCancel} data-cy="adastra-mapping-dialog">
      <DialogContent sx={dialogBoxStyles}>
        <TextField
          label="New Mapping Code"
          value={newMappingCode}
          onChange={handleCodeChange}
          name="code"
          sx={inputStyles}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>
        <Button
          aria-label="Create"
          onClick={handleCreate}
          disabled={!newMappingCode}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
