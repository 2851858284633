import React, { useMemo } from "react";
import { DateInput } from "../inputs/DateInput";
import getCurrentDate from "../../utilities/getCurrentDate";

const inputStyles = {
  display: "block",
  width: "260px",
  "& > div": {
    width: "260px",
  },
};

export function BookingDate() {
  const defaultMinDate = useMemo(() => getCurrentDate(), []);

  return (
    <DateInput
      sx={inputStyles}
      disablePast
      defaultValue={defaultMinDate}
      label="Appointment date"
      source="selectedDate"
    />
  );
}
