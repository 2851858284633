import {
  ClinicalPractitionerAttachmentsDTO,
  ClinicalPractitionerDocuments,
  AdminClinicalPractitionerDTO,
  ClinicalPractitionerRegistrationsDTO,
} from "../../types/clinicalPractitioners";
import updateExitingClinicalPractitionerDocuments from "./updateExitingClinicalPractitionerDocuments";
import uploadClinicalPractitionerDocuments from "./uploadClinicalPractitionerDocuments";
import get from "lodash/get";
import { ResourceManagementRulesDTO } from "../../types/resources";
import createResourceRules from "./createResourceRules";
import updateResourceRules from "./updateResourceRules";
import { UpdateParams } from "ra-core";
import { update } from "../gaiaClient";
import { CPRegistrations, formatClinicalPractitioner } from "./helpers/formatClinicalPractitioner";
import updateRegistrations from "./updateRegistrations";

interface Body {
  id: string;
  attributes: AdminClinicalPractitionerDTO["data"]["attributes"];
  expertiseAreas: string[];
  cpDocuments: ClinicalPractitionerDocuments[];
  clinicalPractitionerAttachments: ClinicalPractitionerAttachmentsDTO[];
  resourceManagementRules?: ResourceManagementRulesDTO["data"][];
  cpRegistrations: CPRegistrations;
  clinicalPractitionerRegistrations: ClinicalPractitionerRegistrationsDTO[];
}

async function updateClinicalPractitioner(params: UpdateParams<Body>) {
  const {
    attributes,
    id,
    expertiseAreas,
    cpDocuments,
    clinicalPractitionerAttachments,
    resourceManagementRules = [],
    clinicalPractitionerRegistrations,
    cpRegistrations
  } = params.data as Body;

  const userId = attributes.identityUserId;
  const rules = resourceManagementRules[0];

  await updateRegistrations(
    id,
    clinicalPractitionerRegistrations,
    cpRegistrations);

  const exitingClinicalPractitionerDocuments = get(
    params.previousData,
    "clinicalPractitionerAttachments",
    []
  );

  updateExitingClinicalPractitionerDocuments({
    clinicalPractitionerAttachments,
    exitingClinicalPractitionerDocuments,
    clinicalPractitionerId: id,
  });

  if (cpDocuments) {
    await uploadClinicalPractitionerDocuments({
      documents: cpDocuments,
      userId,
      id,
    });
  }

  const url = `/ms/clinicalPractitioners/${id}?include=expertiseAreas`;

  if (rules) {
    const { id: resourceRulesId } = rules;
    const rulesArgs = {
      clinicalPractitionerId: id,
      id: resourceRulesId!,
      ...rules.attributes,
    };

    if (resourceRulesId) {
      await updateResourceRules(rulesArgs);
    } else {
      await createResourceRules(rulesArgs);
    }
  }

  delete attributes.fullName;

  const updateCPBody = {
    data: {
      type: "adminClinicalPractitioners",
      id,
      attributes,
      relationships: {
        expertiseAreas: {
          data: expertiseAreas.map(area => ({
            id: area,
            type: "expertiseAreas",
          })),
        }
      },
    },
  };

  const { data } = await update(url, updateCPBody, undefined, true);
  return Promise.resolve(formatClinicalPractitioner(data[0] || data));
}

export default updateClinicalPractitioner;
