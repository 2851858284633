import { remove } from "..";
import { FollowUpActionDTO } from "../../types/workQueues";

interface Args {
  clientId: string;
  userId: string;
  outputs: FollowUpActionDTO[];
  existingOutputs: FollowUpActionDTO[];
  consultationId: string;
}

async function updateExistingOutputAttachments(args: Args) {
  const { clientId, userId, outputs, consultationId, existingOutputs } = args;

  const outputIds = outputs.map(({ id }) => id);
  const baseEndpoint = `/clients/${clientId}/users/${userId}/consultations/${consultationId}/consultationOutputs`;
  const toRemove = existingOutputs.filter(({ id }) => !outputIds.includes(id));

  const requests = toRemove.map(({ id }) => {
    const outputEndpoint = `${baseEndpoint}/${id}`;
    return remove(outputEndpoint);
  });

  try {
    await Promise.all(requests);
  } catch (err) {
    console.log(err);
  }

  return {};
}

export default updateExistingOutputAttachments;
