export function passwordStrengthCalculator(password: string) {
  const rulePercentage = 100 / 6;

  const hasTwelveCharacters = /.{12,}/.test(password);
  const hasUppercase = /^(?=.*[A-Z]+)/g.test(password);
  const hasLetterAndLowercase = /^(?=.*[a-z]+)/g.test(password);
  const hasNumber = /^(?=.*[0-9]+)/g.test(password);
  const hasSymbol = /^(?=.*[$*@#&!'£%^?"]+)/g.test(password);

  const passwordLengthPercentage = hasTwelveCharacters ? rulePercentage : 0;
  const uppercasePercentage = hasUppercase ? rulePercentage : 0;
  // The letter and lower case are two separate rules so we multiple the rule percentage
  const letterAndLowercase = hasLetterAndLowercase ? rulePercentage * 2 : 0;
  const numberPercentage = hasNumber ? rulePercentage : 0;
  const symbolPercetnage = hasSymbol ? rulePercentage : 0;

  const total =
    passwordLengthPercentage +
    uppercasePercentage +
    letterAndLowercase +
    numberPercentage +
    symbolPercetnage;

  return Math.round(total);
}
