import { create } from "..";

interface Args {
  userId: string;
  appointmentId: string;
  bookingId: string;
  clientId: string;
}

async function rescheduleBooking(args: Args) {
  const { userId, appointmentId, bookingId, clientId } = args;
  const endpoint = `/clients/${clientId}/users/${userId}/bookings/${bookingId}/rebook/${appointmentId}`;

  const { data } = await create(endpoint);

  return {
    data,
  };
}

export default rescheduleBooking;
