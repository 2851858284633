import { FieldValues } from "react-hook-form";

interface FormData {
  password: string;
  confirmPassword: string;
}

type Errors = Partial<FormData>;

const passwordRegex = new RegExp(
  "^(?=.{12,})(?=.*[A-Z]+)(?=.*[a-z]+)(?=.*[0-9]+)(?=.*[$*@#&!'£%^?\"]+)",
  "g"
);

function validatePasswordChange(values: FieldValues) {
  const errors: Errors = {};

  const hasTwelveCharacters = /.{12,}/.test(values.password);
  const hasUpperCase = /^(?=.*[A-Z]+)/g.test(values.password);
  const hasNumber = /^(?=.*[0-9]+)/g.test(values.password);
  const hasSymbol = /^(?=.*[$*@#&!'£%^?"]+)/g.test(values.password);

  if (!values.password) {
    errors.password = "Please enter a new password";
  }

  if (values.password && !passwordRegex.test(values.password)) {
    errors.password = "Your password is not strong enough. ";
  }

  if (values.password && !hasTwelveCharacters) {
    errors.password += "It needs to be longer. ";
  }

  if (values.password && !hasUpperCase) {
    errors.password += "It needs an uppercase letter. ";
  }

  if (values.password && !hasNumber) {
    errors.password += "It needs a number. ";
  }

  if (values.password && !hasSymbol) {
    errors.password += "It needs a symbol. ";
  }

  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = "Please ensure both passwords match";
  }

  return errors;
}

export default validatePasswordChange;
