import React, { useState } from "react";
import {
  Button,
  Confirm,
  useNotify,
  useCreate,
  useRefresh,
  useRecordContext,
} from "react-admin";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export function CloseThreadButton() {
  const data = useRecordContext();

  const { id = "" } = data;
  const isClosed = data?.attributes?.status === "Closed";

  const [isDialogOpen, setDialogOpenState] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const [closeThread, { isLoading }] = useCreate();

  const handleClose = () => {
    closeThread(
      "closeThread",
      {
        data: {
          id: id,
        },
      },
      {
        onSuccess: () => {
          setDialogOpenState(false);
          notify("The thread has been closed");
          refresh();
        },
        onError: () => {
          notify("There was a problem closing this thread");
        },
      }
    );
  };

  return (
    <>
      <Confirm
        isOpen={isDialogOpen}
        title="Close Thread"
        content="Are you sure you want to close this thread?"
        confirm="Yes"
        confirmColor="primary"
        cancel="Cancel"
        onConfirm={handleClose}
        onClose={() => setDialogOpenState(false)}
      />
      <Button
        disabled={isLoading || isClosed}
        color="secondary"
        label="Close Thread"
        sx={{ marginLeft: 2 }}
        onClick={() => setDialogOpenState(true)}
      >
        <DeleteForeverIcon />
      </Button>
    </>
  );
}
