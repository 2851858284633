import { GetListParams } from "ra-core";
import { list } from "..";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

async function listQuinyxResources(params: GetListParams) {
  const quinyxResourcesEndpoint =
    "/ms/resourceManagement/quinyxClinicalPractitioners";

  const { pagination } = params;
  const { page, perPage } = pagination;
  const { filter } = params;

  const { firstName, lastName, badgeNumber, clinicalPractitionerId } = filter;
  const { id } = filter;

  const filterParams = {
    "filter[firstName]": firstName ? `like:${firstName}` : "",
    "filter[lastName]": lastName ? `like:${lastName}` : "",
    "filter[badgeNumber]": badgeNumber,
    "filter[id]": id,
    "filter[clinicalPractitioner.id]": clinicalPractitionerId,
  };

  const pageParams = {
    "page[number]": page,
    "page[size]": perPage,
  };

  const include = "clinicalPractitioner"

  const queryParams = {
    ...pageParams,
    ...filterParams,
    include
  };

  const filteredParams = shallowlyRemoveEmptyValues(queryParams);
  const { data, total } = await list(quinyxResourcesEndpoint, {
    params: filteredParams,
  });

  return { data, total };
}

export default listQuinyxResources;
