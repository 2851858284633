import { create } from "..";
import { NotificationSettingsDTO } from "../../types/notifications";

interface Args {
  clientId: string;
  settings: NotificationSettingsDTO["data"]["attributes"];
}

export async function createNotificationSettings(args: Args) {
  const { clientId, settings } = args;
  const endpoint = `/ms/clients/${clientId}/notificationSettings`;

  const createSettingsBody = {
    data: {
      type: "notificationSettings",
      attributes: settings,
    },
  };

  await create(endpoint, createSettingsBody);
}
