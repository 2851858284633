import { ChannelSettingsDTO } from "../../../types/notifications";
import nonNullable from "../../../utilities/nonNullable";

function formatNotificationChannels(
  existingNotificationChannelSettings: ChannelSettingsDTO["data"][] = []
) {
  const notificationChannelSettings = existingNotificationChannelSettings
    .map(({ attributes }) => {
      if (attributes.isEnabled) {
        return attributes.notificationChannel;
      }
    })
    .filter(nonNullable);

  return {
    existingNotificationChannelSettings,
    notificationChannelSettings,
  };
}

export default formatNotificationChannels;
