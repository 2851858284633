import { GetOneParams } from "ra-core";
import { list } from "../gaiaClient";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";
import { ConsultationDocumentOutputResponse } from "../../types/gaia";

async function getDocument(params: GetOneParams) {
  const { id } = params;

  const [caseRef, startDate, endDate] = String(id).split("__");

  const queryParams = {
    startDate,
    endDate,
  };

  const filteredParams = shallowlyRemoveEmptyValues(queryParams);

  const endpoint = `consultation-output/document-info/${caseRef}`;

  const { data }: ConsultationDocumentOutputResponse = await list(endpoint, {
    params: filteredParams,
  });

  const output = data.consultationDocumentOutputs[0];

  const newDocs = output.documents.map(({ type, ...rest }) => {
    return {
      ...rest,
      type: type ?? "doc",
    };
  });

  const formattedData = {
    ...output,
    id,
    documents: newDocs,
    caseRef,
  };

  return {
    data: formattedData,
  };
}

export default getDocument;
