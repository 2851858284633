import { ClinicalProductsList } from "./ClinicalProductsList";
import Icon from '@mui/icons-material/Sell';
import { ClinicalProductsShow } from "./ClinicalProductsShow";
import { ClinicalProductsCreate } from "./ClinicalProductsCreate";
import { ClinicalProductsEdit } from "./ClinicalProductsEdit";
import { TOGGLE_ENABLE_CLINICAL_PRODUCTS } from "../../featureFlags/flags";

export const productResources = {
  name: 'clinicalProducts',
  options: {
    label: 'Clinical Products',
    flagKey: {
      name: TOGGLE_ENABLE_CLINICAL_PRODUCTS
    },
  },
  icon: Icon,
  list: ClinicalProductsList,
  show: ClinicalProductsShow,
  create: ClinicalProductsCreate,
  edit: ClinicalProductsEdit
}