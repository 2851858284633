import { Grid } from "@mui/material";
import { FormDataConsumer, RaRecord } from "ra-core";
import {
  BooleanInput,
  RadioButtonGroupInput,
  ReferenceInput,
  TextInput,
} from "ra-ui-materialui";
import React from "react";
import { genderChoices } from "../../../constants/constants";
import {
  clientIdentifierMessage,
  dependantMessage,
} from "../../../constants/tooltipMessages";
import {
  booleanInputAdornmentStyles,
  textInputAdornmentStyles,
} from "../../../theme";
import TooltipAdornment from "../../booking/TooltipAdornment";
import { AdornedField } from "../../fields/AdornedField";
import AutocompleteInput from "../../inputs/AutocompleteInput";
import { DateInput } from "../../inputs/DateInput";
import { Group } from "../../layout/Group";
import { Heading } from "../../ui/Heading";
import { useGetOne } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { ClientEmployerInput } from "../../inputs/ClientEmployerInput";

const inputStyles = {
  display: "block",
  "& > div": {
    width: "260px",
  },
};

interface PatientCreatePersonalInfoGroupProps {
  onChangeClient: (clientId: string) => void;
}

export function PatientCreatePersonalInfoGroup(props: PatientCreatePersonalInfoGroupProps) {
  const { onChangeClient } = props;
  const { setValue } = useFormContext();
  const clientId = useWatch({ name: "clientId" } )
  useGetOne("clients", { id: clientId }, {
    onSuccess: (data) => {
      setValue("clientIdentifierRequired", data?.attributes?.clientIdentifierRequired);
    }
  });

  return (
    <Group heading={<Heading level={2}>Personal Information</Heading>}>
      <Grid container>
        <Grid item xs={6}>
          <AdornedField
            style={{ width: "fit-content" }}
            source="isDependant"
            label="Is a Dependant"
            Field={BooleanInput}
            addLabel={false}
            adornment={
              <TooltipAdornment
                sx={booleanInputAdornmentStyles}
                text={dependantMessage}
              />
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (!formData.isDependant) {
                return;
              }

              return (
                <ReferenceInput
                  {...rest}
                  source="dependantOf"
                  reference="patientRecords"
                >
                  <AutocompleteInput
                    label="Dependant of"
                    source="id"
                    filterToQuery={query => ({
                      email: query,
                    })}
                    placeholder="Search by email..."
                    optionText={(record: RaRecord) => {
                      if (!record) return "";

                      return record.attributes.email;
                    }}
                  />
                </ReferenceInput>
              );
            }}
          </FormDataConsumer>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <ReferenceInput
                {...rest}
                disabled={formData.isDependant}
                source="clientId"
                reference="clients"
                sort={{ field: "id", order: "ASC" }}
              >
                 <span style={{ display: "inline-block" }}>
                  <AutocompleteInput
                    label="Client Contract"
                    filterToQuery={query => ({ name: query })}
                    placeholder="Search by contract name..."
                    optionText="attributes.name"
                    required
                    onChange={onChangeClient}
                  />
                 </span>
              </ReferenceInput>
            );
          }}
        </FormDataConsumer>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ClientEmployerInput
          clientId={clientId}
          source="employerId"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="firstName"
          label="First Name"
          required
          sx={inputStyles}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="lastName"
          label="Last Name"
          required
          sx={inputStyles}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateInput
          label="Date of Birth"
          source="dateOfBirth"
          disableFuture
          required
          sx={inputStyles}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <RadioButtonGroupInput
          required
          source="gender"
          choices={genderChoices}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.isDependant || !formData.clientIdentifierRequired) {
              return;
            }

            return (
              <AdornedField
                required
                Field={TextInput}
                source="clientIdentifier"
                label="Policy Number"
                addLabel={false}
                adornment={
                  <TooltipAdornment
                    sx={textInputAdornmentStyles}
                    text={clientIdentifierMessage}
                  />
                }
                sx={inputStyles}
              />
            );
          }}
        </FormDataConsumer>
      </Grid>
    </Group>
  );
}
