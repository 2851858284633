import { styled } from "@mui/material";
import get from "lodash/get";
import { FieldWithPermission } from "ra-access-control-lists";
import React from "react";
import { FormDataConsumer, Labeled, useRecordContext } from "react-admin";
import { DownloadsFieldV2 } from "../../../fields/DownloadsFieldV2";
import { EmptyLabeledText } from "../../../fields/EmptyLabelledTextField";
import { FileImageField } from "../../../fields/FileImageField";
import { SimpleFormIterator } from "../../../form/SimpleFormIterator";
import { ArrayInput } from "../../../inputs/ArrayInput";

export function ConsultationRecordingShow() {
  const downloadSources = {
    clientId: "users[0].attributes.clientId",
    fileId: "relationships.file.data.id",
    userId: "users[0].id",
    fileName: "attributes.description",
  };

  return (
    <FormDataConsumer>
      {({ formData }) => {
        if (!formData) return;

        const consultationRecordings = get(
          formData,
          "consultations[0].recordings"
        );
        if (!consultationRecordings) {
          return (
            <EmptyLabeledText
              label="Consultation Recording"
              text="No Recordings"
            />
          );
        }

        return (
          <Labeled label="Consultation Recording">
            <FieldWithPermission
              options={{
                resource: "recordings",
                permission: "show",
              }}
              Input={DownloadsFieldV2}
              inputProps={{
                downloadSources,
                label: "Name",
                nameSource: "attributes.description",
                titleValue: "Recording",
                titleSource: "",
                source:"consultations[0].recordings"
              }}
            />
          </Labeled>
        );
      }}
    </FormDataConsumer>
  );
}

const StyledSimpleFormIterator = styled(SimpleFormIterator)(({ theme }) => ({
  "& > li": {
    margin: `${theme.spacing(1)} 0`,
    borderBottom: "none",
    "& > section + span:last-of-type": {
      display: "flex",
      alignItems: "center",
      paddingTop: "0",
    },
  },
}));

export function ConsultationRecordingEdit() {
  const record = useRecordContext();

  const status = get(record, "attributes.status");

  const isAttachmentsDisabled = status !== "PendingConsultation";

  const consultationRecordings = get(record, "consultations[0].recordings");

  if (!consultationRecordings) {
    return (
      <EmptyLabeledText label="Consultation Recording" text="No Recordings" />
    );
  }

  return (
    <Labeled label="Consultation Recording">
      <ArrayInput
        source="consultations[0].recordings"
        sx={{ marginBottom: "0" }}
      >
        <StyledSimpleFormIterator
          disableAdd
          disableRemove={isAttachmentsDisabled}
        >
          <FormDataConsumer>
            {({ getSource }) => {
              if (!getSource) return null;
              return (
                <FileImageField
                  key={getSource("attributes.description")}
                  titleSource={getSource("attributes.description")}
                  label="Attachment"
                />
              );
            }}
          </FormDataConsumer>
        </StyledSimpleFormIterator>
      </ArrayInput>
    </Labeled>
  );
}
