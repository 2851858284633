import React, { useState } from "react";
import { FieldValues } from "react-hook-form";
import { useRecordContext } from "react-admin";
import { BaseClinicalProductDialog } from "./BaseClinicalProductDialog";
import uniqBy from "lodash/uniqBy";
import { Box, Button, Tooltip } from "@mui/material";
import { splitByCapitalLetters } from "../../../utilities/splitByCapitalLetters";

export interface ClinicalProductCreateDialogProps {
  updateClinicalProduct: (id: any, values: FieldValues) => void;
  appointmentFilterCategories:any[];
}

export const ClinicalProductEditDialog = (props: ClinicalProductCreateDialogProps) => {
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const record = useRecordContext();

  const { updateClinicalProduct } = props;

  if (!record) {
    return null;
  }

  const { name, code, isEnabled, maxUsageTimeFrame, clinicalProductServices } = record.attributes;

  const services = clinicalProductServices.map((clinicalProductService:any) => {
    if (clinicalProductService.specialty) {
      return {
        id: clinicalProductService.specialty,
        name: splitByCapitalLetters(clinicalProductService.specialty),
        maxUsageCount: clinicalProductService.maxUsageCount,
        group: "Specialties",
      }
    }
    else (clinicalProductService.clinicalServiceId)
    {
      return {
        id: clinicalProductService.clinicalServiceId!.toString(),
        name: clinicalProductService.clinicalService.name,
        maxUsageCount: clinicalProductService.maxUsageCount,
        group: "Clinical Services",
      }
    }

  })

  function submit(values: FieldValues) {
    updateClinicalProduct(record.id, values);
    setIsDialogOpened(false);
  }

  return (
    <Box style={{ padding: "6px 6px 6px 0px" }}>
      <Tooltip title={"Edit Clinical Product"}>
        <Button
          variant="contained"
          size="small"
          onClick={() => setIsDialogOpened(true)}
        >
          Edit
        </Button>
      </Tooltip>
      <BaseClinicalProductDialog
        mode="edit"
        isOpened={isDialogOpened}
        onClose={() => setIsDialogOpened(false)}
        title={"Edit Clinical Product"}
        submitButtonText={"Edit"}
        record={{
          name,
          code,
          isEnabled,
          maxUsageTimeFrame,
          clinicalProductServices: services,
          appointmentFilterCategories: uniqBy(props.appointmentFilterCategories, "id").sort((a:any, b:any) => b.group.localeCompare(a.group)),
        }}
        submit={submit}
      />
    </Box>
  )
}
