import { baseUrl, get } from "../";

export interface BookingNotification {
  id: string;
  type: "userNotifications";
  attributes: {
    notificationChannel: string,
    notificationType: string,
    createdAt: Date;
    metadata: {
      channelData: {
        isDelivered: boolean,
        attributes: {
          destination: string,
        }
      }
      contactDetails: {
        email: string
        phoneNumber: string,
      },
      deliveryEvent: any
    }
  }
}

interface Args {
  bookingId: string;
  userId: string;
  clientId: string;
}

async function getBookingNotifications(args: Args) {
  const { clientId, userId, bookingId } = args;

  const bookingEvents = await get(
    `${baseUrl}/clients/${clientId}/users/${userId}/bookings/${bookingId}/notifications`
  );

  return bookingEvents.data as BookingNotification[];
}

export default getBookingNotifications;
