import { Identifier } from "ra-core";
import { create, remove } from "..";
import { ClientFollowUpActions } from "../../types/clients";

interface Args {
  followUpActions: ClientFollowUpActions["data"];
  oldFollowUpActions: ClientFollowUpActions["data"];
  clientId: Identifier;
}

async function updateFollowUpActions(args: Args) {
  const { followUpActions, oldFollowUpActions, clientId } = args;
  const baseEndpoint = `/ms/clients/${clientId}/clientConsultationFollowUpActions`;
  const promises: Promise<any>[] = [];
  const oldIds = oldFollowUpActions.map(({ id }) => id);

  const filteredFollowUpActions = followUpActions.filter(x => x);
  const newIds = filteredFollowUpActions.map(({ id }) => id);

  const actionsToDelete = oldIds.filter(id => !newIds.includes(id));

  actionsToDelete.forEach(id => {
    const promise = remove(`${baseEndpoint}/${id}`);
    promises.push(promise);
  });

  const actionsToCreate = filteredFollowUpActions.filter(({ id }) => !id);

  actionsToCreate.forEach(followUpAction => {
    const body = {
      data: {
        type: "clientConsultationFollowUpActions",
        attributes: followUpAction.attributes,
      },
    };

    const promise = create(baseEndpoint, body);
    promises.push(promise);
  });

  return promises;
}

export default updateFollowUpActions;
