import * as React from "react";
import { createElement } from "react";
import { styled } from "@mui/material/styles";
import lodashGet from "lodash/get";
import DefaultIcon from "@mui/icons-material/ViewList";
import {
  useResourceDefinitions,
  useGetResourceLabel,
  useCreatePath,
} from "ra-core";

import get from "lodash/get";
import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
} from "ra-ui-materialui";

/* NOTE: This component is almost a replica of the existing RA menu with the exception of how we filter because RA doesn't 
allow you to hide resources that have a list, edit, or create prop. This component uses the options prop to 
filter them via displayInMenu */

/* NOTE: We don't want to be making any changes to this file becaue it is so low level */

const MENU_WIDTH = 240;
const CLOSED_MENU_WIDTH = 55;

const PREFIX = "RaMenu";

export const MenuClasses = {
  open: `${PREFIX}-open`,
  closed: `${PREFIX}-closed`,
};

const Root = styled("div", {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  marginTop: "1.5em",
  marginBottom: "1em",
  [theme.breakpoints.only("xs")]: {
    marginTop: 0,
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  [`&.${MenuClasses.open}`]: {
    width: lodashGet(theme, "sidebar.width", MENU_WIDTH),
  },

  [`&.${MenuClasses.closed}`]: {
    width: lodashGet(theme, "sidebar.closedWidth", CLOSED_MENU_WIDTH),
  },
}));

export const Menu = (props: MenuProps) => {
  const resourcesDefinitions = useResourceDefinitions();
  const getResourceLabel = useGetResourceLabel();
  const createPath = useCreatePath();

  const resources = Object.keys(resourcesDefinitions).map(
    name => resourcesDefinitions[name]
  );

  const {
    hasDashboard,
    dense,
    children = (
      <>
        {hasDashboard && <DashboardMenuItem dense={dense} />}
        {resources
          .filter(resource => {
            const displayInMenu = get(resource, "options.displayInMenu");
            if (resource.hasList && displayInMenu !== false) return true;
          })
          .map(resource => (
            <MenuItemLink
              placeholder={resource.name}
              key={resource.name}
              to={createPath({
                resource: resource.name,
                type: "list",
              })}
              state={{ _scrollToTop: true }}
              primaryText={getResourceLabel(resource.name, 2)}
              leftIcon={
                resource.icon ? createElement(resource.icon) : <DefaultIcon />
              }
              dense={dense}
            />
          ))}
      </>
    ),
    className,
    ...rest
  } = props;

  const [open] = useSidebarState();
  let classList = open ? MenuClasses.open : MenuClasses.closed;
  if (className) {
    classList += ` ${className}`;
  }

  return (
    <Root className={classList} {...rest}>
      {children}
    </Root>
  );
};
