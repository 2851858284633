import { Typography } from "@mui/material";
import React from "react";

interface Props {
  title: string;
}

const textStyles = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  marginLeft: "8px",
};

export function FileFormatTruncatedText(props: Props) {
  const { title } = props;

  const splitTitle = title.toString().split(".");

  if (splitTitle.length === 1) {
    return (
      <Typography sx={textStyles} variant="body1">
        {title}
      </Typography>
    );
  }

  const lastItem = splitTitle.pop();
  const rejoinedString = splitTitle.join(".");

  return (
    <>
      <Typography sx={textStyles} variant="body1">
        {rejoinedString}
      </Typography>
      <Typography variant="body1">.{lastItem}</Typography>
    </>
  );
}
