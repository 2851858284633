import React from "react";
import { Group } from "../../../layout/Group";
import { Stack } from "../../../layout/Stack";
import { Heading } from "../../../ui/Heading";
import { HealthmailLink } from "../../HealthmailLink";
import { BookingGroup } from "../BookingGroup";
import { ClinicalPractitionerGroup } from "../ClinicalPractitionerGroup";
import { useGetOne, useRecordContext } from "react-admin";
import { WorkQueueItemDTO } from "../../../../types/workQueues";
import { HealthmailRaRecord } from "../../../../types/healthmail";
import { GaiaPatientGroup } from "./GaiaPatientGroup";
import { GaiaPractitionerGroup } from "./GaiaPractitionerGroup";
import { GaiaEncounterGroup } from "./GaiaEncounterGroup";

export function SendPrescriptionFields() {
  const record = useRecordContext() as WorkQueueItemDTO["data"];

  const isTaskWithFullContext = Boolean(record.bookings);

  if (isTaskWithFullContext) {
    return <SendPrescriptionWithFullContextFields />
  }

  return <SendPrescriptionCaseContextOnlyFields caseReference={record.attributes.caseReference!} />
}

export function SendPrescriptionWithFullContextFields() {
  return (
    <>
      <BookingGroup />
      <Group
        heading={<Heading level={2}>Healthmail</Heading>}
        data-testid="healthmail-group"
      >
        <Stack>
          <HealthmailLink />
        </Stack>
      </Group>
      <ClinicalPractitionerGroup />
    </>
  )
}

type SendPrescriptionCaseContextOnlyFieldsProps = {
  caseReference: string;
}

export function SendPrescriptionCaseContextOnlyFields(props: SendPrescriptionCaseContextOnlyFieldsProps) {
  const { data: caseData, isLoading } = useGetOne<HealthmailRaRecord>(
    "healthmail",
    { id: props.caseReference },
    { refetchOnWindowFocus: false },
  );

  if (isLoading) {
    return null;
  }

  return (
    <>
      <GaiaPatientGroup patient={caseData!.patient} />
      <GaiaEncounterGroup encounter={caseData!.encounter} />
      <GaiaPractitionerGroup practitioner={caseData!.practitioners[0]} />
    </>
  );
}