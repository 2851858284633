import { FunctionField, SimpleList, useRecordContext } from "react-admin";
import { Group } from "../../layout/Group";
import { Heading } from "../../ui/Heading";
import { Grid } from "@mui/material";
import { Stack } from "../../layout/Stack";
import { TextField } from "../../../stories/TextField.stories";
import { ArrayField, Labeled } from "ra-ui-materialui";
import { splitByCapitalLetters } from "../../../utilities/splitByCapitalLetters";
import { LinkButtonBase } from "../../buttons/LinkButtonBase";
import React from "react";
import { useGetManyReference } from "ra-core";

export function RequestDetailsGroup() {
  const record = useRecordContext();
  const { requestType, linkedTraversalId } = record;

  const shouldShowLinkedRequest = requestType === "RecommendedMedicationRequest" && linkedTraversalId &&
    linkedTraversalId !== "00000000-0000-0000-0000-000000000000";

  return (<Group heading={<Heading level={2}>Request Details</Heading>}>
    <Grid item>
      <Stack>
        <TextField label={"Condition"} source={"condition"} />
        <Labeled label={"Request type"}>
          <FunctionField render={(rec: any) => (splitByCapitalLetters(rec.requestType))} />
        </Labeled>
        <Labeled label={"Medications"}>
          <ArrayField label={"Medications"} source={"medicationRequests"}>
            <SimpleList
              primaryText={(rec: any) => `${rec.medication} ${rec.quantity} ${rec.unitOfMeasure}`}
              linkType={false} />
          </ArrayField>
        </Labeled>
        {shouldShowLinkedRequest && <LinkedTraversal traversalId={linkedTraversalId}/>}
      </Stack>
    </Grid>
  </Group>);
}


interface LinkedTraversalProps {
  traversalId: string;
}

function LinkedTraversal(props: LinkedTraversalProps) {

  const { data, isLoading } = useGetManyReference(
    "prescriptions", {
      id: props.traversalId,
      target: "traversalId"
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  );

  if (isLoading || !data?.length) {
    return null;
  }

  const id = data[0].id;

  return (<Labeled label="Linked traversal">
    <LinkButtonBase to={`/prescriptions/${id}/show`} text={"Linked traversal"} />
  </Labeled>);
}