import { Grid } from "@mui/material";
import React from "react";
import {
  TextField,
  TextInput,
  required,
  useRecordContext,
  Labeled,
} from "react-admin";
import { useEditStateContext } from "../../../../context/EditStateContext";
import { TextArrayField } from "../../../fields/TextArrayField";
import { SimpleFormIterator } from "../../../form/SimpleFormIterator";
import { ArrayInput } from "../../../inputs/ArrayInput";
import { Group } from "../../../layout/Group";
import { Heading } from "../../../ui/Heading";
import { PatientLinkField } from "../../PatientLinkField";

const textAreaStyles = {
  width: "420px",
};

export function ReasonForBooking() {
  const { currentState } = useEditStateContext();

  if (currentState === "show") return <ShowContent />;

  return <EditContent />;
}

const arrayInputStyles = {
  "& li.RaSimpleFormIterator-line": {
    display: "flex",
    alignItems: "center",
    "& .RaSimpleFormIterator-form + .RaSimpleFormIterator-action": {
      position: "relative",
      left: 0,
      ml: 2,
    },
  },
};
function ShowContent() {
  return (
    <Group heading={<Heading level={2}>Reason for booking</Heading>}>
      <Grid item xs={12} sm={6}>
        <PatientLinkField label="Name" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled label="Reason For Booking">
          <TextField source="attributes.reasonForBooking" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextArrayField label="Symptoms" source="attributes.symptoms" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled label="Comments">
          <TextField
            source="attributes.comments"
            emptyText="n/a"
            sx={{ maxWidth: "420px", whiteSpace: "pre-line" }}
          />
        </Labeled>
      </Grid>
    </Group>
  );
}

function EditContent() {
  const record = useRecordContext();
  const { status } = record.attributes;
  const disabled = status !== "PendingConsultation";

  return (
    <Group heading={<Heading level={2}>Reason for booking</Heading>}>
      <Grid item xs={12} sm={6}>
        <PatientLinkField label="Name" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          disabled={disabled}
          source="attributes.reasonForBooking"
          label="Reason For Booking"
          multiline
          validate={required()}
          sx={textAreaStyles}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ArrayInput
          disabled={disabled}
          source="attributes.symptoms"
          label="Symptoms"
          level={3}
          sx={arrayInputStyles}
        >
          <SimpleFormIterator>
            <TextInput source="" label="Symptom" helperText="" />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput
          source="attributes.comments"
          label="Comments"
          disabled={disabled}
          multiline
          sx={textAreaStyles}
        />
      </Grid>
    </Group>
  );
}
