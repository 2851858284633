import { stripePaymentMode } from "../../constants/constants";

type ErrorMessage = string;

const validatePayments = (values: any) => {
  const errors: Record<string, ErrorMessage> = {};

  const payments = values.payments;

  const isPaymentQueryEnabled = payments?.attributes?.isPaymentQueryEnabled;

  if (isPaymentQueryEnabled) {
    if (!payments?.attributes?.stripePaymentMode || 
        !stripePaymentMode.includes(payments.attributes.stripePaymentMode)) {
      errors["payments.attributes.stripePaymentMode"] =
        "Please specify a valid Stripe Payment Mode";
    }
  }

  return errors;
};

export default validatePayments;
