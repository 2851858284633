import { Title, useRecordContext } from "react-admin";
import { PatientNotesAlert } from "../patient/PatientNotesAlert";
import React from "react";

export function CreateBookingTitle() {
  const record = useRecordContext();

  if (!record) return null;

  const hasNotes = record.notes && record.notes.length !== 0;

  return (
    <>
      <Title title="Create Booking" />
      {hasNotes ? <PatientNotesAlert notes={record.notes} /> : null}
    </>
  );
}
