import { GetOneParams } from "react-admin";
import { get } from "..";

const includes = [
  "messages",
  "patient",
  "messages.sender",
  "messages.attachments",
  "client",
  "messages.patientManagementSystemCase",
  "employer"
];

async function getMessageThread(params: GetOneParams) {
  const id = params.id;
  const getMessageThreadEndpoint = `/ms/messageThreads/${id}`;

  const requestParams = {
    include: includes.join(","),
  };

  const { data } = await get(getMessageThreadEndpoint, requestParams);

  const thread = { data: data[0] };

  return thread;
}

export default getMessageThread;
