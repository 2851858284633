import React from "react";
import { FormDataConsumer } from "ra-core";
import { BooleanInput, NumberInput } from "ra-ui-materialui";
import { Heading } from "../ui/Heading";
import { minValue } from "react-admin";
import { ShiftPreferencesView } from "../inputs/ShiftPreferencesInputs/ShiftPreferencesView";
import { styled } from "@mui/material";

const StyledDiv = styled("div")(() => ({
  width: "256px",
}));

const inputStyles = {
  width: "100%",
};

export function AppointmentInputs() {
  return (
    <StyledDiv>
      <NumberInput
        sx={inputStyles}
        source="attributes.contractedHoursPerWeek"
        label="Contract hours per week"
        min="0"
        validate={minValue(0)}
      />
      <NumberInput
        sx={inputStyles}
        source="attributes.contractedOOHPerWeek"
        label="Contract OOH hours per week"
        min="0"
        validate={minValue(0)}
      />
      <NumberInput
        style={{ width: 280 }}
        source="attributes.contractedHoursShoulders"
        label="Contract shoulder hours per week"
        min="0"
        validate={minValue(0)}
      />
      <BooleanInput
        source="attributes.isContractedOvernight"
        label="Can be contracted overnight"
      />
    </StyledDiv>
  );
}

export function AppointmentInputWrapper() {
  return (
    <div>
      <Heading level={2}>Appointment Preferences</Heading>
      <ShiftPreferencesView baseSourcePath="resourceManagementRules[0].attributes" />
      <BooleanInput
        source="showAppointmentDetails"
        label="Show Additional Appointment Preferences"
      />
      <FormDataConsumer>
        {({ formData }) => {
          if (!formData.showAppointmentDetails) return null;

          return <AppointmentInputs />;
        }}
      </FormDataConsumer>
    </div>
  );
}
