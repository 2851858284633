import React from "react";
import { useEditStateContext } from "../../../../context/EditStateContext";
import { Group } from "../../../layout/Group";
import { Heading } from "../../../ui/Heading";
import { PatientNotesField } from "../../PatientNotesField";
import { PatientNotesInput } from "../../PatientNotesInput";

const notesWrapperStyles = {
  gridTemplateColumns: "1fr",
};

export function Notes() {
  const { currentState } = useEditStateContext();

  if (currentState === "show") return <ShowContent />;

  return <EditContent />;
}

function ShowContent() {
  return (
    <Group
      sx={notesWrapperStyles}
      heading={<Heading level={2}>Documents and notes</Heading>}
    >
      <PatientNotesField />
    </Group>
  );
}

function EditContent() {
  return (
    <Group
      sx={notesWrapperStyles}
      heading={<Heading level={2}>Documents and notes</Heading>}
    >
      <PatientNotesInput />
    </Group>
  );
}
