import { get, remove } from "..";
import {
  BatchSendGridTemplateDTO,
  NotificationType,
  SendGridTemplateDTO,
} from "../../types/notifications";
import deepEquals from "../../utilities/deepEquals";
import { createSendGridTemplates } from "./createSendGridTemplates";

interface Args {
  clientId: string;
  templateIds: BatchSendGridTemplateDTO["data"];
  existingTemplateIds: BatchSendGridTemplateDTO["data"];
}

async function getSendGridTemplates(clientId: string) {
  const getTemplatesEndpoint = `/ms/${clientId}/sendGridNotificationTemplates`;
  const res = await get(getTemplatesEndpoint);

  return res.data;
}

function getSendGridIdFromKey(
  sendGridTemplates: SendGridTemplateDTO["data"],
  key: NotificationType
) {
  const record = sendGridTemplates.find(template => {
    return key === template.attributes.notificationType;
  });

  return record?.id ?? "";
}

async function updateSendGridIds(args: Args) {
  const { templateIds, clientId, existingTemplateIds } = args;

  const noChange = deepEquals(templateIds, existingTemplateIds);

  if (noChange) return null;

  const sendGridTemplates = await getSendGridTemplates(clientId);

  const { templates } = templateIds.attributes;
  const templateKeys = Object.keys(templates) as NotificationType[];
  const promises = [];

  try {
    // Delete
    const keysToDelete = templateKeys.filter(
      key => !templateIds.attributes.templates[key].id
    );

    for await (const key of keysToDelete) {
      const id = getSendGridIdFromKey(sendGridTemplates, key);
      const endpoint = `/ms/clients/${clientId}/sendGridNotificationTemplates/${id}`;
      const deletePromise = remove(endpoint);

      promises.push(deletePromise);
    }

    // Update
    const templateToUpdate = templateKeys.reduce((acc, curr) => {
      if (keysToDelete.includes(curr)) {
        return acc;
      }

      return {
        ...acc,
        [curr]: templates[curr].id,
      };
    }, {} as Record<Partial<NotificationType>, string>);

    const updatePromise = createSendGridTemplates({
      clientId,
      templates: templateToUpdate,
    });

    promises.push(updatePromise);

    await Promise.all(promises);
  } catch (err) {
    console.error(err);
  }
}

export default updateSendGridIds;
