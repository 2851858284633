import React from "react";
import { RaRecord, SimpleList, useCreate, useNotify, TextField, WithListContext } from "react-admin";
import { RecordContextProvider, useGetOne, useRecordContext, useRefresh } from "ra-core";
import { ArrayField, Button, FunctionField, Labeled, SelectInput } from "ra-ui-materialui";
import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import get from "lodash/get";
import { splitByCapitalLetters } from "../../../../utilities/splitByCapitalLetters";
import { Heading } from "../../../ui/Heading";
import { Group } from "../../../layout/Group";
import { Stack } from "../../../layout/Stack";
import { useEditStateContext } from "../../../../context/EditStateContext";
import {
  identityVerificationResetStatuses,
  identityVerificationResetStatusesChoices
} from "../../../../constants/constants";
import { NeutralColors } from "../../../../theme";


export function IdentityVerificationDetails() {
  const record = useRecordContext();
  const { currentState } = useEditStateContext();
  const kycEnabled = isKycEnabled(record);


  const isDependant = get(record, "attributes.isDependant");

  if (!kycEnabled || isDependant) {
    return null;
  }

  if (currentState === "show") return <ShowContent />;

  return <EditContent />;
}


function EditContent() {
  const record = useRecordContext();
  const idVerificationStatus = get(record, "attributes.prevIdentityVerificationStatus");

  return (
    <Group heading={<Heading level={2}>Identity Verification</Heading>}>
      <Stack>
        <SelectInput
          data-testid="edit-id-verification-status"
          choices={identityVerificationResetStatusesChoices(idVerificationStatus)}
          source="attributes.identityVerificationStatus"
          label="ID Verification Status"
          required
          disabled={!identityVerificationResetStatuses.includes(idVerificationStatus)}
          helperText={false}
        />
      </Stack>
    </Group>
  );
}


function ShowContent() {
  const record = useRecordContext();
  const idVerificationStatus = get(record, "attributes.identityVerificationStatus");
  const shouldShowResendButton = showResendButton(idVerificationStatus);
  const kycFailed = idVerificationStatus === "Failed" || idVerificationStatus === "Blocked";

  return (
    <Group
      data-testid={"id-verification-details"}
      heading={<Heading level={2}>Identity Verification</Heading>}
    >
      <Stack>
        <Grid container>
          <Grid item sm={6} xs={12}>
            <Labeled label="ID Verification Status">
              <Typography data-testid="id-verification-status">
                {splitByCapitalLetters(idVerificationStatus)}
              </Typography>
            </Labeled>
          </Grid>
          <Grid item sm={6} xs={12}>
            {shouldShowResendButton && <ResendKYCButton />}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6}>
            {kycFailed && <IdentityVerificationPatientDetails />}
          </Grid>
          <Grid item xs={12} sm={6}>
            {kycFailed && <VerificationFailedReason />}
          </Grid>
        </Grid>
      </Stack>
    </Group>
  );
}

const isKycEnabled = (record: RaRecord) => {
  return record?.clients[0].attributes?.isKycEnabled;
};

const showResendButton = (idStatus: any) => {
  return ["NotStarted", "Failed", "Expired"].includes(idStatus);
};

const ResendKYCButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();

  const { data: client } = useGetOne("clients", { id: record?.clients[0]?.id });

  const postVerifyBaseUrl = client?.existingContract?.attributes?.domain;

  const params = {
    postVerifyBaseUrl,
    userId: record.users?.[0]?.id,
    clientId: record.clients[0].id
  };

  const notify = useNotify();

  const [resendKycLink, { isLoading }] = useCreate();

  const submit = () =>
    resendKycLink(
      "resendVerificationLink",
      {
        data: params
      },
      {
        onSuccess: () => {
          notify("Verification link was successfully send");
          refresh();

        },
        onError: () => {
          notify("There was a problem resending a verification link");
        }
      });

  return (<Button
    disabled={isLoading || !postVerifyBaseUrl}
    label={"Resend Verification Link"}
    aria-label={"Resend Verification Link"}
    color="primary"
    variant="outlined"
    onClick={submit}
  />);
};


const checkResultValuesToShow = ["REJECT", "NOT_AVAILABLE"];

const VerificationFailedReason = () => {
  const record = useRecordContext();

  const failedSessions = record.identityVerificationSessions.filter(
    (x: any) => x.attributes.identityVerificationStatus === "Failed"
  );

  const reasons = failedSessions[0]?.identityVerificationSessionChecks
    .filter((x: any) => checkResultValuesToShow.includes(x.attributes.checkResult))
    .map((x: any) => ({
      id: x.id,
      type: splitByCapitalLetters(x.attributes.checkType),
      recommendation:
        checkRecommendationMapping[x.attributes.checkRecommendation] ??
        (x.attributes.checkRecommendation
          ? `${x.attributes.checkRecommendation} - This check failed`
          : "This check failed"),
      result: checkResultMapping[x.attributes.checkResult],
    }));

  if (!reasons || reasons.length === 0) {
    return null;
  }

  return (
    <RecordContextProvider value={{ reasons }}>
      <Heading level={4}>ID Verification Failure Reasons</Heading>
      <ArrayField source="reasons">
        <WithListContext
          render={({ data }) => (
            <Grid container>
              {data.map(rec => (
                <Grid
                  key={rec.id}
                  item
                  xs={12}
                  sx={{ marginTop: "10px" }}
                >
                  <Typography>{`${rec.type} - ${rec.result}`}</Typography>
                  <Typography sx={{ color: NeutralColors.Grey }}>
                    {rec.recommendation}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}
        />
      </ArrayField>
    </RecordContextProvider>
  );
};

const IdentityVerificationPatientDetails = () => {
  const record = useRecordContext();
  const patient = record.identityVerificationPatients?.[0];

  if (!patient) {
    return (
      <>
        <Heading level={4}>ID Details</Heading>
        <Typography>
          {"No ID details found for the patient"}
        </Typography>
      </>
    );
  }

  return (
    <>
      <Heading level={4}>ID Details</Heading>
      <Grid container>
        <Grid item xs={12}>
          <Labeled label={"First Name"}>
            <TextField source={"identityVerificationPatients.0.attributes.firstName"} />
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Labeled label={"Last Name"}>
            <TextField source={"identityVerificationPatients.0.attributes.lastName"} />
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Labeled label={"Full Name"}>
            <TextField source={"identityVerificationPatients.0.attributes.fullName"} />
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Labeled label={"Date Of Birth"}>
            <FunctionField
              render={(rec: any) => dayjs(rec.identityVerificationPatients[0].attributes.dateOfBirth).format("DD/MM/YYYY")} />
          </Labeled>
        </Grid>
      </Grid>
    </>
  );

};

export const checkRecommendationMapping = {
  BLACK_AND_WHITE_IMAGE: "Image is black and white",
  COUNTERFEIT: "Document is suspected counterfeit",
  DATA_MISMATCH: "Data does not match records",
  DOC_NUMBER_INVALID: "Document number is invalid",
  DOCUMENT_COPY: "Document appears to be a copy",
  DOCUMENT_NOT_SUPPORTED: "Document type is not supported",
  DOCUMENT_TOO_DAMAGED: "Document is too damaged to verify",
  DOCUMENT_VERSION_NOT_SUPPORTED: "Document version is unsupported",
  GLARE_OBSTRUCTION: "Image has glare obstruction",
  IMAGE_RESOLUTION_TOO_LOW: "Image resolution is too low",
  INCORRECT_DOCUMENT_TYPE: "Incorrect document type provided",
  MISSING_DOCUMENT_SIDE: "Document side is missing",
  NO_DOCUMENT: "No document detected",
  OBJECT_OBSTRUCTION: "Object obstructing document in image",
  PARTIAL_PHOTO: "Photo is partially cut off",
  PHOTO_OVEREXPOSED: "Photo is overexposed",
  PHOTO_TOO_BLURRY: "Photo is too blurry",
  TAMPERED: "Document is tampered with",
  DIFFERENT_PERSON: "Appears to be a different person",
  DOCUMENT_NOT_FOUND: "Document not found in records",
  FACE_NOT_GENUINE: "Face appears inauthentic",
  FACE_PHOTO_LOW_RESOLUTION: "Face photo has low resolution",
  LARGE_AGE_GAP: "Age gap is too large",
  PHOTO_TOO_DARK: "Photo is too dark",
  DATE_OF_BIRTH_MISMATCH: "Date of birth does not match",
  FIRST_NAME_MISMATCH: "First name does not match",
  LAST_NAME_MISMATCH: "Last name does not match",
  ID_FULL_NAME_MISSING_FIRST: "ID full name missing patient first name",
  ID_FULL_NAME_MISSING_LAST: "ID full name missing patient last name",
  NO_NAMES_SUPPLIED_WITH_ID: "No names were supplied with ID",
  "Date of birth does not match": "Date of birth does not match",
  "First name does not match": "First name does not match",
  "Last name does not match": "Last name does not match",
  "ID full name does not contain patient first name": "ID full name missing patient first name",
  "ID full name does not contain patient last name": "ID full name missing patient last name",
  "Patient record mismatch - Date of birth does not match": "Date of birth does not match",
  "Patient record mismatch - First name does not match": "First name does not match",
  "Patient record mismatch - Last name does not match": "Last name does not match",
  "Patient record mismatch - ID full name does not contain patient first name": "ID full name missing patient first name",
  "Patient record mismatch - ID full name does not contain patient last name": "ID full name missing patient last name",
} as any;

export const checkResultMapping = {
  REJECT: 'Reject',
  NOT_AVAILABLE: 'Not available'
} as any;
