import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import qs from "query-string";
import { HttpError } from "react-admin";
import { getConfig } from "../config";
import { getValidAccessToken } from "./auth";
import consolidateRecords from "./helpers/consolidateRecords";
import { getErrorMessageOrDefault } from "./errors";

interface AxiosErrorData {
  errors: [
    {
      detail: string;
      code: string;
    }
  ];
}

const errorMessages: Record<string, string> = {
  "401":
    "Your account has not been set up to use the Healthmail services. We've notified our developers. Please open a Halo ticket if the issue persists.",
  "403":
    "You don't seem to have permission to perform this action. We've notified our developers. Please open a Halo ticket if the issue persists.",
  "404":
    "We couldn't find a case that matches your case ID. Please check that all numbers were inputted correctly.",
  "409": "This prescription has already been processed.",
  "424":
    "It appears that something's gone wrong and we weren't able to complete your request. We've notified our developer who should resolve this soon.",
  "500":
    "It appears that something's gone wrong and we weren't able to complete your request. We've notified our developer who should resolve this soon.",
};

const sapiHeaders : AxiosRequestHeaders = {
  "Content-Type": "application/vnd.api+json",
  Accept: "application/vnd.api+json",
}

const isAxiosError = (e: any): e is AxiosError<AxiosErrorData> =>
  "isAxiosError" in e;

const config = getConfig();

export const baseUrl = `${config.gaia.apiHost}/v${config.api.apiVersion}`;

const client = axios.create({
  baseURL: baseUrl,
  paramsSerializer: params => qs.stringify(params),
});

client.interceptors.request.use(
  async clientConfig => {
    const accessToken = await getValidAccessToken();

    if (accessToken) {
      if (!clientConfig.headers) {
        clientConfig.headers = {};
      }

      clientConfig.headers["x-device-id"] =
        "6530a136-f1ac-4db4-a8d3-7cce49c6868b";
      clientConfig.headers.Authorization = `Bearer ${accessToken}`;
    }

    return clientConfig;
  },
  error => {
    Promise.reject(error);
  }
);

export async function list(url: string, reqConfig: AxiosRequestConfig = {}, useSapiFormat = false) {
  try {
    if (useSapiFormat) {
      reqConfig.headers = {
        ...reqConfig.headers,
        ...sapiHeaders
      }
    }

    const res = await client.get(url, reqConfig);

    if (useSapiFormat) {
      const { data, meta, included = [] } = res.data;

      if (included.length === 0) {
        const totalRecords = meta ? meta["total-records"] : data.length;
        return { data, total: totalRecords };
      }
    }

    return { data: res.data, total: 100 };
  } catch (err) {
    const message = "We weren't able to get a list of your requested record";
    throw useSapiFormat ? handleSapiError(err, message) : handleError(err, message);
  }
}

export async function get(url: string, reqConfig: AxiosRequestConfig = {}, useSapiFormat = false){
  try {
    if (useSapiFormat) {
      reqConfig.headers = {
        ...reqConfig.headers,
        ...sapiHeaders
      }
    }
    const res = await client.get(url, reqConfig);

    if (useSapiFormat) {
      const { data, included = [], links } = res.data;

      /**
       * Refactor this so it's return type is consistent. Right now it can be either an array or an object
       */
      if (included.length === 0) {
        return { data, links };
      }

      const input = Array.isArray(data) ? data : [data];

      const consolidatedData = consolidateRecords(input, included);

      return { data: consolidatedData, links };
    }

    return { data: res.data};
  } catch (err) {
    const message = "We weren't able to get a requested record";
    throw useSapiFormat ? handleSapiError(err, message) : handleError(err, message);
  }
}

export async function getFile(url: string, reqConfig: AxiosRequestConfig = {}) {
  try {
    const res = await client.get(url, reqConfig);

    const { data } = res;

    return { data };
  } catch (err) {
    const message = "We weren't able to to download your request file";
    throw handleError(err, message);
  }
}

export async function create(
  url: string,
  body: any = {},
  reqConfig: AxiosRequestConfig = {},
  useSapiFormat = false
) {
  try {
    if (useSapiFormat) {
      reqConfig.headers = {
        ...reqConfig.headers,
        ...sapiHeaders
      }
    }
    const res = await client.post(url, body, reqConfig);

    if (useSapiFormat) {
      const { data, included = [] } = res.data;

      if (included.length === 0) {
        return { data };
      }

      const consolidatedData = consolidateRecords([data], included);

      return { data: consolidatedData[0] };
    }

    const { data } = res;

    return { data };
  } catch (err) {
    const message =
      "We weren't able to complete your request. If this problem persists, please log a Halo ticket.";
    throw useSapiFormat ? handleSapiError(err, message) : handleError(err, message);
  }
}

export async function update(
  url: string,
  body: any = {},
  reqConfig: AxiosRequestConfig = {},
  useSapiFormat = false
) {
  const updateMethod = useSapiFormat ? "patch" : "put";

  try {
    if (useSapiFormat) {
      reqConfig.headers = {
        ...reqConfig.headers,
        ...sapiHeaders
      }
    }

    const res = await client[updateMethod](url, body, reqConfig);

    if (useSapiFormat) {
      const { data, included = [] } = res.data;

      if (included.length === 0) {
        return { data };
      }

      const consolidatedData = consolidateRecords([data], included);

      return { data: consolidatedData[0] };
    }

    const { data } = res;

    return { data };
  } catch (err) {
    const message =
      "We weren't able to complete your request. If this problem persists, please log a Halo ticket.";
    throw useSapiFormat ? handleSapiError(err, message) : handleError(err, message);
  }
}


export async function remove(
  url: string,
  reqConfig: AxiosRequestConfig = {}
) {
  try {
    return await client.delete(url, reqConfig);

  } catch (err) {
    const message =
      "We weren't able to complete your request. If this problem persists, please log a Halo ticket.";
    throw handleError(err, message);
  }
}



function handleError(err: unknown, defaultMessage: string) {
  if (isAxiosError(err)) {
    if (err.response) {
      const { status } = err.response;

      const errorMessage = errorMessages[status] ?? defaultMessage;

      return new HttpError(errorMessage, status, {
        detail: errorMessage,
        api: "gaia",
      });
    }
  }

  return new Error(defaultMessage);
}


function handleSapiError(
  err: unknown,
  defaultMessage: string,
) {
  if (axios.isCancel(err)) {
    return "ABORT";
  }

  if (isAxiosError(err)) {
    if (err.response) {
      const { data, status } = err.response;
      const filteredErrors = data.errors.filter(
        errorObject => errorObject.code !== "400_validation"
      );

      const [error] = data.errors.length > 1 ? filteredErrors : data.errors;

      const { code } = error;
      const detail = error.detail ?? "";
      const errorMessage = getErrorMessageOrDefault(code, defaultMessage);
      const fullMessage = `${errorMessage}: ${detail}`;

      return new HttpError(fullMessage, status, error);
    }
  }

  return new Error(defaultMessage);
}
