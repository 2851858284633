import React from "react";
import { useListFilterContext } from "react-admin";
import { AppointmentDTO } from "../../../types/appointments";
import { useNextAvailableAppointmentController } from "./NextAvailableAppointmentController";
import { NextAvailableAppointmentView } from "./NextAvailableAppointmentView";

export type NextAvailableAppointmentType =
  | AppointmentDTO["data"]["attributes"][]
  | null;

const transformData = (
  data?: AppointmentDTO["data"][]
): NextAvailableAppointmentType => {
  if (!data) return null;

  return data.map(({ attributes }) => attributes);
};

export function NextAvailableAppointmentField() {
  const filterContext = useListFilterContext();
  const clientId = filterContext?.filterValues?.clients;

  const { data, isLoading } = useNextAvailableAppointmentController({
    clientId,
  });

  const nextAvailableAppointments = transformData(data);

  return (
    <NextAvailableAppointmentView
      clientId={clientId}
      nextAvailableAppointments={nextAvailableAppointments}
      isLoading={isLoading}
    />
  );
}
