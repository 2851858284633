import React from "react";
import { cloneElement } from "react";
import { ListActionsProps, TopToolbar, useListContext } from "react-admin";
import { CreateButton } from "../CreateButton";

export function ClinicalPractitionerListActions(props: ListActionsProps) {
  const { className, filters } = props;
  const { resource, displayedFilters, filterValues, showFilter } =
    useListContext();
  const { email, firstName, lastName, specialty } = filterValues;
  const initialValues = {
    attributes: {
      firstName,
      lastName,
      email,
      specialty,
      isEnabled: true,
    },
  };

  return (
    <TopToolbar className={className}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton
        initialValues={initialValues}
        label="Create Clinical Practitioner"
        size="medium"
        resource="clinicalPractitioners"
      />
    </TopToolbar>
  );
}
