import React from "react";
import {
    LinearProgress,
    useCreate,
    useRecordContext,
    useNotify,
  } from "react-admin";
import { ButtonProps } from "@mui/material/Button";
import dayjs from "dayjs";
import { Button } from "../buttons/Button";
import { trackEvent } from "../../utilities/trackEvent";
import get from "lodash/get";

interface Props extends ButtonProps {
  label: string;
  applyPassword: boolean;
  caseNo: any;
  encounterStartTime: any;
  patientDob: any;
}

function ConsultationOutputDownloadButton(props: Props) {
    const { label, applyPassword, caseNo, encounterStartTime, patientDob, ...rest } = props;
    const record = useRecordContext();
    const id = get(record, "id");
    const type = get(record, "type");
    const notify = useNotify();
  
    const formattedEncounterDate = dayjs(encounterStartTime, "MM/DD/YYYY HH:mm:ss").format(
      "DDMMYYYY"
    );
  
    const formattedPatientDob = dayjs(patientDob, "YYYY-MM-DD").format(
      "DDMMYYYY"
    );
  
    const fileName = `${caseNo} ${type} ${formattedEncounterDate}.pdf`;
  
    const [downloadFile, { isLoading }] = useCreate(
      "gaia:downloadFile",
      {
        data: {
          url: `consultation-output/documents/${id}`,
          fileName,
          applyPassword,
          password: formattedPatientDob,
        },
      },
      {
        onError: err => {
          notify((err as any).message, { type: "error" });
        },
      }
    );
  
    return (
      <div>
        <Button
          color="primary"
          variant="outlined"
          aria-label={label}
          disabled={isLoading}
          onClick={() => {
            const encryptedState = applyPassword ? "Encrypted" : "Not Encrypted";
            trackEvent(
              "Documents",
              "Download Document",
              `${encryptedState} ${type}`
            );
  
            return downloadFile();
          }}
          {...rest}
        >
          {label}
        </Button>
        {isLoading && <LinearProgress sx={{ width: "100%" }} />}
      </div>
    );
  }

  export default ConsultationOutputDownloadButton;