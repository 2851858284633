import { GetOneParams } from "react-admin";
import { get } from "..";

async function getPatientManagementSystem(params: GetOneParams) {
  const { id } = params;
  const endpoint = `/ms/patientManagementSystems/${id}`;
  const res = await get(endpoint, params);

  return res;
}
export default getPatientManagementSystem;
