import { create } from ".";

interface Params {
  bookingId: string;
  userId: string;
  clientId: string;
}

async function updateAdastra(params: Params) {
  const { clientId, userId, bookingId } = params;
  const endpoint = `/clients/${clientId}/users/${userId}/bookings/${bookingId}/updateadastra/`;

  await create(endpoint);

  return {
    data: {
      id: "id-to-satisfy-ra",
    },
  };
}

export default updateAdastra;
