import React from "react";
import { Edit, SaveButton, TextInput, Toolbar, useNotify, useRefresh } from "react-admin";
import { EditActions } from "../Actions";
import { ScrollableTabbedForm } from "../ScrollableTabLayouts";
import { FormTab } from "../Tabs";
import { useParams } from "react-router-dom";

export function MessageThreadEdit() {
  const params = useParams<{
    id: string;
  }>();

  const notify = useNotify();
  const refresh = useRefresh();


  return (
    <Edit
      title={`Message Thread #${params.id}`}
      actions={<EditActions />}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Changes to Message Thread were successful");
          refresh();
        }
      }}
    >
      <ScrollableTabbedForm
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }>
        <FormTab label="General">
          <TextInput
            label="Product ID"
            resource="messages"
            source="messages[0].attributes.payload.productId"
          />
        </FormTab>
      </ScrollableTabbedForm>
    </Edit>
  )
}
