import { ReferenceField, Show, TextField } from "ra-ui-materialui";
import React from "react";
import { useParams } from "react-router-dom";
import { Actions } from "../Actions";
import { BookingLinkField } from "../booking/BookingLinkField";
import DateField from "../fields/DateField";
import { Heading } from "../ui/Heading";
import { SimpleShowLayout } from "react-admin";
import { FullNameField } from "../fields/FullNameField";
import { StatusField } from "./StatusField";

export function AppointmentShow() {
  const { id } = useParams();

  return (
    <Show actions={<Actions />} title={`Appointment #${id}`}>
      <SimpleShowLayout>
        <Heading level={2}>Appointment Details</Heading>
        <TextField label="ID" source="id"/>
        <StatusField label="Status"/>
        <FullNameField
          label="Clinical Practitioner"
          firstNameSource="clinicalPractitioners[0].attributes.firstName"
          lastNameSource="clinicalPractitioners[0].attributes.lastName"
        />
        <TextField
          label="Clinical Service"
          source="clinicalServices[0].attributes.name"
          emptyText="Not provided"
        />
        <DateField source="attributes.start" label="Start time" showTime hideLabel/>
        <DateField source="attributes.end" label="End time" showTime hideLabel />
        <TextField label="Consultation type" source="attributes.consultationType" />
        <Heading level={2}>Booking Details</Heading>
        <ReferenceField
          label="Booking"
          source="bookings[0].id"
          reference="bookings"
          link={false}
          emptyText="This appointment has no associated booking"
        >
          <BookingLinkField
            patientIdSource="users[0].id"
            clientIdSource="clients[0].id"
            bookingIdSource="id"
          />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
}
