import { DeleteParams } from "ra-core";
import { create } from "..";

async function deleteAppointment(args: DeleteParams) {
  const endpoint = `/ms/appointments/${args.id}/cancel`;

  const res = await create(endpoint);

  return res;
}

export default deleteAppointment;
