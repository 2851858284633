import React from "react";
import dayjs from "dayjs";
import { InputProps, useInput, TextInput } from "react-admin";
import { SxProps, Theme } from "@mui/material";

export interface Props extends InputProps {
  minDate?: string;
  maxDate?: string;
  disabled?: boolean;
  required?: boolean;
  hasHelperText?: boolean;
  sx?: SxProps<Theme>;
}

const defaultTime = dayjs("invalid");

const defaultParse = (val: any) => {
  if (!val) return defaultTime;

  const [hour, minute] = val.split(":");
  const time = dayjs().set("hour", hour).set("minute", minute);
  return time;
};

const defaultFormat = (val: any) => {
  if (!val) return "";

  const time = dayjs(val).format("HH:mm");

  return time;
};

export function TimeInput(props: Props) {
  const {
    source,
    minDate,
    maxDate,
    label,
    disabled = false,
    validate,
    parse = defaultParse,
    format = defaultFormat,
    sx = {},
  } = props;

  const { hasHelperText, ...rest } = props;

  const { fieldState } = useInput(props);
  const { error, isTouched } = fieldState;

  return (
    <TextInput
      {...rest}
      id={source}
      type="time"
      variant="filled"
      margin="dense"
      format={format}
      parse={parse}
      placeholder="hh:mm"
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        min: minDate,
        max: maxDate,
      }}
      source={source}
      sx={[
        { margin: "8px 0", minWidth: "160px" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      aria-label={source}
      error={isTouched && Boolean(error)}
      label={label}
      disabled={disabled}
      helperText={!hasHelperText || isTouched ? false : "hh:mm"}
      validate={validate}
    />
  );
}
