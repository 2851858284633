import { create } from "..";

interface Params {
  clientId: string;
  userId: string;
  bookingId: string;
}

async function sendJoiningInformation(params: Params) {
  const { bookingId } = params;

  const api = `/ms/bookings/${bookingId}/sendJoiningInformation`;

  await create(api);

  return {
    data: { id: "id-to-satisfy-ra" },
  };
}

export default sendJoiningInformation;
