import { ConsultationOutputsList } from "./ConsultationOutputsList";
import { ConsultationOutputsShow } from "./ConsultationOutputsShow";
import ConsultationOutputsIcon from "@mui/icons-material/InsertDriveFile";

export const consultationOutputResources = {
  name: "consultationOutputs",
  options: {
    label: "Consultation Outputs",
    resource: "documents",
  },
  list: ConsultationOutputsList,
  icon: ConsultationOutputsIcon,
  show: ConsultationOutputsShow,
};
