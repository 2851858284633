import React from "react";
import { styled } from "@mui/material/styles";
import { EditTabProps } from "../../../../types/utilities";
import { FormTab } from "../../../form/CustomTabForm/FormTab";
import { Stack } from "../../../layout/Stack";
import { Documents } from "./Documents";

const StyledDiv = styled("div")({
  width: "100%",
});

export function DocumentsTab(props: EditTabProps) {
  return (
    <FormTab {...props} label="Documents" editable="editable">
      <StyledDiv>
        <Stack>
          <Documents/>
        </Stack>
      </StyledDiv>
    </FormTab>
  );
}