import React from "react";
import {
  TextField,
  Show,
  ArrayField,
  useRecordContext,
  FunctionField,
  TitleProps,
  RaRecord,
  Title,
  Labeled,
  useShowContext,
} from "react-admin";
import { Actions } from "../Actions";
import { SimpleIteratorLayout } from "../fields/SimpleIteratorLayout";
import { Heading } from "../ui/Heading";
import DateField from "../fields/DateField";
import get from "lodash/get";
import { Box } from "@mui/system";
import { Stack } from "../layout/Stack";
import ConsultationOutputDownloadButton from "./ConsultationOutputDownloadButton";

export function ConsultationOutputsShow() {  
  return (
    <Show actions={<Actions />} title={<PageTitle />}>
      <Stack sx={{ padding: "8px 16px" }}>
        <Labeled label="Case Ref">
          <TextField source="caseRef" />
        </Labeled>
        <DateField
          showTime
          source="encounter.startTime"
          label="Consultation Time"
          emptyText="n/a"
        />
        <Labeled label="Patient Name">
          <FunctionField
            render={(record?: RaRecord) => {
              if (!record) return "";
              return `${record.patient.givenName} ${record.patient.familyName}`;
            }}
          />
        </Labeled>
        <Labeled label="Patient Postcode">
          <TextField source="patient.address.postalCode" />
        </Labeled>
        <DateField source="patient.birthDate" label="Date of Birth" />
        <Heading level={2}>Consultation Outputs</Heading>

        <ArrayField source="documents">
          <SimpleIteratorLayout>
            <Labeled label="Type">
              <TextField source="type" label="Type" />
            </Labeled>
            <DateField source="createdDate" label="Created At" />
            <Box sx={{ display: "flex", gap: "8px" }}>
              <DownloadButtons />
            </Box>
          </SimpleIteratorLayout>
        </ArrayField>
      </Stack>
    </Show>
  );
}

function PageTitle(props: TitleProps) {
  const record = useRecordContext(props);
  const caseRef = get(record, "caseRef");

  return <Title title={`Documents (Case: ${caseRef})`} />;
}

function DownloadButtons() {
  const { record: showRecord = {} } = useShowContext();

  return <>
    <ConsultationOutputDownloadButton
      label="Download with password"
      applyPassword
      caseNo={showRecord.caseRef}
      encounterStartTime={showRecord.encounter.startTime}
      patientDob={showRecord.patient.birthDate}
      variant="contained"
    />
    <ConsultationOutputDownloadButton
      label="Download without password"
      caseNo={showRecord.caseRef}
      encounterStartTime={showRecord.encounter.startTime}
      patientDob={showRecord.patient.birthDate}
      applyPassword={false}
    />
  </>
}