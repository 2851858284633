import React from "react";
import { TextInput, useGetOne } from "react-admin";
import AutocompleteInput from "../inputs/AutocompleteInput";
import { useWatch } from "react-hook-form";

interface AppointmentFilterInputsProps {
  clientId: string,
  patientRecordId: string
}


const AppointmentFilterRescheduleInputs = ({
 clientId: id,
 patientRecordId
}: AppointmentFilterInputsProps) => {

  const consultationType = useWatch({ name: "consultationType" });
  const { data } = useGetOne("availableServices", { id, meta: { filter: { consultationType, patientRecordId } } });

  return (
    <>
      <AutocompleteInput
        source="service"
        label="Services"
        placeholder="Choose a specialty"
        choices={data?.availableServices ?? []}
        required
        groupBy={(choice) => choice.group}
      />
      <TextInput
        sx={{width: "260px"}}
        source="consultationType"
        label="Consultation Type"
        required
        disabled
      />
    </>
  );
};

export default AppointmentFilterRescheduleInputs;
