/* eslint-disable react/jsx-key */
import { CustomRoutes, Resource, ResourceProps } from "ra-core";
import authProvider from "./authProvider";
import React from "react";
import { Route } from "react-router-dom";
import { appointmentResources } from "./components/appointments";
import { BookingCreate } from "./components/booking/BookingCreate";
import { BookingEdit } from "./components/booking/BookingEdit";
import { BookingReschedule } from "./components/booking/BookingReschedule";
import { clientResources } from "./components/client";
import { clinicalPractitionerResources } from "./components/clinicalPractitioner";
import { consultationOutputResources } from "./components/consultationOutputs";
import { messageThreadResources } from "./components/messageThreads";
import { patientResources } from "./components/patient";
import { QuinyxEdit, QuinyxList } from "./components/quinyxUnassignedResources";
import { resourceManagementResources } from "./components/resourceManagement";
import { schedulerResources } from "./components/scheduler/Scheduler";
import { shiftResources } from "./components/shifts";
import { ShiftImportWrapper } from "./components/shifts/ShiftImport";
import { workQueuesResources } from "./components/workQueues";
import { FeatureFlagService } from "./featureFlags";
import { ResetPasswordPage } from "./components/auth/ResetPasswordPage";
import { ChangePasswordPage } from "./components/auth/ChangePasswordPage";
import { resourcePoolResources } from "./components/resorucePools";
import { pharmacyManagementResources } from "./components/pharmacies";
import { gpSurgeriesResources } from "./components/gpSurgeries";
import { clinicalServiceResources } from "./components/clinicalServices";
import { ShiftImportProcess } from "./components/shifts/ShiftImportProcess";
import { prescriptionResources } from "./components/prescriptions";
import { productResources } from "./components/clinicalProducts";

const hasComponents = ({ list, show, edit, create }: ResourceProps) => {
  return list || show || edit || create;
};

export const publicResources = (
  <CustomRoutes noLayout>
    <Route path="/reset-password" element={<ResetPasswordPage />} />
    <Route path="/change-password" element={<ChangePasswordPage />} />
  </CustomRoutes>
);

const resources = [
  <CustomRoutes>
    <Route
      path="/patientRecords/:userId/bookings/create"
      element={<BookingCreate />}
    />
    <Route
      path="/patientRecords/:userId/clients/:clientId/bookings/:id/reschedule"
      element={<BookingReschedule />}
    />
    <Route
      path="/patientRecords/:userId/clients/:clientId/bookings/:id/*"
      element={<BookingEdit />}
    />
    <Route path="/shifts/import" element={<ShiftImportWrapper />} />
    <Route path="/shifts/import/process" element={<ShiftImportProcess />} />
    <Route path="/shifts/quinyxResources/:id" element={<QuinyxEdit />} />
    <Route path="/shifts/quinyxResources/" element={<QuinyxList />} />
  </CustomRoutes>,

  <Resource {...patientResources} />,
  <Resource {...schedulerResources} />,
  <Resource {...workQueuesResources} />,
  <Resource {...clinicalPractitionerResources} />,
  <Resource {...shiftResources} />,
  <Resource {...appointmentResources} />,
  <Resource {...resourceManagementResources} />,
  <Resource {...clientResources} />,
  <Resource {...consultationOutputResources} />,
  <Resource {...messageThreadResources} />,
  <Resource {...resourcePoolResources}/>,
  <Resource {...pharmacyManagementResources}/>,
  <Resource {...gpSurgeriesResources}/>,
  <Resource {...clinicalServiceResources}/>,
  <Resource {...prescriptionResources}/>,
  <Resource {...productResources}/>,

  <Resource name="quinyxResources" />,
  <Resource name="gaia:pharmacies" />,
  <Resource name="appointments" />,
  <Resource name="bookings" />,
  <Resource name="rescheduleBooking" />,
  <Resource name="messageThreads" />,
  <Resource name="messages" />,
  <Resource name="currentUser" />,
  <Resource name="patientManagementSystems" />,
  <Resource name="expertiseAreas" />,
  <Resource name="dynamicTemplates" />,
  <Resource name="instructions" />,
  <Resource name="appointmentFilters" />,
  <Resource name="clientGroups" />,
  <Resource name="payments" />,
  <Resource name="addresses" />,
  <Resource name="clientIdentifiers" />,
  <Resource name="proposedAppointments" />,
  <Resource name="patientTerms" />,
  <Resource name="googlePlaces" />,
];

async function updateResourcesBasedOnPermissions(r: JSX.Element[]) {
  const adjustResources: JSX.Element[] = [];

  for await (const resource of r) {
    const props = resource.props;

    if (!hasComponents(props)) {
      adjustResources.push(resource);
      continue;
    }

    const permissions = await authProvider.getPermissions({});
    const resourceName = props.name;
    const access = permissions[resourceName];

    if (!access) {
      adjustResources.push(resource);
      continue;
    }

    const { list, create, edit, show } = props;

    const newProps = {
      ...props,
      list: access.list ? list : undefined,
      create: access.create ? create : undefined,
      edit: access.edit ? edit : undefined,
      show: access.show ? show : undefined,
    };

    const updatedResource = <Resource {...newProps} />;

    adjustResources.push(updatedResource);
    continue;
  }

  return adjustResources;
}

async function updateResourcesBasedOnFeatureFlags(r: JSX.Element[]) {
  const featureFlags = FeatureFlagService.getInstance();
  const adjustedResources: JSX.Element[] = [];

  await featureFlags.client!.waitUntilReady();

  for await (const resource of r) {
    const props = resource.props;
    const flagKey = props.options?.flagKey?.name;

    if (!flagKey) {
      adjustedResources.push(resource);
      continue;
    }

    const flagValue = featureFlags.getFlag(flagKey);
    const transformFlagValue = props.options?.flagKey?.transformFlagValue;
    const displayInMenu = transformFlagValue
      ? transformFlagValue(flagValue)
      : flagValue;

    const newProps = {
      ...props,
      options: {
        ...props.options,
        displayInMenu,
      },
    };

    const updatedResource = <Resource {...newProps} />;
    adjustedResources.push(updatedResource);

    continue;
  }

  return adjustedResources;
}

const createResources = async () => {
  const updatedWithPermissions = await updateResourcesBasedOnPermissions(
    resources
  );

  const updatedWithFlags = await updateResourcesBasedOnFeatureFlags(
    updatedWithPermissions
  );

  updatedWithFlags.push(publicResources);

  return updatedWithFlags
};

export default createResources;
