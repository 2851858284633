import React, { useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Edit,
  useRecordContext,
  Title,
  useNotify,
  ResourceContextProvider,
} from "react-admin";

import validateEditBookingForm from "./validateEditBookingForm";
import { BookingEditActions } from "./BookingActions";
import {
  EditStateContextProvider,
  useEditStateContext,
} from "../../context/EditStateContext";
import { PatientTab } from "./tabs/PatientTab";
import { SummaryTab } from "./tabs/SummaryTab";
import { PmsTab } from "./tabs/PmsTab";
import { HealthmailTab } from "./tabs/HealthmailTab";
import { PaymentsTab } from "./tabs/PaymentsTab";
import { EventsTab } from "./tabs/EventsTab";
import { DocumentsTab } from "./tabs/DocumentsTab";
import get from "lodash/get";
import { TabLayoutView } from "../form/CustomTabForm/TabLayoutView";
import { FormTab } from "../form/CustomTabForm/FormTab";
import { BookingToolbar } from "./BookingToolbar";
import { styled } from "@mui/material";
import { VhiPaymentsTab } from "./tabs/VhiPaymentsTab";

const StyledLink = styled(Link)(({ theme }) => ({
  marginLeft: "16px",
  color: theme.palette.primary.main,
}));

function PatientNameTitle() {
  const record = useRecordContext();
  const firstName = get(record, "patients[0].attributes.firstName");
  const lastName = get(record, "patients[0].attributes.lastName");
  const bookingId = get(record, "bookingId");
  const patientId = get(record, "patients[0].id");
  const clientId = get(record, "clients[0].id");

  if (!record) return null;

  return (
    <>
      <Title title={`Booking #${bookingId}`} />
      <StyledLink to={`/patientRecords/${patientId}:${clientId}/general`}>
        {firstName} {lastName}
      </StyledLink>
    </>
  );
}

export function BookingEditInner() {
  const notify = useNotify();
  const { updateState } = useEditStateContext();
  const params = useParams<{
    id: string;
    userId: string;
    clientId: string;
  }>();

  const isClientVHI = params.clientId == '233';

  const onSuccess = useCallback(() => {
    updateState("show");
    notify("Changes were saved successfully");
  }, [updateState, notify]);

  return (
    <ResourceContextProvider value="bookings">
      <Edit
        mutationOptions={{
          onSuccess,
        }}
        title={<PatientNameTitle />}
        resource="bookings"
        id={`${params.id}:${params.userId}:${params.clientId}`}
        mutationMode="pessimistic"
        actions={<BookingEditActions />}
      >
        <TabLayoutView
          validate={validateEditBookingForm}
          Toolbar={BookingToolbar}
        >
          <SummaryTab path="summary" />
          <PatientTab path="patient" />
          <DocumentsTab path="documents"/>
          <PmsTab path="pms" />
          <FormTab label="Events" path="events" editable="hidden">
            <EventsTab />
          </FormTab>
          <HealthmailTab path="healthmail" />
          {isClientVHI ? <VhiPaymentsTab path="payments" /> : <PaymentsTab path="payments" />}
        </TabLayoutView>
      </Edit>
    </ResourceContextProvider>
  );
}

export function BookingEdit() {
  return (
    <EditStateContextProvider>
      <BookingEditInner />
    </EditStateContextProvider>
  );
}
