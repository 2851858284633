import dayjs from "dayjs";
import isNumber from "lodash/isNumber";

export const dateHasLeadingZeroDigit = (val: string | number) => {
  // Dayjs doesn’t handle leading zeroes in years very well
  // If a leading zero is present, don’t send the value through
  // to the dayjs function
  if (isNumber(val)) return false;

  const [year] = val.split("-");
  const leadingChar = year.split("")[0];
  const leadingDigit = Number(leadingChar);
  return leadingDigit === 0;
};

export const parseDateTime = (val: string | number | null) => {
  if (!val) return "";
  if (dateHasLeadingZeroDigit(val)) return val;
  return dayjs(val).format();
};
