import { FieldProps } from "ra-ui-materialui";
import get from "lodash/get";
import React from "react";
import dayjs from "dayjs";
import { Typography } from "@mui/material";
import { useRecordContext } from "ra-core";

interface Props extends FieldProps {
  startSource: string;
  endSource: string;
}

export function TimeRange(props: Props) {
  const { startSource, endSource } = props;
  const record = useRecordContext();
  const startTime = get(record, startSource);
  const endTime = get(record, endSource);

  const formattedStart = dayjs(startTime).format("HH:mm");
  const formattedEnd = dayjs(endTime).format("HH:mm");

  return (
    <Typography variant="body1">
      {formattedStart} - {formattedEnd}
    </Typography>
  );
}
