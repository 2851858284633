import { create } from "..";
import { ConsultationType } from "../../types/consultations";
import { convertToMAD } from "../appointments";

interface Args {
  userId: string;
  appointmentId: string;
  bookingId: string;
  clientId: string;
  consultationType: ConsultationType
}

async function swapBooking(args: Args) {
  const { userId, appointmentId, bookingId, clientId, consultationType } = args;
  const isMad = consultationType === "MessageDoctor";
  const consultationTypePathSegment = isMad ? "messageDoctors" : "bookings";

  if (isMad) {
    await convertToMAD({ id: appointmentId });
  }

  const endpoint = `/clients/${clientId}/users/${userId}/${consultationTypePathSegment}/${bookingId}/swap/${appointmentId}`;
  const { data } = await create(endpoint);

  return {
    data,
  };
}

export default swapBooking;
