import { GetListParams } from "ra-core";
import { list } from "..";
import { SendGridDynamicTemplatesDTO } from "../../types/notifications";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

async function getSendGridDynamicTemplates(params: GetListParams) {
  const { filter } = params;

  const { name } = filter;

  const queryParams = {
    "filter[name]": name ? `like:${name}` : "",
  };

  const query = shallowlyRemoveEmptyValues(queryParams);

  const endpoint = "/ms/sendgridDynamicTemplate";
  const { data, total }: SendGridDynamicTemplatesDTO = await list(endpoint, {
    params: query,
  });

  return { data, total };
}

export default getSendGridDynamicTemplates;
