import { ClientPatientManagementSystemMappingDTO } from "../../types/patientManagementSystems";
import recursiveIsObjectEmpty from "../../utilities/recursiveIsObjectEmpty";

type ErrorMessage = string;

interface AssignedPMSError {
  clientCodeId?: ErrorMessage;
  specialties?: ErrorMessage;
}

interface Errors {
  assignedPMS: AssignedPMSError[];
}

interface Values {
  assignedPMS: ClientPatientManagementSystemMappingDTO[];
}

function validateAssignedPMS(values: Values) {
  const { assignedPMS } = values;
  const errors: Errors = {
    assignedPMS: [],
  };

  if (assignedPMS) {
    const specialties = assignedPMS.flatMap(pms => pms?.specialties);

    assignedPMS.forEach((pms, i: number) => {
      if (!pms) return;

      const error: AssignedPMSError = {};

      if (pms.specialties.length > 0) {
        if (!pms.clientCodeId) {
          error.clientCodeId =
            "A client code must be selected to assign specialties.";
        }

        const findDuplicates = (arr: string[]) =>
          arr.filter((item, index) => arr.indexOf(item) != index);
        const containsDuplicateSpecialty = findDuplicates(specialties);

        if (containsDuplicateSpecialty.length > 0) {
          error.specialties =
            "A specialty cannot be assigned to more than one PMS.";
        }
      }

      const errorObjectIsEmpty = recursiveIsObjectEmpty(error);

      if (!errorObjectIsEmpty) {
        errors.assignedPMS[i] = error;
      }
    });
  }

  return errors;
}

export default validateAssignedPMS;
