import React from "react";
import {
  BooleanInput,
  Create,
  RaRecord,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
} from "react-admin";
import { resourceRoles } from "../../constants/constants";
import { EmailInput } from "../inputs/EmailInput";
import validateResource from "./validateCreateResourceForm";

export function ResourceCreate() {
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Create
      title="Create User"
      mutationOptions={{
        onSuccess: (newRecord: RaRecord) => {
          notify("Admin user was created successfully");
          redirect(`/resourceManagement/${newRecord.id}/show`);
        },
      }}
      transform={(record: RaRecord) => {
        const isCsoUser = record.role === "cso";
        record.attributes.isCsoUser = isCsoUser;
        return record;
      }}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        redirect="show"
        mode="onChange"
        validate={validateResource}
        toolbar={
          <Toolbar>
            <SaveButton label="Create User" size="medium" />
          </Toolbar>
        }
      >
        <EmailInput source="attributes.email" required />
        <TextInput source="attributes.firstName" label="First name" required />
        <TextInput source="attributes.lastName" label="Last name" required />
        <TextInput source="attributes.phoneNumber" label="Phone number" />
        <SelectInput
          source="role"
          label="Role"
          choices={resourceRoles}
          required
        />
        <BooleanInput source="attributes.isEnabled" label="Is Enabled" />
      </SimpleForm>
    </Create>
  );
}
