import { GetManyParams } from "ra-core";
import { get } from "..";

async function getManyClinicalPractitioners(args: GetManyParams) {
  const { ids } = args;

  const includes = ["resourceManagementRules"];

  const params = {
    "filter[id]": `in:${ids}`,
    include: includes.join(","),
    "page[size]": 100,
  };

  const { data } = await get("ms/clinicalPractitioners", params);

  return { data };
}

export default getManyClinicalPractitioners;
