/**
 *
 * Note: We could have easily used Object.keys(), but Object.keys() ignores any properties that have `{ enumerable: false }`.
 * Object.getOwnPropertyNames() is that it includes properties regardless of whether they are enumerable.
 * Chances are this makes 0 impact on our work, but who knows!
 */
function isObjectEmpty(obj: Record<string, unknown>) {
  const properties = Object.getOwnPropertyNames(obj);

  return properties.length === 0;
}

export default isObjectEmpty;
