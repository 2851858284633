import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { TextInput, useInput } from "react-admin";
import Button from "@mui/material/Button";

const PREFIX = "ImageField";

const classes = {
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  image: `${PREFIX}-image`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.input}`]: {
    display: "block",
    paddingBottom: theme.spacing(1),
    "& > div": {
      width: "260px",
    },
  },

  [`& .${classes.button}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.image}`]: {
    display: "block",
    maxHeight: "150px",
  },
}));

interface Props {
  source: string;
  label: string;
}

export function ImageURLField({ source, label, ...rest }: Props) {
  const { fieldState, field } = useInput({ source, label, ...rest });
  const { value } = field;
  const { invalid } = fieldState;

  const [isImageShown, setImageShown] = useState(false);
  const [isBrokenImage, setIsBrokenImage] = useState(false);

  const handleImageError = () => {
    setIsBrokenImage(true);
    setImageShown(false);
  };

  const validateImageURL = () => {
    if (isBrokenImage) {
      setIsBrokenImage(false);
    }
  };

  const toggleShowImage = () => setImageShown(!isImageShown);
  const isButtonDisplayed = !invalid && !!value && !isBrokenImage;
  const isImageDisplayed = isImageShown && !!value;
  const buttonText = isImageShown ? "Hide preview" : "Show preview";

  return (
    <Root>
      <TextInput
        className={classes.input}
        source={source}
        label={label}
        onChange={validateImageURL}
        error={isBrokenImage}
        helperText={isBrokenImage && "Cannot load image by this url"}
      />
      {isButtonDisplayed && (
        <Button
          className={classes.button}
          color="primary"
          variant="outlined"
          aria-label={buttonText}
          onClick={toggleShowImage}
        >
          {buttonText}
        </Button>
      )}
      {isImageDisplayed ? (
        <img
          onError={handleImageError}
          className={classes.image}
          src={value}
          alt="logo"
        />
      ) : null}
    </Root>
  );
}
