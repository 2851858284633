import { GetManyParams } from "ra-core";
import { get } from "..";

async function getManyClientCodes(params: GetManyParams) {
  const { ids } = params;

  const promises = [];

  for (const id of ids) {
    const request = get(`client-codes/${id}`);

    promises.push(request);
  }
  const data = await Promise.all(promises);

  const mappedData = data.map(val => val.data);

  return { data: mappedData };
}

export default getManyClientCodes;
