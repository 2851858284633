import { ClientPatientManagementSystemMappingDTO } from "../../../types/patientManagementSystems";
import { ClientCodeMappingsDTO } from "../../../types/clients";

export function getClientMappingsFromPMSData(
  pmsDetails: ClientPatientManagementSystemMappingDTO[],
  clientId: string,
  usedForUpdate = false,
  pmsToIgnore: string[] = []
): ClientCodeMappingsDTO["data"] {
  const clientMappings: any = [];

  pmsDetails.forEach((pmsDetail: any, index: any) => {
    if (pmsDetail.mappings) {
      pmsDetail.mappings.map((mapping: any) => {
        if (pmsToIgnore.includes(mapping.id)) {
          return;
        }
        clientMappings.push({
          ...(mapping.clientMapping ? mapping.clientMapping : {}),
          attributes: {
            clientId,
            clientPatientManagementSystemId: mapping.id,
            clientCodeId: usedForUpdate
              ? pmsDetails[index].clientCodeId
              : mapping.clientMapping?.attributes.clientCodeId,
          },
        });
      });
    }
  });

  return clientMappings;
}

export function createClientMappingsFromPmsDetails(
  pmsDetails: ClientPatientManagementSystemMappingDTO[],
  newPms: any,
  pmsToIgnore: string[],
  clientId: string
): ClientCodeMappingsDTO["data"] {
  const mappings = newPms.map((pms: any) => {
    return {
      attributes: {
        clientId,
        clientPatientManagementSystemId: pms.pmsCreateData.id,
        clientCodeId: pms.clientCodeId,
      },
    };
  });

  const existingMappings = getClientMappingsFromPMSData(
    pmsDetails,
    clientId,
    true,
    pmsToIgnore
  );

  return mappings.concat(existingMappings);
}

export default getClientMappingsFromPMSData;
