import { create } from "..";

interface Params {
  attributes: {
    code: string;
    patientManagementSystem: string;
  };
}

async function createClientCode(params: Params) {
  const { attributes } = params;

  const body = {
    data: {
      type: "client-code",
      attributes,
    },
  };

  const { data } = await create("client-codes", body);

  return { data };
}

export default createClientCode;
