import React from "react";
import Typography from "@mui/material/Typography";
import get from "lodash/get";
import formatAddress from "./formatAddress";
import {
  TextFieldProps,
  sanitizeFieldRestProps,
  useRecordContext,
  Labeled,
} from "react-admin";
import { EMPTY_TEXT } from "../../constants/constants";

const pharmacyFieldStyles = {
  marginBottom: "16px",
};

export function PharmacyAddressField(props: TextFieldProps) {
  const { source, emptyText, ...rest } = props;
  const record = useRecordContext();

  if (!record || !source) return null;

  const pharmacyRecord = get(record, source);

  if (!pharmacyRecord)
    return (
      <Labeled label="Pharmacy Address">
        <Typography
          component="div"
          variant="body1"
          sx={pharmacyFieldStyles}
          {...sanitizeFieldRestProps(rest)}
        >
          {EMPTY_TEXT}
        </Typography>
      </Labeled>
    );

  const details = {
    ...pharmacyRecord.address,
  };

  const formattedAddress = formatAddress(details);

  return (
    <Labeled label="Pharmacy Address">
      <Typography
        component="div"
        variant="body1"
        sx={pharmacyFieldStyles}
        {...sanitizeFieldRestProps(rest)}
      >
        {formattedAddress}
      </Typography>
    </Labeled>
  );
}
