import { get } from "../gaiaClient";
import { v4 as uuid } from "uuid";
import { ClinicalSummaryQuestion } from "../../types/prescriptions";

interface Args {
  tenantId: string;
  traversalId: string;
}


const getClinicalSummaryQuestions = async (args: Args) : Promise<{data: ClinicalSummaryQuestion[]}> => {
  const { tenantId, traversalId } = args;

  const questionsEndpoint = `${tenantId}/traversals/${traversalId}/questions`;
  const questions: {data: ClinicalSummaryQuestion[]} = await get(questionsEndpoint, {
    headers: {
      "x-correlation-id": uuid(),
    }
  })

  return questions;
}

export default getClinicalSummaryQuestions;
