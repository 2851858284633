import { RaRecord } from "ra-core";
import recursiveIsObjectEmpty from "../utilities/recursiveIsObjectEmpty";
import { DEFAULT_EMAIL } from "../constants/constants";

function transformAddresses(values: RaRecord) {
  const { gp, nominatedPharmacy } = values;

  const gpIsEmpty = recursiveIsObjectEmpty(gp);
  const pharmacyIsEmpty = recursiveIsObjectEmpty(nominatedPharmacy);

  if (gpIsEmpty) {
    values.gp = null;
  } else {
    if (!values.gp?.surgery?.email) {
      values.gp.surgery.email = DEFAULT_EMAIL;
    }

    if (!values.gp?.surgery?.phoneNumber) {
      values.gp.surgery.phoneNumber = "000";
    }
  }

  if (pharmacyIsEmpty) {
    values.nominatedPharmacy = null;
  } else {
    if (!values.nominatedPharmacy.email) {
      values.nominatedPharmacy.email = DEFAULT_EMAIL;
    }

    if (!values.nominatedPharmacy.phoneNumber) {
      values.nominatedPharmacy.phoneNumber = "000";
    }
  }

  return values;
}

export default transformAddresses;
