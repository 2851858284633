import React from "react";
import {
  ArrayField,
  Datagrid,
  ReferenceField,
  Show,
  TextField,
  Title,
  useRecordContext,
} from "react-admin";
import { CapsDelimitedField } from "../fields/CapsDelimitedField";
import { Tab } from "../Tabs";
import capitalize from "lodash/capitalize";
import get from "lodash/get";
import { GeneralDetailsShowTab } from "./tabs/GeneralDetailsShowTab";
import { ScrollableTabbedShowLayout } from "../ScrollableTabLayouts";
import DateField from "../fields/DateField";

function PageTitle() {
  const record = useRecordContext();

  const firstName = get(
    record,
    "clinicalPractitioners[0].attributes.firstName"
  );
  const lastName = get(record, "clinicalPractitioners[0].attributes.lastName");
  const id = get(record, "id");

  const information = `Shift #${id} (${capitalize(firstName)} ${capitalize(
    lastName
  )})`;
  return <Title title={record ? information : "Shift"} />;
}

/**
 * This is a hack to resolve an issue for 'sticky' positioned elements.
 * 'sticky' elements can only work if no ancestor in the DOM has 'overflow' set to 'hidden'.
 *
 * The default 'overflow' setting for the 'card' component, which is used under-the-hood for all Show/Edit components, is 'hidden'.
 * In a previous MR we reset this value globally to accomodate for a 'sticky' button in the ShiftShow page,
 * but this caused the layout styles to break for pages with lots of tabs. This is because the tabs need 'overflow: hidden' to fit within the page.
 *
 * On this page we don't need scrollable tabs, so we can revert the card's overflow value and get the benefit of using 'sticky' positioning.
 */

const rootStyles = {
  "& .RaShow-card": {
    overflow: "initial",
  },
};

export function ShiftShow() {
  return (
    <Show title={<PageTitle />} sx={rootStyles}>
      <ScrollableTabbedShowLayout>
        <GeneralDetailsShowTab />
        <Tab label="Events">
          <ArrayField source="shiftEvents" label={false}>
            <Datagrid bulkActionButtons={false}>
              <TextField source="id" label="ID" />
              <TextField source="attributes.status" label="Status" />
              <CapsDelimitedField
                source="attributes.eventType"
                label="Event type"
                hideLabel
              />
              <DateField
                showTime
                hideLabel
                source="attributes.createdAt"
                label="Created at"
              />
              <TextField source="userSummaries[0].attributes.userName" label="User" />
            </Datagrid>
          </ArrayField>
        </Tab>
      </ScrollableTabbedShowLayout>
    </Show>
  );
}
