import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import { TextArrayField } from "../fields/TextArrayField";
import { ClinicalPractitionerFilter } from "./ClinicalPractitionerFilter";
import { ClinicalPractitionerListActions } from "./ClinicalPractitionerActions";
import { BooleanField } from "../fields/BooleanField";
import { ListWithoutFilterEmptyText, removeDefaultEmptyMessageStyle } from "../shared/ListHelpers";

export type ClinicalPractitionerListQueryFilter = {
  firstName: string;
  lastName: string;
  email: string;
}

export type ClinicalPractitionerListQueryParameters = {
  displayedFilters: string;
  filter: string;
  order: string;
  page: number;
  perPage: number;
  sort: string;
}

export function ClinicalPractitionerList() {
  return (
    <List
      filterDefaultValues={{ignoreWithoutFilters: true}}
      sx={removeDefaultEmptyMessageStyle}
      filters={<ClinicalPractitionerFilter />}
      title="Clinical Practitioners"
      actions={<ClinicalPractitionerListActions />}
      filter={{showDisabled: true}}
      // queryOptions={{meta: {ignoreWithoutFilters: true}}}
    >
      <Datagrid bulkActionButtons={false} rowClick="show" empty={<ListWithoutFilterEmptyText/>}>
        <TextField sortable={false} source="id" label="ID" />
        <TextField
          sortable={false}
          source="attributes.firstName"
          label="First name"
        />
        <TextField
          sortable={false}
          source="attributes.lastName"
          label="Last name"
        />
        <TextField sortable={false} source="attributes.email" label="Email" />
        <TextArrayField
          hideLabel
          source="attributes.specialties"
          label="Specialties"
        />
        <BooleanField sortable={false} source="attributes.isEnabled" label="Enabled"/>
      </Datagrid>
    </List>
  );
}
