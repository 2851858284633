import React from "react";
import { FormDataConsumer } from "ra-core";
import { BooleanInput, TextInput, SelectInput } from "ra-ui-materialui";
import { FormTab } from "../../Tabs";
import { Heading } from "../../ui/Heading";
import get from "lodash/get";
import { EditTabProps } from "../../../types/utilities";
import { stripePaymentModeChoices} from "../../../constants/constants";
import TooltipAdornment from "../../booking/TooltipAdornment";

const inputStyles = {
  display: "block",
  "& > div": {
    width: "256px",
  },
};

const headingStyles = {
  display: "flex",
  alignItems: "center",
  width: "100%",
};

export function PaymentEditTab(props: EditTabProps) {
  return (
    <FormTab {...props} label="Payments">
      <Heading level={2}>Payment Configuration</Heading>
      <BooleanInput
        source="payments.attributes.isPaymentQueryEnabled"
        label="Enable Payment"
      />
      <FormDataConsumer>
        {({ formData }) => {
          const isPaymentEnabled = get(
            formData,
            "payments.attributes.isPaymentQueryEnabled"
          );

          if (!isPaymentEnabled) return null;

          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Heading level={3} sx={headingStyles}>
                Payment Eligibility
                <TooltipAdornment text={"Payment Eligibility configuration parameters"} />
              </Heading>
              <BooleanInput
                source="payments.attributes.isPspEnabled"
                label="Enable Payment Service Provider (PSP) Integration"
              />
              <BooleanInput
                source="payments.attributes.isVoucherEnabled"
                label="Enable Vouchers"
              />
              <SelectInput
                sx={inputStyles}
                choices={stripePaymentModeChoices}
                label="Stripe Payment Mode"
                source="payments.attributes.stripePaymentMode"
                required
              />
              <TextInput
                label="Connected Stripe Account ID"
                source="payments.attributes.stripeAccountId"                
              />
            </div>
          );
        }}
      </FormDataConsumer>
    </FormTab>
  );
}
