import { ReferenceField, TextField, useRecordContext } from "react-admin";
import get from "lodash/get";
import { ConclusionReport } from "../../../types/prescriptions";
import { Group } from "../../layout/Group";
import { Heading } from "../../ui/Heading";
import { Grid, Stack, Typography } from "@mui/material";
import { styles } from "../PrescriptionsShow";
import React from "react";
import { groupBy } from "../../../utilities/groupBy";
import { Labeled } from "ra-ui-materialui";
import DateField from "../../fields/DateField";


export function OutcomesGroup() {
  const record = useRecordContext();
  const conclusionReport = get(record, "traversal.conclusionReport") as ConclusionReport;

  const isCompleted = record.status === "Completed";

  if (!isCompleted) {
    return (<Group heading={<Heading level={2}>Outcomes</Heading>}>
      <TraversalOutcome conclusionReport={conclusionReport} />
      <Reasons conclusionReport={conclusionReport} />
    </Group>);
  }


  return (<Group heading={<Heading level={2}>Outcomes</Heading>}>
    <Stack>
      <TraversalOutcome conclusionReport={conclusionReport} />
      <ClinicalOutcome />
      <Labeled label={"Actioned by"}>
        <ReferenceField
          source="actioningPractitionerId"
          reference="clinicalPractitioners"
          label="Actioned by"
          link={false}
          emptyText="n/a"
        >
          <TextField source="attributes.fullName" />
        </ReferenceField>
      </Labeled>
      <DateField label={"Completed at"} source="completedAt" />
      <Reasons conclusionReport={conclusionReport} />
      <Medications />
    </Stack>
  </Group>);

}

function ClinicalOutcome() {
  const record = useRecordContext();
  const outcome = record.outcome;

  if (!outcome) {
    return null;
  }

  return (<>
    <Grid item>
      <Labeled label={"Clinical outcome"}>
        <Typography>
          {outcome}
        </Typography>
      </Labeled>
    </Grid>
  </>);
}


function Medications() {
  const record = useRecordContext();
  const outcome = record.outcome;

  if (outcome === "Approval") {
    return <ApprovedMedications />;
  }

  if (outcome === "Rejection") {
    return <RejectedMedications />;
  }

  if (outcome === "Recommendation") {
    return <RecommendedMedications />;
  }

  if (outcome === "RejectionWithRecommendation") {
    return (<>
      <RecommendedMedications />
      <RejectedMedications />
    </>);
  }

  return null;
}


function ApprovedMedications() {
  const record = useRecordContext();
  const medicationRequests = record.medicationRequests;

  if (!medicationRequests.length) {
    return null;
  }


  return (<>
    <Grid item xs={12}>
      <Typography variant={"h4"}>
        Approved medications
      </Typography>
      {medicationRequests.map((medication: any) => (
        <Grid key={medication.code} sx={styles.subCategoriesContainer} container>
          <Grid item>
            <Typography sx={styles.subCategoryHeader}>
              {`${medication.medication} ${medication.quantity} ${medication.unitOfMeasure}`}
            </Typography>
            {medication.dosageInstructions}
          </Grid>
        </Grid>
      ))}
    </Grid>
  </>);


}


function RejectedMedications() {
  const record = useRecordContext();

  const rejectedMedications = record.rejectedMedications;

  if (!rejectedMedications?.length) return null;
  const medicationRequestIds = rejectedMedications.map((x: any) => x.medicationRequestId);


  const medicationRequests = record.medicationRequests.filter((x: any) => medicationRequestIds.includes(x.medicationRequestId));

  if (!medicationRequests?.length) return null;


  return (<>
    <Grid item xs={12}>
      <Typography variant={"h4"}>
        Rejected medications
      </Typography>
      {medicationRequests.map((medication: any) => (
        <Grid key={medication.code} sx={styles.subCategoriesContainer} container>
          <Grid item>
            <Typography sx={styles.subCategoryHeader}>
              {`${medication.medication} ${medication.quantity} ${medication.unitOfMeasure}`}
            </Typography>
            <Typography>
              {rejectedMedications.find((r: any) => r.medicationRequestId === medication.medicationRequestId)?.display}
            </Typography>
          </Grid>
        </Grid>
      ))}

    </Grid>
  </>);
}


function RecommendedMedications() {
  const record = useRecordContext();

  const recommendedMedications = record.recommendedMedications;

  if (!recommendedMedications?.length) return null;


  return (<>
    <Grid item xs={12}>
      <Typography variant={"h4"}>
        Recommended medications
      </Typography>
      <ul>
      {recommendedMedications.map((medication: any) => (
        <li key={medication.code}>
        <Grid key={medication.code} sx={styles.subCategoriesContainer} container>
          <Grid item>
            <Typography sx={styles.subCategoryHeader}>
              {medication.display}
            </Typography>
          </Grid>
        </Grid>
        </li>
      ))
      }
      </ul>

    </Grid>
  </>);
}


interface PropsWithConsultationReport {
  conclusionReport: ConclusionReport;
}

function TraversalOutcome(props: PropsWithConsultationReport) {
  const { conclusionReport } = props;
  const traversalOutcome = conclusionReport?.informationConclusions
    .find((conclusion) => conclusion.category1.toLowerCase() === "outcome")?.displayText;

  if (!traversalOutcome) return null;

  return (<>
    <Grid item>
      <Labeled label="Traversal outcome">
        <Grid item xs={8}>
          {traversalOutcome}
        </Grid>
      </Labeled>
    </Grid>

  </>);

}


function Reasons(props: PropsWithConsultationReport) {
  const { conclusionReport } = props;

  if (!conclusionReport?.informationConclusions?.length) return null;

  const validConclusions = conclusionReport.informationConclusions
    .filter((conclusion) => conclusion.category1.toLowerCase() === "reason");

  const groupedConclusions = groupBy(validConclusions, "subCategory");

  if (!Object.keys(groupedConclusions).length) return null;


  return (
    <>
      <Grid item xs={12} data-testid={"reason-group"} >
        <Typography variant={"h3"}>
          Reason
        </Typography>
      </Grid>
      {Object.keys(groupedConclusions).map((conclusionName: string, conclusionNameIndex) => (
        <Grid key={conclusionNameIndex} sx={styles.subCategoriesContainer} container>
          <Grid item>
            <Typography sx={styles.subCategoryHeader}>
              {conclusionName}
            </Typography>
            <ul style={{ padding: "0 10px", margin: 0 }}>
              {groupedConclusions[conclusionName]?.map((conclusion, conclusionIndex) => (
                <li
                  key={conclusionIndex}
                  style={styles.summaryGroup}
                  dangerouslySetInnerHTML={{
                    __html: conclusion.displayText
                  }}
                />
              ))}
            </ul>
          </Grid>
        </Grid>
      ))}
    </>);
}
