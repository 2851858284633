import { getConfig } from "../../../config";

export function isVnetAssociatedClient(client: any) {
  return Boolean(client?.assignedPMS?.find((x: any) =>
    x.patientManagementSystemType.toLowerCase() === "vnet2"
  ));
}

export function isVNetCaseService(client: any, service: any) {
  const config = getConfig();
  const ihcCaseServices = [...config.vnet.ihcCaseClinicalServiceIds];
  return isVnetAssociatedClient(client) && ihcCaseServices.includes(service);
}

export function isVNetManualIntakeService(client: any, service: any){
  const config = getConfig();  
  const manualIntakeServices = [...config.vnet.manualIntakeSpecialties, ...config.vnet.manualIntakeClinicalServiceIds];
  return isVnetAssociatedClient(client) && manualIntakeServices.includes(service);
}

export function isManualIntakeEligibleBooking(booking: any) {
  const config = getConfig();
  const clinicalServiceId = booking?.appointments[0]?.attributes?.clinicalServiceId;

  if (clinicalServiceId && config.vnet.manualIntakeClinicalServiceIds.includes(clinicalServiceId)) {
    return true;
  }

  const specialties = booking?.appointments[0]?.attributes?.specialties;

  if (specialties && config.vnet.manualIntakeSpecialties.find((x: string) => specialties.includes(x))) {
    return true;
  }

  return false;
}