import React from "react";
import { Button } from "ra-ui-materialui";
import { Link } from "react-router-dom";
import AssignmentIcon from "@mui/icons-material/Assignment";

export function QuinyxButton() {
  return (
    <Button
      sx={{ margin: "0 8px" }}
      component={Link}
      to="/shifts/quinyxResources/"
      color="primary"
      variant="outlined"
      size="medium"
      label="Assign Quinyx Resources"
    >
      <AssignmentIcon />
    </Button>
  );
}
