import dayjs from "dayjs";
import { useGetList } from "react-admin";

interface Props {
  clientId?: string;
}

export function useNextAvailableAppointmentController(props: Props) {
  const { clientId } = props;
  const start = dayjs().add(1, "hour").second(0).format();

  const filterParams = {
    start,
    status: "in:Available",
    specialities: "GeneralPractitioner",
    clientId,
  };

  const res = useGetList(
    "appointments",
    {
      pagination: { page: 0, perPage: 10 },
      sort: { field: "start", order: "ASC" },
      filter: filterParams,
    },
    {
      enabled: Boolean(clientId),
    }
  );

  return res;
}
