import React from "react";
import { Heading } from "../ui/Heading";
import { BooleanInput, TextInput } from "react-admin";
import { CommentsInput } from "./CommentsInput";
import { PatientAddressForm } from "../inputs/PatientAddress/PatientAddressInput";
import { Group } from "../layout/Group";
import { Grid } from "@mui/material";
import { BookingAttachmentInput } from "./BookingAttachmentInput";

export function PatientDetailsInputs() {
  return (
    <Group heading={<Heading level={2}>Patient details</Heading>}>
      <Grid item xs={12}>
        <Heading level={3}>Location at time of appointment</Heading>
      </Grid>
      <Grid item xs={12} sm={6}>
        <PatientAddressForm sourcePrefix="address" />
      </Grid>
      <Grid item xs={12}>
        <TextInput source="phoneNumber" label="Telephone" required />
      </Grid>
      <Grid item xs={12}>
        <TextInput source="secondaryPhoneNumber" label="Secondary Telephone" />
      </Grid>
      <Grid item xs={12}>
        <BooleanInput
          source="acceptHiddenCalls"
          label="Phone accepts calls from withheld numbers"
        />
      </Grid>
      <Grid item xs={12}>
        <CommentsInput />
      </Grid>
      <Grid item xs={12}>
        <Heading level={3}>Documents</Heading>
      </Grid>
      <BookingAttachmentInput />
    </Group>
  );
}
