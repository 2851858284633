import { create } from "..";
import { IdentityVerificationColor } from "../../theme";

interface Data {
  clientId: string,
  userId: string,
  postVerifyBaseUrl: string
}


async function resendVerificationLink(data: Data) {
  const { clientId, userId, postVerifyBaseUrl } = data;

  const url = `/clients/${clientId}/users/${userId}/identity-verification`;
  const requestBody = {
    data: {
      type: "identityVerifications",
      attributes: {
        successUrl: `https://${postVerifyBaseUrl}/`,
        errorUrl: `https://${postVerifyBaseUrl}/verify-identity`,
        privacyPolicyUrl: 'https://www.healthhero.com/privacy-policy',
        shouldSendNotification: true,
        ...IdentityVerificationColor
      }
    }
  }

  await create(url, requestBody);

  return {
    data: { id: "id-to-satisfy-ra" },
  };

}


export default resendVerificationLink;
