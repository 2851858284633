import { getFile } from ".";
import downloadBlob from "./downloadBlob";

interface Params {
  consultationId: string;
  outputId: string;
  fileName: string;
}

async function downloadUnencryptedOutput(params: Params) {
  const { consultationId, outputId, fileName } = params;

  const endpoint = `/ms/consultations/${consultationId}/downloadOutputUnencrypted/${outputId}`;

  const { data: blob } = await getFile(endpoint);

  downloadBlob(blob, fileName);

  return {
    data: {
      id: "id-to-satisfy",
    },
  };
}

export default downloadUnencryptedOutput;
