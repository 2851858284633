import { update } from "..";
import { EmployerSettingsDTO } from "../../types/employerSettings";
import deepEquals from "../../utilities/deepEquals";
import { createEmployerSettings } from "./createEmployerSettings";

type Args = {
  clientId: string;
  employerSettings: EmployerSettingsDTO["data"];
  oldEmployerSettings: EmployerSettingsDTO["data"];
};

async function updateEmployerSettings(args: Args) {
  const { clientId, employerSettings, oldEmployerSettings } = args;
  const baseClientEndpoint = `/ms/clients/${clientId}`;

  const noChange = deepEquals(
    employerSettings,
    oldEmployerSettings
  );

  if (noChange) {
    return null;
  }

  try {
    const { id: employerSettingId } = employerSettings;

    if (!employerSettingId) {
      const res = await createEmployerSettings({
        clientId,
        settings: employerSettings.attributes,
      });

      return res;
    }

    const updateSettingEndpoint = `${baseClientEndpoint}/employerSettings/${employerSettingId}`;
    const res = update(updateSettingEndpoint, {
      data: employerSettings,
    });

    return res;
  } catch (err) {
    console.error(err);
  }
}

export default updateEmployerSettings;
