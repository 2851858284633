import React from "react";
import { useRecordContext } from "ra-core";
import { Button } from "ra-ui-materialui";
import { PmsPatientCaseManualLink } from "../../../../types/patientManagementSystems";
import { HealthmailListRaRecord } from "../../../../types/healthmail";

interface CaseLinkButtonParams {
  onLinkClick: (caseToLink: PmsPatientCaseManualLink) => void;
}

export function CaseLinkButton(props: CaseLinkButtonParams) {
  const record : HealthmailListRaRecord = useRecordContext();

  const convertRecordToCase = (recordToConvert: HealthmailListRaRecord) => {
    return {
        caseNumber: recordToConvert.caseNumber,
        caseReference: recordToConvert.caseReference,
        patientReference: recordToConvert.patientReference,
    };
  };

  return (
    <Button
      sx={{ margin: 1 }}
      size="small"
      label="Link"
      aria-label="Link"
      variant="contained"
      onClick={() => props.onLinkClick(convertRecordToCase(record))}
      data-cy="case-link-button"
    />
  );
}