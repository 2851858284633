const clientFunctionEndpoints = {
  wpa: {
    local: "https://ebbc72599992af7756016fe5dc79ccab.m.pipedream.net/",
    development: "https://ebbc72599992af7756016fe5dc79ccab.m.pipedream.net/",
    uat: `https://ms-uat-msapi-client-wpa-validation.azurewebsites.net/api/ms-msapi-function-client-wpa-validation?code=${process.env.REACT_APP_WPA_AUTH_CODE}`,
    prod: `https://ms-live-msapi-client-wpa-validation.azurewebsites.net/api/ms-msapi-function-client-wpa-validation?code=${process.env.REACT_APP_WPA_AUTH_CODE}`,
    sandbox: `https://sandbox-msapi-client-validation-wpa.azurewebsites.net/api/ms-msapi-function-client-validation-wpa?code=${process.env.REACT_APP_WPA_AUTH_CODE}`,
  },
};

type ClientName = keyof typeof clientFunctionEndpoints;

export function getClientFunctionEndpoints(clientName?: ClientName) {
  if (!clientName) return "";

  const env = process.env["REACT_APP_ENV"] ?? "development";
  const endpoint = clientFunctionEndpoints[clientName][env];

  return endpoint;
}

export function getClientFunctionName(clientEndpoint?: string) {
  if (!clientEndpoint) return "";

  const env = process.env["REACT_APP_ENV"] ?? "development";

  const clientName = Object.keys(clientFunctionEndpoints).find(name => {
    return clientEndpoint === clientFunctionEndpoints[name as ClientName][env];
  });

  return clientName ?? "";
}
