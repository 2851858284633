import { RaRecord } from "react-admin";

export const renderGaiaPatientName = (record?: RaRecord) => {
  return renderGaiaNameBase("patient", record);
};

export const renderGaiaPractitionerName = (record?: RaRecord) => {
  return renderGaiaNameBase("", record);
};

const renderGaiaNameBase = (resource = "", record?: RaRecord) => {
  if (!record) return "";

  if (!resource.length) {
    return `${record.givenName} ${record.familyName}`;
  }

  return `${record[resource].givenName} ${record[resource].familyName}`;
};
