import { GetListParams } from "react-admin";
import { baseUrl, list } from "../";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";
import { CLIENT_DISCARDED_ID, CLIENT_LIVE_ID } from "../../constants/constants";
import isObjectEmpty from "../../utilities/isObjectEmpty";

const apiUrl = `${baseUrl}/ms`;

async function listClients(params: GetListParams) {
  const { pagination, filter, sort } = params;
  const { page, perPage } = pagination;
  const { field, order } = sort;

  const sortQuery = `${order === "DESC" ? "-" : ""}${field}`;

  const searchFilters = shallowlyRemoveEmptyValues({
    "filter[name]": filter.name || "",
    "filter[id]": filter.id || "",
  });
  const hasNoFilter = isObjectEmpty(searchFilters);

  if (filter?.ignoreWithoutFilters && hasNoFilter) {
    return {
      data: [],
      total: 0,
    };
  }

  const query = {
    "page[number]": page,
    "page[size]": perPage,
    "filter[clientStatusID]": filter.shouldShowDiscardedClients ? CLIENT_DISCARDED_ID : CLIENT_LIVE_ID,
    ...searchFilters,
    sort: sortQuery,
  };

  const queryParams = shallowlyRemoveEmptyValues(query);

  const { data, total } = await list(`${apiUrl}/clients`, {
    params: queryParams,
  });

  return { data, total };
}

export default listClients;
