import { ClientBookingChannelsDTO } from "../../../types/clients";


const getDefaultClientBookingChannels =  (clientId: string) :ClientBookingChannelsDTO["data"] => ({
  id: "",
  type: "clientBookingChannels",
  attributes: {
    clientId: clientId,
    phone: false,
    patientPortal: false,
    clientApp: false,
    symbio: false
  }
})

const formatClientBookingChannels = (
  clientId: string,
  bookingChannels: ClientBookingChannelsDTO["data"][] = []
) => bookingChannels[0] ?? getDefaultClientBookingChannels(clientId)

export default formatClientBookingChannels;
