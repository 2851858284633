import { Identifier } from "ra-core";
import { create } from "..";

interface Args {
  id: Identifier;
}

async function convertToMAD(args: Args) {
  const endpoint = `/ms/appointments/${args.id}/convertToMessageDoctor`;

  await create(endpoint);

  return {
    data: {
      id: "id-to-satisfy-ra",
    },
  };
}

export default convertToMAD;
