import React from "react"
import { Show, Title, useRecordContext } from "react-admin";
import { GeneralTab } from "./GeneralTab/GeneralTab";
import { FullDetailsTab } from "./FullDetailsTab";
import { TabbedShowLayout } from "ra-ui-materialui";
import { SummaryTab } from "./SummaryTab";
import { DocumentsTab } from "./DocumentsTab/DocumentsTab";
import { ClinicalNotesTab } from "./ClinicalNotesTab";
import { MessagingTab } from "./MessagingTab";

export const styles = {
  "& .MuiTabs-flexContainer": {
    backgroundColor: "#ffffff"
  },
  "& .MuiDivider-root": {
    display: "none",
  },
  subCategoriesContainer: {
    padding: 0
  },
  subCategoryHeader: {
    fontWeight: "bold",
    marginBottom: "10px"
  },
  summaryGroup: {
    marginBottom: "10px"
  }
}

function PrescriptionTitle() {
  const record = useRecordContext();
  const title = `Prescription #${record.identifier}`;

  return <Title title={title}/>
}

export function PrescriptionsShow() {
  return (
    <Show
      resource="prescriptions"
      title={<PrescriptionTitle/>}
      actions={false}
      emptyWhileLoading={true}
    >
      <TabbedShowLayout sx={styles} spacing={0} syncWithLocation={false}>
        <GeneralTab/>
        <SummaryTab/>
        <FullDetailsTab/>
        <DocumentsTab/>
        <ClinicalNotesTab/>
        <MessagingTab/>
      </TabbedShowLayout>
    </Show>
  )
}
