import React from "react";
import { ArrayField, TextField, Datagrid } from "react-admin";
import {
  isCpEnabledMessage,
  isCpExternalMessage,
} from "../../../constants/tooltipMessages";
import { ShowTabProps } from "../../../types/utilities";
import TooltipAdornment from "../../booking/TooltipAdornment";
import { AdornedField } from "../../fields/AdornedField";
import { BooleanField } from "../../fields/BooleanField";
import { CapsDelimitedField } from "../../fields/CapsDelimitedField";
import { DownloadsField } from "../../fields/DownloadsField";
import { TextArrayField } from "../../fields/TextArrayField";
import { Tab } from "../../Tabs";
import { Heading } from "../../ui/Heading";
import { CPSpecificFields } from "../CPSpecificFields";

const downloadSources = {
  identityUserId: "attributes.identityUserId",
  fileId: "relationships.file.data.id",
  fileName: "attributes.description",
  id: "id",
  clinicalPractitionerAttachmentType: "attributes.clinicalPractitionerAttachmentType",
};

export function GeneralDetailsShowTab(props: ShowTabProps) {
  return (
    <Tab {...props} label="General">
      <Heading level={2}>CP Attributes</Heading>
      <AdornedField
        source="attributes.isEnabled"
        label="Is Enabled"
        Field={BooleanField}
        adornment={<TooltipAdornment text={isCpEnabledMessage} />}
      />
      <AdornedField
        source="attributes.isExternal"
        label="Is External"
        Field={BooleanField}
        adornment={<TooltipAdornment text={isCpExternalMessage} />}
      />
      <TextField source="attributes.extraCompliance" label="Compliance Level" />

      <Heading level={2}>Personal Details</Heading>
      <TextField source="attributes.email" label="Email" />
      <TextField source="attributes.userName" label="Username" />
      <TextField source="attributes.firstName" label="First Name" />
      <TextField source="attributes.lastName" label="Last Name" />
      <TextField source="attributes.gender" label="Gender" />
      <TextArrayField
        source="attributes.consultationTypes"
        label="Consultation Types"
        hideLabel
      />

      <Heading level={2}>Specialty Details</Heading>
      <CapsDelimitedField
        source="attributes.specialty"
        label="Specialty"
        hideLabel
      />
      <TextArrayField
        hideLabel
        source="attributes.expertiseAreas"
        label="Expertise"
      />
      <CPSpecificFields />
      <DownloadsField
        label="Clinical Practitioner Documents"
        source="clinicalPractitionerAttachments"
        hideLabel
        downloadSources={downloadSources}
        fields={[
          {
            label: "Type",
            source: "attributes.clinicalPractitionerAttachmentType",
          },
        ]}
      />
      <ArrayField source="resourcePools" label="Resource Pools">
        <Datagrid optimized bulkActionButtons={false}>
          <TextField source="id" label="Resource Pool Id" sortable={false} />
          <TextField
            source="attributes.name"
            label="Name"
            sortable={false}
          />
          <BooleanField
            sortable={false}
            source="attributes.isEnabled"
            label="Is Enabled"
          />
        </Datagrid>
      </ArrayField>
    </Tab>
  );
}
