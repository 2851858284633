import { baseUrl, update } from "../";
import { PatientRecordDTO } from "../../types/patients";
import recursiveIsObjectEmpty from "../../utilities/recursiveIsObjectEmpty";

export interface Body {
  attributes: Partial<PatientRecordDTO["data"]["attributes"]>;
  clientId: string;
  userId: string;
  id: string;
}

async function updatePatientClinicalDetails(body: Body) {
  const { clientId, userId, ...requestBody } = body;

  const { id } = requestBody;

  const gpIsEmpty = recursiveIsObjectEmpty(requestBody.attributes.gp);
  const pharmacyIsEmpty = recursiveIsObjectEmpty(
    requestBody.attributes.nominatedPharmacy
  );

  if (gpIsEmpty) {
    requestBody.attributes.gp = null;
  }

  if (pharmacyIsEmpty) {
    requestBody.attributes.nominatedPharmacy = null;
  } else {
    requestBody.attributes.nominatedPharmacy!.organisationId = undefined;
  }

  const updateRecordEndpoint = `${baseUrl}/clients/${clientId}/users/${userId}/patientRecord/${id}`;

  await update(updateRecordEndpoint, {
    data: requestBody,
  });

  return;
}

export default updatePatientClinicalDetails;
