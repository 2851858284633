import React from "react";
import {
  Create,
  SelectInput,
  SimpleForm,
  Toolbar,
  TextInput,
  RaRecord,
  BooleanInput,
  SaveButton,
  useRedirect,
  useNotify,
  CheckboxGroupInput,
} from "react-admin";
import {
  cpConsultationPreferences,
  genderChoices,
} from "../../constants/constants";
import { countrySelectChoices } from "../../data/countryCodes";
import { complianceLevels } from "../../constants/constants";
import { Heading } from "../ui/Heading";
import validateCreateCPForm from "./validateCreateCPForm";
import {
  CPExpertiseInput,
  CPSpecificInputs,
  CPSpecialityInput,
} from "./CPSpecificInputs";
import { AdornedField } from "../fields/AdornedField";
import TooltipAdornment from "../booking/TooltipAdornment";
import { isCpExternalMessage } from "../../constants/tooltipMessages";
import { AppointmentInputWrapper } from "./AppointmentInputs";
import { CPDocumentsInput } from "./CPDocumentInputs";
import { EmailInput } from "../inputs/EmailInput";
import { booleanInputAdornmentStyles } from "../../theme";

export function ClinicalPractitionerCreate() {
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Create
      title="Create Clinical Practitioner"
      mutationOptions={{
        onSuccess: (newRecord: RaRecord) => {
          const id = newRecord.id;

          notify("Clinical Practitioner was created successfully");
          redirect(`/clinicalPractitioners/${id}/show`);
        },
      }}
      transform={(newRecord: RaRecord) => {
        const { specialty, consultationTypes } = newRecord.attributes;

        newRecord.attributes.specialties = [specialty];
        newRecord.attributes.isVideoAllowed = consultationTypes?.includes("Video");
        newRecord.attributes.isPhoneAllowed = consultationTypes?.includes("Phone");
        newRecord.attributes.isChatAllowed = consultationTypes?.includes("Chat");
        newRecord.attributes.isOnsiteAllowed = consultationTypes?.includes("Onsite");

        return newRecord;
      }}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        validate={validateCreateCPForm}
        noValidate
        toolbar={
          <Toolbar>
            <SaveButton size="medium" label="Create Clinical Practitioner" />
          </Toolbar>
        }
        mode="onBlur"
      >
        <Heading level={2}>General Details</Heading>
        <BooleanInput source="attributes.isEnabled" label="Is Enabled" />
        <AdornedField
          style={{ width: "fit-content" }}
          Field={BooleanInput}
          addLabel={false}
          adornment={
            <TooltipAdornment
              sx={booleanInputAdornmentStyles}
              text={isCpExternalMessage}
            />
          }
          source="attributes.isExternal"
          label="Is External"
        />
        <SelectInput
          label="Compliance Level"
          source="attributes.extraCompliance"
          defaultValue="Low"
          choices={complianceLevels}
          required
        />
        <Heading level={2}>CP Attributes</Heading>
        <Heading level={2}>Personal Details</Heading>
        <EmailInput required source="attributes.email" />
        <TextInput source="attributes.firstName" label="First Name" required />
        <TextInput source="attributes.lastName" label="Last Name" required />
        <SelectInput
          required
          source="attributes.gender"
          label="Gender"
          choices={genderChoices}
        />
        <SelectInput
          required
          source="attributes.country"
          label="Location (country)"
          choices={countrySelectChoices}
        />
        <CheckboxGroupInput
          source="attributes.consultationTypes"
          label="Preferred consultation types"
          required
          choices={cpConsultationPreferences}
        />

        <Heading level={2}>Specialty Details</Heading>
        <CPSpecialityInput />
        <CPExpertiseInput />
        <CPSpecificInputs />
        <CPDocumentsInput />
        <AppointmentInputWrapper />
      </SimpleForm>
    </Create>
  );
}
