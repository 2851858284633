import { BooleanInput } from "ra-ui-materialui";
import React from "react";
import { Heading } from "../ui/Heading";

export function Qualifications() {
  return (
    <>
      <Heading level={4}>IMC and GMC Required Qualifications</Heading>
      <BooleanInput
        source="attributes.imcgpRequired"
        label="IMC (General Practitioner)"
        helperText=""
      />
      <BooleanInput
        source="attributes.gmcgpRequired"
        label="GMC (General Practitioner)"
        helperText=""
      />
    </>
  );
}
