import { Grid, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import get from "lodash/get";
import {
  RaRecord,
  required,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
} from "ra-core";
import {
  Labeled,
  Link,
  ReferenceField,
  sanitizeFieldRestProps,
  SelectInput,
  TextField,
  TextFieldProps,
  TextInput
} from "ra-ui-materialui";
import React, { useState } from "react";
import { ShowTabProps } from "../../../types/utilities";
import { CapsDelimitedField } from "../../fields/CapsDelimitedField";
import DateField from "../../fields/DateField";
import { DurationField } from "../../fields/DurationField";
import { FormTab } from "../../form/CustomTabForm/FormTab";
import { Heading } from "../../ui/Heading";
import { Button, useGetOne } from "react-admin";
import MailIcon from "@mui/icons-material/Mail";
import { Colors } from "../../../theme";
import { BookingStatus } from "../../../types/bookings";
import getIsBookingActionDisabled from "../helpers/getIsBookingActionDisabled";
import { Stack } from "../../layout/Stack";
import { Group } from "../../layout/Group";
import { ConsultationSummary } from "./PatientTab/ConsultationSummary";
import { useEditStateContext } from "../../../context/EditStateContext";
import { bookingConsultationTypeChoices } from "../../../constants/constants";
import { ClientSettingsDTO } from "../../../types/clientSettings";

const PREFIX = "SummaryTab";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  layout: `${PREFIX}-layout`,
};

const StyledLabeled = styled(Labeled)({
  [`& .${classes.wrapper}`]: {
    width: "initial",
  },
  [`& .${classes.layout}`]: {
    width: "100%",
  },
});

const getBookingActionTooltipText = (status: BookingStatus) => {
  if (getIsBookingActionDisabled(status)) {
    return "You cannot send booking details for a booking that has been completed";
  }

  return "";
};

const getIsEditable = (record: RaRecord) => {
  if (record?.attributes?.status !== "PendingConsultation") return false;

  if (record?.attributes.consultationType === "MessageDoctor") return false;

  return true;
};

export function SummaryTab(props: ShowTabProps) {
  const record = useRecordContext();
  const [bookingId] = String(record?.id).split(":");
  const status = get(record, "attributes.status");
  const consultationType = get(record, "attributes.consultationType");
  const isVideoConsultationType = consultationType === "Video";
  const isBookingActionDisabled = getIsBookingActionDisabled(status);
  const hasClinicalService = !!get(record, "appointments[0].attributes.clinicalServiceId");
  const notify = useNotify();
  const refresh = useRefresh();


  const clientId = get(record, "clients[0].id");
  const { data: client } = useGetOne("clients", { id: clientId });

  const clientSettings = client?.clientSettings as ClientSettingsDTO["data"][];
  const collectProductId =
    clientSettings?.some(
      s =>
        s.attributes.key == "CollectProductId" && s.attributes.value == "true"
    ) ?? false;

  const [create, { isLoading }] = useCreate(
    "sendJoiningInformation",
    {
      data: {
        bookingId,
      },
    },
    {
      onSuccess: () => {
        notify("The video consultation link was sent successfully");
        refresh();
      },
      onError: (error) => {
        notify((error as Error).message);
      },
    }
  );

  const [disableImageRequest, setDisableImageRequest] = useState(false);
  const [sendImageRequest, { isLoading: isImageRequestLoading }] = useCreate(
    "sendImageRequest",
    {
      data: {
        bookingId,
      },
    },
    {
      onSuccess: () => {
        notify("The photo request link was sent successfully");
        setDisableImageRequest(true);
        refresh();
      },
      onError: (error: any) => {
        if (error.status === 400)
        {
          notify("Unable to send photo request link. Please ensure the booking contact details include a mobile phone number.");
        }
        else
        {
          notify((error as Error).message);
        }
      },
    }
  );

  const editable = getIsEditable(record) ? "editable" : "noneditable";

  return (
    <FormTab {...props} label="Summary" editable={editable}>
      <div className={classes.layout}>
        <Stack>
          <Group heading={<Heading level={2}>Summary</Heading>}>
            <Grid item xs={12} sm={6}>
              <DateField
                label="Time"
                source="consultations[0].attributes.start"
                showTime
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DurationField label="Duration" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Labeled label="Client">
                <TextField
                  source="clients[0].attributes.name"
                  emptyText="n/a"
                />
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CapsDelimitedField
                label="Booking Status"
                source="attributes.status"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {hasClinicalService ? 
                <Labeled label="Clinical Service">
                  <ReferenceField
                    source="appointments[0].attributes.clinicalServiceId"
                    reference="clinicalServices"
                    link={false}
                  >
                    <TextField source="attributes.name" label="Service" />
                  </ReferenceField>
                </Labeled>
              :
                <Labeled label="Specialty">
                  <CapsDelimitedField 
                      sortable={false}
                      hideLabel
                      label="Specialty" 
                      source="appointments[0].attributes.specialties[0]"
                    />
                </Labeled>
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              <ConsultationTypeInput />
            </Grid>
            {collectProductId && (
              <Grid item xs={12} sm={6}>
                <ProductIdInput />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Tooltip title={getBookingActionTooltipText(status)}>
                <span className={classes.wrapper}>
                  <Button
                    disabled={disableImageRequest || isImageRequestLoading || isBookingActionDisabled}
                    label="Send Photo Request Link"
                    onClick={() => sendImageRequest()}
                  >
                    <MailIcon />
                  </Button>
                </span>
              </Tooltip>
              {isVideoConsultationType && (
                <div style={{ marginTop: "12px" }}>
                  <Tooltip title={getBookingActionTooltipText(status)}>
                    <span className={classes.wrapper}>
                      <Button
                        disabled={isLoading || isBookingActionDisabled}
                        label="Resend Video Consultation Link"
                        onClick={() => create()}
                      >
                        <MailIcon />
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              )}
            </Grid>
          </Group>
          <Group heading={<Heading level={2}>Clinician Details</Heading>}>
            <Grid item xs={12} sm={6}>
              <CpNameAndGenderField label="CP Name" emptyText="N/A" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CapsDelimitedField
                label="Specialty"
                source="appointments[0].clinicalPractitioners[0].attributes.specialties[0]"
              />
            </Grid>
          </Group>
          <ConsultationSummary />
        </Stack>
      </div>
    </FormTab>
  );
}

function ConsultationTypeInput() {
  const { currentState } = useEditStateContext();
  const isInEditState = currentState === "edit";

  if (isInEditState) {
    return (
      <SelectInput
        validate={required("A consultation is required")}
        label="Consultation Type"
        source="attributes.consultationType"
        choices={bookingConsultationTypeChoices}
      />
    );
  }

  return (
    <StyledLabeled label="Type">
      <TextField source="attributes.consultationType" />
    </StyledLabeled>
  );
}

function ProductIdInput() {
  const { currentState } = useEditStateContext();
  const isInEditState = currentState === "edit";

  if (isInEditState) {
    return (
      <TextInput
        label="Product ID"
        source="attributes.payload.productId"
      />
    );
  }

  return (
    <StyledLabeled label="Product ID">
      <TextField source="attributes.payload.productId" />
    </StyledLabeled>
  );
}

function CpNameAndGenderField(props: TextFieldProps & { label: string }) {
  const record = useRecordContext();
  const { emptyText, label, ...rest } = props;

  if (!record) {
    return (
      <Labeled label={label}>
        <Typography
          component="span"
          variant="body1"
          {...sanitizeFieldRestProps(rest)}
        >
          {emptyText}
        </Typography>
      </Labeled>
    );
  }

  const firstName = get(
    record,
    "appointments[0].clinicalPractitioners[0].attributes.firstName"
  );
  const lastName = get(
    record,
    "appointments[0].clinicalPractitioners[0].attributes.lastName"
  );
  const gender = get(
    record,
    "appointments[0].clinicalPractitioners[0].attributes.gender"
  );
  const cpId = get(record, "appointments[0].clinicalPractitioners[0].id");

  return (
    <Labeled label={label}>
      <Typography
        variant="body1"
        {...sanitizeFieldRestProps(rest)}
        sx={{
          "& > a.RaLink-link": {
            textDecoration: "underline",
            color: Colors.BrightBlue,
          },
        }}
      >
        <Link to={`/clinicalPractitioners/${cpId}/show`}>
          {firstName} {lastName}
        </Link>
        {` (${gender})`}
      </Typography>
    </Labeled>
  );
}
