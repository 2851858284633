import React from "react";
import { Grid } from "@mui/material";
import { Group } from "../../../layout/Group";
import { Heading } from "../../../ui/Heading";
import { RecordContextProvider } from "react-admin";
import { GaiaEncounter } from "../../../../types/gaia";
import DateField from "../../../fields/DateField";

type GaiaEncounterGroupProps = {
  encounter: GaiaEncounter,
}

export function GaiaEncounterGroup(props: GaiaEncounterGroupProps) {
  return (
    <RecordContextProvider value={props.encounter}>
      <Group 
        heading={<Heading level={2}>Consultation</Heading>}
        data-testid="gaia-encounter-group"
      >
        <Grid item xs={12} sm={6}>
          <DateField
            label="Start time" 
            source="startTime"
            showTime 
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateField
            label="End time" 
            source="endTime"
            showTime 
          />
        </Grid>
      </Group>
    </RecordContextProvider>
  );
}
