import dayjs from "dayjs";
import { contractQualificationChoices } from "../../../constants/constants";
import { ClientContractDTO } from "../../../types/clients";

const emptyContract = {
  id: null,
  type: "contracts",
  attributes: {
    startDate: dayjs().format(),
    endDate: dayjs().format(),
    name: "contract",
    phoneNumber: "03000240015",
  },
};

function formatContract(existingContracts: ClientContractDTO["data"][]) {
  const [contract] = existingContracts;

  if (!contract) {
    return emptyContract;
  }

  const { attributes } = contract;
  const requiredQualifications: string[] = [];

  contractQualificationChoices.forEach(({ id }) => {
    if (attributes[id]) {
      requiredQualifications.push(id);
    }
  });

  return {
    ...contract,
    attributes: {
      ...attributes,
      requiredQualifications,
    },
  };
}

export default formatContract;
