import React from "react";
import { EditTabProps } from "../../../../types/utilities";
import { HealthMailSendTab } from "./HealthMailSendTab";
import { useRecordContext } from "react-admin";
import get from "lodash/get";
import { HealthmailUnavailableReason, HealthmailUnavailableTab } from "./HealthmailUnavailableTab";

export function HealthmailTab(props: EditTabProps) {  
  const record = useRecordContext();
  const caseRef = get(record, "patientManagementSystemCases[0].attributes.caseReference");
  const bookingId = get(record, "bookingId");
  const consultationId = get(record, "consultations[0].id");
  const isClientIrish = record.clients?.[0]?.attributes.countryCode == "IE";


  if (!isClientIrish) {
    return <HealthmailUnavailableTab reason={HealthmailUnavailableReason.ClientNotIrish} {...props} />
  }

  if (!caseRef) {
    return <HealthmailUnavailableTab reason={HealthmailUnavailableReason.LinkRequired} {...props} />
  }

  return (<HealthMailSendTab caseRef={caseRef} bookingId={bookingId} consultationId={consultationId} { ...props } />);
}
