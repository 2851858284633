import React from "react";
import { Card, Typography } from "@mui/material";

export const NoRecordsMessage = (props: { message: string }) => (
  <Card style={{ padding: 16 }}>
    <Typography variant="body1">
      {props.message}
    </Typography>
  </Card>
);
