import create from "zustand";
import { Specialty } from "../types/clinicalPractitioners";
import { ConsultationType } from "../types/consultations";

export type SwappingState = "PENDING" | "SWAPPING" | "PENDING_CONFIRM";

interface AppointmentDetails {
  id: number;
  cpName: string;
  start: string;
  consultationType: ConsultationType;
  specialty: Specialty;
}

interface BookingDetails extends AppointmentDetails {
  patientName: string;
  userId: string;
  clientId: number;
}

type State = {
  swapFromBooking: BookingDetails | null;
  setBooking: (details: BookingDetails | null) => void;
  swapToAppointment: AppointmentDetails | null;
  setAppointment: (details: AppointmentDetails | null) => void;
  getSwappingState: () => SwappingState;
  completeSwap: () => void;
};

export const useAppointmentSwapStore = create<State>((set, get) => ({
  swapFromBooking: null,
  swapToAppointment: null,
  setBooking: details => {
    return set({
      swapFromBooking: details,
    });
  },
  setAppointment: details => {
    return set({
      swapToAppointment: details,
    });
  },
  getSwappingState: () => {
    const { swapFromBooking, swapToAppointment } = get();

    if (swapFromBooking && swapToAppointment) return "PENDING_CONFIRM";

    if (swapFromBooking) return "SWAPPING";

    return "PENDING";
  },
  completeSwap: () => {
    set({
      swapFromBooking: null,
      swapToAppointment: null,
    });
  },
}));
