import React from "react";
import { RaRecord, useNotify, useRedirect } from "ra-core";
import {
  BooleanInput,
  Create, ReferenceInput,
  SelectInput,
  SimpleForm,
} from "ra-ui-materialui";
import {
  NumberInput,
  ReferenceArrayInput,
  TextInput
} from "react-admin";
import AutocompleteInput from "../inputs/AutocompleteInput";
import validateClinicalService from "./validateClinicalService";
import { clinicalServiceDeliveryTypes } from "../../constants/constants";
import { useWatch } from "react-hook-form";
import { Heading } from "../ui/Heading";


export function ClinicalServiceCreate() {
  const notify = useNotify();
  const redirect = useRedirect();

  function onSuccess(record: RaRecord) {
    notify("Clinical service was created successfully");
    redirect(`/clinicalServices/${record.id}/show`);
  }

  return (
    <Create
      mutationOptions={{
        onSuccess,
      }}
      title="Create Clinical Service"
    >
      <SimpleForm
        mode="onChange"
        noValidate
        validate={validateClinicalService}
      >
        <Heading level={2}>General Details</Heading>
        <TextInput
          required
          source="attributes.name"
          label="Name"
        />
        <SelectInput
          choices={clinicalServiceDeliveryTypes}
          source="attributes.deliveryType"
          label="Delivery Type"
          required
        />
        <TextInput
          label="PMS Code"
          source="attributes.pmsCode"
        />
        <AppointmentDurationInput/>
        <ReferenceArrayInput
          source="attributes.shiftTypeId"
          reference="quinyxShiftTypes"
        >
          <AutocompleteInput
            source="id"
            label="Shift Types"
            optionText="attributes.name"
            showClearButton={true}
          />
        </ReferenceArrayInput>
        <BooleanInput
          label="Notifications Enabled"
          source="attributes.notificationsEnabled"
        />

        <Heading level={2}>Release Settings</Heading>
        <BooleanInput
          label="Enable Release Settings"
          source="attributes.canBeReleased"
        />
        <ReleaseSettings/>
      </SimpleForm>
    </Create>
  );
}

function AppointmentDurationInput() {
  const deliveryType = useWatch({name: "attributes.deliveryType"});
  const isDeliveryTypeAsynchronous = deliveryType === "Asynchronous";

  if (isDeliveryTypeAsynchronous) return null;

  return <NumberInput
    required
    label="Appointment Duration"
    source="attributes.appointmentDuration"
  />
}

function ReleaseSettings() {
  const canBeReleased = useWatch({name: "attributes.canBeReleased"});

  if (!canBeReleased) return null;

  return (
    <>
      <NumberInput
        required
        label="Release Threshold"
        source="attributes.releaseThreshold"
      />
      <ReferenceInput
        source="attributes.fallbackServiceId"
        reference="clinicalServices"
      >
        <AutocompleteInput
          label="Fallback Clinical Service"
          optionText="attributes.name"
          filterToQuery={query => ({
            name: query
          })}
        />
      </ReferenceInput>
    </>
  );
}


