import React from "react";
import { styled } from "@mui/material/styles";
import { EditTabProps } from "../../../../types/utilities";
import { FormTab } from "../../../form/CustomTabForm/FormTab";
import { Stack } from "../../../layout/Stack";
import { ContactForBooking } from "./ContactForBooking";
import { Employer } from "./Employer";
import { LocationForBooking } from "./LocationForBooking";
import { ReasonForBooking } from "./ReasonForBooking";

const StyledDiv = styled("div")({
  width: "100%",
});

export function PatientTab(props: EditTabProps) {
  return (
    <FormTab {...props} label="Patient" editable="editable">
      <StyledDiv>
        <Stack>
          <ReasonForBooking />
          <ContactForBooking />
          <LocationForBooking />
          <Employer />
        </Stack>
      </StyledDiv>
    </FormTab>
  );
}
