import { CreateParams } from "react-admin";
import { create } from "..";

interface Params {
  attributes: {
    start: string;
    end: string;
    clinicalPractitionerIds: string[];
    unitGroupIds: number[];
  };
}

async function importShifts(params: CreateParams<Params>["data"]) {
  const endpoint = "/ms/resourceManagement/quinyxSync";
  const {
    start,
    end,
    clinicalPractitionerIds,
    unitGroupIds = [],
  } = params.attributes;

  const body = {
    data: {
      type: "quinyxSyncRequests",
      attributes: {
        start,
        end,
        clinicalPractitionerIds,
        unitGroupIds,
        createAppointments: true,
      },
    },
  };

  const res = await create(endpoint, body, {
    params: {
      include: "shifts",
    },
  });

  return {
    data: {
      id: "id-to-satisfy-ra",
      ...res.data,
    },
  };
}

export default importShifts;
