import useEffectOnce from "./useEffectOnce";
import { useState } from "react";

function useForceRerender() {
  const [v, update] = useState(0);

  return useEffectOnce(() => {
    // forces an initial rerender so that we can get the width
    // from the ref
    update(v + 1);
  });
}

export default useForceRerender;
