import { isNonEmptyFormLevel } from "../../validators/nonEmpty";

type ErrorMessage = string;

function validateCreateResourceForm(values: any) {
  const errors: Record<string, ErrorMessage> = {};

  if (!isNonEmptyFormLevel(values.attributes?.email)) {
    errors["attributes.email"] = "Please enter a valid email";
  }

  if (!values.role) {
    errors["role"] = "Please choose a role for the user";
  }

  if (!isNonEmptyFormLevel(values.attributes?.firstName)) {
    errors["attributes.firstName"] = "The user's first name is required";
  }

  if (!isNonEmptyFormLevel(values.attributes?.lastName)) {
    errors["attributes.lastName"] = "The user's last name is required";
  }

  return errors;
}

export default validateCreateResourceForm;
