import React from "react";
import {
  // eslint-disable-next-line no-restricted-imports
  SimpleFormIterator as RASimpleFormIterator,
  SimpleFormIteratorProps,
} from "ra-ui-materialui";

export function SimpleFormIterator(props: SimpleFormIteratorProps) {
  return (
    <RASimpleFormIterator reOrderButtons={<span />} {...props}>
      {props.children}
    </RASimpleFormIterator>
  );
}
