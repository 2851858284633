import React from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from "react";
import { useRecordContext } from "react-admin";
import get from "lodash/get";

interface CopyToClipboardProps {
  value: string;
}

export function CopyToClipboard(props: CopyToClipboardProps) {
  const { value } = props;
  const [copied, setCopied] = useState(false);
  const title = copied ? 'Copied' : 'Copy to clipboard';
  async function copyText() {
    await navigator.clipboard.writeText(value);
    setCopied(true);
  }
  return (
    <Tooltip title={title} onOpen={() => setCopied(false)}>
      <Box sx={{ display: 'inline' }}>
        <IconButton
          aria-label={title}
          sx={{
            marginLeft: 1,
            '& .MuiSvgIcon-root': {
              fontSize: '18px'
            }
          }}
          onClick={copyText}>
          <ContentCopyIcon />
        </IconButton>
      </Box>
    </Tooltip>
  );
}

interface CopyFieldToClipboardProps {
  source: string;
}

export function CopyFieldToClipboard(props: CopyFieldToClipboardProps) {
  const { source } = props;
  const record = useRecordContext()
  const text = get(record, source);
  
  return (
   <CopyToClipboard value={text} />
  );
}