import { update } from "..";
import { getConfig } from '../../config';

interface RefundOrderRequestBody {
  amount: number;
}

interface Data {
  orderId: string;
  clientId: string;
  userId: string;
  requestBody: RefundOrderRequestBody;
}

async function refundOrder(data: Data) {
  const { orderId, clientId, userId, requestBody } = data;
  const config = getConfig();
  
  await update(
    `${config.pew.apiHost}/v${config.pew.apiVersion}/clients/${clientId}/users/${userId}/orders/${orderId}/refund`,
     requestBody,
     /*reqConfig*/ undefined,
     /*updateMethod*/ "put",
     /*useSapiFormat*/ false);

  return {
    data: {
      id: "id-to-satisfy-ra",
    },
  };
}

export default refundOrder;
