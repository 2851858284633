import React from "react";
import get from "lodash/get";
import { useRecordContext } from "ra-core";

interface Props {
  source: string;
  description: string;
}

export function QualificationItem(props: Props) {
  const { source, description } = props;
  const record = useRecordContext();

  const item = get(record, source);

  if (!item) return null;

  return <div>{description}</div>;
}
