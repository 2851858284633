import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";
import { usePrevious } from "../hooks/usePrevious";
import { useSearchParams } from "react-router-dom";

type State = "edit" | "show";

interface Context {
  updateState: (state: State) => void;
  currentState: State;
}

const EditStateContext = createContext<Context>({
  currentState: "show",
  updateState: () => null,
});

interface ProviderProps {
  children: React.ReactNode;
}

const options = { replace: true };

export function EditStateContextProvider(props: ProviderProps) {
  const { children } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const urlState = (searchParams.get("state") as State) ?? "show";
  const { pathname } = useLocation();
  const previousPathName = usePrevious(pathname);

  const changeState = useCallback(
    // We use this to change the url which then triggers the state update in the useEffect below
    (state: State) => {
      if (state === "edit") {
        return setSearchParams({ state: "edit" }, options);
      }

      return setSearchParams({}, options);
    },
    [setSearchParams]
  );

  useEffect(() => {
    // We use this to set the state based on the url
    if (urlState === "edit") {
      return setSearchParams({ state: "edit" }, options);
    }
    return setSearchParams({}, options);
  }, [urlState, setSearchParams]);

  useEffect(() => {
    // We use this to determine if the tab has been changed
    if (previousPathName === null) return;

    if (pathname !== previousPathName) {
      changeState("show");
    }
  }, [pathname, changeState, previousPathName]);

  return (
    <EditStateContext.Provider
      value={{
        currentState: urlState,
        updateState: changeState,
      }}
    >
      {children}
    </EditStateContext.Provider>
  );
}

export const useEditStateContext = () => useContext(EditStateContext);
