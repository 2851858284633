import { get } from "../gaiaClient";
import { v4 as uuid } from "uuid";
import { ConclusionReport } from "../../types/prescriptions";

interface Args {
  tenantId: string;
  traversalId: string;
}

const getConclusionReport = async (args: Args) : Promise<{data: ConclusionReport}> => {
  const { tenantId, traversalId } = args;

  const conclusionReportEndpoint = `${tenantId}/traversals/${traversalId}/conclusion-report`;
  const conclusionReport = await get(conclusionReportEndpoint, {
    headers: {
      "x-correlation-id": uuid(),
    }
  })

  return conclusionReport;
}

export default getConclusionReport;
