import { baseUrl, create } from ".";

interface Params {
  clientId: string;
  userId: string;
  bookingId: string;
}

async function resendNotifications(params: Params) {
  const { clientId, bookingId, userId } = params;

  const api = `${baseUrl}/clients/${clientId}/users/${userId}/bookings/${bookingId}/resendNotifications`;

  await create(api, {});

  return {
    data: { id: "id-to-satisfy-ra" },
  };
}

export default resendNotifications;
