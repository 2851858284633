import { AuthProvider } from "react-admin";
import { login, getValidAccessToken } from "./api/auth";
import AdminHub, { initAdminHub } from "./sockets/AdminHub";
import decodeJwt from "jwt-decode";
import { Role, DecodedToken } from "./types/permissions";
import { permissions } from "./permissions";
import {
  isUserInactive,
  resetActiveTime,
  setLastActiveTime,
} from "./utilities/lastActive";
import getRoleFromToken from "./api/helpers/getRoleFromToken";
import { FeatureFlagService } from "./featureFlags";
import checkInactivityInterval from "./utilities/checkInactivityInterval";
import { restartRumSession, setSessionUser } from "./monitoring";

export interface ExtendedAuthProvider extends AuthProvider {
  inactiveIntervalId: null | NodeJS.Timeout;
}

const authProvider: ExtendedAuthProvider = {
  inactiveIntervalId: null,
  // called when the user attempts to log in
  login: async ({ username, password }) => {
    const { accessToken, refreshToken } = await login({
      username,
      password,
    });

    if (!accessToken) return Promise.reject();

    const decodedToken = decodeJwt<DecodedToken>(accessToken);
    const role = getRoleFromToken(decodedToken);

    if (!role) return Promise.reject();

    setLastActiveTime();

    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("role", role);

    setSessionUser(decodedToken.sub, decodedToken.name, role);

    return Promise.resolve();
  },
  // called when the user clicks on the logout button
  logout: () => {
    AdminHub.getInstance().close();

    resetActiveTime();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");

    restartRumSession();

    FeatureFlagService.getInstance().updateUser("anonymous", {
      key: "anonymous",
    });

    if (authProvider.inactiveIntervalId) {
      clearInterval(authProvider.inactiveIntervalId);
      authProvider.inactiveIntervalId = null;
    }

    return Promise.resolve();
  },
  getIdentity: async () => {
    try {
      const accessToken = await getValidAccessToken();
      if (!accessToken) {
        restartRumSession();
        return Promise.reject();
      }
      const decodedToken = decodeJwt<DecodedToken>(accessToken);
      const fullName = decodedToken.name;
      const id = decodedToken.sub;
      return Promise.resolve({ fullName: `Signed in as ${fullName}`, id });
    } catch (err) {
      restartRumSession();
      return Promise.reject(err);
    }
  },
  // called when the API returns an error
  checkError: ({ status, body = {} }) => {
    const isGaiaRequest = body.api === "gaia";

    if (status === 401 && !isGaiaRequest) {
      // we can also choose to log out user if status === 403
      localStorage.removeItem("accessToken");
      localStorage.removeItem("role");

      restartRumSession();

      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {
    try {
      const accessToken = await getValidAccessToken();

      if (!accessToken) {
        restartRumSession();
        return Promise.reject();
      }

      const userIsInactive = isUserInactive();

      if (userIsInactive) {
        restartRumSession();
        return Promise.reject({
          message: "You have been logged out due to inactivity",
        });
      }

      if (!authProvider.inactiveIntervalId) {
        const intervalId = checkInactivityInterval();
        authProvider.inactiveIntervalId = intervalId;
      }

      const decodedToken = decodeJwt<DecodedToken>(accessToken);
      const username = decodedToken.name;

      FeatureFlagService.getInstance().updateUser(decodedToken.sub, {
        key: decodedToken.sub,
        email: username,
      });
    } catch (err) {
      console.error(err);
      restartRumSession();
      return Promise.reject();
    }

    initAdminHub();
    setLastActiveTime();

    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const role = localStorage.getItem("role") as Role | null;

    if (!role) {
      return Promise.resolve({});
    }

    const userPermissions = permissions[role];

    return Promise.resolve(userPermissions);
  },
};

export default authProvider;
