import { Tab, Tooltip } from "@mui/material";

import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { TabProps } from "./FormTab";

interface Props extends TabProps {
  disabled?: boolean;
  tooltipMessage?: string;
}

export function FormTabHeader(props: Props) {
  const { value, tooltipMessage } = props;
  const params = useParams();
  const urlValue = params["*"];

  const location = useLocation();

  const propsForLink = {
    component: Link,
    to: { ...location, pathname: value },
  };

  return (
    <Tooltip title={tooltipMessage ?? ""}>
      <span>
        <Tab
          value={value}
          id={`tabheader-${value}`}
          aria-controls={`tabpanel-${value}`}
          label={props.label}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          selected={urlValue === value}
          {...propsForLink} // to avoid TypeScript screams, see https://github.com/mui-org/material-ui/issues/9106#issuecomment-451270521
        />
      </span>
    </Tooltip>
  );
}
