import { GetListParams } from "react-admin";
import { list } from "..";

async function listBookings(args: GetListParams) {
  const { sort, pagination } = args;
  const { field, order } = sort;
  const { perPage, page } = pagination;

  const sortQuery = `${order === "DESC" ? "-" : ""}${field}`;

  const params = {
    "page[size]": perPage,
    "page[number]": page,
    sort: sortQuery,
    include: "patientRecord,user,appointment,client",
  };

  const bookingsEndpoint = "/ms/bookings";
  const { data, total } = await list(bookingsEndpoint, { params });

  return { data, total };
}

export default listBookings;
