import {
  Identifier,
  RaRecord,
  SimpleShowLayout,
  useRecordContext,
} from "react-admin";
import React from "react";
import { MarkdownField } from "../fields/MarkdownField";

type DatagridExpandProps = {
  id?: Identifier;
  record?: RaRecord;
  resource?: string;
};

export function ShowInstructions(props: DatagridExpandProps) {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <SimpleShowLayout {...props} sx={{ whiteSpace: "normal" }}>
      <MarkdownField
        record={record}
        source="attributes.instructions"
        label="Instructions"
      />
    </SimpleShowLayout>
  );
}
