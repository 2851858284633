import { Box, Grid } from "@mui/material";
import { FormDataConsumer, required } from "ra-core";
import { BooleanInput, FieldProps, TextInput } from "ra-ui-materialui";
import React from "react";
import { invalidFileExtensions } from "../../constants/constants";
import { innerGroupStyles } from "../../theme";
import { FileImageField } from "../fields/FileImageField";
import { FormIteratorAddButton } from "../form/FormIteratorAddButton";
import { FormIteratorRemoveButton } from "../form/FormIteratorRemoveButton";
import { SimpleFormIterator } from "../form/SimpleFormIterator";
import { ArrayInput } from "../inputs/ArrayInput";
import { FileInput } from "../inputs/FileInput";

const floatingButtonStyles = {
  position: "absolute",
  top: "32px",
  right: "24px",
};

export function PatientNotesInput(props: FieldProps) {
  return (
    <ArrayInput {...props} source="notes" label={false}>
      <SimpleFormIterator
        addButton={<FormIteratorAddButton label="Add note" />}
        removeButton={
          <FormIteratorRemoveButton
            sx={floatingButtonStyles}
            label="Remove note"
          />
        }
      >
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            if (!getSource) return null;

            return (
              <Grid container sx={innerGroupStyles}>
                <Grid item xs={5}>
                  <TextInput
                    sx={{ marginTop: "0", width: "100%" }}
                    label="Patient notes"
                    multiline
                    validate={required()}
                    source={getSource("attributes.note")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BooleanInput
                    source={getSource(
                      "attributes.isAvailableToClinicalPractitioner"
                    )}
                    label="Is note visible to the CP?"
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArrayInput
                    source={getSource("files")}
                    record={scopedFormData}
                    label="Documents"
                    margin="dense"
                    level={4}
                  >
                    <SimpleFormIterator
                      disableAdd
                      removeButton={
                        <Box>
                          <FormIteratorRemoveButton label="Remove" />
                        </Box>
                      }
                    >
                      <DocumentList />
                    </SimpleFormIterator>
                  </ArrayInput>
                </Grid>
                <FileInput
                  label={false}
                  source={getSource("newAttachments")}
                  exclude={invalidFileExtensions}
                  helperText=""
                  multiple
                >
                  <FileImageField />
                </FileInput>
              </Grid>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
}

function DocumentList(props: any) {
  return (
    <FormDataConsumer {...props}>
      {({ scopedFormData, getSource }) => {
        if (!getSource) return null;

        return (
          <FileImageField
            // To satisfy RA warnings
            source={getSource("attributes.fileName")}
            titleSource="attributes.fileName"
            record={scopedFormData}
          />
        );
      }}
    </FormDataConsumer>
  );
}
