import { useEffect, useState } from "react";

interface Props {
  background: string;
  placeholder: string;
}

export const useImageWithPlaceholder = ({ background, placeholder }: Props) => {
  const [source, setSource] = useState(placeholder);

  useEffect(() => {
    const img = new Image();
    img.src = background;
    img.onload = () => setSource(background);
  }, [background]);
  return source;
};
