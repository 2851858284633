import { Tab } from "../../Tabs";
import { ArrayField, Datagrid, TextField } from "react-admin";
import { RaRecord } from "ra-core";
import { BooleanField } from "../../fields/BooleanField";
import React from "react";
import { ShowTabProps } from "../../../types/utilities";


export function ClientClinicianPoolsShowTab(props: ShowTabProps) {
  return (
    <Tab label="CP Management" {...props}>
      <ArrayField source="clientClinicianPools" label={false}>
        <Datagrid bulkActionButtons={false} rowClick={(id, resource, record: RaRecord) => `/resourcePools/${record.attributes.resourcePoolId}/`}>
          <TextField
            source="resourcePools.[0].attributes.name"
            label="Name"
          />
          <BooleanField
            sortable={false}
            source="attributes.isExclusive"
            label="Is Exclusive"
          />
          <TextField
            label="Service"
            source="resourcePools.[0].attributes.serviceName"
            sortable={false}
          />
          <BooleanField
            label="Is Enabled"
            source="resourcePools.[0].attributes.isEnabled"
            sortable={false}
          />
          <BooleanField
            label="Can be released"
            source="resourcePools.[0].attributes.isGloballyAvailable"
            sortable={false}
          />
          <TextField
            label="Release duration"
            source="resourcePools.[0].attributes.globallyAvailableDuration"
            sortable={false}
          />
        </Datagrid>
      </ArrayField>
    </Tab>
  )
}
