import dayjs from "dayjs";
import { AppointmentFiltersDTO } from "../../../types/appointments";

export const defaultAptFilter = {
  id: null,
  type: "appointmentFilters",
  attributes: {
    dailyStartTime: "08:00:00",
    dailyEndTime: "22:00:00",
    categories: [],
  },
};

function parseTime(time: string) {
  const splitTime = time.split(":");
  const parsedTime = dayjs()
    .set("hour", Number(splitTime[0]))
    .set("minute", Number(splitTime[1]))
    .set("second", Number(splitTime[2]))
    .format();

  return parsedTime;
}

function formatAppointmentFilters(
  appointments?: AppointmentFiltersDTO["data"][]
) {
  if (!appointments || !appointments[0]) return defaultAptFilter;

  const { dailyStartTime, dailyEndTime } = appointments[0].attributes;

  appointments[0].attributes.parsedStartTime = parseTime(dailyStartTime);
  appointments[0].attributes.parsedEndTime = parseTime(dailyEndTime);

  return appointments[0];
}

export default formatAppointmentFilters;
