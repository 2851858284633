import React from "react";

import { GooglePlaceContextProvider } from "../../../context/GooglePlaceAddressContext";
import { GooglePlaceAddressInputs } from "./GooglePlaceAddressInputs";
import { FindAddress } from "./FindAddress";
import { SelectAddress } from "./SelectAddress";
import { Box, styled } from "@mui/material";

export type PlaceType = "GP Surgery" | "Pharmacy";
interface Props {
  sourcePrefix: string;
  type: PlaceType;
}

const StyledParagraph = styled("p")(() => ({
  marginBottom: 5,
  marginTop: 32,
}));

export function GooglePlaceAddressForm(props: Props) {
  const { type, sourcePrefix } = props;
  return (
    <GooglePlaceContextProvider type={type}>
      <Box>
        <FindAddress />
        <SelectAddress />
        <StyledParagraph>Or manually input an address:</StyledParagraph>
        <GooglePlaceAddressInputs sourcePrefix={sourcePrefix} type={type} />
      </Box>
    </GooglePlaceContextProvider>
  );
}
