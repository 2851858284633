import { GetListParams } from "ra-core";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";
import axios from "axios";
import { list } from "../index";
import isObjectEmpty from "../../utilities/isObjectEmpty";
import { getResourcePoolServiceName } from "../../types/resourcePools";

const endpoint = "/ms/resourcePools";

async function listResourcePools(params: GetListParams) {
  const { sort, pagination, filter } = params;
  const { page, perPage } = pagination;
  const { name, clientId, clinicalPractitionerId, specialty, ignoreWithoutFilters, clinicalServiceId } = filter;

  const includes = [
    "clinicalPractitioners",
    "clientClinicianPools",
    "clientClinicianPools.clients",
    "clinicalService",
  ];

  const { field, order } = sort;

  const sortQuery = `${order === "DESC" ? "-" : ""}${field}`;


  const filters = {
    "filter[name]": name ? `like:${name}` : "",
    "filter[specialty]": specialty,
    "filter[clientClinicianPools.clientId]": clientId,
    "filter[clinicalPractitioners.id]": clinicalPractitionerId ? clinicalPractitionerId : "",
    "filter[clinicalServiceId]": clinicalServiceId
  };

  const filterParams = shallowlyRemoveEmptyValues(filters);

  const hasNoFilter = isObjectEmpty(filterParams);

  if (ignoreWithoutFilters && hasNoFilter) {
    return {
      data: [],
      total: 0,
    };
  }

  const query = {
    "page[number]": page,
    "page[size]": perPage,
    "include": includes.join(","),
    "sort": sortQuery,
    ...filterParams
  };


  const source = axios.CancelToken.source();

  const { data, total } = await list(endpoint, {
    params: query,
    cancelToken: source.token,
  });

  const transformedData = data.map((rp:any) =>
    ({
      ...rp,
      attributes: {
        ...rp.attributes,
        serviceName: getResourcePoolServiceName(rp)
      },
      relationships: {
        ...rp?.relationships,
        clinicalPractitioners: {
          data: rp?.relationships?.clinicalPractitioners.data.map((r: any) => r.id)
        }
      }
  }))

  return { data: transformedData, total };
}

export default listResourcePools;
