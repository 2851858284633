import { create } from "../index";

const endpoint = "/ms/clinicalServices";

async function createClinicalService(args: any) {
  const { shiftTypeId, appointmentDuration, deliveryType } = args.attributes;

  const body = {
    data: {
      type: "clinicalServices",
      attributes: {
        ...args.attributes,
        shiftTypeId: shiftTypeId ? shiftTypeId : null,
        appointmentDuration: deliveryType === "Synchronous" ? appointmentDuration : null,
      },
      relationships: {
        clinicalPractitioners: {
          data: []
        }
      }
    }
  }
  return await create(endpoint, body);
}

export default createClinicalService;
