import { create } from "..";

const endpoint = "/ms/resourcePools";

async function createResourcePool(args: any)
{
  const body = {
    data: {
      type: "resourcePools",
      attributes: args.attributes,
      relationships: {
        clinicalPractitioners: {
          data: []
        }
      }
    }
  };

  return await create(endpoint, body);
}

export default createResourcePool;
