import {
  TabbedFormProps,
  TabbedFormTabs,
  TabbedShowLayout,
  TabbedShowLayoutProps,
  TabbedShowLayoutTabs,
  TabbedForm,
} from "ra-ui-materialui";
import React, { MutableRefObject, useState } from "react";
import { useRef } from "react";
import useForceRerender from "../hooks/useForceRerender";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import { Box, styled } from "@mui/material";

const ioOptions = {
  threshold: [0, 0.25, 0.5, 0.75, 1],
};

const StyledTabbedForm = styled(TabbedForm)({
  "& .MuiDivider-root": {
    display: "none",
  },
  position: "relative",
});

const tabContainerStyles = {
  "& .MuiDivider-root": {
    display: "none",
  },
  position: "relative",
};

const stickyContainerStyles = {
  minHeight: "48px",
  position: "relative",
};

/**
 *
 * PLEASE READ BEFORE MAKING CHANGES TO THIS FILE
 *
 * We're currently in the process of moving over to the custom tabs in the `CustomTabForm` directory.
 *
 * While we move over there will be some duplication between these tabs and the others. We should aim to minimise any changes made
 * to these components and instead try and migrate all tabbed forms to the new component and make changes there.
 */

export function useStickyTabs(ref: MutableRefObject<HTMLDivElement>) {
  const [isSticky, setStickyState] = useState(false);

  const callback: IntersectionObserverCallback = details => {
    const [entry] = details;

    if (entry.intersectionRatio < 0.5) {
      setStickyState(true);
    } else {
      setStickyState(false);
    }
  };

  useIntersectionObserver(ref, { ...ioOptions, callback });

  const stickyStyles = {
    zIndex: 10,
    backgroundColor: "white",
    position: isSticky ? "fixed" : "absolute",
    top: isSticky ? 48 : 0,
    width: ref?.current?.offsetWidth ?? "100%",
  };

  return stickyStyles;
}

/**
 * Scrollable tabs is a known sticking points in React Admin
 * https://github.com/marmelab/react-admin/pull/2226
 * https://github.com/marmelab/react-admin/issues/2094
 * https://github.com/marmelab/react-admin/issues/6024
 *
 * I've opened up a proposal with RA to use this solution to reimplement tab scolling.
 *
 *
 * This adds the necessary styles to the layout component only when scrolling is true
 */

export function ScrollableTabbedForm(props: TabbedFormProps) {
  return (
    <StyledTabbedForm {...props} tabs={<FormTabsContainer {...props} />} />
  );
}

function FormTabsContainer(props: any) {
  const ref = useRef<HTMLDivElement>(null!);
  const stickyStyles = useStickyTabs(ref);
  const rest = sanitizeTabFormProps(props);

  return (
    <Box ref={ref} sx={stickyContainerStyles}>
      <TabbedFormTabs
        {...rest}
        TabIndicatorProps={{ style: { display: "none" } }}
        style={stickyStyles}
        variant="scrollable"
      />
    </Box>
  );
}

export function ScrollableTabbedShowLayout(
  props: Omit<TabbedShowLayoutProps, "tabs">
) {
  return (
    <TabbedShowLayout
      {...props}
      sx={tabContainerStyles}
      tabs={<ShowTabsContainer {...props} />}
    />
  );
}

function ShowTabsContainer(props: any) {
  const ref = useRef<HTMLDivElement>(null!);
  const stickyStyles = useStickyTabs(ref);
  const rest = sanitizeTabFormProps(props);

  useForceRerender();

  return (
    <Box ref={ref} sx={stickyContainerStyles}>
      <TabbedShowLayoutTabs
        TabIndicatorProps={{ style: { display: "none" } }}
        {...rest}
        data-testid="tabs-root"
        style={stickyStyles}
        variant="scrollable"
      />
    </Box>
  );
}

function sanitizeTabFormProps(props: any) {
  const { warnWhenUnsavedChanges, onChange, validate, mode, ...rest } = props;

  return rest;
}
