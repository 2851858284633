import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import qs from "query-string";
import { HttpError } from "react-admin";
import { getConfig } from "../config";
import { getValidAccessToken } from "./auth";

interface AxiosErrorData {
  errors: [
    {
      detail: string;
      code: string;
    }
  ];
}

export default class GaiaClientService {
  private client: AxiosInstance;
  private errorMessages: Record<string, string> = {
    "401":
      "Your account has not been set up to use the Healthmail services. We've notified our developers. Please open a Halo ticket if the issue persists.",
    "403":
      "You don't seem to have permission to perform this action. We've notified our developers. Please open a Halo ticket if the issue persists.",
    "404":
      "We couldn't find a case that matches your case ID. Please check that all numbers were inputted correctly.",
    "409": "This prescription has already been processed.",
    "424":
      "It appears that something's gone wrong and we weren't able to complete your request. We've notified our developer who should resolve this soon.",
    "500":
      "It appears that something's gone wrong and we weren't able to complete your request. We've notified our developer who should resolve this soon.",
  };

  constructor() {
    const config = getConfig();
    const baseUrl = `${config.gaia.apiHost}/v${config.api.apiVersion}`;

    this.client = axios.create({
      baseURL: baseUrl,
      paramsSerializer: params => qs.stringify(params),
    });

    this.client.interceptors.request.use(
      async clientConfig => {
        const accessToken = await getValidAccessToken();
    
        if (accessToken) {
          if (!clientConfig.headers) {
            clientConfig.headers = {};
          }
    
          clientConfig.headers["x-device-id"] =
            "6530a136-f1ac-4db4-a8d3-7cce49c6868b";
          clientConfig.headers.Authorization = `Bearer ${accessToken}`;
        }
    
        return clientConfig;
      },
      error => {
        Promise.reject(error);
      }
    );
  }

  public async list(url: string, reqConfig: AxiosRequestConfig = {}) {
  
    try {
      const res = await this.client.get(url, reqConfig);
  
      return { data: res.data, total: 100 };

    } catch (err) {
      const message = "We weren't able to get a list of your requested record";
      throw this.handleError(err, message);
    }
  }

  public async getFile(url: string, reqConfig: AxiosRequestConfig = {}) {
    try {
      const res = await this.client.get(url, reqConfig);
  
      const { data } = res;
  
      return { data };
    } catch (err) {
      const message = "We weren't able to to download your request file";
      throw this.handleError(err, message);
    }
  }

  public async create(
    url: string,
    body: any = {},
    reqConfig: AxiosRequestConfig = {}
  ) {
    try {
      const res = await this.client.post(url, body, reqConfig);
  
      const { data } = res.data;
  
      return { data };
    } catch (err) {
      const message =
        "We weren't able to complete your request. If this problem persists, please log a Halo ticket.";
      throw this.handleError(err, message);
    }
  }

  private handleError(err: unknown, defaultMessage: string) {
    if (this.isAxiosError(err)) {
      if (err.response) {
        const { status } = err.response;
  
        const errorMessage = this.errorMessages[status] ?? defaultMessage;
  
        return new HttpError(errorMessage, status, {
          detail: errorMessage,
          api: "gaia",
        });
      }
    }
  
    return new Error(defaultMessage);
  }

  private isAxiosError = (e: any): e is AxiosError<AxiosErrorData> =>
  "isAxiosError" in e;
}
