import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DatagridProps,
  useListContext,
  ArrayField,
  SingleFieldList,
  FunctionField,
  ChipField,
  RaRecord,
} from "react-admin";
import { Card, Typography } from "@mui/material";
import { ConsultationOutputsFilter } from "./ConsultationOutputsFilter";
import DateField from "../fields/DateField";

const styles = {
  "& .RaList-main": {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
};

function EmptyText() {
  const context = useListContext();
  const { filterValues, total, isFetching } = context;

  if (!filterValues.caseRef) {
    return (
      <Card style={{ padding: 16 }}>
        <Typography variant="body1">
          Please enter the 5-digit case ref to retrieve a list of consultation
          outputs
        </Typography>
      </Card>
    );
  }

  if (total === 0 && !isFetching) {
    return (
      <Card style={{ padding: 16 }}>
        <Typography variant="body1">
          We couldn't find any consultation outputs that match that case ref.
          Please make sure the case ref is correct.{" "}
        </Typography>
      </Card>
    );
  }

  return null;
}

function Wrapper(props: any) {
  return (
    <>
      <EmptyText />
      <Card className={props.className}>{props.children}</Card>
    </>
  );
}

export function ConsultationOutputsList() {
  return (
    <List
      filters={<ConsultationOutputsFilter />}
      filterDefaultValues={{
        recentBookingsOnly: true,
      }}
      pagination={false}
      actions={false}
      title="Consultation Outputs"
      sx={styles}
      component={Wrapper}
    >
      <ConsultationOutputsListItems />
    </List>
  );
}

function ConsultationOutputsListItems(props: DatagridProps) {
  return (
    <Datagrid {...props} bulkActionButtons={false} rowClick="show">
      <TextField sortable={false} label="Case Ref" source="caseRef" />
      <ArrayField sortable={false} label="Document Type" source="documents">
        <SingleFieldList linkType={false}>
          <ChipField source="type" emptyText="doc" />
        </SingleFieldList>
      </ArrayField>
      <DateField
        hideLabel
        label="Consultation Time"
        source="encounter.startTime"
      />
      <FunctionField
        sortable={false}
        label="Patient Name"
        render={(record?: RaRecord) => {
          if (!record) {
            return "";
          }

          return `${record.patient.givenName} ${record.patient.familyName}`;
        }}
      />
      <DateField
        sortable={false}
        hideLabel
        label="Date of Birth"
        source="patient.birthDate"
      />
      <TextField
        sortable={false}
        label="Postcode"
        source="patient.address.postalCode"
      />
    </Datagrid>
  );
}
