import { get } from ".";

/*
we use `getList` instead of `getOne` due to how RA caches response
`getOne` requires an id to be passed through which acts as the key for the value in the cache.
when making this request we don't know the userId prior, and such wouldn't be able to make it work with `getOne` without a hack
*/

async function getCurrentUser() {
  const endpoint = `/currentUser`;
  const res = await get(endpoint);

  return {
    data: [res.data],
    total: 1,
  };
}

export default getCurrentUser;
