import {
  TextField,
  ArrayField,
  Datagrid,
  ReferenceField,
  Labeled,
  TextFieldProps,
} from "ra-ui-materialui";
import React from "react";
import { NONE_SPECIFIED } from "../../../constants/constants";
import { ShowTabProps } from "../../../types/utilities";
import { BooleanField } from "../../fields/BooleanField";
import { Tab } from "../../Tabs";
import { useRecordContext } from "react-admin";
import Typography from "@mui/material/Typography";

const SpecialtiesList = (props: TextFieldProps) => {
  const record = useRecordContext();

  if (record.specialties.length === 0) return NONE_SPECIFIED;

  return record.specialties.map((specialty: string) => (
    <Typography component="div" variant="body1" key={specialty} {...props}>
      {specialty}
    </Typography>
  ));
};

export function PMSShowTab(props: ShowTabProps) {
  return (
    <Tab {...props} label="PMS Details">
      <ArrayField source="assignedPMS" label={false}>
        <Datagrid bulkActionButtons={false}>
          <TextField label="Name" source="pms.attributes.name" />
          <Labeled label="Specialties">
            <SpecialtiesList label={false} />
          </Labeled>
          <BooleanField label="Is Enabled" source="isEnabled" />
          <BooleanField
            label="Is Automatic Case Creation Enabled"
            source="isAutomaticCaseCreationEnabled"
          />
          <ReferenceField
            source="clientCodeId"
            reference="clientCodes"
            label="Client Code"
            emptyText={NONE_SPECIFIED}
            link={false}
          >
            <TextField source="attributes.code"/>
          </ReferenceField>
        </Datagrid>
      </ArrayField>
    </Tab>
  );
}
