import React from "react";
import { SchedulerView } from "./SchedulerView";
import { SchedulerToolbar } from "./SchedulerToolbar";
import { Paper } from "@mui/material";
import { Title } from "ra-ui-materialui";
import { ListBase } from "./SchedulerListBase";

export function SchedulerWrapper() {
  return (
    <ListBase>
      <SchedulerToolbar />
      <Paper>
        <Title title="Schedule" />
        <SchedulerView />
      </Paper>
    </ListBase>
  );
}
