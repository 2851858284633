import { GetManyParams } from "react-admin";
import { get } from "..";

async function getManyAdminAppointments(params: GetManyParams) {
  const { ids } = params;

  const url = "/ms/appointments";
  const filters = {
    "filter[id]": `in:${ids}`,
  };

  const data = await get(url, filters);

  return data;
}

export default getManyAdminAppointments;
