import { Identifier } from "ra-core";
import { create } from "..";
import { ClientFunctionValidationDTO } from "../../types/validation";
import createRestEndpoint from "../createRestEndpoint";

interface Args {
  clientId: Identifier;
  clientFunctionValidation: ClientFunctionValidationDTO["data"];
  isEnabled: boolean;
}

async function updateExternalValidation(args: Args) {
  const { clientId, clientFunctionValidation, isEnabled } = args;

  const baseEndpoint = `/ms/clients/${clientId}/functionValidations`;
  const validationId = clientFunctionValidation.id;
  const { remoteUrl } = clientFunctionValidation.restEndpoints[0].attributes;

  if (!isEnabled) return null;

  if (!validationId && !remoteUrl) return null;

  if (!validationId) {
    const { data: restEndpointData } = await createRestEndpoint({
      remoteUrl,
      clientId,
    });

    const body = {
      data: {
        type: "clientFunctionValidations",
        attributes: {},
        relationships: {
          endpoint: {
            data: {
              id: restEndpointData.id,
            },
          },
        },
      },
    };

    return create(baseEndpoint, body);
  }

  return null;
}

export default updateExternalValidation;
