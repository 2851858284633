import { RaRecord } from "ra-core";
import React from "react";

import { FunctionField } from "ra-ui-materialui";
import { FieldProps } from "react-admin";



export function StatusField(props: FieldProps) {
  const { label, ...rest } = props;
  return (
      <FunctionField props={rest} label={props.label} render={(record: RaRecord) => {
        return record.attributes.status === "Nonworking" ? "Break" : record.attributes.status
      }}/>
  );
}
