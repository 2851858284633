import { ResourceManagementRulesDTO } from "../../types/resources";

type ErrorMessage = string;

interface Values {
  resourceManagementRules: ResourceManagementRulesDTO["data"][];
}

function validateResourceRules(values: Values) {
  const errors: Record<string, ErrorMessage> = {};

  if (
    !values.resourceManagementRules ||
    !values.resourceManagementRules.length
  ) {
    return errors;
  }

  const [rules] = values.resourceManagementRules;
  const { appointmentDuration } = rules.attributes;

  if (appointmentDuration) {
    if (appointmentDuration < 5) {
      errors["resourceManagementRules[0].attributes.appointmentDuration"] =
        "Appointment must be at least 5 minutes long";
    }
    if (appointmentDuration > 60) {
      errors["resourceManagementRules[0].attributes.appointmentDuration"] =
        "Appointment cannot be longer than 60 minutes";
    }
  }

  return errors;
}

export default validateResourceRules;
