import { Identifier } from "ra-core";
import create from "zustand";
import dataProvider from "../dataProvider";
import { FeatureFlagService } from "../featureFlags";
import { TOGGLE_SCHEDULER_APPOINTMENT_HOLDS } from "../featureFlags/flags";

export type SchedulerBookingState = "PENDING" | "HELD" | "COMPLETE";
export type AppointmentStartingState = "Available" | "Nonworking" | null;

type State = {
  appointmentId: Identifier | null;
  startingState: AppointmentStartingState;
  holdAppointment: (args: {
    startingState: AppointmentStartingState;
    appointmentId: Identifier;
  }) => void;
  releaseAppointment: () => void;
  state: SchedulerBookingState;
  complete: () => void;
  reset: () => void;
};

export const useSchedulerBookingStore = create<State>((set, get) => ({
  appointmentId: null,
  startingState: null,
  state: "PENDING",
  holdAppointment: ({ appointmentId, startingState }) => {
    if (startingState === "Available") {
      const featureFlags = FeatureFlagService.getInstance();
      const isSchedulerAppointmentHoldEnabled = featureFlags.getFlag(
        TOGGLE_SCHEDULER_APPOINTMENT_HOLDS
      );
      if (isSchedulerAppointmentHoldEnabled) {
        dataProvider.create("appointmentHolds", {
          data: {
            appointmentId: appointmentId
          },
        });
      } else {
        dataProvider.create("makeAppointmentNonWorking", {
          data: { id: appointmentId },
        });
      }
    }

    return set({
      appointmentId,
      startingState,
      state: "HELD",
    });
  },
  releaseAppointment: () => {
    const { startingState, appointmentId, reset } = get();
    if (!appointmentId) {
      return;
    }

    if (startingState === "Available") {
      const featureFlags = FeatureFlagService.getInstance();
      const isSchedulerAppointmentHoldEnabled = featureFlags.getFlag(
        TOGGLE_SCHEDULER_APPOINTMENT_HOLDS
      );
      if (isSchedulerAppointmentHoldEnabled) {
        dataProvider.create("releaseAppointmentHolds", {
          data: { appointmentId },
        });
      } else {
        dataProvider.create("makeAppointmentAvailable", {
          data: { id: appointmentId },
        });
      }
    }

    set({
      state: "PENDING",
    });

    return reset();
  },
  complete: () => {
    const { reset } = get();

    set({
      state: "COMPLETE",
    });

    // This little hack is due to the fact that this causes react to
    // re-render before redirecting the user to the new booking page.

    // Setting the state to Pending straight away causes the Create Form to quickly
    // render the control state, but this causes issues with React Admin's underlying form state
    // As a result, it causes the "Unsaved changes prompt" to display
    setTimeout(() => {
      set({
        state: "PENDING",
      });
    }, 1000);

    return reset();
  },
  reset: () => {
    return set({
      appointmentId: null,
      startingState: null,
    });
  },
}));
