import { ShowTabProps } from "../../types/utilities";
import { useRecordContext } from "react-admin";
import get from "lodash/get";
import { ClinicalReport } from "../../types/prescriptions";
import { Tab } from "../Tabs";
import { Group } from "../layout/Group";
import { Heading } from "../ui/Heading";
import { Grid, Typography } from "@mui/material";
import { styles } from "./PrescriptionsShow";
import React from "react";

export function SummaryTab(props: ShowTabProps) {
  const EXCLUDE_CATEGORIES = ["General information", "Messages"];

  const record = useRecordContext();
  const clinicalReport = get(record, "traversal.clinicalReport") as ClinicalReport;

  if (!clinicalReport) {
    return (<Tab data-testid="empty-clinical-summary-tab" {...props} label="Summary">
      <Typography>
        Summary is not available for this prescription
      </Typography>
    </Tab>)
  }

  const validCategories = clinicalReport.summary.categories.filter(
    (category) => !EXCLUDE_CATEGORIES.includes(category.displayText)
  );

  return (
    <Tab data-testid="clinical-summary-tab" {...props} label="Summary">
      {validCategories.map((category, categoryIndex) => (
        <Group
          key={categoryIndex}
          heading={<Heading level={2}>{category.displayText}</Heading>}
        >
          {category.subCategories.map((subCategory, subCategoryIndex) => (
            <Grid key={subCategoryIndex} sx={styles.subCategoriesContainer} container>
              <Grid item xs={4}>
                <Typography sx={styles.subCategoryHeader}>
                  {subCategory.displayText}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {subCategory.summaryGroups.map((summaryGroup, summaryGroupIndex) => (
                  <Typography key={summaryGroupIndex} dangerouslySetInnerHTML={{ __html: summaryGroup.displayText }}
                              sx={styles.summaryGroup} />
                ))}
              </Grid>
            </Grid>
          ))}
        </Group>
      ))}
    </Tab>
  );
}