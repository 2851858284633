import React from "react";
import { useRecordContext } from "ra-core";
import { TextFieldProps } from "ra-ui-materialui";
import { Colors } from "../../theme";
import get from "lodash/get";
import { FileFormatTruncatedText } from "../ui/FIleFormatTruncatedText";
import { FileIcon, LinkIcon } from "./FileIcon";
import { styled } from "@mui/material";

interface Props extends TextFieldProps {
  titleSource: string;
  imgSource: string;
  resourceTypeSource: string;
}

const StyledDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  background: Colors.LightestGrey,
  width: "200px",
  padding: "8px",
  borderRadius: "4px",
  boxSizing: "border-box",
  paddingRight: theme.spacing(1),
  border: `1px solid ${Colors.BrightBlue}`,
}));

export function FileImageField(props: Props) {
  const record = useRecordContext(props);
  const title = get(record, props.titleSource, "");
  const resourceType = get(record, props.resourceTypeSource, "");

  return (
    <StyledDiv>
      {resourceType === "Link" ? (
        <LinkIcon {...props} />
      ) : (
        <FileIcon {...props} />
      )}
      <FileFormatTruncatedText title={title} />
    </StyledDiv>
  );
}

FileImageField.defaultProps = {
  titleSource: "title",
  imgSource: "url",
  source: "url",
  title: "title",
  resourceTypeSource: "File",
};
