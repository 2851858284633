import React from "react";
import TextField from "@mui/material/TextField";
import { useController } from "react-hook-form";

export function MultilineInput({ input: inputProps, ...props }: any) {
  const { field, fieldState } = useController({ name: props.name });
  const { isTouched, error } = fieldState;

  return (
    <TextField
      {...field}
      error={!!(isTouched && error?.message)}
      helperText={isTouched && error?.message}
      {...inputProps}
      {...props}
      multiline
      fullWidth
    />
  );
}
