import { create } from "..";
import { FollowUpActionDTO } from "../../types/workQueues";

interface Args {
  clientId: string;
  userId: string;
  outputs: FollowUpActionDTO[];
  consultationId: string;
}

async function uploadBookingOutputs(args: Args) {
  const { clientId, userId, outputs, consultationId } = args;

  const consultationOutputs = {data: [] as any};
  const baseEndpoint = `/clients/${clientId}/users/${userId}`;
  const fileEndpoint = `${baseEndpoint}/fileUpload`;
  const outputEndpoint = `${baseEndpoint}/consultations/${consultationId}/consultationOutputs/batch`;

  if(!outputs.length) return;

  for await (const output of outputs) {
    if (!output?.file) continue;

    const file = output.file?.rawFile;
    const formData = new FormData();

    formData.append("file", file);
    formData.append("filename", file.name);

    const fileRes = await create(fileEndpoint, formData);

    const outputBody = {
      attributes: {
        description: file.name,
        type: output.attributes.followUpActionType,
        tag: output.attributes.tag,
      },
      type: "consultationOutputs",
      relationships: {
        file: {
          data: {
            id: fileRes.data.id,
            type: fileRes.data.type,
          },
        },
      },
    };

    consultationOutputs.data.push(outputBody);
  }

  if (consultationOutputs.data.length)
    await create(outputEndpoint, consultationOutputs);
}

export default uploadBookingOutputs;
