import React from "react";
import { styled } from "@mui/material/styles";
import { Colors } from "../../../../theme";

const PREFIX = "AttachmentsSection";

const classes = {
  section: `${PREFIX}-section`,
};

const Root = styled("div")({
  [`&.${classes.section}`]: {
    borderBottom: `solid 1px ${Colors.MediumGrey}`,
    "&:last-child": {
      border: "none",
    },
    "& > div:first-of-type": {
      width: "100%",
    },
    "& > span": {
      width: "100%",
    },
  },
});

interface Props {
  children: React.ReactNode;
}

export function Section(props: Props) {
  const { children } = props;

  return <Root className={classes.section}>{children}</Root>;
}
