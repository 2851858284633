import { create } from ".";

interface Data {
  clientId: string;
  userName: string;
}

const apiUrl = `/resetPassword/start`;

async function resetPassword(data: Data) {
  const { userName, clientId } = data;

  const requestBody = {
    data: {
      type: "passwordResetRequests",
      attributes: {
        userName,
        clientId,
      },
    },
  };

  await create(apiUrl, requestBody);

  return {
    data: { id: "id-to-satisfy-ra" },
  };
}

export default resetPassword;
