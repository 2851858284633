type ErrorMessage = string;

type PatientValidationProperties =
  | "phoneNumber"
  | "address.addressLine1"
  | "address.city"
  | "address.postCode"
  | "address.countryCode"
  | "address";

export type PatientValidation = {
  [Key in PatientValidationProperties]?: ErrorMessage;
};

function validatePatientAddress(values: any) {
  const errors: PatientValidation = {};

  const shouldUseGuardianAddress =
    values?.isDependant && values?.shouldCopyGuardianAddress;

  if (shouldUseGuardianAddress) return {};

  if (!values.address?.addressLine1) {
    errors["address.addressLine1"] =
      "The patient's first line of address is required";
  }

  if (!values.address?.city) {
    errors["address.city"] = "The patient's city is required";
  }

  if (!values.address?.countryCode) {
    errors["address.countryCode"] =
      "The patient's country of residence is required";
  }

  if (!values.phoneNumber) {
    errors["phoneNumber"] = "The patient's phone number is required";
  }

  return errors;
}

export default validatePatientAddress;
