import { styled } from "@mui/material/styles";
import React from "react";
import { CheckboxGroupInput, FieldProps, ReferenceInput } from "react-admin";

import AutocompleteInput from "../../inputs/AutocompleteInput";

const Root = styled("div")({
  display: "flex",
});

interface Props extends FieldProps {
  notificationType: string;
  name: string;
  withReloadData?: boolean;
}

export const NotificationSetting = React.memo(function NotificationSetting(
  props: Props
) {
  const { notificationType, name, ...fieldProps } = props;

  return (
    <Root>
      <CheckboxGroupInput
        {...fieldProps}
        label=""
        source="notificationTypeSettings"
        helperText=""
        choices={[{ id: notificationType, name }]}
      />
      <ReferenceInput
        source={`templateIds.attributes.templates[${notificationType}].id`}
        reference="dynamicTemplates"
        sx={{ width: "460px" }}
        enableGetChoices={input => {
          const inputText = input?.name;
          if (!inputText) return false;
          return inputText.length >= 1;
        }}
      >
        <AutocompleteInput
          label="Template Name"
          filterToQuery={searchText => ({ name: searchText })}
          placeholder="Search by Template name..."
          source="id"
          optionText="attributes.name"
        />
      </ReferenceInput>
    </Root>
  );
});
