import { create } from "..";

interface Params {
  clientId: string;
  userId: string;
  bookingId: string;
}

async function sendImageRequest(params: Params) {
  const { bookingId } = params;

  const api = `/ms/bookings/${bookingId}/sendImageRequest`;

  await create(api);

  return {
    data: { id: "id-to-satisfy-ra" },
  };
}

export default sendImageRequest;
