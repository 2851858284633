import { GetListParams } from "ra-core";
import { list } from "..";
import { ClientCodeDTO } from "../../types/clients";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

async function listClientCodes(params: GetListParams) {
  const { filter, sort } = params;
  const { code, patientManagementSystem } = filter;

  const sortOrder = sort.order === "DESC" ? "-" : "";
  const formattedSort = `${sortOrder}${sort.field}`;

  const filteredParams = shallowlyRemoveEmptyValues({
    sort: formattedSort,
    "filter[code]": code,
  });

  const { data, total } = await list("client-codes", {
    params: filteredParams,
  });

  // Temporary fix whilst the API does not provide a filter mechanism for PMS.
  // We want to avoid any data integrity issues, preventing a user mapping to a code that is not associated with the specified PMS.
  if (patientManagementSystem) {
    const pmsFilteredCodes = data.filter((c:  ClientCodeDTO["data"]) => c.attributes.patientManagementSystem == patientManagementSystem);

    return { 
      data: pmsFilteredCodes, 
      total 
    };
  }

  return {
    data,
    total,
  };
}

export default listClientCodes;
