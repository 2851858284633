import isNil from "lodash/isNil";
import isUndefined from "lodash/isUndefined";

/**
 *
 * This is more of a TS helper than anything else. There's a known issue
 * with Array.prototype.filter() where the return type seems to still assume there are falsy values
 *
 * We use the type predicate to narrow down the type, since we're confident the return won't include
 * null or undefined
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 */
function nonNullable<T>(value: T): value is NonNullable<T> {
  return !isUndefined(value) && !isNil(value);
}

export default nonNullable;
