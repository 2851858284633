import { GetManyParams } from "ra-core";
import { list } from "..";

async function getManyBookings(args: GetManyParams) {
  const endpoint = "/ms/bookings";

  const params = {
    "filter[id]": `in:${args.ids}`,
    include: "client,patientRecord,user",
  };

  const data = await list(endpoint, { params });

  return data;
}

export default getManyBookings;
