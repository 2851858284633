import React from "react";
import {
  TextFieldProps,
  sanitizeFieldRestProps,
  Labeled,
  useRecordContext,
} from "react-admin";
import Typography from "@mui/material/Typography";
import get from "lodash/get";
import formatAddress from "./formatAddress";
import { EMPTY_TEXT } from "../../constants/constants";

const addressFieldStyles = {
  marginBottom: "16px",
};

export function GPAddressField(props: TextFieldProps) {
  const { source, ...rest } = props;
  const record = useRecordContext();

  if (!record || !source) return null;

  const gpRecord = get(record, source);

  if (!gpRecord)
    return (
      <Labeled label="GP Surgery Address">
        <Typography
          component="div"
          variant="body1"
          sx={addressFieldStyles}
          {...sanitizeFieldRestProps(rest)}
        >
          {EMPTY_TEXT}
        </Typography>
      </Labeled>
    );

  const details = {
    ...gpRecord.surgery.address,
  };

  const formattedAddress = formatAddress(details);

  return (
    <Labeled label="GP Surgery Address">
      <Typography
        component="div"
        variant="body1"
        sx={addressFieldStyles}
        {...sanitizeFieldRestProps(rest)}
      >
        {formattedAddress}
      </Typography>
    </Labeled>
  );
}
