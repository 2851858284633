import React, { useState } from "react";
import { Group } from "../../../layout/Group";
import { Stack } from "../../../layout/Stack";
import { PmsPatientCaseManualLink } from "../../../../types/patientManagementSystems";
import { PmsEditCaseLinkedPanel } from "./PmsEditCaseLinkedPanel";
import { PmsEditSearchCasePanel } from "./PmsEditSearchCasePanel";
import { useFormContext } from "react-hook-form";



export function PmsEditContent() {
  const [linkedCase, setLinkedCase] = useState(null as PmsPatientCaseManualLink | null);
  const { setValue, reset } = useFormContext();


  const handleLink = (caseToLink: PmsPatientCaseManualLink) => {
    setLinkedCase(caseToLink);
    updateFormState(caseToLink);
  };

  const handleUnlink = () => {
    setLinkedCase(null);
    updateFormState(null);
  };

  const updateFormState = (caseToLink: PmsPatientCaseManualLink | null) => {
    const setValueOptions = { shouldDirty: true };

    if (!caseToLink) {
      reset(); // nothing changed, nothing to save.
      setValueOptions.shouldDirty = false;
    }

    setValue("pmsPatientCase.caseNumber", caseToLink?.caseNumber, setValueOptions);
    setValue("pmsPatientCase.caseReference", caseToLink?.caseReference, setValueOptions);
    setValue("pmsPatientCase.patientReference", caseToLink?.patientReference, setValueOptions);
  };

  const isCaseLinked = Boolean(linkedCase);

  return (
    <Stack>
      <Group>
        { isCaseLinked ? 
            <PmsEditCaseLinkedPanel linkedPmsCase={linkedCase!} onUnlinkClick={handleUnlink} />
            : <PmsEditSearchCasePanel onLinkClick={handleLink} />
        }
      </Group>
    </Stack>
  );
}