import axios, { AxiosError, AxiosRequestConfig } from "axios";
import qs from "query-string";
import { HttpError } from "react-admin";
import { getConfig } from "../config";
import { getIsPublicEndpoint, getValidAccessToken } from "./auth";
import consolidateRecords from "./helpers/consolidateRecords";
import { getErrorMessageOrDefault } from "./errors";

interface AxiosErrorData {
  errors: [
    {
      detail: string;
      code: string;
    }
  ];
}

type NetworkInterceptorCallback = (
  axiosConfig: AxiosRequestConfig
) => Promise<AxiosRequestConfig>;

const isAxiosError = (e: any): e is AxiosError<AxiosErrorData> =>
  "isAxiosError" in e;

const config = getConfig();

export const getBaseUrl = (options: { apiVersion: string }) => {
  const { apiVersion } = options;
  return `${config.api.apiHost}/v${apiVersion}`;
};

export const baseUrl = getBaseUrl({ apiVersion: config.api.apiVersion });

const client = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/vnd.api+json",
    Accept: "application/vnd.api+json",
  },
  paramsSerializer: params => qs.stringify(params),
});

client.interceptors.request.use(
  async clientConfig => {
    const { url = "" } = clientConfig;
    const isPublicEndpoint = getIsPublicEndpoint(baseUrl, url);
    if (isPublicEndpoint) return clientConfig;

    const accessToken = await getValidAccessToken();
    if (accessToken) {
      if (!clientConfig.headers) {
        clientConfig.headers = {};
      }

      clientConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    return clientConfig;
  },
  error => {
    Promise.reject(error);
  }
);

export const interceptNetworkRequests = (
  callback: NetworkInterceptorCallback,
  onError?: (error: any) => void
) => {
  const interceptor = client.interceptors.request.use(callback, onError);

  return () => client.interceptors.request.eject(interceptor);
};

interface QueryParams {
  page?: Record<string, unknown>;
  filter?: Record<string, unknown>;
  include?: string;
  [key: string]: any;
}

export async function list(url: string, reqConfig: AxiosRequestConfig = {}) {
  try {
    const res = await client.get(url, reqConfig);

    const { data, meta, included = [] } = res.data;

    if (included.length === 0) {
      const totalRecords = meta ? meta["total-records"] : data.length;
      return { data, total: totalRecords };
    }

    const consolidatedData = consolidateRecords(data, included);

    return { data: consolidatedData, total: meta["total-records"], included };
  } catch (err) {
    const message = "We weren't able to get a list of your requested record";
    throw handleError(err, message);
  }
}

export async function get(
  url: string,
  params?: QueryParams,
  useSapiFormat = true
) {
  try {
    const res = await client.get(url, { params });

    if (!useSapiFormat) {
      return res.data;
    }

    const { data, included = [], links } = res.data;

    /**
     * Refactor this so it's return type is consistent. Right now it can be either an array or an object
     */
    if (included.length === 0) {
      return { data, links };
    }

    const input = Array.isArray(data) ? data : [data];

    const consolidatedData = consolidateRecords(input, included);

    return { data: consolidatedData, links };
  } catch (err) {
    const message = "We weren't able to get your requested record";
    throw handleError(err, message, useSapiFormat);
  }
}

// Because this function iterates over the links returned by each request, it results in multiple requests.
// If you find yourself needing this functionality, chat with one of the server-side devs to see if there's an alternative
export async function getAll(url: string) {
  async function sendRequest(reqUrl: string) {
    const res = await get(reqUrl);
    const data = res.data;

    if (res.links?.next) {
      const nextUrl = res.links.next.split("v1")[1];
      const nextResults = await sendRequest(nextUrl);
      data.push(...nextResults);
    }

    return data;
  }

  const res = await sendRequest(url);

  return { data: res };
}

export async function create(url: string, body: any = {}, reqConfig?: any) {
  try {
    const res = await client.post(url, body, reqConfig);

    const { data, included = [] } = res.data;

    if (included.length === 0) {
      return { data };
    }

    const consolidatedData = consolidateRecords([data], included);

    return { data: consolidatedData[0] };
  } catch (err) {
    const message = "We weren't able to complete your create request";
    throw handleError(err, message);
  }
}

export async function update(
  url: string,
  body: any,
  reqConfig?: AxiosRequestConfig,
  updateMethod: "put" | "patch" = "patch",
  useSapiFormat = true
) {
  try {
    const res = await client[updateMethod](url, body, reqConfig);

    if (!useSapiFormat) {
      return res.data;
    }

    const { data, included = [] } = res.data;

    if (included.length === 0) {
      return { data };
    }

    const consolidatedData = consolidateRecords([data], included);

    return { data: consolidatedData[0] };
  } catch (err) {
    const message = "We weren't able to to complete your update request";
    throw handleError(err, message, useSapiFormat);
  }
}

export async function remove(url: string, reqConfig?: AxiosRequestConfig) {
  try {
    const res = await client.delete(url, reqConfig);

    return res.data;
  } catch (err) {
    const message = "We weren't able to to complete your delete request";
    throw handleError(err, message);
  }
}

export async function getFile(url: string) {
  try {
    const res = await client.get(url, { responseType: "blob" });

    const { data } = res;

    return { data };
  } catch (err) {
    const message = "We weren't able to to download your request file";
    throw handleError(err, message);
  }
}


export async function NonJsonApiCreate(url: string, body: any = {}, reqConfig?: any) {
  try {
    const res = await client.post(url, body, reqConfig);

    return res.data;

  } catch (err) {
    const message = "We weren't able to complete your create request";
    throw handleError(err, message);
  }
}

function handleError(
  err: unknown,
  defaultMessage: string,
  useSapiFormat = true
) {
  if (axios.isCancel(err)) {
    return "ABORT";
  }

  if (isAxiosError(err)) {
    if (err.response) {
      const { data, status, statusText } = err.response;

      if (!useSapiFormat || !data) {
        const errorMessage = getErrorMessageOrDefault(status, defaultMessage);
        return new HttpError(errorMessage, status, statusText);
      }

      const filteredErrors = data.errors.filter(
        errorObject => errorObject.code !== "400_validation"
      );

      const [error] = data.errors.length > 1 ? filteredErrors : data.errors;

      const { code } = error;
      const detail = error.detail ?? "";
      const errorMessage = getErrorMessageOrDefault(code, defaultMessage);
      const fullMessage = `${errorMessage}: ${detail}`;

      return new HttpError(fullMessage, status, error);
    }
  }

  return new Error(defaultMessage);
}
