import { ClientEmployerChange } from "../../types/clientEmployer";
import { ChangeAction } from "../../types/common";

type ErrorMessage = string;
interface NotificationError {
  attributes: {
    employerRequiredOnBookings?: ErrorMessage;
  };
}
interface Errors {
  employerSettings: NotificationError;
}

const validateEmployers = (values: any) => {
  const errors: Errors = {
    employerSettings: {
      attributes: {},
    },
  };

  const { clientEmployersTotal, employerSettings, clientEmployersChanges } = values;

  const isEmployerRequiredOnBookings =
    employerSettings?.attributes?.employerRequiredOnBookings ?? false;

  const addedCount = clientEmployersChanges.filter((r:ClientEmployerChange) => r.action === ChangeAction.Added).length;
  const deletedCount = clientEmployersChanges.filter((r:ClientEmployerChange) => r.action === ChangeAction.Deleted).length;

  if (isEmployerRequiredOnBookings) {
    if (clientEmployersTotal + addedCount - deletedCount === 0) {
      errors.employerSettings.attributes.employerRequiredOnBookings =
        "Please provide at least one employer";
    }
  }

  return errors;
};

export default validateEmployers;
