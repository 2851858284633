import React from "react";
import { BookingGroup } from "./BookingGroup";

export function CancelBookingFields() {
  return (
    <>
      <BookingGroup />
    </>
  );
}
