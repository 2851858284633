import { create } from "..";

interface Args {
  appointmentsPerHour?: number;
  appointmentDuration?: number;
  clinicalPractitionerId: string;
}

function createResourceRules(args: Args) {
  const {
    appointmentDuration,
    clinicalPractitionerId,
    appointmentsPerHour = "",
  } = args;

  if (!appointmentDuration) {
    return Promise.resolve(null);
  }

  const endpoint = "/ms/resourceManagement/resourceManagementRules";
  const body = {
    data: {
      type: "resourceManagementRules",
      attributes: {
        appointmentsPerHour,
        appointmentDuration,
      },
      relationships: {
        clinicalPractitioner: {
          data: {
            type: "clinicalPractitioners",
            id: clinicalPractitionerId,
          },
        },
      },
    },
  };

  return create(endpoint, body);
}

export default createResourceRules;
