import React from "react";
import {
  BooleanInput,
  Edit, SaveButton,
  SimpleForm,
  TextInput,
  Title,
  Toolbar,
  useNotify,
  useRecordContext,
  useRefresh
} from "react-admin";
import { EditActions } from "../Actions";
import get from "lodash/get";
import { AutocompleteArrayInput, ReferenceArrayInput } from "ra-ui-materialui";
import { Heading } from "../ui/Heading";


function PageTitle() {
  const record = useRecordContext();
  const name = get(record, "attributes.name");

  return <Title title={name} />;
}


export function ClinicalProductsEdit() {
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <Edit
      mutationMode="pessimistic"
      title={<PageTitle />}
      actions={<EditActions />}
      mutationOptions={{
        onSuccess: () => {
          notify("Changes to the clinical product were successful");
          refresh();
        }
      }}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <GeneralDetails/>
      </SimpleForm>
    </Edit>
  );
}

function GeneralDetails() {
  const record = useRecordContext();

  const clients = get(record, "clients", []);

  return (<>
    <Heading level={2}>General Details</Heading>
    <TextInput
      label="Name"
      source="attributes.name"
    />
    <TextInput
      label="Code"
      source="attributes.code"
      inputProps={{ maxLength: 10 }}
      disabled={clients.length}
    />
    <BooleanInput
      label="Is Enabled"
      source="attributes.isEnabled"
      sx={{ marginBottom: "5px" }}
    />
    <ReferenceArrayInput
      source={"servicesIds"}
      reference="clinicalServicesWithSpecialities"
      filter={{ deliveryType: "Synchronous" }}
      required
    >
      <AutocompleteArrayInput
        id={"services"}
        sx={{ display: "inline-flex" }}
        label="Services"
        optionText="name"
        filterToQuery={query => ({
          name: query
        })}
        groupBy={(choice) => choice.group}
      />
    </ReferenceArrayInput>
  </>);
}
