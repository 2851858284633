import { Identifier } from "ra-core";
import { create, list } from "..";
import {
  RuleType,
  ValidationRules,
  ValidationRulesDTO,
} from "../../types/validation";

interface Args {
  clientId: Identifier;
}

const ruleTypes: RuleType[] = [
  "Multipoint",
  "ClientIdentifier",
  "ClientFunction",
];

async function createMissingValidationRules(
  rules: ValidationRulesDTO["data"],
  clientId: Identifier
) {
  const newRules: ValidationRulesDTO["data"] = [];
  if (rules.length === ruleTypes.length) return newRules;
  const promises = [];

  const endpoint = `/ms/clients/${clientId}/validationRules`;
  const existingRules = rules.map(({ attributes }) => attributes.ruleType);
  const missingRules = ruleTypes.filter(rule => !existingRules.includes(rule));

  for await (const rule of missingRules) {
    const createBody = {
      data: {
        type: "clientValidationRules",
        attributes: {
          ruleType: rule,
          isEnabled: false,
        },
      },
    };

    const res = create(endpoint, createBody);
    promises.push(res);
  }

  const res = await Promise.all(promises);

  res.forEach(rule => {
    newRules.push(rule.data);
  });

  return newRules;
}

function convertToDict(rules: ValidationRulesDTO["data"]): ValidationRules {
  const multipointRecord = rules.find(
    ({ attributes }) => attributes.ruleType === "Multipoint"
  )!;

  const clientIdentifiersRecord = rules.find(
    ({ attributes }) => attributes.ruleType === "ClientIdentifier"
  )!;

  const clientFunctionRecord = rules.find(
    ({ attributes }) => attributes.ruleType === "ClientFunction"
  )!;

  return {
    multipoint: {
      id: multipointRecord.id,
      isEnabled: multipointRecord.attributes.isEnabled,
    },
    clientIdentifier: {
      id: clientIdentifiersRecord.id,
      isEnabled: clientIdentifiersRecord.attributes.isEnabled,
    },
    clientFunction: {
      id: clientFunctionRecord.id,
      isEnabled: clientFunctionRecord.attributes.isEnabled,
    },
  };
}

async function getValidationRules(args: Args) {
  const { clientId } = args;
  const endpoint = `/ms/clients/${clientId}/validationRules`;

  const { data } = await list(endpoint);
  const newRules = await createMissingValidationRules(data, clientId);
  const consolidatedRules: ValidationRulesDTO["data"] = [...data, ...newRules];
  const validationRulesDict = convertToDict(consolidatedRules);

  return validationRulesDict;
}

export default getValidationRules;
