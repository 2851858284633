import { Identifier } from "ra-core";
import { create } from ".";

interface Args {
  clientId: Identifier;
  remoteUrl: string;
}

async function createRestEndpoint(args: Args) {
  const { remoteUrl, clientId } = args;
  const endpoint = `/admin/clients/${clientId}/restEndpoints`;

  const body = {
    data: {
      type: "restEndpoints",
      attributes: {
        remoteUrl,
      },
    },
  };

  const { data } = await create(endpoint, body);

  return { data };
}

export default createRestEndpoint;
