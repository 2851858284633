import React from "react";
import { ReferenceInput, useRecordContext } from "react-admin";
import { AssignButton } from "./AssignButton";
import { AssignUserAutocompleteInput } from "./AssignUserAutocompleteInput";
import { Box } from "@mui/material";

export function AssignmentActions() {
  const record = useRecordContext();
  const { isCompleted } = record!.attributes;

  if (isCompleted) {
    return null;
  }

  return (
    <ReferenceInput
      source="relationships.assignee.data.id"
      reference="resourceManagement"
      filter={{ includeCurrentUser: true }}
    >
      <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
        <AssignUserAutocompleteInput />
        <AssignButton />
      </Box>
    </ReferenceInput>
  );
}
