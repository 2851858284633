import { create } from "..";

interface Args {
  id: string;
}

async function createAppointments(args: Args) {
  const { id } = args;
  const endpoint = `/ms/resourceManagement/shifts/${id}/createAppointments`;

  const res = await create(endpoint);

  return {
    data: res.data,
  };
}

export default createAppointments;
