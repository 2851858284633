import { ClinicalPractitionerList } from "./ClinicalPractitionerList";
import { ClinicalPractitionerCreate } from "./ClinicalPractitionerCreate";
import { ClinicalPractitionerEdit } from "./ClinicalPractitionerEdit";
import { ClinicalPractitionerShow } from "./ClinicalPractitionerShow";
import ClinicalPractitionerIcon from "@mui/icons-material/Group";

export const clinicalPractitionerResources = {
  name: "clinicalPractitioners",
  options: { label: "Clinical Practitioners" },
  list: ClinicalPractitionerList,
  icon: ClinicalPractitionerIcon,
  create: ClinicalPractitionerCreate,
  show: ClinicalPractitionerShow,
  edit: ClinicalPractitionerEdit,
};
