import { useCreate, useNotify, useRecordContext, useRefresh } from "ra-core";
import get from "lodash/get";
import React from "react";
import { Button } from "../buttons/Button";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";

export function CreateAppointmentButton() {
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();

  const id = get(record, "id", "");
  const status = get(record, "attributes.status");

  const [create, { isLoading, error }] = useCreate(
    "createAppointments",
    {
      data: {
        id,
      },
    },
    {
      onSuccess: () => {
        notify("The appointments were created");
        refresh();
      },
      onError: () => {
        notify((error as Error).message);
      },
    }
  );

  if (!record) return null;

  const hasBeenProcessed = status === "Processed";

  if (hasBeenProcessed) return null;

  const buttonCopy = "Confirm shift";

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Button
        disabled={isLoading}
        startIcon={<AddIcon />}
        onClick={() => create()}
        aria-label={buttonCopy}
      >
        {buttonCopy}
      </Button>
    </Box>
  );
}
