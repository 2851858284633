import { getFile } from ".";
import downloadBlob from "./downloadBlob";

interface Props {
  clientId: string;
  fileId: string;
  fileName: string;
  id: string;
  url: string;
  userId: string;
}

async function downloadFile(params: Props) {
  const { url, fileName } = params;

  const { data: blob } = await getFile(url);

  downloadBlob(blob, fileName);

  return {
    data: {
      id: "id-to-satisfy-ra",
    },
  };
}

export default downloadFile;
