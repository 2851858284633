import { Identifier } from "ra-core";
import { list } from "..";
import { PatientValidationDTO } from "../../types/validation";

type Args = {
  clientId: Identifier;
};

function getLastItem<T>(arr: T[]) {
  const [item] = arr.slice(-1);

  return item;
}

const defaultValue: PatientValidationDTO["data"] = {
  id: null,
  type: "clientMultipoints",
  attributes: {
    isEnabled: true,
    minValidColumns: 1,
    rows: [],
  },
  clientProperties: {
    specifiedColumns: [],
  },
};

async function getPatientIdentifiers(args: Args) {
  const { clientId } = args;
  const endpointUrl = `/ms/clients/${clientId}/multipoints`;

  try {
    const { data } = await list(endpointUrl);

    if (data.length === 0) return defaultValue;

    const lastItem = getLastItem<PatientValidationDTO["data"]>(data);

    const patientValidationData: PatientValidationDTO["data"] = {
      ...lastItem,
      clientProperties: {
        specifiedColumns: [],
      },
    };

    return patientValidationData;
  } catch (err) {
    console.error(err);
    return defaultValue;
  }
}

export default getPatientIdentifiers;
