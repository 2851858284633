import { useRecordContext } from "react-admin";
import { Group } from "../../layout/Group";
import { Heading } from "../../ui/Heading";
import { ArrayField } from "ra-ui-materialui";
import { SimpleIteratorLayout } from "../../fields/SimpleIteratorLayout";
import React from "react";
import { DownloadDocumentButton } from "./DownloadDocumentButton";

export function PatientAttachmentsGroup() {
  const record = useRecordContext();
  const clientId = record.tenantId.split(".")[0];

  return (<Group heading={<Heading level={2}>Patient attachments</Heading>}>
    <ArrayField label={false} source={"files"}>
      <SimpleIteratorLayout>
        <DownloadDocumentButton clientId={clientId} patientRecordId={record.patientRecordId} />
      </SimpleIteratorLayout>
    </ArrayField>
  </Group>);
}


