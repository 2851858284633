import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { AppointmentFilterCategory, AppointmentFiltersDTO } from "../../types/appointments";
import { MaintenanceWindowsDTO } from "../../types/clients";
import pureSplice from "../../utilities/pureSplice";
import {
  APPOINTMENT_HOLD_DURATION_MINIMUM,
  APPOINTMENT_HOLD_DURATION_MAXIMUM,
  INPUT_TIME_INVALID_VALUE,
} from "../../constants/appointments";

type ErrorMessage = string;

interface MaintenanceWindowError {
  attributes: {
    description?: ErrorMessage;
    start?: ErrorMessage;
    end?: ErrorMessage;
  };
}

interface AppointmentFiltersAttributeErrors {
  parsedStartTime?: string;
  parsedEndTime?: string;
  appointmentHoldDurationMinutes?: string;
}

interface AppointmentFilterCategoriesError {
  specialty?: ErrorMessage;
  consultationType?: ErrorMessage;
}

interface Errors {
  appointmentFilterCategories: AppointmentFilterCategoriesError[];
  maintenanceWindows: MaintenanceWindowError[];
  appointmentFilters: {
    attributes: AppointmentFiltersAttributeErrors;
  };
}

interface Values {
  maintenanceWindows: MaintenanceWindowsDTO["data"];
  appointmentFilters: AppointmentFiltersDTO["data"];
  appointmentFilterCategories: AppointmentFilterCategory[];
}

function validateServiceConfig(values: Values) {
  const { maintenanceWindows, appointmentFilters, appointmentFilterCategories } = values;
  const errors: Errors = {
    maintenanceWindows: [],
    appointmentFilters: {
      attributes: {
      },
    },
    appointmentFilterCategories: []
  };

  if (maintenanceWindows) {
    maintenanceWindows.forEach((mWindow, i) => {
      let hasErrors = false;
      if (!mWindow) return;

      const error: MaintenanceWindowError = {
        attributes: {},
      };

      const description = get(mWindow, "attributes.description");
      const start = get(mWindow, "attributes.start");
      const end = get(mWindow, "attributes.end");

      if (!description) {
        hasErrors = true;
        error.attributes.description =
          "A maintenance window needs a description";
      }

      const isStartBeforeEnd = dayjs(start).isBefore(end);

      if (!isStartBeforeEnd && mWindow.attributes.end) {
        hasErrors = true;
        error.attributes.start =
          "The scheduled start time cannot be after the scheduled end time";
      }

      if (hasErrors) {
        errors.maintenanceWindows[i] = error;
      }
    });
  }

  if (appointmentFilters) {
    if (!appointmentFilterCategories) return null;

    validateAppointmentHoldDuration(
      appointmentFilters.attributes.appointmentHoldDurationMinutes,
      errors
    );

    const filterStartTime = appointmentFilters.attributes.parsedStartTime
      ? dayjs(appointmentFilters.attributes.parsedStartTime).format("HH:mm:ss")
      : INPUT_TIME_INVALID_VALUE;
    const filterEndTime = appointmentFilters.attributes.parsedEndTime
      ? dayjs(appointmentFilters.attributes.parsedEndTime).format("HH:mm:ss")
      : INPUT_TIME_INVALID_VALUE;
    validateAppointmentFilterTimes(filterStartTime, filterEndTime, errors);


    appointmentFilterCategories.forEach((category, i: number) => {
      if (!category) return;

      const { consultationType, id } = category;
      const error: AppointmentFilterCategoriesError = {};

      const previousCategory = pureSplice(appointmentFilterCategories, i);

      const sameAppointmentFilterConfigExists = previousCategory.some(
        prevCategory => {
          const consultationTypeMatches =
            prevCategory.consultationType === consultationType;
          const specialtyMatches = prevCategory.id === id;

          return consultationTypeMatches && specialtyMatches;
        }
      );

      if (sameAppointmentFilterConfigExists) {
        error.consultationType =
          "This consultation type and specialty combination already exists.";
      }

      if (!consultationType) {
        error.consultationType = "Please choose a consultation type";
      }

      if (!id) {
        error.specialty = "Please choose a specialty or a clinical service";
      }

      if (!isEmpty(error)) {
        errors.appointmentFilterCategories[i] = error;
      }
    });
  }

  return errors;
}

function validateAppointmentHoldDuration(holdDuration: number, errors: Errors) {
  if (
    !holdDuration ||
    holdDuration < APPOINTMENT_HOLD_DURATION_MINIMUM ||
    holdDuration > APPOINTMENT_HOLD_DURATION_MAXIMUM
  ) {
    errors.appointmentFilters.attributes.appointmentHoldDurationMinutes = `Hold duration must be a value between ${APPOINTMENT_HOLD_DURATION_MINIMUM} and ${APPOINTMENT_HOLD_DURATION_MAXIMUM} minutes (inclusive)`;
  }
}

function validateAppointmentFilterTimes(
  startTime: string,
  endTime: string,
  errors: Errors
) {
  if (!startTime || startTime === INPUT_TIME_INVALID_VALUE) {
    errors.appointmentFilters.attributes.parsedStartTime =
      "Must have a valid start time";
  }

  if (!endTime || endTime === INPUT_TIME_INVALID_VALUE) {
    errors.appointmentFilters.attributes.parsedEndTime =
      "Must have a valid end time";
  }
}

export default validateServiceConfig;
