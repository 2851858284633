import { NumberField } from "ra-ui-materialui";
import React from "react";
import { ShowTabProps } from "../../../types/utilities";
import { BooleanField } from "../../fields/BooleanField";
import { Tab } from "../../Tabs";
import { Heading } from "../../ui/Heading";
import { ListExistingIdentifiers } from "../fields/ListExistingIdentifiers";

export function ValidationShowTab(props: ShowTabProps) {
  return (
    <Tab {...props} label="Validation">
      <Heading level={2}>Patient Validation</Heading>
      <BooleanField
        source="validationRules.multipoint.isEnabled"
        label="Is enabled"
      />

      <NumberField
        source="patientIdentifiers.attributes.minValidColumns"
        label="Minimum valid patient identifiers"
      />

      <Heading level={2}>Client Validation</Heading>
      <BooleanField
        source="attributes.clientIdentifierRequired"
        label="Capture Client Identifier"
      />
      <BooleanField
        source="validationRules.clientIdentifier.isEnabled"
        label="Is enabled"
      />
      <ListExistingIdentifiers />
    </Tab>
  );
}
