import { Identifier } from "react-admin";
import { get } from "..";
import {
  SendGridDynamicTemplatesDTO,
  SendGridTemplateDTO,
  Templates,
} from "../../types/notifications";
import getManySendGridDynamicTemplates from "./getManySendgridDynamicTemplates";

interface Args {
  clientId: Identifier;
}

const getTemplateName = (
  id: Identifier,
  clientTemplates: SendGridDynamicTemplatesDTO
) => {
  const templateSettings = clientTemplates.data.filter(item => item.id === id);
  return templateSettings?.[0]?.attributes.name;
};

async function getSendGridTemplates(args: Args) {
  const { clientId } = args;

  try {
    const getTemplatesEndpoint = `/ms/${clientId}/sendGridNotificationTemplates`;
    const { data } = await get(getTemplatesEndpoint);

    const ids = data.map(
      (item: SendGridTemplateDTO["data"][0]) => item.attributes.templateId
    );
    const clientTemplates = await getManySendGridDynamicTemplates({ ids });

    const templates = data.reduce(
      (prev: Templates, { attributes }: SendGridTemplateDTO["data"][0]) => {
        return {
          ...prev,
          [attributes.notificationType]: {
            id: attributes.templateId,
            name: getTemplateName(attributes.templateId, clientTemplates),
          },
        };
      },
      {}
    );

    const batchedTemplates = {
      type: "batchSendGridNotificationTemplates",
      id: clientId,
      attributes: {
        templates,
      },
    };

    return batchedTemplates;
  } catch (err) {
    console.error(err);

    return {
      id: null,
      type: "sendGridNotificationTemplates",
      attributes: {
        templates: {},
      },
    };
  }
}

export default getSendGridTemplates;
