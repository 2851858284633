import { GetOneParams } from "react-admin";
import { get } from "../gaiaClient";

export default async function getGpSurgery(params: GetOneParams) {

  const { id } = params;

  const endpoint = `/gp-surgeries/${id}`;

  const { data } = await get(endpoint);

  return { data };
}
