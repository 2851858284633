import React from "react";
import { SelectInput, useGetOne } from "react-admin";
import { splitByCapitalLetters } from "../../utilities/splitByCapitalLetters";
import { Typography } from "@mui/material";
import { useWatch } from "react-hook-form";
import { removeAllWhitespace } from "../../utilities/removeAllWhitespace";
import { useFlag } from "../../featureFlags";
import { TOGGLE_ENABLE_MESSAGE_DOCTOR, TOGGLE_ENABLE_MESSAGE_DOCTOR_CREATE_REPLY } from "../../featureFlags/flags";

type Choice = { name: string; id: string };
interface AppointmentFilterInputsProps {
  clientId: string;
  patientRecordId: string;
  appointmentConsultationType?: string;
}
export const getConsultationTypeChoices = (
  serviceId: string | number | undefined,
  email: string | undefined,
  data: Record<string, any>,
  appointmentConsultationType?: string
): Choice[] => {
  if (!data) return [];

  let consultationTypes:string[];

  // All consultation types available
  if (!data?.appointmentFiltersEnabled){
    consultationTypes = data.consultationTypes.map((s:Choice) => removeAllWhitespace(s.name));
  }
  // Service isn't selected yet - return all available types
  else if (!serviceId){
    consultationTypes = data?.availableConsultationTypes?.map((s:Choice) => removeAllWhitespace(s.name));
  }
  // Service is selected - return all available types for the service
  else {
    const service = data?.availableServices?.find((s: any) => s.id == serviceId);
    consultationTypes = service?.consultationTypes?.filter((ct: string) => email || ct === "Phone");
  }

  if (appointmentConsultationType) {
    consultationTypes = consultationTypes.filter((x: any) => getAvailableTypesForAppointmentType(appointmentConsultationType).includes(x))
  }

  return consultationTypes
    .map((ct: string) => {
      return {
        id: ct,
        name: splitByCapitalLetters(ct),
      };
    });
};

function getAvailableTypesForAppointmentType(appointmentConsultationType: string) {
  if (appointmentConsultationType === 'Video') {
    return ['Video', 'Phone']
  }
  return [appointmentConsultationType];
}

export const ConsultationTypeInput = ({
  clientId: id,
  patientRecordId,
  appointmentConsultationType
}: AppointmentFilterInputsProps) => {

  const { data, isLoading } = useGetOne("availableServices", { id, meta: {filter: { patientRecordId }} });
  const service = useWatch({name: "service"});
  const email = useWatch({name: "email"});
  let choices = getConsultationTypeChoices(service, email, data, appointmentConsultationType) ?? [];

  const messageDoctorEnabled = useFlag(TOGGLE_ENABLE_MESSAGE_DOCTOR);
  const messageDoctorCreateReplyEnabled = useFlag(TOGGLE_ENABLE_MESSAGE_DOCTOR_CREATE_REPLY);

  if (!(messageDoctorEnabled && messageDoctorCreateReplyEnabled)){
    choices = choices.filter((c: Choice) => c.id != "MessageDoctor");
  }

  if (isLoading) return null;

  return (
    <>
      {!email ? (
        <Typography>
          This patient doesn't have an email address, and will not be able
          to make Video, MAD or Onsite bookings
        </Typography>
      ) : null}
      <SelectInput
        isLoading={isLoading}
        disabled={!choices?.length}
        source="consultationType"
        label="Consultation Type"
        required
        choices={choices}
      />
    </>
  );
};
