import { RaRecord, useRecordContext } from "ra-core";
import React from "react";
import get from "lodash/get";
import { FieldProps, sanitizeFieldRestProps } from "react-admin";
import { Typography } from "@mui/material";

function getStatus(record: RaRecord) {
  const status = get(record, "appointments[0].attributes.status");

  if (status === "Cancelled" || status === "Available") {
    return status;
  }

  const isConflicting = get(record, "attributes.isConflicting");
  if (isConflicting) return "Not available";

  const isIgnored = get(record, "attributes.isIgnored");
  if (isIgnored) return "Ignored";

  const isWorking = get(record, "attributes.isWorking");

  if (!isWorking || status === "Nonworking") return "Break";

  if (status) return status;

  return "Available";
}

export function StatusField(props: FieldProps) {
  const record = useRecordContext();

  const status = getStatus(record!);

  return (
    <Typography variant="body1" {...sanitizeFieldRestProps(props)}>
      {status}
    </Typography>
  );
}
