import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  useNotify,
  useRefresh,
  Form,
  useRecordContext,
  useCreate,
} from "react-admin";
import { MessageOptionsField } from "../fields/MessageOptionsField";
import { FieldValues } from "react-hook-form";

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

type Props = DialogProps;

interface FormData {
  message: string;
}

const validate = (values: FieldValues) => {
  const errors: Partial<FormData> = {};

  if (!values.message) {
    errors.message = "A message is required";
  }

  return errors;
};

export function AddMessageDialog(props: Props) {
  const { open, onClose } = props;
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const id = String(record?.id);

  const [threadId] = id.split(":");

  const ids = {
    threadId,
  };

  function onFailure(error: unknown) {
    notify((error as Error).message, { type: "error" });
  }

  function onSuccess() {
    onClose();
    notify("Your message has been added");
    refresh();
  }

  const [sendMessage, { isLoading }] = useCreate("messages");

  function submit(values: FieldValues) {
    const payload = {
      ...ids,
      message: values.message,
    };

    sendMessage(
      "messages",
      { data: payload },
      {
        onError: onFailure,
        onSuccess,
      }
    );
  }

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="add-message-dialog"
      open={open}
      fullWidth
    >
      <Form onSubmit={submit} validate={validate} mode="onChange">
        <DialogTitle>Add a message to the thread</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            You must go into Adastra, copy the CP's reply and format it with one
            of the following templates.
          </Typography>
          <MessageOptionsField clients={record?.clients} />
          <Typography sx={{ marginTop: 2 }} variant="body1">
            Note: when you respond to this message, you will be unable to edit
            or send a follow-up response.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" aria-label="Cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            aria-label="Dialog Add Message"
          >
            {isLoading && <CircularProgress />}
            Add Message
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
