import { create } from "..";

export type ClientIdentifier = {
  id: string;
  clientIdentifier: string;
  name?: string;
  isEnabled: boolean;
  clientId: string;
  productCode?: string;
}

type Args = {
  clientId: string;
  newIdentifiersCSV?: File;
  isEnabled: boolean;
};

async function updateIdentifiers(args: Args) {
  const { clientId, newIdentifiersCSV, isEnabled } = args;

  if (!isEnabled) return;

  if (!newIdentifiersCSV) return;

  const identifiersUploadEndpoint = `/ms/clients/${clientId}/identifiers/upload`;

  const formData = new FormData();
  formData.append("file", newIdentifiersCSV);
  formData.append("filename", newIdentifiersCSV.name);

  await create(identifiersUploadEndpoint, formData);
}

export default updateIdentifiers;
