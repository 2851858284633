import { HubConnectionBuilder, HubConnection } from "@microsoft/signalr";
import { baseUrl } from "../api";
import { getValidAccessToken } from "../api/auth";
import {
  SubscriptionEventMap,
  PublishEventMap,
  SubscriptionCallback,
} from "../types/realTime";

const url = `${baseUrl}/signalR/adminHub`;

class AdminHub {
  connection: HubConnection;

  static instance: AdminHub | null = null;

  static getInstance() {
    if (!this.instance) {
      const instance = new AdminHub();
      this.instance = instance;
    }

    return this.instance;
  }

  constructor() {
    const connection = new HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: async () => getValidAccessToken(),
      })
      .withAutomaticReconnect()
      .build();

    this.connection = connection;
    this.start();
  }

  getConnectionState() {
    return this.connection.state;
  }

  reconnect() {
    console.log("reconnecting...");
    this.start();
  }

  async start() {
    const connection = this.connection;

    try {
      await connection.start();

      try {
        // swallow error incase CSO doesn't have permissions
        await connection.invoke("subscribeToAdminUpdates");
      } catch (err) {
        console.error(err);
      }
    } catch (err) {
      const start = this.start;
      setTimeout(start, 5000);
    }
  }

  addEventListener<Type extends keyof SubscriptionEventMap>(
    event: Type,
    callback: SubscriptionCallback<Type>
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.connection.on(event, callback);
  }

  removeEventListener<Type extends keyof SubscriptionEventMap>(
    event: Type,
    callback: SubscriptionCallback<Type>
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.connection.off(event, callback);
  }

  async sendRequest<Ev extends keyof PublishEventMap>(
    request: Ev,
    ...args: PublishEventMap[Ev]
  ) {
    this.connection.invoke(request, ...args);
  }

  close() {
    this.connection.stop();
    console.log("SignalR Closed");
  }
}

export const initAdminHub = () => {
  const hub = AdminHub.getInstance();
  const connection = hub.getConnectionState();

  if (connection === "Disconnected") {
    return hub.reconnect();
  }
};

export default AdminHub;
