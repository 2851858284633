export const getPlacesResult = (
  placeId: string
): Promise<google.maps.places.PlaceResult> => {
  const PlacesService = new google.maps.places.PlacesService(
    document.createElement("div")
  );
  const OK = google.maps.places.PlacesServiceStatus.OK;

  return new Promise((resolve, reject) => {
    PlacesService.getDetails({ placeId }, (results, status) => {
      if (status !== OK) {
        reject(status);
      }
      if (!results) {
        return reject(status);
      }

      resolve(results);
    });
  });
};
