import { getClientFunctionName } from "../../../components/utils/clientFunctionHelpers";
import { ClientFunctionValidationDTO } from "../../../types/validation";

function formatClientFunctionValidation(
  clientFunctionValidation: ClientFunctionValidationDTO["data"]
) {
  const { remoteUrl } = clientFunctionValidation.restEndpoints[0].attributes;

  const clientName = getClientFunctionName(remoteUrl);

  return {
    ...clientFunctionValidation,
    clientName,
  };
}

export default formatClientFunctionValidation;
