import React from "react";
import { Labeled, useRecordContext } from "react-admin";
import { LinkButtonBase } from "../buttons/LinkButtonBase";
import { Typography } from "@mui/material";
import get from "lodash/get";
import { stringify } from "query-string";

export function ConsultationOutputLink() {
  const record = useRecordContext();

  const adastraCaseRef = get(
    record,
    "bookings[0].patientManagementSystemCases[0].attributes.caseNumber"
  );

  const to = {
    pathname: "/consultationOutputs",
    search: stringify({
      // https://marmelab.com/react-admin/ListTutorial.html#linking-to-a-pre-sorted-list
      filter: JSON.stringify({
        caseRef: adastraCaseRef,
        recentBookingsOnly: false,
      }),
      page: 1,
      perPage: 10,
      sort: "id",
      order: "ASC",
    }),
  };

  if (!adastraCaseRef) {
    return (
      <Labeled label="Link to document">
        <Typography variant="body1">No Associated Case Ref</Typography>
      </Labeled>
    );
  }

  return (
    <Labeled label="Link to document">
      <LinkButtonBase to={to} text="View Document" />
    </Labeled>
  );
}
