import { Identifier } from "react-admin";
import { remove as sapiRemove } from "..";
import { remove as gaiaRemove } from "../gaiaClient";
import { ClinicalPractitionerAttachmentsDTO } from "../../types/clinicalPractitioners";
import { getConfig } from "../../config";

interface Args {
  clinicalPractitionerAttachments: ClinicalPractitionerAttachmentsDTO[];
  exitingClinicalPractitionerDocuments: ClinicalPractitionerAttachmentsDTO[];
  clinicalPractitionerId: Identifier;
}

const config = getConfig();

async function updateExitingClinicalPractitionerDocumentsInSapi(args: Args) {
  const {
    clinicalPractitionerAttachments = [],
    exitingClinicalPractitionerDocuments = [],
    clinicalPractitionerId,
  } = args;

  const attachmentsIds = clinicalPractitionerAttachments.map(({ id }) => id);

  const baseEndpoint = `/ms/clinicalPractitioners/${clinicalPractitionerId}/attachments/`;
  const toRemove = exitingClinicalPractitionerDocuments.filter(
    ({ id }) => !attachmentsIds.includes(id)
  );

  const requests = toRemove.map(({ id }) => {
    const attachmentEndpoint = `${baseEndpoint}/${id}`;
    return sapiRemove(attachmentEndpoint);
  });

  try {
    await Promise.all(requests);
  } catch (err) {
    console.error(err);
  }
}

async function updateExitingClinicalPractitionerDocumentsInGaia(args: Args) {
  const {
    clinicalPractitionerAttachments = [],
    exitingClinicalPractitionerDocuments = [],
    clinicalPractitionerId,
  } = args;

  const attachmentsIds = clinicalPractitionerAttachments.map(({ id }) => id);

  const baseEndpoint = `/clinical-practitioners/${clinicalPractitionerId}/documents`;
  const toRemove = exitingClinicalPractitionerDocuments.filter(
    ({ id }) => !attachmentsIds.includes(id)
  );
    
  const requests = toRemove.map(({ id, attributes }) => {
    const attachmentEndpoint = `${baseEndpoint}/${attributes.clinicalPractitionerAttachmentType}/${id}`;
    return gaiaRemove(attachmentEndpoint);
  });

  try {
    await Promise.all(requests);
  } catch (err) {
    console.error(err);
  }
}

export default config.featureFlags.useGaiaForCpDocuments ? updateExitingClinicalPractitionerDocumentsInGaia : updateExitingClinicalPractitionerDocumentsInSapi;
