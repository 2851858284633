import { required, useRecordContext } from "ra-core";
import {
  Labeled,
  RadioButtonGroupInput,
  sanitizeFieldRestProps,
  TextField,
  TextFieldProps,
  TextInput,
} from "ra-ui-materialui";
import React from "react";
import {
  genderChoices,
} from "../../../../constants/constants";
import { pastDate } from "../../../../validators/pastDate";
import { validDate } from "../../../../validators/validDate";
import { DateInput } from "../../../inputs/DateInput";
import { Group } from "../../../layout/Group";
import { Heading } from "../../../ui/Heading";
import get from "lodash/get";
import { useEditStateContext } from "../../../../context/EditStateContext";
import { Grid, Typography } from "@mui/material";
import DateField from "../../../fields/DateField";
import { RaRecord } from "react-admin";
import { ClientEmployerInput } from "../../../inputs/ClientEmployerInput";
import { ClientEmployerField } from "../../../fields/ClientEmployerField";


const isKycEnabled = (record: RaRecord) => {
  return record?.clients[0].attributes?.isKycEnabled;
};

export function PersonalDetails() {
  const { currentState } = useEditStateContext();

  if (currentState === "show") return <ShowContent />;

  return <EditContent />;
}


function ShowContent() {
  const record = useRecordContext();
  const clientId = get(record, "clients[0].id");
  const clientIdentifierRequired = get(record, "clients[0].attributes.clientIdentifierRequired");

  return (
    <Group heading={<Heading level={2}>Personal Details</Heading>}>
      <Grid item xs={12} sm={6}>
        <PatientTitle label="Patient Name" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled label="Client Contract Name">
          <TextField source="clients[0].attributes.name" />
        </Labeled>
      </Grid>

      <Grid item xs={12} sm={6}>
        <DateField label="Date of Birth" source="attributes.dateOfBirth" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled label="Gender">
          <TextField source="attributes.gender" />
        </Labeled>
      </Grid>
      {clientIdentifierRequired && (
        <Grid item xs={12} sm={6}>
          <Labeled label="Policy Number">
            <TextField
              source="users[0].attributes.clientIdentifier"
              emptyText="n/a"
            />
          </Labeled>
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <ClientEmployerField
          clientId={clientId}
          source="attributes.employer.name"
        />
      </Grid>

    </Group>
  );
}

function EditContent() {
  const record = useRecordContext();
  const isDependant = get(record, "attributes.isDependant");
  const kycEnabled = isKycEnabled(record);
  const idVerificationStatus = get(record, "attributes.prevIdentityVerificationStatus");
  const clientIdentifierRequired = get(record, "clients[0].attributes.clientIdentifierRequired");
  const clientId = get(record, "clients[0].id");
  const idVerificationReadOnlyField = kycEnabled && idVerificationStatus === "Verified";

  return (
    <Group heading={<Heading level={2}>Personal Details</Heading>}>
      <Grid item xs={12}>
        <TextInput
          disabled
          label="Client Contract Name"
          source="clients[0].attributes.name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          label="First Name"
          disabled={idVerificationReadOnlyField}
          source="attributes.firstName" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          label="Last Name"
          disabled={idVerificationReadOnlyField}
          source="attributes.lastName" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateInput
          label="Date of Birth"
          source="attributes.dateOfBirth"
          disabled={idVerificationReadOnlyField}
          validate={[
            validDate("A valid date is required"),
            pastDate("The date of birth must be in the past")
          ]}
          disableFuture
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <RadioButtonGroupInput
          validate={required()}
          label="Gender"
          source="attributes.gender"
          choices={genderChoices}
        />
      </Grid>
      {clientIdentifierRequired && (
        <Grid item xs={12} sm={6}>
          <TextInput
            validate={required()}
            label="Policy Number"
            source="users[0].attributes.clientIdentifier"
            disabled={isDependant}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <ClientEmployerInput
          clientId={clientId}
          source="attributes.employerId"
        />
      </Grid>
    </Group>
  );
}

export function PatientTitle(props: TextFieldProps & { label: string }) {
  const { emptyText, ...rest } = props;
  const record = useRecordContext(props);

  if (!record) return null;

  const { firstName, lastName } = record.attributes;

  return (
    <Labeled label={props.label}>
      <Typography
        component="span"
        variant="body1"
        {...sanitizeFieldRestProps(rest)}
      >
        {record ? `${firstName} ${lastName}` : emptyText}
      </Typography>
    </Labeled>
  );
}
