import { Button, Confirm, useRecordContext, useRefresh } from "react-admin";
import { useACL } from "ra-access-control-lists";
import { useDelete, useNotify } from "ra-core";
import React, { useState } from "react";


export function CancelShiftButton() {
  const resource = "shifts";
  const permission = "edit";
  const access = useACL(resource!);
  const canAccess = access[permission];
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();


  const [open, setOpen] = useState(false);

  const [cancel, { isLoading }] = useDelete(
    resource,
    { id: record && record.id },
    {
      onSuccess: () => {
        setOpen(false);
        notify("Shift was successfully cancelled");
        refresh();
      },
      onError: (error: Error) => {
        setOpen(false);
        notify(`An error occurred. ${error.message}`,
          {
            type: "error"
          }
        );
      }
    }
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  if (!canAccess) return null;


  return (
    <>
      <Button
        variant="outlined"
        color={"secondary"}
        size={"large"}
        onClick={handleClick}
        label={"Cancel shift"}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={"Cancel shift?"}
        content={"Are you sure you want to cancel this shift?"}
        onConfirm={() => cancel()}
        onClose={handleDialogClose}
      />
    </>
  );
}
