import { InputHelperText, TextInputProps } from "ra-ui-materialui";
import React, { useState } from "react";
import { useInput, useRecordContext, RaRecord } from "react-admin";
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";
import displayTooltipMessages from "../../utilities/displayTooltipMessages";

interface ValidationMessage {
  emptyMessage: string;
}

export function MarkdownInput(
  props: TextInputProps & {
    record?: RaRecord;
    validationMessages: ValidationMessage;
  }
) {
  const { source = "", disabled, validationMessages } = props;
  const record = useRecordContext(props);
  const { label, ...inputProps } = props;
  const [tab, setTab] = useState<"write" | "preview">("write");

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useInput({ ...inputProps, source });

  const message = displayTooltipMessages([
    [!value, validationMessages["emptyMessage"]],
    [Boolean(error), error?.message ?? ""],
  ]);

  if (!record) return null;

  return (
    <span aria-label={`${label}`} data-testid={props.source}>
      <ReactMde
        generateMarkdownPreview={markdown =>
          Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
        }
        readOnly={disabled}
        selectedTab={tab}
        value={value}
        onTabChange={setTab}
        onChange={onChange}
      />
      <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
        <InputHelperText touched error={message} />
      </span>
    </span>
  );
}
