import React from "react";
import qs from "query-string";
import { Labeled } from "react-admin";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { styled } from "@mui/material";
import { ClinicalPractitionerListQueryFilter, ClinicalPractitionerListQueryParameters } from "../../../clinicalPractitioner/ClinicalPractitionerList";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

type PractitionerNameSearchLinkProps = {
  label: string;
  firstName: string;
  lastName: string;
}

export function PractitionerNameSearchLink(props: PractitionerNameSearchLinkProps) {
  const filter = JSON.stringify({ 
    firstName: props.firstName,
    lastName: props.lastName,
  } as ClinicalPractitionerListQueryFilter)
  const practitionerListQueryParameters = {
      filter: filter,
  } as ClinicalPractitionerListQueryParameters;
  
  return (
    <Labeled label={props.label}>
      <StyledLink 
        aria-label="practitioner-search-link"  
        to={{
          pathname: "/clinicalPractitioners",
          search: qs.stringify(practitionerListQueryParameters),
        }}
      >
        <Typography variant="body1">
          {props.firstName} {props.lastName}
        </Typography>
      </StyledLink>
    </Labeled>
  );
}
