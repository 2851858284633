import { update } from "..";
import { NotificationSettingsDTO } from "../../types/notifications";
import deepEquals from "../../utilities/deepEquals";
import { createNotificationSettings } from "./createNotificationSettings";

type Args = {
  clientId: string;
  notificationSettings: NotificationSettingsDTO["data"];
  existingNotificationSettings: NotificationSettingsDTO["data"];
};

async function updateNotificationSettings(args: Args) {
  const { clientId, notificationSettings, existingNotificationSettings } = args;
  const baseClientEndpoint = `/ms/clients/${clientId}`;

  const noChange = deepEquals(
    notificationSettings,
    existingNotificationSettings
  );

  if (noChange) {
    return null;
  }

  try {
    const { id: notificationSettingId } = notificationSettings;

    if (!notificationSettingId) {
      const res = await createNotificationSettings({
        clientId,
        settings: notificationSettings.attributes,
      });

      return res;
    }

    const updateSettingEndpoint = `${baseClientEndpoint}/notificationSettings/${notificationSettingId}`;
    const res = update(updateSettingEndpoint, {
      data: notificationSettings,
    });

    return res;
  } catch (err) {
    console.error(err);
  }
}

export default updateNotificationSettings;
