import { GetManyParams } from "react-admin";
import { get } from "..";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

async function getManySendGridDynamicTemplates(params: GetManyParams) {
  const endpoint = "/ms/sendgridDynamicTemplate";
  const { ids = [] } = params;

  const queryParams = {
    "filter[id]": ids.length ? `in:${ids}` : "",
  };

  const query = shallowlyRemoveEmptyValues(queryParams);

  const { data } = await get(endpoint, query);

  return { data };
}

export default getManySendGridDynamicTemplates;
