import React from "react";
import { Heading, Level } from "../ui/Heading";
// eslint-disable-next-line no-restricted-imports
import { ArrayInput as RaArrayInput, ArrayInputProps } from "react-admin";

interface LabeledProps extends ArrayInputProps {
  level?: Level;
}

export const ArrayInput = (props: LabeledProps) => {
  const { label, level = 2, sx = {} } = props;
  return (
    <>
      {label ? (
        <Heading sx={{ margin: 0 }} level={level}>
          {label}
        </Heading>
      ) : null}
      <RaArrayInput
        {...props}
        label={false}
        sx={[
          { marginTop: 0, marginBottom: 2 },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      />
    </>
  );
};

ArrayInput.displayName = "ArrayInput";
