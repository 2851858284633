import React from "react";
import { useWatch } from "react-hook-form";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Heading } from "../../ui/Heading";
import nonNullable from "../../../utilities/nonNullable";
import { Typography } from "@mui/material";
import {
  PatientValidationDTO,
  PatientValidationField,
  PatientValidationIdentifiers,
} from "../../../types/validation";
import { CheckboxGroupInput } from "ra-ui-materialui";
import { patientValidationChoices } from "../../../constants/constants";

const MAX_ROW_COUNT = 100;

const checkboxGroupStyles = {
  width: "550px",
  display: "flex",
  "& label": {
    width: "250px",
    "& > span:first-of-type": {
      width: "50px",
    },
  },
};

function filterRows(rows: PatientValidationIdentifiers[]) {
  return rows.filter(row => {
    return Object.values(row).some(nonNullable);
  });
}

export function ListNewPatientIdentifiers() {
  const identifiers = useWatch({
    name: "newPatientIdentifiers",
  }) as PatientValidationDTO["data"];

  const { clientProperties, attributes } = identifiers;
  const specifiedColumns = clientProperties?.specifiedColumns ?? [];
  const { rows } = attributes;

  if (!rows.length) {
    return null;
  }

  const filteredRows = filterRows(rows);
  const slice = filteredRows.slice(0, MAX_ROW_COUNT);

  return (
    <div>
      <CheckboxGroupInput
        sx={checkboxGroupStyles}
        label="Patient columns to validate against"
        source="newPatientIdentifiers.clientProperties.specifiedColumns"
        choices={patientValidationChoices}
      />
      <Heading level={4}>Identifiers to be added</Heading>
      <Typography variant="body1">
        Showing {slice.length} of {filteredRows.length} rows
      </Typography>
      {filteredRows.length ? (
        <PatientIdenfitiersTable rows={slice} columns={specifiedColumns} />
      ) : (
        <Typography>
          You've uploaded a file, but no patient identifiers exist for the
          columns you've chosen. Upload a new file, or choose different columns
          to validate against.
        </Typography>
      )}
    </div>
  );
}

interface TableProps {
  columns: PatientValidationField[];
  rows: PatientValidationIdentifiers[];
}

function PatientIdenfitiersTable(props: TableProps) {
  const { columns, rows } = props;

  return (
    <TableContainer sx={{ marginBottom: "16px" }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(column => {
              return <TableCell key={column}>{column}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            // index is only used because there's no unique value guaranteed
            <TableRow key={i}>
              {columns.map(column => {
                return <TableCell key={column}>{row[column] ?? "-"}</TableCell>;
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
