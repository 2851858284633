import { Grid } from "@mui/material";
import React from "react";
import {
  BooleanInput,
  TextField,
  TextInput,
  required,
  useRecordContext,
  Labeled,
} from "react-admin";
import { NONE_SPECIFIED } from "../../../../constants/constants";
import { useEditStateContext } from "../../../../context/EditStateContext";
import { BooleanField } from "../../../fields/BooleanField";
import { Group } from "../../../layout/Group";
import { Heading } from "../../../ui/Heading";
import { Stack } from "../../../layout/Stack";

export function ContactForBooking() {
  const { currentState } = useEditStateContext();

  if (currentState === "show") return <ShowContent />;

  return <EditContent />;
}

function ShowContent() {
  return (
    <Group heading={<Heading level={2}>Contact for booking</Heading>}>
      <Grid xs={12} sm={6} item>
        <Stack>
          <Labeled label="Email">
            <TextField
              source="patients[0].attributes.email"
              emptyText={NONE_SPECIFIED}
            />
          </Labeled>
          <Labeled label="Phone number at time of appointment">
            <TextField
              source="attributes.contactDetails.phoneNumber"
              emptyText={NONE_SPECIFIED}
            />
          </Labeled>
          <Labeled label="Secondary phone number at time of appointment">
            <TextField
              source="attributes.contactDetails.secondaryPhoneNumber"
              emptyText={NONE_SPECIFIED}
            />
          </Labeled>
          <Labeled label="Phone accepts calls from withheld numbers">
            <BooleanField source="attributes.contactDetails.acceptHiddenCalls"/>
          </Labeled>
        </Stack>
      </Grid>
    </Group>
  );
}

function EditContent() {
  const record = useRecordContext();
  const { status } = record.attributes;
  const disabled = status !== "PendingConsultation";

  return (
    <Group heading={<Heading level={2}>Contact for booking</Heading>}>
      <Grid item xs={12} sm={6}>
        <Stack>
          <TextInput
            source="patients[0].attributes.email"
            label="Email"
            disabled={disabled}
          />
          <TextInput
            source="attributes.contactDetails.phoneNumber"
            label="Phone number at time of apt"
            validate={required()}
            disabled={disabled}
          />
          <TextInput
            source="attributes.contactDetails.secondaryPhoneNumber"
            label="Secondary phone number at time of apt"
            disabled={disabled}
          />
          <BooleanInput
            source="attributes.contactDetails.acceptHiddenCalls"
            label="Phone accepts calls from withheld numbers"
            disabled={disabled}
          />
        </Stack>
      </Grid>
    </Group>
  );
}
