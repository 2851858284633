import React from "react";
import {
  ArrayField,
  ChipFieldProps,
  Link,
  ReferenceArrayField,
  Show,
  SingleFieldList,
  TextField,
  Title,
  sanitizeFieldRestProps,
  useRecordContext
} from "react-admin";
import get from "lodash/get";
import { BooleanField } from "../fields/BooleanField";
import { ScrollableTabbedShowLayout } from "../ScrollableTabLayouts";
import { Tab } from "../Tabs";
import { Actions } from "../Actions";
import { FullNameChipField } from "../fields/FullNameChipField";
import { Chip } from "@mui/material";

function PageTitle() {
  const record = useRecordContext();
  const name = get(record, "attributes.name");

  return <Title title={name} />;
}

function ClientLink(props: ChipFieldProps) {
  const record = useRecordContext();
  const clientId = get(record, "attributes.clientId");
  const clientName = get(record, "clients[0].attributes.name");

  // The MUI Chip requires an onClick handler to behave like a clickable element.
  // eslint-disable-next-line lodash/prefer-noop, @typescript-eslint/no-empty-function
  const handleClick = () => {};

  return <Link to={`/clients/${clientId}/show/4`}>
    <Chip
      label={clientName}
      onClick={handleClick}
      component="span"
      variant="body1"
      {...sanitizeFieldRestProps(props)} />
  </Link>
}

export function ResourcePoolShow() {
  return (
    <Show
      actions={<Actions />}
      title={<PageTitle />}
    >
      <ScrollableTabbedShowLayout>
        <Tab label="General">
          <TextField label="Name" source="attributes.name" />
          <TextField
            label="Service"
            source="attributes.serviceName"
            sortable={false}
          />
          <BooleanField
            label="Is Enabled"
            source="attributes.isEnabled"
            emptyText="No"
          />
          <BooleanField
            label="Can be released"
            source="attributes.isGloballyAvailable"
            sortable={false}
          />
          <TextField
            label="Release duration"
            source="attributes.globallyAvailableDuration"
          />
          <ArrayField source="clientClinicianPools" label={"Clients"} sortable={false} >
            <SingleFieldList
              sx={{ margin: "5px 0px" }} linkType={false}>
                <ClientLink />
            </SingleFieldList>
          </ArrayField>
          <ReferenceArrayField
            label="Clinical Practitioners"
            reference="clinicalPractitioners"
            source="relationships.clinicalPractitioners.data">
            <SingleFieldList
              sx={{ margin: "5px 0px" }}>
              <FullNameChipField
                firstNameSource="attributes.firstName"
                lastNameSource="attributes.lastName"
                sx={{ marginRight: "5px" }} />
            </SingleFieldList>
          </ReferenceArrayField>
        </Tab>
      </ScrollableTabbedShowLayout>
    </Show>
  );
}
