import React from "react";
import { ShowTabProps } from "../../../types/utilities";
import { Tab } from "../../Tabs";
import { ConsultationOutputsGroup } from "./ConsultationOutputsGroup";
import { PatientAttachmentsGroup } from "./PatientAttachmentsGroup";

export function DocumentsTab(props: ShowTabProps) {
  return (
    <Tab label={"Documents"} {...props}>
      <PatientAttachmentsGroup />
      <ConsultationOutputsGroup />
    </Tab>
  );
}



