import { create, update, remove } from "..";
import { ClientCodeMappingsDTO } from "../../types/clients";
import deepEquals from "../../utilities/deepEquals";

interface Params {
  oldClientCodeMappings: ClientCodeMappingsDTO["data"];
  clientCodeMappings: ClientCodeMappingsDTO["data"];
}

async function updateClientCodeMapping(params: Params) {
  const { oldClientCodeMappings, clientCodeMappings } = params;

  const promises: Array<Promise<unknown>> = [];

  clientCodeMappings.forEach(currentMapping => {
    const { id, attributes } = currentMapping;
    const oldMapping = oldClientCodeMappings.find(
      mapping => mapping.id === currentMapping.id
    )!;

    if (deepEquals(oldMapping, currentMapping)) return;

    if (!id && !attributes.clientCodeId) {
      return;
    }

    if (!id) {
      const promise = create("client-codes/mappings", {
        data: {
          type: "client-code-mappings",
          attributes: currentMapping.attributes,
        },
      });

      promises.push(promise);
      return;
    }

    if (!attributes.clientCodeId) {
      const promise = remove(`client-codes/mappings/${id}`);
      promises.push(promise);
      return;
    }

    const promise = update(`client-codes/mappings/${id}`, {
      data: currentMapping,
    });

    promises.push(promise);
  });

  try {
    await Promise.all(promises);
  } catch (err) {
    console.error(err);
  }
}

export default updateClientCodeMapping;
