import React from "react";
import {
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  required,
  useRedirect,
  useNotify,
  SelectInput,
} from "react-admin";
import { useParams } from "react-router-dom";
import { resourceRoles } from "../../constants/constants";
import { EditActions } from "../Actions";
import { EmailInput } from "../inputs/EmailInput";
import { PageTitle } from "../PageTitle";
import { BasicToolbar } from "../Toolbar";

export function ResourceEdit() {
  const notify = useNotify();
  const redirect = useRedirect();
  const { id } = useParams();

  return (
    <Edit
      actions={<EditActions />}
      mutationOptions={{
        onSuccess: () => {
          notify("The changes to the user were saved successfully");
          redirect(`/resourceManagement/${id}/show`);
        },
      }}
      mutationMode="pessimistic"
      title={<PageTitle />}
      transform={record => {
        const isCsoUser = record.role === "cso";
        record.attributes.isCsoUser = isCsoUser;
        return record;
      }}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={<BasicToolbar resetPasswordLabel="Reset user's password" />}
      >
        <EmailInput source="attributes.email" validate={[required()]} />
        <TextInput
          source="attributes.userName"
          label="Username"
          validate={[required()]}
        />
        <TextInput
          source="attributes.firstName"
          label="First name"
          validate={required()}
        />
        <TextInput
          source="attributes.lastName"
          label="Last name"
          validate={required()}
        />
        <TextInput source="attributes.phoneNumber" label="Phone number" />
        <SelectInput
          source="role"
          label="Role"
          choices={resourceRoles}
          validate={required()}
        />
        <BooleanInput source="attributes.isEnabled" label="Is Enabled" />
      </SimpleForm>
    </Edit>
  );
}
