import { BookingStatus } from "../../../types/bookings";

const validStatuses: BookingStatus[] = [
  "Cancelled",
  "PendingFollowUpActions",
  "Completed",
  "ConsultationCompleted",
];

const getIsBookingActionDisabled = (status: BookingStatus) => {
  return validStatuses.includes(status);
};

export default getIsBookingActionDisabled;
