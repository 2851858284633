import { create, remove, update } from "..";
import lodashGet from "lodash/get";
import { MasterClientGroupDTO } from "../../types/clients";
import isError from "lodash/isError";

type Args = {
  clientId: string;
  oldMasterClientGroup: MasterClientGroupDTO["data"];
  masterClientGroup: MasterClientGroupDTO["data"];
  handleError: (message: string) => void;
};

async function updateMasterClient(args: Args) {
  const { clientId, oldMasterClientGroup, masterClientGroup, handleError } =
    args;

  const isMasterClientIdEmptyString =
    lodashGet(masterClientGroup, "clientGroups[0].id") === "";

  const masterClientId = isMasterClientIdEmptyString
    ? undefined
    : lodashGet(masterClientGroup, "clientGroups[0].id");

  const oldMasterClientId = lodashGet(
    oldMasterClientGroup,
    "clientGroups[0].id"
  );
  const masterClientGroupId = lodashGet(masterClientGroup, "id", "");
  const noChange = masterClientId === oldMasterClientId;

  if (noChange) {
    return null;
  }

  const masterClientEndpoint = `/ms/clientgroupsClient`;
  const updateMasterClientEndpoint = `/ms/clientgroupsClient/${masterClientGroupId}`;

  const masterClientBody = {
    data: {
      type: "clientGroupsClients",
      attributes: {},
      relationships: {
        client: {
          data: {
            type: "clients",
            id: clientId,
          },
        },
        clientGroup: {
          data: {
            type: "clientGroups",
            id: masterClientId,
          },
        },
      },
    },
  };

  const updateMasterClientBody = {
    ...masterClientBody,
    data: {
      ...masterClientBody.data,
      id: masterClientGroupId,
    },
  };

  try {
    if (!oldMasterClientId) {
      return await create(masterClientEndpoint, masterClientBody);
    }

    if (masterClientId === null && oldMasterClientId !== null) {
      return await remove(updateMasterClientEndpoint);
    }

    return await update(updateMasterClientEndpoint, updateMasterClientBody);
  } catch (error) {
    if (isError(error)) {
      handleError(error.message);
    }

    return {
      data: masterClientId,
    };
  }
}

export default updateMasterClient;
