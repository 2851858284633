import React from "react";
import { Button } from "react-admin";
import BackArrowIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation } from "react-router-dom";

const styles = {
  width: "fit-content",
  marginTop: "1.5rem",
};

export function BackButton() {
  const navigate = useNavigate();
  const location = useLocation();

  const isDashboard = location.pathname === "/";

  if (isDashboard) return null;

  return (
    <Button label="Back" onClick={() => navigate(-1)} sx={styles}>
      <BackArrowIcon />
    </Button>
  );
}
