import { Box, SxProps } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

interface Props {
  children: React.ReactNode;
  sx?: SxProps;
}

export function Stack(props: Props) {
  const { children, sx } = props;

  return <Root sx={sx}>{children}</Root>;
}

const Root = styled(Box)(
  () => `
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    margin-block-start: 0;
  }

  & > * + * {
    margin-block-start: 1rem;
  }`
);
