import React from "react";
import { Identifier, Labeled, useGetOne, useRecordContext } from "react-admin";
import { LinkButtonBase } from "../buttons/LinkButtonBase";
import { PatientRecordDTO } from "../../types/patients";
import { BookingDTO } from "../../types/bookings";
import { WorkQueueItemType } from "../../types/workQueues";
import { ConsultationDTO } from "../../types/consultations";
import get from "lodash/get";
import { Typography } from "@mui/material";

export type RecordType = {
  id: Identifier;
  bookings: BookingDTO["data"][];
  patients: PatientRecordDTO["data"][];
  consultations: ConsultationDTO["data"][];
  attributes: {
    itemType: WorkQueueItemType;
    status: string;
  };
};

export function MessageADoctorLink() {
  const record = useRecordContext();

  const threadId = get(record, "messageThreads[0].id");

  const { isLoading } = useGetOne("messageThreads", { id: threadId });

  if (isLoading) {
    return (
      <Labeled label="Booking ID">
        <Typography variant="body1">{threadId}</Typography>
      </Labeled>
    );
  }

  const url = `/messageThreads/${threadId}/show`;

  return (
    <Labeled label="Booking ID">
      <LinkButtonBase to={url} text={threadId} />
    </Labeled>
  );
}
