import { GetOneParams, GetOneResult } from "ra-core";
import { get } from "..";
import { ShiftDTO } from "../../types/shifts";

const includes = [
  "clinicalPractitioner",
  "events",
  "events.user",
  "proposedAppointments",
  "proposedAppointments.createdAppointment",
  "clinicalService"
];

async function getShift(args: GetOneParams): Promise<GetOneResult> {
  const { id } = args;

  const endpoint = `ms/resourceManagement/shifts/${id}`;

  const params = {
    include: includes.join(","),
  };

  const res = await get(endpoint, params);

  const data: ShiftDTO["data"] = Array.isArray(res.data)
    ? res.data[0]
    : res.data;

  const validProposedAppointments =
    data.shiftProposedAppointments?.filter(apt => {
      if (apt.attributes.isConflicting) return false;

      if (data.attributes.status === "Processed" && apt.attributes.isIgnored) {
        return false;
      }

      return true;
    }) ?? [];

  const updatedData = {
    ...data,
    validProposedAppointments,
  };

  return {
    data: updatedData,
  };
}

export default getShift;
