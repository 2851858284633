import { notificationTypeNames } from "../../../constants/constants";
import {
  BatchSendGridTemplateDTO,
  ChannelTypeDTO,
  NotificationType,
  Templates,
} from "../../../types/notifications";
import { notificationTypeEnum } from "../../../constants/constants";

const getExpandedNotificationTypeSettings = (
  notificationTypeSettings: ChannelTypeDTO["data"][],
  templates: Templates
) => {
  const getTemplateName = (notificationType: NotificationType) => {
    return templates[notificationType] ? templates[notificationType].name : "";
  };

  return notificationTypeSettings
    .map(item => {
      const { notificationType } = item.attributes;

      return {
        ...item,
        attributes: {
          ...item.attributes,
          notificationTypeName: notificationTypeNames[notificationType],
          templateName: getTemplateName(notificationType),
        },
      };
    })
    .filter(({ attributes }) => attributes.notificationTypeName);
};

function formatNotificationTypes(
  notificationTypeSettings: ChannelTypeDTO["data"][] = [],
  templateIds: BatchSendGridTemplateDTO["data"]
) {
  const { templates } = templateIds.attributes;

  const defaultNotificationTypeSettings: ChannelTypeDTO["data"][] =
    notificationTypeEnum.map(type => {
      return {
        attributes: {
          notificationType: type,
          isEnabled: false,
        },
        type: "notificationTypeSettings",
      };
    });

  if (notificationTypeSettings.length) {
    return getExpandedNotificationTypeSettings(
      notificationTypeSettings,
      templates
    );
  }
  return getExpandedNotificationTypeSettings(
    defaultNotificationTypeSettings,
    templates
  );
}

export default formatNotificationTypes;
