import React, { useState } from "react";
import {
  Datagrid,
  TextField,
  useRecordContext,
  ArrayField,
  SingleFieldList,
  ChipField,
  RaRecord,
  useList,
  ListContextProvider
} from "react-admin";
import { ShowTabProps } from "../../../types/utilities";
import { FormTab } from "../../form/CustomTabForm/FormTab";
import get from "lodash/get";
import { EmptyTab } from "../../layout/EmptyTab";
import { Box, Button } from "@mui/material";
import DateField from "../../fields/DateField";
import { CapsDelimitedField } from "../../fields/CapsDelimitedField";
import { getConfig } from "../../../config";
import { FunctionField } from "ra-ui-materialui";
import { useGetManyReference } from "ra-core";

export function OnlinePharmacyShowTab(props: ShowTabProps) {
  const record = useRecordContext(props);

  const config = getConfig();

  const clientId = get(record, "clients[0].id");

  const shouldDisplay = config.formFillPrescriptions.clientIds.includes(clientId.toString());

  return (
    shouldDisplay
      ? <FormTab
        {...props}
        label="Online Pharmacy"
        editable="hidden"
        disabled={false}
      >
        <Prescriptions />
      </FormTab>
      : null
  );
}

function Prescriptions() {
  const record = useRecordContext();
  const [continuationToken, setContinuationToken] = useState(null);
  const [prescriptions, setPrescriptions] = useState<any[]>([]);

  const { isLoading, pageInfo } = useGetManyReference(
    "prescriptions", {
      id: record.id,
      target: "patientRecordId",
      meta: { continuationToken }
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => {
        setPrescriptions([...prescriptions, ...data]);
      }
    }
  );

  const loadMoreItems = () => {
    // @ts-ignore
    setContinuationToken(pageInfo.continuationToken);
  };

  const listContext = useList({ data: prescriptions });

  const hasRecords = prescriptions.length > 0;
  if (!hasRecords) {
    return (
      <EmptyTab emptyText="There are no prescriptions associated with this patient" />
    );
  }

  return (
    <ListContextProvider value={listContext}>
      <Box sx={{ width: "100%" }}>
        <Datagrid
          id="prescriptions"
          bulkActionButtons={false}
          rowClick={(_id, _basePath, rec) => {
            return `/prescriptions/${rec.id}/show`;
          }}
        >
          <TextField label="ID" source="identifier" sortable={false} />
          <TextField label="Condition" source="condition" sortable={false} />
          <ArrayField label="Medication Requests" source="medicationRequests" sortable={false}>
            <SingleFieldList>
              <ChipField label={false} clickable={false} source="medication" />
            </SingleFieldList>
          </ArrayField>
          <CapsDelimitedField
            hideLabel
            label="Type"
            source="requestType"
            sortable={false}
          />
          <FunctionField
            label="Status"
            render={(rec: RaRecord) => {
              return rec.outcome ?? rec.status ?? "-";
            }}
            sortable={false}
          />
          <DateField
            showTime
            hideLabel
            label="Requested"
            source="dateRequested"
            sortable={false}
          />
          <DateField
            showTime
            hideLabel
            label="Deliver By"
            source="deliverBy"
            sortable={false}
          />
        </Datagrid>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          data-testid={"LoadMoreButton"}
          sx={{ marginTop: "10px" }}
          onClick={loadMoreItems}
          variant="outlined"
          size="large"
          // @ts-ignore
          disabled={isLoading || !pageInfo?.continuationToken}
        >
          Load more
        </Button>
      </Box>
    </ListContextProvider>
  );
}