import { GetManyParams } from "ra-core";

/* 
  This file is a big hack to satisfy how the ChoicesContext works

  Since we can't query the pharmacy endpoint by ID. we store the entire record as the ID and then return that value.
  We have to do this because it's how RA knows what the `selectedRecord` is, which we want to consume to display the full address.
*/
async function getManyGaiaPharmacies(params: GetManyParams) {
  const { ids = [] } = params;

  const pharmacy = JSON.parse(`${ids[0]}`);
  const { city, state, line } = pharmacy.address;
  const formattedName = `${pharmacy.name} (${line[0]}, ${city}, ${state})`;

  const updatedRecord = {
    ...pharmacy,
    id: ids[0],
    pharmacyId: pharmacy.id,
    formattedName,
  };

  if (!updatedRecord) {
    return { data: [] };
  }

  return {
    data: [updatedRecord],
  };
}

export default getManyGaiaPharmacies;
