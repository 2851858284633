import { GetListParams } from "ra-core";
import { list } from "..";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

async function getExpertise(params: GetListParams) {
  const expertiseEndpoint = "/ms/expertiseAreas";
  const { filter } = params;
  const { specialty, name } = filter;

  const queryParams = {
    "filter[specialty]": specialty,
    "filter[name]": name ? `like:${name}` : "",
  };

  const filteredParams = shallowlyRemoveEmptyValues(queryParams);

  const { data, total } = await list(expertiseEndpoint, {
    params: filteredParams,
  });

  return { data, total };
}

export default getExpertise;
