import { Identifier } from "ra-core";
import { get, list } from "..";
import { getClientCodeMappings } from "../client-codes";
import consolidatePMSData from "./helpers/consolidatePMSData";

interface Args {
  clientId: Identifier;
}

export async function getAssignedPMS(args: Args) {
  const { clientId } = args;

  const endpoint = `/ms/clients/${clientId}/patientManagementSystems`;
  const include = "patientManagementSystem";

  const clientPmsPromise = get(endpoint, { include });
  const codeMappingsPromise = getClientCodeMappings(args);

  const pmsEndpoint = `/ms/patientManagementSystems`;

  const [clientPmsResponse, codeMappingData] = await Promise.all([
    clientPmsPromise,
    codeMappingsPromise,
  ]);

  const { data } = await list(pmsEndpoint);

  const consolidatedPMSData = consolidatePMSData(
    clientPmsResponse.data,
    codeMappingData
  );

  const adastraPMS = data.filter(
    (pms: any) => pms.attributes.systemType === "Adastra"
  );
  const vnetPMS = data.filter(
    (pms: any) => pms.attributes.systemType === "vNET2"
  );

  const existingAdastraPms = consolidatedPMSData.filter(
    pms => pms.patientManagementSystems[0].attributes.systemType === "Adastra"
  );
  const existingVnetPms = consolidatedPMSData.filter(
    pms => pms.patientManagementSystems[0].attributes.systemType === "vNET2"
  );

  const adastraPMSDetails = getPms(existingAdastraPms);
  const vnetPMSDetails = getPms(existingVnetPms);

  const clientPatientManagementSystems = [];

  clientPatientManagementSystems.push({
    ...adastraPMSDetails,
    specialties: existingAdastraPms.map(pms => pms.attributes.specialty),
    pms: adastraPMS[0],
    mappings: existingAdastraPms,
    patientManagementSystemType: "Adastra",
  });

  clientPatientManagementSystems.push({
    ...vnetPMSDetails,
    specialties: existingVnetPms.map(pms => pms.attributes.specialty),
    pms: vnetPMS[0],
    mappings: existingVnetPms,
    patientManagementSystemType: "vNET2",
  });

  return clientPatientManagementSystems;
}

function getPms(patientManagementSystems: any) {
  const defaultValues = {
    isEnabled: false,
    isAutomaticCaseCreationEnabled: false,
  };

  if (patientManagementSystems.length === 0) return defaultValues;

  const pmsSettings = patientManagementSystems.filter(
    (p: any) => p.clientMapping?.attributes.clientCodeId
  );

  if (pmsSettings.length > 0) {
    // The settings will be the same in each array, so we can just take the first one
    const pms = pmsSettings[0];
    return {
      clientCodeId: pms.clientMapping?.attributes.clientCodeId,
      isEnabled: pms.attributes.isEnabled,
      isAutomaticCaseCreationEnabled:
        pms.attributes.isAutomaticCaseCreationEnabled,
      patientManagementSystemId: pms.id,
    };
  } else {
    return defaultValues;
  }
}

export default getAssignedPMS;
