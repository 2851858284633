import React from "react";
import { useRecordContext, Labeled } from "react-admin";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import get from "lodash/get";
import { styled } from "@mui/material";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

interface Props {
  label: string;
}
export function PatientLinkField(props: Props) {
  const { label } = props;
  const record = useRecordContext();
  const patientId = get(record, "patients[0].id");
  const clientId = get(record, "clients[0].id");
  const firstName = get(record, "patients[0].attributes.firstName");
  const lastName = get(record, "patients[0].attributes.lastName");

  return (
    <Labeled label={label}>
      <StyledLink aria-label="patient-link" to={`/patientRecords/${patientId}:${clientId}/general`}>
        <Typography variant="body1">
          {firstName} {lastName}
        </Typography>
      </StyledLink>
    </Labeled>
  );
}