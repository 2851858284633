import React from "react";
import { Labeled, TextField as TextFieldComponent } from "ra-ui-materialui";
import { ComponentMeta, ComponentStory } from "@storybook/react";

const Template: ComponentStory<typeof TextFieldComponent> = args => (
  <Labeled label={`${args.label}`}>
    <TextFieldComponent {...args} />
  </Labeled>
);

export const TextField = Template.bind({});

TextField.args = {
  label: "Input Label",
  source: "attributes.firstName",
};

export default {
  title: "EDS/Components/Data Display",
  argTypes: {
    label: {
      control: {
        type: "text",
      },
    },
  },
} as ComponentMeta<typeof TextFieldComponent>;
