import { Identifier } from "ra-core";
import { create, remove } from "..";
import { TermsDTO } from "../../types/clients";

interface Args {
  terms: TermsDTO["data"];
  oldTerms: TermsDTO["data"];
  clientId: Identifier;
}

async function updateClientTerms(args: Args) {
  const { terms, oldTerms, clientId } = args;
  const baseEndpoint = `/clients/${clientId}/terms`;
  const promises: Promise<any>[] = [];
  const oldIds = oldTerms.map(({ id }) => id);
  const newIds = terms.map(({ id }) => id);

  const termsToDelete = oldIds.filter(id => !newIds.includes(id));

  termsToDelete.forEach(id => {
    const promise = remove(`${baseEndpoint}/${id}`);
    promises.push(promise);
  });

  const termsToCreate = terms.filter(({ id }) => !id);

  termsToCreate.forEach(term => {
    const body = {
      data: {
        type: "terms",
        attributes: term.attributes,
      },
    };

    const promise = create(baseEndpoint, body);
    promises.push(promise);
  });

  return promises;
}

export default updateClientTerms;
