import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

interface Props {
  onClick: () => void;
}

const Root = styled("div")({
  position: "absolute",
  right: "16px",
  top: "8px",
});

export function DialogCloseButton(props: Props) {
  return (
    <Root>
      <IconButton
        size="small"
        color="primary"
        aria-label="close"
        onClick={props.onClick}
      >
        <CloseIcon />
      </IconButton>
    </Root>
  );
}
