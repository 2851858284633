import { Grid } from "@mui/material";
import { Box, BoxProps, styled } from "@mui/system";
import React from "react";
import { Colors } from "../../theme";

interface Props extends BoxProps {
  children: React.ReactNode;
  heading?: JSX.Element;
}

const PREFIX = "Group";

const classes = {
  grid: `${PREFIX}-grid`,
  heading: `${PREFIX}-heading`,
};

export function Group(props: Props) {
  const { children, heading, ...rest } = props;

  return (
    <Root {...rest}>
      {heading ? (
        <Grid item xs={12} className={classes.heading}>
          {heading}
        </Grid>
      ) : null}

      <Grid container className={classes.grid} rowGap="1rem">
        {children}
      </Grid>
    </Root>
  );
}

const Root = styled(Box)({
  "&": {
    padding: "36px 50px 20px",
    borderRadius: "20px",
    backgroundColor: Colors.White,
    marginRight: "auto",
    marginLeft: "auto",
    maxWidth: "900px",
    width: "100%",

    "& > *": {
      margin: 0,
    },
  },
  [`& .${classes.heading}`]: {
    width: "100%",
    "& > *": { marginTop: 0 },
  },
  [`& .${classes.grid}`]: {
    "& > .MuiGrid-root > div": {
      width: "100%",
    },
  },
});
