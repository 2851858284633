import dayjs from "dayjs";
import getDefaultDate from "../../utilities/getDefaultDate";
import {
  Datagrid,
  Filter,
  FormDataConsumer,
  List,
  RaRecord,
  required,
  SaveButton,
  Toolbar,
  useCreate,
  useCreateContext,
  useListContext,
  useNotify,
  useRedirect
} from "react-admin";
import {
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  ReferenceArrayInput,
  SimpleForm,
  TextField
} from "ra-ui-materialui";
import { Heading } from "../ui/Heading";
import { DateInput } from "../inputs/DateInput";
import { maxDate as validateMaxDate } from "../../validators/maxDate";
import { minDate as validateMinDate } from "../../validators/minDate";
import { ListWithoutFilterEmptyText, removeDefaultEmptyMessageStyle } from "../shared/ListHelpers";
import { FullNameField } from "../fields/FullNameField";
import DateField from "../fields/DateField";
import { Button } from "../buttons/Button";
import React from "react";


export function ShiftImportV2() {
  const startOfToday = dayjs(getDefaultDate()).format();
  const notify = useNotify();
  const redirect = useRedirect();
  const maxDate = dayjs().add(1, "year").format();

  return (
    <>
      <Create
        title="Import Shifts"
        resource="importShiftsStarted"
        transform={(record: RaRecord) => {
          const { start, shouldSyncAllCps } = record;

          record.end = dayjs(start).endOf("day").format();

          if (shouldSyncAllCps) {
            record.clinicalPractitionerIds = [];
          }

          return record;
        }}
        sx={{marginBottom: "20px"}}
        mutationOptions={{
          onSuccess: (record: any) => {
            if (!record.shifts.length) {
              notify(`No changes were made. The shifts of your chosen params are already up to date`);
              return;
            }

            redirect("/shifts/import/process", "importShiftsProcess", record.id, {}, { shifts: record.shifts });
          }
        }}
      >
        <SimpleForm
          warnWhenUnsavedChanges
          defaultValues={{
            shouldSyncAllCps: true
          }}
          toolbar={<ImportToolbar />}
        >
          <Heading level={2}>Import Shifts from Quinyx</Heading>
          <DateInput
            source="start"
            label="Date"
            validate={[
              required(),
              validateMaxDate(maxDate),
              validateMinDate(startOfToday)
            ]}
            maxDate={maxDate}
            minDate={startOfToday}
            defaultValue={startOfToday}
          />

          <ReferenceArrayInput
            source="unitGroupIds"
            validate={required(
              "Please choose the units you'd like to import shifts from"
            )}
            reference="quinyxUnits"
          >
            <AutocompleteArrayInput
              source="id"
              label="Units"
              optionText="attributes.name"
              optionValue="attributes.groupId"
            />
          </ReferenceArrayInput>
          <BooleanInput
            source="shouldSyncAllCps"
            label="Sync all Clinical Practitioners appointments"
            style={{ width: "max-content" }}
          />
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.shouldSyncAllCps) return null;
              return (
                <ReferenceArrayInput
                  source="clinicalPractitionerIds"
                  validate={required(
                    "Please choose the CPs whose shifts you'd like to import"
                  )}
                  reference="clinicalPractitioners"
                  filterToQuery={(query: string) => ({
                    fullName: query
                  })}
                >
                  <AutocompleteArrayInput
                    source="id"
                    label="Clinical Practitioner"
                    optionText="attributes.fullName"
                  />
                </ReferenceArrayInput>
              );
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Create>
      <List
        sx={removeDefaultEmptyMessageStyle}
        filter={{ status: "New,Processing", source: "Quinyx" }}
        filterDefaultValues={{ startDay: startOfToday }}
        sort={{ order: "DESC", field: "status" }}
        title={<></>}
        filters={
          <Filter>
            <DateInput
              label="Start"
              source="startDay"
              alwaysOn
              sx={{ marginBottom: "-14px" }}
            />
          </Filter>}
        resource={"shifts"}
        actions={false}
      >
        <>
          <Datagrid
            sx={{ marginTop: "40px" }}
            isRowSelectable={(record: any) => record.attributes.status === "New"}
            bulkActionButtons={<></>}
            empty={
              <ListWithoutFilterEmptyText
                text={"No new or processing shifts found on this date. Try to choose another day or import them using the form above."}
              />}
          >
            <FullNameField
              sortable={false}
              label="Assigned CP"
              emptyText="n/a"
              firstNameSource="clinicalPractitioners[0].attributes.firstName"
              lastNameSource="clinicalPractitioners[0].attributes.lastName"
            />
            <DateField
              sortBy="start"
              label="Start time"
              hideLabel
              showTime
              source="attributes.start"
            />
            <DateField
              sortable={false}
              hideLabel
              label="End time"
              showTime
              source="attributes.end"
            />
            <TextField
              sortable={false}
              label="Shift status"
              source="attributes.status"
            />
            <DateField
              sortable={false}
              hideLabel
              label="CreatedAt"
              showTime
              source="attributes.createdAt"
            />
          </Datagrid>
          <ProcessShiftImportButton />
        </>
      </List>
    </>
  );
}

function ProcessShiftImportButton() {
  const { selectedIds, isLoading, total } = useListContext();
  const [create, { isLoading: isUseCreateLoading }] = useCreate();
  const redirect = useRedirect();
  const notify = useNotify();

  const process = async () => {
    await create("importShiftsProcessed", { data: { shiftIds: selectedIds } });
    notify("Selected shift queued to process");
    redirect("/shifts");

  };

  if (!total) {
    return null;
  }

  return (<Button
    sx={{ marginTop: "20px" }}
    disabled={!selectedIds?.length || isLoading || isUseCreateLoading}
    onClick={process}
    aria-label={"Process import"}
    variant="outlined"
  >
    {"Process import"}
  </Button>);
}


function ImportToolbar() {
  const { saving } = useCreateContext();

  return (
    <Toolbar>
      <SaveButton
        disabled={saving}
        alwaysEnable={!saving}
        label="Import shifts"
      />
    </Toolbar>
  );
}
