import { FormTabProps } from "ra-ui-materialui";
import React from "react";
import { FormTabHeader } from "./FormTabHeader";
import { FormTabPanel } from "./FormTabPanel";

/**
 *
 * The FormTab component is only concerned with the layout within each tab.
 *
 * Confusingly, it can be rendered in two separate places, when generating the Tab headings, and when generating the actual TabPanel
 *
 */

export type EditableStates = "editable" | "noneditable" | "hidden";

export interface TabProps extends FormTabProps {
  selected?: boolean;
  editable?: EditableStates;
  disabled?: boolean;
  save?: (formData: any) => void;
  validate?: (values: any) => void;
  tooltipMessage?: string;
  Toolbar?: React.ReactNode;
}

export const FormTab = (props: TabProps) => {
  const {
    children,
    hidden,
    selected,
    intent,
    editable = "editable",
    disabled,
    label,
    save,
    validate,
    value,
    tooltipMessage,
    Toolbar,
  } = props;

  const renderHeader = () => (
    <FormTabHeader
      label={label}
      value={value}
      selected={selected}
      disabled={disabled}
      tooltipMessage={tooltipMessage}
    />
  );

  const renderContent = () => (
    <FormTabPanel
      Toolbar={Toolbar}
      hidden={hidden}
      editable={editable}
      value={value}
      save={save}
      validate={validate}
    >
      {children}
    </FormTabPanel>
  );
  return intent === "header" ? renderHeader() : renderContent();
};
