import { Labeled, TextInput } from "ra-ui-materialui";
import { Group } from "../../../layout/Group";
import React from "react";
import { Stack } from "../../../layout/Stack";


export function PmsManualIntakeEditing() {
  return (<Stack>
    <Group>
      <Labeled label={"Manual Intake Reference"}>
        <TextInput source={"attributes.clientAttributes.VNet.intakeReference"} label={"Intake Reference"} />
      </Labeled>
    </Group>
  </Stack>);
}