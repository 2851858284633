import { GetOneParams } from "ra-core";
import { get } from "..";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

async function getQuinyxResource(params: GetOneParams) {
  const quinyxResourcesEndpoint =
    "/ms/resourceManagement/quinyxClinicalPractitioners";

  const { id } = params;

  const queryParams = {
    "filter[id]": id,
    include: "clinicalPractitioner",
  };

  const filteredParams = shallowlyRemoveEmptyValues(queryParams);
  const { data } = await get(quinyxResourcesEndpoint, filteredParams);

  return {
    data: {
      ...data[0],
    },
  };
}

export default getQuinyxResource;
