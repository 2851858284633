import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useHealthmailAddressContext } from "../../../context/HealthmailAddressContext";
import { Button } from "../../buttons/Button";

export function FindAddressInputs() {
  const { requestName, requestAddress, requestCity } = useHealthmailAddressContext();
  const [nameFilter, setNameValue] = useState("");
  const [addressFilter, setAddressValue] = useState("");
  const [cityFilter, setCityValue] = useState("");

  const isNotFiltering = !nameFilter && !addressFilter && !cityFilter;

  const handleSearch = () => {
    if (isNotFiltering) {
      requestName(null);
      requestAddress(null);
      requestCity(null);
      return;
    }

    requestName(nameFilter ?? null)
    requestAddress(addressFilter ?? null);
    requestCity(cityFilter ?? null);
  };


  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <TextField
        sx={{ marginRight: "1em" }}
        InputProps={{
          placeholder: "Pharmacy name",
        }}
        name="find-pharmacy-name"
        variant="filled"
        margin="dense"
        label="Pharmacy name"
        onChange={e => setNameValue(e.target.value)}
        onKeyDown={e => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
        fullWidth
      />
      <TextField
        sx={{ marginRight: "1em" }}
        InputProps={{
          placeholder: "Pharmacy address",
        }}
        name="find-pharmacy-address"
        variant="filled"
        margin="dense"
        label="Pharmacy address"
        onChange={e => setAddressValue(e.target.value)}
        onKeyDown={e => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
        fullWidth
      />
      <TextField
        sx={{ marginRight: "1em" }}
        InputProps={{
          placeholder: "Pharmacy City",
        }}
        name="find-pharmacy-city"
        variant="filled"
        margin="dense"
        label="Pharmacy city"
        onChange={e => setCityValue(e.target.value)}
        onKeyDown={e => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
        fullWidth
      />
      <Button
        sx={{ marginTop: "8px", marginBottom: "4px", alignSelf: "flex-start" }}
        onClick={handleSearch}
        aria-label="Find pharmacy"
        variant="outlined"
        disabled={isNotFiltering}
      >
        Find Pharmacy
      </Button>
    </div>
  );
}

