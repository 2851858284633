import React from "react";
import { useEditStateContext } from "../../../../context/EditStateContext";
import { Group } from "../../../layout/Group";
import { Heading } from "../../../ui/Heading";
import {
  ConsultationOutputsEdit,
  ConsultationOutputsShow,
} from "./ConsultationOutputs";
import {
  ConsultationRecordingEdit,
  ConsultationRecordingShow,
} from "./ConsultationRecordings";
import {
  PatientAttachmentsEdit,
  PatientAttachmentsShow,
} from "./PatientAttachments";
import { Section } from "./AttachmentsSection";
import { Stack } from "../../../layout/Stack";

const wrapperStyles = {
  display: "flex",
  flexDirection: "column",
};
export function Documents() {
  const { currentState } = useEditStateContext();

  if (currentState === "show") return <ShowContent />;

  return <EditContent />;
}

function ShowContent() {
  return (
    <Group
      sx={wrapperStyles}
      heading={<Heading level={2}>Documents</Heading>}
    >
      <Stack>
        <Section>
          <PatientAttachmentsShow />
        </Section>
        <Section>
          <ConsultationOutputsShow />
        </Section>
        <Section>
          <ConsultationRecordingShow />
        </Section>
      </Stack>
    </Group>
  );
}

function EditContent() {
  return (
    <Group
      sx={wrapperStyles}
      heading={<Heading level={2}>Documents</Heading>}
    >
      <Stack>
        <Section>
          <PatientAttachmentsEdit />
        </Section>
        <Section>
          <ConsultationOutputsEdit />
        </Section>
        <Section>
          <ConsultationRecordingEdit />
        </Section>
      </Stack>
    </Group>
  );
}
