import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Labeled, useRecordContext } from "react-admin";
import { DownloadsField } from "../fields/DownloadsField";
import { PatientNotesDTO } from "../../types/patients";
import { BooleanField } from "../fields/BooleanField";
import { Grid } from "@mui/material";
import { innerGroupStyles } from "../../theme";

interface NotesField {
  id: string;
  notes: PatientNotesDTO["data"][];
}

const PREFIX = "PatientNotesField";

const classes = {
  list: `${PREFIX}-list`,
  item: `${PREFIX}-item`,
};

const Root = styled("ul")(({ theme }) => ({
  [`&.${classes.list}`]: {
    padding: 0,
    margin: 0,
    listStyle: "none",
    width: "100%",
  },

  [`& .${classes.item}`]: {
    padding: `${theme.spacing(1)} 0`,
  },
}));

export function PatientNotesField() {
  const record = useRecordContext<NotesField>();

  if (!record) return null;

  if (!record.notes || !record.notes.length) {
    return (
      <Labeled label="Notes">
        <Typography variant="body1">There are no notes to display</Typography>
      </Labeled>
    );
  }

  const downloadSources = {
    clientId: "clients[0].id",
    fileId: "id",
    fileName: "attributes.fileName",
    userId: "users[0].id",
  };

  return (
    <Root className={classes.list}>
      {record.notes.map((item, idx) => {
        return (
          <li key={item.attributes.note} className={classes.item}>
            <Grid container sx={innerGroupStyles}>
              <Grid item xs={12} sm={6}>
                <Labeled label="Note">
                  <Typography variant="body1">
                    {item.attributes.note}
                  </Typography>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label="Is note visible to the CP?">
                  <BooleanField
                    source={`notes[${idx}].attributes.isAvailableToClinicalPractitioner`}
                  />
                </Labeled>
              </Grid>
              {item.files ? (
                <DownloadsField
                  record={item}
                  fullWidth
                  label="Documents"
                  view="edit"
                  source="files"
                  downloadSources={downloadSources}
                  fields={[{ label: "", source: "attributes.fileName" }]}
                />
              ) : null}
            </Grid>
          </li>
        );
      })}
    </Root>
  );
}
