import React from "react";
import get from "lodash/get";
import { TextFieldProps, FunctionField, Labeled } from "react-admin";
import { splitByCapitalLetters } from "../../utilities/splitByCapitalLetters";

interface Props extends TextFieldProps {
  source: string;
  secondarySource?: string;
  hideLabel?: boolean;
  label: string;
}

export const CapsDelimitedField = (props: Props) => {
  const { label, source, secondarySource, hideLabel = false } = props;

  return (
    <Labeled label={hideLabel ? false : label}>
      <FunctionField
        render={(record: any) => {
          let value = splitByCapitalLetters(get(record, source));
          if (!value){
            value = splitByCapitalLetters(get(record, secondarySource ?? ''));
          }
          return value;
        }}
      />
    </Labeled>
  );
};
