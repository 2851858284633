import { styled } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControlLabel,
  Typography,
  Switch,
} from "@mui/material";

import React from "react";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { KeyboardKey } from "./KeyboardKey";
import { useShortcutsState } from "../context/useShortcutContext";

const StyledDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingBottom: 8,
  ["& p:first-child"]: {
    paddingRight: "8px",
    minWidth: theme.spacing(14),
  },
}));

function Shortcut(props: { label: string; char: string }) {
  return (
    <StyledDiv>
      <Typography variant="body1">{props.label}</Typography>
      <KeyboardKey char={props.char} />
    </StyledDiv>
  );
}

export function ShortcutModal() {
  const [isOpen, setOpenState] = useState(false);

  const [areShortcutsEnabled, setShortcutsEnabledState] = useShortcutsState();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setShortcutsEnabledState(checked);
  };

  const handleOpen = () => setOpenState(true);
  const handleClose = () => setOpenState(false);

  useHotkeys("shift+k", handleOpen);

  if (!isOpen) return null;

  return (
    <Dialog onClose={handleClose} open={isOpen} maxWidth="xs" fullWidth>
      <DialogTitle>Keyboard shortcuts</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Switch
              inputProps={{
                id: "keyboard-shortcuts-toggle",
              }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              defaultChecked={areShortcutsEnabled}
              onChange={onChange}
            />
          }
          label="Keyboard shortcuts are enabled"
        />

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h2">Navigation</Typography>
            <Shortcut label="Home" char="d" />
            <Shortcut label="Patients" char="p" />
            <Shortcut label="Schedule" char="s" />
            <Shortcut label="Task Monitor" char="t" />
            <Shortcut label="CPs" char="g" />
            <Shortcut label="Appointments" char="a" />
            <Shortcut label="Shifts" char="h" />
            <Shortcut label="Users" char="u" />
            <Shortcut label="Clients Contracts" char="c" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h2">Actions</Typography>
            <Shortcut label="Show/Hide Nav" char="/" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" aria-label="Confirm" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
