import React from "react";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { Button, useListContext } from "react-admin";

interface Props {
  defaultFilterValues: Record<string, unknown>;
}

export function ResetListFilterButton(props: Props) {
  const { defaultFilterValues } = props;

  const { setFilters } = useListContext();
  const resetFilters = () => setFilters(defaultFilterValues, {});

  return (
    <Button
      onClick={resetFilters}
      variant="outlined"
      sx={{ margin: "0 8px" }}
      size="medium"
      label="Reset Filters"
    >
      <ClearAllIcon />
    </Button>
  );
}
