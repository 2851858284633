import { TextField, Labeled } from "react-admin";
import React from "react";
import DateField from "../fields/DateField";
import { Stack } from "../layout/Stack";
import { MarkdownField } from "../fields/MarkdownField";

export function ShowTermDetails() {
  return (
    <Stack>
      <Labeled label="Term ID">
        <TextField source="id" sortable={false} />
      </Labeled>
      <Labeled label="Title">
        <TextField source="attributes.title" sortable={false} />
      </Labeled>
      <Labeled label="Version">
        <TextField source="attributes.version" sortable={false} />
      </Labeled>
      <Labeled label="Type">
        <TextField source="attributes.type" sortable={false} />
      </Labeled>
      <DateField
        source="attributes.activeFrom"
        label="Active from"
        sortable={false}
      />
      <Labeled label="Body">
        <MarkdownField source="attributes.body" />
      </Labeled>
    </Stack>
  );
}
