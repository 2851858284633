import React from "react";
import { useCreate, useNotify, useRefresh } from "ra-core";
import { ButtonProps } from "ra-ui-materialui";
import { Tooltip } from "@mui/material";
import { Button } from "../buttons/Button";

const PROCESSED_MESSAGE =
  "You can't change appointments that have already been processed";

const IGNORE_MESSAGE =
  "Ignoring an appointment will ensure it won't be created";

interface Props extends ButtonProps {
  actionKey: "ignoreAppointments" | "workingAppointments";
  shouldAdd: boolean;
  appointmentId: string;
  icon: JSX.Element;
}

export function ApplyOperationButtonBase(props: Props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const {
    actionKey,
    disabled,
    id = "",
    icon,
    label,
    appointmentId,
    shouldAdd,
  } = props;

  const [apply, { isLoading, error }] = useCreate(
    "applyShiftOperation",

    {
      data: {
        id,
        [actionKey]: { [appointmentId]: shouldAdd },
      },
    },

    {
      onSuccess: () => {
        refresh();
        notify("The changes have been applied to your proposed appointments", {
          type: "success",
        });
      },
      onError: () => {
        notify((error as Error).message);
      },
    }
  );

  const tooltipMessage = disabled ? PROCESSED_MESSAGE : IGNORE_MESSAGE;

  return (
    <Tooltip title={tooltipMessage}>
      <span>
        <Button
          disabled={isLoading || disabled}
          startIcon={icon}
          onClick={() => apply()}
          aria-label={label}
          variant="outlined"
        >
          {label}
        </Button>
      </span>
    </Tooltip>
  );
}
