import { DeleteManyParams } from "react-admin";
import deleteEmployer from "./deleteEmployer";

async function deleteManyEmployers(params: DeleteManyParams) {
  const { ids } = params;

  const promises = ids.map(id => {
    return deleteEmployer({ id });
  });

  await Promise.all(promises);

  return {
    data: ids,
  };
}

export default deleteManyEmployers;
