import { styled } from "@mui/material";
import get from "lodash/get";
import { useRecordContext } from "ra-core";
import React from "react";
import { FormDataConsumer, Labeled } from "react-admin";
import { invalidFileExtensions } from "../../../../constants/constants";
import { DownloadsFieldV2 } from "../../../fields/DownloadsFieldV2";
import { EmptyLabeledText } from "../../../fields/EmptyLabelledTextField";
import { FileImageField } from "../../../fields/FileImageField";
import { SimpleFormIterator } from "../../../form/SimpleFormIterator";
import { ArrayInput } from "../../../inputs/ArrayInput";
import { FileInput } from "../../../inputs/FileInput";

const StyledSimpleFormIterator = styled(SimpleFormIterator)(({ theme }) => ({
  "& > li": {
    margin: `${theme.spacing(1)} 0`,
    borderBottom: "none",
    "& > section + span:last-of-type": {
      display: "flex",
      alignItems: "center",
      paddingTop: "0",
    },
  },
}));

const arrayInputStyles = {
  marginBottom: 0,
  "& .RaSimpleFormIterator-line": {
    my: 1,
  },
  "& .RaSimpleFormIterator-form": {
    "& + .RaSimpleFormIterator-action": {
      ml: 1,
    },
  },
};

export function PatientAttachmentsEdit() {
  const record = useRecordContext();
  const status = get(record, "attributes.status");
  const isAttachmentsDisabled = status !== "PendingConsultation";
  const consultationStatus = get(record, "consultations[0].attributes.status");
  const consultationIsPending = consultationStatus === "Pending";

  return (
    <>
      <ExistingAttachments />
      <FormDataConsumer>
        {({ formData }) => {
          if (
            formData.attributes.status === "Completed" ||
            !consultationIsPending
          )
            return null;
          return (
            <FileInput
              source="newAttachments"
              exclude={invalidFileExtensions}
              label=""
              disabled={isAttachmentsDisabled}
              multiple
            >
              <FileImageField />
            </FileInput>
          );
        }}
      </FormDataConsumer>
    </>
  );
}

export function PatientAttachmentsShow() {
  const downloadSources = {
    clientId: "users[0].attributes.clientId",
    fileId: "relationships.file.data.id",
    userId: "users[0].id",
    fileName: "attributes.description",
  };

  return (
    <FormDataConsumer>
      {({ formData }) => {
        if (!formData) return;

        const attachments = get(formData, "attachments");
        if (!attachments) {
          return (
            <EmptyLabeledText
              label="Patient Attachments"
              text="No Attachments"
            />
          );
        }
        return (
          <Labeled label="Patient Attachments">
            <DownloadsFieldV2
              label={"Patient Attachments"}
              source={"attachments"}
              downloadSources={downloadSources}
              nameSource={"attributes.description"}
              titleValue={"PatientAttachment"}
            />
          </Labeled>
        );
      }}
    </FormDataConsumer>
  );
}

function ExistingAttachments() {
  const record = useRecordContext();

  const attachments = get(record, "attachments");
  const status = get(record, "attributes.status");

  const isAttachmentsDisabled = status !== "PendingConsultation";

  if (!attachments) {
    return (
      <EmptyLabeledText label="Patient Attachments" text="No Attachments" />
    );
  }

  return (
    <Labeled label="Patient Attachments">
      <ArrayInput
        source="attachments"
        sx={arrayInputStyles}
        className="nathan Britten"
      >
        <StyledSimpleFormIterator
          disableAdd
          disableRemove={isAttachmentsDisabled}
        >
          <FormDataConsumer>
            {({ getSource }) => {
              if (!getSource) return null;
              return (
                <FileImageField
                  key={getSource("attributes.description")}
                  titleSource={getSource("attributes.description")}
                  label="Attachment"
                />
              );
            }}
          </FormDataConsumer>
        </StyledSimpleFormIterator>
      </ArrayInput>
    </Labeled>
  );
}
