import { DeleteParams } from "react-admin";
import { getBaseUrl, remove } from "../";

async function deleteBooking(params: DeleteParams) {
  const { previousData, meta } = params;
  if (!previousData || !meta) return;
  const { bookingId, users, clients } = previousData;

  const clientId = clients[0].id;
  const userId = users[0].id;

  const baseUrl = getBaseUrl({ apiVersion: "2" });
  const deleteBookingEndpoint = `${baseUrl}/clients/${clientId}/users/${userId}/bookings/${bookingId}`;

  const deleteBookingBody = {
    data: {
      id: bookingId,
      type: "bookingCancellations",
      attributes: {
        cancellationReason: meta.reason,
        cancellationReasonNotes: meta.reasonNotes,
        isCancelledByPatient: meta.isCancelledByPatient,
      },
    },
  };
  const booking = await remove(deleteBookingEndpoint, {
    data: deleteBookingBody,
  });

  return booking;
}

export default deleteBooking;
