// 000s
export const UNCATEGORISED_ERROR = "000_uncategorised";
export const UNCATEGORISED_CLIENT_ERROR = "000_uncategorised_client";
export const UNCATEGORISED_SERVER_ERROR = "000_uncategorised_server";

// 400s
export const E_400 = "400";
export const VALIDATION = "400_validation";
export const INVALID_FILTER = "400_invalid_filter";
export const INVALID_CLIENT_CODE = "400_invalid_client_code";
export const EMAIL_NOT_VERIFIED = "400_email_not_verified";
export const INVALID_USERNAME_OR_PASSWORD = "400_invalid_username_or_password";
export const APPOINTMENT_NOT_ON_HOLD = "400_appointment_not_on_hold";
export const INVALID_CLIENT_IDENTIFIER = "400_invalid_client_identifier";
export const CLIENT_VALIDATION_FAILED = "400_client_validation_failed";
export const MFA_REQUIRED = "400_mfa_required";
export const INVALID_REQUEST_FILTER = "400_invalid_request_filter";
export const INVALID_APPOINTMENT_SWAP = "400_invalid_appointment_swap";
export const PMS_NOT_SUPPORTED = "400_pms_not_supported";
export const INVALID_MESSAGE_DOCTOR_CONVERSION =
  "400_invalid_message_doctor_conversion";

// 403s
export const E_403 = "403";
export const NOT_ON_HOLD = "403_not_on_hold";
export const ON_HOLD_BY_OTHER_USER = "403_on_hold_by_other_user";
export const INVALID_APPOINTMENT_STATUS = "403_invalid_appointment_status";
export const APPOINTMENT_HOLD_NOT_COMPLETED =
  "403_appointment_hold_not_completed";

// 404s
export const E_404 = "404";
export const APPOINTMENT = "404_appointment";
export const APPOINTMENT_HOLD = "404_appointment_hold";
export const BOOKING = "404_booking";
export const USER = "404_user";
export const CLINICAL_PRACTITIONER = "404_clinical_practitioner";
export const CONSULTATION = "404_consultation";
export const PATIENT_RECORD = "404_patient_record";
export const DEPENDANT = "404_dependant";
export const REST_ENDPOINT = "404_rest_endpoint";
export const NO_MATCHING_APPOINTMENTS = "404_no_matching_appointments";
export const CLIENT_APPOINTMENT_FILTER = "404_client_appointment_filter";

// 409s
export const E_409 = "409";

// 412s
export const E_412 = "412";
export const OPTIMISTIC_LOCK = "412_optimistic_lock";
export const INVALID_STATE = "412_invalid_state";
export const PATIENT_RECORD_REQUIRED = "412_patient_record_required";

// 429s
export const E_429 = "429";

// Server error
export const E_500 = "500";
export const CLINICAL_MANAGEMENT_ADD_CASE = "500_clinical_management_add_case";
export const CLINICAL_MANAGEMENT_UPDATE_CASE =
  "500_clinical_management_update_case";
export const CLINICAL_COMMUNICATION_FIELD = "500_pms_communication_failed";

const defaultErrorMessages: Record<string, string> = {
  // 000s
  [UNCATEGORISED_ERROR]: "Something went wrong when making your request",
  [UNCATEGORISED_CLIENT_ERROR]: "Something went wrong when making your request",
  [UNCATEGORISED_SERVER_ERROR]:
    "It looks like something's wrong with our servers. Please try again in a little bit, or get in touch with our tech team",

  // 400s
  [E_400]: "We had trouble processing your request",
  [VALIDATION]:
    "We had trouble processing your request. The following fields are not valid:",
  [INVALID_REQUEST_FILTER]:
    "We had trouble filtering the data, please make sure all your filter values are valid",
  [INVALID_FILTER]:
    "We had trouble filtering the data, please make sure all your filter values are valid",
  [INVALID_CLIENT_CODE]:
    "We had trouble processing the client code. Please make sure it's correct",
  [INVALID_CLIENT_IDENTIFIER]:
    "We had trouble processing the client identifier. Please make sure it's correct",
  [EMAIL_NOT_VERIFIED]:
    "The email you're trying to log in with has not been verified. Please verify using the code sent to your inbox",
  [INVALID_USERNAME_OR_PASSWORD]:
    "The username and password you have entered is invalid. Please provide the correct details.",
  [CLIENT_VALIDATION_FAILED]:
    "We couldn't complete your request. Due to the client's validation rules the patient isn't permitted to access to the services. Make sure the details are correct",
  [APPOINTMENT_NOT_ON_HOLD]: "The chosen appointment is not on hold",
  [MFA_REQUIRED]:
    "You will need to perform Multi-Factor Authentication to proceed",
  [INVALID_APPOINTMENT_SWAP]:
    "We were not able to swap those appointments. Please make sure that the booking hasn't already started or been cancelled",
  [INVALID_MESSAGE_DOCTOR_CONVERSION]:
    "It looks like something went wrong when converting doctors",
  [PMS_NOT_SUPPORTED]: "We do not support your chosen PMS",

  // 403s
  [E_403]:
    "You do not have permission to access this resource. Please make sure you're logged in and have the correct permissions.",
  [NOT_ON_HOLD]: "The chosen appointment is not on hold",
  [ON_HOLD_BY_OTHER_USER]: "The chosen appointment is on hold by another user",
  [INVALID_APPOINTMENT_STATUS]: "The chosen appointment has an invalid status",
  [APPOINTMENT_HOLD_NOT_COMPLETED]:
    "We were unable to successfully hold the appointment",

  // 404s
  [E_404]: "We were not able to find the resource you requested",

  [APPOINTMENT]: "We were not able to find your requested appointment",

  [NO_MATCHING_APPOINTMENTS]:
    "We were not able to find your requested appointment",

  [APPOINTMENT_HOLD]: "We were not able to find your request appointment hold",

  [BOOKING]: "We were not able to find your requested booking",

  [USER]: "We were not able to find your requested user",

  [CLINICAL_PRACTITIONER]:
    "We were not able to find your requested clinical practitioner",

  [CONSULTATION]: "We were not able to find your requested consultation",

  [PATIENT_RECORD]: "We were not able to find your requested patient",

  [DEPENDANT]: "We were not able to find your requested dependant",

  [REST_ENDPOINT]: "We were not able to find your requested custom endpoints",

  [CLIENT_APPOINTMENT_FILTER]:
    "We were not able to find your requested appointment filters",

  // Conflict
  [E_409]:
    "We ran into a conflict",

  // Precondition failed
  [E_412]:
    "We were not able to make your changes. Please get in touch with the tech team if this issue persists",

  [OPTIMISTIC_LOCK]:
    "We were not able to make your changes. The record may be being updated by elsewhere right now. Please get in touch with the tech team if this issue persists.",

  [INVALID_STATE]:
    "We are unable to make your changes to the resources. This may be because it's in a state where it can no longer be modified. Please get in touch with the tech team if this issue continues",

  [PATIENT_RECORD_REQUIRED]:
    "We ran into a problem. A patient record is required to complete your request.",

  // Too many request
  [E_429]:
    "This request has been made too many times, and has hit our rate limit. Please try again in a few minutes.",

  // Server error
  [E_500]:
    "It looks like something's wrong with our servers. Please try again in a little bit, or get in touch with our tech team",
  [CLINICAL_MANAGEMENT_ADD_CASE]:
    "It looks like something's wrong with our servers. Please try again in a little bit, or get in touch with our tech team",
  [CLINICAL_MANAGEMENT_UPDATE_CASE]:
    "It looks like something's wrong with our servers. Please try again in a little bit, or get in touch with our tech team",
  [CLINICAL_COMMUNICATION_FIELD]:
    "It looks like something's wrong with our servers. Please try again in a little bit, or get in touch with our tech team",
};

export function getErrorMessageOrDefault(
  code: string | number,
  defaultMessage?: string
) {
  return (
    defaultErrorMessages[code] ??
    defaultMessage ??
    defaultErrorMessages[UNCATEGORISED_ERROR]
  );
}
