import { GetManyParams } from "react-admin";
import getManyClinicalServices from "../clinicalServices/getManyClinicalServices";
import { specialtyChoices } from "../../constants/constants";


async function getManyClinicalServiceWithSpeciality(params: GetManyParams) {
  const { ids } = params;

  // @ts-ignore
  const clinicalServicesIds = ids.filter(x => !isNaN(x));
  const clinicalServices = await getManyClinicalServices({ids: clinicalServicesIds});

  return {
    data: [
      ...clinicalServices.data.map((x: any) => ({
        id: x.id,
        name: x.attributes.name,
        deliveryType: x.attributes.deliveryType
      })),
      ...specialtyChoices.filter(x => ids.includes(x.id))
    ]
  };
}


export default getManyClinicalServiceWithSpeciality;
