import { Box, CircularProgress, TextField } from "@mui/material";
import React, { useRef } from "react";
import { useGooglePlaceAddressContext } from "../../../context/GooglePlaceAddressContext";
import { Button } from "../../buttons/Button";

const textFieldStyles = {
  marginRight: "1em",
  flex: 1,
};

const buttonStyles = {
  marginTop: "8px",
  marginBottom: "4px",
  alignSelf: "flex-end",
};

export function FindAddress() {
  const { requestAddresses, type, loading } = useGooglePlaceAddressContext();
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    const input = ref.current?.querySelector("input");
    const value = input?.value;

    if (!value) {
      return requestAddresses(null);
    }

    return requestAddresses(value);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <TextField
        sx={textFieldStyles}
        InputProps={{
          ref,
          placeholder: "E.g. NR14 7PZ",
        }}
        name={`find${type.replace(" ", "")}`}
        variant="filled"
        margin="dense"
        label="Enter a postcode"
        onKeyDown={e => {
          if (e.key === "Enter") {
            handleClick();
          }
        }}
      />
      <Button
        sx={buttonStyles}
        onClick={handleClick}
        aria-label={`Find ${type} Address`}
        variant="outlined"
      >
        Find address
      </Button>
      {loading ? (
        <CircularProgress
          sx={{ marginLeft: 2, alignSelf: "center" }}
          size={30}
        />
      ) : null}
    </Box>
  );
}
