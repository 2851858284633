import React from "react";
import { useCreate, useNotify, useRecordContext } from "ra-core";
import { TextFieldProps } from "ra-ui-materialui";
import { Colors } from "../../../../theme";
import get from "lodash/get";
import { styled, Typography } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { splitByCapitalLetters } from "../../../../utilities/splitByCapitalLetters";


interface Props extends TextFieldProps {
  titleSource: string;
  nameSource: string;
  resourceTypeSource: string;
  consultationId: string;
}

const StyledDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  background: Colors.LighterGrey,
  width: "280px",
  padding: "6px",
  borderRadius: "10px",
  boxSizing: "border-box",
  paddingRight: theme.spacing(1),
  '&:hover': {
    background: Colors.LighterBlue,
    color: Colors.BrightBlue,
    cursor: "pointer"
  },
  "svg": {
    display: "none",
  },
  '&:hover svg': {
    display: "block"
  }
}));

const WrapperStyledDiv = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "260px"
}));


const typeTextStyles = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  marginLeft: "8px",
  fontSize: "10px",
  lineHeight: "1.5"
};

const titleTextStyles = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  marginLeft: "8px",
  fontSize: "14px",
  width: "220px",
  lineHeight: "1.5"
}
export function ConsultationOutputField(props: Props) {
  const record = useRecordContext(props);
  const title = get(record, props.titleSource, "");
  const name = get(record, props.nameSource, "")
  const resourceType = get(record, props.resourceTypeSource, "File");
  const [downloadUnencryptedOutput] = useCreate();
  const { consultationId } = props;
  const notify = useNotify();

  const goToLink = () => {
    window.open(record.attributes.link);
  };

  const download = () =>
    downloadUnencryptedOutput(
      "downloadUnencryptedOutput",
      {
        data: {
          consultationId,
          outputId: record.id,
          fileName: record.attributes.description,
        },
      },
      {
        onError: () => {
          notify("We had trouble downloading your chosen output");
        },
      }
    );

  return (
    <StyledDiv aria-label={"Get consultation output"} onClick={resourceType === "Link" ? goToLink : download}>
      <WrapperStyledDiv>
        <Typography sx={typeTextStyles} variant="body1">
          {splitByCapitalLetters(title)}
        </Typography>
        <Typography sx={titleTextStyles} variant="body1">
          {name}
        </Typography>

      </WrapperStyledDiv>
      {resourceType === "Link" ? <OpenInNewIcon  /> : <DownloadIcon />}
    </StyledDiv>
  );
}

ConsultationOutputField.defaultProps = {
  titleSource: "title",
  imgSource: "url",
  source: "url",
  title: "title",
  resourceTypeSource: "File",
};
