import { create } from "./";

interface Params {
  attachments: {
    rawFile: File;
  }[];
  clientId: string;
  userId: string;
}

interface FileData {
  type: string;
  id: string;
}

async function uploadFiles(params: Params) {
  const { attachments, clientId, userId } = params;

  const fileEndpoint = `/clients/${clientId}/users/${userId}/fileUpload`;

  const filesData: FileData[] = [];

  if (!attachments || !attachments.length) return filesData;

  for await (const attachment of attachments) {
    const file = attachment.rawFile;
    const formData = new FormData();

    formData.append("file", file);
    formData.append("filename", file.name);

    const fileRes = await create(fileEndpoint, formData);

    const fileData = {
      id: fileRes.data.id,
      type: fileRes.data.type,
    };

    filesData.push(fileData);
  }

  return filesData;
}

export default uploadFiles;
