import React from "react";
import { BookingGroup } from "./BookingGroup";
import { ClinicalPractitionerGroup } from "./ClinicalPractitionerGroup";

export function AllocationRequiredFields() {
  return (
    <>
      <BookingGroup />
      <ClinicalPractitionerGroup />
    </>
  );
}
