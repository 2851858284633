import isObject from "lodash/isObject";
import isEmpty from "lodash/isEmpty";
import isBoolean from "lodash/isBoolean";

const recursiveIsObjectEmpty = (obj?: Record<string, any> | null): boolean => {
  if (!obj) return true;

  return Object.values(obj).every(el => {
    if (isObjectGuard(el)) {
      return recursiveIsObjectEmpty(el);
    }

    if (isBoolean(el)) return false;

    return isEmpty(el);
  });
};

function isObjectGuard(val: any): val is Record<string, unknown> {
  return isObject(val);
}

export default recursiveIsObjectEmpty;
