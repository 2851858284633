import dayjs from "dayjs";
import { NextAvailableAppointmentType } from ".";

type ConsolidatedTime = Record<string, number>;

interface FormattedAppointments {
  [key: string]: {
    [key: string]: number;
  };
}

// We add the consultation type to the key to keep video/phone appointments separate,
// even when the start time is the same

export const consolidateAppointmentTimes = (
  appointments: NextAvailableAppointmentType
): ConsolidatedTime => {
  const consolidatedTimes: ConsolidatedTime = {};

  if (!appointments) return {};

  appointments.forEach(({ start, consultationType }) => {
    const formattedStart = dayjs(start).format("YYYY-MM-DDTHH:mm");
    const key = `${formattedStart}_${consultationType}`;
    const currentEntry = consolidatedTimes[key] ?? 0;

    consolidatedTimes[key] = currentEntry + 1;
  });

  return consolidatedTimes;
};

export const splitAppointmentsByDate = (appointments: ConsolidatedTime) => {
  const consolidatedDates: FormattedAppointments = {};

  const entries = Object.entries(appointments);

  entries.forEach(([key, count]) => {
    const [fullTime, consultationType] = key.split("_");
    const date = dayjs(fullTime).format("YYYY-MM-DD");
    const time = dayjs(fullTime).format("HH:mma");

    if (!consolidatedDates[date]) {
      consolidatedDates[date] = {};
    }
    const newKey = `${time}_${consultationType}`;

    consolidatedDates[date][newKey] = count;
  });

  return consolidatedDates;
};
