import React from "react";
import qs from "query-string";
import { List, Datagrid, TextField } from "react-admin";
import { PatientFilter } from "./PatientFilter";
import { PatientListActions } from "./PatientActions";
import useEffectOnce from "../../hooks/useEffectOnce";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import { NextAvailableAppointmentField } from "../fields/NextAvailableAppointmentField";
import { PatientViewButton } from "./PatientViewButton";
import { CreateBookingButton } from "../buttons/CreateBookingButton";
import { trackEvent } from "../../utilities/trackEvent";
import { useLocation } from "react-router-dom";
import { ListWithoutFilterEmptyText, removeDefaultEmptyMessageStyle } from "../shared/ListHelpers";

export type PatientListQueryFilter = {
  fakeFilter: string;
  dateOfBirth: string;
  firstName: string;
  lastName: string;
}

export type PatientListQueryParameters = {
  displayedFilters: string;
  filter: string;
  order: string;
  page: number;
  perPage: number;
  sort: string;
  persistFilters: boolean;
}

function Wrapper(props: any) {
  return (
    <>
      <NextAvailableAppointmentField />
      <ListWithoutFilterEmptyText />
      <Card className={props.className}>{props.children}</Card>
    </>
  );
}

// This forces the list to display empty when no filters are selected
export const defaultPatientFilterValues = {
  fakeFilter: "fakeVal",
};

const styles = {
  "& .RaList-main": {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  ...removeDefaultEmptyMessageStyle
};

export function PatientList() {
  return (
    <List
      filters={<PatientFilter />}
      filterDefaultValues={defaultPatientFilterValues}
      actions={<PatientListActions />}
      title="Search Patients"
      component={Wrapper}
      sx={styles}
      empty={false}
    >
      <PatientListItems />
    </List>
  );
}

function PatientListItems() {
  const navigate = useNavigate();
  const { search } = useLocation();

  const queryParameters = qs.parse(search, {
    parseNumbers: true,
    parseBooleans: true,
  }) as PatientListQueryParameters;

  useEffectOnce(() => {
    if (!queryParameters.persistFilters) {
      const filter = JSON.stringify(defaultPatientFilterValues)
      const patientListQueryParameters = {
          displayedFilters: JSON.stringify({}),
          filter: filter,
          order: 'ASC',
          page: 1,
          perPage: 10,
      } as PatientListQueryParameters;
      qs.stringify(patientListQueryParameters);

      // Resets the filters when accessing the list
      navigate(
        {
          pathname: '/patientRecords',
          search: qs.stringify(patientListQueryParameters)
        },
        { replace: true }
      );
    }
  });

  return <PatientListItemsVariant />;
}

function PatientListItemsVariant() {
  return (
    <Datagrid bulkActionButtons={false} data-testid="patient-list">
      <TextField
        sortable={false}
        label="Client Contract Name"
        source="clients[0].attributes.name"
      />
      <TextField
        sortable={false}
        label="Patient's First Name"
        source="attributes.firstName"
      />
      <TextField
        sortable={false}
        label="Patient's Last Name"
        source="attributes.lastName"
      />
      <TextField
        sortable={false}
        label="Date of Birth"
        source="attributes.dateOfBirth"
      />
      <TextField sortable={false} label="Email" source="attributes.email" />
      <PatientViewButton />
      <CreateBookingButton
          size="small"
          onClick={() => {
            trackEvent("Bookings", "Navigate to Create Booking");
          }}
        />
    </Datagrid>
  );
}
