import React from "react";
import { useGetOne } from "ra-core";
import get from "lodash/get";
import { Labeled, TextField } from "ra-ui-materialui";


export interface ClientEmployerFieldProps {
  clientId: string|number;
  source: string;
}

export function ClientEmployerField(props: ClientEmployerFieldProps) {
  const { clientId, source} = props;

  const { data: employerSettings } = useGetOne("employerSettings", {
    id: clientId,
  });
  const employerRequiredOnBookings = get(
    employerSettings,
    "attributes.employerRequiredOnBookings"
  );

  if (!employerRequiredOnBookings) {
    return null;
  }

  return (
    <Labeled label="Employer">
      <TextField
        source={source}
        emptyText="Not provided"
      />
    </Labeled>
  )
}
