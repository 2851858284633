import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Group } from "../../../layout/Group";
import { Heading } from "../../../ui/Heading";
import { PractitionerNameSearchLink } from "./PractitionerNameSearchLink";
import { RecordContextProvider } from "react-admin";
import { GaiaPractitioner } from "../../../../types/gaia";

type GaiaPractitionerGroupProps = {
  practitioner: GaiaPractitioner,
}

export function GaiaPractitionerGroup(props: GaiaPractitionerGroupProps) {
  return (
    <RecordContextProvider value={props.practitioner}>
      <Group 
        heading={<Heading level={2}>Clinical Practitioner</Heading>}
        data-testid="gaia-practitioner-group"
      >
        <Grid item xs={12} sm={6}>
          <Box>
            <PractitionerNameSearchLink 
              label="Practitioner Name"
              firstName={props.practitioner.givenName}
              lastName={props.practitioner.familyName}
            />
          </Box>
        </Grid>
      </Group>
    </RecordContextProvider>
  );
}
