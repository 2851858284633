import React, { ChangeEvent } from "react";
import {
  FileField,
  FormDataConsumer,
  ImageField,
  ImageInput,
  InputProps,
  Labeled,
  SelectInput,
  TextField,
  RaRecord,
} from "react-admin";
import { FormIteratorAddButton } from "../form/FormIteratorAddButton";
import { FormIteratorRemoveButton } from "../form/FormIteratorRemoveButton";
import get from "lodash/get";
import { useFormContext } from "react-hook-form";
import { FileInput, fileInputStyles } from "../inputs/FileInput";
import { ArrayInput } from "../inputs/ArrayInput";
import { SimpleFormIterator } from "../form/SimpleFormIterator";
import { styled } from "@mui/material";

interface FileInputProps {
  source?: string;
}

const StyledOutputWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  ["& > div"]: {
    width: "10px",
  },
}));

const arrayInputStyles = {
  maxWidth: "675px",
};

const cpDocuments = [
  { id: "ClinicalPractitionerPhoto", name: "Photo" },
  {
    id: "ClinicalPractitionerESignature",
    name: "E-Signature",
  },
  {
    id: "ClinicalPractitionerDocument",
    name: "Registration Document",
  },
];

export const NewDocuments = (props: Omit<InputProps, "source">) => {
  return (
    <ArrayInput
      {...props}
      label="Existing Documents"
      source="clinicalPractitionerAttachments"
      sx={arrayInputStyles}
      level={3}
    >
      <SimpleFormIterator disableAdd>
        <FormDataConsumer>
          {({ formData, getSource }) => {
            if (!getSource) return null;
            return (
              <StyledOutputWrapper>
                <Labeled label="File name" aria-label="File name">
                  <TextField
                    source={getSource("attributes.description")}
                    record={formData}
                  />
                </Labeled>
                <Labeled label="Document type">
                  <TextField
                    source={getSource(
                      "attributes.clinicalPractitionerAttachmentType"
                    )}
                    record={formData}
                  />
                </Labeled>
              </StyledOutputWrapper>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export const CPDocumentsInput = () => {
  const form = useFormContext();

  const clearFileField = ({
    target,
  }: ChangeEvent<HTMLInputElement> | RaRecord) => {
    form.setValue(`${target.name.split(".")[0]}.file`, "");
  };

  return (
    <ArrayInput
      label="CP Documents"
      source="cpDocuments"
      sx={arrayInputStyles}
      level={3}
    >
      <SimpleFormIterator
        addButton={<FormIteratorAddButton label="Add document" />}
        removeButton={<FormIteratorRemoveButton label="Hide document" />}
      >
        <SelectInput
          choices={cpDocuments}
          source="attributes.cpDocumentType"
          label="Document Type"
          onChange={clearFileField}
          helperText=""
        />
        <DocumentsInputs />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const DocumentsInputs = ({ source }: FileInputProps) => {
  return (
    <FormDataConsumer>
      {({ formData }) => {
        const documentType = get(
          formData,
          `${source}.attributes.cpDocumentType`
        );

        if (!documentType) return null;

        if (documentType === "ClinicalPractitionerPhoto") {
          return (
            <ImageInput
              source={`${source}.file`}
              label="Add CP Photo"
              accept="image/*"
              sx={fileInputStyles}
              placeholder={<p>Drop your photo here</p>}
              inputProps={{
                name: `${source}.file`,
              }}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          );
        }

        if (documentType === "ClinicalPractitionerESignature") {
          return (
            <ImageInput
              source={`${source}.file`}
              sx={fileInputStyles}
              label="Add E-Signature"
              accept="image/*"
              placeholder={<p>Drop your E-signature Picture here</p>}
              inputProps={{
                name: `${source}.file`,
              }}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          );
        }

        if (documentType === "ClinicalPractitionerDocument") {
          return (
            <FileInput
              source={`${source}.file`}
              label="Documents"
              accept="application/pdf"
            >
              <FileField source="title" title="title" />
            </FileInput>
          );
        }
      }}
    </FormDataConsumer>
  );
};
