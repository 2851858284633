import { GetListParams } from "ra-core";
import { list } from "..";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

export async function listClientInstructions(args: GetListParams) {
  const { filter } = args;
  const { clientId, instructionType, isEnabled } = filter;

  const params = {
    "filter[instructionType]": instructionType,
    "filter[isEnabled]": isEnabled,
  };

  const filteredParams = shallowlyRemoveEmptyValues(params);

  const endpoint = `/ms/clients/${clientId}/clientinstructions`;
  const { data, total } = await list(endpoint, { params: filteredParams });

  return {
    data,
    total,
  };
}
