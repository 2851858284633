import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useRef } from "react";
import { Form } from "ra-core";
import { Button, SaveButton, TextInput } from "ra-ui-materialui";
import React from "react";
import { DialogCloseButton } from "../buttons/DialogCloseButton";
import { datadogLogs } from "@datadog/browser-logs";

interface Props {
  handleClose: () => void;
  open: boolean;
}

export function ReprocessPrescriptionModal(props: Props) {
  const { open, handleClose } = props;
  const ref = useRef<HTMLAnchorElement>(null!);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <Form
        validate={values => {
          const errors: Record<string, string> = {};

          if (!values.reason) {
            errors.reason = "Please enter a reason";
          }

          return errors;
        }}
        onSubmit={values => {
          datadogLogs.logger.info("Reprocess Prescription", {
            reason: values.reason,
          });

          ref.current.click();
        }}
        mode="onChange"
      >
        <DialogCloseButton onClick={handleClose} />
        <DialogTitle>Reprocess Prescription</DialogTitle>
        <DialogContent>
          <TextInput
            source="reason"
            label="Reason for reprocessing"
            fullWidth
          />
          <a
            ref={ref}
            href="https://healthhero.atlassian.net/wiki/spaces/ED3/pages/3310485510/Healthmail+FAQs#The-pharmacy-hasn%E2%80%99t-received-a-prescription%2C-and-I-can%E2%80%99t-re-process-from-ED3.-How-can-I-resend-the-prescription-to-the-pharmacy%3F"
            rel="noreferrer noopener"
            target="_blank"
            onClick={handleClose}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            aria-label="Cancel"
            size="medium"
            onClick={handleClose}
            label="Cancel"
          />

          <SaveButton
            variant="contained"
            aria-label="Reprocess"
            icon={<span />}
            color="secondary"
            size="medium"
            label="Reprocess"
          />
        </DialogActions>
      </Form>
    </Dialog>
  );
}
