import { Typography, List, ListItem, Box } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { AppointmentDTO } from "../../../types/appointments";
import {
  consolidateAppointmentTimes,
  splitAppointmentsByDate,
} from "./helpers";
import PhoneIcon from "@mui/icons-material/Phone";
import VideoIcon from "@mui/icons-material/Videocam";

interface ListProps {
  appointments: AppointmentDTO["data"]["attributes"][];
}

const iconWrapperStyle = {
  width: "50%",
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  marginLeft: "24px",
};

const listItemStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flex: 1,
  alignItems: "center",
};

const iconStyles = {
  borderRadius: "100%",
  backgroundColor: "grey.400",
  width: "32px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "32px",
  margin: "0 4px",
};

const wrapper = {
  backgroundColor: "grey.300",
  borderRadius: 1,
  marginBottom: 1,
  px: 2,
};

export function ModalList(props: ListProps) {
  const { appointments } = props;

  if (!appointments.length) return null;

  const consolidatedApts = consolidateAppointmentTimes(appointments);
  const appointmentsByDate = splitAppointmentsByDate(consolidatedApts);
  const keys = Object.keys(appointmentsByDate);

  return (
    <Box sx={wrapper}>
      <List data-cy="appointment-list" sx={{ ["& li"]: { paddingLeft: 0 } }}>
        {keys.map(date => (
          <DateList key={date} date={date} times={appointmentsByDate[date]} />
        ))}
      </List>
    </Box>
  );
}

function DateList(props: { date: string; times: Record<string, number> }) {
  const { date, times } = props;
  const formattedDate = dayjs(date).format("Do MMMM");
  const entries = Object.entries(times);

  return (
    <>
      <Typography variant="h4">{formattedDate}</Typography>
      {entries.map(([key, count]) => {
        const [time, consultationType] = key.split("_");
        const isVideo = consultationType === "Video";
        const isPhone = consultationType === "Phone";

        return (
          <ListItem sx={listItemStyles} key={time}>
            <Typography style={{ width: "50%" }}>
              {time} ({count} available)
            </Typography>
            <Box sx={iconWrapperStyle}>
              {isVideo || isPhone ? (
                <ConsultationTypeIcon icon={<PhoneIcon width={24} />} />
              ) : null}
              {isVideo ? (
                <ConsultationTypeIcon icon={<VideoIcon width={24} />} />
              ) : null}
            </Box>
          </ListItem>
        );
      })}
    </>
  );
}

interface Props {
  icon: JSX.Element;
}

function ConsultationTypeIcon(props: Props) {
  return <Box sx={iconStyles}>{props.icon}</Box>;
}
