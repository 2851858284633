import { GetListParams } from "react-admin";
import { list } from "../gaiaClient";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";
import { extractGuid } from "../../utilities/extractGuidFromFhirId";

const endpoint = "pharmacies";

export default async function listPharmacies(params: GetListParams) {
  const query = {
    pageSize: params.pagination.perPage,
    pageNumber: params.pagination.page,
    nameContains: params.filter.name,
    addressContains: params.filter.address,
    addressCity: params.filter.city,
    email: params.filter.email,
    pageToken: params.meta.pageToken,
  };

  const filteredQuery = shallowlyRemoveEmptyValues(query);

  const { data } = await list(endpoint, { params: filteredQuery });

  const formattedData = data.pharmacies.map((pharmacy: any) => ({
    ...pharmacy,
    id: extractGuid(pharmacy.id),
  }));

  return {
    data: formattedData,
    total: data.total,
    pageInfo: {
      nextPageToken: data.nextPageToken,
    },
  };
}
