import { AppointmentShow } from "./AppointmentShow";
import { AppointmentEdit } from "./AppointmentEdit";
import { AppointmentList } from "./AppointmentList";
import { AppointmentCreate } from "./AppointmentCreate";
import AppointmentIcon from "@mui/icons-material/EventAvailable";
import { TOGGLE_APPOINTMENTS_PAGE_ENBALED } from "../../featureFlags/flags";

export const appointmentResources = {
  name: "adminAppointments",
  options: { label: "Appointments", flagKey: { name: TOGGLE_APPOINTMENTS_PAGE_ENBALED } },
  icon: AppointmentIcon,
  show: AppointmentShow,
  list: AppointmentList,
  create: AppointmentCreate,
  edit: AppointmentEdit,
};
