import React from "react";
import { Button, Typography } from "@mui/material";
import { useSchedulerBookingStore } from "../context/schedulerBookingStore";
import { StateDisplay } from "./ui/StateDisplay";
import { Stack } from "./layout/Stack";

export function SchedulerBookingStateDisplay() {
  const store = useSchedulerBookingStore(({ state, releaseAppointment }) => {
    return { state, releaseAppointment };
  });
  const isAppointmentHeld = store.state === "HELD";

  return (
    <StateDisplay
      shouldDisplay={isAppointmentHeld}
      message={
        <Stack>
          <Typography variant="body1">
            You're currently holding an appointment, which can be used to create
            a booking for a patient.
          </Typography>
        </Stack>
      }
      action={
        <Button
          color="secondary"
          onClick={() => {
            store.releaseAppointment();
          }}
          variant="text"
          aria-label="Release Appointment"
        >
          Release Appointment
        </Button>
      }
    />
  );
}
