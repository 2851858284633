import { DeleteManyParams } from "ra-core";
import { deleteAppointment } from "../appointments";

async function deleteManyProposedAppointments(params: DeleteManyParams) {
  const { ids } = params;

  const promises = ids.map(id => {
    return deleteAppointment({ id });
  });

  await Promise.all(promises);

  return {
    data: ids,
  };
}

export default deleteManyProposedAppointments;
