import React from "react";
import { Datagrid, Filter, List, TextInput } from "react-admin";
import { SelectInput, TextField } from "ra-ui-materialui";
import { BooleanField } from "../fields/BooleanField";
import { ListActions } from "../Actions";


function ProductsListFilter(props: any) {
  return (
    <Filter {...props}>
      <TextInput
        label="Name"
        source="name"
        alwaysOn
        autoComplete="off"
      />
      <TextInput
        label="Code"
        source="code"
        alwaysOn
        autoComplete="off"
      />
      <SelectInput
        label={"Enabled"}
        source="isEnabled"
        alwaysOn
        choices={[{ id: true, name: "Enabled" }, { id: false, name: "Disabled" }]}
      />
    </Filter>
  );
}


export function ClinicalProductsList() {
  return (
    <List
      title="Products"
      filters={<ProductsListFilter />}
      actions={<ListActions createLabel="Create" />}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField
          label="Name"
          source="attributes.name"
          sortable={false}
        />
        <TextField
          label="Code"
          source="attributes.code"
          sortable={false}
        />
        <BooleanField
          label="Enabled"
          source="attributes.isEnabled"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
}