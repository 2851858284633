import React from "react";
import {
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
  Title,
  useRecordContext
} from "react-admin";
import get from "lodash/get";
import { Actions } from "../Actions";
import { GaiaGpSurgery } from "../../types/gaia";

function PageTitle() {
  const record = useRecordContext();
  const name = get(record, "name");
  return <Title title={name} defaultTitle="" />;
}

const renderAddress = (rec: GaiaGpSurgery) => rec.address.line
  .concat([rec.address.city, rec.address.postalCode!])
  .filter((part: any) => part)
  .join(", ");

export const GpSurgeryShow = () => {
  return (
    <Show title={<PageTitle />} actions={<Actions />}>
      <SimpleShowLayout>
        <TextField label="Name" source="name" sx={{marginBottom: "5px"}}/>
        <TextField label="Email Address" source="email" sx={{marginBottom: "5px"}}/>
      <FunctionField label={"Address"} render={renderAddress}></FunctionField>
      </SimpleShowLayout>
    </Show>
  );
};
