import React from "react";
import { styled } from "@mui/material/styles";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import SmsIcon from "@mui/icons-material/SmsOutlined";
import PushIcon from "@mui/icons-material/BookOnlineOutlined";
import { ArrayField, Datagrid, FunctionField } from "ra-ui-materialui";
import { CapsDelimitedField } from "../../fields/CapsDelimitedField";
import DateField from "../../fields/DateField";
import { RaRecord, TextField, useRecordContext } from "react-admin";
import { customStyles } from "../../../theme";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { DATE_AND_TIME_FORMAT, TIME_FORMAT } from "../../../constants/constants";
import { BookingEvent } from "../../../types/bookings";
const PREFIX = "EventsTab";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledArrayField = styled(ArrayField)({
  [`&.${classes.root}`]: {
    ...customStyles.horiztonalScrollableShadows,
  },
});

const useStyles = makeStyles({
  iconColumn: { width: '0px' },
  icon: { verticalAlign: 'middle' },
});

const IconField = (props: { headerClassName: string, label: string, source: string }) => {
  const record = useRecordContext();
  const icon = record[props.source];
  const label = icon === 'Push' ? 'Push Notification' : icon;
  const iconClasses = useStyles();
  return (
    <>
      {icon === 'Email' ? <EmailIcon className={iconClasses.icon} color="action" aria-label={label} /> : null}
      {icon === 'SMS' ? <SmsIcon className={iconClasses.icon} color="action" aria-label={label} /> : null}
      {icon === 'Push' ? <PushIcon className={iconClasses.icon} color="action" aria-label={label} /> : null}
    </>
  );
}

function getExtraNotes(record: BookingEvent) {
  if (record.eventType !== "AppointmentSwap") {
    return "-";
  }

  const oldAppointment = record.appointments[record.oldAppointmentId];
  const newAppointment = record.appointments[record.newAppointmentId];

  const oldCp = oldAppointment.clinicalPractitioners[0];
  const newCp = newAppointment.clinicalPractitioners[0];

  const notes: Array<string> = []

  if (oldCp.id !== newCp.id) {
    notes.push(ClinicianChanged(`${oldCp.attributes.firstName} ${oldCp.attributes.lastName}`, `${newCp.attributes.firstName} ${newCp.attributes.lastName}`))
  }

  if (!dayjs(oldAppointment.attributes.start).isSame(dayjs(newAppointment.attributes.start))
    || !dayjs(oldAppointment.attributes.end).isSame(dayjs(newAppointment.attributes.end))) {
    notes.push(AppointmentChanged(oldAppointment.attributes.start, oldAppointment.attributes.end, newAppointment.attributes.start, newAppointment.attributes.end))
  }

  return <ul style={{ paddingLeft: "15px" }}>
    {notes.map((item: string) => {
      return <li key={item}>{item}</li>
    })}
  </ul>
}

export function ClinicianChanged(oldCpName: string, newCPName: string) {
  return `Clinician changed from ${oldCpName} to ${newCPName}.`;
}

export function AppointmentChanged(oldAppointmentStart: string, oldAppointmentEnd: string, newAppointmentStart: string, newAppointmentEnd: string,) {
  return `Appointment changed from ${dayjs(oldAppointmentStart).format(DATE_AND_TIME_FORMAT)}-${dayjs(oldAppointmentEnd).format(TIME_FORMAT)} to ${dayjs(newAppointmentStart).format(DATE_AND_TIME_FORMAT)}-${dayjs(newAppointmentEnd).format(TIME_FORMAT)}.`
}

export function EventsTab() {
  const iconClasses = useStyles();
  return (
    <StyledArrayField label={false} source="events" className={classes.root}>
      <Datagrid bulkActionButtons={false}>
        <DateField
          sortable={false}
          label="Time"
          source="createdAt"
          showTime
          hideLabel
        />
        <IconField
          headerClassName={iconClasses.iconColumn}
          label=""
          source="icon" />
        <CapsDelimitedField
          sortable={false}
          hideLabel
          label="Event Type"
          source="eventType"
        />
        <CapsDelimitedField
          sortable={false}
          label="Status"
          hideLabel
          source="status"
        />
        <TextField
          sortable={false}
          label="User"
          source="user"
          emptyText="-"
        />
        <FunctionField
          label="Notes"
          sx={{ whiteSpace: "normal" }}
          render={(record: BookingEvent) => getExtraNotes(record)}
        />
      </Datagrid>
    </StyledArrayField>
  );
}
