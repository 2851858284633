import get from "lodash/get";
import { useGetOne } from "ra-core";
import { useFormContext, useWatch } from "react-hook-form";

interface Props {
  baseSourcePath: string;
  children: JSX.Element;
}

export function ShiftPreferencesController(props: Props) {
  const { baseSourcePath, children } = props;

  const form = useFormContext();
  const clinicalPractitioner = useWatch({ name: "clinicalPractitioner" }) ?? "";

  const basePrefix = baseSourcePath ? `${baseSourcePath}.` : "";

  const onSuccess = (response: any) => {
    const appointmentsPerHour = get(
      response,
      "resourceManagementRules[0].attributes.appointmentsPerHour"
    );

    if (appointmentsPerHour) {
      form.setValue(`${basePrefix}appointmentsPerHour`, appointmentsPerHour);
    }

    const appointmentDuration = get(
      response,
      "resourceManagementRules[0].attributes.appointmentDuration"
    );

    if (appointmentDuration) {
      form.setValue(`${basePrefix}appointmentDuration`, appointmentDuration);
    }
  };

  const options = {
    onSuccess,
    enabled: Boolean(clinicalPractitioner),
  };

  useGetOne("clinicalPractitioners", { id: clinicalPractitioner }, options);

  return children;
}
