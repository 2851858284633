import { FieldProps, FunctionField } from "ra-ui-materialui";
import React from "react";
import get from "lodash/get";

export function TrimmedTimeField(props: FieldProps) {
  const { source = "" } = props;

  return (
    <FunctionField
      render={(record: any) => {
        const time = get(record, source, "");

        const splitTime = time.split(":");

        if (splitTime.length < 2) return "n/a";

        return `${splitTime[0]}:${splitTime[1]}`;
      }}
    />
  );
}

TrimmedTimeField.defaultProps = {
  addLabel: true,
};
