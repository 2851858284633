import * as ld from "launchdarkly-js-client-sdk";
import {
  asyncWithLDProvider,
  useFlags,
  useLDClient,
} from "launchdarkly-react-client-sdk";

export class FeatureFlagService {
  static instance: FeatureFlagService | null = null;
  client: ld.LDClient | null = null;
  key = "anonymous";

  static getInstance() {
    if (!this.instance) {
      this.instance = new FeatureFlagService();
    }

    return this.instance;
  }

  updateUser(key: string, attributes: ld.LDUser) {
    const hasUserChanged = key !== this.key;

    if (hasUserChanged && this.client) {
      const customAttributes = attributes?.custom ?? {};
      const enrichedAttributes = {
        ...attributes,
        anonymous: key === "anonymous",
        custom: {
          ...customAttributes,
          environment: process.env.REACT_APP_ENV ?? "development",
        },
      };

      this.client.identify(enrichedAttributes);
    }

    this.key = key;

    return this;
  }

  initialiseClient(client?: ld.LDClient) {
    if (!client) return;
    if (this.client) return;

    this.client = client;
  }

  getFlag(id: string) {
    if (!this.client) {
      return false;
    }

    return this.client.variation(id, false);
  }
}
export const useFlag = (key: string) => {
  const flags = useFlags();

  return flags[key] ?? false;
};

// Note: I tested that this doesn't break the app if LD fails to load
// I did this by omitting LaunchDarkly resources from the CSP. It caused the app to load and all flags fellback to their control state
export async function getFeatureFlagsProvider() {
  return asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_FEATURE_MGMT_API_KEY ?? "",
    user: {
      key: "anonymous",
    },
    options: {
      bootstrap: "localStorage",
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });
}

// This little hack is a way to access feature flags in and out of the react component tree
// Doing this at the top level of the RA tree triggers a re-render which causes RA to reinitialise a bunch of state code
// This causes issues with passing state across views.
export function InitExternalLDClient() {
  const client = useLDClient();
  FeatureFlagService.getInstance().initialiseClient(client);

  return null;
}
