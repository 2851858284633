import downloadBlob from "../downloadBlob";
import { getFile } from "../gaiaClient";

interface Params {
  url: string;
  applyPassword: boolean;
  password: string;
  fileName: string;
}

async function downloadDocument(params: Params) {
  const { url, fileName, password, applyPassword } = params;

  const queryParams = {
    applyPassword,
    password,
    fileName,
  };

  const { data } = await getFile(url, {
    params: queryParams,
    responseType: "blob",
  });

  downloadBlob(data, fileName);

  return {
    data: {
      id: "id-to-satisfy-ra",
    },
  };
}

export default downloadDocument;
