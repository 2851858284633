import dayjs, { Dayjs } from "dayjs";

export const maxDate = (max: string) => (date: string | null) => {
  const formattedMaxDate = dayjs(max).format("DD/MM/YYYY");
  const message = `Choose a date before ${formattedMaxDate}`;

  return validate(max, date, message, "d");
};

export const maxTime = (max: Dayjs) => (date: Dayjs | null) => {
  if (!date) {
    return `Please enter a valid date`;
  }

  const formattedMaxTime = max.format("HH:mm");
  const message = `Choose a time before ${formattedMaxTime}`;

  return validate(max, date, message, "s");
};

export const maxDateAndTime = (max: string) => (date: string | null) => {
  const formattedMaxDateAndTime = dayjs(max).format("DD/MM/YYYY HH:mm");

  const message = `Choose a period before ${formattedMaxDateAndTime}`;

  return validate(max, date, message, "s");
};

const validate = (
  max: string | Dayjs,
  date: Dayjs | string | null,
  message: string,
  unit: dayjs.OpUnitType
) => {
  const isValid =
    dayjs(date).isBefore(max, unit) || dayjs(date).isSame(max, unit);

  const error = !isValid ? message : undefined;

  return error;
};
