import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useWatch, useFormContext } from "react-hook-form";
import {
  SelectInput,
  useGetList,
  RaRecord,
  useRecordContext,
} from "react-admin";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import dayjs, { Dayjs } from "dayjs";
import { useCreate, useNotify } from "ra-core";
import { genderChoices } from "../../constants/constants";

const PREFIX = "AppointmentInput";

const classes = {
  inputWrapper: `${PREFIX}-inputWrapper`,
  selectInput: `${PREFIX}-selectInput`,
  buttonRow: `${PREFIX}-buttonRow`,
  errorText: `${PREFIX}-errorText`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.inputWrapper}`]: {
    paddingBottom: 8,
  },

  [`& .${classes.selectInput}`]: {
    width: "260px",
  },

  [`& .${classes.buttonRow}`]: {
    display: "flex",
  },

  [`& .${classes.errorText}`]: {
    color: theme.palette.error.main,
  },
}));

type Props = {
  fallbackClientId?: string;
  disabled?: boolean;
};

const defaultParams = {
  paginParams: {
    page: 1,
    perPage: 100,
  },
  sortParams: {
    field: "id",
    order: "DESC",
  },
};

const buttonStyles = {
  marginBottom: "8px",
  alignSelf: "flex-end",
};

const errorMessage =
  "There are no appointments available with your preferences. Try changing the date, consultation type, and gender of the practitioner";

const hintMessage = "Select a service to see available appointments"

function getDateRange(date?: string, shouldOffsetAnHour?: boolean) {
  if (!date) {
    return ["", ""];
  }
  const nowPlus15Minutes = dayjs().add(15, "minutes").second(0).format();

  const startRange = shouldOffsetAnHour ? nowPlus15Minutes : date;
  const endRange = dayjs(date).hour(0).minute(0).add(1, "day").format();

  return [startRange, endRange];
}

function filterOutPastAppointments(apts: RaRecord[]) {
  return apts.filter(({ attributes }) => dayjs().isBefore(attributes.start));
}

function sortAppointments(apts: RaRecord[]) {
  return apts.sort((a, b) => {
    const aStart = dayjs(a.attributes.start);
    const bStart = dayjs(b.attributes.start);

    return dayjs(aStart).isBefore(bStart) ? -1 : 1;
  });
}

function getOptions(data?: Record<string, any>) {
  if (!data) return [];

  const appointments = Object.values(data);

  if (appointments.length === 0) return [];

  const filteredAppointments = filterOutPastAppointments(appointments);
  const sortedAppointments = sortAppointments(filteredAppointments);

  const formattedApts = sortedAppointments.map(apt => {
    const formattedStartTime = dayjs(apt.attributes.start).format("HH:mm");
    const { firstName, lastName, gender } =
      apt.clinicalPractitioners[0].attributes;

    const genders = genderChoices;

    const [filteredGender] = genders.filter(({ id }) => id === gender);

    const label = `${formattedStartTime} - ${firstName} ${lastName} - ${filteredGender.name}`;

    return {
      id: apt.id,
      name: label,
    };
  });

  return formattedApts;
}

function combineDateAndTime(date: Dayjs, time?: Dayjs) {
  const isTimeValid = time && time.isValid();

  const hour = isTimeValid ? dayjs(time).get("hour") : 0;
  const minute = isTimeValid ? dayjs(time).get("minute") : 0;

  return dayjs(date).set("hour", hour).set("minute", minute).format();
}

function AppointmentInput(props: Props) {
  const record = useRecordContext();
  const userId = record?.userId;
  const { fallbackClientId = "", disabled = false } = props;
  const gender = useWatch({ name: "clinicalPractitioner.gender" });
  const clientIdFromForm = useWatch({ name: "clients[0].id" });
  const service = useWatch({ name: "service" });

  const selectedDate: Dayjs = useWatch({ name: "selectedDate" });
  const selectedTime: Dayjs = useWatch({ name: "selectedTime" });
  const ignoreClientContractRequirements = useWatch({
    name: "ignoreClientContractRequirements",
  });
  const ignoreOpeningTimes = useWatch({ name: "ignoreOpeningTimes" });
  const appointmentId = useWatch({ name: "appointmentId" });
  const consultationType = useWatch({ name: "consultationType" });

  const form = useFormContext();

  const hour = dayjs(selectedTime).get("hour");
  const minute = dayjs(selectedTime).get("minute");

  const selectionIsToday = dayjs(selectedDate).isToday();

  const selectedTimeIsWithin15Minutes =
    !selectedTime ||
    dayjs()
      .set("hour", hour)
      .set("minute", minute)
      .isBefore(dayjs().add(15, "minutes"));

  const shouldOffset15Minutes =
    selectionIsToday && selectedTimeIsWithin15Minutes;

  const dateAndTime = combineDateAndTime(selectedDate, selectedTime);

  const [startRange, endRange] = getDateRange(
    dateAndTime,
    shouldOffset15Minutes
  );

  const clientId = clientIdFromForm || fallbackClientId;

  const isMAD = consultationType === "MessageDoctor";

  const filterParams = {
    start: `ge:${startRange}`,
    end: `le:${endRange}`,
    gender,
    specialties: isNaN(service) ? service : "",
    clinicalServiceId: !isNaN(service) ? service : null,
    status: "in:Available,OnHold",
    ignoreClientContractRequirements: ignoreClientContractRequirements ? 1 : 0,
    ignoreOpeningTimes: ignoreOpeningTimes ? 1 : 0,
    clientId,
    userId,
    consultationType: isMAD ? "in:MessageDoctor,Phone,Video" : consultationType,
  };

  // explanation in README#using-specialised-hooks
  const { data, isLoading } = useGetList("appointments", {
    pagination: defaultParams.paginParams,
    sort: defaultParams.sortParams,
    filter: filterParams,
    meta: {
      requestSource: "AppointmentInput"
    }
  });

  const choices = getOptions(data);

  useEffect(() => {
    if (isLoading || appointmentId === "") return;

    // only appointmends in the current results can be selected
    const isSelectAptContainedInChoices = choices.some(
      ({ id }) => id === appointmentId
    );

    if (!isSelectAptContainedInChoices) {
      form.setValue("appointmentId", "");
    }
  });

  const notify = useNotify();

  const [holdAppointment] = useCreate();

  // We haven't included the other dependancies here because we only ever want it to fire when the appointmentId changes.

  useEffect(() => {
    if (!appointmentId) return;
    holdAppointment(
      "appointmentHolds",
      {
        data: { clientId, userId, appointmentId },
      },
      {
        onSuccess: () => {
          notify("Appointment has been held for 5 minutes");
        },
        onError: error => {
          notify((error as Error).message);
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId]);

  const choicesLength = choices.length;

  return (
    <Root className={classes.inputWrapper}>
      <div className={classes.buttonRow}>
        <SelectInput
          source="appointmentId"
          disabled={disabled || isLoading || !choicesLength}
          label="Appointment Time"
          className={classes.selectInput}
          required
          choices={choices}
          helperText={false}
        />
        <Button
          sx={buttonStyles}
          disabled={disabled || isLoading || !choicesLength}
          color="primary"
          aria-label="Next available appointment"
          variant="contained"
          onClick={() => {
            const updatedChoices = getOptions(data);

            form.setValue("appointmentId", updatedChoices[0].id);
          }}
        >
          Next available appointment
        </Button>
      </div>
      {!choices.length && !isLoading && (
        <Typography className={classes.errorText} variant="body1">
          {service ?  errorMessage : hintMessage}
        </Typography>
      )}
    </Root>
  );
}

export default AppointmentInput;
