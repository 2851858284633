import * as React from "react";
import { TopToolbar, useListContext } from "react-admin";
import { CreateButton } from "../CreateButton";

export function ResourceActions() {
  const { filterValues } = useListContext();
  const { firstName, lastName, role } = filterValues;

  const initialValues = {
    attributes: {
      firstName,
      lastName,
    },
    role,
  };

  return (
    <TopToolbar>
      <CreateButton
        initialValues={initialValues}
        label="Create User"
        size="medium"
        resource="resourceManagement"
      />
    </TopToolbar>
  );
}
