import { update } from "../";
import uploadBookingOutputs from "./uploadBookingOutputs";
import uploadBookingAttachment from "./uploadBookingAttachment";
import get from "lodash/get";
import updateExistingOutputAttachments from "./updateExistingOutputAttachments";
import updateBookingAssignedPmsCase from "./updateBookingAssignedPmsCase";
import { ConsultationDTO } from "../../types/consultations";
import { BookingDTO, BookingAttachmentsDTO } from "../../types/bookings";
import { FollowUpActionDTO } from "../../types/workQueues";
import { PatientManagementSystemsDTO, PmsPatientCase } from "../../types/patientManagementSystems";
import deleteBookingAttachments from "./deleteBookingAttachments";
import getBooking from "./getBooking";
import { UpdateParams } from "ra-core";

interface Body {
  id: string;
  attributes: any;
  type: string;
  newAttachments: {
    rawFile: File;
  }[];
  followUpOutputs: FollowUpActionDTO[];
  existingOutputs: FollowUpActionDTO[];
  consultations: ConsultationDTO["data"][];
  pmsPatientCase: PmsPatientCase;
  oldPmsPatientCase: PmsPatientCase;
  attachments: BookingAttachmentsDTO[];
  patientManagementSystems: PatientManagementSystemsDTO["data"][] | null;
}

type FuncParams = {
  bookingId: string;
  userId: string;
  clientId: string;
  attributes: BookingDTO["data"]["attributes"];
  attachments: BookingAttachmentsDTO[];
};

async function updateFunc(params: FuncParams) {
  const { bookingId, userId, clientId, attributes, attachments } = params;

  const body = {
    data: {
      id: bookingId,
      type: "bookings",
      attributes: {
        consultationType: attributes.consultationType,
        symptoms: attributes.symptoms,
        reasonForBooking: attributes.reasonForBooking,
        contactDetails: attributes.contactDetails,
        comments: attributes.comments,
        payload: attributes.payload,
        clientAttributes: attributes.clientAttributes
      },
      attachments,
    },
  };

  const booking = await update(
    `/clients/${clientId}/users/${userId}/bookings/${bookingId}`,
    body
  );

  return booking;
}

async function updateBooking({ data }: UpdateParams<Body>) {
  const {
    id,
    newAttachments = [],
    followUpOutputs,
    consultations,
    attributes,
    existingOutputs,
    pmsPatientCase,
    oldPmsPatientCase,
    patientManagementSystems,
    attachments,
  } = data as Body;

  const [consultation] = consultations;
  const [bookingId, userId, clientId] = String(id).split(":");

  const consultationOutputs = get(
    data,
    "consultations[0].consultationOutputs",
    []
  );

  await updateExistingOutputAttachments({
    userId,
    existingOutputs,
    outputs: consultationOutputs,
    clientId,
    consultationId: consultation.id,
  });

  await deleteBookingAttachments({
    clientId,
    userId,
    bookingAttachments: newAttachments,
    bookingId,
    attachments,
  });

  if (newAttachments.length > 0) {
    await uploadBookingAttachment({
      clientId,
      userId,
      bookingAttachments: newAttachments,
      bookingId,
    });
  }

  if (followUpOutputs.length > 0) {
    await uploadBookingOutputs({
      outputs: followUpOutputs,
      userId,
      consultationId: consultation.id,
      clientId,
    });
  }

  await updateBookingAssignedPmsCase({
    pmsPatientCase,
    oldPmsPatientCase,
    bookingPatientManagementSystems: patientManagementSystems,
    bookingId,
  });

  if (attributes.status === "PendingConsultation") {
    await updateFunc({
      bookingId,
      clientId,
      userId,
      attributes,
      attachments,
    });
  }

  return getBooking({ id: data.id });
}

export default updateBooking;
