import { create } from "..";
import { EmployerSettingsDTO } from "../../types/employerSettings";

interface Args {
  clientId: string;
  settings: EmployerSettingsDTO["data"]["attributes"];
}

export async function createEmployerSettings(args: Args) {
  const { clientId, settings } = args;
  const endpoint = `/ms/clients/${clientId}/employerSettings`;

  const createSettingsBody = {
    data: {
      type: "employerSettings",
      attributes: {
        clientId: parseInt(clientId),
        employerRequiredOnBookings: settings.employerRequiredOnBookings,
        allowOtherEmployeeOption: settings.allowOtherEmployeeOption
      }
    },
  };

  await create(endpoint, createSettingsBody);
}
