import React from "react";
import { ShowTabProps } from "../../../types/utilities";
import { Tab } from "../../Tabs";
import { ArrayField, Datagrid, SimpleShowLayout, TextField } from "react-admin";
import DateField from "../../fields/DateField";
import { AddMessageButton } from "../AddMessageButton";
import { DownloadsField } from "../../fields/DownloadsField";
import { useFlag } from "../../../featureFlags";
import { TOGGLE_ENABLE_MESSAGE_DOCTOR, TOGGLE_ENABLE_MESSAGE_DOCTOR_CREATE_REPLY } from "../../../featureFlags/flags";

function RowContent() {
  return (
    <SimpleShowLayout>
      <TextField
        label="Message"
        source="attributes.message"
        sx={{ whiteSpace: "break-spaces" }}
      />
      <TextField label="Sent by" source="messageSenders[0].attributes.name" />
      <TextField
        label="Sender's role"
        source="messageSenders[0].attributes.role"
      />
      <DateField
        sortable={false}
        showTime
        label="Sent at"
        source="attributes.createdAt"
      />
      <TextField
        label="Product ID"
        source="attributes.payload.productId"
        emptyText="n/a"
      />
      <TextField
        label="PMS System"
        source="patientManagementSystemCases[0].attributes.systemType"
        emptyText="n/a"
      />
      <TextField
        label="PMS Case Number"
        source="patientManagementSystemCases[0].attributes.caseNumber"
        emptyText="n/a"
      />
      <TextField
        label="PMS Case Reference"
        source="patientManagementSystemCases[0].attributes.caseReference"
        emptyText="n/a"
      />
      <DownloadsField
        label="Attachments"
        source="files"
        fields={[{ label: "Name", source: "attributes.fileName" }]}
        downloadSources={{
          clientId: "clients[0].id",
          fileId: "id",
          fileName: "attributes.fileName",
          userId: "messages[0].messageSenders[0].id",
        }}
      />
    </SimpleShowLayout>
  );
}

export function MessagesShowTab(props: ShowTabProps) {
  const messageDoctorEnabled = useFlag(TOGGLE_ENABLE_MESSAGE_DOCTOR);
  const messageDoctorCreateReplyEnabled = useFlag(TOGGLE_ENABLE_MESSAGE_DOCTOR_CREATE_REPLY);
  
  return (
    <Tab {...props} label="Messages">
      <ArrayField label={false} source="messages">
        <Datagrid
          bulkActionButtons={false}
          rowClick="expand"
          expand={<RowContent />}
        >
          <TextField
            sx={{ width: "170px", display: "block" }}
            noWrap
            sortable={false}
            label="Message Preview"
            source="attributes.message"
          />
          <TextField
            sortable={false}
            label="Sent by"
            source="messageSenders[0].attributes.name"
          />
          <TextField
            sortable={false}
            label="Sender's role"
            source="messageSenders[0].attributes.role"
          />
          <DateField
            sortable={false}
            showTime
            hideLabel
            label="Sent at"
            source="attributes.createdAt"
          />
        </Datagrid>
      </ArrayField>
      {messageDoctorEnabled && messageDoctorCreateReplyEnabled && <AddMessageButton />}
    </Tab>
  )
}
