import dayjs from "dayjs";
import { create, get } from "..";
import { PatientRecordDTO } from "../../types/patients";
import { NewDTO } from "../../types/utilities";

interface Body {
  dependantOf: string;
  shouldCopyGuardianAddress: boolean;
  address: PatientRecordDTO["data"]["attributes"]["address"];
}

function generatePatientRequestBody(
  patientDetails: any
): NewDTO<PatientRecordDTO["data"]> {
  return {
    type: "patients",
    attributes: {
      ...patientDetails,
      gp: patientDetails.gp ?? null,
      nominatedPharmacy: patientDetails.nominatedPharmacy ?? null,
      hasVerifiedEmail: false,
      dateOfBirth: dayjs(patientDetails.dateOfBirth).format("YYYY-MM-DD"),
    },
  };
}

async function createDependantRecord(body: Body) {
  const { dependantOf, shouldCopyGuardianAddress } = body;

  const getGuardianUrl = "/ms/patientRecords";
  const params = {
    "filter[id]": dependantOf,
    include: "client",
  };

  const { data } = await get(getGuardianUrl, params);

  const [guardian] = data;
  const guardianClientId = guardian.clients[0].id;
  const guardianAddress = guardian.attributes.address;

  if (shouldCopyGuardianAddress) {
    body.address = guardianAddress;
  }

  const createDependantRecordEndpoint = `/clients/${guardianClientId}/users/${dependantOf}/dependants`;
  const patientRequestBody = generatePatientRequestBody(body);

  const config = {
    params: {
      include: "client",
    },
  };

  const patientRes = await create(
    createDependantRecordEndpoint,
    {
      data: patientRequestBody,
    },
    config
  );

  return patientRes;
}

export default createDependantRecord;
