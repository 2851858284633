import React, { useState } from "react";
import { Button, Tooltip } from "@mui/material";
import { EmployerCreateDialog } from "../dialogs/EmployerCreateDialog";
import { FieldValues } from "react-hook-form";

export const CreateEmployerButtonField = (props: {addEmployer: (values: FieldValues) => void}) => {
  const [isCreateDialogOpened, setIsCreateDialogOpened] = useState(false);
  const {addEmployer} = props;

  return (
    <div style={{ padding: "6px 6px 6px 0px" }}>
      <Tooltip title="Create employer">
        <Button
          data-testid={"addEmployerButton"}
          variant="contained"
          size="small"
          onClick={() => setIsCreateDialogOpened(true)}
        >
          Add
        </Button>
      </Tooltip>
      <EmployerCreateDialog
        addEmployer={addEmployer}
        isOpened={isCreateDialogOpened}
        onClose={() => setIsCreateDialogOpened(false)}
      />
    </div>
  );
};
