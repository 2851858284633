import React, { useState } from "react";

import {
  Create,
  SaveButton,
  RaRecord,
  SimpleForm,
  Toolbar,
  useNotify,
  useRedirect,
  useGetOne,
} from "react-admin";
import validateForm from "./validateBookingForm";

import { InstructionAside } from "../InstructionAside";
import useEffectOnce from "../../hooks/useEffectOnce";
import { useNavigate, useLocation } from "react-router-dom";
import get from "lodash/get";

import { Colors } from "../../theme";
import NoticeIcon from "@mui/icons-material/Error";
import { styled, Typography } from "@mui/material";
import { trackEvent } from "../../utilities/trackEvent";
import { BookingCreateForm } from "./forms/BookingCreateForm";
import { RequestOverrideModal } from "../modals/RequestOverrideModal";
import { useSchedulerBookingStore } from "../../context/schedulerBookingStore";
import recursiveIsObjectEmpty from "../../utilities/recursiveIsObjectEmpty";
import { DEFAULT_EMAIL } from "../../constants/constants";
import { CLIENT_VALIDATION_FAILED } from "../../api/errors";
import { CreateBookingTitle } from "./CreateBookingTitle";

function transformGpInformation(record: RaRecord) {
  const { gp } = record;
  const gpIsEmpty = recursiveIsObjectEmpty(gp);

  if (gpIsEmpty) return;

  const phoneNumber = gp?.surgery?.phoneNumber || null;
  const email = gp?.surgery?.email || DEFAULT_EMAIL;

  return {
    ...record.gp,
    surgery: {
      ...record.gp.surgery,
      phoneNumber,
      email,
    },
  };
}

function transformPharmacyInformation(record: RaRecord) {
  const { nominatedPharmacy } = record;
  const pharmacyIsEmpty = recursiveIsObjectEmpty(nominatedPharmacy);

  if (pharmacyIsEmpty) return;

  const phoneNumber = nominatedPharmacy?.phoneNumber || null;
  const email = nominatedPharmacy?.email || DEFAULT_EMAIL;

  return {
    ...record.nominatedPharmacy,
    phoneNumber,
    email,
  };
}

function transform(record: RaRecord) {
  const schedulerBookingStore = useSchedulerBookingStore.getState();
  const isSchedulerBooking = schedulerBookingStore.state === "HELD";
  const transformedGpInformation = transformGpInformation(record);
  const transformedPharmacyInformation = transformPharmacyInformation(record);

  if (isSchedulerBooking) {
    record.appointmentId = schedulerBookingStore.appointmentId;
  }

  return {
    ...record,
    employerId: record.employerId > 0 ? record.employerId : null,
    gp: transformedGpInformation,
    nominatedPharmacy: transformedPharmacyInformation,
    appointmentStartingStatus: schedulerBookingStore.startingState,
  };
}

export function BookingCreate() {
  const notify = useNotify();
  const redirect = useRedirect();
  const [isOverrideModalOpen, setOverrideModalState] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const schedulerBookingStore = useSchedulerBookingStore();

  const clientId = get(state, "record.clients[0].id");
  const clientIdentifierRequired = get(state, "record.clients[0].attributes.clientIdentifierRequired");

  function onSuccess(newRecord: RaRecord) {
    schedulerBookingStore.complete();

    if (newRecord.type === "messageDoctor") {
      const id = newRecord.attributes.message.thread.id;

      notify(`Message a Doctor: ${id} was started successfully`);
      redirect(`/messageThreads/${id}/show`);
      return;
    }

    const userId = newRecord.users ? newRecord.users[0].id : "";
    const id = newRecord.id;
    notify(`Booking: ${id} was created successfully`);
    navigate(
      `/patientRecords/${userId}/clients/${clientId}/bookings/${id}/summary`,
      { replace: true }
    );

    trackEvent("Bookings", "Create Booking");
  }

  function onFailure(err: any) {
    if (err.body?.code === CLIENT_VALIDATION_FAILED) {
      return setOverrideModalState(true);
    }

    notify(err.message, { type: "error" });
  }

  useEffectOnce(() => {
    if (!state) {
      notify(
        "It appears you tried access Create Booking page in an unusual way. Please try again as normal.",
        { type: "warning" }
      );
      navigate("/patientRecords");
    }
  });

  const StyledDiv = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  }));

  const isSchedulerBooking = schedulerBookingStore.state === "HELD";
  const { data: employerSettings } = useGetOne("employerSettings", {
    id: clientId,
  });
  const employerRequiredOnBookings = get(
    employerSettings,
    "attributes.employerRequiredOnBookings"
  );

  // Client and appointment are passed into the validateForm function to validate VNet cases
  // This is because the caseReference input value is always passed through as an empty string
  const { data: client } = useGetOne("clients", { id: clientId });
  const { data: appointment } = useGetOne(
    "adminAppointments",
    {
      id: schedulerBookingStore.appointmentId,
    },
    { enabled: Boolean(schedulerBookingStore.appointmentId) }
  );

  return (
    <Create
      title={<CreateBookingTitle/>}
      mutationOptions={{ onSuccess, onError: onFailure }}
      resource="bookings"
      aside={<InstructionAside clientId={clientId} />}
      transform={transform}
    >
      <SimpleForm
        warnWhenUnsavedChanges={Boolean(state)}
        mode="onBlur"
        noValidate
        toolbar={
          <Toolbar>
            <SaveButton label="Create Booking" />
            <StyledDiv>
              <NoticeIcon
                sx={{
                  margin: "0 8px 0 16px",
                  fontSize: "24px",
                  height: "24px",
                  color: Colors.Blue,
                }}
              />
              <Typography variant="body1">
                Please keep the customer on the line until the booking is
                confirmed
              </Typography>
            </StyledDiv>
          </Toolbar>
        }
        validate={values =>
          validateForm({
            ...values,
            isSchedulerBooking,
            employerRequiredOnBookings,
            clientIdentifierRequired,
            client,
            appointment
          })
        }
      >
        <RequestOverrideModal
          open={isOverrideModalOpen}
          onClose={() => setOverrideModalState(false)}
          transform={transform}
        />
        <BookingCreateForm />
      </SimpleForm>
    </Create>
  );
}
