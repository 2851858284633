import React, { useState } from "react";
import {
  Create,
  RaRecord,
  useRedirect,
  Toolbar,
  SaveButton,
  BooleanInput,
  FormDataConsumer,
  SimpleForm,
  useNotify,
  useCreateController,
} from "react-admin";
import validateForm from "./validatePatientForm";
import { Heading } from "../ui/Heading";
import { RequestOverrideModal } from "../modals/RequestOverrideModal";
import { ClinicalAddressesInputs } from "../booking/ClinicalAddressesInputs";
import { ClinicalDetailsInputs } from "./ClinicalDetailsInputs";
import transformAddresses from "../../transformers/transformAddresses";
import { trackEvent } from "../../utilities/trackEvent";
import { Stack } from "../layout/Stack";
import { Group } from "../layout/Group";
import { PatientCreatePersonalInfoGroup } from "./groups/PatientCreatePersonalInfoGroup";
import { PatientCreateContactGroup } from "./groups/PatientCreateContactGroup";
import { CLIENT_VALIDATION_FAILED } from "../../api/errors";
import { BOOKING_EMPLOYER_OTHER_IDENTIFIER } from "../../constants/employer";
import { InstructionAside } from "../InstructionAside";

function transform(values: RaRecord) {
  const updatedValues = transformAddresses(values);

  if (!updatedValues.isDependant) {
    updatedValues.dependantOf = null;
  }

  if (
    !values.employerId ||
    values.employerId === BOOKING_EMPLOYER_OTHER_IDENTIFIER
  ) {
    values.employerId = null;
  }

  return updatedValues;
}

export const PatientCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [isOverrideModalOpen, setOverrideModalState] = useState(false);

  function onSuccess(data: RaRecord) {
    const patientId = data?.id;
    const clientId = data?.clients[0].id;

    const id = `${patientId}:${clientId}`;

    trackEvent("Patients", "Create Patient");
    notify("Patient record was created successfully");
    redirect(`/patientRecords/${id}/general`);
  }

  const createController = useCreateController();
  const [clientId, setClientId] = useState<string>(createController.record!.clientId);

  return (
    <Create
      title="Create Patient"
      aside={<InstructionAside clientId={clientId} />}
      transform={transform}
      mutationOptions={{
        onSuccess,
        onError: (err: any) => {
          if (err.body?.code === CLIENT_VALIDATION_FAILED) {
            return setOverrideModalState(true);
          }

          notify((err as Error).message, { type: "error" });
        },
      }}
      record={createController.record}
      redirect={createController.redirect}
      resource={createController.resource}
    >
      <SimpleForm
        mode="onChange"
        warnWhenUnsavedChanges
        noValidate
        validate={validateForm}
        toolbar={
          <Toolbar>
            <SaveButton size="medium" label="Create Patient" />
          </Toolbar>
        }
        defaultValues={{ allergies: [], medications: [], medicalHistory: [] }}
      >
        <RequestOverrideModal
          open={isOverrideModalOpen}
          onClose={() => setOverrideModalState(false)}
          transform={transform}
        />
        <Stack>
          <PatientCreatePersonalInfoGroup onChangeClient={setClientId} />
          <PatientCreateContactGroup />
          <Group heading={<Heading level={2}>Clinical Details</Heading>}>
            <BooleanInput
              source="showClinicalDetails"
              label="Show Clinical Details"
            />
            <FormDataConsumer>
              {({ formData }) => {
                if (!formData.showClinicalDetails) return null;
                return <ClinicalDetailsInputs />;
              }}
            </FormDataConsumer>
          </Group>
          <ClinicalAddressesInputs />
        </Stack>
      </SimpleForm>
    </Create>
  );
};
