import React from "react";
import dayjs from "dayjs";
import { InputProps } from "ra-core";
import { useInput, TextInput } from "react-admin";
import { dateHasLeadingZeroDigit, parseDateTime } from "./dateInputHelpers";
import { SxProps, Theme } from "@mui/material";

const INPUT_FORMAT = "YYYY-MM-DDTHH:mm";
interface Props extends InputProps {
  minDate?: string | false;
  maxDate?: string;
  hasHelperText?: boolean;
  disabled?: boolean;
  className?: string;
  sx?: SxProps<Theme>;
}

function sanitizeInputProps(props: Props) {
  const { minDate, maxDate, ...rest } = props;
  return rest;
}

export function DateTimeInput(props: Props) {
  const {
    source,
    minDate,
    maxDate,
    label,
    disabled = false,
    hasHelperText,
    sx = {},
  } = props;

  const sanitizedProps = sanitizeInputProps(props);

  const format = (val: string | number | null) => {
    if (!val) return "";

    if (dateHasLeadingZeroDigit(val)) return val;
    return dayjs(val).format(INPUT_FORMAT);
  };

  //@ts-ignore
  const { fieldState } = useInput(props);
  const { error, isTouched } = fieldState;

  return (
    <TextInput
      {...sanitizedProps}
      id={source}
      type="datetime-local"
      role="alert"
      placeholder="dd/mm/yyyy hh:mm"
      variant="filled"
      margin="dense"
      format={format}
      parse={parseDateTime}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        min: minDate,
        max: maxDate || "9999-12-31T23:59",
      }}
      source={source}
      sx={[
        {
          margin: "0 0 8px 0",
          ".RaFilter-form &": {
            marginBottom: 0.5,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      aria-label={source}
      error={isTouched && Boolean(error)}
      label={label}
      disabled={disabled}
      helperText={!hasHelperText || isTouched ? "" : "dd/mm/yyyy hh:mm"}
    />
  );
}
