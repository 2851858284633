import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { ButtonProps, CloneButton } from "react-admin";
import { useACL } from "ra-access-control-lists";

interface CreateButtonProps extends ButtonProps {
  isDisabled?: boolean;
  initialValues: any;
}

export function CreateButton(props: CreateButtonProps) {
  const { label, initialValues, isDisabled, resource = "", ...rest } = props;
  const permission = "create";
  const access = useACL(resource);
  const canAccess = access[permission];

  if (!canAccess) return null;

  return (
    <CloneButton
      {...rest}
      disabled={isDisabled}
      style={{ margin: "0 8px" }}
      variant="contained"
      color="primary"
      record={initialValues}
      aria-label={label}
      label={label}
      icon={<AddIcon />}
    />
  );
}
